<div class="inspection-snapshot">
        <div class="row">
            <div class="col-12">
                <div class="custom-flexbox-container custom-snapshot-heading">
                    <span class="inspector-snapshot-widget-heading">{{ "Inspection Snapshot"}} </span>
                </div>
            </div>
            <div class="col-12"></div>
            <div class="col-12"></div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="custom-flexbox-container">
                    <dl class="snapshot-list">
                        <dt class="row">
                            <div class="col-5 inspection" style="padding-left: 0;">Inspection</div>
                        </dt>
                        <dd class="row">
                            <div class="col-8 inspection-status"> <a (click)="onFilter('inspectionStatus',  'Rejected');"> -Rejected</a></div>
                            <div class="col-4 daily-details"><a (click)="onFilter('inspectionStatus', 'Rejected');">{{inspectionSnapshot?.Rejected}}</a>
                            </div>
                        </dd>
                        <dd class="row">
                            <div class="col-8 inspection-status"  > <a (click)="onFilter('inspectionStatus' , 'Saved');"> -Saved</a></div>
                            <div class="col-4 daily-details"><a (click)="onFilter('inspectionStatus', 'Saved');">{{inspectionSnapshot?.Saved}}</a></div>
                        </dd>
                        <dd class="row">
                            <div class="col-8 inspection-status"><a (click)="onFilter('inspectionStatus', 'Waiting payment');">-Waiting Payment</a>
                            </div>
                            <div class="col-4 daily-details"><a (click)="onFilter('inspectionStatus', 'Waiting payment');">{{inspectionSnapshot?.Waiting_Payment}}</a></div>
                        </dd>
                        <dd class="row">
                            <div class="col-8 inspection-status"><a (click)="onFilter('inspectionStatus', 'Submitted For Review');">-Submitted For
                                    Review</a></div>
                            <div class="col-4 daily-details"><a (click)="onFilter('inspectionStatus', 'Submitted For Review');">{{inspectionSnapshot?.Submitted_For_Review}}</a></div>
                        </dd>
                        <hr>
                        <dt class="row">
                            <div class="col-8 inspection">Property</div>
                        </dt>
                        <dd class="row">
                            <div class="col-8 inspection-status" ><a (click)="onFilter('propertyStatus', 'Properties Pending Approval');">-Properties Pending Approval</a></div>
                            <div class="col-4 daily-details"><a (click)="onFilter('propertyStatus', 'Properties Pending Approval');">{{inspectionSnapshot?.Properties_Pending_Approval}}</a></div>
                        </dd>
                        <dd class="row">
                          <div class="col-8 inspection-status"><a (click)="onFilter('propertyStatus', 'Systems Pending Approval');">-Systems Pending Approval</a></div>
                          <div class="col-4 daily-details"><a (click)="onFilter('propertyStatus', 'Systems Pending  Approval');">{{inspectionSnapshot?.Systems_Pending_Approval}}</a></div>
                      </dd>
                        <hr>
                        <dt class="row">
                            <div class="col-8 inspection">Deficiency</div>
                        </dt>
                        <dd class="row">
                            <div class="col-8 inspection-status"><a (click)="onFilter('deficiencyStatus', 'Corrections Required');">-Corrections Required</a></div>
                            <div class="col-4 daily-details"><a (click)="onFilter('deficiencyStatus', 'Corrections Required');">{{inspectionSnapshot?.Corrections_Required}}</a></div>
                        </dd>

                        <hr>
                        <dt class="row">
                            <div class="col-8 inspection">Cases</div>
                        </dt>
                        <dd class="row">
                            <div class="col-8 inspection-status"><a (click)="navigateToCases();">-Cases</a></div>
                            <div class="col-4 daily-details"><a (click)="navigateToCases();">{{inspectionSnapshot?.Cases_Available_Count}}</a></div>
                        </dd>

                    </dl>
                </div>
            </div>
        </div>
    </div>
