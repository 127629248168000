import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ParentComponent } from '../../shared/components/parent.component';
import { MatTableDataSource, MatPaginator, MatDialogRef } from '@angular/material';
var LinkPropertyDialogComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LinkPropertyDialogComponent, _super);
    function LinkPropertyDialogComponent(dialogRef, data) {
        var _this = _super.call(this) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.dataSource = new MatTableDataSource([]);
        _this.columnsToDisplay = ['blank', 'name', 'address'];
        return _this;
    }
    LinkPropertyDialogComponent.prototype.ngOnInit = function () {
    };
    LinkPropertyDialogComponent.prototype.ngAfterViewInit = function () {
        // Do not load table data in ngOnInit, instead use ngAfterViewInit
        // Always load paginator before data
        this.dataSource.paginator = this.paginator;
        this.dataSource.data = this.data;
    };
    LinkPropertyDialogComponent.prototype.selectProperty = function () {
        this.dialogRef.close(this.selectedPropertyId);
    };
    LinkPropertyDialogComponent.prototype.close = function () {
        this.dialogRef.close(null);
    };
    return LinkPropertyDialogComponent;
}(ParentComponent));
export { LinkPropertyDialogComponent };
