import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@app-shared/models/user.model';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: []
})
export class DashboardComponent implements OnInit, AfterViewInit {

  public user: User;
  constructor(private router: Router) { }

  ngOnInit() {

    $(document).ready(function () {


      $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
    });

    // todo operlay
    //   $('#dismiss, .overlay').on('click', function () {
    //     $('#sidebar').removeClass('active');
    //     $('.overlay').removeClass('active');
    // });

    //   $('#sidebarCollapse').on('click', function () {
    //     $('#sidebar').toggleClass('active');
    //     $('.overlay').addClass('active');
    //     $('.collapse.in').toggleClass('in');
    //     $('a[aria-expanded=true]').attr('aria-expanded', 'false');
    // });
  });


  }
  ngAfterViewInit() {
    // this.user = { name: 'Ms. Kathy', role: Roles.ROLE_OWNER_ADMIN };
    // if (this.user.role === Roles.ROLE_OWNER_ADMIN) {
    //   this.router.navigate(['user/property']);
    // }
  }
}
