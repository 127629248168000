import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { FormGroup } from "@angular/forms";

import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import * as moment from "moment";
import { frequencyValues } from "./config";
import {
  AssetType,
  SystemDetails,
} from "../../core/models/property/property.model";
import { PropertyService } from "../../core/services/property/property.service";
import { ContactsService } from "../../core/services/property/contacts.service";

import { Roles } from "../../shared/models/user.model";
import { InspectorService } from "../../inspector/services/inspector.service";
import { DatePipe } from "@angular/common";
import { InspectionService } from "../../core/services/inspection/inspection.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "MM-DD-YYYY",
  },
  display: {
    dateInput: "MM-DD-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "MM-DD-YYYY",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

export interface DialogData {
  description: string;
  isNewProperty?: boolean;
  addEditObject: SystemDetails;
  propId: number;
  jurisId?: number;
  localSystemDetails: SystemDetails[]
}

@Component({
  selector: "app-add-system-dialog",
  templateUrl: "app-add-system-dialog.component.html",
  styleUrls: ["app-add-system-dialog.component.scss"],
  providers: [
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
      useValue: { useUtc: true },
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

// const body = this.systemDetails;
export class AddSystemDialog implements OnInit {
  form: FormGroup;
  description: string;
  subscriptions = [];
  public assetTypes: AssetType[];
  public systemDetails: SystemDetails = null;
  public dateErrorMessage: string;
  private userRole: string;

  inspectionFreqOptions: string[];

  SystemTypes: string[];

  SystemTypesList: string[];

  InspectionFrequency: string;

  validDetails: boolean;

  maxDate = null;

  minDate = null;

  user = null;

  errors: {
    systemType: string;
    assetName: string;
    inspectFreq: string;
    systemLocation: string;
    assetCoverage: string;
  };

  assetAlreadyExist = false;
  settings: any;
  disableButton: boolean;
  newProperty = false;
  userDepartment: string;
  isAddingNewSystemAllowedData: any;
  isAddingNewSystemAllowedWorx: any;
  listOfAllowedSystems: any;

  constructor(
    public dialogRef: MatDialogRef<AddSystemDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private propertyService: PropertyService,
    private contactsService: ContactsService,
    private inspectorService: InspectorService,
    private inspectionService: InspectionService,
    private dialog: MatDialog,
    private datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.userRole = localStorage.getItem("userRole");
    this.userDepartment =
      JSON.parse(localStorage.getItem("userDetails")).role.name ===
      "ROLE_AUTHORITY_WORX"
        ? "TEGRIS Worx"
        : "TEGRIS Fire";
    this.user = JSON.parse(localStorage.getItem("userDetails"));

    this.newProperty = this.data.isNewProperty;

    this.inspectorService.getInspectorSettings().subscribe((x) => {
      this.settings = x;
    });
    if (
      !(
        this.user.role.id === Roles.ROLE_SITE_ADMIN ||
        this.user.role.id === Roles.ROLE_AUTHORITY_ADMIN ||
        this.user.role.id === Roles.ROLE_AUTHORITY_USER ||
        this.user.role.id === Roles.ROLE_AUTHORITY_WORX ||
        this.user.role.id === Roles.ROLE_RFA
      )
    ) {
      this.minDate = new Date();

      this.minDate.setDate(this.minDate.getDate() - 45);
    }

    this.description = this.data.description;
    this.validDetails = true;
    this.errors = {
      systemType: null,
      assetName: null,
      inspectFreq: null,
      systemLocation: null,
      assetCoverage: null,
    };
    this.subscriptions.push(
      this.propertyService
        .getCustomAssetOptions(this.data.jurisId)
        .subscribe((data) => {
          this.assetTypes = data;
          this.SystemTypes = [
            ...new Set(this.assetTypes.map((items) => items.systemType)),
          ];
          if (this.data.addEditObject == null) {
            this.systemDetails = {
              assetCoverage: "",
              assetInspectionFrequency: "",
              assetLocation: "",
              assetType: "",
              inspectionDueDate: "",
              isActive: true,
              isLocationFinalized: false,
              propertyId: this.data.propId,
              systemType: "",
            };
            this.systemDetails.systemType = this.userDepartment;
            this.populateAssetType();
            this.populateInspectFreq();
          } else {
            this.systemDetails = {
              assetCoverage: this.data.addEditObject.assetCoverage,
              assetInspectionFrequency:
                this.data.addEditObject.assetInspectionFrequency,
              assetLocation: this.data.addEditObject.assetLocation,
              assetType: this.data.addEditObject.assetType,
              id: this.data.addEditObject.id,
              inspectionDueDate: this.data.addEditObject.inspectionDueDate,
              isActive: this.data.addEditObject.isActive,
              isLocationFinalized: this.data.addEditObject.isLocationFinalized,
              propertyId: this.data.addEditObject.propertyId,
              systemType: this.data.addEditObject.systemType,
            };
            this.populateAssetType();
            this.maxDateByFrequency();
          }
        })
    );

    this.subscriptions.push(
      this.propertyService.getInspectionFreqOptions().subscribe((data) => {
        this.inspectionFreqOptions = data;
      })
    );

    this.subscriptions.push(
      this.propertyService
        .isAddingNewSystemAllowedDataForDepartment(this.data.jurisId)
        .subscribe((res) => {
          this.isAddingNewSystemAllowedData = res;
        })
    );

    this.isAddingNewSystemAllowedWorx = null;
    this.subscriptions.push(
      this.propertyService
        .isAddingNewSystemAllowedDataForJuridiction(this.data.jurisId)
        .subscribe((res) => {
          this.isAddingNewSystemAllowedWorx = res;
        })
    );

    setTimeout(() => {
      console.log(this.systemDetails);
      console.log(this.userRole), console.log(this.newProperty);
    }, 5000);
  }

  ngAfterViewInit() {
    const jurisId = this.data.jurisId;
    if (jurisId && jurisId !== null) {
      this.inspectionService.getAllowedSystems(jurisId).subscribe((res) => {
        this.listOfAllowedSystems = res;
      });
    }
  }

  enableDisableField(condition) {    
    if(((this.userRole === 'ROLE_AUTHORITY' || this.userRole === "ROLE_RFA") && this.systemDetails.systemType === 'TEGRIS WorX') ||
        this.userRole === 'ROLE_AUTHORITY_WORX' && this.systemDetails.systemType === 'TEGRIS Fire'){
      return true;
    }
    return condition;
  }

  save() {
    if (
      this.systemDetails &&
      this.systemDetails.systemType === "TEGRIS WorX" &&
      this.isAddingNewSystemAllowedWorx &&
      (!this.isAddingNewSystemAllowedWorx["TEGRIS WorX"] ||
        this.isAddingNewSystemAllowedWorx["TEGRIS WorX"] === false)
    ) {
      alert(
        "There is no Tegris WorX account for this jurisdiction. Please contact support@tegrisfire.com for additional information"
      );
      return;
    }
    let isAddingNewSystemAllowed: boolean;
    isAddingNewSystemAllowed =
      this.isAddingNewSystemAllowedData[this.systemDetails.systemType];
    let assetId = -1;
    if (this.settings) {
      if (
        !this.settings["assetTypes"].find(
          (x) => x.data === this.systemDetails.assetType
        ) &&
        this.systemDetails.assetType.indexOf("Custom") < 0
      ) {
        if (
          this.assetTypes.find((item) => {
            return item.assetTypeName === this.systemDetails.assetType;
          })
        ) {
          assetId = this.assetTypes.find((item) => {
            return item.assetTypeName === this.systemDetails.assetType;
          }).id;
        }
        if (assetId > 0) {
          if (
            confirm(
              `System ${this.systemDetails.assetType} is not associated with your Inspection Company's profile. Click ok to add this system to your profile.`
            )
          ) {
            this.settings["assetTypes"].push({
              id: assetId,
              data: this.systemDetails.assetType,
            });
            this.inspectorService.putInspectorPreferencesSettings(
              this.settings
            );
          } else {
            this.disableButton = false;
            return;
          }
        }
      }
    }
    this.errors = {
      systemType: null,
      assetName: null,
      inspectFreq: null,
      systemLocation: null,
      assetCoverage: null,
    };

    if (
      !this.systemDetails.systemType ||
      this.systemDetails.systemType.length === 0
    ) {
      this.errors.systemType = "Enter department type of system.";
      this.validDetails = false;
    }
    if (
      !this.systemDetails.assetType ||
      this.systemDetails.assetType.length === 0
    ) {
      this.errors.assetName = "Enter system name of system.";
      this.validDetails = false;
    }
    if (
      !this.systemDetails.assetInspectionFrequency ||
      this.systemDetails.assetInspectionFrequency.length === 0
    ) {
      this.errors.inspectFreq = "Enter Inspection Frequency of system.";
      this.validDetails = false;
    }

    if (
      !this.systemDetails.inspectionDueDate ||
      this.systemDetails.inspectionDueDate.length === 0
    ) {
      this.dateErrorMessage = "Enter Inspection Due Date";
      this.validDetails = false;
    }

    if (
      this.systemDetails.assetLocation &&
      this.systemDetails.assetLocation.length > 128
    ) {
      this.errors.systemLocation =
        "System Location should not be more than 128 characters.";
      this.validDetails = false;
    }

    if (
      this.systemDetails.assetCoverage &&
      this.systemDetails.assetCoverage.length > 128
    ) {
      this.errors.assetCoverage =
        "Coverage Area should note be more than 128 characters.";
      this.validDetails = false;
    }
    if (
      this.systemDetails.assetType &&
      this.systemDetails.assetType.length > 0
    ) {
      if (
        this.listOfAllowedSystems &&
        this.listOfAllowedSystems.findIndex(
          (elem) => elem.assetTypeName === this.systemDetails.assetType
        ) < 0
      ) {
        this.validDetails = false;
        alert(
          "The Fire Marshal is not currently accepting reports for this type of system."
        );
      }
    }

    if (this.validDetails === true) {
      if (this.data.description.includes("Add")) {
        // this.systemDetails.inspectionDueDate = this.systemDetails.inspectionDueDate.format('YYYY-MM-DD');
        // const newDate = new Date(this.systemDetails.inspectionDueDate._d);
        // newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
        // this.systemDetails.inspectionDueDate = newDate;
        // this.systemDetails.inspectionDueDate = this.datepipe.transform(this.systemDetails.inspectionDueDate, 'yyyy-MM-dd');
        this.systemDetails.inspectionDueDate = moment(
          this.systemDetails.inspectionDueDate
        ).format("YYYY-MM-DD");
        this.systemDetails.additionalInformation = {
          isNewProperty: true && this.newProperty,
        };
        if (
          isAddingNewSystemAllowed &&
          (this.user.role.id == Roles.ROLE_OWNER_ADMIN ||
            this.user.role.id == Roles.ROLE_OWNER_USER ||
            this.user.role.id == Roles.ROLE_INSPECTOR_ADMIN ||
            this.user.role.id == Roles.ROLE_INSPECTOR_USER)
        ) {
          if (
            confirm(
              "The information you provided will be sent to the Fire Marshal's office for approval." +
                "The new system will be in your File Cabinet and marked as inactive.  You will receive an email notification" +
                " once it is reviewed.  Do you want to continue?"
            )
          ) {
            this.systemDetails.isActive = false;
            this.performApiCalls();
          }
        } else {
          this.performApiCalls();
        }
      } else if (this.data.description.includes("Edit")) {
        this.systemDetails.inspectionDueDate = moment(
          this.systemDetails.inspectionDueDate
        ).format("YYYY-MM-DD");

          if(this.IsUserInspectorOrAdmin()){
            this.dialogRef.close(this.systemDetails);
            return;
          }


        this.disableButton = true;
        this.subscriptions.push(
          this.propertyService.putSystemDetails(this.systemDetails).subscribe(
            (res) => {
              this.disableButton = false;
              this.systemDetails = res;
              this.dialogRef.close(this.systemDetails);
            },
            (error) => {
              this.disableButton = false;
              console.log(error);
            }
          )
        );
      }
    }
  }

  IsUserInspectorOrAdmin() : boolean {
    return (this.user.role.id == Roles.ROLE_INSPECTOR_ADMIN ||
      this.user.role.id == Roles.ROLE_INSPECTOR_USER ||
      this.user.role.id == Roles.ROLE_OWNER_ADMIN ||
      this.user.role.id === Roles.ROLE_OWNER_USER)
  }

  performApiCalls() {
    if (this.IsUserInspectorOrAdmin() && this.systemDetails.propertyId === null) {
      if(this.data.localSystemDetails && this.data.localSystemDetails.length > 0 && this.systemDetails.isActive){
        let existingSystems = this.data.localSystemDetails.filter(o => o.assetType === this.systemDetails.assetType && o.assetLocation === this.systemDetails.assetLocation );
        if(existingSystems.length > 0){
          alert('Asset already exists at same location.');
          return
        }
      }
      console.log('api call restricted');
      this.dialogRef.close(this.systemDetails)
      return;
    }

    if (this.systemDetails && this.systemDetails.propertyId === null) {
      this.subscriptions.push(
        this.propertyService
          .postPropertyDetails(this.propertyService.propertyDetails$.getValue())
          .subscribe(
            (res) => {
              //  this.creationDisabled = false;
              if (res.streetLine1) {
                this.propertyService.propertySaved.next(true);
                this.propertyService.propertyDetails$.next(res);
                this.propertyService.propertyId$.next(res.id);
                this.contactsService.propertyID.next(res.id);

                this.propertyService.oldProperyDetailsModel$.next({
                  ...this.propertyService.propertyDetails$.getValue(),
                });
                this.systemDetails.propertyId = res.id;
                this.postPropertyContats(res.id);
                this.postSystemDetails();
              } else {
                alert("Duplicate address"); //todo will moved in first screen
              }
            },
            (error) => {
              console.log(error);
              if (
                error &&
                error.error &&
                error.error.message &&
                error.error.message.includes("Property Already exists")
              ) {
                alert("This address is already registered with TEGRIS FireTM.");
              } else {
                alert("Unexpected error occured");
              }
              // this.currentButton = null;
            }
          )
      );
    } else {
      this.postSystemDetails();
    }
  }

  postSystemDetails() {
    this.disableButton = true;
    this.subscriptions.push(
      this.propertyService.postSystemDetails(this.systemDetails).subscribe(
        (res) => {
          this.disableButton = false;
          this.systemDetails = res;
          this.dialogRef.close(this.systemDetails);
        },
        (error) => {
          this.disableButton = false;
          alert(error.error.message);
        }
      )
    );
  }

  postPropertyContats(propertyId) {
    if (
      this.contactsService.newpropertyContacts.getValue() &&
      this.contactsService.newpropertyContacts.getValue().length > 0
    ) {
      this.subscriptions.push(
        this.contactsService
          .addNewContacts(
            this.contactsService.newpropertyContacts.value,
            propertyId
          )
          .subscribe((res) => {
            // alert('contacts saved successfully');
          })
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  // toFormattedDate(inspectionDueDate: any){
  //   if(moment(inspectionDueDate).isValid()){
  //     return moment(inspectionDueDate).format("MM-DD-YYYY")
  //   }
  //   return '';
  // }
  validateDate(inspectionDueDate: any) {
    let dateformat =
      /^(0?[1-9]|1[0-2])[\-](0?[1-9]|[1-2][0-9]|3[01])[\-]\d{4}$/;

    // Match the date format through regular expression
    if (inspectionDueDate.match(dateformat)) {
      let operator = inspectionDueDate.split("-");

      // Extract the string into month, date and year
      let datepart = [];
      if (operator.length > 1) {
        datepart = inspectionDueDate.split("-");
      }
      let month = parseInt(datepart[0]);
      let day = parseInt(datepart[1]);
      let year = parseInt(datepart[2]);

      // Create list of days of a month
      let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (year < 1000 || year > 3000 || month === 0 || month > 12) {
        return "";
      }
      if (month == 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          ///This check is for Confirming that the date is not out of its range
          return "";
        }
      } else if (month == 2) {
        let leapYear = false;
        if ((!(year % 4) && year % 100) || !(year % 400)) {
          leapYear = true;
        }
        if (leapYear == false && day >= 29) {
          return "";
        } else if (leapYear == true && day > 29) {
          return "";
        }
      }
    } else {
      return "";
    }
    return inspectionDueDate;
  }

  getErrorMessage(pickerInput: string) {
    if (typeof this.systemDetails.inspectionDueDate !== "undefined") {
      if (!pickerInput || pickerInput === "") {
        this.validDetails = false;
        this.dateErrorMessage = "*Please Choose a date";
        return this.dateErrorMessage;
      } else if (
        pickerInput.length !== 10 ||
        pickerInput.indexOf("/") >= 0 ||
        pickerInput.indexOf("-") < 0
      ) {
        this.validDetails = false;
        this.dateErrorMessage = "*Invalid Date Format";
        return "*Invalid Date Format";
      } else if (!this.validateDate(pickerInput)) {
        this.validDetails = false;
        this.dateErrorMessage = "*Invalid Date Format";
        return "*Invalid Date Format";
      } else if (
        this.minDate &&
        this.maxDate &&
        (+this.minDate > +this.systemDetails.inspectionDueDate ||
          +this.systemDetails.inspectionDueDate > +this.maxDate)
      ) {
        this.validDetails = false;
        return `Please enter past date up to 45 days or future date up to one inspection cycle.`;
      } else {
        const date = pickerInput.split("-");
        if (
          date.length !== 3 ||
          date[0].length !== 2 ||
          date[1].length !== 2 ||
          date[2].length !== 4
        ) {
          this.validDetails = false;
          this.dateErrorMessage = "*Invalid Date Format";
        } else if (!moment(pickerInput, "MM-DD-YYYY").isValid()) {
          this.validDetails = false;
          this.dateErrorMessage = "*Invalid Date";
        } else {
          this.dateErrorMessage = "";
          this.validDetails = true;
          //this.disableButton = false;
          // this.systemDetails.inspectionDueDate = moment(pickerInput).toDate();
        }
      }
    }

    if (this.assetAlreadyExist) {
      this.assetAlreadyExist = false;
      //this.disableButton = false;
      return "Asset type already present at this location. Kindly choose some other location or try adding another asset.";
    }
  }

  updateCalcs(event: any) {
    console.log(moment(event.value).toDate());
    this.dateErrorMessage = "";
  }
  toFormattedDate(formattedDate: string) {
    // if (formattedDate !== '') {
    //   const fullDate = new Date(formattedDate);
    //   const date =
    //     fullDate.getDate() < 10 ? '0' + fullDate.getDate() : fullDate.getDate();
    //   const month =
    //     fullDate.getMonth() < 10
    //       ? '0' + (fullDate.getMonth() + 1)
    //       : fullDate.getMonth() + 1;
    //   this.systemDetails.inspectionDueDate =  `${month}-${date}-${fullDate.getFullYear()}`;
    // }
    // this.systemDetails.inspectionDueDate =  '';
  }

  //asset typ asset inspection freq
  populateAssetType() {
    const sysType = this.systemDetails.systemType;
    // this.systemDetails.assetType = '';
    // this.systemDetails.assetInspectionFrequency = '';

    if (sysType && sysType.length > 0) {
      this.SystemTypesList = this.assetTypes
        .filter((items) => items.systemType === sysType)
        .map((assets) => assets.assetTypeName);
    } else {
      this.SystemTypesList = null;
    }
  }

  populateInspectFreq() {
    const assetName: string = this.systemDetails.assetType;
    if (assetName && assetName.length > 0) {
      this.InspectionFrequency = this.assetTypes.find((item) => {
        return item.assetTypeName === assetName;
      }).inspectionFrequencyDescription;
      this.systemDetails.assetInspectionFrequency = this.InspectionFrequency;
    } else {
      this.systemDetails.assetInspectionFrequency = null;
      this.InspectionFrequency = null;
    }
    this.maxDateByFrequency();
  }

  maxDateByFrequency() {
    if (
      !(
        this.user.role.id == Roles.ROLE_SITE_ADMIN ||
        this.user.role.id == Roles.ROLE_AUTHORITY_ADMIN ||
        this.user.role.id == Roles.ROLE_AUTHORITY_USER ||
        this.user.role.id === Roles.ROLE_AUTHORITY_WORX ||
        this.user.role.id === Roles.ROLE_RFA
      )
    ) {
      const maxDate =
        frequencyValues[this.systemDetails.assetInspectionFrequency];

      this.maxDate = maxDate;
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((x) => {
      x.unsubscribe();
    });
  }
}
