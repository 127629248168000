/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./enlarge-map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@agm/core";
import * as i3 from "../../../../../../node_modules/@agm/core/agm-core.ngfactory";
import * as i4 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "@angular/material/list";
import * as i7 from "@angular/common";
import * as i8 from "./enlarge-map.component";
import * as i9 from "@angular/material/dialog";
var styles_EnlargeMapComponent = [i0.styles];
var RenderType_EnlargeMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EnlargeMapComponent, data: {} });
export { RenderType_EnlargeMapComponent as RenderType_EnlargeMapComponent };
function View_EnlargeMapComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "agm-marker", [], null, [[null, "dragEnd"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragEnd" === en)) {
        var pd_0 = (_co.setPoints($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i2.FitBoundsAccessor, null, [i2.AgmMarker]), i1.ɵdid(2, 1720320, null, 1, i2.AgmMarker, [i2.MarkerManager], { latitude: [0, "latitude"], longitude: [1, "longitude"], draggable: [2, "draggable"] }, { dragEnd: "dragEnd" }), i1.ɵqud(603979776, 1, { infoWindow: 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "agm-info-window", [], null, null, null, i3.View_AgmInfoWindow_0, i3.RenderType_AgmInfoWindow)), i1.ɵdid(5, 770048, [[1, 4]], 0, i2.AgmInfoWindow, [i2.InfoWindowManager, i1.ElementRef], { isOpen: [0, "isOpen"] }, null), (_l()(), i1.ɵted(6, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.lat; var currVal_1 = _v.context.$implicit.lng; var currVal_2 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = true; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var currVal_4 = _v.context.$implicit.label; _ck(_v, 6, 0, currVal_4); }); }
export function View_EnlargeMapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["style", "margin: 5px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "agm-map", [["style", "height: 830px;"]], [[2, "sebm-google-map-container", null]], [[null, "mapReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mapReady" === en)) {
        var pd_0 = (_co.mapReady($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AgmMap_0, i3.RenderType_AgmMap)), i1.ɵprd(4608, null, i2.MarkerManager, i2.MarkerManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i2.InfoWindowManager, i2.InfoWindowManager, [i2.GoogleMapsAPIWrapper, i1.NgZone, i2.MarkerManager]), i1.ɵprd(4608, null, i2.CircleManager, i2.CircleManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i2.DataLayerManager, i2.DataLayerManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i2.KmlLayerManager, i2.KmlLayerManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i2.LayerManager, i2.LayerManager, [i2.GoogleMapsAPIWrapper]), i1.ɵprd(4608, null, i2.PolygonManager, i2.PolygonManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i2.PolylineManager, i2.PolylineManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i2.RectangleManager, i2.RectangleManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(512, null, i2.GoogleMapsAPIWrapper, i2.GoogleMapsAPIWrapper, [i2.MapsAPILoader, i1.NgZone]), i1.ɵprd(512, null, i2.ɵa, i2.ɵa, [i2.MapsAPILoader]), i1.ɵdid(13, 770048, null, 0, i2.AgmMap, [i1.ElementRef, i2.GoogleMapsAPIWrapper, i1.PLATFORM_ID, i2.ɵa, i1.NgZone], { longitude: [0, "longitude"], latitude: [1, "latitude"], zoom: [2, "zoom"], fullscreenControl: [3, "fullscreenControl"] }, { mapReady: "mapReady" }), (_l()(), i1.ɵeld(14, 0, null, 0, 3, "mat-icon", [["class", "custom-close-map mat-icon notranslate mat-list-icon"], ["id", "close"], ["mat-list-icon", ""], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(15, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], null, null), i1.ɵdid(16, 16384, null, 0, i6.MatListIconCssMatStyler, [], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_EnlargeMapComponent_1)), i1.ɵdid(19, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.lng; var currVal_2 = _co.lat; var currVal_3 = 13; var currVal_4 = true; _ck(_v, 13, 0, currVal_1, currVal_2, currVal_3, currVal_4); _ck(_v, 15, 0); var currVal_7 = _co.propertyMarker; _ck(_v, 19, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_5 = i1.ɵnov(_v, 15).inline; var currVal_6 = (((i1.ɵnov(_v, 15).color !== "primary") && (i1.ɵnov(_v, 15).color !== "accent")) && (i1.ɵnov(_v, 15).color !== "warn")); _ck(_v, 14, 0, currVal_5, currVal_6); }); }
export function View_EnlargeMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-enlarge-map", [], null, null, null, View_EnlargeMapComponent_0, RenderType_EnlargeMapComponent)), i1.ɵdid(1, 114688, null, 0, i8.EnlargeMapComponent, [i9.MatDialogRef, i9.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EnlargeMapComponentNgFactory = i1.ɵccf("app-enlarge-map", i8.EnlargeMapComponent, View_EnlargeMapComponent_Host_0, {}, {}, []);
export { EnlargeMapComponentNgFactory as EnlargeMapComponentNgFactory };
