import { environment } from '../../../environments/environment';
import { InspectionCompany, AdminSettings, InspectionReport, InspectionCompanyState, jurisdication, additionalPageAuthority, NotificationTemplate, RFA } from '../models/admin.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AdminService = /** @class */ (function () {
    function AdminService(http) {
        this.http = http;
        this.baseUrl = environment.baseUrl;
    }
    AdminService.prototype.getAdminSettings = function () {
        var url = this.baseUrl + "/admin/settings";
        return this.http.get(url);
    };
    AdminService.prototype.setAdminSettings = function (data) {
        var url = this.baseUrl + "/admin/settings";
        return this.http.put(url, data);
    };
    AdminService.prototype.getInspectionCompanies = function () {
        var url = this.baseUrl + "/admin/inpectioncompanies";
        return this.http.get(url);
    };
    AdminService.prototype.getInspectionReport = function (parameters) {
        var url = this.baseUrl + "/admin/inspectionreports";
        return this.http.post(url, parameters);
    };
    AdminService.prototype.savePAC = function (companyId) {
        var url = this.baseUrl + "/admin/savepac/" + companyId;
        return this.http.put(url, null);
    };
    AdminService.prototype.saveCordinate = function () {
        var url = this.baseUrl + "/property/location";
        return this.http.put(url, null);
    };
    AdminService.prototype.searchInspectionCompanies = function (keyword) {
        var url = this.baseUrl + "/inspector/search/" + keyword;
        return this.http.get(url);
    };
    AdminService.prototype.uploadPropertyFile = function (file) {
        var formData = new FormData();
        formData.append("file", file, file.name);
        return this.http.post(this.baseUrl + "/property/upload", formData);
    };
    AdminService.prototype.getAdminRegistration = function (role, isPrimary) {
        var param = { 'role': role, 'isPrimary': isPrimary };
        var url = this.baseUrl + "/user/getDetails";
        return this.http.get(url, { params: param });
    };
    AdminService.prototype.changeStatus = function (userId, status) {
        var url = this.baseUrl + "/user/changeStatus/" + userId + "/" + status;
        return this.http.post(url, null);
    };
    AdminService.prototype.getAssets = function () {
        var url = this.baseUrl + "/assettype";
        return this.http.get(url);
    };
    AdminService.prototype.putAssetsFee = function (body) {
        var url = this.baseUrl + "/assettypes/fee";
        return this.http.put(url, body);
    };
    AdminService.prototype.getUsers = function (userType) {
        if (userType === void 0) { userType = ''; }
        var url = this.baseUrl + "/user/primary";
        return this.http.get(url, { params: { role: userType } });
    };
    AdminService.prototype.putUserDiscount = function (body) {
        var url = this.baseUrl + "/user/fee";
        return this.http.put(url, body);
    };
    AdminService.prototype.getAHJFee = function (id) {
        var url = this.baseUrl + "/authorityassetfees/authority/" + id + " ";
        return this.http.get(url);
    };
    AdminService.prototype.putAHJFee = function (body) {
        var url = this.baseUrl + "/authorityAssetFees";
        return this.http.put(url, body);
    };
    AdminService.prototype.getJurisdications = function () {
        var url = this.baseUrl + "/jurisdiction";
        return this.http.get(url);
    };
    AdminService.prototype.getAuthorityPages = function (isWorxDept) {
        var url = this.baseUrl + "/page";
        return this.http.get(url, {
            params: isWorxDept ? {
                isWorx: isWorxDept
            } : undefined
        });
    };
    AdminService.prototype.postAuthorityPages = function (authorityPage) {
        var url = this.baseUrl + "/page";
        return this.http.post(url, authorityPage);
    };
    AdminService.prototype.deletePage = function (id) {
        var url = this.baseUrl + "/page/" + id;
        return this.http.delete(url);
    };
    AdminService.prototype.updateTemplate = function (notificationTemplate) {
        var url = this.baseUrl + "/authority/jurisdiction/preferences/notificationtemplate";
        return this.http.put(url, notificationTemplate);
    };
    AdminService.prototype.getTemplatePreview = function (templateDto, propertyId) {
        var url = this.baseUrl + "/jurisdiction/notification/preview/" + propertyId;
        return this.http.post(url, templateDto);
    };
    AdminService.prototype.deleteTemplate = function (templateUrl) {
        var params = { filePath: templateUrl };
        var url = this.baseUrl + "/report_thymeleaf/report";
        return this.http.delete(url, { params: params });
    };
    AdminService.prototype.getTemplate = function (jurisdiction, type, systemType) {
        var url = this.baseUrl + '/authority/preferences/notificationtemplatenew';
        return this.http.get(url, {
            params: {
                templateType: type,
                jurisdictionId: jurisdiction,
                department: systemType
            }
        });
    };
    AdminService.prototype.getAhjFees = function (userId) {
        var url = this.baseUrl + "/siteadmin/authorityfeesdetails/" + userId;
        return this.http.get(url);
    };
    AdminService.prototype.setAhjFees = function (body) {
        var url = this.baseUrl + "/siteadmin/authorityfeesdetails";
        return this.http.put(url, body);
    };
    AdminService.prototype.getAssetsPerAuthority = function (userId) {
        var url = this.baseUrl + "/authority/assetTypes/" + userId;
        return this.http.get(url);
    };
    AdminService.prototype.getAdminJurisdication = function (jurisId) {
        var url = this.baseUrl + "/RFA/availableAuthorityList/" + jurisId;
        return this.http.get(url);
    };
    AdminService.prototype.postRFAAdmin = function (rfa) {
        var url = this.baseUrl + "/RFA";
        return this.http.post(url, rfa);
    };
    AdminService.prototype.postWorkXUser = function (rfa) {
        var url = this.baseUrl + "/worx/register";
        return this.http.post(url, rfa);
    };
    AdminService.prototype.putWorkXUser = function (rfa) {
        var url = this.baseUrl + "/worx/user";
        return this.http.put(url, rfa);
    };
    AdminService.prototype.putRFAUser = function (rfa) {
        var url = this.baseUrl + "/RFA";
        return this.http.put(url, rfa);
    };
    AdminService.prototype.getRFAUsers = function () {
        var url = this.baseUrl + "/RFAList";
        return this.http.get(url);
    };
    AdminService.prototype.deleteRFAuser = function (userId) {
        var url = this.baseUrl + "/RFA/delete/" + userId;
        return this.http.delete(url);
    };
    AdminService.prototype.deleteWorkxuser = function (userId) {
        var url = this.baseUrl + "/worx/user/" + userId;
        return this.http.delete(url);
    };
    AdminService.prototype.getWorkXUsers = function () {
        var url = this.baseUrl + "/worx/userList";
        return this.http.get(url);
    };
    AdminService.prototype.getWorxPPAAllowed = function () {
        var url = this.baseUrl + "/worx/isPPAallowed";
        return this.http.get(url);
    };
    AdminService.prototype.findAllUsers = function (searchString, offset, limit) {
        var url = this.baseUrl + "/user/search";
        return this.http.get(url, {
            params: {
                searchString: searchString,
                offset: offset,
                limit: limit
            }
        });
    };
    AdminService.prototype.getDepartments = function () {
        var url = this.baseUrl + '/systemType';
        return this.http.get(url);
    };
    AdminService.prototype.getJurisdicationonDept = function (isWorxDept) {
        var url = this.baseUrl + "/jurisdiction";
        return this.http.get(url, {
            params: isWorxDept ? {
                isWorx: isWorxDept
            } : undefined
        });
    };
    AdminService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.ɵɵinject(i1.HttpClient)); }, token: AdminService, providedIn: "root" });
    return AdminService;
}());
export { AdminService };
