import { MatDialogRef } from '@angular/material/dialog';
var ConfirmationPopupDialogDeleteComponent = /** @class */ (function () {
    function ConfirmationPopupDialogDeleteComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    ConfirmationPopupDialogDeleteComponent.prototype.ngOnInit = function () {
    };
    ConfirmationPopupDialogDeleteComponent.prototype.areYouSureNo = function () {
        this.dialogRef.close(false);
    };
    ConfirmationPopupDialogDeleteComponent.prototype.areYouSureYes = function () {
        if (!(this.comments)) {
            alert('Please add comments');
            return;
        }
        this.comments = this.comments ? this.comments : '';
        this.dialogRef.close({ sub: true, comments: this.comments });
    };
    return ConfirmationPopupDialogDeleteComponent;
}());
export { ConfirmationPopupDialogDeleteComponent };
