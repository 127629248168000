<table cellspacing="0" cellpadding="0" class="clean-agent-top-table" width="100%">
  <tbody>
    <tr>
      <td colspan="5">Extinguishing Agent</td>
    </tr>
    <tr id="systemTypesRow">
      <td width="20%">System Type</td>
      <td width="80%">
      <mat-radio-group  class="example-radio-group" [ngModel]="getsystemType()">
        <mat-radio-button class="example-radio-button"  *ngFor="let systemType of systemTypes" (change)="radioChange($event)" [value]="systemType">
          {{systemType}}
        </mat-radio-button>
      </mat-radio-group>
      <input *ngIf="others" type="text" id="anserText_1223" style="width:70px;" name="anserText_1223"
      [(ngModel)]="qtd[findQuestionId('OTHER')]" [disabled]="reviewInspection">
    </td>
   <!--  <td width="20%">
      HALON&nbsp;&nbsp;
      <input type="checkbox" value="Yes" name="radio_1220" id="radio_1220_1"
        [(ngModel)]="qtd[findQuestionId('HALON')]" [disabled]="reviewInspection">

    </td>
    <td width="20%">
      CO2&nbsp;&nbsp;
      <input type="checkbox" value="Yes" name="radio_1221" id="radio_1221_1" [(ngModel)]="qtd[findQuestionId('CO2')]"
        [disabled]="reviewInspection">
    </td>
    <td width="20%">
      FM 200&nbsp;&nbsp;
      <input type="checkbox" value="Yes" name="radio_1222" id="radio_1222_1"
        [(ngModel)]="qtd[findQuestionId('FM 200')]" [disabled]="reviewInspection">
    </td>
    <td width="20%">
      OTHER&nbsp;&nbsp;
      <input type="text" id="anserText_1223" style="width:70px;" name="anserText_1223"
        [(ngModel)]="qtd[findQuestionId('OTHER')]" [disabled]="reviewInspection">
    </td> -->
    </tr>
    <tr>
      <td colspan="5"></td>
    </tr>
    <tr>
      <td width="20%">Agent Bottles</td>
      <td width="20%">#1</td>
      <td width="20%">#2</td>
      <td width="20%">#3</td>
      <td width="20%">#4</td>
    </tr>
    <tr>
      <td>Design weight</td>
      <td width="20%">
        <input type="text" id="anserText_1224" style="width:70px;" name="anserText_1224"
          [(ngModel)]="qtd[findQuestionId('Dummy DW1')]" [disabled]="reviewInspection">
      </td>
      <td width="20%">
        <input type="text" id="anserText_1225" style="width:70px;" name="anserText_1225"
          [(ngModel)]="qtd[findQuestionId('Dummy DW2')]" [disabled]="reviewInspection">
      </td>
      <td width="20%">
        <input type="text" id="anserText_1226" style="width:70px;" name="anserText_1226"
          [(ngModel)]="qtd[findQuestionId('Dummy DW3')]" [disabled]="reviewInspection">
      </td>
      <td width="20%">
        <input type="text" id="anserText_1227" style="width:70px;" name="anserText_1227"
          [(ngModel)]="qtd[findQuestionId('Dummy DW4')]" [disabled]="reviewInspection">
      </td>
    </tr>
    <tr>
      <td width="20%">Actual weight</td>
      <td width="20%">
        <input type="text" id="anserText_1228" style="width:70px;" name="anserText_1228"
          [(ngModel)]="qtd[findQuestionId('Dummy AW1')]" [disabled]="reviewInspection">
      </td>
      <td width="20%">
        <input type="text" id="anserText_1229" style="width:70px;" name="anserText_1229"
          [(ngModel)]="qtd[findQuestionId('Dummy AW2')]" [disabled]="reviewInspection">
      </td>
      <td width="20%">
        <input type="text" id="anserText_1230" style="width:70px;" name="anserText_1230"
          [(ngModel)]="qtd[findQuestionId('Dummy AW3')]" [disabled]="reviewInspection">
      </td>
      <td width="20%">
        <input type="text" id="anserText_1231" style="width:70px;" name="anserText_1231"
          [(ngModel)]="qtd[findQuestionId('Dummy AW4')]" [disabled]="reviewInspection">
      </td>
    </tr>
  </tbody>
</table>
