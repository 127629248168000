import * as tslib_1 from "tslib";
import { environment } from "../../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FileCabinetService = /** @class */ (function () {
    function FileCabinetService(http) {
        this.http = http;
        this.baseUrl = environment.baseUrl;
    }
    FileCabinetService.prototype.getFileCabinetProperties = function (searchCriteria) {
        var url = this.baseUrl + "/fileCabinet";
        if (searchCriteria.systemType === "Backflow Serial Number") {
            var searchCriteriaSlice = tslib_1.__assign({}, searchCriteria);
            searchCriteriaSlice.systemType = "";
            return this.http.post(url, searchCriteriaSlice);
        }
        else {
            return this.http.post(url, searchCriteria);
        }
    };
    FileCabinetService.prototype.getFileCabinetProperties1 = function (searchCriteria) {
        var url = this.baseUrl + "/fileCabinet";
        if (searchCriteria.systemType === "Backflow Serial Number") {
            var searchCriteriaSlice = tslib_1.__assign({}, searchCriteria);
            searchCriteriaSlice.systemType = "";
            return this.http.post(url, searchCriteriaSlice);
        }
        else {
            return this.http.post(url, searchCriteria);
        }
    };
    FileCabinetService.prototype.getCasesProperties = function (searchCriteria) {
        var url = this.baseUrl + "/casesPropertySearch";
        return this.http.post(url, searchCriteria);
    };
    FileCabinetService.prototype.getDeficiencyStatusList = function () {
        var url = this.baseUrl + "/fileCabinet/deficiencyStatusList";
        return this.http.get(url);
    };
    FileCabinetService.prototype.getInspectionStatusList = function () {
        var url = this.baseUrl + "/fileCabinet/inspectionStatusList";
        return this.http.get(url);
    };
    FileCabinetService.prototype.getPropertyStatusList = function () {
        var url = this.baseUrl + "/fileCabinet/propertyStatusList";
        return this.http.get(url);
    };
    FileCabinetService.prototype.getNotificationStatusList = function () {
        var url = this.baseUrl + "/fileCabinet/notificationStatusList";
        return this.http.get(url);
    };
    FileCabinetService.prototype.getAssetsForInspector = function (dept) {
        var url = this.baseUrl + "/inspector/preferences/" + dept + "/assetType";
        return this.http.get(url);
    };
    FileCabinetService.prototype.getAssestsOfDepatment = function (dept) {
        // /api/{systemType}/assettype
        var url = this.baseUrl + "/" + dept + "/assettype";
        return this.http.get(url);
    };
    FileCabinetService.prototype.getDepartments = function () {
        var url = this.baseUrl + "/systemType";
        return this.http.get(url);
    };
    FileCabinetService.prototype.removeCustomer = function (propertyId) {
        var url = this.baseUrl + "/fileCabinet/removeCustomer/" + propertyId;
        return this.http.delete(url);
    };
    FileCabinetService.prototype.getAssignedUsers = function () {
        var url = this.baseUrl + "/user/assignedUser";
        return this.http.get(url);
    };
    FileCabinetService.prototype.getAllOwners = function (limit, offset, searchKey) {
        var url = this.baseUrl + "/owner";
        return this.http.get(url, {
            params: {
                searchString: searchKey,
                limit: limit,
                offset: offset,
            },
        });
    };
    FileCabinetService.prototype.changeOwner = function (propertyId, userId) {
        var url = this.baseUrl + "/fileCabinet/changeOwner/" + propertyId + "/" + userId;
        return this.http.post(url, null);
    };
    FileCabinetService.prototype.getActiveJurisdictions = function () {
        var url = this.baseUrl + "/jurisdiction";
        return this.http.get(url);
    };
    FileCabinetService.prototype.getAllAssetTypes = function () {
        var url = this.baseUrl + "/siteAdmin/assetTypes";
        return this.http.get(url);
    };
    FileCabinetService.prototype.updatePropertyAssignment = function (assignmentId, propertyIdList) {
        var url = this.baseUrl + "/assign/property/" + assignmentId;
        return this.http.post(url, propertyIdList);
    };
    FileCabinetService.prototype.setOwnerDetails = function (owner) {
        this.owner = owner;
    };
    FileCabinetService.prototype.getOwnerDetails = function () {
        return this.owner;
    };
    FileCabinetService.prototype.getFileCabinetHistory = function () {
        var url = this.baseUrl + "/fileCabinet/propertyActionHistory";
        return this.http.get(url);
    };
    FileCabinetService.prototype.addPropertyActionHistory = function (propertyId) {
        var url = this.baseUrl + "/fileCabinet/propertyActionHistory/" + propertyId;
        return this.http.post(url, null);
    };
    FileCabinetService.prototype.deleteAssetUnderSPA = function (propAssetId, authComments) {
        var url = this.baseUrl + "/spa/propertyAsset";
        var params = {
            propertyAssetId: propAssetId,
            authorityComments: authComments,
        };
        return this.http.delete(url, { params: params });
    };
    FileCabinetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileCabinetService_Factory() { return new FileCabinetService(i0.ɵɵinject(i1.HttpClient)); }, token: FileCabinetService, providedIn: "root" });
    return FileCabinetService;
}());
export { FileCabinetService };
