import { Component, OnInit, Inject } from '@angular/core';
import { mapDialogData, PropertyMarker } from '../map-view.component'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapViewComponent } from '../map-view.component'
declare const google: any;
@Component({
  selector: 'app-enlarge-map',
  templateUrl: './enlarge-map.component.html',
  styleUrls: ['./enlarge-map.component.scss',]
})

export class EnlargeMapComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MapViewComponent>, @Inject(MAT_DIALOG_DATA) public data: mapDialogData) { }
  propertyMarker: PropertyMarker[]; // Available Properties
  lat: number = 47.6062;   // latitude of area
  lng: number = -122.3321; // longitude of area
  map: any

  ngOnInit() {
    this.propertyMarker = this.data.properties;
    this.lat = this.data.latitude;
    this.lng = this.data.longitude;
  }


  mapReady(event: any) {
    this.map = event;
    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('close'));
  }

  close(){
    this.dialogRef.close();
  }

  setPoints(e){
    // console.log(e);
  }

}
