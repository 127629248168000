<div class="container p-0" style="max-width: 1920px;">
  <header>
    <div>
      <div class="row m-0 m-0">
        <app-header>
        </app-header>
      </div>
    </div>
  </header>


  <div class="wrapper">
    <nav id="sidebar">
      <ul>
        <li class="nav-list-item" *ngFor="let menuOption of menuOptions">
          <a [routerLink]="menuOption.route" [queryParams]="menuOption.queryParams ? menuOption.queryParams : {}" #rla="routerLinkActive" [routerLinkActiveOptions]="{exact: true}"
          routerLinkActive="active" [class.disabled]="!linksEnabled ? true : null">
          <div style="width: 30px;" class="d-flex justify-content-center">
            <img alt="tegrisfire" [src]="rla.isActive ? [menuOption.iconActive] : [menuOption.icon]" />
          </div>
          <span class="text">{{ menuOption.name }}</span>
        </a>
        </li>
      </ul>
      <!--Statistics will only show to Authority Admin-->
      <!-- 3 is Role Id of Authority Admin changing the Id for ease in Dev -->
      <div *ngIf="userRole === 'ROLE_AUTHORITY' || userRole === 'ROLE_AUTHORITY_WORX' || userRole === 'ROLE_RFA'">
        <app-authority-statistics></app-authority-statistics>
      </div>
      <div *ngIf="userRole === 'ROLE_OWNER'">
        <app-owner-statistics></app-owner-statistics>
      </div>
      <div *ngIf="userRole === 'ROLE_INSPECTOR'">
        <app-inspector-statistics></app-inspector-statistics>
      </div>
    </nav>

    <div id="navcontent" class="p-0">
      <div class="overlay"></div>

      <nav class="navbar navbar-expand-lg navbar-light bg-light p-0">
        <div class="container-fluid p-0">
          <div class="side-nav-content">
            <ng-content></ng-content>
          </div>
        </div>
      </nav>
    </div>
  </div>

  <footer>
    <div style="margin-top: 30px; margin-bottom: 30px; margin-left: 330px; margin-right: 30px;">
      <div class="p-0 m-0 row col-12 wrapper" id="footer">

        <div class="col-4 p-0 d-flex justify-content-start">
          <div (click)="openContactUs()" class="p-0 d-flex justify-content-center">
            <img alt="tegrisfire" class="example-icon" src="./assets/images/contact-2.png" />
            &nbsp;&nbsp;<span class="text">Contact Us</span>
          </div>          
        </div>

        <div class="col-8  p-0 d-flex justify-content-end">
          <div class="m-3 d-sm-none">
            <div class="d-flex justify-content-center">
              <span class="mt-3 custom-footer-text">Copyright &copy; 2017 Tegris Inc.</span>
            </div>
            <div class="d-flex">
              <span class="mt-3 custom-footer-text">All Rights Reserved</span>
            </div>
          </div>

          <div class="d-sm-block">
            <div class="d-flex">
              <span class="custom-footer-text">Copyright &copy; 2017 Tegris Inc.|All Rights Reserved</span>
            </div>
          </div>
        </div>


      </div>
    </div>
  </footer>
</div>
