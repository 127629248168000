/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/cdk/drag-drop";
import * as i4 from "@angular/common";
import * as i5 from "@angular/cdk/bidi";
import * as i6 from "./app-dialog.component";
var styles_AppDialogComponent = [i0.styles];
var RenderType_AppDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppDialogComponent, data: {} });
export { RenderType_AppDialogComponent as RenderType_AppDialogComponent };
export function View_AppDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 8, "div", [["cdkDrag", ""], ["cdkDragHandle", ""], ["cdkDragRootElement", ".cdk-overlay-pane"], ["class", "mat-dialog-title cdk-drag cdk-drag-handle"], ["mat-dialog-title", ""]], [[8, "id", 0], [2, "cdk-drag-disabled", null], [2, "cdk-drag-dragging", null]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), i1.ɵdid(2, 4866048, null, 3, i3.CdkDrag, [i1.ElementRef, [3, i3.CDK_DROP_LIST], i4.DOCUMENT, i1.NgZone, i1.ViewContainerRef, i3.CDK_DRAG_CONFIG, [2, i5.Directionality], i3.DragDrop, i1.ChangeDetectorRef], { rootElementSelector: [0, "rootElementSelector"] }, null), i1.ɵqud(603979776, 1, { _handles: 1 }), i1.ɵqud(603979776, 2, { _previewTemplate: 0 }), i1.ɵqud(603979776, 3, { _placeholderTemplate: 0 }), i1.ɵprd(2048, null, i3.ɵb, null, [i3.CdkDrag]), i1.ɵdid(7, 147456, [[1, 4]], 0, i3.CdkDragHandle, [i1.ElementRef, [2, i3.ɵb]], null, null), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i2.MatDialogContent, [], null, null), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(13, 16384, null, 0, i2.MatDialogActions, [], null, null), i1.ɵncd(null, 2)], function (_ck, _v) { _ck(_v, 1, 0); var currVal_3 = ".cdk-overlay-pane"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; var currVal_1 = i1.ɵnov(_v, 2).disabled; var currVal_2 = i1.ɵnov(_v, 2)._dragRef.isDragging(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_AppDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog", [], null, null, null, View_AppDialogComponent_0, RenderType_AppDialogComponent)), i1.ɵdid(1, 114688, null, 0, i6.AppDialogComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppDialogComponentNgFactory = i1.ɵccf("app-dialog", i6.AppDialogComponent, View_AppDialogComponent_Host_0, {}, {}, [".dialog-title", ".dialog-content", ".dialog-actions"]);
export { AppDialogComponentNgFactory as AppDialogComponentNgFactory };
