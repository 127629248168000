import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import * as FileSaver from "file-saver";
import { PropertyMarker } from "../models/property/property.model";
import { EnlargeMapComponent } from "../../shared/components/map-view/enlarge-map/enlarge-map.component";
import { VerifyPacComponent } from "../../inspection/inspection-properties/verify-pac/verify-pac.component";
import { CertifyBypassComponent } from "../../inspection/inspection-properties/certify-bypass/certify-bypass.component";
import * as fs from "file-saver";
import * as Excel from "exceljs/dist/exceljs.min.js";
import { Roles, UserDetails } from "../../shared/models/user.model";
import { InspectionService } from "./inspection/inspection.service";
import { ViewDeficiencyDialogComponent } from "../../inspection/view-deficiency-dialog/view-deficiency-dialog.component";
import { Workbook } from "exceljs";

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  EXCEL_EXTENSION = ".xlsx";
  _columns: Array<any>;
  startTime: number;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private inspectionService: InspectionService
  ) {}

  openEnlargeMap(propertyMarker: PropertyMarker[]) {
    let lat = 47.6062;
    let lng = -122.3321;

    if (propertyMarker && propertyMarker.length > 0) {
      lat = propertyMarker[0].lat;
      lng = propertyMarker[0].lng;
    }

    const dialogRef = this.dialog.open(EnlargeMapComponent, {
      height: "840px",
      minWidth: "350px",
      width: "1500px",
      data: {
        properties: propertyMarker,
        latitude: lat,
        longitude: lng,
      },
    });
  }

  setPropertyOnMap(propertyList) {
    const propertyMarker: PropertyMarker[] = [];
    for (const property of propertyList) {
      if (
        property &&
        property.latitude !== null &&
        property.longitude !== null
      ) {
        propertyMarker.push({
          propertyId: property.id,
          lat: property.latitude,
          lng: property.longitude,
          label: property.buildingName || property.propertyName,
        });
      }
    }
    return propertyMarker;
  }

  setPropertyAndSystemOnMap(property, systems) {
    const propertyMarker: PropertyMarker[] = [];
    if (property && property.latitude !== null && property.longitude !== null) {
      propertyMarker.push({
        propertyId: property.id,
        lat: property.latitude,
        lng: property.longitude,
        label: property.buildingName || property.propertyName,
      });
    }
    for (let i = 0; i < systems.length && propertyMarker.length < 10; ++i) {
      if (systems[i].latitude !== null && systems[i].longitude !== null) {
        let propName = "";
        let propId = "";
        if (property) {
          propName = property.buildingName || property.propertyName;
          propId = property.id && property.id.toString();
          if (propName && propName.length > 0) {
            propName = propName + " - ";
          }
        }
        propertyMarker.push({
          lat: systems[i].latitude,
          lng: systems[i].longitude,
          label: propName + systems[i].assetType,
          propertyId: propId,
          propertyAssetId: systems[i].id.toString(),
        });
      }
    }
    return propertyMarker;
  }

  navigateToInspectionPropertyAsset(
    pac: string = "0",
    propertyId: any,
    isPACSearch: boolean = false,
    fileCabinetRedirect: boolean = false,
    isSimplifiedReportEnabled: boolean,
    isImageUploadEnabled: boolean,
    jurisIDs,
    userRoleID?: any
  ) {
    if (userRoleID != 9 && (!pac || pac.length === 0 || isPACSearch)) {
      const dialogRef = this.dialog.open(VerifyPacComponent, {
        width: "800px",
        minWidth: "350px",
        data: {
          PropertyId: propertyId,
          pac: pac === "0" ? "" : pac,
          jurisIDs,
        },
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === "cancelled") {
        } else if (result === "bypass") {
          const dialogCertifyRef = this.dialog.open(CertifyBypassComponent, {
            width: "800px",
            height: "500px",
            minWidth: "350px",
            disableClose: true,
          });
          dialogCertifyRef.afterClosed().subscribe((certified) => {
            if (certified) {
              if (fileCabinetRedirect) {
                this.router.navigate(
                  [
                    "user/inspection/searchInspectionProperties/getInspectionAssetsByProperty",
                  ],
                  {
                    queryParams: {
                      id: propertyId.toString(),
                      pac: "0",
                      fileCabinetRedirect,
                      isSimplifiedReportEnabled,
                      isImageUploadEnabled,
                      jurisIDs,
                    },
                  }
                );
              } else {
                this.router.navigate(
                  [
                    "user/inspection/searchInspectionProperties/getInspectionAssetsByProperty",
                  ],
                  {
                    queryParams: {
                      id: propertyId.toString(),
                      pac: "0",
                      isSimplifiedReportEnabled,
                      isImageUploadEnabled,
                      jurisIDs,
                    },
                  }
                );
              }
            }
          });
          // this.router.navigate(['getInspectionAssetsByProperty'], { queryParams: { id: property.propertyId, pac: '0' } });
          // 2066 TODO: Remove if not required at all
          // } else if (result === "navigate") {
          //   const user: UserDetails = JSON.parse(
          //     localStorage.getItem("userDetails")
          //   );
          //   if (user.role.id === Roles.ROLE_OWNER_ADMIN) {
          //     this.router.navigate(["user/owner/dashboard"]);
          //   } else if (
          //     user.role.id === Roles.ROLE_AUTHORITY_ADMIN ||
          //     user.role.id === Roles.ROLE_AUTHORITY_WORX
          //   ) {
          //     this.router.navigate(["user/authority/dashboardAuthority"]);
          //   } else if (user.role.id === Roles.ROLE_INSPECTOR_ADMIN) {
          //     this.router.navigate(["user/inspector/dashboardInspector"]);
          //   } else if (user.role.id === Roles.ROLE_RFA) {
          //     this.router.navigate(["user/authority/dashboardAuthority"]);
          //   } else if (user.role.id === Roles.ROLE_SITE_ADMIN) {
          //     this.router.navigate(["user/admin/dashboardSiteAdmin"]);
          //   }
        } else {
          if (fileCabinetRedirect) {
            this.router.navigate(
              [
                "user/inspection/searchInspectionProperties/getInspectionAssetsByProperty",
              ],
              {
                queryParams: {
                  id: propertyId,
                  pac: result,
                  fileCabinetRedirect,
                  isSimplifiedReportEnabled,
                  isImageUploadEnabled,
                  jurisIDs,
                },
              }
            );
          } else {
            this.router.navigate(
              [
                "user/inspection/searchInspectionProperties/getInspectionAssetsByProperty",
              ],
              {
                queryParams: {
                  id: propertyId,
                  pac: result,
                  isSimplifiedReportEnabled,
                  isImageUploadEnabled,
                  jurisIDs,
                },
              }
            );
          }
        }
      });
    } else {
      if (userRoleID === 9) {
        pac = "0";
      }
      this.router.navigate(
        [
          "user/inspection/searchInspectionProperties/getInspectionAssetsByProperty",
        ],
        {
          queryParams: {
            id: propertyId,
            pac,
            fileCabinetRedirect,
            isSimplifiedReportEnabled,
            isImageUploadEnabled,
          },
        }
      );
    }
  }

  eachColumnInRange(ws, col1, col2, cb) {
    for (let c = col1; c <= col2; c++) {
      let col = ws.getColumn(c);
      cb(col);
    }
  }

  autofitColumns(ws) {
    // no good way to get text widths
    this.eachColumnInRange(ws, 1, ws.columnCount, (column) => {
      let maxWidth = 15;
      column.eachCell((cell) => {
        if (!cell.isMerged && cell.value) {
          // doesn't handle merged cells

          let text = "";
          if (typeof cell.value != "object") {
            // string, number, ...
            text = cell.value.toString();
          } else if (cell.value.richText) {
            // richText
            text = cell.value.richText.reduce(
              (text, obj) => text + obj.text.toString(),
              ""
            );
          }

          // handle new lines -> don't forget to set wrapText: true
          let values = text.split(/[\n\r]+/);

          for (let value of values) {
            let width = value.length;

            // if (cell.font && cell.font.bold) {
            //   width *= 1.08; // bolding increases width
            // }

            maxWidth = Math.max(maxWidth, width);
          }
        }
      });

      maxWidth += 0.71; // compensate for observed reduction
      maxWidth += 1; // buffer space

      column.width = maxWidth;
    });
  }

  // columnWebWorker(headerArray) {
  //   const worker = new InlineWorker(() => {
  //     // START OF WORKER THREAD CODE
  //     console.log("Start worker thread, wait for postMessage: ");

  //     const calculateCountOfPrimeNumbers = (headerArray) => {
  //       const columns = [];

  //       headerArray.map((key) => {
  //         if (key.toLowerCase().indexOf("date") !== -1) {
  //           columns.push({
  //             header: key,
  //             key: key,
  //             width: key.length < 12 ? 13 : key.length + 3,
  //             style: { numFmt: "mm-dd-yyyy" },
  //           });
  //         } else {
  //           columns.push({
  //             header: key,
  //             key: key,
  //             // width: key.length < 12 ? 13 : key.length + 3
  //           });
  //         }
  //       });

  //       // this is from DedicatedWorkerGlobalScope ( because of that we have postMessage and onmessage methods )
  //       // and it can't see methods of this class
  //       // @ts-ignore
  //       postMessage({
  //         columns: columns,
  //       });
  //     };

  //     // @ts-ignore
  //     onmessage = (evt) => {
  //       console.log("Calculation started: " + new Date());
  //       console.log(evt.data);

  //       calculateCountOfPrimeNumbers(evt.data.headerArray);
  //     };
  //     // END OF WORKER THREAD CODE
  //   });

  //   worker.postMessage({ headerArray: headerArray });

  //   worker.onmessage().subscribe((data) => {
  //     console.log("Calculation done: ", new Date() + " " + data.data);
  //     this._columns = data.data.columns;
  //     worker.terminate();
  //   });

  //   worker.onerror().subscribe((data) => {
  //     console.log(data);
  //   });
  // }

  chunkIteration(arrayToChunk, index, lengthOfChunk, operationFunc, resolve) {
    var j,
      l = arrayToChunk.length;

    for (j = index + lengthOfChunk; index < j && index < l; index += 1) {
      operationFunc(arrayToChunk[index]);
    }

    if (l > index) {
      setTimeout(() => {
        this.chunkIteration(
          arrayToChunk,
          index,
          lengthOfChunk,
          operationFunc,
          resolve
        );
      }, 10);
    } else {
      resolve();
    }
  }

  async exportAsExcelFile(reports: any, fileName: string) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("Data");

    const headerArray = Object.keys(reports[0]);
    const columns = [];
    for (const key of headerArray) {
      if (key.toLowerCase().indexOf("date") !== -1) {
        columns.push({
          header: key,
          key: key,
          width: key.length < 12 ? 13 : key.length + 3,
          style: { numFmt: "mm-dd-yyyy" },
        });
      } else {
        columns.push({
          header: key,
          key: key,
          // width: key.length < 12 ? 13 : key.length + 3
        });
      }
    }
    // await this.columnWebWorker(headerArray);
    // const columns = this._columns;

    worksheet.columns = columns;
    worksheet.addRows(reports);
    for (const [i, column] of columns.entries()) {
      if (
        column.key !== column.key.toUpperCase() &&
        column.key.indexOf("_") === -1
      ) {
        // if (column.key.toLowerCase().indexOf("date") !== -1) {
        //   worksheet.getRow(1).getCell(i + 1).numFmt = "mm-dd-yyyy";
        //   worksheet.getRow(1).getCell(i + 1).alignment = {
        //     wrapText: false,
        //     vertical: "middle",
        //     horizontal: "left",
        //   };
        // }
        worksheet.getRow(1).getCell(i + 1).value = this.returnKeyAsWord(
          column.key
        );
      }
    }
    worksheet.getRow(1).fill = {
      type: "gradient",
      gradient: "path",
      center: { left: 0.5, top: 0.5 },
      stops: [
        { position: 0, color: { argb: "FFBBBBBB" } },
        { position: 1, color: { argb: "FFBBBBBB" } },
      ],
    };
    worksheet.getRow(1).font = { bold: true };

    this.autofitColumns(worksheet);
    this.startTime = performance.now();
    await workbook.xlsx.writeBuffer().then(async (data) => {
      console.log("1 - ", performance.now() - this.startTime);

      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      await fs.saveAs(
        blob,
        `${fileName}_export_${new Date().getTime()}${this.EXCEL_EXTENSION}`
      );
      console.log("2 - ", performance.now() - this.startTime);

      // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(reports);
      // const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      // this.saveAsExcelFile(excelBuffer, fileName);
    });
    const duration = performance.now() - this.startTime;
    console.log(`someMethodIThinkMightBeSlow took ${duration}ms`);
  }

  // saveAsExcelFile(buffer: any, fileName: string): void {
  //   const data: Blob = new Blob([buffer], { type: this.EXCEL_TYPE });
  //   FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + this.EXCEL_EXTENSION);
  // }

  returnKeyAsWord(key) {
    return (
      key[0].toUpperCase() +
      key
        .substring(1)
        .replace(/([(A-Z)])/g, " $1")
        .replace(/ +/g, " ")
        .trim()
    );
  }

  openDeficiencyPopup(
    property,
    assetId,
    oldAssetId,
    name,
    searchPAC = undefined,
    reportNumber = undefined
  ) {
    let id = -1;
    if (name === "Shaft Pressurization") {
      id = 13;
    }
    if (name === "Standpipe") {
      id = 6;
    }
    if (name === "Clean agent") {
      id = 10;
    }
    this.inspectionService.getDateInfo(assetId).subscribe(
      (resp) => {
        let deficiencies = resp["inspectionDeficiency"];
        const authorityRemarks = resp["authorityReviewRemarks"];
        const inspectorRemarks = resp["inspectionRemarks"];
        if (
          resp &&
          resp.propertyAsset &&
          resp.propertyAsset.inspectionStatus &&
          resp.propertyAsset.inspectionStatus.description !== null &&
          resp.propertyAsset.inspectionStatus.hasOwnProperty("description") &&
          resp.propertyAsset.inspectionStatus.description ===
            "Awaiting Re-Submittal"
        ) {
          deficiencies = [];
        }
        if (deficiencies && deficiencies.length > 0) {
          const dialogRef = this.dialog.open(ViewDeficiencyDialogComponent, {
            width: "1400px",
            minWidth: "350px",
            disableClose: true,
            autoFocus: false,
            data: {
              assetId,
              propertyId: property,
              oldAssetId,
              id,
              deficiencies,
              authorityRemarks,
              inspectorRemarks,
              searchPAC,
              reportNumber,
            },
          });
          dialogRef.afterClosed().subscribe((res) => {
            if (res === "update") {
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            }
          });
        } else {
          const dialogRef = this.dialog.open(ViewDeficiencyDialogComponent, {
            width: "500px",
            minWidth: "350px",
            disableClose: true,
            autoFocus: false,
            data: {
              assetId,
              propertyId: property,
              oldAssetId,
              id,
              deficiencies,
              authorityRemarks,
              inspectorRemarks,
              searchPAC,
              reportNumber,
            },
          });
        }
      },
      (error) => {
        alert(error.error.message);
      }
    );
  }

  downloadImage(image) {
    const fileURL = image,
      fileName = image.split("/").pop();
    // for non-IE
    if (!(window as any).ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      save.download = fileName || "unknown";

      var evt = new MouseEvent("click", {
        view: window as any,
        bubbles: true,
        cancelable: false,
      });
      save.dispatchEvent(evt);

      ((window as any).URL || (window as any).webkitURL).revokeObjectURL(
        save.href
      );
    }

    // for IE < 11
    else if (!!(window as any).ActiveXObject && document.execCommand) {
      var _window = (window as any).open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
  }

  openDeficiencyPopup1(
    property,
    assetId,
    oldAssetId,
    name,
    limit,
    offset,
    selectedAssetId,
    searchPAC,
    reportNumber,
  ) {
    let id = -1;
    if (name === "Shaft Pressurization") {
      id = 13;
    }
    if (name === "Standpipe") {
      id = 6;
    }
    if (name === "Clean agent") {
      id = 10;
    }
    this.inspectionService.getDateInfo(assetId).subscribe(
      (resp) => {
        let deficiencies = resp["inspectionDeficiency"];
        const authorityRemarks = resp["authorityReviewRemarks"];
        const inspectorRemarks = resp["inspectionRemarks"];
        if (
          resp &&
          resp.propertyAsset &&
          resp.propertyAsset.inspectionStatus &&
          resp.propertyAsset.inspectionStatus.description !== null &&
          resp.propertyAsset.inspectionStatus.hasOwnProperty("description") &&
          resp.propertyAsset.inspectionStatus.description ===
            "Awaiting Re-Submittal"
        ) {
          deficiencies = [];
        }
        if (deficiencies && deficiencies.length > 0) {
          const dialogRef = this.dialog.open(ViewDeficiencyDialogComponent, {
            width: "1400px",
            minWidth: "350px",
            disableClose: true,
            autoFocus: false,
            data: {
              assetId,
              propertyId: property,
              oldAssetId,
              id,
              deficiencies,
              authorityRemarks,
              inspectorRemarks,
              searchPAC,
              reportNumber,
              limit,
              offset,
              selectedAssetId,
            },
          });
          dialogRef.afterClosed().subscribe((res) => {
            if (res === "update") {
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            }
          });
        } else {
          const dialogRef = this.dialog.open(ViewDeficiencyDialogComponent, {
            width: "500px",
            minWidth: "350px",
            disableClose: true,
            autoFocus: false,
            data: {
              assetId,
              propertyId: property,
              oldAssetId,
              id,
              deficiencies,
              authorityRemarks,
              inspectorRemarks,
              searchPAC,
              reportNumber,
              limit,
              offset,
              selectedAssetId
            },
          });
        }
      },
      (error) => {
        alert(error.error.message);
      }
    );
  }
 exportAsExcle1(fromDate, toDate, res, authorityName) {


  const data = res.metricCount.map(object => {
    return {...object, ats: ''};
  });

  let workbook = new Excel.Workbook();
  let worksheet = workbook.addWorksheet('Data');
  worksheet.columns = [
    {header: 'Assembly Test Summary', key: 'ats', width: 30,},
    { header: '', key: 'RPBA', width: 10,style: { font: { bold  : true} } },
    { header: '', key: 'DCVA', width: 10, style: { font: { bold  : true} } },
    { header: '', key: 'PVBA', width: 10, style: { font: { bold  : true} } },
    { header: '', key: 'Other', width: 10, style: { font: { bold  : true} } },
    { header: '', key: 'Total', width: 10, style: { font: { bold  : true} } }
  ];


  row = worksheet.getRow(1);
  row.getCell("A").font = {  bold: true, italic  : 'true' };

  row = worksheet.getRow(1);
  row.getCell("F").value = authorityName;
  row.getCell("F").font = { with: 50, size: 10, bold: true,  italic  : true };



  row = worksheet.getRow(2);
  row.getCell("A").value = fromDate.replaceAll("-","/") + ' - ' + toDate.replaceAll("-","/");
  row.getCell("A").font = { size: 10, bold: true, italic: 'true' };

  //worksheet.getCell('B1').alignment = { vertical: 'middle', horizontal: 'center' };


  var row = worksheet.addRow([], "n");
  worksheet.addRow({RPBA: 'RPBA', DCVA: 'DCVA', PVBA: 'PVBA', Other: 'Other', Total: 'TOTAL'},"n");
  worksheet.addRow({ats: 'All Assemblies'},"n");


  row = worksheet.getRow(5);
  row.getCell("A").font = { size: 10, bold: true, underline: 'single'};

  row = worksheet.getRow(4);
  row.getCell("A").border = { top: {style:'thin'},left: {style:'thin'},bottom: {style:'thin'}};
  row.getCell("B").alignment = { vertical: 'middle', horizontal: 'center' };
  row.getCell("B").border = { top: {style:'thin'},bottom: {style:'thin'}};
  row.getCell("C").alignment = { vertical: 'middle', horizontal: 'center' };
  row.getCell("C").border = { top: {style:'thin'},bottom: {style:'thin'}};
  row.getCell("D").alignment = { vertical: 'middle', horizontal: 'center' };
  row.getCell("D").border = { top: {style:'thin'},bottom: {style:'thin'}};
  row.getCell("E").alignment = { vertical: 'middle', horizontal: 'center' };
  row.getCell("E").border = { top: {style:'thin'},bottom: {style:'thin'}};
  row.getCell("F").alignment = { vertical: 'middle', horizontal: 'center' };
  row.getCell("F").border = { top: {style:'thin'},bottom: {style:'thin'},right: {style:'thin'}};

  data.forEach(e => {
    worksheet.addRow({ats: e.ats, RPBA: e.rpba, DCVA: e.dcva, PVBA:e.pvba, Other:e.other, Total:e.total },"n");
  });
  row = worksheet.getRow(6);
  row.getCell("A").value = "Number of Assemblies";
  row = worksheet.getRow(7);
  row.getCell("A").value = "Number of Tests Completed";
  row = worksheet.getRow(8);
  row.getCell("A").value = "Number of Passes";
  row = worksheet.getRow(9);
  row.getCell("A").value = "Number of Failures";
  row = worksheet.getRow(10);
  row.getCell("A").value = "Number of New Installations";

  let Row6= worksheet.getRow(6)
  Row6.eachCell(cell => {
   if(cell.value == null) {
    cell.value = 0;
   }
  });

  let Row7= worksheet.getRow(7)
  Row7.eachCell(cell => {
   if(cell.value == null) {
    cell.value = 0;
   }
  });





  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, `WorX Annual Report Export_${new Date().getTime()}${this.EXCEL_EXTENSION}`);
  });
 }
}




