import { NgZone } from '@angular/core';
import { environment } from '@environment/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * LoggerService
 * @description Service to handle all the logging levels.
 * You can also write custom logger methods in this service
 */
var LoggerService = /** @class */ (function () {
    /**
     * Constructor of the Service.
     * Configure the logger methods
     */
    function LoggerService(http, ngZone) {
        this.http = http;
        this.ngZone = ngZone;
        /**
         * method to log.
         * @param message javascript object
         * @param optionalParams A list of JavaScript objects to output
         */
        this.baseUrl = environment.baseUrl;
        this.configureLogger();
        var url = this.baseUrl + "/setPreference";
    }
    /**
     * Setting the logger configuration based on environment
     */
    LoggerService.prototype.configureLogger = function () {
        var _this = this;
        if (!environment.production) {
            /**
             * By default, logger methods are bind to the console methods
             * You can also implement your custom logger methods here
             */
            this.log = console.log.bind(console);
            // tslint:disable-next-line:no-console
            this.debug = console.debug.bind(console);
            // tslint:disable-next-line:no-console
            this.info = console.info.bind(console);
            this.warn = console.warn.bind(console);
            this.error = console.error.bind(console);
            // tslint:disable-next-line:no-console
            this.time = console.time.bind(console);
            // tslint:disable-next-line:no-console
            this.timeEnd = console.timeEnd.bind(console);
            this.logToServer = this.error;
        }
        else {
            // In case of production replace the functions definition
            this.log = this.debug = this.info = this.warn = this.error = this.time = this.timeEnd = function () { };
            this.logToServer = function (err) {
                /** temp added to print in the console during production */
                // console.error(err);
                _this.pushToServer(err);
                /** TODO: Integrate API for logging to server */
            };
        }
    };
    LoggerService.prototype.pushToServer = function (e) {
        var _this = this;
        var a = e;
        if (typeof e !== 'string') {
            a = JSON.stringify(e);
        }
        this.ngZone.run(function () {
            _this.http.post(_this.baseUrl + '/log', [a]).subscribe();
        });
    };
    LoggerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoggerService_Factory() { return new LoggerService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i0.NgZone)); }, token: LoggerService, providedIn: "root" });
    return LoggerService;
}());
export { LoggerService };
