import { Component, OnInit, Inject } from '@angular/core';
import { EnterInspectionComponent } from '../enter-inspection.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PropertyService } from '../../../core/services/property/property.service';
import { PropertyDetails } from '../../../core/models/property/property.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-property-dialog',
  templateUrl: './edit-property-dialog.component.html',
  styleUrls: ['./edit-property-dialog.component.scss']
})
export class EditPropertyDialogComponent implements OnInit {
  propDetails: PropertyDetails;
  managerAddress: string;
  propertyIdForEdit: any;
  constructor(public dialogRef: MatDialogRef<EnterInspectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private propService: PropertyService, private route: ActivatedRoute) { }
  isEditDetail: string;
  ngOnInit() {
    this.isEditDetail = 'Edit';
    this.propDetails = {};
    this.propertyIdForEdit = this.data.id;
    this.propService.getPropertyById(this.data.id).subscribe((res) => {
      if (res) {
        this.propDetails = res;
        if (this.propDetails && this.propDetails.propertyContacts && this.propDetails.propertyContacts[0]) {
          this.managerAddress = this.propDetails.propertyContacts[0].streetLine1;
          if (this.propDetails.propertyContacts[0].streetLine2) {
            this.managerAddress += this.propDetails.propertyContacts[0].streetLine2;
          }
          this.managerAddress += ` ${this.propDetails.propertyContacts[0].zipcode.city}
           ${this.propDetails.propertyContacts[0].zipcode.state}, ${this.propDetails.propertyContacts[0].zipcode.zipcode}`;

        }
      }
    });

  }
  close() {
    this.dialogRef.close();
  }

}
