import * as i0 from "@angular/core";
import * as i1 from "./user.service";
import * as i2 from "@angular/common";
var AuthorityInspectorGuard = /** @class */ (function () {
    function AuthorityInspectorGuard(userService, location) {
        this.userService = userService;
        this.location = location;
    }
    AuthorityInspectorGuard.prototype.canActivate = function () {
        var a = this.userService.userDetails.role.name === 'ROLE_AUTHORITY' || this.userService.userDetails.role.name === 'ROLE_INSPECTOR'
            || this.userService.userDetails.role.name === 'ROLE_RFA' || this.userService.userDetails.role.name === 'ROLE_AUTHORITY_WORX';
        if (a) {
            return a;
        }
        this.location.back();
        return a;
    };
    AuthorityInspectorGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthorityInspectorGuard_Factory() { return new AuthorityInspectorGuard(i0.ɵɵinject(i1.UserService), i0.ɵɵinject(i2.Location)); }, token: AuthorityInspectorGuard, providedIn: "root" });
    return AuthorityInspectorGuard;
}());
export { AuthorityInspectorGuard };
