import { Component, OnInit } from '@angular/core';
import { StatisticsService } from '../../../services/statistics.service';
import { AuthorityStatistics } from '../../../models/statistics.model';
import { LoggerService } from '../../../../../core/services/logger/logger.service';
import { AuthorityService } from '../../../../../authority/shared/services/authority.service';
import { mergeMap, map } from 'rxjs/operators';

@Component({
  selector: 'app-authority-statistics',
  templateUrl: './authority-statistics.component.html',
  styleUrls: ['./authority-statistics.component.scss']
})

export class AuthorityStatisticsComponent implements OnInit {
  rfaLoggedIn: boolean;

  constructor(
    private statisticsService: StatisticsService,
    private loggerService: LoggerService, private authorityService: AuthorityService) { }
  authorityStatistics: AuthorityStatistics;
  ngOnInit() {

    const userRoleID = JSON.parse(localStorage.getItem('userDetails')).role.id;
    if (userRoleID === 8) {
      this.rfaLoggedIn = true;
    } else {
      this.rfaLoggedIn = false;
    }

    if (this.rfaLoggedIn) {
      this.statisticsService.jurisId$.subscribe((res) => {
        if (res) {
          this.statisticsService.getRFAAuthorityStatistics(res).subscribe((innerRes) => {
            this.authorityStatistics = innerRes;
            // 1944 - bug fix
            // this.authorityStatistics.generalCompliancePercent =
            //   parseFloat((+this.authorityStatistics.generalCompliancePercent).toFixed(2)).toString() + '%';            
          }, (error) => {
            this.loggerService.log(error);
            this.authorityStatistics = {
              generalCompliancePercent: '',
              propDueInNext30Days: 0,
              totalActiveProperties: 0,
              totalActiveSystem: 0,
              totalInspectionCompanies: 0
            };
          });
        } else {
          const userDetails = JSON.parse(localStorage.getItem('userDetails'));
          if (res === null && (userDetails === undefined || userDetails === null)) {
            return;
          }
          this.authorityService.getRFAJurisdication().pipe(map(y => {
            if (y) {
              return y.map((x) => x.id);
            }
          }), mergeMap((jurisIds) => {
            return this.statisticsService.getRFAAuthorityStatistics(jurisIds);
          })).subscribe(response => {
            this.authorityStatistics = response;
          }, (error) => {
            this.loggerService.log(error);
            this.authorityStatistics = {
              generalCompliancePercent: '',
              propDueInNext30Days: 0,
              totalActiveProperties: 0,
              totalActiveSystem: 0,
              totalInspectionCompanies: 0
            };
          });
        }
      });
    }

    if (!this.rfaLoggedIn) {
      this.authorityStatistics = {};

      this.statisticsService.getAuthorityStatistics().subscribe((res) => {
        this.authorityStatistics = res;
      }, (error) => {
        this.loggerService.log(error);
        this.authorityStatistics = {
          generalCompliancePercent: '',
          propDueInNext30Days: 0,
          totalActiveProperties: 0,
          totalActiveSystem: 0,
          totalInspectionCompanies: 0
        };
      });
    }
  }

}
