import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from '@angular/material';
import { InspectionService } from '../../core/services/inspection/inspection.service';

@Component({
  selector: 'app-payment-details-dialog',
  templateUrl: './payment-details-dialog.component.html',
  styleUrls: []
})
export class PaymentDetailsDialogComponent implements OnInit {

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  displayedColumns = ['blank', 'systemType', 'location'];
  propertyAssetTotal: any = {};
  unsub: any
  total: number = 0
  constructor(
    public dialogRef: MatDialogRef<PaymentDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private inspectionService: InspectionService
  ) { }

  ngOnInit() {
    this.unsub = this.inspectionService.getPaymentDetailsById(this.data.join(','))
      .subscribe((res) => {
        this.dataSource.data = res;
        this.calculateTotal(res);
      })
  }

  ngOnDestroy() {
    this.unsub.unsubscribe();
  }

  calculateTotal(list) {
    list.forEach(res => {
      let total = 0;
      res.priceList.sort((a, b) => a.order - b.order);
      const groupedPriceList = {};

      res.priceList = res.priceList.filter(dat => dat.value || dat.description === 'Grand Total');

      (res.priceList || []).forEach(data => {
        if (!groupedPriceList[data.heading]) {
          groupedPriceList[data.heading] = [];
        }
        groupedPriceList[data.heading].push(data);
        total += (data.value || 0);

      });
      res.groupedPriceList = Object.keys(groupedPriceList).map(data => groupedPriceList[data]);

      total += res.techSupportFee || 0;
      this.propertyAssetTotal[res.id] = total;

      this.total += total;

    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
