var UserDetails = /** @class */ (function () {
    function UserDetails() {
    }
    return UserDetails;
}());
export { UserDetails };
var PasswordChange = /** @class */ (function () {
    function PasswordChange() {
    }
    return PasswordChange;
}());
export { PasswordChange };
var User = /** @class */ (function () {
    function User() {
    }
    return User;
}());
export { User };
var UserRole = /** @class */ (function () {
    function UserRole() {
    }
    return UserRole;
}());
export { UserRole };
var MenuOption = /** @class */ (function () {
    function MenuOption() {
    }
    return MenuOption;
}());
export { MenuOption };
export var Roles;
(function (Roles) {
    Roles[Roles["ROLE_SITE_ADMIN"] = 1] = "ROLE_SITE_ADMIN";
    Roles[Roles["ROLE_OWNER_ADMIN"] = 2] = "ROLE_OWNER_ADMIN";
    Roles[Roles["ROLE_AUTHORITY_ADMIN"] = 3] = "ROLE_AUTHORITY_ADMIN";
    Roles[Roles["ROLE_INSPECTOR_ADMIN"] = 4] = "ROLE_INSPECTOR_ADMIN";
    Roles[Roles["ROLE_OWNER_USER"] = 5] = "ROLE_OWNER_USER";
    Roles[Roles["ROLE_AUTHORITY_USER"] = 6] = "ROLE_AUTHORITY_USER";
    Roles[Roles["ROLE_INSPECTOR_USER"] = 7] = "ROLE_INSPECTOR_USER";
    Roles[Roles["ROLE_RFA"] = 8] = "ROLE_RFA";
    Roles[Roles["ROLE_AUTHORITY_WORX"] = 9] = "ROLE_AUTHORITY_WORX";
})(Roles || (Roles = {}));
var Notification = /** @class */ (function () {
    function Notification() {
    }
    return Notification;
}());
export { Notification };
var Owner = /** @class */ (function () {
    function Owner() {
    }
    return Owner;
}());
export { Owner };
