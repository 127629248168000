import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { MatDialogRef, MatTableDataSource, } from "@angular/material";
var AdditionalCertificateComponent = /** @class */ (function () {
    function AdditionalCertificateComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.columnsToDisplay = [
            "certificationName",
            "certificationNumber",
            "otherCertExpiryDate",
        ];
        this.err = {};
        this.editMode = false;
    }
    AdditionalCertificateComponent.prototype.ngOnInit = function () {
        this.editMode = this.data.editMode ? true : false;
        var certificateData = this.data.certificateList && this.data.certificateList.length > 0
            ? JSON.parse(JSON.stringify(this.data.certificateList))
            : [];
        if (this.data.editMode) {
            this.columnsToDisplay.push("actions");
        }
        certificateData.forEach(function (data) {
            data.certificationName = data.certificationName
                ? data.certificationName
                : "";
            data.certificationNumber = data.certificationNumber
                ? data.certificationNumber
                : "";
            data.otherCertExpiryDate = data.otherCertExpiryDate
                ? data.otherCertExpiryDate
                : "";
            data.otherCertImage = data.otherCertImage ? data.otherCertImage : "";
            var fileNameArr = data.otherCertImage ? data.otherCertImage.split('/') : [];
            data.certFileName = data.certFileName ? data.certFileName : fileNameArr.length > 0 ? fileNameArr[fileNameArr.length - 1] : "Required – Use Arrow to Upload Certificate";
        });
        this.certificates = new MatTableDataSource(certificateData);
    };
    AdditionalCertificateComponent.prototype.hasErrorInDate = function (error, index, key) {
        if (!this.err[key]) {
            this.err[key] = {};
        }
        this.err[key][index] = error;
    };
    AdditionalCertificateComponent.prototype.AddNewCertificate = function () {
        if (this.certificates.data.length < 6) {
            var data = JSON.parse(JSON.stringify(this.certificates.data));
            data.unshift({
                id: null,
                certificationName: "",
                certificationNumber: "",
                otherCertExpiryDate: "",
                otherCertImage: "",
                sequence: 0,
                certFileName: ""
            });
            this.certificates = new MatTableDataSource(data);
        }
        else {
            alert("You are not allowed to add more than 6 additional certificates");
        }
    };
    AdditionalCertificateComponent.prototype.setBase64OtherCertificateImage = function (event, index) {
        var file = event.target.files[0];
        var extIndex = file.name.lastIndexOf(".");
        var fileExt = file.name.substring(extIndex + 1).toLowerCase();
        if (!(fileExt === "jpeg" ||
            fileExt === "jpg" ||
            fileExt === "pjpeg" ||
            fileExt === "pjp" ||
            fileExt === "gif" ||
            fileExt === "jfif" ||
            fileExt === "png" ||
            fileExt === "tiff" ||
            fileExt === "pdf")) {
            alert("Allowed File Formats are JPEG, PNG, PDF, GIF, TIFF");
        }
        else if (file.size > 2 * 1024 * 1024) {
            alert("Max size allowed is 2 MB");
        }
        else {
            var reader_1 = new FileReader();
            reader_1.readAsDataURL(file);
            var that_1 = this;
            reader_1.onload = function () {
                var data = JSON.parse(JSON.stringify(that_1.certificates.data));
                data[index].otherCertImage = reader_1.result;
                data[index].certFileName = file.name;
                that_1.certificates = new MatTableDataSource(data);
            };
            reader_1.onerror = function (error) {
                console.log("Error: ", error);
            };
        }
    };
    AdditionalCertificateComponent.prototype.DeleteCertificate = function (id, index) {
        var data = JSON.parse(JSON.stringify(this.certificates.data));
        data.splice(index, 1);
        this.certificates = new MatTableDataSource(data);
    };
    AdditionalCertificateComponent.prototype.close = function () {
        var data = JSON.parse(JSON.stringify(this.certificates.data));
        if (this.errorInform(data)) {
            alert("Enter all the required information");
            return;
        }
        this.dialogRef.close(data);
    };
    AdditionalCertificateComponent.prototype.errorInform = function (form) {
        var e_1, _a, e_2, _b;
        if (!this.editMode || form.length === 0)
            return false;
        // if error in date
        if (this.err && this.err.otherCertExpiryDate) {
            try {
                for (var _c = tslib_1.__values(Object.entries(this.err.otherCertExpiryDate)), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var _e = tslib_1.__read(_d.value, 2), key = _e[0], value = _e[1];
                    if (value.error && value.error !== 'Enter Expiration Date') {
                        console.log("Validation #1");
                        return true;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        var IsError = false;
        try {
            for (var _f = tslib_1.__values(form.entries()), _g = _f.next(); !_g.done; _g = _f.next()) {
                var _h = tslib_1.__read(_g.value, 2), i = _h[0], o = _h[1];
                if (o.certificationName === "" && o.certificationNumber === "" && o.otherCertExpiryDate === "" && o.otherCertImage === "") {
                    console.log("Validation Error: " + (i + 1) + " Row is empty");
                    IsError = true;
                    break;
                }
                if (o.certificationNumber !== "" && o.certificationNumber.length < 5) {
                    console.log("Validation Error: Certification Number should be atlease 5 character on row " + (i + 1));
                    IsError = true;
                    break;
                }
                if (o.certificationNumber !== "" && o.certificationNumber.length >= 5 && (o.otherCertExpiryDate === '' || o.otherCertImage === '')) {
                    console.log("Validation Error: Either Certification name, certification expiry date or image is missing for row no. " + (i = 1));
                    IsError = true;
                    break;
                }
                if (o.otherCertExpiryDate !== "" && o.certificationNumber === "" && o.certificationName === "") {
                    console.log("Validation Error: Certification Number or name is missing on row " + (i + 1));
                    IsError = true;
                    break;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return IsError;
    };
    AdditionalCertificateComponent.prototype.getUploadTooltip = function (element) {
        return element.certFileName || 'Required – Use Arrow to Upload Certificate';
    };
    return AdditionalCertificateComponent;
}());
export { AdditionalCertificateComponent };
