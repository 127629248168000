import { Component, OnInit, Input } from '@angular/core';
import { HeadingQuestion } from '../../../../core/models/inspection/inspection.model';

@Component({
  selector: 'app-electric-devices',
  templateUrl: './electric-devices.component.html',
  styleUrls: []
})
export class ElectricDevicesComponent implements OnInit {
  @Input() questions: Array<HeadingQuestion>;
  @Input() qtd: Array<any>;
  @Input() reviewInspection: boolean;
  stairwayTable;
  constructor() { }

  ngOnInit() {
    this.init();
  }

  init() {
    this.stairwayTable = {
      electricStrike: {
        'unitsInBuilding': this.findAnswerbyQuestion('Electric Strike', 'unitsInBuilding') || '',
        'unitsTested': this.findAnswerbyQuestion('Electric Strike', 'unitsTested') || '',
        'resultsAcceptable': this.findAnswerbyQuestion('Electric Strike', 'resultsAcceptable') || 'N/A'
      },
      electricBolt: {
        'unitsInBuilding': this.findAnswerbyQuestion('Electric Bolt', 'unitsInBuilding') || '',
        'unitsTested': this.findAnswerbyQuestion('Electric Bolt', 'unitsTested') || '',
        'resultsAcceptable': this.findAnswerbyQuestion('Electric Bolt', 'resultsAcceptable') || 'N/A'
      },
      otherLockingDevices: {
        'unitsInBuilding': this.findAnswerbyQuestion('Other Locking Devices', 'unitsInBuilding') || '',
        'unitsTested': this.findAnswerbyQuestion('Other Locking Devices', 'unitsTested') || '',
        'resultsAcceptable': this.findAnswerbyQuestion('Other Locking Devices', 'resultsAcceptable') || 'N/A'
      }
    }
  }

  ngOnChanges(changes) {
    if (changes['questions'] && changes['questions'].currentValue) {
      this.questions = [...changes['questions'].currentValue];
      this.init();
    }
  }

  findAnswerbyQuestion(question, description) {
    let myQuestion = this.questions.filter(x => x.questions.question === question)
    if (myQuestion.length > 0) {
      if (myQuestion[0].questions &&
        myQuestion[0].questions.tableAnswers &&
        myQuestion[0].questions.tableAnswers.length > 0 &&
        myQuestion[0].questions.tableAnswers[0][description]) {
        this.updateModel(question, description, myQuestion[0].questions.tableAnswers[0][description]);
        return myQuestion[0].questions.tableAnswers[0][description]
      } else {
        if (description === 'resultsAcceptable') {
          this.updateModel(question, description, 'N/A');
          return 'N/A'
        } else {
          return '';
        }
      }
    }
    return;
  }

  findQuestionId(description: string) {
    let myQuestion = this.questions.filter(x => x.questions.question === description)
    if (myQuestion.length > 0) {
      return myQuestion[0].questions.questionId;
    }
    return 0;
  }

  updateModel(question, column, value) {
    const id = this.findQuestionId(question);
    if (!this.qtd[id] || typeof this.qtd[id][0] === 'string') {
      this.qtd[id] = {};
    }
    if (column === 'unitsInBuilding') {
      this.qtd[id][column] = value;
    } else if (column === 'unitsTested') {
      this.qtd[id][column] = value;
    } else if (column === 'resultsAcceptable') {
      this.qtd[id][column] = value;
    }
  }

}
