import { OnInit } from '@angular/core';
var OwnerStatisticsComponent = /** @class */ (function () {
    function OwnerStatisticsComponent(ownerService) {
        this.ownerService = ownerService;
        this.compliancePercentage = '0';
        this.latestBidNotification = {};
        this.lowestBidNotification = {};
        this.unsub = [];
    }
    OwnerStatisticsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.unsub.push(this.ownerService.getOwnerStatistics().subscribe(function (res) {
            _this.propertiesOwned = res.propertiesOwned;
            _this.systemOwned = res.systemsOwned;
            _this.compliancePercentage = res.compliancePercentage ? parseFloat(res.compliancePercentage.toFixed(2)).toString() : '0';
            _this.latestBidNotification.inspectorName = res.latestBidNotification;
            _this.lowestBidNotification.inspectorName = res.lowestBidNotification;
        }));
    };
    OwnerStatisticsComponent.prototype.ngOnDestroy = function () {
        this.unsub.map(function (elem) { return elem.unsubscribe(); });
    };
    return OwnerStatisticsComponent;
}());
export { OwnerStatisticsComponent };
