import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JournalDetailService } from '../../../core/services/property/journal-detail.service';
import { PropertyService } from '../../../core/services/property/property.service';
import { DialogData, JournalComponent } from '../journal.component';
import { JournalNotes } from '../../../core/models/property/journal.model';


@Component({
  selector: 'app-add-notes-dialog',
  templateUrl: './add-notes-dialog.component.html',
  styleUrls: ['./add-notes-dialog.component.scss']
})

export class AddNotesDialogComponent implements OnInit {

  public addNotesForm: FormGroup;
  public notesPayload = {} as JournalNotes[];
  public isAuthority: boolean;
  public isOwner: boolean;
  public isInspector: boolean;
  disableSubmit = false;
  constructor(
    public dialogRef: MatDialogRef<JournalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public fb: FormBuilder,
    private journalDetailService: JournalDetailService,
    // private _snackBar: MatSnackBar ,
    private propertyService: PropertyService
    ) { }

  ngOnInit() {
    this.addNotesForm = this.fb.group({
      noteHeading: [, Validators.required],
      accessType: ['Private', Validators.required],
      note: [, Validators.required]
    });

    this.isAuthority = false;
    this.isOwner = false;
    this.isInspector = false;

    if (this.data.dialogOption === 'edit') {
      this.addNotesForm.patchValue({
        noteHeading: this.data.note.noteHeader,
        accessType: this.data.note.accessControl,
        note: this.data.note.noteBody
    });

    }

  }

  checkAuthority(event) {
    this.isAuthority = event.target.checked ? true : false;
  }
  checkOwner(event) {
    this.isOwner = event.target.checked ? true : false;
  }
  checkInspector(event) {
    this.isInspector = event.target.checked ? true : false;
  }

  setAccessType() : string{
    let accessType = this.addNotesForm.value.accessType.toString();
    if(accessType === 'Public'){
      return 'Public';
    } else if (accessType === 'Private'){
      return 'Private';
    } else if (accessType === 'Specific'){
      if(this.isAuthority && this.isInspector && this.isOwner){
        return 'Specific/Inspector,Authority,Owner';
      }else if(this.isInspector && this.isAuthority){
        return 'Specific/Inspector,Authority';
      }else if(this.isOwner && this.isInspector){
        return 'Specific/Owner,Inspector';
      }else if(this.isOwner && this.isAuthority) {
        return 'Specific/Owner,Authority';
      }else if(this.isInspector) {
        return 'Specific/Inspector';
      }else if(this.isOwner){
        return 'Specific/Owner';
      }else if (this.isAuthority){
        return 'Specific/Authority';
      }
    }
    return 'null';
  }

  onSubmit() {
    this.disableSubmit = true;
    let accessType = this.setAccessType();
    if (this.addNotesForm.valid && accessType) {
      let lastIndex = this.journalDetailService.notes.getValue() ? this.journalDetailService.notes.getValue().length : 0;
      this.notesPayload = [{
        displaySequence: ++lastIndex,
        noteHeader: this.addNotesForm.value.noteHeading.toString(),
        accessControl: accessType,
        noteBody: this.addNotesForm.value.note.toString(),
        propertyId: this.data.propertyId || this.propertyService.propertyId$.getValue()
      }];

      if (this.data.dialogOption === 'edit') {

        this.notesPayload[0].id = this.data.note.id;
        this.notesPayload[0].displaySequence = this.data.note.displaySequence;
        this.journalDetailService.postNotesForProperty(this.notesPayload).subscribe((res: any) => {
          this.disableSubmit = false;
          this.dialogRef.close();
      }, (error: any) => {
        this.disableSubmit = false;
        console.log(error);
        this.dialogRef.close();
      });

      } else {

        this.journalDetailService.postNotesForProperty(this.notesPayload).subscribe((res: any) => {
          this.disableSubmit = false;
          alert('Note Saved Successfully');
          this.dialogRef.close();
      }, (error: any) => {
        this.disableSubmit = false;
        console.log(error);
        this.dialogRef.close();
      });
    }

    } else {
      this.disableSubmit = false;
      console.error('Empty add note dialod');
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
