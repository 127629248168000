<app-dialog>
  <span class="dialog-title">
    Notes
    <span class="float-right">
      <mat-icon class="cursor-pointer" (click)="onNoClick()">close</mat-icon>
    </span>
  </span>
  <div class="dialog-content">
    <form [formGroup]="addNotesForm" >
      <div class="form-group row form-group-padding ">
        <label for="smFormGroupInput" class="col-4 col-form-label col-form-label-sm inputLabel notes-sub-heading">Note
          Heading</label>
        <div class="col-7">
          <input type="text" class="form-control form-control-sm rounded-form-fields" formControlName="noteHeading"
          [(ngModel)]="this.addNotesForm.value.noteHeading" maxlength="100">
        </div>
      </div>
      <div class="form-group row form-group-padding ">
          <label for="smFormGroupInput" class="col-4 col-form-label col-form-label-sm inputLabel notes-sub-heading">Access
            Type<label class="mandatory">*</label></label>
          <div class="col-7">
            <select #speceficValue class="rounded-form-fields no-Padding custom-select" formControlName="accessType"
            [(ngModel)] = "this.addNotesForm.value.accessType">
              <option value="Private" selected>Private</option>
              <option value="Public">Public</option>
              <option value="Specific">Specific</option>
            </select>
            <div *ngIf="speceficValue.value === 'Specific'" style="margin-top: 14px;" >
                <div class="row">
                  <input class="custom-checkbox-notes col-4" type="checkbox" (change)="checkAuthority($event)"/>
                  <input class="custom-checkbox-notes col-4" type="checkbox" (change)="checkOwner($event)" />
                  <input class="custom-checkbox-notes col-4" type="checkbox" (change)="checkInspector($event)"/>
                </div>
                <div class="row" style="text-align: center">
                  <label class="col-4 ">Authority</label>
                  <label class="col-4 ">Owner</label>
                  <label class="col-4 ">Inspector</label>
                </div>
              </div>
          </div>
      </div>
      <div class="form-group row form-group-padding ">
        <label for="smFormGroupInput"
          class="col-4 col-form-label col-form-label-sm inputLabel notes-sub-heading">Note</label>
        <div class="col-7">
          <textarea class="col-12" rows="4" name="comment" formControlName="note"
          [(ngModel)]="this.addNotesForm.value.note"></textarea>
        </div>
      </div>
    </form>
  </div>

  <div class="dialog-actions">
    <button class="custom-buttons" type="button" [disabled]="disableSubmit" (click)="onSubmit()" cdkFocusInitial>Submit</button>
    <button type="button" class="custom-buttons" (click)="onNoClick()">Cancel</button>
  </div>

</app-dialog>
