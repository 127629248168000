import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Contacts } from '../../../property-page/property-contacts/property-contacts.component';
import { PropertyContacts } from '../../models/property/property.model';


@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  baseUrl = environment.baseUrl;
  public propertyContacts = new BehaviorSubject<Contacts[]>(null);
  public newpropertyContacts = new BehaviorSubject<any[]>(null);
  public propertyName = new BehaviorSubject<any>(null);
  public propertyID = new BehaviorSubject<any>(null);
  public propertyAddress = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) { }

  getContactsByPropertyId(propertyId: number): Observable<PropertyContacts[]> {
    const url = this.baseUrl + `/property/${propertyId}/propertyContact`;
    return this.http.get<PropertyContacts[]>(url);
  }

  addNewContacts(contacts: any, propertyId: number) {
    const url = this.baseUrl + `/propertyContact/${propertyId}`;
    return this.http.post<PropertyContacts[]>(url, contacts);
  }

  updateExistingContacts(contacts: any, propertyId: number) {
    const url = this.baseUrl + `/propertyContact/${propertyId}`;
    return this.http.put<PropertyContacts[]>(url, contacts);
  }

  deleteContact(contactId: number) {
    const url = this.baseUrl + `/propertyContact/${contactId}`;
    return this.http.delete<PropertyContacts[]>(url);
  }
}

