import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { JournalNotes, JournalContacts, PropertyJournalResponse, PropertyJournalRequest } from '../../models/property/journal.model';


@Injectable({
  providedIn: 'root'
})


export class JournalDetailService {

  baseUrl = environment.baseUrl;
  public notes = new BehaviorSubject<any>(null);
  public journal = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) { }

  // GET Methods
  getNotesForProperty(propertyId: number): Observable<any> {
    const url = this.baseUrl + `/property/${propertyId}/propertyNote`;
    return this.http.get<JournalNotes[]>(url);
  }

  getPropertyContactsByPropertyId(propertyId: number): Observable<any> {
    const url = this.baseUrl + `/property/${propertyId}/propertyContact`;
    return this.http.get<JournalContacts[]>(url);
  }

  getJournalDetails(propertyId: number): Observable<any> {
    const url = this.baseUrl + `/propertyjournals/${propertyId}`;
    return this.http.get<PropertyJournalResponse[]>(url);
  }

  getAssetJournals(propertyId: number) {
    const url = this.baseUrl + `/propertyAssetJournals/${propertyId}`;
    return this.http.get(url);
   }

   getNoteByNoteId(noteId: number): Observable<any> {
    const url = this.baseUrl + `/propertyNote/${noteId}`;
    return this.http.get<JournalNotes>(url);
  }

  // POST Methods
  postNotesForProperty(data: JournalNotes[]): Observable<any> {
    const url = this.baseUrl + `/property/${data[0].propertyId}/propertyNote`;
    return this.http.post(url, data);
  }

  postJournalEntry(data: PropertyJournalRequest, propertyId: number): Observable<any> {
    const url = this.baseUrl + `/propertyjournals/${propertyId}`;
    return this.http.post(url, data);
  }

  // PUT Methods
  putNotesDisplaySequence(data: JournalNotes[]): Observable<any> {
    const url = this.baseUrl + '/propertyNote';
    return this.http.put(url, data);
  }

  putNotesForProperty(data: JournalNotes[]): Observable<any> {
    const url = this.baseUrl + `/propertyNote`;
    return this.http.put(url, data);
  }

  putPropertyContactsDisplaySequence(data: JournalContacts[]) {
    const url = this.baseUrl + '/propertyContact';
    return this.http.put(url, data);
  }

  // DELETE Methods
  deleteNote(noteId: number[]) {
    const url = `${this.baseUrl}/propertyNote/${noteId}`;
    return this.http.delete(url);
  }

}
