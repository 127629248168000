import { Component, OnInit, Inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatTableDataSource,
} from "@angular/material";
import { additionalCertificates } from "../../models/inspector.model";

@Component({
  selector: "app-additional-certificate",
  templateUrl: "./additional-certificate.component.html",
  styleUrls: ["./additional-certificate.component.scss"],
})
export class AdditionalCertificateComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  certificates: MatTableDataSource<additionalCertificates>;
  columnsToDisplay: any = [
    "certificationName",
    "certificationNumber",
    "otherCertExpiryDate",
  ];

  err: {
    otherCertExpiryDate?: { error: string }[];
  } = {};

  editMode: boolean = false;

  ngOnInit() {
    this.editMode = this.data.editMode ? true : false;
    const certificateData: additionalCertificates[] =
      this.data.certificateList && this.data.certificateList.length > 0
        ? JSON.parse(JSON.stringify(this.data.certificateList))
        : [];

    if (this.data.editMode) {
      this.columnsToDisplay.push("actions");
    }

    certificateData.forEach((data) => {
      data.certificationName = data.certificationName
        ? data.certificationName
        : "";
      data.certificationNumber = data.certificationNumber
        ? data.certificationNumber
        : "";
      data.otherCertExpiryDate = data.otherCertExpiryDate
        ? data.otherCertExpiryDate
        : "";
      data.otherCertImage = data.otherCertImage ? data.otherCertImage : "";
      const fileNameArr = data.otherCertImage ? data.otherCertImage.split('/') : []
      data.certFileName = data.certFileName ? data.certFileName : fileNameArr.length > 0 ? fileNameArr[fileNameArr.length-1] : "Required – Use Arrow to Upload Certificate"
    });

    this.certificates = new MatTableDataSource(certificateData);
  }

  hasErrorInDate(error, index, key) {
    if (!this.err[key]) {
      this.err[key] = {};
    }
    this.err[key][index] = error;
  }

  AddNewCertificate() {
    if (this.certificates.data.length < 6) {
      const data = JSON.parse(
        JSON.stringify(this.certificates.data)
      ) as additionalCertificates[];
      data.unshift({
        id: null,
        certificationName: "",
        certificationNumber: "",
        otherCertExpiryDate: "",
        otherCertImage: "",
        sequence: 0,
        certFileName: ""
      });
      this.certificates = new MatTableDataSource(data);
    } else {
      alert("You are not allowed to add more than 6 additional certificates");
    }
  }

  setBase64OtherCertificateImage(event, index) {
    const file = event.target.files[0];
    const extIndex = file.name.lastIndexOf(".");
    const fileExt = file.name.substring(extIndex + 1).toLowerCase();
    if (
      !(
        fileExt === "jpeg" ||
        fileExt === "jpg" ||
        fileExt === "pjpeg" ||
        fileExt === "pjp" ||
        fileExt === "gif" ||
        fileExt === "jfif" ||
        fileExt === "png" ||
        fileExt === "tiff" ||
        fileExt === "pdf"
      )
    ) {
      alert("Allowed File Formats are JPEG, PNG, PDF, GIF, TIFF");
    } else if (file.size > 2 * 1024 * 1024) {
      alert("Max size allowed is 2 MB");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const that = this;
      reader.onload = function () {
        const data = JSON.parse(JSON.stringify(that.certificates.data));
        data[index].otherCertImage = reader.result;
        data[index].certFileName = file.name;
        that.certificates = new MatTableDataSource(data);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }
  }

  DeleteCertificate(id: number, index: number) {
    let data = JSON.parse(
      JSON.stringify(this.certificates.data)
    ) as additionalCertificates[];
    data.splice(index, 1);
    this.certificates = new MatTableDataSource(data);
  }

  close() {
    const data = JSON.parse(
      JSON.stringify(this.certificates.data)
    ) as additionalCertificates[];
    if (this.errorInform(data)) {
      alert("Enter all the required information");
      return;
    }
    this.dialogRef.close(data);
  }

  private errorInform(form: additionalCertificates[]): boolean {
    if (!this.editMode || form.length === 0) return false;

    // if error in date
    if (this.err && this.err.otherCertExpiryDate){
      for (const [key, value] of Object.entries(this.err.otherCertExpiryDate)) {
        if(value.error && value.error !== 'Enter Expiration Date'){
          console.log("Validation #1");
          return true;
        }
      }
    }

    let IsError: boolean = false;


    for(let [i, o] of form.entries()){
        if(o.certificationName === "" && o.certificationNumber === "" && o.otherCertExpiryDate === "" && o.otherCertImage === ""){
        console.log(`Validation Error: ${i+1} Row is empty`);
        IsError = true;
        break;
      }

      if(o.certificationNumber !== "" && o.certificationNumber.length < 5){
        console.log(`Validation Error: Certification Number should be atlease 5 character on row ${i+1}`);
        IsError = true;
        break;
      }

      if(o.certificationNumber !== "" && o.certificationNumber.length >=5 && (o.otherCertExpiryDate === '' || o.otherCertImage === '')){
        console.log(`Validation Error: Either Certification name, certification expiry date or image is missing for row no. ${i=1}`);
        IsError = true;
        break;
      }

      if(o.otherCertExpiryDate !== "" && o.certificationNumber === "" && o.certificationName === ""){
        console.log(`Validation Error: Certification Number or name is missing on row ${i+1}`);
        IsError = true;
        break;
      }

    }

    return IsError;
  }

  getUploadTooltip(element: additionalCertificates): string{
    return  element.certFileName || 'Required – Use Arrow to Upload Certificate'
  }
}
