import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CartService = /** @class */ (function () {
    function CartService(http) {
        this.http = http;
        this.baseUrl = environment.baseUrl;
    }
    CartService.prototype.store = function (body) {
        return this.http.post(this.baseUrl + "/cart", body);
    };
    CartService.prototype.get = function () {
        return this.http.get(this.baseUrl + "/cart");
    };
    CartService.prototype.getCount = function () {
        var url = this.baseUrl + "/cart/count";
        return this.http.get(url);
    };
    CartService.prototype.remove = function (id) {
        return this.http.delete(this.baseUrl + "/cart/" + id);
    };
    CartService.prototype.submit = function (body) {
        // TODO: remove redundant code
        // not removing now, because unsure of from where all is it being called
        return this.http.post(this.baseUrl + "/cart", body);
    };
    CartService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CartService_Factory() { return new CartService(i0.ɵɵinject(i1.HttpClient)); }, token: CartService, providedIn: "root" });
    return CartService;
}());
export { CartService };
