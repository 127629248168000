import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';

@Component({
  selector: 'app-assign-property-dialog',
  templateUrl: './assign-property-dialog.component.html',
  styleUrls: []
})

export class AssignPropertyDialogComponent {
  pac: string = "";
  showError: boolean;
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.showError = false;
  }

  close() {
    this.dialogRef.close();
  }

  submit() {
    if (this.pac.length > 0) {
      this.showError = false;
      this.dialogRef.close(this.pac);
    } else {
      this.showError = true;
    }
    
  }
}
