import { ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
var DataInvestigatorDirective = /** @class */ (function () {
    function DataInvestigatorDirective(userService, el, renderer) {
        this.userService = userService;
        this.el = el;
        this.renderer = renderer;
    }
    DataInvestigatorDirective.prototype.ngAfterViewInit = function () {
        if (this.userService.userDetails.accessSpecifier === 'DATA_INVESTIGATOR') {
            var div = this.renderer.createElement('span');
            this.renderer.setStyle(div, 'cursor', 'pointer');
            var uuid = uuidv4();
            this.renderer.setAttribute(div, 'id', uuid);
            $(this.el.nativeElement).wrapAll(div);
            $('#' + uuid).click(function (e) {
                // console.log(e);
                alert('Data Investigator is not authorized to perform this action');
            });
            this.renderer.addClass(this.el.nativeElement, 'disablePointer');
        }
    };
    return DataInvestigatorDirective;
}());
export { DataInvestigatorDirective };
var DataInvestigatorModule = /** @class */ (function () {
    function DataInvestigatorModule() {
    }
    return DataInvestigatorModule;
}());
export { DataInvestigatorModule };
