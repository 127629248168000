export const environment = {
  production: true,
  env: 'staging',
  baseUrl: `https://api.tegrisfire-stage.com/tegrisfire-backend/api`,
  pushNotificationPublicKey: '',
  geoLocationMapAPIKey: '',
  uspsServiceOn: true,
  pageSize: 10

};
