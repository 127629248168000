import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LoginService = /** @class */ (function () {
    function LoginService(http) {
        this.http = http;
        this.baseUrl = environment.baseUrl;
        this.UserDetails$ = new BehaviorSubject(null);
        this.loggedIn$ = new BehaviorSubject(false);
    }
    LoginService.prototype.loginUser = function (data) {
        // http://10.127.129.218:9003/tegrisfire-backend/api
        var url = this.baseUrl + "/login";
        return this.http.post(url, data);
    };
    LoginService.prototype.recoverPassword = function (data) {
        var url = this.baseUrl + "/regeneratePassword";
        return this.http.put(url, data);
    };
    LoginService.prototype.changePassword = function (data) {
        var url = this.baseUrl + "/resetPassword";
        return this.http.put(url, data);
    };
    LoginService.prototype.getUserDetails = function (userId) {
        var url = this.baseUrl + ("/user/" + userId);
        return this.http.get(url);
    };
    LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.HttpClient)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
