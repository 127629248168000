import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MatTableDataSource,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { InspectionReportService } from "../../../core/services/inspection/inspection-report.service";
import { ActivatedRoute } from "@angular/router";
import { InspectionService } from "../../../core/services/inspection/inspection.service";
import { ConfirmationDialog } from "../../../inspector/shared/confirmation-popup-dialog/confirmation-dialog.component";
import { ConfirmationPopupDialogDeleteComponent } from "../../../inspector/shared/confirmation-popup-dialog-delete/confirmation-popup-dialog-delete.component";

@Component({
  selector: "app-view-previous-reports",
  templateUrl: "./view-previous-reports.component.html",
  styleUrls: ["./view-previous-reports.component.scss"],
})
export class  ViewPreviousReportsComponent implements OnInit, OnDestroy {
  subscriptions = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  columnsToDisplay: string[];
  inspectionSystemInfo = {} as any;
  userRoleId: number;
  unsub = [];
  userRoleName: string;

  constructor(
    public dialogRef: MatDialogRef<ViewPreviousReportsComponent>,
    private inspectService: InspectionService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public inspectionSystem: any
  ) {}

  ngOnInit() {
     this.userRoleId = JSON.parse(localStorage.getItem("userDetails")).role.id;
      this.userRoleName = JSON.parse(localStorage.getItem("userDetails")).role.name;
    this.columnsToDisplay = [
      "inspectReportNo",
      "inspectDate",
      "inspectCompany",
      "actions",
    ];
    if (
      this.inspectionSystem.sys.inspectionReport.previousReports &&
      this.inspectionSystem.sys.inspectionReportNumber
    ) {
      this.inspectionSystem.sys.inspectionReport.previousReports =
        this.inspectionSystem.sys.inspectionReport.previousReports.filter(
          (report) =>
            report.inspectionReportNumber.toString() ===
            this.inspectionSystem.sys.inspectionReportNumber.toString()
        ) || [];
    }

    if (
      this.inspectionSystem.sys.inspectionReport.previousReports &&
      this.inspectionSystem.sys.inspectionReport.previousReports.length > 0 &&
      this.inspectionSystem.sys.backflowSerialNumber &&
      this.inspectionSystem.sys.backflowSerialNumber.length > 0
    ) {
      this.inspectionSystem.sys.inspectionReport.previousReports =
        this.inspectionSystem.sys.inspectionReport.previousReports.filter(
          (report) =>
            report.backflowSerialNo.toLowerCase() ===
            this.inspectionSystem.sys.backflowSerialNumber.toLowerCase()
        ) || [];
    }
    this.inspectionSystem.sys.inspectionReport.previousReports.sort(
      (a: any, b: any) => {
        return b.inspectionReportNumber - a.inspectionReportNumber;
      }
    );
    this.dataSource.data =
      this.inspectionSystem.sys.inspectionReport.previousReports || [];
    this.inspectionSystemInfo = this.inspectionSystem.sys.inspectionReport;
  }

  ngOnDestroy() {
    this.unsub.forEach((res) => res.unsubscribe());
  }

  close() {
    this.dialogRef.close(null);
  }

  viewReport(report) {
    window.open(
      `${window.location.origin}/printReport?propId=${this.inspectionSystem.prop.id}&inspectedBy=${this.inspectionSystemInfo.inspectedById}&previousResultId=${report.inspectionResultId}&id=${this.inspectionSystem.sys.assetTypeId}`,
      "_blank"
    );
  }

  deleteInspectionReport(reports) {
   // this.addToHistory(property.id);
    const dialogRef = this.dialog.open(ConfirmationPopupDialogDeleteComponent, {
      width: "500px",
      minWidth: "350px",
      height: "300px",
      autoFocus: false,
      disableClose: true,
      data: "Are you sure you want to delete this report?",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.subscriptions.push(
          this.inspectService.deleteInspectionPrevious(0, reports.inspectionResultId, res.comments).subscribe(
            (result) => {
              alert("Report Deleted");
              this.inspectService.getPreviousReports(this.inspectionSystem.sys.inspectionReport.propertyAssetId).subscribe((res) => {
                if(res.previousReports != null) {
                  this.dataSource.data = res.previousReports || [];
                  this.dataSource.data = this.sortData(this.dataSource.data)
                  //this.dataSource.data = this.dataSource.data.sort((a,b) => new Date(b.inspectedDate) - new Date(a.inspectedDate));
                } else {
                  this.close();
               }
              });

              //this.searchFileCabinetProperties();
              //this.ngOnInit();
              //this.close();
            },
            (error) => {
              if (error.error && error.error.message) {
                alert(error.error.message);
              } else {
                alert("Unexpected Error");
              }
            }
          )
        );
      }
    });
  }


   sortData(data) {
    return data.sort((a, b) => {
      return <any>new Date(b.inspectedDate) - <any>new Date(a.inspectedDate);
    });
  }

}
