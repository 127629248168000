import { OnInit } from '@angular/core';
import { ConfirmationDialog } from '../../../../inspector/shared/confirmation-popup-dialog/confirmation-dialog.component';
import { environment } from '../../../../../environments/environment';
var AppHeaderComponent = /** @class */ (function () {
    function AppHeaderComponent(router, ownerService, cartService, dialog, loggerService, statService, loginService, inspectorService) {
        this.router = router;
        this.ownerService = ownerService;
        this.cartService = cartService;
        this.dialog = dialog;
        this.loggerService = loggerService;
        this.statService = statService;
        this.loginService = loginService;
        this.inspectorService = inspectorService;
    }
    AppHeaderComponent.prototype.openContactUs = function () {
        window.location.href = ('https://www.tegrisinc.com/contacts');
    };
    AppHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
        this.currentDate = new Date();
        var cartAlreadyShown = localStorage.getItem('cartDialogShown');
        if (this.userDetails.role.name === 'ROLE_INSPECTOR' && !cartAlreadyShown) {
            localStorage.setItem('cartDialogShown', 'true');
            this.cartService.getCount()
                .subscribe(function (res) {
                if (res > 0) {
                    _this.openDialog('login');
                }
            }, function (err) { return _this.loggerService.log(err); });
        }
    };
    AppHeaderComponent.prototype.checkBeforeLogout = function () {
        var _this = this;
        if (this.userDetails.role.name === 'ROLE_INSPECTOR') {
            this.cartService.getCount()
                .subscribe(function (res) {
                if (res > 0) {
                    _this.openDialog();
                    return;
                }
                _this.logout();
            }, function (err) { return _this.logout(); });
            return;
        }
        this.logout();
    };
    // clear all subjects here.......
    AppHeaderComponent.prototype.clearSubjectsBeforeLogout = function () {
        this.loginService.loggedIn$.next(false);
        this.statService.jurisId$.next(null);
        this.statService.jurisName$.next(null);
        this.ownerService.ownerProperties.next(null);
        this.ownerService.dashboardProperties.next(null);
        this.inspectorService.bidOpportunities.next([]);
        this.inspectorService.isBidOpportunityApiLoaded = false;
    };
    AppHeaderComponent.prototype.logout = function () {
        localStorage.clear();
        sessionStorage.clear();
        this.clearSubjectsBeforeLogout();
        if (environment.env && (environment.env === 'qa'
            || environment.env === 'dev'
            || environment.env === 'pantest'
            || environment.env === 'staging')) {
            this.router.navigate(['login/login-form']);
        }
        else {
            window.location.href = ('https://www.tegrisinc.com/');
        }
        this.ownerService.preferencesUpdated.next(true);
    };
    AppHeaderComponent.prototype.openDialog = function (type) {
        var _this = this;
        if (type === void 0) { type = ''; }
        var a = this.dialog.open(ConfirmationDialog, {
            data: {
                cartFlag: true,
                content: "There are some item left in cart",
                yesButton: "Move to Cart",
                noButton: "Pay Later"
            }
        });
        a.afterClosed().subscribe(function (res) {
            if (res) {
                if (!_this.router.url.endsWith('cart')) {
                    _this.router.navigate(['user/inspector/cart']);
                }
                return;
            }
            if (type === 'login') {
                return;
            }
            _this.logout();
        });
    };
    return AppHeaderComponent;
}());
export { AppHeaderComponent };
