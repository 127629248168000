import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { HttpClient } from '@angular/common/http';

import { LoggerService } from '../logger/logger.service';

import { environment } from '@environment/environment';

/**
 * This service manage the web push notifications
 * This service provides the functionalities to subscribe & unsubscribe the push notifications.
 * This service uses the [SwPush]{@link https://angular.io/api/service-worker/SwPush} service of ```@angular/service-worker```.
 */
@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {
  constructor(
    private swPush: SwPush,
    private logger: LoggerService,
    private http: HttpClient
  ) {}

  /**
   * Subscribe the push notifications. This service prompts user to allow access for web push notifications.
   * This uses the server public key defined in the environment variables to subscribe the notifications.
   */
  async subscribeNotifications() {
    const sub = await this.swPush
      .requestSubscription({
        serverPublicKey: environment.pushNotificationPublicKey
      })
      .catch(e => {
        this.logger.info('Push notification subscription is declined', e);
      });

    /** To check the subscription */
    this.logger.log({ subscription: sub });

    if (sub) {
      /** Send the subscription to server in case of no error */
      this.addSubscription(sub);
    }
  }

  /**
   * Unsubscribe to push notifcation using ```SwPush``` API.
   */
  async unsubscribeToPushNotification() {
    await this.swPush.unsubscribe().catch(e => {
      this.logger.error('Error while unsubscribing the notifications', e);
    });
  }

  /**
   * Save the subscription to the server.
   * @param sub Push notification subscription object
   */
  private addSubscription(sub: PushSubscription) {
    /** TODO: Save the subscription on the server */
    // this.http.post()
  }
}
