import { Component, OnInit } from '@angular/core';
import { OwnerService } from '../../../../../shared/services/owner.service';
import * as moment from 'moment';
import {tap} from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-owner-statistics',
  templateUrl: './owner-statistics.component.html',
  styleUrls: ['./owner-statistics.component.scss']
})
export class OwnerStatisticsComponent implements OnInit {

  // lowestBid: number;
  propertiesOwned: number;
  systemOwned: number;
  compliancePercentage: string = '0';
  latestBidNotification: any = {};
  lowestBidNotification: any = {};
  unsub: Subscription[] = [];

  constructor(private ownerService: OwnerService) { }

  ngOnInit() {
    this.unsub.push(this.ownerService.getOwnerStatistics().subscribe((res) => {
      this.propertiesOwned = res.propertiesOwned;
      this.systemOwned = res.systemsOwned;
      this.compliancePercentage = res.compliancePercentage ? parseFloat(res.compliancePercentage.toFixed(2)).toString() : '0';
      this.latestBidNotification.inspectorName = res.latestBidNotification;
      this.lowestBidNotification.inspectorName = res.lowestBidNotification;
    }));
  }

  ngOnDestroy() {
    this.unsub.map(elem => elem.unsubscribe());
  }

}
