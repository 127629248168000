/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./usps-popUp-valid.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/app-dialog/app-dialog.component.ngfactory";
import * as i3 from "../../../shared/components/app-dialog/app-dialog.component";
import * as i4 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "@angular/common";
import * as i7 from "./usps-popUp-valid.component";
import * as i8 from "@angular/material/dialog";
var styles_USPSValidPopUpComponent = [i0.styles];
var RenderType_USPSValidPopUpComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_USPSValidPopUpComponent, data: {} });
export { RenderType_USPSValidPopUpComponent as RenderType_USPSValidPopUpComponent };
function View_USPSValidPopUpComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [["class", "col-form-label col-form-label-sm inputLabel notes-sub-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" , ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.streetLine2; _ck(_v, 1, 0, currVal_0); }); }
function View_USPSValidPopUpComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [["class", "col-form-label col-form-label-sm inputLabel notes-sub-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" -", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.subzipcode; _ck(_v, 1, 0, currVal_0); }); }
export function View_USPSValidPopUpComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, "app-dialog", [], null, null, null, i2.View_AppDialogComponent_0, i2.RenderType_AppDialogComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppDialogComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "span", [["class", "dialog-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "mat-icon", [["class", "cursor-pointer mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(8, 0, null, 1, 11, "div", [["class", "dialog-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "label", [["class", "col-form-label col-form-label-sm inputLabel notes-sub-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Here's the full address, using standard abbreviations and formatting... "])), (_l()(), i1.ɵeld(11, 0, null, null, 8, "div", [["class", "form-group form-group-padding "]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "label", [["class", "col-form-label col-form-label-sm inputLabel notes-sub-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_USPSValidPopUpComponent_1)), i1.ɵdid(15, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "label", [["class", "col-form-label col-form-label-sm inputLabel notes-sub-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, [" , ", ", ", ", ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_USPSValidPopUpComponent_2)), i1.ɵdid(19, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(20, 0, null, 2, 4, "div", [["class", "dialog-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "button", [["cdkFocusInitial", ""], ["class", "custom-buttons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Continue"])), (_l()(), i1.ɵeld(23, 0, null, null, 1, "button", [["class", "custom-buttons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 6, 0); var currVal_4 = ((_co.data.streetLine2 && (_co.data.streetLine2 != "null")) && (_co.data.streetLine2 != "NULL")); _ck(_v, 15, 0, currVal_4); var currVal_8 = ((_co.data.subzipcode && (_co.data.subzipcode != "null")) && (_co.data.subzipcode != "NULL")); _ck(_v, 19, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.data.manager === true) ? "MANAGER ADDRESS" : "PROPERTY ADDRESS"); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).inline; var currVal_2 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = _co.data.streetLine1; _ck(_v, 13, 0, currVal_3); var currVal_5 = _co.data.city; var currVal_6 = _co.data.state; var currVal_7 = _co.data.zipcode; _ck(_v, 17, 0, currVal_5, currVal_6, currVal_7); }); }
export function View_USPSValidPopUpComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-usps-valid-dialog", [], null, null, null, View_USPSValidPopUpComponent_0, RenderType_USPSValidPopUpComponent)), i1.ɵdid(1, 114688, null, 0, i7.USPSValidPopUpComponent, [i8.MatDialogRef, i8.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var USPSValidPopUpComponentNgFactory = i1.ɵccf("app-usps-valid-dialog", i7.USPSValidPopUpComponent, View_USPSValidPopUpComponent_Host_0, {}, {}, []);
export { USPSValidPopUpComponentNgFactory as USPSValidPopUpComponentNgFactory };
