import { Directive, ElementRef, Renderer2, AfterViewInit, NgModule } from '@angular/core';
import {UserService} from '../../services/user.service';
import { CommonModule } from '@angular/common';
import { v4 as uuidv4 } from 'uuid';
declare var $;

@Directive({
  selector: '[appReadOnly]'
})
export class ReadOnlyDirective implements AfterViewInit {

  constructor(
    private userService: UserService,
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngAfterViewInit() {




    if (this.userService.userDetails.accessSpecifier === 'READ_ONLY') {
      const div = this.renderer.createElement('span');
      this.renderer.setStyle(div, 'cursor', 'pointer');
      const uuid = uuidv4();
      this.renderer.setAttribute(div, 'id', uuid);
      $(this.el.nativeElement).wrapAll(div);

      $('#' + uuid).click( (e) => {
        alert( 'Read only user is not authorized to perform this action' );
      });
      this.renderer.addClass(this.el.nativeElement, 'disablePointer');

    }
  }

}

@NgModule({
  declarations: [
    ReadOnlyDirective,
    ],
  imports: [
    CommonModule
  ],
  exports: [
    ReadOnlyDirective
  ]
})
export class ReadOnlyModule { }

