<app-dialog>
    <span class="dialog-title">
      Duplicate Property
      <span class="float-right">
        <mat-icon class="cursor-pointer" (click)="cancel()">close</mat-icon>
      </span>
    </span>
    <div class="dialog-content">
      <label class="col-form-label col-form-label-sm inputLabel notes-sub-heading">
       Duplicate Property Exist do you want to edit it ?
      </label>
    </div>
    <div class="dialog-actions">
      <button class="custom-buttons" (click)="save()" cdkFocusInitial>Continue</button>
      <button class="custom-buttons" (click)="cancel()">Cancel</button>
    </div>
  </app-dialog>
