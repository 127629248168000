import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var DuplicatePropertyComponent = /** @class */ (function () {
    function DuplicatePropertyComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    DuplicatePropertyComponent.prototype.ngOnInit = function () {
        console.log(this.data);
    };
    DuplicatePropertyComponent.prototype.save = function () {
        this.dialogRef.close('continue');
    };
    DuplicatePropertyComponent.prototype.cancel = function () {
        this.dialogRef.close('cancelled');
    };
    return DuplicatePropertyComponent;
}());
export { DuplicatePropertyComponent };
