<app-dialog>
  <span class="dialog-title">
    Search Property
    <span class="float-right">
      <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
    </span>
  </span>
  <div class="dialog-content">
  <div dialog-content class="row form-group-padding">
    <div class="col-2 notes-sub-heading">Name: </div>
    <div class="col-6">
      <input type="text" class="col-11 col-form-label col-form-label-sm rounded-form-fields"
      [(ngModel)]="searchText" (keydown.enter)="searchByPropName()"/>
    </div>
      <button (click)="searchByPropName()" class="custom-buttons" style="margin: 0px !important;">Go</button>
  </div>

  <div class="tableData">
    <table mat-table #table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="blank">
        <mat-header-cell *matHeaderCellDef class="blank-head"></mat-header-cell>
        <mat-cell class="blank-head" *matCellDef="let property;let i = index;">
          <mat-radio-group aria-label="Select a Property" [(ngModel)]="selectedPropertyId">
            <mat-radio-button [value]="property.propertyId" (change)="selectProperty(property.propertyId)">
            </mat-radio-button>
          </mat-radio-group>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef class="user-name">Property Name</mat-header-cell>
        <mat-cell *matCellDef="let property; let i = index;" class="user-name">
          <label class="tableLabel">
            {{property.propertyName}}
          </label>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="address" class="user-address">
        <mat-header-cell *matHeaderCellDef class="user-address">Property Address</mat-header-cell>
        <mat-cell *matCellDef="let property; let i = index;">
          <label class="tableLabel" [innerHTML]="property.propertyAddress || ''">
          </label>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let element; columns: columnsToDisplay;let i = dataIndex" class="example-element-row">
      </mat-row>
    </table>
    <mat-paginator #properties [pageSize]="environment.pageSize" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons
     [length]="dataSource.data.length">
    </mat-paginator>

  </div>
</div>

  <div class="dialog-actions">
      <button class="custom-buttons" (click)="previewSamplePDF()">Preview Sample PDF</button>
      <button class="custom-buttons" (click)="close()">Cancel</button>
  </div>

</app-dialog>
