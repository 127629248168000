import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { paymentStatic } from '../models/paymentStatic';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  baseUrl = environment.baseUrl;
  updateTrigger: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient
  ) { }
  generateRequestSignature(body, fromInspection?) {
    const url = `${this.baseUrl}/generateRequestSignature`;
    let fullBody = {
      ...paymentStatic,
      retunUrl: window.location.href,
      ...body
    };
    if (fromInspection) {
      fullBody.retunUrl = fromInspection;
    }
    return this.http.post(url, fullBody);
  }

  paymentConfirmation(body) {
    delete body[`status`];
    const url = `${this.baseUrl}/returnUrl`;
    return this.http.put(url, body);

  }

  creditSub() {
    return this.updateTrigger.pipe(
      filter(res => res)
    );
  }

  creditSubUpdate(a) {
    this.updateTrigger.next(a);
  }
}
