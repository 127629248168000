import * as i0 from "@angular/core";
import * as i1 from "./user.service";
import * as i2 from "@angular/common";
var AuthorityInspectorAdminGuard = /** @class */ (function () {
    function AuthorityInspectorAdminGuard(userService, location) {
        this.userService = userService;
        this.location = location;
    }
    AuthorityInspectorAdminGuard.prototype.canActivate = function () {
        var a = this.userService.userDetails.role.name === 'ROLE_AUTHORITY' ||
            this.userService.userDetails.role.name === 'ROLE_INSPECTOR' ||
            this.userService.userDetails.role.name === 'ROLE_SITE_ADMIN' ||
            this.userService.userDetails.role.name === 'ROLE_RFA' ||
            this.userService.userDetails.role.name === 'ROLE_AUTHORITY_WORX';
        if (a) {
            return a;
        }
        this.location.back();
        return a;
    };
    AuthorityInspectorAdminGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthorityInspectorAdminGuard_Factory() { return new AuthorityInspectorAdminGuard(i0.ɵɵinject(i1.UserService), i0.ɵɵinject(i2.Location)); }, token: AuthorityInspectorAdminGuard, providedIn: "root" });
    return AuthorityInspectorAdminGuard;
}());
export { AuthorityInspectorAdminGuard };
