import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var EmailSentDialogComponent = /** @class */ (function () {
    function EmailSentDialogComponent(dialogRef, data, loginService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.loginService = loginService;
    }
    EmailSentDialogComponent.prototype.ngOnInit = function () { };
    EmailSentDialogComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    return EmailSentDialogComponent;
}());
export { EmailSentDialogComponent };
