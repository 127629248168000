<div class="full-container">
<div class="password-change-container">
  <div class="log-div">
    <div class="logo">
      <img alt="tegrisfire" src='/assets/images/tegrisfire_logo.png'/>
      <img alt="tegrisfire" src='/assets/images/tegrisworx_logo.png' />
    </div>

    <div class="row" *ngIf="encOldPwd.length > 0" style="margin-top: 30px">
      <label class="col-form-label col-form-label-sm inputLabel offset-1" >
        Your old password does not meet the new security requirements. Please enter a new password.
      </label>
    </div>

    <div class="row" *ngIf="encOldPwd.length > 0">
      <ul class="offset-1">
        <li>
          <label class="col-form-label col-form-label-sm inputLabel">
            Length of password should be between 8 to 15
          </label>
          </li>
        <li>
          <label class="col-form-label col-form-label-sm inputLabel">
            Minimum one digit, one Upper case and one Lower case character should be used
          </label>
        </li>
        <li>
          <label class="col-form-label col-form-label-sm inputLabel">
            Three same digits cannot be used together
          </label>
        </li>
      </ul>
    </div>

    <form [formGroup]="form" (ngSubmit)="save()">
      <div class="password-content">
        <div class="form-group row form-group-padding custom-form-control">
          <div class="offset-4 col-8 colorRed" *ngIf="errors.apierror">
            *{{errors.apierror}}
          </div>
        </div>
        <div class="form-group row form-group-padding custom-form-control">
          <div class="offset-1 col-3">
            <label class="col-form-label col-form-label-sm inputLabel">
              Old/Temporary Password:
              <label class="colorRed">*</label>
            </label>
          </div>
          <div class="col-5">
            <input type="password" class="form-control form-control-sm rounded-form-fields" [value]="form.value.oldPwd"
              formControlName="oldPwd" [disabled]="forceChange" />
          </div>
          <div class="offset-4 col-8 colorRed" *ngIf="errors.old">
            *{{errors.old}}
          </div>
        </div>
        <div class=" form-group row form-group-padding custom-form-control">
          <label class="offset-1 col-3 col-form-label col-form-label-sm inputLabel">New
            Password:<label class="colorRed">*</label></label>
          <div class="col-5">
            <input type="password" class="form-control form-control-sm rounded-form-fields" [value]="form.value.newPwd"
              formControlName="newPwd" minlength="8" maxlength="15">
          </div>
          <div class="offset-4 col-8 colorRed" *ngFor="let new of errors.new">
            *{{new}}
          </div>
        </div>
        <div class=" form-group row form-group-padding custom-form-control">
          <label class="offset-1 col-3 col-form-label col-form-label-sm inputLabel">
            Confirm Password:
            <label class="colorRed">*</label>
          </label>
          <div class="col-5">
            <input type="Password" class="form-control form-control-sm rounded-form-fields"
              [value]="form.value.confirmPwd" formControlName="confirmPwd" minlength="8" maxlength="15">
          </div>
          <div class="offset-4 col-8 colorRed" *ngFor="let Confirm of errors.confirm">
            *{{Confirm}}
          </div>
          <div class="offset-4 col-8 colorRed" *ngIf="errors.newconfirm">
            *{{errors.newconfirm}}
          </div>
        </div>
      </div>
      <div class="form-group row form-group-padding form-buttons-margin">
        <div class="offset-4 col-6">
          <div class="row">
            <button class="custom-buttons login" type="submit">
              Save
            </button>
            <button class="custom-buttons login" type="button" (click)="cancel()">Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
</div>
