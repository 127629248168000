import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { InspectionCompany, AdminSettings, InspectionReport, InspectionCompanyState, userDetailsI, jurisdication, additionalPageAuthority, NotificationTemplate, RFA } from '../models/admin.model';
import { ListData } from '../../core/models/file-cabinet/file-cabinet.model';
import { AdminRegUserDetails } from '../models/admin.model';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getAdminSettings() {
    const url = `${this.baseUrl}/admin/settings`;
    return this.http.get<AdminSettings>(url);
  }

  setAdminSettings(data) {
    const url = `${this.baseUrl}/admin/settings`;
    return this.http.put<AdminSettings>(url, data);
  }

  getInspectionCompanies() {
    const url = `${this.baseUrl}/admin/inpectioncompanies`;
    return this.http.get<InspectionCompany[]>(url);
  }

  getInspectionReport(parameters) {
    const url = `${this.baseUrl}/admin/inspectionreports`;
    return this.http.post<InspectionReport[]>(url, parameters);
  }

  savePAC(companyId) {
    const url = `${this.baseUrl}/admin/savepac/${companyId}`;
    return this.http.put<any>(url, null);
  }
  
  saveCordinate() {
    const url = `${this.baseUrl}/property/location`;
    return this.http.put<any>(url, null);
  }

  searchInspectionCompanies(keyword) {
    const url = `${this.baseUrl}/inspector/search/${keyword}`;
    return this.http.get<InspectionCompanyState[]>(url);
  }

  uploadPropertyFile(file) {
    const formData = new FormData();
    formData.append(`file`, file, file.name);
    return this.http.post<Map<number, string[]>>(`${this.baseUrl}/property/upload`, formData);
  }

  getAdminRegistration(role: string, isPrimary: string) {
    let param: any = { 'role': role, 'isPrimary': isPrimary };
    const url = `${this.baseUrl}/user/getDetails`;
    return this.http.get<AdminRegUserDetails[]>(url, { params: param });
  }

  changeStatus(userId: string, status: string) {
    const url = `${this.baseUrl}/user/changeStatus/${userId}/${status}`;
    return this.http.post<any>(url, null);
  }

  getAssets() {
    const url = `${this.baseUrl}/assettype`;
    return this.http.get<any>(url);
  }

  putAssetsFee(body) {
    const url = `${this.baseUrl}/assettypes/fee`;
    return this.http.put<any>(url, body);
  }

  getUsers(userType = '') {
    const url = `${this.baseUrl}/user/primary`;
    return this.http.get<any>(url, { params: { role: userType } });
  }

  putUserDiscount(body) {
    const url = `${this.baseUrl}/user/fee`;
    return this.http.put<any>(url, body);
  }


  getAHJFee(id) {
    const url = `${this.baseUrl}/authorityassetfees/authority/${id} `;
    return this.http.get<any>(url);
  }

  putAHJFee(body) {
    const url = `${this.baseUrl}/authorityAssetFees`;
    return this.http.put<any>(url, body);
  }

  getJurisdications() {
    const url = `${this.baseUrl}/jurisdiction`;
    return this.http.get<jurisdication[]>(url);
  }

  getAuthorityPages(isWorxDept?: any) {
    const url = `${this.baseUrl}/page`;
    return this.http.get<additionalPageAuthority[]>(url, {
      params: isWorxDept?{
        isWorx: isWorxDept 
      } : undefined
    });
  }

  postAuthorityPages(authorityPage) {
    const url = `${this.baseUrl}/page`;
    return this.http.post<additionalPageAuthority[]>(url, authorityPage);
  }

  deletePage(id) {
    const url = `${this.baseUrl}/page/${id}`;
    return this.http.delete(url);
  }

  updateTemplate(notificationTemplate) {
    const url = `${this.baseUrl}/authority/jurisdiction/preferences/notificationtemplate`;
    return this.http.put(url, notificationTemplate);
  }

  getTemplatePreview(templateDto, propertyId) {
    const url = `${this.baseUrl}/jurisdiction/notification/preview/${propertyId}`;
    return this.http.post<ListData>(url, templateDto);
  }

  deleteTemplate(templateUrl) {
    const params = {filePath: templateUrl};
    const url = `${this.baseUrl}/report_thymeleaf/report`;
    return this.http.delete(url, { params});
  }

  getTemplate(jurisdiction, type, systemType) {
    const url = this.baseUrl + '/authority/preferences/notificationtemplatenew';
    return this.http.get<NotificationTemplate>(url, {
      params: {
        templateType: type,
        jurisdictionId: jurisdiction,
        department: systemType
      }
    });
  }

  getAhjFees(userId) {
    const url = `${this.baseUrl}/siteadmin/authorityfeesdetails/${userId}`;
    return this.http.get<any>(url);
  }

  setAhjFees(body) {
    const url = `${this.baseUrl}/siteadmin/authorityfeesdetails`;
    return this.http.put<any>(url, body);
  }

  getAssetsPerAuthority(userId) {
    const url = `${this.baseUrl}/authority/assetTypes/${userId}`;
    return this.http.get<any>(url);
  }

  getAdminJurisdication(jurisId) {
    const url = `${this.baseUrl}/RFA/availableAuthorityList/${jurisId}`;
    return this.http.get<any>(url);
  }

  postRFAAdmin(rfa) {
    const url = `${this.baseUrl}/RFA`;
    return this.http.post<RFA>(url, rfa);
  }

  postWorkXUser(rfa) {
    const url = `${this.baseUrl}/worx/register`;
    return this.http.post<RFA>(url, rfa);
  }

  putWorkXUser(rfa) {
    const url = `${this.baseUrl}/worx/user`;
    return this.http.put<any>(url, rfa);
  }
  putRFAUser(rfa) {
    const url = `${this.baseUrl}/RFA`;
    return this.http.put<any>(url, rfa);
  }

  getRFAUsers() {
    const url = `${this.baseUrl}/RFAList`;
    return this.http.get<any>(url);
  }

  deleteRFAuser(userId) {
    const url = `${this.baseUrl}/RFA/delete/${userId}`;
    return this.http.delete(url);
  }

  deleteWorkxuser(userId) {
    const url = `${this.baseUrl}/worx/user/${userId}`;
    return this.http.delete(url);
  }

  getWorkXUsers() {
    const url = `${this.baseUrl}/worx/userList`;
    return this.http.get<any>(url);
  }

  getWorxPPAAllowed() {
    const url = `${this.baseUrl}/worx/isPPAallowed`;
    return this.http.get<any>(url);
  }

  findAllUsers(searchString: string, offset, limit) {
    const url = `${this.baseUrl}/user/search`;
    return this.http.get<any>(url, {
      params: {
        searchString: searchString,
        offset: offset,
        limit: limit
      }
    })
  }

  getDepartments() {
    const url = this.baseUrl + '/systemType';
    return this.http.get<string[]>(url);
  }
  getJurisdicationonDept(isWorxDept?: any) {
    const url = `${this.baseUrl}/jurisdiction`;
    return this.http.get<jurisdication[]>(url, {
      params: isWorxDept?{
        isWorx: isWorxDept 
      } : undefined
    });
  }

}

