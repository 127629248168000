import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InspectionReportService {
  baseUrl = environment.baseUrl;

  constructor(
    private http: HttpClient
  ) { }


  getPropertyDetails(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/propertyDetails/${id}`);
  }

  getThymeLeafReport(id, isWaterMarkAllowed) {
    if(isWaterMarkAllowed === "false") {
      return this.http.get(`${this.baseUrl}/report_thymeleaf/report/inspection/${id}`, {
        params: {
          isWaterMarkAllowed: isWaterMarkAllowed
        }
      });
    }else {
      return this.http.get(`${this.baseUrl}/report_thymeleaf/report/inspection/${id}`);
    }
  }

  getPropertyManagerDetails(id) {
    return this.http.get(`${this.baseUrl}/property/manager/${id}`);
  }

  getPreviousInspectionReports(id) {
    return this.http.get(`${this.baseUrl}/inspection/property/${id}/previousreports`);
  }


  getInspectorDetails(id) {
    return this.http.get(`${this.baseUrl}/inspector/details/${id}`);
  }


  getInspectionResult(id) {
    return this.http.get(`${this.baseUrl}/inspection/${id}`);
  }

  getInspectorLetterHead(id, letterHeadType) {
    return this.http.get(`${this.baseUrl}/inspector/notification/${id}/${letterHeadType}`);
  }


  getAuthorityLetterHead(id, letterHeadType) {
    // return this.http.get(`${this.baseUrl}/authority/notificationtemplate/${id}/${letterHeadType}`);
    return this.http.get(`${this.baseUrl}/authority/preferences/notificationtemplatenew`, {
      params: {
        templateType: letterHeadType,
        propertyId: id,
        department: JSON.parse(localStorage.getItem('userDetails')).role.name === 'ROLE_AUTHORITY_WORX' ?
          'TEGRIS Worx' : 'TEGRIS Fire'
      }
    });
  }

  mailPdf(inspectionId, body) {
    const url = `${this.baseUrl}/report_thymeleaf/report/mail/inspection/${inspectionId}`;
    return this.http.post(url, body);
  }

  createJournalEntry(propertyId, propertyJournal) {
    const url = `${this.baseUrl}/propertyjournals/report/${propertyId}`;
    return this.http.post(url, propertyJournal);
  }
}

