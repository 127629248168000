import { Component, OnInit, OnDestroy, NgModule } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-abcd',
  template: '',
})
export class ParentComponent implements OnInit, OnDestroy {
  unsub: Subscription[] = [];
  environment = environment;


  constructor() { }
  ngOnInit() {
  }

  ngOnDestroy() {
      this.unsub.forEach(res => {
        res.unsubscribe();
      });
  }
}

@NgModule({
  declarations: [
    ParentComponent
    ],
  imports: [
    CommonModule,
  ],
  exports: [
    ParentComponent
  ]
})
export class ParentModule { }



