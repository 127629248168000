import { OnInit } from "@angular/core";
import { MatDialogRef, } from "@angular/material";
var DeleteRequestDialogComponent = /** @class */ (function () {
    function DeleteRequestDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    DeleteRequestDialogComponent.prototype.ngOnInit = function () {
    };
    DeleteRequestDialogComponent.prototype.onClose = function (submit) {
        // let response: any;
        // response.sub = submit;
        this.comments = this.comments ? this.comments : '';
        this.dialogRef.close({ sub: submit, comments: this.comments });
    };
    return DeleteRequestDialogComponent;
}());
export { DeleteRequestDialogComponent };
