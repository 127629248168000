import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
var AddJournalDialogComponent = /** @class */ (function () {
    function AddJournalDialogComponent(dialogRef, data, fb, journalDetialServices, 
    // private _snackBar: MatSnackBar,
    propertyService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.fb = fb;
        this.journalDetialServices = journalDetialServices;
        this.propertyService = propertyService;
        this.journalFormPayload = {};
    }
    AddJournalDialogComponent.prototype.ngOnInit = function () {
        this.addJournalForm = this.fb.group({
            subject: [, Validators.required],
            accessType: ['Private', Validators.required],
            content: [, Validators.required]
        });
        this.isAuthority = false;
        this.isOwner = false;
        this.isInspector = false;
    };
    AddJournalDialogComponent.prototype.checkAuthority = function (event) {
        this.isAuthority = event.target.checked ? true : false;
    };
    AddJournalDialogComponent.prototype.checkOwner = function (event) {
        this.isOwner = event.target.checked ? true : false;
    };
    AddJournalDialogComponent.prototype.checkInspector = function (event) {
        this.isInspector = event.target.checked ? true : false;
    };
    AddJournalDialogComponent.prototype.setAccessType = function () {
        var accessType = this.addJournalForm.value.accessType.toString();
        if (accessType === 'Public') {
            return 'Public';
        }
        else if (accessType === 'Private') {
            return 'Private';
        }
        else if (accessType === 'Specific') {
            if (this.isAuthority && this.isInspector && this.isOwner) {
                return 'Specific/Inspector,Authority,Owner';
            }
            else if (this.isInspector && this.isAuthority) {
                return 'Specific/Inspector,Authority';
            }
            else if (this.isOwner && this.isInspector) {
                return 'Specific/Owner,Inspector';
            }
            else if (this.isOwner && this.isAuthority) {
                return 'Specific/Owner,Authority';
            }
            else if (this.isInspector) {
                return 'Specific/Inspector';
            }
            else if (this.isOwner) {
                return 'Specific/Owner';
            }
            else if (this.isAuthority) {
                return 'Specific/Authority';
            }
        }
        return 'null';
    };
    AddJournalDialogComponent.prototype.onSubmit = function () {
        var _this = this;
        var accessType = this.setAccessType();
        if (this.addJournalForm.valid && accessType) {
            this.journalFormPayload = {
                subject: this.addJournalForm.value.subject.toString(),
                accessControl: accessType,
                body: this.addJournalForm.value.content.toString(),
                inspectionId: 0
            };
            var propertyId = this.data.propertyId || this.propertyService.propertyId$.getValue();
            this.journalDetialServices.postJournalEntry(this.journalFormPayload, propertyId).subscribe(function (res) {
                alert('Journal Saved Successfully');
                _this.dialogRef.close();
            }, function (error) {
                console.log(error);
                _this.dialogRef.close();
            });
        }
        else {
            console.error('Empty Form');
        }
    };
    AddJournalDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return AddJournalDialogComponent;
}());
export { AddJournalDialogComponent };
