import { Component, OnInit } from '@angular/core';
import { SwUpdate, SwPush } from '@angular/service-worker';
import { PushNotificationsService } from '@app-core/services';
import { UserService } from './shared/services/user.service';
import { NavigationEnd, Router } from '@angular/router';
import { Roles } from '../app/shared/models/user.model';
import { LoginService } from './login/services/login.service';

// declare ga as a function to set and sent the events
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent implements OnInit {
  title = 'TegrisFire';
  newContentAvailable = false;
  user: any;
  userDetails: any;
  showLogin: any;

  constructor(
    public swPush: SwPush,
    private swUpdate: SwUpdate,
    private pushNotificationsService: PushNotificationsService,
    private userService: UserService,
    private router: Router,
    private loginService: LoginService
  ) {
    // Google Analytics
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('set', 'page', event.urlAfterRedirects);
        gtag('send', 'pageview');
      }
    });
    // Google Analytics   

    /** Check for new content available in the service worker */
    this.swUpdate.available.subscribe(update => {
      /** TODO */
      /** Show popup or notification that angular has new content that is available for serve */
      /** Reload the application if you want to serve the new application */
      this.newContentAvailable = true;
    });
    const userL = JSON.parse(localStorage.getItem('userDetails'));
    if (userL) {
      this.userService.userDetails = userL;
    }
  }

  ngOnInit() {
    if (document.location.href.indexOf('user') < 0 && document.location.href.indexOf('printReport') < 0) {
      this.user = JSON.parse(localStorage.getItem('userDetails'));
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      if (this.userDetails && this.userDetails.isForcePasswordChanged) {
        this.router.navigate(['login/force-change-password']);
      } else if (this.user && !this.user['isPreferenceSet']) {
        if (this.user.role['id'] === Roles.ROLE_OWNER_ADMIN) {
          this.router.navigate(['user/owner/preferences']);
        } else if (this.user.role['id'] === Roles.ROLE_AUTHORITY_ADMIN || this.user.role['id'] === Roles.ROLE_AUTHORITY_WORX) {
          this.router.navigate(['user/authority/authorityPreference']);
        } else if (this.user.role['id'] === Roles.ROLE_INSPECTOR_ADMIN) {
          this.router.navigate(['user/inspector/preferencesInspector']);
        } else if (this.user.role['id'] === Roles.ROLE_RFA) {
          this.router.navigate(['user/authority/authorityPreference']);
        }
      } else {
        if (this.user) {
          if (this.user.role['id'] === Roles.ROLE_OWNER_ADMIN) {
            this.router.navigate(['user/owner/dashboard']);
          } else if (this.user.role['id'] === Roles.ROLE_AUTHORITY_ADMIN || this.user.role['id'] === Roles.ROLE_AUTHORITY_WORX) {
            this.router.navigate(['user/authority/dashboardAuthority']);
          } else if (this.user.role['id'] === Roles.ROLE_INSPECTOR_ADMIN) {
            this.router.navigate(['user/inspector/dashboardInspector']);
          } else if (this.user.role['id'] === Roles.ROLE_RFA) {
            this.router.navigate(['user/authority/dashboardAuthority']);
          } else {
            this.router.navigate(['user/admin/dashboardSiteAdmin']);
          }
        }
      }
    }
  }

  /** Refresh the application when new content is available */
  refreshApplication() {
    window.location.reload();
  }

  subscribeToNotifications() {
    this.pushNotificationsService.subscribeNotifications();
  }

  unSubscribeNotifications() {
    this.pushNotificationsService.unsubscribeToPushNotification();
  }


}
