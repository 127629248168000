export const paymentStatic = {
    userId: null,
    requestCallerReference: null,
    paymentCallerReference: null,
    credit: null,
    transactionAmount: null,
    transactionId: null,
    transactionStatus: null,
    status: null,
    createdOn: null,
    source: null,
    retunUrl: window.location.href,
    resultCode: null,
    signature: null,
    sellerId: null,
    awsAccessKeyId: null,
    orderReferenceId: null,
    propAssetIds: null,


};
