import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import { switchMap, tap, map } from 'rxjs/operators';
import { InspectionReportService } from '../../core/services/inspection/inspection-report.service';
import { HeadingQuestion, InspectionData } from '../../core/models/inspection/inspection.model';
import { InspectionService } from '../../core/services/inspection/inspection.service';

import { MatDialog } from '@angular/material';
import { EmailComponent } from './email/email.component';
import { AuthorityService } from '../../authority/shared/services/authority.service';
import { InspectorService } from '../../inspector/services/inspector.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AdminService } from '../../admin/services/admin.service';

@Component({
  selector: 'app-inspection-report',
  templateUrl: './inspection-report.component.html',
  styleUrls: ['./inspection-report.component.scss']
})
export class InspectionReportComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('printReport', { static: false }) content: ElementRef;
  propDetails = {} as any;
  propManagerDetails = {} as any;
  inspectorDetails = {} as any;
  inspectionResult = {} as any;
  currDate = new Date();
  unsub = [];
  qtd;
  questions: Array<HeadingQuestion>;
  inspectorLetterHead = {} as any;
  authorityLetterHead = {} as any;
  deficiencies = [];
  noCorrectionRequired: boolean;
  assetTypeId: string;
  propId: string;
  images;
  inspectionDataforSecondPage: InspectionData;
  prvRows: any;
  hoseRows: any;
  sequence: any;
  printBlank: boolean;
  userRole: string;
  loading: boolean;
  inspectionResultRendering: any;
  _printIframe: any;
  inspectionResultRenderingIframe: any;
  inspectionResultId: number;
  constructor(
    // private sendMailService: SendmailService,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    // public dialogRef: MatDialogRef<any>,
    private inspectionReportService: InspectionReportService,
    private ar: ActivatedRoute,
    private inspectionService: InspectionService,
    private dialog: MatDialog,
    private authorityService: AuthorityService,
    private inspectorService: InspectorService,
    private adminService: AdminService,
    private sanitizer: DomSanitizer
  ) { }

  @HostListener('window:beforeunload', ['$event']) unloadHandler(event) {
    if (this.inspectionResultRendering.length > 0) {
      this.unsub.push(this.adminService.deleteTemplate(this.inspectionResultRendering)
        .subscribe(res => {
        // Do nothing
      }));
    }   
  }

  ngOnInit() {
    this.inspectionDataforSecondPage = {};
    this.userRole = localStorage.getItem('userRole');
    this.images = {};
    this.prvRows = [];
    this.hoseRows = [];
    this.sequence = [];
    // console.log(jsPDF)
    this.assetTypeId = null;
    this.propId = null;
    this.inspectionResultRendering = [];
    this.qtd = {};
    // console.log(this.data.previousResultId);
    this.ar.queryParams
      .subscribe(params => {
        // console.log(params); // {order: 'popular'}
        const reportId = params.previousResultId;
        this.assetTypeId = params.assetTypeId;
        this.propId = params.propId;
        this.inspectionResultId = +params[`previousResultId`];
        this.inspectionReportService.getInspectionResult(this.inspectionResultId).subscribe((res) => {
          this.inspectionResult = res;
          this.inspectionDataforSecondPage.inspectionType = res['inspectionType'];
          this.inspectionDataforSecondPage.inspectionAttachment = res['inspectionAttachment'];
        });
        if (reportId) {
          this.loading = true;
          this.printBlank = false;
          const isWaterMarkAllowed = params.isWaterMarkAllowed;
          this.inspectionReportService.getThymeLeafReport(reportId, isWaterMarkAllowed).subscribe(
            (res) => {
              if (res) {
                this.inspectionResultRendering = res['1'][0];
                this.inspectionResultRenderingIframe = this.sanitizer.bypassSecurityTrustResourceUrl(res['1'][0]);
              }
            }, (err) => {
              alert("Report generation failed");
            }
          );
          // this.inspectionService.getPropertyDetailsById(this.propId).pipe(map(res => {
          //   if (res) {
          //     return res;
          //   }
          // }), switchMap(property => {
          //   return this.inspectionService.getQuestionsByReportId(reportId, +property.jurisdictionId);
          // })).subscribe((res) => {
          //   if (res) {
          //     this.questions = res.sort((a, b) => {
          //       return a.questions.sequence - b.questions.sequence;
          //     });
          //     this.questions.forEach((question) => {
          //       if (question && question.questions) {
          //         this.qtd[question.questions.questionId] = question.questions.answer;
          //         if (question.questions.answer && question.questions.answer[0] === 'N/A' && question.questions.optionType === 'Text'
          //           && question.questions.options) {
          //           this.qtd[question.questions.questionId + 'checkbox'] = true;
          //           this.sequence.push(question.questions.sequence + 1);
          //         }
          //       }
          //       if (question.questions.optionType === 'Table') {
          //         if (question.questions.answer && question.questions.answer[0]) {
          //           const finalAnswer = question.questions.answer[0].split('~');
          //           for (let i = 0; i < finalAnswer.length; i++) {
          //             this.qtd[question.questions.questionId + '~m~' + i + '~' + finalAnswer.length] = finalAnswer[i];
          //           }
          //         }
          //       }
          //       if (question.questions.questionType === 'PRV') {
          //         if (question.questions.answer && question.questions.answer[0]) {
          //           this.inspectionDataforSecondPage.showPRV = true;
          //           const finalAnswer = question.questions.answer[0].split('~');
          //           if (this.prvRows.length < finalAnswer.length) {
          //             for (let k = 0; k < finalAnswer.length; k++) {
          //               this.prvRows.push({ id: k });
          //             }
          //           }
          //           this.inspectionService.setStandPipeRows(finalAnswer.length);
          //           for (let i = 0; i < finalAnswer.length; i++) {
          //             this.prvRows[i][question.questions.question] = finalAnswer[i];
          //           }
          //         }
          //       }
          //       if (question.questions.questionType === 'ClassII') {
          //         if (question.questions.answer && question.questions.answer[0]) {
          //           this.inspectionDataforSecondPage.showHose = true;
          //           const finalAnswer = question.questions.answer[0].split('~');
          //           if (this.hoseRows.length < finalAnswer.length) {
          //             for (let k = 0; k < finalAnswer.length; k++) {
          //               this.hoseRows.push({ id: k });
          //             }
          //           }
          //           this.inspectionService.setHoseCount(finalAnswer.length);
          //           for (let i = 0; i < finalAnswer.length; i++) {
          //             this.hoseRows[i][question.questions.question] = finalAnswer[i];
          //           }
          //         }
          //       }
          //       if (question && question.questions && question.questions.images && question.questions.images.length > 0) {
          //         const imagesForNextScreen = [];
          //         question.questions.images.forEach((x) => {
          //           imagesForNextScreen.push({ imageContent: x.imageContent, imagePlacement: x.imagePlacement });
          //         });
          //         this.images[question.questions.questionId] = imagesForNextScreen;
          //         if (question.questions.question === 'Pictures of Hydrant (front and back wide enough to show clearance)') {
          //           if (question.questions.images) {
          //             if (question.questions.images.filter((x) => x.imagePlacement === 'Front').length === 0) {
          //               //this.noFrontImage = true;
          //             }
          //             if (question.questions.images.filter((x) => x.imagePlacement === 'Back').length === 0) {
          //              // this.noBackImage = true;
          //             }
          //           }
          //         }
          //       }
          //       if (question && question.questions && question.questions.subquestion) {
          //         question.questions.subquestion.forEach((x) => {
          //           if (x && x.images && x.images.length > 0) {
          //             const imagesForNextScreen = [];
          //             x.images.forEach((y) => {
          //               imagesForNextScreen.push({ imageContent: y.imageContent, imagePlacement: y.imagePlacement });
          //             });
          //             this.images[x.questionId] = imagesForNextScreen;
          //           }
          //           this.qtd[x.questionId] = x.answer ? x.answer[0] : null;
          //           if (x.optionType === 'Radio') {
          //             x.options = x.options.sort((a, b) => {
          //               return a.sequence - b.sequence;
          //             });
          //           }
          //           if (x.answer && x.answer[0] === 'N/A' && x.optionType === 'Text' && x.options) {
          //             this.qtd[x.questionId + 'checkbox'] = true;
          //           }
          //           if (x.subquestion && x.subquestion.length > 0) {
          //             if (x.subquestion[0].optionType === 'Text') {
          //               if (x.subquestion[0].answer) {
          //                 this.qtd[x.subquestion[0].questionId] = x.subquestion[0].answer[0];
          //               }
          //             } else {
          //               if (x.subquestion[0].answer && x.subquestion[0].answer.length > 0) {
          //                 // tslint:disable-next-line: prefer-for-of
          //                 for (let i = 0; i < x.subquestion[0].answer.length; i++) {
          //                   this.qtd[x.subquestion[0].questionId + '*o*' + x.subquestion[0].answer[i]] = true;
          //                 }
          //               }
          //             }
          //           }

          //         });
          //       }
          //       if (question && question.questions.optionType === 'Radio' ||
          //         question.questions.questionType === 'Test Results Acceptable') {
          //         if (question.questions.answer) {
          //           this.qtd[question.questions.questionId] = question.questions.answer[0];
          //         }
          //         question.questions.options = question.questions.options.sort((a, b) => {
          //           return a.sequence - b.sequence;
          //         });
          //         if (question && question.questions.subquestion) {
          //           question.questions.subquestion.forEach((x) => {
          //             this.qtd[x.questionId] = x.answer ? x.answer[0] : null;
          //             if (x.optionType === 'Radio') {
          //               x.options = x.options.sort((a, b) => {
          //                 return a.sequence - b.sequence;
          //               });
          //             }
          //           });
          //         }
          //       }
          //       if (question && question.questions.optionType === 'Checkbox') {
          //         question.questions.options = question.questions.options.sort((a, b) => {
          //           return a.sequence - b.sequence;
          //         });
          //         if (question.questions.answer && question.questions.answer.length > 0) {
          //           // tslint:disable-next-line: prefer-for-of
          //           for (let i = 0; i < question.questions.answer.length; i++) {
          //             this.qtd[question.questions.questionId + '*o*' + question.questions.answer[i]] = true;
          //           }
          //         }
          //       }
          //     });
          //     this.inspectionService.sePVRRows(this.prvRows);
          //     this.inspectionService.setHoseRows(this.hoseRows);
          //     this.inspectionDataforSecondPage.sequence = this.sequence;
          //   } else {
          //     this.questions = [];
          //   }
          // });
        } else if (this.assetTypeId) {
          this.loading = false;
          this.inspectionResultRendering = null;
          if (this.userRole === 'ROLE_AUTHORITY' || this.userRole === 'ROLE_AUTHORITY_WORX') {
            this.authorityService.getLetterHead().subscribe((x) => {
              this.authorityLetterHead = x;
            });
          } else {
            this.inspectorService.getLetterHead().subscribe((response) => {
              this.inspectorLetterHead = response;
            });
          }
          this.printBlank = true;
          this.inspectionService.setStandPipeRows(5);
          this.inspectionService.setHoseCount(5);
          let jurisdicationId = 0;
          const userDetails = JSON.parse(localStorage.getItem('userDetails'));
          if (userDetails.jurisdiction) {
            jurisdicationId = userDetails.jurisdiction.id;
          }
          this.inspectionService
            .getQuestions(+this.assetTypeId, jurisdicationId)
            .subscribe(res => {
              if (res) {
                this.questions = res.sort((a, b) => {
                  return a.questions.sequence - b.questions.sequence;
                });
              }
            });
        }
      });
  }

  ngOnDestroy() {
    this.unsub.forEach(res => res.unsubscribe());
  }

  ngAfterViewInit() {
    let params = {};
    if (!this.ar.snapshot.queryParams.propId) {
      return;
    }
    const unsub = this.ar.queryParams.pipe(
      switchMap(res => {
        params = res;
        return this.inspectionReportService.getPropertyDetails(res[`propId`]);
      }),
      tap(res => {
        this.propDetails = res;
        // console.log(this.propDetails);

        const a = (this.propDetails[`ownerAddress`] || '').split(',');
        this.propDetails[`jurisdiction`] = a.splice(-3).join(',');
        this.propDetails[`address1`] = a.join(',');
      }),
      switchMap(res =>
        this.inspectionReportService.getPropertyManagerDetails(
          params[`propId`]
        )
      ),
      tap(res => (this.propManagerDetails = res)),

      switchMap(res => this.inspectionReportService.getInspectionResult(+params[`previousResultId`])),
      tap((res) => {
        this.inspectionResult = res;
        this.inspectionDataforSecondPage.inspectionType = res['inspectionType'];
        this.inspectionDataforSecondPage.inspectionAttachment = res['inspectionAttachment'];
        if (this.inspectionResult.inspectionDeficiency && this.inspectionResult.inspectionDeficiency.length > 0) {
          const filteredDefs = this.inspectionResult.inspectionDeficiency.filter(x => x.isCorrected).length;
          if (filteredDefs === this.inspectionResult.inspectionDeficiency.length) {
            this.noCorrectionRequired = true;
          } else {
            this.noCorrectionRequired = false;
          }
        }
      }),
      // switchMap(res => this.inspectionReportService.getPreviousInspectionReports(this.data.prop.id)),
      // filter((res: any) => res.filter(dat => dat.propertyAssetId === this.data.sys.id)[0].inpectedById),
      switchMap(res => {

        // const a = res.filter(dat => dat.propertyAssetId === this.data.sys.id)[0];
        return this.inspectionReportService.getInspectorDetails(this.inspectionResult[`inspectedById`]);
      }),
      tap(res => this.inspectorDetails = res),
      switchMap(res => this.inspectionReportService.getInspectorLetterHead(this.inspectionResult[`inspectedById`], 'LetterHead')),
      tap(res => this.inspectorLetterHead = res),
      switchMap(res => this.inspectionReportService.getAuthorityLetterHead(+params[`propId`], 'LetterHead')),
      tap(res => this.authorityLetterHead = res)

    )
      .subscribe(res => {
        // console.log(res)
        if (this.inspectionResult.propertyAsset.isActive) {

          this.addWaterMark(false, this.inspectionResult.propertyAsset.inspectionStatus.name === 'Submitted_For_Review');

        }

      });
    this.unsub.push(unsub);
  }

  emailReport() {

    const dialogRef = this.dialog.open(EmailComponent, {
      width: '800px',
      data: {
        to: '',
        cc: '',
        subject: 'Inspection Report',
        // attachment: blob,
        body: '',
        inspectionResultId: this.inspectionResultId
      }
    });

      // this.inspectionReportService.sendPdf(formData)
      //   .subscribe(data => {
      //     const msg = data[`message`];
      //     alert(msg);
      //     // console.log(data, 'success');
      //   }, error => {
      //     console.error(error, 'error');
      //   });
    // });
  }

  convertToPdf() {
    const doc = new jsPDF();
    doc.addHTML(document.getElementById('parentdiv'), function () {
      doc.save("report.pdf");
    });
  }

  createPDF() {

    const propertyJournal = {
      body:  'Save Pdf' ,
      accessControl : 'Private',
      subject: 'Save Inspection Report as PDF'
     };

    this.inspectionReportService.createJournalEntry(this.propId, propertyJournal).subscribe(
      res => {}
    );

    // this.inspectionReportService.getReport(this.inspectionResultRendering).subscribe((res) => {

    // });
    // window.scrollTo(0, 0);
    // if (this.inspectionResult.propertyAsset.isActive) {
    //   this.addWaterMark(true, this.inspectionResult.propertyAsset.inspectionStatus.name === 'Submitted_For_Review');

    // }

    // const HTML_WIDTH = document.querySelector('#parentdiv').clientWidth;
    // const HTML_HEIGHT = document.querySelector('#parentdiv').clientHeight;
    // const TOP_LEFT_MARGIN = 15;
    // const PDF_WIDTH = HTML_WIDTH + (TOP_LEFT_MARGIN * 2);
    // const PDF_HEIGHT = (PDF_WIDTH * 1.5) + (TOP_LEFT_MARGIN * 2);
    // const CANVAS_IMAGE_WIDTH = HTML_WIDTH;
    // const CANVAS_IMAGE_HEIGHT = HTML_HEIGHT;
    // const totalPDFPages = Math.ceil(HTML_HEIGHT / PDF_HEIGHT) - 1;
    // console.log(totalPDFPages);

    // html2canvas(document.querySelector('#parentdiv')).then(canvas => {

    //   // const pdf = new jsPDF('p', 'pt', [PDF_WIDTH, PDF_HEIGHT]);

    //   // const imgData = canvas.toDataURL('image/jpeg', 1.0);
    //   // pdf.addImage(imgData, 0, 0, canvas.width, canvas.height);
    //   // pdf.save('report.pdf');
    //   canvas.getContext('2d');

    //   // console.log(canvas.height+'  '+canvas.width);
    //   // console.log(canvas)


    //   const imgData = canvas.toDataURL('image/jpeg', 1.0);
    //   // const i = document.createElement('img');
    //   // i.src = imgData
    //   // document.body.appendChild(i);
    //   const pdf = new jsPDF('p', 'pt', [PDF_WIDTH, PDF_HEIGHT]);

    //   pdf.addImage(imgData, 'JPG', TOP_LEFT_MARGIN, TOP_LEFT_MARGIN, CANVAS_IMAGE_WIDTH, CANVAS_IMAGE_HEIGHT);

    //   for (let i = 1; i <= totalPDFPages; i++) {
    //     pdf.addPage(PDF_WIDTH, PDF_HEIGHT);
    //     pdf.addImage(
    //       imgData, 'JPG', TOP_LEFT_MARGIN, - (PDF_HEIGHT * i) + (TOP_LEFT_MARGIN * 4), CANVAS_IMAGE_WIDTH, CANVAS_IMAGE_HEIGHT);
    //   }

    //   pdf.save('HTML-Document.pdf');
    //   this.removeVisibleWaterMark();

    // });



  }

  addWaterMark(visible = false, submittedForReview = false) {
    const p = document.querySelector('#parentdiv');
    if(p !== null){
    const pHeight = p.scrollHeight;
    const windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    let noOfWatermark = pHeight / windowHeight;
    noOfWatermark = Math.ceil(noOfWatermark / 2);
    for (let i = 0; i < noOfWatermark; i++) {
      const elem = document.createElement('div');
      elem.className = `${visible ? 'visible-water-mark' : 'water-mark'}`;
      elem.style.cssText = `
      top: ${60 * (i === 0 ? i + 1 : i + (visible ? 2.5 : 1))}vh;
      `;
      if (submittedForReview) {
        elem.style.cssText += `
          font-size: 8vw;
          left: -15vw;
          width: 133%;
        `;
      }
      elem.innerText = submittedForReview ? 'Under Review by Fire Marshal' : 'Unofficial Copy';
      p.appendChild(elem);
    }
  }
  }

  printTheReport() {
    // let iframe = this._printIframe;
    // if (!this._printIframe) {
    //   iframe = this._printIframe = document.createElement('iframe');
    //   document.body.appendChild(iframe);

    //   iframe.style.display = 'none';
    //   iframe.onload = function() {
    //     setTimeout(function() {
    //       iframe.focus();
    //       iframe.contentWindow.print();
    //     }, 1);
    //   };
    // }
    // iframe.src = `${this.inspectionResultRendering}`;
   // window.open(`${this.inspectionResultRendering}`).print();
   const propertyJournal = {
     body:  'Print' ,
     accessControl : 'Private',
     subject: 'Print Inspection Report'
    };
   this.inspectionReportService.createJournalEntry(this.propId, propertyJournal).subscribe(
     res => {}
   );
   window.print();
  }

  cancelReport() {
    window.open(location.pathname, '_self').close();
  }

  removeVisibleWaterMark() {
    const a: any = document.querySelectorAll('.visible-water-mark');
    for (const i of a) {
      i.parentNode.removeChild(i);

    }
  }

  textLayerRendered(e: CustomEvent) {
    // console.log('(text-layer-rendered)', e);
    this.loading = false;
  }
}
