import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import {
  SearchCriteria,
  ListData,
  AssetDetail,
  FileCabinetHistory,
  FileCabinetResponse,
  FileCabinetResponse1,
} from "../../models/file-cabinet/file-cabinet.model";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FileCabinetService {
  baseUrl = environment.baseUrl;
  owner: any;
  constructor(private http: HttpClient) {}

  public getFileCabinetProperties(searchCriteria: SearchCriteria) {
    const url = `${this.baseUrl}/fileCabinet`;
    if (searchCriteria.systemType === "Backflow Serial Number") {
      const searchCriteriaSlice = { ...searchCriteria };
      searchCriteriaSlice.systemType = "";
      return this.http.post<FileCabinetResponse>(url, searchCriteriaSlice);
    } else {
      return this.http.post<FileCabinetResponse>(url, searchCriteria);
    }
  }

  public getFileCabinetProperties1(searchCriteria: SearchCriteria) {
    const url = `${this.baseUrl}/fileCabinet`;
    if (searchCriteria.systemType === "Backflow Serial Number") {
      const searchCriteriaSlice = { ...searchCriteria };
      searchCriteriaSlice.systemType = "";
      return this.http.post<FileCabinetResponse1>(url, searchCriteriaSlice);
    } else {
      return this.http.post<FileCabinetResponse1>(url, searchCriteria);
    }
  }

  public getCasesProperties(searchCriteria: SearchCriteria) {
    const url = `${this.baseUrl}/casesPropertySearch`;
    return this.http.post<FileCabinetResponse>(url, searchCriteria);
  }
  public getDeficiencyStatusList() {
    const url = `${this.baseUrl}/fileCabinet/deficiencyStatusList`;
    return this.http.get<ListData[]>(url);
  }

  public getInspectionStatusList() {
    const url = `${this.baseUrl}/fileCabinet/inspectionStatusList`;
    return this.http.get<ListData[]>(url);
  }

  public getPropertyStatusList() {
    const url = `${this.baseUrl}/fileCabinet/propertyStatusList`;
    return this.http.get<ListData[]>(url);
  }

  public getNotificationStatusList() {
    const url = `${this.baseUrl}/fileCabinet/notificationStatusList`;
    return this.http.get<ListData[]>(url);
  }

  getAssetsForInspector(dept) {
    const url = `${this.baseUrl}/inspector/preferences/${dept}/assetType`;
    return this.http.get<any[]>(url);
  }

  getAssestsOfDepatment(dept) {
    // /api/{systemType}/assettype
    const url = `${this.baseUrl}/${dept}/assettype`;
    return this.http.get<any[]>(url);
  }

  getDepartments() {
    const url = this.baseUrl + "/systemType";
    return this.http.get<string[]>(url);
  }

  removeCustomer(propertyId: any) {
    const url = `${this.baseUrl}/fileCabinet/removeCustomer/${propertyId}`;
    return this.http.delete(url);
  }

  getAssignedUsers() {
    const url = `${this.baseUrl}/user/assignedUser`;
    return this.http.get<ListData[]>(url);
  }

  getAllOwners(limit, offset, searchKey) {
    const url = `${this.baseUrl}/owner`;
    return this.http.get<any>(url, {
      params: {
        searchString: searchKey,
        limit: limit,
        offset: offset,
      },
    });
  }

  changeOwner(propertyId, userId) {
    const url = `${this.baseUrl}/fileCabinet/changeOwner/${propertyId}/${userId}`;
    return this.http.post(url, null);
  }

  getActiveJurisdictions() {
    const url = `${this.baseUrl}/jurisdiction`;
    return this.http.get<ListData[]>(url);
  }

  getAllAssetTypes() {
    const url = `${this.baseUrl}/siteAdmin/assetTypes`;
    return this.http.get<AssetDetail[]>(url);
  }

  updatePropertyAssignment(assignmentId, propertyIdList) {
    const url = `${this.baseUrl}/assign/property/${assignmentId}`;
    return this.http.post(url, propertyIdList);
  }

  setOwnerDetails(owner: any) {
    this.owner = owner;
  }

  getOwnerDetails() {
    return this.owner;
  }

  getFileCabinetHistory() {
    const url = `${this.baseUrl}/fileCabinet/propertyActionHistory`;
    return this.http.get<FileCabinetHistory[]>(url);
  }

  addPropertyActionHistory(propertyId) {
    const url = `${this.baseUrl}/fileCabinet/propertyActionHistory/${propertyId}`;
    return this.http.post<FileCabinetHistory>(url, null);
  }

  deleteAssetUnderSPA(propAssetId, authComments) {
    const url = `${this.baseUrl}/spa/propertyAsset`;
    const params = {
      propertyAssetId: propAssetId,
      authorityComments: authComments,
    };
    return this.http.delete(url, { params });
  }
}
