import * as i0 from "@angular/core";
import * as i1 from "./user.service";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
var AdminGuard = /** @class */ (function () {
    function AdminGuard(userService, router, location) {
        this.userService = userService;
        this.router = router;
        this.location = location;
    }
    AdminGuard.prototype.canActivate = function () {
        var a = this.userService.userDetails.role.name === 'ROLE_SITE_ADMIN';
        if (a) {
            return a;
        }
        this.location.back();
        return a;
    };
    AdminGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminGuard_Factory() { return new AdminGuard(i0.ɵɵinject(i1.UserService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Location)); }, token: AdminGuard, providedIn: "root" });
    return AdminGuard;
}());
export { AdminGuard };
