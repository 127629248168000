import { OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { Roles } from '../app/shared/models/user.model';
var AppComponent = /** @class */ (function () {
    function AppComponent(swPush, swUpdate, pushNotificationsService, userService, router, loginService) {
        var _this = this;
        this.swPush = swPush;
        this.swUpdate = swUpdate;
        this.pushNotificationsService = pushNotificationsService;
        this.userService = userService;
        this.router = router;
        this.loginService = loginService;
        this.title = 'TegrisFire';
        this.newContentAvailable = false;
        // Google Analytics
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                gtag('set', 'page', event.urlAfterRedirects);
                gtag('send', 'pageview');
            }
        });
        // Google Analytics   
        /** Check for new content available in the service worker */
        this.swUpdate.available.subscribe(function (update) {
            /** TODO */
            /** Show popup or notification that angular has new content that is available for serve */
            /** Reload the application if you want to serve the new application */
            _this.newContentAvailable = true;
        });
        var userL = JSON.parse(localStorage.getItem('userDetails'));
        if (userL) {
            this.userService.userDetails = userL;
        }
    }
    AppComponent.prototype.ngOnInit = function () {
        if (document.location.href.indexOf('user') < 0 && document.location.href.indexOf('printReport') < 0) {
            this.user = JSON.parse(localStorage.getItem('userDetails'));
            this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
            if (this.userDetails && this.userDetails.isForcePasswordChanged) {
                this.router.navigate(['login/force-change-password']);
            }
            else if (this.user && !this.user['isPreferenceSet']) {
                if (this.user.role['id'] === Roles.ROLE_OWNER_ADMIN) {
                    this.router.navigate(['user/owner/preferences']);
                }
                else if (this.user.role['id'] === Roles.ROLE_AUTHORITY_ADMIN || this.user.role['id'] === Roles.ROLE_AUTHORITY_WORX) {
                    this.router.navigate(['user/authority/authorityPreference']);
                }
                else if (this.user.role['id'] === Roles.ROLE_INSPECTOR_ADMIN) {
                    this.router.navigate(['user/inspector/preferencesInspector']);
                }
                else if (this.user.role['id'] === Roles.ROLE_RFA) {
                    this.router.navigate(['user/authority/authorityPreference']);
                }
            }
            else {
                if (this.user) {
                    if (this.user.role['id'] === Roles.ROLE_OWNER_ADMIN) {
                        this.router.navigate(['user/owner/dashboard']);
                    }
                    else if (this.user.role['id'] === Roles.ROLE_AUTHORITY_ADMIN || this.user.role['id'] === Roles.ROLE_AUTHORITY_WORX) {
                        this.router.navigate(['user/authority/dashboardAuthority']);
                    }
                    else if (this.user.role['id'] === Roles.ROLE_INSPECTOR_ADMIN) {
                        this.router.navigate(['user/inspector/dashboardInspector']);
                    }
                    else if (this.user.role['id'] === Roles.ROLE_RFA) {
                        this.router.navigate(['user/authority/dashboardAuthority']);
                    }
                    else {
                        this.router.navigate(['user/admin/dashboardSiteAdmin']);
                    }
                }
            }
        }
    };
    /** Refresh the application when new content is available */
    AppComponent.prototype.refreshApplication = function () {
        window.location.reload();
    };
    AppComponent.prototype.subscribeToNotifications = function () {
        this.pushNotificationsService.subscribeNotifications();
    };
    AppComponent.prototype.unSubscribeNotifications = function () {
        this.pushNotificationsService.unsubscribeToPushNotification();
    };
    return AppComponent;
}());
export { AppComponent };
