import { ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
var ReadOnlyDirective = /** @class */ (function () {
    function ReadOnlyDirective(userService, el, renderer) {
        this.userService = userService;
        this.el = el;
        this.renderer = renderer;
    }
    ReadOnlyDirective.prototype.ngAfterViewInit = function () {
        if (this.userService.userDetails.accessSpecifier === 'READ_ONLY') {
            var div = this.renderer.createElement('span');
            this.renderer.setStyle(div, 'cursor', 'pointer');
            var uuid = uuidv4();
            this.renderer.setAttribute(div, 'id', uuid);
            $(this.el.nativeElement).wrapAll(div);
            $('#' + uuid).click(function (e) {
                alert('Read only user is not authorized to perform this action');
            });
            this.renderer.addClass(this.el.nativeElement, 'disablePointer');
        }
    };
    return ReadOnlyDirective;
}());
export { ReadOnlyDirective };
var ReadOnlyModule = /** @class */ (function () {
    function ReadOnlyModule() {
    }
    return ReadOnlyModule;
}());
export { ReadOnlyModule };
