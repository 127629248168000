import { OnInit, OnDestroy } from '@angular/core';
import { UserDetails, PasswordChange, Roles } from '../../shared/models/user.model';
import { Validators } from '@angular/forms';
import * as AES from "crypto-js/aes";
import * as UTF8 from "crypto-js/enc-utf8";
var ForceChangePasswordComponent = /** @class */ (function () {
    function ForceChangePasswordComponent(router, fb, aR, loginService) {
        this.router = router;
        this.fb = fb;
        this.aR = aR;
        this.loginService = loginService;
        this.subscriptions = [];
        this.forceChange = false;
        this.secretKey = "ui87n@!9845%$bh|pqw";
        this.encOldPwd = '';
    }
    ForceChangePasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.aR.queryParams.subscribe(function (params) {
            _this.forceChange = params && params.force === '1' ? true : false;
            _this.encOldPwd = sessionStorage.getItem('ss-up').split('=')[0];
        }));
        this.user = JSON.parse(localStorage.getItem('userDetails'));
        this.userRole = this.user.role.name;
        this.resetErrors();
        this.formValidity = true,
            this.form = this.fb.group({
                oldPwd: ['', Validators.required],
                newPwd: ['', Validators.required],
                confirmPwd: ['', Validators.required]
            });
        if (this.forceChange) {
            this.form.patchValue({
                oldPwd: this.decrypt(this.encOldPwd)
            });
        }
        this.passwordChange = {
            oldPassword: null,
            newPassword: null
        };
    };
    ForceChangePasswordComponent.prototype.decrypt = function (encryptedVal) {
        return AES.decrypt(encryptedVal, this.secretKey).toString(UTF8);
    };
    ForceChangePasswordComponent.prototype.save = function () {
        var _this = this;
        this.formValidity = true;
        this.resetErrors();
        this.validateOldPassword();
        this.validateNewPassword();
        this.validateConfirmPassword();
        this.validateEqualityNewConfirmPassword();
        if (this.formValidity) {
            this.passwordChange = {
                oldPassword: this.form.value.oldPwd,
                newPassword: this.form.value.newPwd
            };
            this.subscriptions.push(this.loginService.changePassword(this.passwordChange).subscribe(function (response) {
                _this.user.isForcePasswordChanged = false;
                sessionStorage.removeItem('ss-up');
                localStorage.setItem('userDetails', JSON.stringify(_this.user));
                alert('Password has been updated');
                if (!_this.user.isPreferenceSet) {
                    if (_this.user.role.id === Roles.ROLE_OWNER_ADMIN) {
                        _this.router.navigate(['user/owner/preferences']);
                    }
                    else if (_this.user.role.id === Roles.ROLE_AUTHORITY_ADMIN || _this.user.role.id === Roles.ROLE_AUTHORITY_WORX) {
                        _this.router.navigate(['user/authority/authorityPreference']);
                    }
                    else if (_this.user.role.id === Roles.ROLE_INSPECTOR_ADMIN) {
                        _this.router.navigate(['user/inspector/preferencesInspector']);
                    }
                    else if (_this.user.role.id === Roles.ROLE_RFA) {
                        _this.router.navigate(['user/authority/authorityPreference']);
                    }
                }
                else {
                    if (_this.user.role.id === Roles.ROLE_OWNER_ADMIN) {
                        _this.router.navigate(['user/owner/dashboard']);
                    }
                    else if (_this.user.role.id === Roles.ROLE_AUTHORITY_ADMIN || _this.user.role.id === Roles.ROLE_AUTHORITY_WORX) {
                        _this.router.navigate(['user/authority/dashboardAuthority']);
                    }
                    else if (_this.user.role.id === Roles.ROLE_INSPECTOR_ADMIN) {
                        _this.router.navigate(['user/inspector/dashboardInspector']);
                    }
                    else if (_this.user.role.id === Roles.ROLE_RFA) {
                        _this.router.navigate(['user/authority/dashboardAuthority/rfa']);
                    }
                }
                if (_this.user.role.id === Roles.ROLE_SITE_ADMIN) {
                    _this.router.navigate(['user/admin/dashboardSiteAdmin']);
                }
                //this.router.navigate(['user/dashboard']);
            }, function (error) {
                _this.errors.apierror = error.error.message;
            }));
        }
        else {
            console.log(this.errors);
        }
    };
    ForceChangePasswordComponent.prototype.validateOldPassword = function () {
        if (!this.form.value.oldPwd || this.form.value.oldPwd.length == 0) {
            this.errors.old = 'Enter Old Password';
            this.formValidity = false;
        }
    };
    ForceChangePasswordComponent.prototype.validateNewPassword = function () {
        var password = this.form.value.newPwd;
        if (!password || password.length === 0) {
            this.errors.new.push('Enter New Password');
            this.formValidity = false;
            return;
        }
        else {
            if (password.length < 8 || password.length > 15) {
                this.formValidity = false;
                this.errors.new.push('Length of Password should be between 8 to 15 ');
            }
            if (password === this.form.value.oldPwd) {
                this.formValidity = false;
                this.errors.new.push('Old Password and New Password are same. Please use different in New Password.');
            }
            if (password.includes('.') || password.includes(',') || password.includes(';') || password.includes(':')) {
                this.formValidity = false;
                this.errors.new.push('Coma, colan, Semicolon, dot cannot be used');
            }
            if (password === password.toLowerCase()) {
                this.formValidity = false;
                this.errors.new.push('Minimum one Upper case character should be used');
            }
            if (password === password.toUpperCase()) {
                this.formValidity = false;
                this.errors.new.push('Minimum one Lower case character should be used');
            }
            if (!(/\d/.test(password))) {
                this.formValidity = false;
                this.errors.new.push('Minimum one digit should be used');
            }
            else {
                for (var i = 0; i < password.length - 2; ++i) {
                    if (/\d/.test(password.charAt(i))) {
                        if (password.charAt(i) === password.charAt(i + 1) && password.charAt(i) === password.charAt(i + 2)) {
                            this.formValidity = false;
                            this.errors.new.push('3 same digits cannot be used together');
                            break;
                        }
                    }
                }
            }
        }
    };
    ForceChangePasswordComponent.prototype.validateConfirmPassword = function () {
        var password = this.form.value.confirmPwd;
        if (!password || password.length === 0) {
            this.errors.confirm.push('Enter New Password');
            this.formValidity = false;
            return;
        }
        else {
            if (password.length < 8 || password.length > 15) {
                this.formValidity = false;
                this.errors.confirm.push('Length of Password should be between 8 to 15 ');
            }
            if (password === this.form.value.oldPwd) {
                this.formValidity = false;
                this.errors.confirm.push('Old Password and New Password are same. Please use different in New Password.');
            }
            if (password.includes('.') || password.includes(',') || password.includes(';') || password.includes(':')) {
                this.formValidity = false;
                this.errors.confirm.push('Coma, colan, Semicolon, dot cannot be used');
            }
            if (password === password.toLowerCase()) {
                this.formValidity = false;
                this.errors.confirm.push('Minimum one Upper case character should be used');
            }
            if (password === password.toUpperCase()) {
                this.formValidity = false;
                this.errors.confirm.push('Minimum one Lower case character should be used');
            }
            if (!(/\d/.test(password))) {
                this.formValidity = false;
                this.errors.confirm.push('Minimum one digit should be used');
            }
            else {
                for (var i = 0; i < password.length - 2; ++i) {
                    if (/\d/.test(password.charAt(i))) {
                        if (password.charAt(i) === password.charAt(i + 1) && password.charAt(i) === password.charAt(i + 2)) {
                            this.formValidity = false;
                            this.errors.confirm.push('3 same digits cannot be used together');
                            break;
                        }
                    }
                }
            }
        }
    };
    ForceChangePasswordComponent.prototype.validateEqualityNewConfirmPassword = function () {
        if (this.form.value.newPwd !== this.form.value.confirmPwd) {
            this.errors.newconfirm = 'Your New Password and Confirm Password do not match.';
            this.formValidity = false;
        }
    };
    ForceChangePasswordComponent.prototype.cancel = function () {
        sessionStorage.removeItem('ss-up');
        this.user = JSON.parse(localStorage.getItem('userDetails'));
        if (!this.user || this.forceChange === true) {
            localStorage.clear();
            this.router.navigate(['login/login-form']);
        }
        else {
            if (this.user.role['id'] === Roles.ROLE_OWNER_ADMIN) {
                this.router.navigate(['user/owner/dashboard']);
            }
            else if (this.user.role['id'] === Roles.ROLE_AUTHORITY_ADMIN || this.user.role.id === Roles.ROLE_AUTHORITY_WORX) {
                this.router.navigate(['user/authority/dashboardAuthority']);
            }
            else if (this.user.role['id'] === Roles.ROLE_INSPECTOR_ADMIN) {
                this.router.navigate(['user/inspector/dashboardInspector']);
            }
            else if (this.user.role['id'] === Roles.ROLE_RFA) {
                this.router.navigate(['user/authority/dashboardAuthority']);
            }
            else {
                this.router.navigate(['user/admin/dashboardSiteAdmin']);
            }
        }
    };
    ForceChangePasswordComponent.prototype.resetErrors = function () {
        this.errors = {
            old: null,
            new: [],
            confirm: [],
            newconfirm: null,
            apierror: null
        };
    };
    ForceChangePasswordComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (x) {
            x.unsubscribe();
        });
    };
    return ForceChangePasswordComponent;
}());
export { ForceChangePasswordComponent };
