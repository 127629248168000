import { OnInit } from '@angular/core';
var ConfirmationDialogComponent = /** @class */ (function () {
    function ConfirmationDialogComponent(matDialogRef) {
        this.matDialogRef = matDialogRef;
    }
    ConfirmationDialogComponent.prototype.ngOnInit = function () {
    };
    ConfirmationDialogComponent.prototype.onClick = function (e) {
        this.matDialogRef.close(e);
    };
    return ConfirmationDialogComponent;
}());
export { ConfirmationDialogComponent };
