<div class="mt-sm-5 w-100">
  <div class="container login-container mt-sm-5 p-0" style="background: white;">
    <div class="log-div row">
      <div class="logo">
        <img alt="tegrisfire" src='./assets/images/tegris _logo.png' />
      </div>

      <form [formGroup]="form" class="login-container-inner" (ngSubmit)="login()">
        <div>
          <div class="form-group row form-group-padding custom-form-control error"
            *ngIf="errors.apiErrors && errors.apiErrors.length>0">
            <div class="col-12"> *{{errors.apiErrors}}</div>
          </div>
          <div class="form-group row form-group-padding custom-form-control">
            <div class="col-12">
              <label class="col-form-label col-form-label-sm inputLabel">Email:<label class="colorRed">*</label></label>
            </div>
            <div class="col-12">
              <input type="text" pattern="[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*"
                class="form-control form-control-sm rounded-form-fields" [value]="form.value.username"
                formControlName="username" />
            </div>
            <div class="col-12 error" *ngIf="errors.email">
              *{{errors.email}}
            </div>
          </div>
          <div class=" form-group row form-group-padding custom-form-control">
            <label class="col-12 col-form-label col-form-label-sm inputLabel">Password:<label
                class="colorRed">*</label></label>
            <div class="col-12">
              <input [type]="showPassword" class="form-control form-control-sm rounded-form-fields"
                [value]="form.value.password" formControlName="password" minlength="8" maxlength="30">
            </div>
            <div class="col-1" style="margin-left:-70px; margin-top:15px">
              <em class="material-icons show-password" (click)="toggleShowPassword()">remove_red_eye</em>
            </div>
            <div class="col-12 error" *ngIf="errors.password">
              *{{errors.password}}
            </div>
            <div class="col-6">
              <span class="col-custom-checkbox">
                <input class="custom-checkbox" type="checkbox" [value]="form.value.rememberMe"
                  formControlName="rememberMe">
                <label>
                  Remember me
                </label>
              </span>

            </div>
            <div class="col-6 text-right">
              <label class="clickableLabel forget-password-label" style="cursor: pointer;" (click)="forgotPassword()">
                Forgot your password?
              </label>
            </div>
          </div>
        </div>
        <div class="form-group row form-group-padding custom-form-control row">

        </div>
        <div class="form-group row justify-content-center form-group-padding form-buttons-margin">
          <div class="col-12">
            <button class="custom-buttons login" [disabled]="disableClick" type="submit">
              Login
            </button>
          </div>
          <div class="col-12">
            <a class="p-0" href="https://www.tegrisinc.com">
              <button class="custom-buttons" type="button">Cancel</button>
            </a>
          </div>
        </div>
        <div class="row justify-content-center" style="font-size: 18px">
          <div class="col-12 text-center">
            <label class="new-user-label">
              New User? Sign up
              <a (click)="signUp()">
                here
              </a>
            </label>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>

<div class="lds-ripple" *ngIf="loading">
  <div></div>
  <div></div>
</div>
