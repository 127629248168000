import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ParentComponent } from '../../shared/components/parent.component';
import { MatTableDataSource, MatPaginator, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-link-property-dialog',
  templateUrl: './link-property-dialog.component.html',
  styleUrls: ['./link-property-dialog.component.scss']
})
export class LinkPropertyDialogComponent extends ParentComponent implements OnInit {

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @ViewChild('properties', { static: false }) paginator: MatPaginator;
  columnsToDisplay: string[] = ['blank', 'name', 'address'];

  selectedPropertyId: -1;

  constructor(
    public dialogRef: MatDialogRef<LinkPropertyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      super();
    }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    // Do not load table data in ngOnInit, instead use ngAfterViewInit
    // Always load paginator before data
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = this.data;
  }

  selectProperty() {
    this.dialogRef.close(this.selectedPropertyId);
  }

  close() {
    this.dialogRef.close(null);
  }

}
