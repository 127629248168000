/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/components/app-dialog/app-dialog.component.ngfactory";
import * as i2 from "../../../shared/components/app-dialog/app-dialog.component";
import * as i3 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "./app-del-system-dialog.component";
import * as i6 from "@angular/material/dialog";
var styles_DelSystemDialog = [];
var RenderType_DelSystemDialog = i0.ɵcrt({ encapsulation: 2, styles: styles_DelSystemDialog, data: {} });
export { RenderType_DelSystemDialog as RenderType_DelSystemDialog };
export function View_DelSystemDialog_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "app-dialog", [], null, null, null, i1.View_AppDialogComponent_0, i1.RenderType_AppDialogComponent)), i0.ɵdid(1, 114688, null, 0, i2.AppDialogComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 5, "span", [["class", "dialog-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Delete System "])), (_l()(), i0.ɵeld(4, 0, null, null, 3, "span", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "mat-icon", [["class", "cursor-pointer mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deletionRejected() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i0.ɵdid(6, 9158656, null, 0, i4.MatIcon, [i0.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], null, null), (_l()(), i0.ɵted(-1, 0, ["close"])), (_l()(), i0.ɵeld(8, 0, null, 1, 3, "div", [["class", "dialog-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "form-group form-group-padding"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "label", [["class", "col-form-label col-form-label-sm inputLabel notes-sub-heading"], ["style", "padding-left: 10px"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Are you sure you want to delete this item ? "])), (_l()(), i0.ɵeld(12, 0, null, 2, 4, "div", [["class", "dialog-actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "button", [["class", "custom-buttons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deletionApproved() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Delete"])), (_l()(), i0.ɵeld(15, 0, null, null, 1, "button", [["class", "custom-buttons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deletionRejected() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 6, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 6).inline; var currVal_1 = (((i0.ɵnov(_v, 6).color !== "primary") && (i0.ɵnov(_v, 6).color !== "accent")) && (i0.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_0, currVal_1); }); }
export function View_DelSystemDialog_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-del-system-dialog", [], null, null, null, View_DelSystemDialog_0, RenderType_DelSystemDialog)), i0.ɵdid(1, 114688, null, 0, i5.DelSystemDialog, [i6.MatDialogRef, i6.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DelSystemDialogNgFactory = i0.ɵccf("app-del-system-dialog", i5.DelSystemDialog, View_DelSystemDialog_Host_0, {}, {}, []);
export { DelSystemDialogNgFactory as DelSystemDialogNgFactory };
