/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inspector-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../inspector/shared/inspection-snapshot/inspection-snapshot.component.ngfactory";
import * as i3 from "../../../inspector/shared/inspection-snapshot/inspection-snapshot.component";
import * as i4 from "../../../inspector/services/inspector.service";
import * as i5 from "@angular/router";
import * as i6 from "../map-view/map-view.component.ngfactory";
import * as i7 from "../map-view/map-view.component";
import * as i8 from "../../../core/services/utility.service";
import * as i9 from "@angular/common";
import * as i10 from "./inspector-details.component";
var styles_InspectorDetailsComponent = [i0.styles];
var RenderType_InspectorDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InspectorDetailsComponent, data: {} });
export { RenderType_InspectorDetailsComponent as RenderType_InspectorDetailsComponent };
function View_InspectorDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inspection-snapshot", [], null, null, null, i2.View_InspectionSnapshotComponent_0, i2.RenderType_InspectionSnapshotComponent)), i1.ɵdid(1, 245760, null, 0, i3.InspectionSnapshotComponent, [i4.InspectorService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_InspectorDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "inner-flexbox-container inspector-detial"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-map-view", [], null, null, null, i6.View_MapViewComponent_0, i6.RenderType_MapViewComponent)), i1.ɵdid(2, 114688, null, 0, i7.MapViewComponent, [i8.UtilityService], { propertyMarker: [0, "propertyMarker"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InspectorDetailsComponent_1)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.propertyMarker; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isInspector; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_InspectorDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inspector-details", [], null, null, null, View_InspectorDetailsComponent_0, RenderType_InspectorDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i10.InspectorDetailsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InspectorDetailsComponentNgFactory = i1.ɵccf("app-inspector-details", i10.InspectorDetailsComponent, View_InspectorDetailsComponent_Host_0, { propertyMarker: "propertyMarker" }, {}, []);
export { InspectorDetailsComponentNgFactory as InspectorDetailsComponentNgFactory };
