import * as i0 from "@angular/core";
import * as i1 from "./user.service";
import * as i2 from "@angular/common";
var AuthorityGuard = /** @class */ (function () {
    function AuthorityGuard(userService, location) {
        this.userService = userService;
        this.location = location;
    }
    AuthorityGuard.prototype.canActivate = function () {
        var a = this.userService.userDetails.role.name === 'ROLE_AUTHORITY' || this.userService.userDetails.role.name === 'ROLE_RFA' ||
            this.userService.userDetails.role.name === 'ROLE_AUTHORITY_WORX';
        var b = this.userService.userDetails.role.name === 'ROLE_RFA';
        if (a) {
            return a;
        }
        if (b) {
            return b;
        }
        this.location.back();
        return a;
    };
    AuthorityGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthorityGuard_Factory() { return new AuthorityGuard(i0.ɵɵinject(i1.UserService), i0.ɵɵinject(i2.Location)); }, token: AuthorityGuard, providedIn: "root" });
    return AuthorityGuard;
}());
export { AuthorityGuard };
