import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule, MatProgressSpinnerModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AuthorityNotificationDialogComponent } from '../authority/shared/authority-notification/authority-notification-dialog.component';
import { CertifyBypassComponent } from '../inspection/inspection-properties/certify-bypass/certify-bypass.component';
import { VerifyPacComponent } from '../inspection/inspection-properties/verify-pac/verify-pac.component';
// tslint:disable-next-line: max-line-length
import { ViewPreviousReportsComponent } from '../inspection/inspection-property-assets/view-previous-reports/view-previous-reports.component';
import { ViewDeficiencyDialogComponent } from '../inspection/view-deficiency-dialog/view-deficiency-dialog.component';
import { DeleteDialogComponent } from '../inspector/inspector-preferences/inspector-settings/delete-dialog/delete-dialog.component';
import { ConfirmationDialog } from '../inspector/shared/confirmation-popup-dialog/confirmation-dialog.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { AppHeaderComponent } from './shared/components/app-header/app-header.component';
import { AppLogoComponent } from './shared/components/app-logo/app-logo.component';
import { AppSideNavComponent } from './shared/components/app-side-nav/app-side-nav.component';
import { AuthorityStatisticsComponent } from './shared/components/app-statistics/authority-statistics/authority-statistics.component';
import { InspectorStatisticsComponent } from './shared/components/app-statistics/inspector-statistics/inspector-statistics.component';
import { OwnerStatisticsComponent } from './shared/components/app-statistics/owner-statistics/owner-statistics.component';
import { AddSystemDialog } from '../property-page/system-details/app-add-system-dialog.component';
import { DelSystemDialog } from '../property-page/system-details/app-del-system-dialog/app-del-system-dialog.component';
import { USPSValidPopUpComponent } from '../property-page/property-details/usps-popUp-valid/usps-popUp-valid.component';
import { USPSInvalidPopUpComponent } from '../property-page/property-details/usps-popUp-invalid/usps-popUp-invalid.component';
import { AddNotesDialogComponent } from '../property-page/journal/add-notes-dialog/add-notes-dialog.component';
import { AddJournalDialogComponent } from '../property-page/journal/add-journal-dialog/add-journal-dialog.component';
import { ContactDialogComponent } from '../property-page/journal/contact-dialog/contact-dialog.component';
import { EnlargeMapComponent } from '../shared/components/map-view/enlarge-map/enlarge-map.component';
import { DuplicatePropertyComponent } from '../property-page/property-details/duplicate-property/duplicate-property.component';
import { ChangeOwnerComponent } from '../file-cabinet/change-owner/change-owner.component';
import { ConfirmationDialogComponent } from '../shared/components/confirmation-dialog/confirmation-dialog.component';
import { AssignPropertyComponent } from '../file-cabinet/assign-property/assign-property.component';
import { EmailComponent } from '../inspection/inspection-report/email/email.component';
import { SearchPropertyComponent } from '../admin/configure-notification-template/search-property/search-property.component';
import { EditPropertyDialogComponent } from '../inspection/enter-inspection/edit-property-dialog/edit-property-dialog.component';
import { LinkPropertyDialogComponent } from '../cases/link-property-dialog/link-property-dialog.component';
import { FilterDateDialogComponent } from '../metrics/filter-date-dialog/filter-date-dialog.component';
import { LoaderComponent } from '../components/shared/loader/loader.component';
import { FileCabinetHistoryComponent } from '../file-cabinet/file-cabinet-history/file-cabinet-history.component';
import { PaymentDetailsDialogComponent } from '../inspection/payment-details-dialog/payment-details-dialog.component';
import { DeleteRequestDialogComponent } from '../shared/components/delete-request-dialog/delete-request-dialog.component';
import { ViewReportDialogComponent } from '../inspection/view-report-dialog/view-report-dialog.component';
import { ConfirmationPopupDialogDeleteComponent } from '../inspector/shared/confirmation-popup-dialog-delete/confirmation-popup-dialog-delete.component';
import { AdditionalCertificateComponent } from '../inspector/shared/additional-certificate/additional-certificate.component';
@NgModule({
  declarations: [
    DashboardComponent,
    AppSideNavComponent,
    AppHeaderComponent,
    AuthorityStatisticsComponent,
    InspectorStatisticsComponent,
    OwnerStatisticsComponent,
    AppLogoComponent,
    LoaderComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    DashboardComponent,
    AppSideNavComponent,
    AppHeaderComponent,
    AuthorityStatisticsComponent,
    InspectorStatisticsComponent,
    OwnerStatisticsComponent,
    AppLogoComponent,
    LoaderComponent,
  ],
  entryComponents: [
    VerifyPacComponent,
    CertifyBypassComponent,
    ViewPreviousReportsComponent,
    ViewDeficiencyDialogComponent,
    AuthorityNotificationDialogComponent,
    DeleteDialogComponent,
    ConfirmationDialog,
    AdditionalCertificateComponent, 
    AddSystemDialog,
    DelSystemDialog,
    USPSValidPopUpComponent,
    USPSInvalidPopUpComponent,
    AddNotesDialogComponent,
    AddJournalDialogComponent,
    ContactDialogComponent,
    EnlargeMapComponent,
    ConfirmationDialogComponent,
    DuplicatePropertyComponent,
    ChangeOwnerComponent,
    AssignPropertyComponent,
    EmailComponent,
    SearchPropertyComponent,
    EditPropertyDialogComponent,
    LinkPropertyDialogComponent,
    FilterDateDialogComponent,
    FileCabinetHistoryComponent,
    PaymentDetailsDialogComponent,
    DeleteRequestDialogComponent,
    ViewReportDialogComponent,
    ConfirmationPopupDialogDeleteComponent
  ],
})
export class DashboardModule {}

