import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var FireAlarmComponent = /** @class */ (function () {
    function FireAlarmComponent() {
    }
    FireAlarmComponent.prototype.ngOnInit = function () {
    };
    FireAlarmComponent.prototype.ngOnChanges = function (changes) {
        if (changes['questions'] && changes['questions'].currentValue) {
            this.questions = tslib_1.__spread(changes['questions'].currentValue);
        }
    };
    return FireAlarmComponent;
}());
export { FireAlarmComponent };
