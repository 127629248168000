<app-dialog>
  <span class="dialog-title">
    {{data.manager === true ? 'MANAGER ADDRESS' : 'PROPERTY ADDRESS'}}
    <span class="float-right">
      <mat-icon class="cursor-pointer" (click)="cancel()">close</mat-icon>
    </span>
  </span>
  <div class="dialog-content">
    <div class="form-group row form-group-padding">
      <label style="padding-left: 10px" class="col-form-label col-form-label-sm inputLabel notes-sub-heading">
        The address provided is not valid
      </label>
    </div>
  </div>
  <div class="dialog-actions">
    <button type="button" class="custom-buttons" *ngIf="checkAccess()" (click)="continue()" cdkFocusInitial>Continue</button>
    <button type="button" class="custom-buttons" (click)="abort()" cdkFocusInitial>Abort</button>
    <button type="button" class="custom-buttons" (click)="tryAgain()">Try Again</button>
  </div>
</app-dialog>
