import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoginService } from '../../../services/login.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  public userEmail: { email: string };
  public error: string;
  subscriptions = [];
  loading: boolean;

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.loading = false;
    this.userEmail = {
      email: ''
    };
    this.error = '';
  }

  recover() {
    this.error = '';
    this.userEmail.email = this.userEmail.email.trim();
    if (!this.userEmail.email || this.userEmail.email.length === 0) {
      this.error = 'Please enter email address.';
    }
    if (this.error === '') {
      this.loading = true;
      this.subscriptions.push(
        this.loginService.recoverPassword(this.userEmail).subscribe(
          data => {
            this.loading = false;
            this.dialogRef.close(this.userEmail.email);
          },
          error => {
            this.loading = false;
            if (error.message && error.message.length > 0) {
              this.error = error.error.message;
            }
          }
        )
      );
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => {
      x.unsubscribe();
    });
  }
}
