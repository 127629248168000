import { OnInit } from '@angular/core';
import { mergeMap, map } from 'rxjs/operators';
var AuthorityStatisticsComponent = /** @class */ (function () {
    function AuthorityStatisticsComponent(statisticsService, loggerService, authorityService) {
        this.statisticsService = statisticsService;
        this.loggerService = loggerService;
        this.authorityService = authorityService;
    }
    AuthorityStatisticsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userRoleID = JSON.parse(localStorage.getItem('userDetails')).role.id;
        if (userRoleID === 8) {
            this.rfaLoggedIn = true;
        }
        else {
            this.rfaLoggedIn = false;
        }
        if (this.rfaLoggedIn) {
            this.statisticsService.jurisId$.subscribe(function (res) {
                if (res) {
                    _this.statisticsService.getRFAAuthorityStatistics(res).subscribe(function (innerRes) {
                        _this.authorityStatistics = innerRes;
                        // 1944 - bug fix
                        // this.authorityStatistics.generalCompliancePercent =
                        //   parseFloat((+this.authorityStatistics.generalCompliancePercent).toFixed(2)).toString() + '%';            
                    }, function (error) {
                        _this.loggerService.log(error);
                        _this.authorityStatistics = {
                            generalCompliancePercent: '',
                            propDueInNext30Days: 0,
                            totalActiveProperties: 0,
                            totalActiveSystem: 0,
                            totalInspectionCompanies: 0
                        };
                    });
                }
                else {
                    var userDetails = JSON.parse(localStorage.getItem('userDetails'));
                    if (res === null && (userDetails === undefined || userDetails === null)) {
                        return;
                    }
                    _this.authorityService.getRFAJurisdication().pipe(map(function (y) {
                        if (y) {
                            return y.map(function (x) { return x.id; });
                        }
                    }), mergeMap(function (jurisIds) {
                        return _this.statisticsService.getRFAAuthorityStatistics(jurisIds);
                    })).subscribe(function (response) {
                        _this.authorityStatistics = response;
                    }, function (error) {
                        _this.loggerService.log(error);
                        _this.authorityStatistics = {
                            generalCompliancePercent: '',
                            propDueInNext30Days: 0,
                            totalActiveProperties: 0,
                            totalActiveSystem: 0,
                            totalInspectionCompanies: 0
                        };
                    });
                }
            });
        }
        if (!this.rfaLoggedIn) {
            this.authorityStatistics = {};
            this.statisticsService.getAuthorityStatistics().subscribe(function (res) {
                _this.authorityStatistics = res;
            }, function (error) {
                _this.loggerService.log(error);
                _this.authorityStatistics = {
                    generalCompliancePercent: '',
                    propDueInNext30Days: 0,
                    totalActiveProperties: 0,
                    totalActiveSystem: 0,
                    totalInspectionCompanies: 0
                };
            });
        }
    };
    return AuthorityStatisticsComponent;
}());
export { AuthorityStatisticsComponent };
