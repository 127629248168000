import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var ViewReportDialogComponent = /** @class */ (function () {
    function ViewReportDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    ViewReportDialogComponent.prototype.ngOnInit = function () {
        this.data.dialogRef = this.dialogRef;
    };
    ViewReportDialogComponent.prototype.cancel = function () {
        this.dialogRef.close('cancelled');
    };
    return ViewReportDialogComponent;
}());
export { ViewReportDialogComponent };
