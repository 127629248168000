import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Roles } from '../../../shared/models/user.model';
var USPSInvalidPopUpComponent = /** @class */ (function () {
    function USPSInvalidPopUpComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.user = null;
    }
    USPSInvalidPopUpComponent.prototype.ngOnInit = function () {
        this.user = JSON.parse(localStorage.getItem('userDetails'));
    };
    USPSInvalidPopUpComponent.prototype.abort = function () {
        this.dialogRef.close('abort');
    };
    USPSInvalidPopUpComponent.prototype.tryAgain = function () {
        this.dialogRef.close('try again');
    };
    USPSInvalidPopUpComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    USPSInvalidPopUpComponent.prototype.continue = function () {
        this.dialogRef.close('continue');
    };
    USPSInvalidPopUpComponent.prototype.checkAccess = function () {
        console.log(this.user.role);
        if (this.user.role['authority'] === 'ROLE_SITE_ADMIN'
            || this.user.role['authority'] === 'ROLE_AUTHORITY'
            || this.user.role['id'] === Roles.ROLE_RFA
            || this.user.role['id'] === Roles.ROLE_AUTHORITY_WORX) {
            return true;
        }
        else
            return false;
    };
    return USPSInvalidPopUpComponent;
}());
export { USPSInvalidPopUpComponent };
