/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/app-dialog/app-dialog.component.ngfactory";
import * as i3 from "../../../shared/components/app-dialog/app-dialog.component";
import * as i4 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "@angular/common";
import * as i7 from "./contact-dialog.component";
import * as i8 from "@angular/material/dialog";
import * as i9 from "@angular/forms";
import * as i10 from "../../../core/services/property/journal-detail.service";
var styles_ContactDialogComponent = [i0.styles];
var RenderType_ContactDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactDialogComponent, data: {} });
export { RenderType_ContactDialogComponent as RenderType_ContactDialogComponent };
function View_ContactDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "example-box"]], [[2, "highlightedRow", null], [1, "data-index", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "row custom-contacts-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "col-4 contacts-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "col-3 contacts-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["class", "col-5 contacts-content number"], ["target", "_top"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.isManager === true); var currVal_1 = _v.context.index; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.firstName; var currVal_3 = _v.context.$implicit.lastName; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = _v.context.$implicit.phoneNum; _ck(_v, 5, 0, currVal_4); var currVal_5 = ("mailto:" + _v.context.$implicit.email); _ck(_v, 6, 0, currVal_5); var currVal_6 = _v.context.$implicit.email; _ck(_v, 7, 0, currVal_6); }); }
export function View_ContactDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "app-dialog", [], null, null, null, i2.View_AppDialogComponent_0, i2.RenderType_AppDialogComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppDialogComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "span", [["class", "dialog-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Primary Contact "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "mat-icon", [["class", "cursor-pointer mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(8, 0, null, 1, 2, "div", [["class", "dialog-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactDialogComponent_1)), i1.ɵdid(10, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 6, 0); var currVal_2 = _co.contacts; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).inline; var currVal_1 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_0, currVal_1); }); }
export function View_ContactDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contact-dialog", [], null, null, null, View_ContactDialogComponent_0, RenderType_ContactDialogComponent)), i1.ɵdid(1, 114688, null, 0, i7.ContactDialogComponent, [i8.MatDialogRef, i8.MAT_DIALOG_DATA, i9.FormBuilder, i10.JournalDetailService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactDialogComponentNgFactory = i1.ɵccf("app-contact-dialog", i7.ContactDialogComponent, View_ContactDialogComponent_Host_0, {}, {}, []);
export { ContactDialogComponentNgFactory as ContactDialogComponentNgFactory };
