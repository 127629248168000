import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserLogin, LoginInfo, PasswordChange } from '../../shared/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  baseUrl = environment.baseUrl;

  public UserDetails$ = new BehaviorSubject<LoginInfo>(null);
  public loggedIn$ = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) { }

  loginUser(data: UserLogin): Observable<LoginInfo> {
    // http://10.127.129.218:9003/tegrisfire-backend/api
    const url = `${this.baseUrl}/login`;
    return this.http.post<LoginInfo>(url, data);
  }

  recoverPassword(data): Observable<string> {
    const url = `${this.baseUrl}/regeneratePassword`;
    return this.http.put<string>(url, data);
  }

  changePassword(data: PasswordChange): Observable<string> {
    const url = `${this.baseUrl}/resetPassword`;
    return this.http.put<string>(url, data);
  }

  getUserDetails(userId: number): Observable<any> {
    const url = this.baseUrl + `/user/${userId}`;
    return this.http.get<any>(url);
  }
}
