import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
// tslint:disable-next-line: max-line-length
import { PropertyDetails, SystemDetails, ContsructionType, PropertyType, ZipcodeMappingDetails, AssetType, States, Cities, PropertyContacts, ContactListResponse } from '../../models/property/property.model';
import { ListData } from '../../models/file-cabinet/file-cabinet.model';
import { InspectionPropertyInfo } from '../../models/inspection/inspection.model';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  // assetType$: BehaviorSubject<AssetType> = new BehaviorSubject({});

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  propertyDetails$: BehaviorSubject<PropertyDetails> = new BehaviorSubject(null);
  propertySaved = new BehaviorSubject(false);
  isPropAddressValidated$ = new BehaviorSubject(false);
  oldProperyDetailsModel$: BehaviorSubject<PropertyDetails> = new BehaviorSubject(null);
  propertyId$ = new BehaviorSubject(-1);
  public systemDetails$: BehaviorSubject<SystemDetails[]> = new BehaviorSubject<SystemDetails[]>(null);

  private readonly uspsAPIrootUrl = 'https://secure.shippingapis.com/ShippingApi.dll?API=Verify&XML=';
  private readonly uspsUserId = '808TEGRI1050';


  getConstructionTypeOptions(): Observable<ContsructionType[]> {
    const url = this.baseUrl + '/code/constructiontype';
    return this.http.get<ContsructionType[]>(url);
    // .pipe(map(res => res ));

  }

  getOccupancyType(): Observable<PropertyType[]> {
    const url = this.baseUrl + '/code/propertytype';
    return this.http.get<PropertyType[]>(url);
  }

  getInspectionFreqOptions(): Observable<string[]> {
    const url = this.baseUrl + '/code/inspectionfrequency';
    return this.http.get<string[]>(url);
  }

  getJurisdictionDetailsFromZipcode(zipcode: string) {

    const url = this.baseUrl + `/zipcode/${zipcode}/details`;
    return this.http.get<ZipcodeMappingDetails>(url);
  }
  
  checkDuplicateProperty(data: PropertyDetails) {
    const url = this.baseUrl + '/property/duplicate';
    return this.http.post<PropertyDetails>(url, data);
  }

  postPropertyDetails(data: PropertyDetails) {
    const url = this.baseUrl + '/property';
    return this.http.post<PropertyDetails>(url, data);
  }

  postPropertyDetailsWithAsset(data: PropertyDetails){
    const url = this.baseUrl + '/property/propertyAssets';
    return this.http.post<PropertyDetails>(url, data);
  }

  putPropertyDetails(data: PropertyDetails, propId) {
    const url = this.baseUrl + `/property/${propId}`;
    return this.http.put<PropertyDetails>(url, data);
  }

  getAssestTypeOptions(): Observable<AssetType[]> {
    const url = this.baseUrl + '/assettype';
    return this.http.get<AssetType[]>(url);
  }

  getCustomAssetOptions(jurisId): Observable<AssetType[]> {
    const url = this.baseUrl + `/property/jurisdiction/${jurisId}/assetTypes`;
    return this.http.get<AssetType[]>(url);
  }

  validateAddressFromUsps(addressObj) {
    const addressRequestTemplate = `<AddressValidateRequest USERID="${this.uspsUserId}"><Address ID="1"><FirmName/>`
      + `<Address1>${addressObj.streetLine2}</Address1><Address2>${addressObj.streetLine1}</Address2><City>${addressObj.city}</City>`
      + `<State>${addressObj.state}</State><Zip5>${addressObj.zipcode}</Zip5><Zip4/></Address></AddressValidateRequest>`;

    return this.http.get(this.uspsAPIrootUrl + addressRequestTemplate, { responseType: 'text' });
  }

  postSystemDetails(systemDetails: SystemDetails) {
    const url = this.baseUrl + '/propertyAsset';
    return this.http.post<SystemDetails>(url, systemDetails);
  }

  putSystemDetails(systemDetails: SystemDetails) {
    const url = this.baseUrl + '/propertyAsset';
    return this.http.put<SystemDetails>(url, systemDetails);
  }

  getAllSystemsOfProperty(propId): Observable<SystemDetails[]> {
    const url = this.baseUrl + `/property/${propId}/propAsset`;
    return this.http.get<SystemDetails[]>(url);
  }

  getAllSystemsOfPropertyRFA(propId, jurisId): Observable<SystemDetails[]> {
    const url = this.baseUrl + `/property/${propId}/propAsset`;
    return this.http.get<SystemDetails[]>(url, { params: jurisId });
  }

  deleteAssetOfProperty(id: string): Observable<any> {
    const url = this.baseUrl + `/propertyAsset/${id}`;
    return this.http.delete(url);
  }

  getPropertyById(id: string): Observable<PropertyDetails> {
    const url = this.baseUrl + `/property/${id}`;
    return this.http.get<PropertyDetails>(url);
  }

  getOwnerPropertyList(id) {
    const url = this.baseUrl + `/owner/properties/${id}`;
    return this.http.get<any[]>(url);
  }

  getManagerPropertyList(id) {
    const url = this.baseUrl + `/manager/properties/${id}`;
    return this.http.get<any[]>(url);
  }

  getAllStates(): Observable<States[]> {
    const url = this.baseUrl + '/state';
    return this.http.get<States[]>(url);
  }

  getCitiesByState(stateID): Observable<Cities[]> {
    const url = `${this.baseUrl}/state/${stateID}/city`;
    return this.http.get<Cities[]>(url);
  }

  validatePAC(propertyId, pac): Observable<boolean> {
    const url = `${this.baseUrl}/property/${propertyId}/ispacvalid/${pac}`;
    return this.http.get<boolean>(url);
  }

  deleteProperty(propertyId) {
    const url = `${this.baseUrl}/property/${propertyId}`;
    return this.http.delete(url);
  }

  deletePropertyPendingApproval(propertyId, authorityComments) {
    const url = `${this.baseUrl}/ppa/property/`;
    return this.http.delete(url, { params: { propertyId, authorityComments } });
  }

  activateProperty(propId) {
    const url = `${this.baseUrl}/activateProperty/${propId}`;
    return this.http.put(url, null);
  }

  restoreProperty(propId) {
    const url = `${this.baseUrl}/restore/property/${propId}`;
    return this.http.post(url, null);
  }

  activateSystem(propAssetId) {
    const url = `${this.baseUrl}/activateAsset/${propAssetId}`;
    return this.http.put(url, null);
  }

  getActiveJurisdictions() {
    const url = `${this.baseUrl}/jurisdiction`;
    return this.http.get<ListData[]>(url);
  }

  getPropertyListByJurisdictionId(jurisdictionId) {
    const url = `${this.baseUrl}/property/jurisdiction/${jurisdictionId}`;
    return this.http.get<InspectionPropertyInfo[]>(url);
  }

  setAccountMaintainanceFeeForProperty(params) {
    const url = `${this.baseUrl}/property/accountmaintenancefee`;
    return this.http.put<InspectionPropertyInfo[]>(url, {}, { params });
  }

  setSuppressFeeForProperty(params) {
    const url = `${this.baseUrl}/property/suppressfee`;
    return this.http.put<InspectionPropertyInfo[]>(url, {}, { params });
  }
  setTechSupportFeeForProperty(params) {
    const url = `${this.baseUrl}/property/techsupportfee`;
    return this.http.put<InspectionPropertyInfo[]>(url, {}, { params });
  }

  setTechSupportFeeForPropertyAsset(params) {
    const url = `${this.baseUrl}/propertyAsset/techsupportfee`;
    return this.http.put<InspectionPropertyInfo[]>(url, {}, { params });
  }

  putPropertyEditDetails(data: PropertyDetails, propId) {
    const url = this.baseUrl + `/property/contacts/${propId}`;
    return this.http.put<PropertyDetails>(url, data);
  }

  getNotificationLetter(propertyId, notificationType,journalId) {
    const url = `${this.baseUrl}/property/notifications`;
    return this.http.get<string>(url, { params: {propertyId, notificationType,journalId} });
  }

  public getManagerList(limit, offset, searchString) {
    const url = this.baseUrl + `/contactList/manager`;
    return this.http.get<ContactListResponse>(url, {
      params: {
        offset: offset,
        limit: limit,
        searchString: searchString
      }
    });
  }

  putPropCoordinated(propId, data) {
    const url = this.baseUrl + `/property/coordinates/${propId}`;
    return this.http.put(url, data);
  }

  putAssetCoordinates(data) {
    const url = this.baseUrl + `/propertyAsset/coordinates`;
    return this.http.put(url, data);
  }

  public isAddingNewSystemAllowedDataForDepartment(propertyId) {
    const url = this.baseUrl + `/property/jurisdiction/addingNewSystemAllowed`;
    return this.http.get<any>(url, {
      params: {
        jurisdictionId: propertyId
      }
    });
  }

  public isAddingNewSystemAllowedDataForJuridiction(juriId) {
    const url = this.baseUrl + `/property/jurisdiction/addingNewWorxSystemAllowed`;
    return this.http.get<any>(url, {
      params: {
        jurisdictionId: juriId
      }
    });
  }
}
