import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ContactsService = /** @class */ (function () {
    function ContactsService(http) {
        this.http = http;
        this.baseUrl = environment.baseUrl;
        this.propertyContacts = new BehaviorSubject(null);
        this.newpropertyContacts = new BehaviorSubject(null);
        this.propertyName = new BehaviorSubject(null);
        this.propertyID = new BehaviorSubject(null);
        this.propertyAddress = new BehaviorSubject(null);
    }
    ContactsService.prototype.getContactsByPropertyId = function (propertyId) {
        var url = this.baseUrl + ("/property/" + propertyId + "/propertyContact");
        return this.http.get(url);
    };
    ContactsService.prototype.addNewContacts = function (contacts, propertyId) {
        var url = this.baseUrl + ("/propertyContact/" + propertyId);
        return this.http.post(url, contacts);
    };
    ContactsService.prototype.updateExistingContacts = function (contacts, propertyId) {
        var url = this.baseUrl + ("/propertyContact/" + propertyId);
        return this.http.put(url, contacts);
    };
    ContactsService.prototype.deleteContact = function (contactId) {
        var url = this.baseUrl + ("/propertyContact/" + contactId);
        return this.http.delete(url);
    };
    ContactsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContactsService_Factory() { return new ContactsService(i0.ɵɵinject(i1.HttpClient)); }, token: ContactsService, providedIn: "root" });
    return ContactsService;
}());
export { ContactsService };
