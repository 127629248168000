import * as i0 from "@angular/core";
import * as i1 from "./user.service";
import * as i2 from "@angular/common";
var InspectorGuard = /** @class */ (function () {
    function InspectorGuard(userService, location) {
        this.userService = userService;
        this.location = location;
    }
    InspectorGuard.prototype.canActivate = function () {
        var a = this.userService.userDetails.role.name === 'ROLE_INSPECTOR';
        if (a) {
            return a;
        }
        this.location.back();
        return a;
    };
    InspectorGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InspectorGuard_Factory() { return new InspectorGuard(i0.ɵɵinject(i1.UserService), i0.ɵɵinject(i2.Location)); }, token: InspectorGuard, providedIn: "root" });
    return InspectorGuard;
}());
export { InspectorGuard };
