<app-dialog>

  <span class="dialog-title">
    {{description}}
    <span class="float-right">
      <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
    </span>
  </span>

    <div class="custom-hide-overflow add-system dialog-content" *ngIf="systemDetails">
      <div class="form-group row form-group-padding">
        <label class="col-5   inputLabel notes-sub-heading">Department
          <label class='mandatory'>*</label></label>
        <div class="col-7">
          <select [(ngModel)]="systemDetails.systemType"
            [disabled]="enableDisableField(!(this.user.role.id == 3 || this.user.role.id == 1 || this.user.role.id == 5 || this.user.role.id == 8) && this.data.description === 'Edit System Property')"
            class="rounded-form-fields no-Padding custom-select form-control" (change)="populateAssetType()" required>
            <option value="" class="rounded-form-fields no-Padding form-control ">Select</option>
            <option *ngFor="let Systems of SystemTypes"
              [disabled]="(user.role.id === 3 || user.role.id === 9 || user.role.id == 8) &&  Systems.toLowerCase() !== userDepartment.toLowerCase()"
              class="rounded-form-fields no-Padding form-control" value="{{ Systems }}">
              {{Systems}}
            </option>
          </select>
        </div>
        <div class="offset-5" style="padding-left: 15px" *ngIf="errors.systemType">
          <mat-error>
            {{errors.systemType}}
          </mat-error>
        </div>
      </div>
      <div class="form-group row form-group-padding ">
        <label class="col-5   inputLabel notes-sub-heading">
          System Type<label class='mandatory'>*</label></label>
        <div class="col-7">
          <select [(ngModel)]="systemDetails.assetType" (change)="populateInspectFreq()"
            [disabled]="enableDisableField(!(user.role.id == 3 || user.role.id == 1 || user.role.id == 5 || user.role.id == 9 || user.role.id == 8) && data.description === 'Edit System Property')"
            class="rounded-form-fields no-Padding custom-select form-control" required>
            <option value="" class="rounded-form-fields no-Padding form-control">Select</option>
            <option *ngFor="let SystemName of SystemTypesList" class="rounded-form-fields no-Padding form-control"
              value="{{ SystemName }}" [selected]="systemDetails.assetType == SystemName">
              {{SystemName}}
            </option>
          </select>
        </div>
        <div class="offset-5" style="padding-left: 15px" *ngIf="errors.assetName">
          <mat-error>
            {{errors.assetName}}
          </mat-error>
        </div>
      </div>
      <div class="form-group row form-group-padding ">
        <label class="col-5 pr-0  inputLabel notes-sub-heading">Property System Inspection
          Frequency <label class="mandatory">*</label></label>
        <div class="col-7">
          <select [(ngModel)]="systemDetails.assetInspectionFrequency"
            class="rounded-form-fields no-Padding custom-select form-control" (change)="maxDate()" required
            [disabled]="enableDisableField(!(user.role.id == 3 || user.role.id == 1 || user.role.id == 5 || user.role.id == 9 || user.role.id == 8))">
            <option value="" class="rounded-form-fields no-Padding form-control"
              [selected]='!systemDetails.assetInspectionFrequency'>Select</option>
            <option *ngFor="let Frequency of inspectionFreqOptions" class="rounded-form-fields no-Padding form-control"
              value="{{ Frequency}}" [selected]="systemDetails.assetInspectionFrequency == Frequency">
              {{Frequency}}
            </option>
          </select>

        </div>
        <div class="offset-5" style="padding-left: 15px; margin-top: -15px" *ngIf="errors.inspectFreq">
          <mat-error>
            {{errors.inspectFreq}}
          </mat-error>
        </div>
      </div>
      <div class="form-group row form-group-padding ">
        <label class="col-5   inputLabel notes-sub-heading">System Location:</label>
        <div class="col-7">
          <input type="text" [(ngModel)]="systemDetails.assetLocation"
            [disabled]="enableDisableField(!(user.role.id == 8 || user.role.id == 3 || user.role.id == 1 || user.role.id == 5 || user.role.id == 9) && data.description === 'Edit System Property')"
            class="form-control form-control-sm rounded-form-fields"/>
          <label class="custom-description-system">example : Riser Room, 2nd Fl. Electrical Room.</label>
        </div>
        <div class="offset-5" style="padding-left: 15px; margin-top: -15px" *ngIf="errors.systemLocation">
          <mat-error>
            {{errors.systemLocation}}
          </mat-error>
        </div>
      </div>
      <div class="form-group row form-group-padding ">
        <label class="col-5   inputLabel notes-sub-heading">{{systemDetails.systemType.toUpperCase() === 'TEGRIS WORX' && systemDetails.assetType.toUpperCase() === 'BACKFLOW' ? 'Route & Service:' : 'Coverage Area:'}}</label>
        <div class="col-7">
          <input type="text" class="form-control form-control-sm rounded-form-fields"
            [(ngModel)]="systemDetails.assetCoverage" 
            [disabled]="enableDisableField(false)"/>
          <label *ngIf="systemDetails.assetType.toUpperCase() !== 'BACKFLOW'" class="custom-description-system">example: Whole Bldg., Basement Area, Office only.</label>
          <label *ngIf="systemDetails.assetType.toUpperCase() === 'BACKFLOW'" class="custom-description-system">examples: 03-0046, 12-1226, 37-0001, 27-00.</label>
        </div>
        <div class="offset-5" style="padding-left: 15px; margin-top: -15px" *ngIf="systemDetails.assetType.toUpperCase() !== 'BACKFLOW' && errors.assetCoverage">
          <mat-error>
            {{errors.assetCoverage}}
          </mat-error>
        </div>
      </div>

      <div class="form-group row form-group-padding">
        <label class="col-5   inputLabel notes-sub-heading">Due Date
          <label class="mandatory">*</label>
        </label>
        <div class="col-6" mat-form-field>
          <input class="form-control form-control-sm rounded-form-fields"
            [disabled]="enableDisableField(!(user.role.id == 3 || user.role.id == 1 || user.role.id == 5 || user.role.id == 9 || user.role.id == 8) && data.description === 'Edit System Property')"
            [matDatepicker]="picker" #datepicker 
            [(ngModel)]="systemDetails.inspectionDueDate">
          <label class="custom-description-system">Date format MM-DD-YYYY</label>
          <mat-error>{{getErrorMessage(datepicker.value)}}</mat-error>
        </div>
        <div class="col-1">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker style="z-index: -999;"></mat-datepicker>
        </div>
      </div>

    </div>

      <div class="dialog-actions">
        <button class="custom-buttons" type="submit" [disabled]="enableDisableField(disableButton)" (click)="save()">Submit</button>
        <button class="custom-buttons" (click)="close()">Cancel</button>
      </div>

</app-dialog>
