import { Injectable, NgZone } from '@angular/core';
import { environment } from '@environment/environment';
import { HttpClient } from '@angular/common/http';
/**
 * LoggerService
 * @description Service to handle all the logging levels.
 * You can also write custom logger methods in this service
 */
@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  /**
   * method to log.
   * @param message javascript object
   * @param optionalParams A list of JavaScript objects to output
   */
  baseUrl = environment.baseUrl;

  log: (message?: any, ...optionalParams: any[]) => void;

  /**
   * method to debug.
   * @param message javascript object
   * @param optionalParams A list of JavaScript objects to output
   */
  debug: (message?: any, ...optionalParams: any[]) => void;

  /**
   * method to log info.
   * @param message javascript object
   * @param optionalParams A list of JavaScript objects to output
   */
  info: (message?: any, ...optionalParams: any[]) => void;

  /**
   * method to log warnings.
   * @param message javascript object
   * @param optionalParams A list of JavaScript objects to output
   */
  warn: (message?: any, ...optionalParams: any[]) => void;

  /**
   * method to log errors.
   * @param message javascript object
   * @param optionalParams A list of JavaScript objects to output
   */
  error: (message?: any, ...optionalParams: any[]) => void;

  /**
   * method to log time start
   * @param name timername
   */
  time: (timerName?: string) => void;

  /**
   * method to log time end
   * @param name timername
   */
  timeEnd: (timerName?: string) => void;

  /**
   * method to send logs to server
   * @param err error object or message string
   */
  logToServer: (err?: any) => void;

  /**
   * Constructor of the Service.
   * Configure the logger methods
   */
  constructor(
    private http: HttpClient,
    private ngZone: NgZone
  ) {
    this.configureLogger();
    const url = this.baseUrl + `/setPreference`;

  }

  /**
   * Setting the logger configuration based on environment
   */
  private configureLogger() {
    if (!environment.production) {
      /**
       * By default, logger methods are bind to the console methods
       * You can also implement your custom logger methods here
       */
      this.log = console.log.bind(console);

      // tslint:disable-next-line:no-console
      this.debug = console.debug.bind(console);

      // tslint:disable-next-line:no-console
      this.info = console.info.bind(console);

      this.warn = console.warn.bind(console);

      this.error = console.error.bind(console);

      // tslint:disable-next-line:no-console
      this.time = console.time.bind(console);

      // tslint:disable-next-line:no-console
      this.timeEnd = console.timeEnd.bind(console);

      this.logToServer = this.error;
    } else {
      // In case of production replace the functions definition
      this.log = this.debug = this.info = this.warn = this.error = this.time = this.timeEnd = () => {};

      this.logToServer = (err: any) => {
        /** temp added to print in the console during production */
        // console.error(err);
        this.pushToServer(err);
        /** TODO: Integrate API for logging to server */
      };
    }
  }



  pushToServer(e) {
    let a = e;
    if (typeof e !== 'string') {
       a = JSON.stringify(e);
    }

    this.ngZone.run(() => {
      this.http.post(this.baseUrl + '/log', [a]).subscribe();

    });


  }
}
