/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./shared/components/app-side-nav/app-side-nav.component.ngfactory";
import * as i2 from "./shared/components/app-side-nav/app-side-nav.component";
import * as i3 from "../shared/services/owner.service";
import * as i4 from "@angular/router";
import * as i5 from "../components/shared/loader/loader.component.ngfactory";
import * as i6 from "../components/shared/loader/loader.component";
import * as i7 from "../core/services/loader/loader.service";
import * as i8 from "./dashboard.component";
var styles_DashboardComponent = [];
var RenderType_DashboardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DashboardComponent, data: {} });
export { RenderType_DashboardComponent as RenderType_DashboardComponent };
export function View_DashboardComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-side-nav", [], null, null, null, i1.View_AppSideNavComponent_0, i1.RenderType_AppSideNavComponent)), i0.ɵdid(1, 114688, null, 0, i2.AppSideNavComponent, [i3.OwnerService, i4.Router], null, null), (_l()(), i0.ɵeld(2, 16777216, null, 0, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(3, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-loader", [], null, null, null, i5.View_LoaderComponent_0, i5.RenderType_LoaderComponent)), i0.ɵdid(5, 114688, null, 0, i6.LoaderComponent, [i7.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_DashboardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-dashboard", [], null, null, null, View_DashboardComponent_0, RenderType_DashboardComponent)), i0.ɵdid(1, 4308992, null, 0, i8.DashboardComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardComponentNgFactory = i0.ɵccf("app-dashboard", i8.DashboardComponent, View_DashboardComponent_Host_0, {}, {}, []);
export { DashboardComponentNgFactory as DashboardComponentNgFactory };
