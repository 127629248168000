import { Component, OnInit, OnDestroy } from '@angular/core';
import { InspectorService } from '../../services/inspector.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-inspection-snapshot',
  templateUrl: './inspection-snapshot.component.html',
  styleUrls: ['./inspection-snapshot.component.scss']
})
export class InspectionSnapshotComponent implements OnInit, OnDestroy {

  public pendingApproval: number;
  inspectionSnapshot: IInspectionSnapshot;
  unsubscribe = [];
  constructor(private inspectorServices: InspectorService, private router: Router) { }

  ngOnInit() {
    const a = this.inspectorServices.getPendingApprovalPropertyCount().subscribe(res => {
      this.pendingApproval = res;
    });
    this.unsubscribe.push(a);
    const ab = this.inspectorServices.getInspectionSnapshot().subscribe((res: IInspectionSnapshot) => this.inspectionSnapshot = res);
    this.unsubscribe.push(ab);

  }

  onFilter(key, value) {
    const params = {};
    params[key] = value;

    this.router.navigate(['user', 'general', 'fileCabinet'], { queryParams: params });
  }

  navigateToCases() {
    this.router.navigate(['user/cases']);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(res => res.unsubscribe());
  }

}


export interface IInspectionSnapshot {
  Rejected: number;
  Systems_Pending_Approval: number;
  Saved: number;
  Submitted_For_Review: number;
  Properties_Pending_Approval: number;
  Waiting_Payment: number;
  Corrections_Required: number;
  Cases_Available_Count?: number;
}


