import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData, JournalComponent } from '../journal.component';
import { JournalContacts } from '../../../core/models/property/journal.model';
import { JournalDetailService } from '../../../core/services/property/journal-detail.service';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss']
})



export class ContactDialogComponent implements OnInit {

  contacts: Array<JournalContacts>;
  constructor(
    public dialogRef: MatDialogRef<JournalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public fb: FormBuilder, private journalDetailServices: JournalDetailService) { }

  ngOnInit() {
    this.getAllContacts();
  }

  getAllContacts() {
    this.journalDetailServices
      .getPropertyContactsByPropertyId(this.data.propertyId)
      .subscribe((res: JournalContacts[]) => {
        res.sort(function(a,b){
          return a.displaySequence - b.displaySequence
        });
        this.contacts = res;
        if(this.contacts.length > 5){
          this.contacts.length = 5;
        }
        console.log(res);
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.contacts, event.previousIndex, event.currentIndex);
  //   for (let i = 0; i < this.contacts.length; i++){
  //     this.contacts[i].displaySequence = i;
  //   }
  //   this.putContactsDisplaySequence(this.contacts);
  // }

  putContactsDisplaySequence(data: JournalContacts[]){
    this.journalDetailServices.putPropertyContactsDisplaySequence(data).subscribe((res: any) => {

    }, (error: any) => {
      console.log('Error while updating diplay sequence');
    });
  }
}
