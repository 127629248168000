import { Component, Input, OnInit } from '@angular/core';
import { UtilityService } from '../../../core/services/utility.service';

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss']
})
export class MapViewComponent implements OnInit {

  lat: number = 47.6062; // latitude of area
  lng: number = -122.3321; // longitude of area

  @Input() propertyMarker: PropertyMarker[]; // Available Properties
  constructor(private utilityService: UtilityService) { }

  ngOnInit() { }

  openenlargeMap(): void {
    this.utilityService.openEnlargeMap(this.propertyMarker);
  }

}

export interface mapDialogData {
  properties: PropertyMarker[];
  latitude: number;
  longitude: number;
}

export interface PropertyMarker{
  lat: number;
  lng: number;
  label?: string;
  propertyId?: string;
  propertyAssetId?: string;
}
