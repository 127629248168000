<div id="FireAlarmSystemDeviceDiv">
  <table class="sample" width="100%">
    <tbody>
      <tr class="GridRowGray">
        <td align="center" width="25%">System Devices</td>
        <td align="center" width="20%">Total Number of Units in Building</td>
        <td align="center" width="20%">Total Number Units Tested</td>
        <td align="center" width="30%">Test Results Acceptable</td>
      </tr>
      <tr>
        <td>Bells, Horns, Chimes</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[0].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_0__totalUnits"
                    [(ngModel)]="systemTable.bells.unitsInBuilding"
                    (change)="updateModel('Bells, Horns, Chimes', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[0].totalUnitsTested" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_0__totalUnitsTested"
                    [(ngModel)]="systemTable.bells.unitsTested"
                    (change)="updateModel('Bells, Horns, Chimes', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>

                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="systemTable.bells.resultsAcceptable">
                    <mat-radio-button name="reportDataDto.systemDevices[0].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_0__resultsAcceptedN/A"
                    value="N/A"
                    (change)="updateModel('Bells, Horns, Chimes', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[0].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_0__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Bells, Horns, Chimes', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[0].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_0__resultsAcceptedNo" value="No"
                    (change)="updateModel('Bells, Horns, Chimes', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.systemDevices[0].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_0__resultsAcceptedN/A" checked="checked"
                    value="N/A"
                    [(ngModel)]="systemTable.bells.resultsAcceptable"
                    (change)="updateModel('Bells, Horns, Chimes', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_0__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.systemDevices[0].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_0__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="systemTable.bells.resultsAcceptable"
                    (change)="updateModel('Bells, Horns, Chimes', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_0__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.systemDevices[0].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_0__resultsAcceptedNo" value="No"
                    [(ngModel)]="systemTable.bells.resultsAcceptable"
                    (change)="updateModel('Bells, Horns, Chimes', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_0__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>Horn Strobes</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[1].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_1__totalUnits"
                    [(ngModel)]="systemTable.hornStrobes.unitsInBuilding"
                    (change)="updateModel('Horn Strobes', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[1].totalUnitsTested" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_1__totalUnitsTested"
                    [(ngModel)]="systemTable.hornStrobes.unitsTested"
                    (change)="updateModel('Horn Strobes', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>

                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="systemTable.hornStrobes.resultsAcceptable">
                    <mat-radio-button name="reportDataDto.systemDevices[1].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_1__resultsAcceptedN/A" 
                    value="N/A"
                    (change)="updateModel('Horn Strobes', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[1].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_1__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Horn Strobes', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[1].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_1__resultsAcceptedNo" value="No"
                    (change)="updateModel('Horn Strobes', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.systemDevices[1].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_1__resultsAcceptedN/A" checked="checked"
                    value="N/A"
                    [(ngModel)]="systemTable.hornStrobes.resultsAcceptable"
                    (change)="updateModel('Horn Strobes', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_1__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.systemDevices[1].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_1__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="systemTable.hornStrobes.resultsAcceptable"
                    (change)="updateModel('Horn Strobes', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_1__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.systemDevices[1].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_1__resultsAcceptedNo" value="No"
                    [(ngModel)]="systemTable.hornStrobes.resultsAcceptable"
                    (change)="updateModel('Horn Strobes', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_1__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>Voice Speakers (Voice Clarity)</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[2].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_2__totalUnits"
                    [(ngModel)]="systemTable.voiceSpeakers.unitsInBuilding"
                    (change)="updateModel('Voice Speakers (Voice Clarity)', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[2].totalUnitsTested" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_2__totalUnitsTested"
                    [(ngModel)]="systemTable.voiceSpeakers.unitsTested"
                    (change)="updateModel('Voice Speakers (Voice Clarity)', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>

                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="systemTable.voiceSpeakers.resultsAcceptable">
                    <mat-radio-button name="reportDataDto.systemDevices[2].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_2__resultsAcceptedN/A"
                    value="N/A"
                    (change)="updateModel('Voice Speakers (Voice Clarity)', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[2].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_2__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Voice Speakers (Voice Clarity)', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[2].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_2__resultsAcceptedNo" value="No"
                    (change)="updateModel('Voice Speakers (Voice Clarity)', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.systemDevices[2].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_2__resultsAcceptedN/A" checked="checked"
                    value="N/A"
                    [(ngModel)]="systemTable.voiceSpeakers.resultsAcceptable"
                    (change)="updateModel('Voice Speakers (Voice Clarity)', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_2__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.systemDevices[2].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_2__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="systemTable.voiceSpeakers.resultsAcceptable"
                    (change)="updateModel('Voice Speakers (Voice Clarity)', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_2__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.systemDevices[2].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_2__resultsAcceptedNo" value="No"
                    [(ngModel)]="systemTable.voiceSpeakers.resultsAcceptable"
                    (change)="updateModel('Voice Speakers (Voice Clarity)', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_2__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <input type="hidden" name="reportDataDto.systemDevices[3].systemDeviceInspectionResultId" value=""
          id="reviewInspectionForm_reportDataDto_systemDevices_3__systemDeviceInspectionResultId">
        <input type="hidden" name="reportDataDto.systemDevices[3].systemDeviceId" value="106"
          id="reviewInspectionForm_reportDataDto_systemDevices_3__systemDeviceId">
        <input type="hidden" name="reportDataDto.systemDevices[3].inspectionResultId" value=""
          id="reviewInspectionForm_reportDataDto_systemDevices_3__inspectionResultId">
        <td>Smoke Detectors</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[3].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_3__totalUnits"
                    [(ngModel)]="systemTable.smokeDetectors.unitsInBuilding"
                    (change)="updateModel('Smoke Detectors', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[3].totalUnitsTested" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_3__totalUnitsTested"
                    [(ngModel)]="systemTable.smokeDetectors.unitsTested"
                    (change)="updateModel('Smoke Detectors', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>

                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="systemTable.smokeDetectors.resultsAcceptable">
                    <mat-radio-button name="reportDataDto.systemDevices[3].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_3__resultsAcceptedN/A"
                    value="N/A"
                    (change)="updateModel('Smoke Detectors', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[3].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_3__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Smoke Detectors', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[3].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_3__resultsAcceptedNo" value="No"
                    (change)="updateModel('Smoke Detectors', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.systemDevices[3].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_3__resultsAcceptedN/A" checked="checked"
                    value="N/A"
                    [(ngModel)]="systemTable.smokeDetectors.resultsAcceptable"
                    (change)="updateModel('Smoke Detectors', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_3__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.systemDevices[3].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_3__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="systemTable.smokeDetectors.resultsAcceptable"
                    (change)="updateModel('Smoke Detectors', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_3__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.systemDevices[3].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_3__resultsAcceptedNo" value="No"
                    [(ngModel)]="systemTable.smokeDetectors.resultsAcceptable"
                    (change)="updateModel('Smoke Detectors', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_3__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>Heat Detectors</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[4].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_4__totalUnits"
                    [(ngModel)]="systemTable.heatDetectors.unitsInBuilding"
                    (change)="updateModel('Heat Detectors', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[4].totalUnitsTested" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_4__totalUnitsTested"
                    [(ngModel)]="systemTable.heatDetectors.unitsTested"
                    (change)="updateModel('Heat Detectors', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>

                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="systemTable.heatDetectors.resultsAcceptable">
                    <mat-radio-button name="reportDataDto.systemDevices[4].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_4__resultsAcceptedN/A"
                    value="N/A"
                    (change)="updateModel('Heat Detectors', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[4].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_4__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Heat Detectors', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[4].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_4__resultsAcceptedNo" value="No"
                    (change)="updateModel('Heat Detectors', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.systemDevices[4].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_4__resultsAcceptedN/A" checked="checked"
                    value="N/A"
                    [(ngModel)]="systemTable.heatDetectors.resultsAcceptable"
                    (change)="updateModel('Heat Detectors', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_4__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.systemDevices[4].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_4__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="systemTable.heatDetectors.resultsAcceptable"
                    (change)="updateModel('Heat Detectors', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_4__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.systemDevices[4].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_4__resultsAcceptedNo" value="No"
                    [(ngModel)]="systemTable.heatDetectors.resultsAcceptable"
                    (change)="updateModel('Heat Detectors', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_4__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>Duct Detectors</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[5].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_5__totalUnits"
                    [(ngModel)]="systemTable.ductDetectors.unitsInBuilding"
                    (change)="updateModel('Duct Detectors', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[5].totalUnitsTested" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_5__totalUnitsTested"
                    [(ngModel)]="systemTable.ductDetectors.unitsTested"
                    (change)="updateModel('Duct Detectors', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>

                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="systemTable.ductDetectors.resultsAcceptable">
                    <mat-radio-button name="reportDataDto.systemDevices[5].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_5__resultsAcceptedN/A"
                    value="N/A"
                    (change)="updateModel('Duct Detectors', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[5].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_5__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Duct Detectors', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[5].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_5__resultsAcceptedNo" value="No"
                    (change)="updateModel('Duct Detectors', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.systemDevices[5].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_5__resultsAcceptedN/A" checked="checked"
                    value="N/A"
                    [(ngModel)]="systemTable.ductDetectors.resultsAcceptable"
                    (change)="updateModel('Duct Detectors', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_5__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.systemDevices[5].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_5__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="systemTable.ductDetectors.resultsAcceptable"
                    (change)="updateModel('Duct Detectors', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_5__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.systemDevices[5].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_5__resultsAcceptedNo" value="No"
                    [(ngModel)]="systemTable.ductDetectors.resultsAcceptable"
                    (change)="updateModel('Duct Detectors', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_5__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>Sprinkler Flow Switches</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[6].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_6__totalUnits"
                    [(ngModel)]="systemTable.sprinklerFlow.unitsInBuilding"
                    (change)="updateModel('Sprinkler Flow Switches', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[6].totalUnitsTested" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_6__totalUnitsTested"
                    [(ngModel)]="systemTable.sprinklerFlow.unitsTested"
                    (change)="updateModel('Sprinkler Flow Switches', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>

                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="systemTable.sprinklerFlow.resultsAcceptable">
                    <mat-radio-button name="reportDataDto.systemDevices[6].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_6__resultsAcceptedN/A"
                    value="N/A"
                    (change)="updateModel('Sprinkler Flow Switches', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[6].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_6__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Sprinkler Flow Switches', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[6].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_6__resultsAcceptedNo" value="No"
                    (change)="updateModel('Sprinkler Flow Switches', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.systemDevices[6].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_6__resultsAcceptedN/A" checked="checked"
                    value="N/A"
                    [(ngModel)]="systemTable.sprinklerFlow.resultsAcceptable"
                    (change)="updateModel('Sprinkler Flow Switches', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_6__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.systemDevices[6].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_6__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="systemTable.sprinklerFlow.resultsAcceptable"
                    (change)="updateModel('Sprinkler Flow Switches', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_6__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.systemDevices[6].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_6__resultsAcceptedNo" value="No"
                    [(ngModel)]="systemTable.sprinklerFlow.resultsAcceptable"
                    (change)="updateModel('Sprinkler Flow Switches', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_6__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>Sprinkler Supervisory Switches</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[7].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_7__totalUnits"
                    [(ngModel)]="systemTable.sprinklerSupervisory.unitsInBuilding"
                    (change)="updateModel('Sprinkler Supervisory Switches', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[7].totalUnitsTested" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_7__totalUnitsTested"
                    [(ngModel)]="systemTable.sprinklerSupervisory.unitsTested"
                    (change)="updateModel('Sprinkler Supervisory Switches', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>

                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="systemTable.sprinklerSupervisory.resultsAcceptable"
                  >
                    <mat-radio-button name="reportDataDto.systemDevices[7].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_7__resultsAcceptedN/A"
                    value="N/A"
                    (change)="updateModel('Sprinkler Supervisory Switches', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[7].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_7__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Sprinkler Supervisory Switches', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[7].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_7__resultsAcceptedNo" value="No"
                    (change)="updateModel('Sprinkler Supervisory Switches', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.systemDevices[7].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_7__resultsAcceptedN/A" checked="checked"
                    value="N/A"
                    [(ngModel)]="systemTable.sprinklerSupervisory.resultsAcceptable"
                    (change)="updateModel('Sprinkler Supervisory Switches', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_7__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.systemDevices[7].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_7__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="systemTable.sprinklerSupervisory.resultsAcceptable"
                    (change)="updateModel('Sprinkler Supervisory Switches', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_7__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.systemDevices[7].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_7__resultsAcceptedNo" value="No"
                    [(ngModel)]="systemTable.sprinklerSupervisory.resultsAcceptable"
                    (change)="updateModel('Sprinkler Supervisory Switches', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_7__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>

        <td>Visual Alarm Devices</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[8].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_8__totalUnits"
                    [(ngModel)]="systemTable.visualAlarm.unitsInBuilding"
                    (change)="updateModel('Visual Alarm Devices', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[8].totalUnitsTested" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_8__totalUnitsTested"
                    [(ngModel)]="systemTable.visualAlarm.unitsTested"
                    (change)="updateModel('Visual Alarm Devices', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>

                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="systemTable.visualAlarm.resultsAcceptable"
                  >
                    <mat-radio-button name="reportDataDto.systemDevices[8].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_8__resultsAcceptedN/A"
                    value="N/A"
                    (change)="updateModel('Visual Alarm Devices', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[8].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_8__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Visual Alarm Devices', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[8].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_8__resultsAcceptedNo" value="No"
                    (change)="updateModel('Visual Alarm Devices', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.systemDevices[8].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_8__resultsAcceptedN/A" checked="checked"
                    value="N/A"
                    [(ngModel)]="systemTable.visualAlarm.resultsAcceptable"
                    (change)="updateModel('Visual Alarm Devices', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_8__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.systemDevices[8].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_8__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="systemTable.visualAlarm.resultsAcceptable"
                    (change)="updateModel('Visual Alarm Devices', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_8__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.systemDevices[8].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_8__resultsAcceptedNo" value="No"
                    [(ngModel)]="systemTable.visualAlarm.resultsAcceptable"
                    (change)="updateModel('Visual Alarm Devices', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_8__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>Manual Pull Stations</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[9].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_9__totalUnits"
                    [(ngModel)]="systemTable.manualPull.unitsInBuilding"
                    (change)="updateModel('Manual Pull Stations', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[9].totalUnitsTested" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_9__totalUnitsTested"
                    [(ngModel)]="systemTable.manualPull.unitsTested"
                    (change)="updateModel('Manual Pull Stations', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>

                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="systemTable.manualPull.resultsAcceptable"
                  >
                    <mat-radio-button name="reportDataDto.systemDevices[9].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_9__resultsAcceptedN/A"
                    value="N/A"
                    (change)="updateModel('Manual Pull Stations', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[9].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_9__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Manual Pull Stations', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[9].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_9__resultsAcceptedNo" value="No"
                    (change)="updateModel('Manual Pull Stations', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.systemDevices[9].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_9__resultsAcceptedN/A" checked="checked"
                    value="N/A"
                    [(ngModel)]="systemTable.manualPull.resultsAcceptable"
                    (change)="updateModel('Manual Pull Stations', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_9__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.systemDevices[9].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_9__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="systemTable.manualPull.resultsAcceptable"
                    (change)="updateModel('Manual Pull Stations', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_9__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.systemDevices[9].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_9__resultsAcceptedNo" value="No"
                    [(ngModel)]="systemTable.manualPull.resultsAcceptable"
                    (change)="updateModel('Manual Pull Stations', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_9__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>Automatic Door Unlocks</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[10].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_10__totalUnits"
                    [(ngModel)]="systemTable.automaticDoorUnlocks.unitsInBuilding"
                    (change)="updateModel('Automatic Door Unlocks', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[10].totalUnitsTested" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_10__totalUnitsTested"
                    [(ngModel)]="systemTable.automaticDoorUnlocks.unitsTested"
                    (change)="updateModel('Automatic Door Unlocks', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>

                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="systemTable.automaticDoorUnlocks.resultsAcceptable"
                  >
                    <mat-radio-button name="reportDataDto.systemDevices[10].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_10__resultsAcceptedN/A"
                    value="N/A"
                    (change)="updateModel('Automatic Door Unlocks', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[10].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_10__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Automatic Door Unlocks', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[10].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_10__resultsAcceptedNo" value="No"
                    (change)="updateModel('Automatic Door Unlocks', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.systemDevices[10].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_10__resultsAcceptedN/A" checked="checked"
                    value="N/A"
                    [(ngModel)]="systemTable.automaticDoorUnlocks.resultsAcceptable"
                    (change)="updateModel('Automatic Door Unlocks', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_10__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.systemDevices[10].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_10__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="systemTable.automaticDoorUnlocks.resultsAcceptable"
                    (change)="updateModel('Automatic Door Unlocks', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_10__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.systemDevices[10].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_10__resultsAcceptedNo" value="No"
                    [(ngModel)]="systemTable.automaticDoorUnlocks.resultsAcceptable"
                    (change)="updateModel('Automatic Door Unlocks', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_10__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>Automatic Door Release</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[11].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_11__totalUnits"
                    [(ngModel)]="systemTable.automaticDoorRelease.unitsInBuilding"
                    (change)="updateModel('Automatic Door Release', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[11].totalUnitsTested" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_11__totalUnitsTested"
                    [(ngModel)]="systemTable.automaticDoorRelease.unitsTested"
                    (change)="updateModel('Automatic Door Release', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>

                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="systemTable.automaticDoorRelease.resultsAcceptable"
                  >
                    <mat-radio-button name="reportDataDto.systemDevices[11].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_11__resultsAcceptedN/A"
                    value="N/A"
                    (change)="updateModel('Automatic Door Release', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[11].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_11__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Automatic Door Release', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[11].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_11__resultsAcceptedNo" value="No"
                    (change)="updateModel('Automatic Door Release', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.systemDevices[11].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_11__resultsAcceptedN/A" checked="checked"
                    value="N/A"
                    [(ngModel)]="systemTable.automaticDoorRelease.resultsAcceptable"
                    (change)="updateModel('Automatic Door Release', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_11__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.systemDevices[11].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_11__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="systemTable.automaticDoorRelease.resultsAcceptable"
                    (change)="updateModel('Automatic Door Release', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_11__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.systemDevices[11].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_11__resultsAcceptedNo" value="No"
                    [(ngModel)]="systemTable.automaticDoorRelease.resultsAcceptable"
                    (change)="updateModel('Automatic Door Release', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_11__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>Annunciator(s)</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[12].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_12__totalUnits"
                    [(ngModel)]="systemTable.annunciator.unitsInBuilding"
                    (change)="updateModel('Annunciator(s)', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[12].totalUnitsTested" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_12__totalUnitsTested"
                    [(ngModel)]="systemTable.annunciator.unitsTested"
                    (change)="updateModel('Annunciator(s)', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>

                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="systemTable.annunciator.resultsAcceptable"
                  >
                    <mat-radio-button name="reportDataDto.systemDevices[12].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_12__resultsAcceptedN/A"
                    value="N/A"
                    (change)="updateModel('Annunciator(s)', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[12].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_12__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Annunciator(s)', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[12].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_12__resultsAcceptedNo" value="No"
                    (change)="updateModel('Annunciator(s)', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.systemDevices[12].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_12__resultsAcceptedN/A" checked="checked"
                    value="N/A"
                    [(ngModel)]="systemTable.annunciator.resultsAcceptable"
                    (change)="updateModel('Annunciator(s)', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_12__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.systemDevices[12].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_12__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="systemTable.annunciator.resultsAcceptable"
                    (change)="updateModel('Annunciator(s)', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_12__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.systemDevices[12].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_12__resultsAcceptedNo" value="No"
                    [(ngModel)]="systemTable.annunciator.resultsAcceptable"
                    (change)="updateModel('Annunciator(s)', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_12__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>Beam Detectors</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[13].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_13__totalUnits"
                    [(ngModel)]="systemTable.beamDetectors.unitsInBuilding"
                    (change)="updateModel('Beam Detectors', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[13].totalUnitsTested" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_13__totalUnitsTested"
                    [(ngModel)]="systemTable.beamDetectors.unitsTested"
                    (change)="updateModel('Beam Detectors', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>

                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="systemTable.beamDetectors.resultsAcceptable"
                  >
                    <mat-radio-button name="reportDataDto.systemDevices[13].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_13__resultsAcceptedN/A"
                    value="N/A"
                    (change)="updateModel('Beam Detectors', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[13].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_13__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Beam Detectors', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[13].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_13__resultsAcceptedNo" value="No"
                    (change)="updateModel('Beam Detectors', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.systemDevices[13].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_13__resultsAcceptedN/A" checked="checked"
                    value="N/A"
                    [(ngModel)]="systemTable.beamDetectors.resultsAcceptable"
                    (change)="updateModel('Beam Detectors', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_13__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.systemDevices[13].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_13__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="systemTable.beamDetectors.resultsAcceptable"
                    (change)="updateModel('Beam Detectors', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_13__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.systemDevices[13].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_13__resultsAcceptedNo" value="No"
                    [(ngModel)]="systemTable.beamDetectors.resultsAcceptable"
                    (change)="updateModel('Beam Detectors', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_13__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>Fire Dampers</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[14].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_14__totalUnits"
                    [(ngModel)]="systemTable.fireDampers.unitsInBuilding"
                    (change)="updateModel('Fire Dampers', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.systemDevices[14].totalUnitsTested" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_systemDevices_14__totalUnitsTested"
                    [(ngModel)]="systemTable.fireDampers.unitsTested"
                    (change)="updateModel('Fire Dampers', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>

                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="systemTable.fireDampers.resultsAcceptable"
                  >
                    <mat-radio-button name="reportDataDto.systemDevices[14].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_14__resultsAcceptedN/A"
                    value="N/A"
                    (change)="updateModel('Fire Dampers', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[14].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_14__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Fire Dampers', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.systemDevices[14].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_14__resultsAcceptedNo" value="No"
                    (change)="updateModel('Fire Dampers', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.systemDevices[14].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_14__resultsAcceptedN/A" checked="checked"
                    value="N/A"
                    [(ngModel)]="systemTable.fireDampers.resultsAcceptable"
                    (change)="updateModel('Fire Dampers', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_14__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.systemDevices[14].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_14__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="systemTable.fireDampers.resultsAcceptable"
                    (change)="updateModel('Fire Dampers', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_14__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.systemDevices[14].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_systemDevices_14__resultsAcceptedNo" value="No"
                    [(ngModel)]="systemTable.fireDampers.resultsAcceptable"
                    (change)="updateModel('Fire Dampers', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_systemDevices_14__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</div>
