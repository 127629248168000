<form #formQuestion="ngForm" (ngSubmit)="onSubmit()" class="inspectionForm" focusInvalidInput
  [ngClass]="{'reviewInspection': reviewInspection==null}" style="padding: 15px;border: 1px solid #f1f1f1;">
  <div *ngIf="!showSimplified || (!showSimplified && reviewInspection)">
    <div class="cleanAgent" *ngIf="assetTypeid === '10'">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12 questions">
              <app-extinguishing-agent [questions]="questions" [qtd]="qtd" [reviewInspection]="reviewInspection">
              </app-extinguishing-agent>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="shaft" *ngIf="assetTypeid === '13'">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12 questions">
              <app-shaft [questions]="questions" [qtd]="qtd" [reviewInspection]="reviewInspection"></app-shaft>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="standpipe" *ngIf="assetTypeid === '6'">
      <div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-5 questions">
              Standpipe Class:
            </div>
            <div class="col-md-5">
              <mat-radio-group class="example-radio-group" id="standpipeclass" name="standpipeclassName" required
                [(ngModel)]="qtd[findQuestionId('Standpipe Class:')]" [disabled]="printBlank || reviewInspection"
                (change)="setClass($event)" #radio5="ngModel">
                <mat-radio-button class="example-radio-button" value="class1" [disabled]="reviewInspection">
                  Class I&nbsp;
                </mat-radio-button>

                <mat-radio-button class="example-radio-button" value="class2" [disabled]="reviewInspection">
                  Class II&nbsp;
                </mat-radio-button>

                <mat-radio-button class="example-radio-button" value="class3" [disabled]="reviewInspection">
                  Class III&nbsp;
                </mat-radio-button>
              </mat-radio-group>
              <div *ngIf="submitted" [hidden]="radio5.valid" class="alert alert-danger">
                * Please answer this question
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hydrant" *ngIf="assetTypeid === '11'">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12 hydrantQuestion">
              Locate the hydrant lateral gate valve and close six turns. Open the hydrant lateral gate valve until a
              stop
              is reached and then back it off 1/2 turn. (This will assure that this valve is fully open)
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="assetTypeid === '14'">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12 hydrantQuestion" style="font-weight: bold !important;">
              The following document outlines operational conditions of In-Building Emergency-Responder Signal
              Reinforcement Systems.
              These alarm and monitoring requirements are referenced in International Fire Code (IFC), NFPA 72 and local
              standards.
            </div>
          </div>
        </div>
      </div>
    </div>


    <ng-container *ngIf="isDampnerTableQuestion">
      <app-fire-smoke-damper [questions]="questions" [qtd]="qtd" [reviewInspection]="reviewInspection"></app-fire-smoke-damper>
    </ng-container>

    
    <ng-container *ngIf="(assetTypeid == '17' && !isDampnerTableQuestion) || (assetTypeid != '17' && !isDampnerTableQuestion)">
      <div *ngFor="let question of questions">
        <!-- Line 800+ related -->
        <div *ngIf="question.questions.mainHeading === 'Heading' &&
          (!question.questions.questionType ||
          ( question.questions.questionType &&
          question.questions.questionType !== 'Delete')) &&
          question.questions.questionType!== 'Additional' &&
          question.questions.question!== 'CLASS I' &&
          question.questions.question!== 'CLASS II' &&
          question.questions.question!== 'CLASS III'||
          (question.questions.question &&
          question.questions.question === 'CLASS I' &&
          (qtd[findQuestionId('Standpipe Class:')] === 'class1' || printBlank)) ||
          (question.questions.question &&
          question.questions.question === 'CLASS II' &&
          (qtd[findQuestionId('Standpipe Class:')] === 'class2' || printBlank)) ||
          (question.questions.question &&
          question.questions.question === 'CLASS III' &&
          (qtd[findQuestionId('Standpipe Class:')] === 'class3' || printBlank))" style="width: 100%;">
          <div *ngIf="question.questions && !question.questions.optionType">
            <div class="rowOuter">
              <div class="row">
                <div class="col-md-4 mainHeading">
                  {{question.questions.question | uppercase}}
                </div>
              </div>
  
              <div class="row">
                <div class="col-md-12"
                  *ngIf="question.questions.subquestion && question.questions.subquestion.length > 0 && inspectionDataforSecondPage.accessSpecifier === 'CUSTOM'">
                  <div *ngFor="let subQuestion of question.questions.subquestion"
                    [ngClass]="!printBlank ? 'subQuestion': ''">
                    <div *ngIf="subQuestion.optionType && subQuestion.optionType === 'Radio'" style="width: 100%;">
                      <div class="rowOuter">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-5 questionsCustom">
                              {{subQuestion.question}}
                            </div>
                            <div class="col-md-7">
                              <mat-radio-group class="example-radio-group" name="name-{{subQuestion.questionId}}"
                                [(ngModel)]="qtd[subQuestion.questionId]" id="enum_answer_{{subQuestion.questionId}}"
                                [disabled]="printBlank || reviewInspection" required #radio7="ngModel">
                                <mat-radio-button class="example-radio-button" *ngFor="let option of subQuestion.options"
                                  [value]="option.data" [disabled]="printBlank || reviewInspection">
                                  {{option.data}}&nbsp;
                                </mat-radio-button>
                              </mat-radio-group>
                              <div *ngIf="submitted" [hidden]="radio7.valid" class="alert alert-danger">
                                * Please answer this question
                              </div>
                            </div>
                            <div class="col-md-7"
                              *ngIf="subQuestion.subquestion && subQuestion.subquestion[0] && subQuestion.subquestion[0].questionType && subQuestion.subquestion[0].questionType === 'Custom'">
                              <div [ngClass]="!printBlank ? 'subQuestion': ''">
                                <div *ngIf="subQuestion.subquestion[0].optionType === 'Text'">
                                  <input style="width:130px!important" type="text" class="rounded-form-fields"
                                    name="qtd-{{subQuestion.subquestion[0].questionId}}"
                                    id="qtd{{subQuestion.subquestion[0].questionId}}"
                                    [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                                    [disabled]="printBlank || reviewInspection"
                                    [(ngModel)]="qtd[subQuestion.subquestion[0].questionId]" />&nbsp;<label
                                    class="questions">
                                    {{subQuestion.subquestion[0].unit}}</label>&nbsp;
                                  <div *ngIf="submitted" [hidden]="qtd[subQuestion.subquestion[0].questionId]"
                                    class="alert alert-danger">
                                    * Please answer this question
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="subQuestion.optionType && subQuestion.optionType === 'Checkbox'" style="width: 100%;">
                      <div class="rowOuter">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-5 questionsCustom">
                              {{subQuestion.question}}
                            </div>
                            <div class="col-md-7">
                              <div style="display:inline;margin-right:2px;" *ngFor="let option of subQuestion.options">
                                <input type="checkbox" id="enum_answer_{{option.id}}"
                                  [(ngModel)]="qtd[subQuestion.questionId + '*o*'+ option.data]" name="name-{{option.id}}"
                                  [disabled]="reviewInspection"
                                  [checked]="qtd[subQuestion.questionId + '*o*'+ option.data]" ngModel>
                                &nbsp;&nbsp;
                                <label for="enum_answer_{{option.id}}"
                                  class="questions">{{option.data}}</label>&nbsp;&nbsp;
                              </div>
                              <div style="display:inline;margin-right:2px;">
                                <div
                                  *ngIf="displayCondition(subQuestion.questionId, subQuestion.options , subQuestion.questionType)  && submitted"
                                  class="alert alert-danger">
                                  * Please answer this question
                                </div>
                              </div>
                            </div>
                            <div class="col-md-7"
                              *ngIf="subQuestion.subquestion && subQuestion.subquestion[0] && subQuestion.subquestion[0].questionType && subQuestion.subquestion[0].questionType === 'Custom'">
                              <div *ngFor="let subQuestion of subQuestion.subquestion"
                                [ngClass]="!printBlank ? 'subQuestion': ''">
                                <div *ngIf="subQuestion.optionType === 'Text'">
                                  <input style="width:130px!important" type="text" class="rounded-form-fields"
                                    name="qtd-{{subQuestion.questionId}}" id="qtd{{subQuestion.questionId}}"
                                    [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                                    [disabled]="printBlank || reviewInspection"
                                    [(ngModel)]="qtd[subQuestion.questionId]" />&nbsp;<label class="questions">
                                    {{subQuestion.unit}}</label>&nbsp;
                                  <div *ngIf="submitted" [hidden]="qtd[subQuestion.questionId]"
                                    class="alert alert-danger">
                                    * Please answer this question
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="subQuestion.optionType && subQuestion.optionType === 'TextArea'" style="width: 100%;">
                      <div class="rowOuter">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-5 questionsCustom">
                              {{subQuestion.question}}
                            </div>
                            <div class="col-md-7">
                              <div class="row">
                                <div class="col-md-7">
                                  <textarea rows="4" cols="30" name="name-{{subQuestion.questionId}}" #textArea1="ngModel"
                                    [(ngModel)]="qtd[subQuestion.questionId]" [disabled]="reviewInspection" required>
                                  </textarea>
                                  <div *ngIf="submitted" [hidden]="textArea1.valid" class="alert alert-danger">
                                    * Please answer this question
                                  </div>
                                </div>
                                <div class="col-md-2"
                                  *ngIf="subQuestion.subquestion && subQuestion.subquestion[0] &&  subQuestion.subquestion[0].questionType && subQuestion.subquestion[0].questionType === 'Custom'">
                                  <div *ngFor="let subQuestion of subQuestion.subquestion" style="margin-top:15px;"
                                    [ngClass]="!printBlank ? 'subQuestion': ''">
                                    <div *ngIf="subQuestion.optionType === 'Checkbox'">
                                      <div style="display:inline;margin-right:2px;"
                                        *ngFor="let option of subQuestion.options">
                                        <input type="checkbox" id="enum_answer_{{option.id}}"
                                          [(ngModel)]="qtd[subQuestion.questionId + '*o*'+ option.data]"
                                          name="name-{{option.id}}" [disabled]="reviewInspection"
                                          [checked]="qtd[subQuestion.questionId + '*o*'+ option.data]" ngModel>
                                        &nbsp;&nbsp;
                                        <label for="enum_answer_{{option.id}}"
                                          class="questions">{{option.data}}</label>&nbsp;&nbsp;
                                      </div>
                                    </div>
                                    <div *ngIf="subQuestion.optionType === 'Text'" class="col-md-6">
                                      <input style="width:130px!important" type="text" class="rounded-form-fields"
                                        name="qtd-{{subQuestion.questionId}}" id="qtd{{subQuestion.questionId}}"
                                        [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                                        [disabled]="printBlank || reviewInspection"
                                        [(ngModel)]="qtd[subQuestion.questionId]" />&nbsp;<label class="questions">
                                        {{subQuestion.unit}}</label>&nbsp;
                                      <div
                                        *ngIf="submitted && (!qtd[subQuestion.questionId] || qtd[subQuestion.questionId] === '')"
                                        class="alert alert-danger">
                                        * Please answer this question
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div *ngIf="question.questions && question.questions.optionType && question.questions.optionType === 'Radio'"
            style="width: 100%;">
            <div class="rowOuter">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-5 mainHeading" style="margin-left: 0px">
                    {{question.questions.question | uppercase}}
                  </div>
                  <div class="col-md-7">
                    <div class="row">
                      <mat-radio-group class="example-radio-group" name="name-{{question.questions.questionId}}"
                        [(ngModel)]="qtd[question.questions.questionId]"
                        id="enum_answer_{{question.questions.questionId}}" [disabled]="printBlank || reviewInspection"
                        required #radio6="ngModel">
                        <mat-radio-button class="example-radio-button" *ngFor="let option of question.questions.options"
                          [value]="option.data" [disabled]="printBlank || reviewInspection">
                          {{option.data}}&nbsp;
                        </mat-radio-button>
                        <div *ngIf="submitted" [hidden]="radio6.valid" class="alert alert-danger">
                          * Please answer this question
                        </div>
                      </mat-radio-group>
                      <div *ngIf="question.questions.subquestion && question.questions.subquestion.length > 0">
                        <div *ngFor="let subQuestion of question.questions.subquestion"
                          [ngClass]="!printBlank ? 'subQuestion': ''">
                          <div *ngIf="subQuestion.optionType === 'Text' && subQuestion.questionType === 'Custom'">
                            <input style="width:130px!important" type="text" class="rounded-form-fields"
                              name="qtd-{{subQuestion.questionId}}" id="qtd{{subQuestion.questionId}}"
                              [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                              [disabled]="printBlank || reviewInspection"
                              [(ngModel)]="qtd[subQuestion.questionId]" />&nbsp;<label class="questions">
                              {{subQuestion.unit}}</label>&nbsp;
                            <div *ngIf="submitted" [hidden]="qtd[subQuestion.questionId]" class="alert alert-danger">
                              * Please answer this question
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <!-- Handled all cases -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" *ngIf="question.questions.subquestion && question.questions.subquestion.length > 0">
                <div *ngFor="let subQuestion of question.questions.subquestion"
                  [ngClass]="!printBlank ? 'subQuestion': ''">
                  <div
                    *ngIf="subQuestion.optionType && subQuestion.optionType === 'Radio' && qtd[question.questions.questionId] === question.questions.options[0].data"
                    style="width: 100%;">
                    <div class="rowOuter">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-5 questionsCustom">
                            {{subQuestion.question}}
                          </div>
                          <div class="col-md-7">
                            <div class="row">
                              <mat-radio-group class="example-radio-group" name="name-{{subQuestion.questionId}}"
                                [(ngModel)]="qtd[subQuestion.questionId]" id="enum_answer_{{subQuestion.questionId}}"
                                [disabled]="printBlank || reviewInspection" required #radio7="ngModel">
                                <mat-radio-button class="example-radio-button" *ngFor="let option of subQuestion.options"
                                  [value]="option.data" [disabled]="printBlank || reviewInspection">
                                  {{option.data}}&nbsp;
                                </mat-radio-button>
                                <div *ngIf="submitted" [hidden]="radio7.valid" class="alert alert-danger">
                                  * Please answer this question
                                </div>
                              </mat-radio-group>
                              <div
                                *ngIf="subQuestion.subquestion && subQuestion.subquestion[0] && subQuestion.subquestion[0].questionType && subQuestion.subquestion[0].questionType === 'Custom'">
                                <div [ngClass]="!printBlank ? 'subQuestion': ''">
                                  <div *ngIf="subQuestion.subquestion[0].optionType === 'Text'">
                                    <input style="width:130px!important" type="text" class="rounded-form-fields"
                                      name="qtd-{{subQuestion.subquestion[0].questionId}}"
                                      id="qtd{{subQuestion.subquestion[0].questionId}}"
                                      [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                                      [disabled]="printBlank || reviewInspection"
                                      [(ngModel)]="qtd[subQuestion.subquestion[0].questionId]" />&nbsp;<label
                                      class="questions">
                                      {{subQuestion.subquestion[0].unit}}</label>&nbsp;
                                    <div *ngIf="submitted" [hidden]="qtd[subQuestion.subquestion[0].questionId]"
                                      class="alert alert-danger">
                                      * Please answer this question
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="subQuestion.optionType && subQuestion.optionType === 'Checkbox' && qtd[question.questions.questionId] === question.questions.options[0].data"
                    style="width: 100%;">
                    <div class="rowOuter">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-5 questionsCustom">
                            {{subQuestion.question}}
                          </div>
                          <div class="col-md-7">
                            <div class="row">
                              <div style="display:inline;margin-right:2px;" *ngFor="let option of subQuestion.options">
                                <input type="checkbox" id="enum_answer_{{option.id}}"
                                  [(ngModel)]="qtd[subQuestion.questionId + '*o*'+ option.data]" name="name-{{option.id}}"
                                  [disabled]="reviewInspection"
                                  [checked]="qtd[subQuestion.questionId + '*o*'+ option.data]" ngModel>
                                &nbsp;&nbsp;
                                <label for="enum_answer_{{option.id}}"
                                  class="questions">{{option.data}}</label>&nbsp;&nbsp;
                              </div>
                              <div style="display:inline;margin-right:2px;">
                                <div
                                  *ngIf="displayCondition(subQuestion.questionId, subQuestion.options , subQuestion.questionType)  && submitted"
                                  class="alert alert-danger">
                                  * Please answer this question
                                </div>
                              </div>
                              <div
                                *ngIf="subQuestion.subquestion && subQuestion.subquestion[0] && subQuestion.subquestion[0].questionType && subQuestion.subquestion[0].questionType === 'Custom'">
                                <div *ngFor="let subQuestion of subQuestion.subquestion"
                                  [ngClass]="!printBlank ? 'subQuestion': ''">
                                  <div *ngIf="subQuestion.optionType === 'Text'">
                                    <input style="width:130px!important" type="text" class="rounded-form-fields"
                                      name="qtd-{{subQuestion.questionId}}" id="qtd{{subQuestion.questionId}}"
                                      [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                                      [disabled]="printBlank || reviewInspection"
                                      [(ngModel)]="qtd[subQuestion.questionId]" />&nbsp;<label class="questions">
                                      {{subQuestion.unit}}</label>&nbsp;
                                    <div *ngIf="submitted" [hidden]="qtd[subQuestion.questionId]"
                                      class="alert alert-danger">
                                      * Please answer this question
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="subQuestion.optionType && subQuestion.optionType === 'TextArea' && qtd[question.questions.questionId] === question.questions.options[0].data"
                    style="width: 100%;">
                    <div class="rowOuter">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-5 questionsCustom">
                            {{subQuestion.question}}
                          </div>
                          <div class="col-md-7">
                            <div class="row">
                              <div class="col-md-7">
                                <textarea rows="4" cols="30" name="name-{{subQuestion.questionId}}" #textArea1="ngModel"
                                  [(ngModel)]="qtd[subQuestion.questionId]" [disabled]="reviewInspection" required>
                                </textarea>
                                <div *ngIf="submitted" [hidden]="textArea1.valid" class="alert alert-danger">
                                  * Please answer this question
                                </div>
                              </div>
                              <div class="col-md-2"
                                *ngIf="subQuestion.subquestion && subQuestion.subquestion[0] &&  subQuestion.subquestion[0].questionType && subQuestion.subquestion[0].questionType === 'Custom'">
                                <div *ngFor="let subQuestion of subQuestion.subquestion" style="margin-top:15px;"
                                  [ngClass]="!printBlank ? 'subQuestion': ''">
                                  <div *ngIf="subQuestion.optionType === 'Checkbox'">
                                    <div style="display:inline;margin-right:2px;"
                                      *ngFor="let option of subQuestion.options">
                                      <input type="checkbox" id="enum_answer_{{option.id}}"
                                        [(ngModel)]="qtd[subQuestion.questionId + '*o*'+ option.data]"
                                        name="name-{{option.id}}" [disabled]="reviewInspection"
                                        [checked]="qtd[subQuestion.questionId + '*o*'+ option.data]" ngModel>
                                      &nbsp;&nbsp;
                                      <label for="enum_answer_{{option.id}}"
                                        class="questions">{{option.data}}</label>&nbsp;&nbsp;
                                    </div>
                                  </div>
                                  <div *ngIf="subQuestion.optionType === 'Text'" class="col-md-6">
                                    <input style="width:130px!important" type="text" class="rounded-form-fields"
                                      name="qtd-{{subQuestion.questionId}}" id="qtd{{subQuestion.questionId}}"
                                      [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                                      [disabled]="printBlank || reviewInspection"
                                      [(ngModel)]="qtd[subQuestion.questionId]" />&nbsp;<label class="questions">
                                      {{subQuestion.unit}}</label>&nbsp;
                                    <div
                                      *ngIf="submitted && (!qtd[subQuestion.questionId] || qtd[subQuestion.questionId] === '')"
                                      class="alert alert-danger">
                                      * Please answer this question
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="question.questions && question.questions.optionType && question.questions.optionType === 'Text' || question.questions.optionType === 'Date'"
            style="width: 100%;">
            <div class="rowOuter">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-5 mainHeading">
                    {{question.questions.question | uppercase}}
                  </div>
                  <div class="col-md-5">
                    <input type="text" class="rounded-form-fields" name="qtd-{{question.questions.questionId}}"
                      [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                      id="qtd{{question.questions.questionId}}" [(ngModel)]="qtd[question.questions.questionId]" required
                      [disabled]="qtd[question.questions.questionId+'checkbox'] && question.questions.options  && question.questions.options.length > 0 || reviewInspection"
                      (change)="checkValidity($event.target.value,question.questions.optionType,question.questions.questionId ,question.questions.unit , question.questions.question)" />&nbsp;<label
                      class="questions">
                      {{question.questions.unit}}</label>&nbsp;
                    <input
                      *ngIf="question.questions.options && question.questions.options.length > 0 && !reviewInspection || printBlank"
                      id="checkbox_{{question.questions.questionId}}" type="checkbox"
                      name="qtd-check-{{question.questions.questionId}}" ngModel
                      [(ngModel)]="qtd[question.questions.questionId+'checkbox']"
                      (change)="setValuesOfNA(question.questions.questionId , $event.target);"
                      [disabled]="printBlank">&nbsp;<label
                      *ngIf="question.questions.options  && question.questions.options.length > 0 &&  !reviewInspection || printBlank"
                      class="questions">N/A</label>
                    <div *ngIf="submitted" [hidden]="qtd[question.questions.questionId]" class="alert alert-danger">
                      * Please answer this question
                    </div>
                    <div
                      *ngIf="submitted && !checkValidity(qtd[question.questions.questionId],question.questions.optionType,question.questions.questionId ,question.questions.unit , question.questions.question) && question.questions.optionType !== 'Date'"
                      class="alert alert-danger">
                      * Please enter a valid value
                    </div>
                    <div
                      *ngIf="submitted && !checkValidity(qtd[question.questions.questionId],question.questions.optionType,question.questions.questionId ,question.questions.unit , question.questions.question) && question.questions.optionType === 'Date'"
                      class="alert alert-danger">
                      * Please enter a valid date
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div *ngFor="let subQuestion of question.questions.subquestion" style="margin-top:15px;"
                      [ngClass]="!printBlank ? 'subQuestion': ''">
                      <div *ngIf="subQuestion.optionType === 'Checkbox'">
                        <div style="display:inline;margin-right:2px;" *ngFor="let option of subQuestion.options">
                          <input type="checkbox" id="enum_answer_{{option.id}}"
                            [(ngModel)]="qtd[subQuestion.questionId + '*o*'+ option.data]" name="name-{{option.id}}"
                            [disabled]="reviewInspection" [checked]="qtd[subQuestion.questionId + '*o*'+ option.data]"
                            ngModel>
                          &nbsp;&nbsp;
                          <label for="enum_answer_{{option.id}}" class="questions">{{option.data}}</label>&nbsp;&nbsp;
                        </div>
                        <div style="display:inline;margin-right:2px;">
                          <div
                            *ngIf="displayCondition(subQuestion.questionId , subQuestion.options , subQuestion.questionType) && submitted"
                            class="alert alert-danger">
                            * Please answer this question
                          </div>
                        </div>
                      </div>
                      <div *ngIf="subQuestion.optionType === 'Text'" class="col-md-5">
                        <input style="width:130px!important" type="text" class="rounded-form-fields"
                          name="qtd-{{subQuestion.questionId}}" id="qtd{{subQuestion.questionId}}"
                          [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                          [disabled]="printBlank || reviewInspection"
                          [(ngModel)]="qtd[subQuestion.questionId]" />&nbsp;<label class="questions">
                          {{subQuestion.unit}}</label>&nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="question.questions && question.questions.optionType && question.questions.optionType === 'Checkbox'"
            style="width: 100%;">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-5 mainHeading">
                    {{question.questions.question | uppercase}}
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div *ngFor="let option of question.questions.options">
                        <input type="checkbox" id="enum_answer_{{option.id}}"
                          [(ngModel)]="qtd[question.questions.questionId + '*o*'+ option.data]" name="name-{{option.id}}"
                          [disabled]="reviewInspection"
                          [checked]="qtd[question.questions.questionId + '*o*'+ option.data]" ngModel>
                        &nbsp;&nbsp;
                        <label for="enum_answer_{{option.id}}" class="questions">{{option.data}}</label>&nbsp;&nbsp;
                      </div>
                      <div
                        *ngIf="displayCondition(question.questions.questionId, question.questions.options, question.questions.questionType) && submitted"
                        class="alert alert-danger">
                        * Please answer this question
                      </div>
                      <div *ngIf="question.questions.subquestion && question.questions.subquestion.length > 0">
                        <div *ngFor="let subQuestion of question.questions.subquestion"
                          [ngClass]="!printBlank ? 'subQuestion': ''">
                          <div *ngIf="subQuestion.optionType === 'Text' && subQuestion.questionType === 'Custom'">
                            <input style="width:130px!important" type="text" class="rounded-form-fields"
                              name="qtd-{{subQuestion.questionId}}" id="qtd{{subQuestion.questionId}}"
                              [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                              [disabled]="printBlank || reviewInspection"
                              [(ngModel)]="qtd[subQuestion.questionId]" />&nbsp;<label class="questions">
                              {{subQuestion.unit}}</label>&nbsp;
                            <div *ngIf="submitted" [hidden]="qtd[subQuestion.questionId]" class="alert alert-danger">
                              * Please answer this question
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" *ngIf="question.questions.subquestion && question.questions.subquestion.length > 0">
                <div *ngFor="let subQuestion of question.questions.subquestion"
                  [ngClass]="!printBlank ? 'subQuestion': ''">
                  <div *ngIf="subQuestion.optionType && subQuestion.optionType === 'Radio'" style="width: 100%;">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-5 questionsCustom">
                            {{subQuestion.question}}
                          </div>
                          <div class="col-md-7">
                            <div class="row">
                              <mat-radio-group class="example-radio-group" name="name-{{subQuestion.questionId}}"
                                [(ngModel)]="qtd[subQuestion.questionId]" id="enum_answer_{{subQuestion.questionId}}"
                                [disabled]="printBlank || reviewInspection" required #radio8="ngModel">
                                <mat-radio-button class="example-radio-button" *ngFor="let option of subQuestion.options"
                                  [value]="option.data" [disabled]="printBlank ||reviewInspection">
                                  {{option.data}}&nbsp;
                                </mat-radio-button>
                                <div *ngIf="submitted" [hidden]="radio8.valid" class="alert alert-danger">
                                  * Please answer this question
                                </div>
                              </mat-radio-group>
  
                              <div
                                *ngIf="subQuestion.subquestion && subQuestion.subquestion[0] && subQuestion.subquestion[0].questionType && subQuestion.subquestion[0].questionType === 'Custom'">
                                <div *ngFor="let subQuestion of subQuestion.subquestion"
                                  [ngClass]="!printBlank ? 'subQuestion': ''">
                                  <div *ngIf="subQuestion.optionType === 'Text'">
                                    <input style="width:130px!important" type="text" class="rounded-form-fields"
                                      name="qtd-{{subQuestion.questionId}}" id="qtd{{subQuestion.questionId}}"
                                      [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                                      [disabled]="printBlank || reviewInspection"
                                      [(ngModel)]="qtd[subQuestion.questionId]" />&nbsp;<label class="questions">
                                      {{subQuestion.unit}}</label>&nbsp;
                                    <div *ngIf="submitted" [hidden]="qtd[subQuestion.questionId]"
                                      class="alert alert-danger">
                                      * Please answer this question
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="subQuestion.optionType && subQuestion.optionType === 'Checkbox'" style="width: 100%;">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-5 questionsCustom">
                            {{subQuestion.question}}
                          </div>
                          <div class="col-md-7">
                            <div class="row">
                              <div style="display:inline;margin-right:2px;" *ngFor="let option of subQuestion.options">
                                <input type="checkbox" id="enum_answer_{{option.id}}"
                                  [(ngModel)]="qtd[subQuestion.questionId + '*o*'+ option.data]" name="name-{{option.id}}"
                                  [disabled]="reviewInspection"
                                  [checked]="qtd[subQuestion.questionId + '*o*'+ option.data]" ngModel>
                                &nbsp;&nbsp;
                                <label for="enum_answer_{{option.id}}"
                                  class="questions">{{option.data}}</label>&nbsp;&nbsp;
                              </div>
                              <div style="display:inline;margin-right:2px;">
                                <div
                                  *ngIf="displayCondition(subQuestion.questionId , subQuestion.options , subQuestion.questionType) && submitted"
                                  class="alert alert-danger">
                                  * Please answer this question
                                </div>
                              </div>
                              <div class="col-md-7"
                                *ngIf="subQuestion.subquestion && subQuestion.subquestion[0] && subQuestion.subquestion[0].questionType && subQuestion.subquestion[0].questionType === 'Custom'">
                                <div *ngFor="let subQuestion of subQuestion.subquestion"
                                  [ngClass]="!printBlank ? 'subQuestion': ''">
                                  <div *ngIf="subQuestion.optionType === 'Text'">
                                    <input style="width:130px!important" type="text" class="rounded-form-fields"
                                      name="qtd-{{subQuestion.questionId}}" id="qtd{{subQuestion.questionId}}"
                                      [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                                      [disabled]="printBlank || reviewInspection"
                                      [(ngModel)]="qtd[subQuestion.questionId]" />&nbsp;<label class="questions">
                                      {{subQuestion.unit}}</label>&nbsp;
                                    <div *ngIf="submitted" [hidden]="qtd[subQuestion.questionId]"
                                      class="alert alert-danger">
                                      * Please answer this question
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="subQuestion.optionType && subQuestion.optionType === 'TextArea'" style="width: 100%;">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-5 questionsCustom">
                            {{subQuestion.question}}
                          </div>
                          <div class="col-md-7">
                            <div class="row">
                              <div class="col-md-7">
                                <textarea rows="4" cols="30" name="name-{{subQuestion.questionId}}" #textArea2="ngModel"
                                  [(ngModel)]="qtd[subQuestion.questionId]" [disabled]="reviewInspection" required>
                                </textarea>
                                <div *ngIf="submitted" [hidden]="textArea2.valid" class="alert alert-danger">
                                  * Please answer this question
                                </div>
                              </div>
                              <div class="col-md-2"
                                *ngIf="subQuestion.subquestion && subQuestion.subquestion[0] &&  subQuestion.subquestion[0].questionType && subQuestion.subquestion[0].questionType === 'Custom'">
                                <div *ngFor="let subQuestion of subQuestion.subquestion" style="margin-top:15px;"
                                  [ngClass]="!printBlank ? 'subQuestion': ''">
                                  <div *ngIf="subQuestion.optionType === 'Checkbox'">
                                    <div style="display:inline;margin-right:2px;"
                                      *ngFor="let option of subQuestion.options">
                                      <input type="checkbox" id="enum_answer_{{option.id}}"
                                        [(ngModel)]="qtd[subQuestion.questionId + '*o*'+ option.data]"
                                        name="name-{{option.id}}" [disabled]="reviewInspection"
                                        [checked]="qtd[subQuestion.questionId + '*o*'+ option.data]" ngModel>
                                      &nbsp;&nbsp;
                                      <label for="enum_answer_{{option.id}}"
                                        class="questions">{{option.data}}</label>&nbsp;&nbsp;
                                    </div>
                                  </div>
                                  <div *ngIf="subQuestion.optionType === 'Text'" class="col-md-6">
                                    <input style="width:130px!important" type="text" class="rounded-form-fields"
                                      name="qtd-{{subQuestion.questionId}}" id="qtd{{subQuestion.questionId}}"
                                      [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                                      [disabled]="printBlank || reviewInspection"
                                      [(ngModel)]="qtd[subQuestion.questionId]" />&nbsp;<label class="questions">
                                      {{subQuestion.unit}}</label>&nbsp;
                                    <div *ngIf="submitted" [hidden]="qtd[subQuestion.questionId]"
                                      class="alert alert-danger">
                                      * Please answer this question
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="question.questions && question.questions.optionType && question.questions.optionType === 'TextArea'"
            style="width: 100%;">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-5 mainHeading">
                    {{question.questions.question | uppercase}}
                  </div>
                  <div class="col-md-7" style="margin-left: -15px;">
                    <div class="row">
                      <div class="col-md-7">
                        <textarea rows="4" cols="30" name="name-{{question.questions.questionId}}" #textArea4="ngModel"
                          [(ngModel)]="qtd[question.questions.questionId]" [disabled]="reviewInspection" required>
                        </textarea>
                        <div *ngIf="submitted" [hidden]="textArea4.valid" class="alert alert-danger">
                          * Please answer this question
                        </div>
                      </div>
                      <div class="col-md-2"
                        *ngIf="question.questions.subquestion && question.questions.subquestion.length > 0">
                        <div *ngFor="let subQuestion of question.questions.subquestion" style="margin-top:15px;"
                          [ngClass]="!printBlank ? 'subQuestion': ''">
                          <div *ngIf="subQuestion.optionType === 'Checkbox' && subQuestion.questionType === 'Custom'">
                            <div style="display:inline;margin-right:2px;" *ngFor="let option of subQuestion.options">
                              <input type="checkbox" id="enum_answer_{{option.id}}"
                                [(ngModel)]="qtd[subQuestion.questionId + '*o*'+ option.data]" name="name-{{option.id}}"
                                [disabled]="reviewInspection" [checked]="qtd[subQuestion.questionId + '*o*'+ option.data]"
                                ngModel>
                              &nbsp;&nbsp;
                              <label for="enum_answer_{{option.id}}" class="questions">{{option.data}}</label>&nbsp;&nbsp;
                            </div>
                          </div>
                          <div *ngIf="subQuestion.optionType === 'Text' && subQuestion.questionType === 'Custom'"
                            class="col-md-6">
                            <input style="width:130px!important" type="text" class="rounded-form-fields"
                              name="qtd-{{subQuestion.questionId}}" id="qtd{{subQuestion.questionId}}"
                              [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                              [disabled]="printBlank || reviewInspection"
                              [(ngModel)]="qtd[subQuestion.questionId]" />&nbsp;<label class="questions">
                              {{subQuestion.unit}}</label>&nbsp;
                            <div *ngIf="submitted" [hidden]="qtd[subQuestion.questionId]" class="alert alert-danger">
                              * Please answer this question
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div *ngIf="question.questions.subquestion && question.questions.subquestion.length > 0">
                  <div *ngFor="let subQuestion of question.questions.subquestion"
                    [ngClass]="!printBlank ? 'subQuestion': ''">
                    <div *ngIf="subQuestion.optionType && subQuestion.optionType === 'Radio'" style="width: 100%;">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-5 questionsCustom">
                              {{subQuestion.question}}
                            </div>
                            <div class="col-md-7">
                              <div class="row">
                                <mat-radio-group class="example-radio-group" name="name-{{subQuestion.questionId}}"
                                  [(ngModel)]="qtd[subQuestion.questionId]" id="enum_answer_{{subQuestion.questionId}}"
                                  [disabled]="printBlank || reviewInspection " required #radio9="ngModel">
                                  <mat-radio-button class="example-radio-button"
                                    *ngFor="let option of subQuestion.options" [value]="option.data"
                                    [disabled]="printBlank || reviewInspection">
                                    {{option.data}}&nbsp;
                                  </mat-radio-button>
                                  <div *ngIf="submitted" [hidden]="radio9.valid" class="alert alert-danger">
                                    * Please answer this question
                                  </div>
                                </mat-radio-group>
                                <div
                                  *ngIf="subQuestion.subquestion && subQuestion.subquestion[0] && subQuestion.subquestion[0].questionType && subQuestion.subquestion[0].questionType === 'Custom'">
                                  <div *ngFor="let subQuestion of subQuestion.subquestion"
                                    [ngClass]="!printBlank ? 'subQuestion': ''">
                                    <div *ngIf="subQuestion.optionType === 'Text'">
                                      <input style="width:130px!important" type="text" class="rounded-form-fields"
                                        name="qtd-{{subQuestion.questionId}}" id="qtd{{subQuestion.questionId}}"
                                        [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                                        [disabled]="printBlank || reviewInspection"
                                        [(ngModel)]="qtd[subQuestion.questionId]" />&nbsp;<label class="questions">
                                        {{subQuestion.unit}}</label>&nbsp;
                                      <div
                                        *ngIf="submitted && (!qtd[subQuestion.questionId] || (qtd[subQuestion.questionId] && qtd[subQuestion.questionId] === '' ))"
                                        class="alert alert-danger">
                                        * Please answer this question
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="subQuestion.optionType && subQuestion.optionType === 'Checkbox' && subQuestion.questionType !== 'Custom'"
                      style="width: 100%;">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-5 questionsCustom">
                              {{subQuestion.question}}
                            </div>
                            <div class="col-md-7">
                              <div class="row">
                                <div style="display:inline;margin-right:2px;" *ngFor="let option of subQuestion.options">
                                  <input type="checkbox" id="enum_answer_{{option.id}}"
                                    [(ngModel)]="qtd[subQuestion.questionId + '*o*'+ option.data]"
                                    name="name-{{option.id}}" [disabled]="reviewInspection"
                                    [checked]="qtd[subQuestion.questionId + '*o*'+ option.data]" ngModel>
                                  &nbsp;&nbsp;
                                  <label for="enum_answer_{{option.id}}"
                                    class="questions">{{option.data}}</label>&nbsp;&nbsp;
                                </div>
                                <div style="display:inline;margin-right:2px;">
                                  <div
                                    *ngIf="displayCondition(subQuestion.questionId , subQuestion.options , subQuestion.questionType) && submitted "
                                    class="alert alert-danger">
                                    * Please answer this question
                                  </div>
                                </div>
                                <div
                                  *ngIf="subQuestion.subquestion && subQuestion.subquestion[0] && subQuestion.subquestion[0].questionType && subQuestion.subquestion[0].questionType === 'Custom'">
                                  <div *ngFor="let subQuestion of subQuestion.subquestion"
                                    [ngClass]="!printBlank ? 'subQuestion': ''">
                                    <div *ngIf="subQuestion.optionType === 'Text'">
                                      <input style="width:130px!important" type="text" class="rounded-form-fields"
                                        name="qtd-{{subQuestion.questionId}}" id="qtd{{subQuestion.questionId}}"
                                        [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                                        [disabled]="printBlank || reviewInspection"
                                        [(ngModel)]="qtd[subQuestion.questionId]" />&nbsp;<label class="questions">
                                        {{subQuestion.unit}}</label>&nbsp;
                                      <div *ngIf="submitted" [hidden]="qtd[subQuestion.questionId]"
                                        class="alert alert-danger">
                                        * Please answer this question
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="subQuestion.optionType && subQuestion.optionType === 'TextArea'" style="width: 100%;">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-5 questionsCustom">
                              {{subQuestion.question}}
                            </div>
                            <div class="col-md-7">
                              <div class="row">
                                <div class="col-md-7">
                                  <textarea rows="4" cols="30" name="name-{{subQuestion.questionId}}" #textArea5="ngModel"
                                    [(ngModel)]="qtd[subQuestion.questionId]" [disabled]="reviewInspection" required>
                                  </textarea>
                                  <div *ngIf="submitted" [hidden]="textArea5.valid" class="alert alert-danger">
                                    * Please answer this question
                                  </div>
                                </div>
                                <div class="col-md-2"
                                  *ngIf="subQuestion.subquestion && subQuestion.subquestion[0] &&  subQuestion.subquestion[0].questionType && subQuestion.subquestion[0].questionType === 'Custom'">
                                  <div *ngFor="let subQuestion of subQuestion.subquestion" style="margin-top:15px;"
                                    [ngClass]="!printBlank ? 'subQuestion': ''">
                                    <div *ngIf="subQuestion.optionType === 'Checkbox'">
                                      <div style="display:inline;margin-right:2px;"
                                        *ngFor="let option of subQuestion.options">
                                        <input type="checkbox" id="enum_answer_{{option.id}}"
                                          [(ngModel)]="qtd[subQuestion.questionId + '*o*'+ option.data]"
                                          name="name-{{option.id}}" [disabled]="reviewInspection"
                                          [checked]="qtd[subQuestion.questionId + '*o*'+ option.data]" ngModel>
                                        &nbsp;&nbsp;
                                        <label for="enum_answer_{{option.id}}"
                                          class="questions">{{option.data}}</label>&nbsp;&nbsp;
                                      </div>
                                    </div>
                                    <div *ngIf="subQuestion.optionType === 'Text'" class="col-md-6">
                                      <input style="width:130px!important" type="text" class="rounded-form-fields"
                                        name="qtd-{{subQuestion.questionId}}" id="qtd{{subQuestion.questionId}}"
                                        [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                                        [disabled]="printBlank || reviewInspection"
                                        [(ngModel)]="qtd[subQuestion.questionId]" />&nbsp;<label class="questions">
                                        {{subQuestion.unit}}</label>&nbsp;
                                      <div *ngIf="submitted" [hidden]="qtd[subQuestion.questionId]"
                                        class="alert alert-danger">
                                        * Please answer this question
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Complexity - MILD, Reduce complexity -->
        <div *ngIf="subQuestionsCondition(question)">
          <div *ngFor="let subQuestion of question.questions.subquestion" style="margin-top:15px;"
            [ngClass]="!printBlank ? 'subQuestion': ''">
            <div *ngIf="subQuestion.mainHeading !== 'Heading'">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-5 questions">
                    {{subQuestion.question}}
                  </div>
                  <div *ngIf="subQuestion.optionType === 'Radio'" class="col-md-7">
                    <mat-radio-group class="example-radio-group" name="name-{{subQuestion.questionId}}"
                      [(ngModel)]="qtd[subQuestion.questionId]" id="enum_answer_{{subQuestion.questionId}}"
                      [disabled]="printBlank || reviewInspection" required #radio10="ngModel">
                      <mat-radio-button class="example-radio-button" *ngFor="let option of subQuestion.options"
                        [value]="option.data" [disabled]="printBlank || reviewInspection">
                        {{option.data}}&nbsp;
                      </mat-radio-button>
                    </mat-radio-group>
                    <div *ngIf="submitted" [hidden]="radio10.valid" class="alert alert-danger">
                      * Please answer this question
                    </div>
                  </div>
                  <div *ngIf="subQuestion.optionType === 'Checkbox'" class="col-md-7">
                    <div style="display:inline;margin-right:2px;" *ngFor="let option of subQuestion.options">
                      <input type="checkbox" id="enum_answer_{{option.id}}"
                        [(ngModel)]="qtd[subQuestion.questionId + '*o*'+ option.data]" name="name-{{option.id}}"
                        [disabled]="reviewInspection" required
                        [checked]="qtd[subQuestion.questionId + '*o*'+ option.data]" ngModel>
                      &nbsp;&nbsp;
                      <label for="enum_answer_{{option.id}}" class="questions">{{option.data}}</label>&nbsp;&nbsp;
                    </div>
                    <div style="display:inline;margin-right:2px;">
                      <div
                        *ngIf="displayCondition(subQuestion.questionId , subQuestion.options , subQuestion.questionType) && submitted"
                        class="alert alert-danger">
                        * Please answer this question
                      </div>
                    </div>
                  </div>
                  <div *ngIf="subQuestion.optionType === 'Text'" class="col-md-7">
                    <input style="width:130px!important" type="text" class="rounded-form-fields"
                      name="qtd-{{subQuestion.questionId}}" id="qtd{{subQuestion.questionId}}"
                      [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                      [disabled]="printBlank || reviewInspection" [(ngModel)]="qtd[subQuestion.questionId]" />&nbsp;<label
                      class="questions">
                      {{subQuestion.unit}}</label>&nbsp;
                    <div *ngIf="submitted" [hidden]="qtd[subQuestion.questionId]" class="alert alert-danger">
                      * Please answer this question
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Highly Dangerous Condition - Caution before touching -->
        <div *ngIf="question && question.questions && question.questions.question !== 'Others'">
          <div *ngIf="question.questions.mainHeading !== 'Heading' &&
          (!question.questions.questionType ||
          ( question.questions.questionType &&
          question.questions.questionType !== 'Delete')) &&
          question.questions.questionType!== 'Additional' &&
          (question.questions.questionType &&
          question.questions.questionType !== 'CAQAW' &&
          question.questions.questionType !== 'CAQDW' &&
          question.questions.questionType !== 'CAQST') &&
          (question.questions.question &&
          class1Questions.indexOf(question.questions.question) < 0 &&
          class2Questions.indexOf(question.questions.question) < 0 &&
          class3Questions.indexOf(question.questions.question)< 0) &&
          question.questions.optionType != 'Table' &&
          question.questions.questionType != 'SPFI' &&
          (assetTypeid != 13 || question.questions.questionType != 'SPBS') &&
          question.questions.questionType != 'PRV' &&
          question.questions.questionType != 'ClassII' &&
          question.questions.question !==  'Standpipe Class:' ||
          (class1Questions.indexOf(question.questions.question) >= 0 &&
          qtd[findQuestionId('Standpipe Class:')] === 'class1' &&
          question.questions.questionId == findQuestionId('CLASS I')) ||
          (class2Questions.indexOf(question.questions.question) >= 0 &&
          qtd[findQuestionId('Standpipe Class:')] === 'class2' &&
          question.questions.dependencyValue == findQuestionId('CLASS II')) ||
          (class3Questions.indexOf(question.questions.question) >= 0 &&
          qtd[findQuestionId('Standpipe Class:')] === 'class3' &&
          question.questions.dependencyValue == findQuestionId('CLASS III'))" style="width: 100%;">
            <div class="rowOuter">
              <div class="col-md-12">
                <div class="row" *ngIf="question.questions.question !== 'Weight within 1/2oz of stamped weight:' ||
                (question.questions.question === 'Weight within 1/2oz of stamped weight:' &&
                sequence && sequence.indexOf(question.questions.sequence) >= 0) ||
                (inspectionDataforSecondPage && inspectionDataforSecondPage['sequence'] &&
                inspectionDataforSecondPage['sequence'].indexOf(question.questions.sequence)>=0) ||
                printBlank">
  
                  <div
                    *ngIf="!(question.questions.answer == null && reviewInspection === true && configureReport === false)"
                    [ngClass]="[question.questions.questionType=='DASC' ? 'col-md-1':'col-md-5' , question.questions.questionType=='Info' && question.questions.question.indexOf('Close hydrant slowly') >= 0 ? 'col-md-12 hydrantQuestion': question.questions.questionType=='DASC' ? 'col-md-1': 'col-md-5']"
                    class="questions">
                    {{question.questions.question}}
                  </div>
                  <!-- TEGFE-3422 -->
                  <div
                    *ngIf="question.questions.answer == null && question.questions.questionType=='DASC' && question.questions.optionType=='Checkbox' && reviewInspection === true"
                    class="questions col-md-1">{{question.questions.question}}</div>
                  <div [ngClass]="question.questions.questionType=='DASC' ? 'col-md-10':'col-md-7'">
                    <div style="display: inline-block;"
                      *ngIf="(question.questions.question === 'Number of branch lines examined:' || question.questions.question === 'Number of cross mains examined:' || question.questions.question === 'Number of branch lines examined:')">
                      <input style="width:130px!important" type="text" class="rounded-form-fields"
                        name="qtd-{{question.questions.questionId}}" id="qtd{{question.questions.questionId}}"
                        [(ngModel)]="qtd[question.questions.questionId]" required [disabled]="reviewInspection"
                        (change)="checkValidity($event.target.value,question.questions.optionType,question.questions.questionId , question.questions.unit , question.questions.question)"
                        #myInput="ngModel" />&nbsp;<label class="questions">
                        {{question.questions.unit}}</label>&nbsp;
                      <div *ngFor="let subQuestion of question.questions.subquestion" style="display: inline-block;"
                        [ngClass]="!printBlank ? 'subQuestion': ''">
                        <div style="display: inline-block;"
                          *ngIf="subQuestion.optionType === 'Text' || subQuestion.optionType === 'Date'">
                          <input style="width:130px!important" type="text" class="rounded-form-fields"
                            name="qtd-{{subQuestion.questionId}}" id="qtd{{subQuestion.questionId}}"
                            [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                            [(ngModel)]="qtd[subQuestion.questionId]" [disabled]="reviewInspection"
                            (change)="checkValidity($event.target.value,subQuestion.optionType,subQuestion.questionId,subQuestion.unit , subQuestion.question)" />&nbsp;<label
                            class="questions">
                            {{subQuestion.question}}</label>&nbsp;
                        </div>
                      </div>
                      <div
                        *ngIf="submitted && ((!qtd[question.questions.questionId] ||  qtd[question.questions.questionId] === '') || (!qtd[question.questions.subquestion[0].questionId] ||  qtd[question.questions.subquestion[0].questionId] === ''))"
                        class="alert alert-danger">
                        * Please answer this question
                      </div>
                      <div
                        *ngIf="submitted && (!checkValidity(qtd[question.questions.subquestion[0].questionId],question.questions.subquestion[0].optionType,question.questions.subquestion[0].questionId ,question.questions.subquestion[0].unit , question.questions.subquestion[0].question) || !checkValidity(qtd[question.questions.questionId],question.questions.optionType,question.questions.questionId ,question.questions.unit , question.questions.question))"
                        class="alert alert-danger">
                        * Please enter a valid value
                      </div>
                    </div>
  
                    <div style="display: inline-block;"
                      *ngIf="question.questions.question === 'Pressure Gauges Replaced - Quantity'">
                      <div *ngFor="let subQuestion of question.questions.subquestion" style="display: inline-block;"
                        [ngClass]="!printBlank ? 'subQuestion': ''">
                        <div style="display: inline-block;"
                          *ngIf="subQuestion.optionType === 'Text' || subQuestion.optionType === 'Date'">
                          &nbsp;<label class="questions">
                            {{subQuestion.question}}</label>&nbsp;
                          <input style="width:130px!important" type="text"
                            [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                            name="qtd-{{subQuestion.questionId}}" id="qtd{{subQuestion.questionId}}"
                            [(ngModel)]="qtd[subQuestion.questionId]" [disabled]="reviewInspection"
                            (change)="checkValidity($event.target.value,subQuestion.optionType,subQuestion.questionId,subQuestion.unit , subQuestion.question)" />&nbsp;
                          <div *ngIf="submitted" [hidden]="qtd[subQuestion.questionId]" class="alert alert-danger">
                            * Please answer this question
                          </div>
                          <div
                            *ngIf="submitted && !checkValidity(qtd[subQuestion.questionId],subQuestion.optionType,subQuestion.questionId ,subQuestion.unit , subQuestion.question) && subQuestion.optionType !== 'Date'"
                            class="alert alert-danger">
                            * Please enter a valid value
                          </div>
                          <div
                            *ngIf="submitted && !checkValidity(qtd[subQuestion.questionId],subQuestion.optionType,subQuestion.questionId ,subQuestion.unit , subQuestion.question) && subQuestion.optionType === 'Date'"
                            class="alert alert-danger">
                            * Please enter a valid date
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="question.questions.question === 'FCC Registration:'">
                      <mat-radio-group class="example-radio-group" name="name-{{question.questions.questionId}}"
                        [(ngModel)]="qtd[question.questions.questionId]"
                        id="enum_answer_{{question.questions.questionId}}" [disabled]="printBlank || reviewInspection"
                        required #radi077="ngModel">
                        <mat-radio-button [value]="option.data" class="example-radio-button"
                          *ngFor="let option of question.questions.options; let i = index"
                          [disabled]="printBlank ||reviewInspection">
                          &nbsp;
                          {{option.data}}&nbsp;&nbsp;
                          <div *ngIf="option.data.toLowerCase().indexOf('yes') > -1" style="display: inline-block;">
                            <div *ngFor="let subQuestion of question.questions.subquestion" style="display: inline-block;"
                              [ngClass]="!printBlank ? 'subQuestion': ''">
                              <div style="display: inline-block;"
                                *ngIf="subQuestion.optionType === 'Text' || subQuestion.optionType === 'Date'">
                                &nbsp;<label class="questions">
                                  {{subQuestion.question}}</label>&nbsp;
                                <input style="width:130px!important" type="text"
                                  [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                                  name="qtd-{{subQuestion.questionId}}" id="qtd{{subQuestion.questionId}}"
                                  [(ngModel)]="qtd[subQuestion.questionId]" [disabled]="reviewInspection"
                                  (change)="checkValidity($event.target.value,subQuestion.optionType,subQuestion.questionId,subQuestion.unit , subQuestion.question)" />&nbsp;
                                <div
                                  *ngIf="submitted && !checkValidity(qtd[subQuestion.questionId],subQuestion.optionType,subQuestion.questionId ,subQuestion.unit , subQuestion.question) && subQuestion.optionType !== 'Date'"
                                  class="alert alert-danger">
                                  * Please enter a valid value
                                </div>
                                <div
                                  *ngIf="submitted && !checkValidity(qtd[subQuestion.questionId],subQuestion.optionType,subQuestion.questionId ,subQuestion.unit , subQuestion.question) && subQuestion.optionType === 'Date'"
                                  class="alert alert-danger">
                                  * Please enter a valid date
                                </div>
                              </div>
                            </div>
                          </div><span *ngIf="option.data.toLowerCase().indexOf('yes') > -1"
                            style="color:black;">)</span>&nbsp;&nbsp;
                        </mat-radio-button>
                      </mat-radio-group>
                      <div *ngIf="submitted" [hidden]="radi077.valid" class="alert alert-danger">
                        * Please answer this question
                      </div>
                    </div>
                    <div *ngIf="question.questions.question === 'Downstream Process:'">
                      <div
                        *ngIf="question.questions && question.questions.question === 'Downstream Process:' && question.questions.optionType && question.questions.optionType === 'Radio'">
                        <mat-radio-group class="example-radio-group" name="name-{{question.questions.questionId}}"
                          [ngModel]="getDownstreamValue()" id="enum_answer_{{question.questions.questionId}}"
                          [disabled]="printBlank || reviewInspection" required #radio14="ngModel">
                          <mat-radio-button class="example-radio-button" *ngFor="let downstreamType of downstreamTypes"
                            [value]="downstreamType" (change)="changeofDownStream($event)"
                            [disabled]="printBlank || reviewInspection">
                            {{downstreamType}}&nbsp;
                          </mat-radio-button>
                        </mat-radio-group>
                        <input *ngIf="downstreamOthersValue" type="text" id="anserText_1223" style="width:120px;"
                          name="anserText_1223" [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                          (change)=otherValues($event) required [(ngModel)]="qtd[findQuestionId('Others')]"
                          [disabled]="reviewInspection" />
                        <div
                          *ngIf="submitted && getDownstreamValue() ==='OTHER' && (this.qtd[this.findQuestionId('Others')] == null || this.qtd[this.findQuestionId('Others')] === '')"
                          class="alert alert-danger">
                          * Enter valid Downstream Process
                        </div>
                        <div
                          *ngIf="submitted && (qtd[question.questions.questionId] === null || qtd[question.questions.questionId] === '')"
                          [hidden]="radio14.valid" class="alert alert-danger">
                          * Please answer this question
                        </div>
                      </div>
                      &nbsp;&nbsp;
                    </div>
  
                    <div *ngIf="question.questions.question === 'Install:'">
                      <div
                        *ngIf="question.questions && question.questions.question === 'Install:' && question.questions.optionType && question.questions.optionType === 'Radio'">
                        <mat-radio-group class="example-radio-group" name="name-{{question.questions.questionId}}"
                          [(ngModel)]="qtd[question.questions.questionId]"
                          id="enum_answer_{{question.questions.questionId}}" [disabled]="printBlank || reviewInspection"
                          required #radio13="ngModel">
                          <mat-radio-button class="example-radio-button" *ngFor="let option of question.questions.options"
                            [value]="option.data" (change)="installCheckBox($event)"
                            [disabled]="printBlank || reviewInspection">
                            {{option.data}}&nbsp;
                          </mat-radio-button>
                        </mat-radio-group>
                        <div
                          *ngIf="submitted && (qtd[question.questions.questionId] === null || qtd[question.questions.questionId] === '')"
                          [hidden]="radio13.valid" class="alert alert-danger">
                          * Please answer this question
                        </div>
                      </div>
                      <!-- change -->
                      <div style="display: inline-block;">
                        <div *ngFor="let subQuestion of question.questions.subquestion" style="display: inline-block;"
                          [ngClass]="!printBlank ? 'subQuestion': ''">
                          <div style="display: inline-block;"
                            *ngIf="(subQuestion.optionType === 'Text' || subQuestion.optionType === 'Date') && checkvalueforSerNum(qtd,subQuestion)">
                            &nbsp;<label class="questions">
                              {{subQuestion.question}}</label>&nbsp;
                            <input style="width:130px!important" type="text"
                              [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                              name="qtd-{{subQuestion.questionId}}" id="qtd{{subQuestion.questionId}}"
                              [(ngModel)]="qtd[subQuestion.questionId]" [disabled]="reviewInspection"
                              (change)="checkValidity($event.target.value,subQuestion.optionType,subQuestion.questionId,subQuestion.unit , subQuestion.question)" />&nbsp;
                            <div
                              *ngIf="submitted && !checkValidity(qtd[subQuestion.questionId],subQuestion.optionType,subQuestion.questionId ,subQuestion.unit , subQuestion.question) && subQuestion.optionType !== 'Date'"
                              class="alert alert-danger">
                              * Please enter a valid value
                            </div>
                            <div
                              *ngIf="submitted && !checkValidity(qtd[subQuestion.questionId],subQuestion.optionType,subQuestion.questionId ,subQuestion.unit , subQuestion.question) && subQuestion.optionType === 'Date'"
                              class="alert alert-danger">
                              * Please enter a valid date
                            </div>
                          </div>
                        </div>
                      </div>&nbsp;&nbsp;
                    </div>
                    <div *ngIf="question.questions.question === 'FACP Notification (check all that apply)'">
                      <div style="display:inline;margin-right:2px;" *ngFor="let option of question.questions.options">
                        <input *ngIf="question.questions.questionType !='DASC'" type="checkbox"
                          id="enum_answer_{{option.id}}"
                          [(ngModel)]="qtd[question.questions.questionId + '*o*'+ option.data]" name="name-{{option.id}}"
                          [disabled]="reviewInspection"
                          [checked]="qtd[question.questions.questionId + '*o*'+ option.data]"
                          (change)="installCheckBox($event)" ngModel>
                        <input *ngIf="question.questions.questionType =='DASC'" type="checkbox"
                          id="enum_answer_{{option.id}}" [disabled]="reviewInspection"
                          [(ngModel)]="qtd[question.questions.questionId + '*o*'+ option.data]" name="name-{{option.id}}"
                          [checked]="qtd[question.questions.questionId + '*o*'+ option.data]"
                          (change)="installCheckBox($event)" ngModel>
                        &nbsp;&nbsp;
                        <label for="enum_answer_{{option.id}}" class="questions">{{option.data}}</label>&nbsp;&nbsp;
                      </div>
                      <!-- change -->
                      <div style="display: inline-block;">
                        <div *ngFor="let subQuestion of question.questions.subquestion" style="display: inline-block;"
                          [ngClass]="!printBlank ? 'subQuestion': ''">
                          <div style="display: inline-block;"
                            *ngIf="(subQuestion.optionType === 'Text' || subQuestion.optionType === 'Date') && checkvalueforSerNum(qtd,subQuestion)">
                            &nbsp;<label class="questions">
                              {{subQuestion.question}}</label>&nbsp;
                            <input style="width:130px!important" type="text"
                              [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                              name="qtd-{{subQuestion.questionId}}" id="qtd{{subQuestion.questionId}}"
                              [(ngModel)]="qtd[subQuestion.questionId]" [disabled]=" reviewInspection"
                              (change)="checkValidity($event.target.value,subQuestion.optionType,subQuestion.questionId,subQuestion.unit , subQuestion.question)" />&nbsp;
                            <div
                              *ngIf="submitted && !checkValidity(qtd[subQuestion.questionId],subQuestion.optionType,subQuestion.questionId ,subQuestion.unit , subQuestion.question) && subQuestion.optionType !== 'Date'"
                              class="alert alert-danger">
                              * Please enter a valid value
                            </div>
                            <div
                              *ngIf="submitted && !checkValidity(qtd[subQuestion.questionId],subQuestion.optionType,subQuestion.questionId ,subQuestion.unit , subQuestion.question) && subQuestion.optionType === 'Date'"
                              class="alert alert-danger">
                              * Please enter a valid date
                            </div>
                          </div>
                        </div>
                      </div>&nbsp;&nbsp;
                      <div style="display:inline;margin-right:2px;">
                        <div
                          *ngIf="displayCondition(question.questions.questionId , question.questions.options , question.questions.questionType) && submitted"
                          class="alert alert-danger">
                          * Please answer this question
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="question.questions && question.questions.question !== 'Pressure Gauges Replaced - Quantity' && question.questions.question !== 'Number of branch lines examined:' && question.questions.question !== 'Number of cross mains examined:'&& question.questions.optionType && question.questions.optionType === 'Text'  && question.questions.question !== 'Others' || question.questions.optionType === 'Date'">
                      <input type="text" class="rounded-form-fields" name="qtd-{{question.questions.questionId}}"
                        [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                        id="qtd{{question.questions.questionId}}" [(ngModel)]="qtd[question.questions.questionId]"
                        required
                        *ngIf="!reviewInspection || question.questions.question !== 'Date of last smoke sensitivity test?'"
                        [disabled]="qtd[question.questions.questionId+'checkbox'] &&question.questions.options && question.questions.options.length > 0  || reviewInspection"
                        (change)="checkValidity($event.target.value,question.questions.optionType,question.questions.questionId , question.questions.unit , question.questions.question)"
                        #input2="ngModel" />&nbsp;<label class="questions" *ngIf=" !reviewInspection || question.questions.question !== 'Date of last smoke sensitivity test?'">
                        {{question.questions.unit}}</label>&nbsp;
                      <input type="text" class="rounded-form-fields" name="qtd-{{question.questions.questionId}}"
                        [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                        id="qtd{{question.questions.questionId}}" [(ngModel)]="qtd[question.questions.questionId]"
                        required
                        *ngIf= " reviewInspection && question.questions.answer && question.questions.question === 'Date of last smoke sensitivity test?'" style="margin-left: -14px;"
                        [disabled]="qtd[question.questions.questionId+'checkbox'] &&question.questions.options && question.questions.options.length > 0  || reviewInspection"
                        (change)="checkValidity($event.target.value,question.questions.optionType,question.questions.questionId , question.questions.unit , question.questions.question)"
                        #input2="ngModel" /><label  style ="margin-right: 9px;"  class="questions" *ngIf= " reviewInspection && question.questions.answer && question.questions.question === 'Date of last smoke sensitivity test?'">
                        {{question.questions.unit}}</label>
                      <input type="text" class="rounded-form-fields" name="qtd-{{question.questions.questionId}}" style="margin-left: -14px;"
                        [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                        id="qtd{{question.questions.questionId}}" [(ngModel)]="qtd[question.questions.questionId]"
                        required
                        *ngIf= " configureReport && question.questions.question === 'Date of last smoke sensitivity test?'"
                        [disabled]="qtd[question.questions.questionId+'checkbox'] &&question.questions.options && question.questions.options.length > 0  || reviewInspection"
                        (change)="checkValidity($event.target.value,question.questions.optionType,question.questions.questionId , question.questions.unit , question.questions.question)"
                        #input2="ngModel" /><label class="questions" style ="margin-right: 9px;" *ngIf= " configureReport && question.questions.question === 'Date of last smoke sensitivity test?'">
                        {{question.questions.unit}}</label>
                      <input
                        *ngIf="question.questions.options && question.questions.options.length > 0 &&  !reviewInspection ||printBlank"
                        id="checkbox_{{question.questions.questionId}}" type="checkbox"
                        name="qtd-check-{{question.questions.questionId}}" ngModel
                        [(ngModel)]="qtd[question.questions.questionId+'checkbox']"
                        (change)="setValuesOfNA(question.questions.questionId , $event.target , question);"
                        [disabled]="printBlank">&nbsp;<label
                        *ngIf="question.questions.options && question.questions.options.length > 0 && !reviewInspection || printBlank"
                        class="questions">N/A</label>
                      <div *ngIf="submitted" [hidden]="qtd[question.questions.questionId]" class="alert alert-danger">
                        * Please answer this question
                      </div>
                      <div
                        *ngIf="submitted && !checkValidity(qtd[question.questions.questionId],question.questions.optionType,question.questions.questionId ,question.questions.unit , question.questions.question) && question.questions.optionType !== 'Date'"
                        class="alert alert-danger">
                        * Please enter a valid value
                      </div>
                      <div
                        *ngIf="submitted && !checkValidity(qtd[question.questions.questionId],question.questions.optionType,question.questions.questionId ,question.questions.unit , question.questions.question) && question.questions.optionType === 'Date' && question.questions.question.indexOf('Last cleaning date (per NFPA standards):') === -1"
                        class="alert alert-danger">
                        * Please enter a valid date
                      </div>
                      <div *ngIf="submitted &&
                      !checkValidity(qtd[question.questions.questionId],question.questions.optionType,question.questions.questionId ,question.questions.unit , question.questions.question) &&
                      question.questions.optionType === 'Date' &&
                      question.questions.question.indexOf('Last cleaning date (per NFPA standards):') > -1
                      " class="alert alert-danger">
                        <span *ngIf="!validatePastDate(qtd[question.questions.questionId]) &&
                      isValidDate(qtd[question.questions.questionId])">* Future Date is not allowed</span>
                        <span *ngIf="!isValidDate(qtd[question.questions.questionId])">* Please enter a valid date</span>
                      </div>
                    </div>
                    <div *ngIf="(question.questions && question.questions.question !== 'Others')">
                      <div
                        *ngIf="!(question.questions && question.questions.answer == null && reviewInspection === true && configureReport === false) && (question.questions && question.questions.question !== 'FCC Registration:' && question.questions && question.questions.question !== 'Install:'  && question.questions.question !== 'Downstream Process:' && question.questions.optionType && question.questions.optionType === 'Radio')">
                        <mat-radio-group class="example-radio-group" name="name-{{question.questions.questionId}}"
                          [(ngModel)]="qtd[question.questions.questionId]"
                          id="enum_answer_{{question.questions.questionId}}" [disabled]="printBlank || reviewInspection"
                          required #radio11="ngModel">
                          <mat-radio-button class="example-radio-button" *ngFor="let option of question.questions.options"
                            [value]="option.data" [disabled]="printBlank || reviewInspection">
                            {{option.data}}&nbsp;
                          </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="submitted" [hidden]="radio11.valid" class="alert alert-danger">
                          * Please answer this question
                        </div>
                      </div>
                    </div>
                    <div *ngIf="(question.questions && question.questions.question !== 'Others')">
                      <div
                        *ngIf="question.questions && question.questions.question !== 'FACP Notification (check all that apply)' && question.questions.question !== 'Install:' && question.questions.question !== 'Downstream Process:' && question.questions.optionType && question.questions.optionType === 'Checkbox'">
                        <div style="display:inline;margin-right:2px;" *ngFor="let option of question.questions.options">
                          <input *ngIf="question.questions.questionType !='DASC'" type="checkbox"
                            id="enum_answer_{{option.id}}"
                            [(ngModel)]="qtd[question.questions.questionId + '*o*'+ option.data]"
                            name="name-{{option.id}}" [disabled]="reviewInspection"
                            [checked]="qtd[question.questions.questionId + '*o*'+ option.data]" ngModel>
                          <input *ngIf="question.questions.questionType =='DASC'" type="checkbox"
                            id="enum_answer_{{option.id}}" [disabled]="reviewInspection"
                            [(ngModel)]="qtd[question.questions.questionId + '*o*'+ option.data]"
                            name="name-{{option.id}}" [checked]="qtd[question.questions.questionId + '*o*'+ option.data]"
                            ngModel>
                          &nbsp;&nbsp;
                          <label for="enum_answer_{{option.id}}" class="questions">{{option.data}}</label>&nbsp;&nbsp;
                        </div>
                        <div style="display:inline;margin-right:2px;">
                          <div
                            *ngIf="displayCondition(question.questions.questionId, question.questions.options , question.questions.questionType) && submitted"
                            class="alert alert-danger">
                            * Please answer this question
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="question.questions && question.questions.optionType && question.questions.optionType === 'TextArea'"
                      style="width: 100%;">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-7">
                              <textarea rows="4" cols="30" name="name-{{question.questions.questionId}}" ngModel
                                [(ngModel)]="qtd[question.questions.questionId]" [disabled]="reviewInspection" required
                                #textArea1=ngModel>
                              </textarea>
                              <div *ngIf="submitted" [hidden]="textArea1.valid" class="alert alert-danger">
                                * Please answer this question
                              </div>
                            </div>
                            <div class="col-md-2"
                              *ngIf="question.questions.subquestion && question.questions.subquestion[0] && question.questions.subquestion[0].questionType && question.questions.subquestion[0].questionType === 'Custom'">
                              <div *ngFor="let subQuestion of question.questions.subquestion" style="margin-top:15px;"
                                [ngClass]="!printBlank ? 'subQuestion': ''">
                                <div *ngIf="subQuestion.optionType === 'Checkbox'">
                                  <div style="display:inline;margin-right:2px;"
                                    *ngFor="let option of subQuestion.options">
                                    <input type="checkbox" id="enum_answer_{{option.id}}"
                                      [(ngModel)]="qtd[subQuestion.questionId + '*o*'+ option.data]"
                                      name="name-{{option.id}}" [disabled]="reviewInspection" required
                                      [checked]="qtd[subQuestion.questionId + '*o*'+ option.data]" #textAreaInner=ngModel>
                                    &nbsp;&nbsp;
                                    <label for="enum_answer_{{option.id}}"
                                      class="questions">{{option.data}}</label>&nbsp;&nbsp;
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="(question.questions && question.questions.question !== 'Others')">
                      <div
                        *ngIf="question.questions.question !== 'FACP Notification (check all that apply)' && question.questions.question !== 'Install:' && question.questions.question !== 'Others' &&  question.questions.question !== 'Downstream Process:' && question.questions.question !== 'FCC Registration:' &&  question.questions.subquestion && question.questions.hasDependents && !question.questions.dependencyValue">
                        <div *ngFor="let subQuestion of question.questions.subquestion" style="margin-top:15px;"
                          [ngClass]="!printBlank ? 'subQuestion': ''">
                          <div *ngIf="subQuestion.mainHeading !== 'Heading'">
                            <div class="col-md-12">
                              <div class="row">
                                <div class="col-md-2 questions">
                                  {{subQuestion.question}}
                                </div>
                                <div class="col-md-5">
                                  <div *ngIf="(subQuestion.optionType === 'Text' || subQuestion.optionType === 'Date')">
                                    <input type="text" class="rounded-form-fields" name="qtd-{{subQuestion.questionId}}"
                                      [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                                      id="qtd{{subQuestion.questionId}}" [(ngModel)]="qtd[subQuestion.questionId]"
                                      [disabled]="qtd[subQuestion.questionId+'checkbox'] && subQuestion.options && subQuestion.options.length > 0 || reviewInspection"
                                      (change)="checkValidity($event.target.value,subQuestion.optionType,subQuestion.questionId,subQuestion.unit , subQuestion.question)" />&nbsp;<label
                                      class="questions">
                                      {{subQuestion.unit}}</label>&nbsp;
                                    <input
                                      *ngIf="subQuestion.options && subQuestion.options.length >0 && !reviewInspection || printBlank"
                                      id="checkbox_{{subQuestion.questionId}}" type="checkbox"
                                      name="qtd-check-{{subQuestion.questionId}}" ngModel
                                      (change)="setValuesOfNA(subQuestion.questionId , $event.target);"
                                      [(ngModel)]="qtd[subQuestion.questionId+'checkbox']"
                                      [disabled]="printBlank">&nbsp;<label
                                      *ngIf="subQuestion.options && subQuestion.options.length > 0 && !reviewInspection || printBlank"
                                      class="questions">N/A</label>
                                    <div *ngIf="submitted" [hidden]="qtd[subQuestion.questionId]"
                                      class="alert alert-danger">
                                      * Please answer this question
                                    </div>
                                    <div
                                      *ngIf="submitted && !checkValidity(qtd[subQuestion.questionId],subQuestion.optionType,subQuestion.questionId ,subQuestion.unit , subQuestion.question) && subQuestion.optionType !== 'Date'"
                                      class="alert alert-danger">
                                      * Please enter a valid value
                                    </div>
                                    <div
                                      *ngIf="submitted && !checkValidity(qtd[subQuestion.questionId],subQuestion.optionType,subQuestion.questionId ,subQuestion.unit , subQuestion.question) && subQuestion.optionType === 'Date'"
                                      class="alert alert-danger">
                                      * Please enter a valid date
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-12"
                    *ngIf="(isImageUploadEnabled || reviewInspection) && question.questions.isImageUploadEnabled && (!question.questions.countOfImages || (question.questions.countOfImages && question.questions.countOfImages === 1))">
                    <input type="file" accept="image/gif,image/jpeg"
                      *ngIf="!images[question.questions.questionId] || (images[question.questions.questionId] && images[question.questions.questionId].length === 0)"
                      (change)="uploadFile(question.questions.questionId, $event.target,$event.target.files);"
                      id="img-{{question.questions.questionId}}" style="display:none;"
                      name="img-{{question.questions.questionId}}" ngModel
                      [required]="qtd[question.questions.questionId] === 'Yes' ? true : null">
                    <input type="file" accept="image/gif,image/jpeg"
                      *ngIf="images[question.questions.questionId] && images[question.questions.questionId].length > 0"
                      (change)="uploadFile(question.questions.questionId, $event.target,$event.target.files, null, question.questions.countOfImages);"
                      id="img-{{question.questions.questionId}}" name="img-{{question.questions.questionId}}"
                      style="display:none;">
  
                    <label for="img-{{question.questions.questionId}}" class="custom-button-label choose-button"
                      *ngIf="!reviewInspection">
                      Upload Image</label>&nbsp;
                    <div *ngFor="let image of images[question.questions.questionId]; let j = index" [attr.data-index]="j"
                      style="display: inline-flex;">
                      <img alt="tegrisfire"
                        class="font-weight-bold contacts-content-font breakWord text-fields certificate"
                        style="width:100px;height:90px!important; cursor: pointer;" src="{{image['imageContent']}}"
                        (click)="downloadImage(image['imageContent'])" />
                      <em *ngIf="!reviewInspection" class="material-icons"
                        (click)="removeImage(question.questions.questionId, j)">close</em>
                    </div>
  
                    <div *ngFor="let error of uploadError[question.questions.questionId]; let k = index"
                      [attr.data-index]="k" style="display: inline-flex;">
                      <label>{{error}}</label>
                    </div>
                    <div *ngIf="submitted && qtd[question.questions.questionId] === 'Yes'"
                      [hidden]="images[question.questions.questionId] && images[question.questions.questionId].length > 0"
                      class="alert alert-danger">
                      * Please upload the image
                    </div>
                  </div>
  
                  <div class="col-md-12"
                    *ngIf="isImageUploadEnabled && question.questions.isImageUploadEnabled && question.questions.countOfImages && question.questions.countOfImages > 1">
                    <input type="file" accept="image/gif,image/jpeg"
                      *ngIf="!images[question.questions.questionId] || (images[question.questions.questionId] && (images[question.questions.questionId].length === 0 || inspectionDataforSecondPage['noFrontImage']))"
                      (change)="uploadFile(question.questions.questionId, $event.target,$event.target.files, 'Front');"
                      id="img-{{question.questions.questionId}}-1" style="display:none;"
                      name="img-{{question.questions.questionId}}" required ngModel>
                    <input type="file" accept="image/gif,image/jpeg"
                      *ngIf="images[question.questions.questionId] && images[question.questions.questionId].length > 0 && !inspectionDataforSecondPage['noFrontImage']"
                      (change)="uploadFile(question.questions.questionId, $event.target,$event.target.files,'Front');"
                      id="img-{{question.questions.questionId}}-1" style="display:none;">
  
                    <label for="img-{{question.questions.questionId}}-1" class="custom-button-label choose-button"
                      *ngIf="!reviewInspection">Upload Front Picture</label>&nbsp;
  
                    <div *ngFor="let image of images[question.questions.questionId]; let j = index" [attr.data-index]="j"
                      style="display: inline-flex;">
                      <img alt="tegrisfire" *ngIf="image['imagePlacement'] === 'Front'"
                        class="font-weight-bold contacts-content-font breakWord text-fields certificate"
                        style="width:100px;height:90px!important;cursor: pointer;" src="{{image['imageContent']}}"
                        (click)="downloadImage(image['imageContent'])" />
                      <em *ngIf="!reviewInspection &&  image['imagePlacement'] === 'Front'" class=" material-icons"
                        (click)="removeImage(question.questions.questionId, j , 'Front')">close</em>
                    </div>
                    <div
                      *ngIf="uploadError[question.questions.questionId] && uploadError[question.questions.questionId]['Front']">
                      <div *ngFor="let error of uploadError[question.questions.questionId]['Front']; let k = index"
                        [attr.data-index]="k" style="display: inline-flex;">
                        <label>{{error}}</label>
                      </div>
                    </div>
                    <div *ngIf="submitted && !checkHydrantImage(images[question.questions.questionId] , 'Front');"
                      class="alert alert-danger">
                      * Please upload the image
                    </div>
                  </div>
  
                  <div class="col-md-12"
                    *ngIf="isImageUploadEnabled && question.questions.isImageUploadEnabled && question.questions.countOfImages && question.questions.countOfImages > 1">
                    <input type="file" accept="image/gif,image/jpeg"
                      *ngIf="!images[question.questions.questionId] || (images[question.questions.questionId] && (images[question.questions.questionId].length === 0 || inspectionDataforSecondPage['noBackImage']))"
                      (change)="uploadFile(question.questions.questionId, $event.target,$event.target.files,'Back');"
                      id="img-{{question.questions.questionId}}-2" style="display:none;"
                      name="img-{{question.questions.questionId}}" required ngModel>
                    <input type="file" accept="image/gif,image/jpeg"
                      *ngIf="images[question.questions.questionId] && images[question.questions.questionId].length > 0 && !inspectionDataforSecondPage['noBackImage']"
                      (change)="uploadFile(question.questions.questionId, $event.target,$event.target.files,'Back');"
                      id="img-{{question.questions.questionId}}-2" style="display:none;">
  
                    <label for="img-{{question.questions.questionId}}-2" class="custom-button-label choose-button"
                      *ngIf="!reviewInspection">Upload Back Picture</label>&nbsp;
  
                    <div *ngFor="let image of images[question.questions.questionId]; let j = index" [attr.data-index]="j"
                      style="display: inline-flex;">
                      <img alt="tegrisfire" *ngIf="image['imagePlacement'] === 'Back'"
                        class="font-weight-bold contacts-content-font breakWord text-fields certificate"
                        style="width:100px;height:90px!important;cursor: pointer;" src="{{image['imageContent']}}"
                        (click)="downloadImage(image['imageContent'])" />
                      <em *ngIf="!reviewInspection &&  image['imagePlacement'] === 'Back'" class="material-icons"
                        (click)="removeImage(question.questions.questionId, j , 'Back')">close</em>
                    </div>
                    <div
                      *ngIf="uploadError[question.questions.questionId] &&  uploadError[question.questions.questionId]['Back']">
                      <div *ngFor="let error of uploadError[question.questions.questionId]['Back']; let k = index"
                        [attr.data-index]="k" style="display: inline-flex;">
                        <label>{{error}}</label>
                      </div>
                    </div>
                    <div *ngIf="submitted && !checkHydrantImage(images[question.questions.questionId] , 'Back');"
                      class="alert alert-danger">
                      * Please upload the image
                    </div>
                  </div>
                </div>
                <!-- sub questions based on dependency value -->
              </div>
            </div>
            <div *ngIf="(question.questions && question.questions.question !== 'Others')">
              <div *ngIf="(question.questions.subquestion && qtd[question.questions.questionId] === 'Yes' || ( question.questions && question.questions.question !== 'Pressure Gauges Replaced - Quantity' && question.questions.question !== 'Number of branch lines examined:' && question.questions.question !== 'Number of cross mains examined:' && question.questions.question !== 'FACP Notification (check all that apply)' && question.questions.question !== 'Install:'  && question.questions.question !== 'Others' && question.questions.question !== 'Downstream Process:' && question.questions.question !== 'FCC Registration:'&& printBlank) || (question.questions.subquestion && question.questions.subquestion[0] && question.questions.subquestion[0].questionType === 'Custom' && question.questions.optionType !== 'Radio' || (question.questions.optionType === 'Radio' && qtd[question.questions.questionId] === 'Yes' )))
            || ((question.questions.question !== 'Weight of 1st CO2 or Nitrogen cartridge:' &&
              question.questions.question !== 'Weight of 2nd CO2 or Nitrogen cartridge:' &&
              question.questions.question !== 'Weight of 3rd CO2 or Nitrogen cartridge:') &&
              question.questions.hasDependents && qtd[question.questions.questionId] == question.questions.dependencyValue)
            || ((question.questions.question === 'Weight of 1st CO2 or Nitrogen cartridge:' ||
              question.questions.question === 'Weight of 2nd CO2 or Nitrogen cartridge:' ||
              question.questions.question === 'Weight of 3rd CO2 or Nitrogen cartridge:') &&
              question.questions.hasDependents &&
              qtd[question.questions.questionId] &&
              qtd[question.questions.questionId].length > 0 &&
              qtd[question.questions.questionId] !== question.questions.dependencyValue &&
              !qtd[question.questions.questionId+'checkbox'])">
                <div *ngFor="let subQuestion of question.questions.subquestion" style="margin-top:15px;"
                  [ngClass]="!printBlank ? 'subQuestion': ''">
                  <div *ngIf="subQuestion.mainHeading === 'Heading'">
                    <div *ngIf="!subQuestion.optionType" class="mainHeading">
                      {{subQuestion.question | uppercase}}
                    </div>
                    <div *ngIf="subQuestion.optionType === 'Radio'" style="width: 100%;">
                      <div class="rowOuter">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-5 mainHeading">
                              {{subQuestion.question | uppercase}}
                            </div>
                            <div class="col-md-7">
                              <mat-radio-group class="example-radio-group" name="name-{{subQuestion.questionId}}"
                                [(ngModel)]="qtd[subQuestion.questionId]" id="enum_answer_{{subQuestion.questionId}}"
                                [disabled]="printBlank || reviewInspection" required #radio1="ngModel">
                                <mat-radio-button class="example-radio-button" *ngFor="let option of subQuestion.options"
                                  [value]="option.data" [disabled]="printBlank || reviewInspection">
                                  {{option.data}}&nbsp;
                                </mat-radio-button>
                              </mat-radio-group>
                              <div *ngIf="submitted" [hidden]="radio1.valid" class="alert alert-danger">
                                * Please answer this question
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="(subQuestion.optionType === 'Text' || subQuestion.optionType === 'Date')"
                      style="width: 100%;">
                      <div class="rowOuter">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-5 mainHeading">
                              {{subQuestion.question | uppercase}}
                            </div>
                            <div class="col-md-7">
                              <input type="text" class="rounded-form-fields" name="qtd-{{subQuestion.questionId}}"
                                id="qtd{{subQuestion.questionId}}" [(ngModel)]="qtd[subQuestion.questionId]" required
                                [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                                [disabled]="qtd[subQuestion.questionId+'checkbox'] && subQuestion.options && subQuestion.options.length > 0 || reviewInspection"
                                #imp3="ngModel"
                                (change)="checkValidity($event.target.value,subQuestion.optionType,subQuestion.questionId , subQuestion.unit ,subQuestion.question)" />&nbsp;<label
                                class="questions">
                                {{subQuestion.unit}}</label>&nbsp;
                              <input
                                *ngIf="subQuestion.options  && subQuestion.options.length > 0 && !reviewInspection || printBlank"
                                id="checkbox_{{subQuestion.questionId}}" type="checkbox"
                                name="qtd-check-{{subQuestion.questionId}}"
                                [(ngModel)]="qtd[subQuestion.questionId+'checkbox']"
                                (change)="setValuesOfNA(subQuestion.questionId , $event.target);"
                                [disabled]="printBlank">&nbsp;<label
                                *ngIf="subQuestion.options && subQuestion.options.length > 0 &&  !reviewInspection || printBlank"
                                class="questions">N/A</label>
                              <div *ngIf="submitted" [hidden]="qtd[subQuestion.questionId]" class="alert alert-danger">
                                * Please answer this question
                              </div>
                              <div
                                *ngIf="submitted && !checkValidity(qtd[subQuestion.questionId],subQuestion.optionType,subQuestion.questionId ,subQuestion.unit , subQuestion.question) && subQuestion.optionType !== 'Date'"
                                class="alert alert-danger">
                                * Please enter a valid value
                              </div>
                              <div
                                *ngIf="submitted && !checkValidity(qtd[subQuestion.questionId],subQuestion.optionType,subQuestion.questionId ,subQuestion.unit , subQuestion.question) && subQuestion.optionType === 'Date'"
                                class="alert alert-danger">
                                * Please enter a valid date
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6"
                      *ngIf="(isImageUploadEnabled || reviewInspection) && subQuestion.isImageUploadEnabled">
                      <input type="file" accept="image/gif,image/jpeg"
                        *ngIf="!images[subQuestion.questionId] || (images[subQuestion.questionId] && images[subQuestion.questionId].length === 0)"
                        (change)="uploadFile(subQuestion.questionId, $event.target,$event.target.files);"
                        id="img-{{subQuestion.questionId}}" style="display:none;" name="img-{{subQuestion.questionId}}"
                        ngModel [required]="qtd[question.questions.questionId] === 'Yes' ? true : null">
                      <input type="file" accept="image/gif,image/jpeg"
                        *ngIf="images[subQuestion.questionId] && images[subQuestion.questionId].length > 0"
                        (change)="uploadFile(subQuestion.questionId, $event.target,$event.target.files, null, subQuestion.countOfImages);"
                        id="img-{{subQuestion.questionId}}" style="display:none;">
  
                      <label for="img-{{subQuestion.questionId}}" class="custom-button-label choose-button"
                        *ngIf="!reviewInspection">Upload Image</label>&nbsp;
                      <div *ngFor="let image of images[subQuestion.questionId]; let j = index" [attr.data-index]="j"
                        style="display: inline-flex;">
                        <img alt="tegrisfire"
                          class="font-weight-bold contacts-content-font breakWord text-fields certificate"
                          style="width:100px;height:90px!important; cursor: pointer;" src="{{image['imageContent']}}"
                          (click)="downloadImage(image['imageContent'])" />
                        <em *ngIf="!reviewInspection" class="material-icons"
                          (click)="removeImage(subQuestion.questionId, j)">close</em>
                      </div>
  
                      <div *ngFor="let error of uploadError[subQuestion.questionId]; let k = index" [attr.data-index]="k"
                        style="display: inline-flex;">
                        <label>{{error}}</label>
                      </div>
                      <div *ngIf="submitted && qtd[question.questions.questionId] === 'Yes'"
                        [hidden]="images[subQuestion.questionId] && images[subQuestion.questionId].length > 0"
                        class="alert alert-danger">
                        * Please upload the image
                      </div>
                    </div>
                  </div>
                  <div *ngIf="subQuestion.mainHeading !== 'Heading' &&
              subQuestion.question !== '#' &&
              subQuestion.question !== '(specify)'">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-5 questions">
                          {{subQuestion.question}}
                        </div>
                        <div class="col-md-7">
                          <div *ngIf="(subQuestion.optionType === 'Text' || subQuestion.optionType === 'Date')">
                            <input type="text" class="rounded-form-fields" name="qtd-{{subQuestion.questionId}}"
                              id="qtd{{subQuestion.questionId}}" [(ngModel)]="qtd[subQuestion.questionId]" required
                              [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                              [disabled]="qtd[subQuestion.questionId+'checkbox'] && subQuestion.options && subQuestion.options.length > 0 || reviewInspection"
                              #imp11="ngModel"
                              (change)="checkValidity($event.target.value,subQuestion.optionType,subQuestion.questionId,subQuestion.unit , subQuestion.question)" />&nbsp;<label
                              class="questions">
                              {{subQuestion.unit}}</label>&nbsp;
                            <input
                              *ngIf="subQuestion.options && subQuestion.options.length >0 && !reviewInspection || printBlank"
                              id="checkbox_{{subQuestion.questionId}}" type="checkbox"
                              name="qtd-check-{{subQuestion.questionId}}"
                              (change)="setValuesOfNA(subQuestion.questionId , $event.target);"
                              [(ngModel)]="qtd[subQuestion.questionId+'checkbox']" [disabled]="printBlank">&nbsp;<label
                              *ngIf="subQuestion.options && subQuestion.options.length > 0 && !reviewInspection || printBlank"
                              class="questions">N/A</label>
                            <div *ngIf="submitted" [hidden]="qtd[subQuestion.questionId]" class="alert alert-danger">
                              * Please answer this question
                            </div>
                            <div
                              *ngIf="submitted && !checkValidity(qtd[subQuestion.questionId],subQuestion.optionType,subQuestion.questionId ,subQuestion.unit , subQuestion.question) && subQuestion.optionType !== 'Date'"
                              class="alert alert-danger">
                              * Please enter a valid value
                            </div>
                            <div
                              *ngIf="submitted && !checkValidity(qtd[subQuestion.questionId],subQuestion.optionType,subQuestion.questionId ,subQuestion.unit , subQuestion.question) && subQuestion.optionType === 'Date'"
                              class="alert alert-danger">
                              * Please enter a valid date
                            </div>
                          </div>
                          <div *ngIf="subQuestion.optionType === 'Radio'">
                            <mat-radio-group class="example-radio-group" name="name-{{subQuestion.questionId}}"
                              [(ngModel)]="qtd[subQuestion.questionId]" id="enum_answer_{{subQuestion.questionId}}"
                              [disabled]="printBlank || reviewInspection" required #radio2="ngModel">
                              <mat-radio-button class="example-radio-button" *ngFor="let option of subQuestion.options"
                                [value]="option.data" [disabled]="printBlank || reviewInspection">
                                {{option.data}}&nbsp;
                              </mat-radio-button>
                            </mat-radio-group>
                            <div *ngIf="submitted" [hidden]="radio2.valid" class="alert alert-danger">
                              * Please answer this question
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6"
                          *ngIf="(isImageUploadEnabled || reviewInspection) && subQuestion.isImageUploadEnabled">
                          <input
                            *ngIf="!images[subQuestion.questionId] || (images[subQuestion.questionId] && images[subQuestion.questionId].length === 0)"
                            type="file" accept="image/gif,image/jpeg"
                            (change)="uploadFile(subQuestion.questionId, $event.target,$event.target.files);"
                            id="img-{{subQuestion.questionId}}" style="display:none;"
                            name="img-{{subQuestion.questionId}}" ngModel
                            [required]="qtd[question.questions.questionId] === 'Yes' ? true : null">
                          <input *ngIf="images[subQuestion.questionId] && images[subQuestion.questionId].length > 0"
                            type="file" accept="image/gif,image/jpeg"
                            (change)="uploadFile(subQuestion.questionId, $event.target,$event.target.files, null, subQuestion.countOfImages);"
                            id="img-{{subQuestion.questionId}}" style="display:none;">
                          <label for="img-{{subQuestion.questionId}}" class="custom-button-label choose-button"
                            *ngIf="!reviewInspection">Upload Image
                          </label>&nbsp;
                          <div *ngFor="let image of images[subQuestion.questionId]; let j = index" [attr.data-index]="j"
                            style="display: inline-flex;">
                            <img alt="tegrisfire"
                              class="font-weight-bold contacts-content-font breakWord text-fields certificate"
                              style="width:100px;height:90px!important; cursor: pointer;" src="{{image['imageContent']}}"
                              (click)="downloadImage(image['imageContent'])" />
                            <em *ngIf="!reviewInspection" class="material-icons"
                              (click)="removeImage(subQuestion.questionId, j)">close</em>
                          </div>
  
                          <div *ngFor="let error of uploadError[subQuestion.questionId]; let k = index"
                            [attr.data-index]="k" style="display: inline-flex;">
                            <label>{{error}}</label>
                          </div>
                          <div *ngIf="submitted && qtd[question.questions.questionId] === 'Yes'"
                            [hidden]="images[subQuestion.questionId] && images[subQuestion.questionId].length > 0"
                            class="alert alert-danger">
                            * Please upload the image
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Backflow Table -->
    <div class="row" *ngIf="assetTypeid == '15'">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 questions">
            <app-backflow [questions]="questions" [qtd]="qtd" [reviewInspection]="reviewInspection">
            </app-backflow>
          </div>
        </div>
      </div>
    </div>
    <hr>

    <div class="" *ngIf="additionalQuestionExist">
      <div class="col-12">
        <label class="font-weight-bold inputLabel inspection mainHeading" style="margin-left: 0;">ADDITIONAL
          QUESTIONS</label>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div *ngFor="let question of questions;let i = index">
          <div
            *ngIf="question.questions.questionType === 'Additional'  || ( question.questions.questionType === 'Additional' && printBlank)"
            style="width: 100%;">
            <div *ngIf="question.questions && !question.questions.optionType">
              {{question.questions.question}}
            </div>
            <div
              *ngIf="question.questions && question.questions.optionType && question.questions.optionType === 'Radio'"
              style="width: 100%;">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-5 additionalQuestions">
                    {{question.questions.question}}
                  </div>
                  <div class="col-md-6">
                    <mat-radio-group class="example-radio-group" name="name-{{question.questions.questionId}}"
                      [(ngModel)]="qtd[question.questions.questionId]"
                      id="enum_answer_{{question.questions.questionId}}" [disabled]="printBlank || reviewInspection"
                      required #radio12="ngModel">
                      <mat-radio-button class="example-radio-button" *ngFor="let option of question.questions.options"
                        [value]="option.data" [disabled]="printBlank || reviewInspection">
                        {{option.data}}&nbsp;
                      </mat-radio-button>
                    </mat-radio-group>
                    <div *ngIf="submitted" [hidden]="radio12.valid" class="alert alert-danger">
                      * Please answer this question
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="(question.questions && question.questions.optionType && question.questions.optionType === 'Text' && question.questions.question !== 'Others')|| question.questions.optionType === 'Date'"
              style="width: 100%;">
              <div class="">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-5 additionalQuestions">
                      {{question.questions.question}}
                    </div>
                    <div class="col-md-6">
                      <input type="text" class="rounded-form-fields" name="qtd-{{question.questions.questionId}}"
                        [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                        id="qtd{{question.questions.questionId}}" [(ngModel)]="qtd[question.questions.questionId]"
                        required [disabled]="reviewInspection" #imp20="ngModel"
                        (change)="checkValidity($event.target.value,question.questions.optionType,question.questions.questionId ,question.questions.unit , question.questions.question)" />&nbsp;<label
                        class="questions">
                        {{question.questions.unit}}</label>&nbsp;
                      <div *ngIf="submitted" [hidden]="qtd[question.questions.questionId]" class="alert alert-danger">
                        * Please answer this question
                      </div>
                      <div
                        *ngIf="submitted && !checkValidity(qtd[question.questions.questionId],question.questions.optionType,question.questions.questionId ,question.questions.unit , question.questions.question) && question.questions.optionType !== 'Date'"
                        class="alert alert-danger">
                        * Please enter a valid value
                      </div>
                      <div
                        *ngIf="submitted && !checkValidity(qtd[question.questions.questionId],question.questions.optionType,question.questions.questionId ,question.questions.unit , question.questions.question) && question.questions.optionType === 'Date'"
                        class="alert alert-danger">
                        * Please enter a valid date
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="standpipe" *ngIf="assetTypeid === '6'">
      <div *ngIf="qtd[findQuestionId('Do you want to add P.R.V test results?')] ==='Yes' || printBlank">
        <div class=" col-md-12">
          <div class="row">
            <app-standpipe-prv style="width:100%;" [questions]="questions" [qtd]="qtd"
              [reviewInspection]="reviewInspection">
            </app-standpipe-prv>
          </div>
        </div>
      </div>
      <div *ngIf="(showHose || printBlank) && qtd[findQuestionId('Standpipe Class:')] === 'class2'">
        <div class="col-md-12">
          <div class="row">
            <app-standpipe-hose style="width:100%;" [questions]="questions" [qtd]="qtd"
              [reviewInspection]="reviewInspection">
            </app-standpipe-hose>
          </div>
        </div>
      </div>
    </div>
    <div class="cleanAgent" *ngIf="assetTypeid === '10'">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12 questions">
              <app-clean-agent [questions]="questions" [qtd]="qtd" [reviewInspection]="reviewInspection">
              </app-clean-agent>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fireAlarm" *ngIf="assetTypeid === '3'">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12 questions">
              <app-fire-alarm [questions]="questions" [qtd]="qtd" [reviewInspection]="reviewInspection">
              </app-fire-alarm>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="row h6 text-dark" *ngIf="showSimplified">

    <!-- To Add if we remove it from enterinspection -->
    <div class="col-5" *ngIf="!reviewInspection">
      <label class="font-weight-bold inputLabel inspection">Upload File:<label style="color: red">*</label> </label>
    </div>
    <div class="col-7" *ngIf="!reviewInspection">
      <input type="file" accept="image/gif,image/jpeg,application/pdf"
        (change)="uploadFileSimplified($event.target,$event.target.files)" id="img1" style="display:none;">
      <label for="img1" class="custom-button-label choose-button">Upload File</label>&nbsp;
      <label *ngIf="imageUploadErrorSimplified">{{imageUploadErrorSimplified}}</label><label
        *ngIf="!imageUploadErrorSimplified && imagePlacement">{{fileNameSimplified}}</label>
    </div>
    <img alt="tegrisfire" *ngIf="fileNameSimplified && fileNameSimplified.indexOf('pdf') < 0 && imagePlacement"
      class="font-weight-bold contacts-content-font breakWord text-fields certificate" style="cursor: pointer;"
      (click)="downloadImage(imageSimplified)" src="{{imageSimplified}}" />
    <pdf-viewer *ngIf="fileNameSimplified && fileNameSimplified.indexOf('pdf') > 0 && imagePlacement"
      [src]="imageSimplified" [original-size]="false"></pdf-viewer>
    <div *ngIf="submitted && !fileNameSimplified" class="alert alert-danger">
      * Please upload the file
    </div>
  </div>
  <div class="" *ngIf="additionalQuestionExist && showSimplified">
    <div class="col-12">
      <label class="font-weight-bold inputLabel inspection additionalHeading">Additional Questions</label>
    </div>
  </div>
  <div class="row" *ngIf="additionalQuestionExist && showSimplified">
    <div class="col-12">
      <div *ngFor="let question of questions">
        <div
          *ngIf="question.questions.questionType === 'Additional'  || ( question.questions.questionType === 'Additional' && printBlank)"
          style="width: 100%;">
          <div *ngIf="question.questions && !question.questions.optionType">
            {{question.questions.question}}
          </div>
          <div *ngIf="question.questions && question.questions.optionType && question.questions.optionType === 'Radio'"
            style="width: 100%;">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-5 additionalQuestions">
                  {{question.questions.question}}
                </div>
                <div class="col-md-6">
                  <mat-radio-group class="example-radio-group" name="name-{{question.questions.questionId}}"
                    [(ngModel)]="qtd[question.questions.questionId]" id="enum_answer_{{question.questions.questionId}}"
                    [disabled]="printBlank || reviewInspection" required #radio4="ngModel">
                    <mat-radio-button class="example-radio-button" *ngFor="let option of question.questions.options"
                      [value]="option.data" [disabled]="printBlank || reviewInspection">
                      {{option.data}}&nbsp;
                    </mat-radio-button>
                  </mat-radio-group>
                  <div *ngIf="submitted" [hidden]="radio4.valid" class="alert alert-danger">
                    * Please answer this question
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 100%;">
            *ngIf="question.questions && question.questions.optionType && question.questions.optionType === 'Text' &&
            question.questions.question !== 'Others' || question.questions.optionType === 'Date'"
            <div class="">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-5 additionalQuestions">
                    {{question.questions.question}}
                  </div>
                  <div class="col-md-6">
                    <input type="text" class="rounded-form-fields" name="qtd-{{question.questions.questionId}}"
                      [ngClass]="configureReport ? 'configure-Report': 'rounded-form-fields'"
                      id="qtd{{question.questions.questionId}}" [(ngModel)]="qtd[question.questions.questionId]"
                      required [disabled]="reviewInspection" #input30="ngModel"
                      (change)="checkValidity($event.target.value,question.questions.optionType,question.questions.questionId ,question.questions.unit , question.questions.question)" />&nbsp;<label
                      class="questions">
                      {{question.questions.unit}}</label>&nbsp;
                    <div *ngIf="submitted" [hidden]="qtd[question.questions.questionId]" class="alert alert-danger">
                      * Please answer this question
                    </div>
                    <div
                      *ngIf="submitted && !checkValidity(qtd[question.questions.questionId],question.questions.optionType,question.questions.questionId ,question.questions.unit , question.questions.question) && question.questions.optionType !== 'Date'"
                      class="alert alert-danger">
                      * Please enter a valid value
                    </div>
                    <div
                      *ngIf="submitted && !checkValidity(qtd[question.questions.questionId],question.questions.optionType,question.questions.questionId ,question.questions.unit , question.questions.question) && question.questions.optionType === 'Date'"
                      class="alert alert-danger">
                      * Please enter a valid date
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="(!reviewInspection || isViewMode) && !configureReport">
    <div class="row">
      <div class="col-5">
        <label class="font-weight-bold inputLabel inspection">System Status<label style="color: red">*</label></label>
      </div>
      <div class="col-7">
        <mat-radio-group [disabled]="reviewInspection" class="example-radio-group" id="testConducted"
          name="systemstatus" required #systemStatus1="ngModel" [(ngModel)]="systemStatus">
          <mat-radio-button class="example-radio-button" value="serviceTestConducted">
            Service/Test Conducted&nbsp;
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="isRepaired">
            Pre-Acceptance Test&nbsp;
          </mat-radio-button>
        </mat-radio-group>
        <div *ngIf="submitted" [hidden]="systemStatus1.valid" class="alert alert-danger">
          * Please enter the system status
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <label class="font-weight-bold inputLabel inspection">Inspected Date <label style="color: red">*</label></label>
      </div>
      <div class="col-7">
        <div class="date-range">
          <input [disabled]="reviewInspection" type="text" class="rounded-form-fields" name="inspectedDate"
            id="inspectedDateId" #inspectedDate1="ngModel" [(ngModel)]="inspectedDate" [matDatepicker]="pickerFrom"
            required>
          <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
          <label class="questions">
            (MM-DD-YYYY)</label>
        </div>
      </div>
    </div>
    <div class="row" [hidden]="inspectedDate1.untouched">
      <span class="col-5"></span>
      <label class="col-7 error" style="padding-left: 15px !important"
        *ngIf="checkErrorIninspectedDate(inspectedDate1.value).length>0">
        *{{inspectedDateError}}
      </label>
    </div>
    <div class="row">
      <div class="col-6">
        <label class="font-weight-bold inputLabel inspection">Do you want to add additional custom area for inspection
          remarks ? </label>
      </div>
      <div class="col-6">
        <mat-radio-group [disabled]="reviewInspection" class="example-radio-group" id="additional" name="additional"
          required [(ngModel)]="additional">
          <mat-radio-button class="example-radio-button" value="yes">
            Yes&nbsp;
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="no">
            No&nbsp;
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="row" *ngIf="additional === 'yes'">
      <div class="col-5">
        <label class="font-weight-bold inputLabel inspection">Remarks:</label>
      </div>
      <div class="col-6">
        <textarea [disabled]="reviewInspection" rows="4" cols="50" name="additionalRemarks" ngModel
          [(ngModel)]="additionalRemarks">
      </textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="font-weight-bold inputLabel inspection">Authority Remarks</label>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label *ngIf="!authRemarks" class="font-weight-bold inputLabel inspection authority authority-remarks">No
          Authority Remarks
          Configured</label>
        <label *ngIf="authRemarks"
          class="font-weight-bold inputLabel inspection authority authority-remarks">{{authRemarks}}</label>
      </div>
    </div>

    <div class="row" style="margin-bottom: 25px;">
      <div class="col-cust-1">
        <label style="color: red">*</label>&nbsp;<input [disabled]="reviewInspection" id="checkbox_authority"
          [value]='true' type="checkbox" name="checkboxAuthority" ngModel [(ngModel)]="checkbox_authority">
      </div>
      <div class="col-10" style="color:black;">
        By checking here you are certifying, under penalty of perjury, that you are a valid agent of your company
        representing that the company maintains all the necessary licenses and/or certifications to perform this service
        for this system in this jurisdiction AND THAT the company has properly inspected this system consistent with
        state
        and local standards AND THAT the system has been properly tagged or labeled and the property owner or
        responsible
        person has been notified of the inspection results, the system status, and any corrective actions.
      </div>
    </div>
    <div *ngIf="submitted && checkbox_authority !== true" class="alert alert-danger">
      * Please certify the above inspection
    </div>
    <div class="row h6 text-dark">
      <div class="col-5">
        <label class="font-weight-bold inputLabel inspection">Phone # :</label>
      </div>
      <div class="col-7">
        <label
          class="font-weight-bold contacts-content-font breakWord text-fields normal">{{inspectionDataforSecondPage.contact}}</label>
      </div>
    </div>
    <div class="row h6 text-dark">
      <div class="col-5">
        <label class="font-weight-bold inputLabel inspection">Testing Agency : </label>
      </div>
      <div class="col-7">
        <label
          class="font-weight-bold contacts-content-font breakWord text-fields normal">{{inspectionDataforSecondPage.name}}</label>
      </div>
    </div>
    <div class="row h6 text-dark">
      <div class="col-5">
        <label class="font-weight-bold inputLabel inspection">Mailing Address : </label>
      </div>
      <div class="col-7">
        <label class="font-weight-bold contacts-content-font breakWord text-fields normal"
          [innerHTML]="inspectionDataforSecondPage.address || ''"></label>
      </div>
    </div>
    <div class="row h6 text-dark"
      *ngIf="assetTypeid !== '9' && assetTypeid !== '14' && assetTypeName !== 'Emergency Responder Radio Coverage System'">
      <div class="col-5">
        <label class="font-weight-bold inputLabel inspection">Upload File : </label>
      </div>
      <div class="col-7">
        <input [disabled]="reviewInspection" type="file" accept="image/gif,image/jpeg"
          (change)="uploadFileMain($event.target,$event.target.files)" id="img" style="display:none;">
        <label for="img" class="custom-button-label choose-button">Choose File</label>&nbsp;
        <label *ngIf="imageUploadError">{{imageUploadError}}</label>
        <label matTooltip="{{fileName}}" [ngClass]="{'overflow-name': fileName}"
          *ngIf="!imageUploadError">{{fileName}}</label>
        <button [disabled]="reviewInspection" type="button" class="custom-button-label choose-button left"
          id="removeImg" (click)="removeFile();">Remove
          File</button>
      </div>
    </div>
    <div class="row h6 text-dark"
      *ngIf="assetTypeid !== '9' && assetTypeid !== '14' && assetTypeName !== 'Emergency Responder Radio Coverage System'">
      <div class="col-12">
        Upload an image of 'gif' or 'jpg' type OR a pdf file having size less than 1Mb.
      </div>
    </div>
    <div class="row h6 text-dark"
      *ngIf="assetTypeid === '14' || assetTypeName === 'Emergency Responder Radio Coverage System'">
      <div class="col-5">
        <label class="font-weight-bold inputLabel inspection">Upload File: </label>
      </div>
      <div class="col-7">
        <input [disabled]="reviewInspection" type="file" accept="image/gif,image/jpeg,application/pdf"
          (change)="uploadFileMain($event.target,$event.target.files)" id="img" style="display:none;">
        <label for="img" class="custom-button-label choose-button">Choose File</label>&nbsp;
        <label *ngIf="imageUploadError">{{imageUploadError}}</label>
        <label matTooltip="{{fileName}}" [ngClass]="{'overflow-name': fileName4}"
          *ngIf="!imageUploadError">{{fileName}}</label>
        <button [disabled]="reviewInspection" type="button" class="custom-button-label choose-button left"
          id="removeImg" (click)="removeFile();">Remove
          File</button>
      </div>
    </div>

    <div class="row h6 text-dark"
      *ngIf=" assetTypeid === '14' || assetTypeName === 'Emergency Responder Radio Coverage System'">
      <div class="col-12">
        Upload an image of 'gif' or 'jpg' type OR a pdf file having size less than 5Mb.
      </div>
    </div>

    <div class="row h6 text-dark"
      *ngIf="assetTypeid === '14' || assetTypeName === 'Emergency Responder Radio Coverage System'">
      <div class="col-5">
        <label class="font-weight-bold inputLabel inspection">Upload File 1: </label>
      </div>
      <div class="col-7">
        <input [disabled]="reviewInspection" type="file" accept="image/gif,image/jpeg,application/pdf"
          (change)="uploadFileMainEmergencyResp($event.target,$event.target.files)" id="img4" style="display:none;">
        <label for="img4" class="custom-button-label choose-button">Choose File</label>&nbsp;
        <label *ngIf="imageUploadError4">{{imageUploadError4}}</label>
        <label matTooltip="{{fileName4}}" [ngClass]="{'overflow-name': fileName4}"
          *ngIf="!imageUploadError4">{{fileName4}}</label>
        <button [disabled]="reviewInspection" type="button" class="custom-button-label choose-button left"
          id="removeImg4" (click)="removeFileEmergencyRes($event.target);">Remove
          File</button>
      </div>
    </div>

    <div class="row h6 text-dark"
      *ngIf="assetTypeid === '14' || assetTypeName === 'Emergency Responder Radio Coverage System'">
      <div class="col-5">
        <label class="font-weight-bold inputLabel inspection">Upload File 2: </label>
      </div>
      <div class="col-7">
        <input [disabled]="reviewInspection" type="file" accept="image/gif,image/jpeg,application/pdf"
          (change)="uploadFileMainEmergencyResp($event.target,$event.target.files)" id="img2" style="display:none;">
        <label for="img2" class="custom-button-label choose-button">Choose File</label>&nbsp;
        <label *ngIf="imageUploadError2">{{imageUploadError2}}</label>
        <label matTooltip="{{fileName2}}" [ngClass]="{'overflow-name': fileName2}"
          *ngIf="!imageUploadError2">{{fileName2}}</label>
        <button [disabled]="reviewInspection" type="button" class="custom-button-label choose-button left"
          id="removeImg2" (click)="removeFileEmergencyRes($event.target);">Remove
          File</button>
      </div>
    </div>

    <div class="row h6 text-dark"
      *ngIf="assetTypeid === '14' || assetTypeName === 'Emergency Responder Radio Coverage System'">
      <div class="col-5">
        <label class="font-weight-bold inputLabel inspection">Upload File 3: </label>
      </div>
      <div class="col-7">
        <input [disabled]="reviewInspection" type="file" accept="image/gif,image/jpeg,application/pdf"
          (change)="uploadFileMainEmergencyResp($event.target,$event.target.files)" id="img3" style="display:none;">
        <label for="img3" class="custom-button-label choose-button">Choose File</label>&nbsp;
        <label *ngIf="imageUploadError3">{{imageUploadError3}}</label>
        <label matTooltip="{{fileName3}}" [ngClass]="{'overflow-name': fileName3}"
          *ngIf="!imageUploadError3">{{fileName3}}</label>
        <button [disabled]="reviewInspection" type="button" class="custom-button-label choose-button left"
          id="removeImg3" (click)="removeFileEmergencyRes($event.target);">Remove
          File</button>
      </div>
    </div>

    <div class="row h6 text-dark" *ngIf="assetTypeid === '9'">
      <div class="col-5">
        <label class="font-weight-bold inputLabel inspection">Validation Photos : </label>
      </div>
      <div class="row h6 text-dark">
        <div class="col-12" style="font-size:10px;">
          Three photos shall be attached to further provide documentation the commmercial hood is cleaned. The three
          photos
          shall show (1) the cleaned plenum space behind the filters, (2) a close-up of the changed fusible link at the
          hood
          duct opening and (3) a photo shall show the cleaned exterior fan assembly. All photos shall be jpeg format and
          limited to 1MB
        </div>
      </div>
    </div>
    <div class="row h6 text-dark" *ngIf="assetTypeid === '9'">
      <div class="col-5">
        <label class="font-weight-bold inputLabel inspection">Upload Photo 1 : </label>
      </div>
      <div class="col-7">
        <input [disabled]="reviewInspection" type="file" *ngIf="!attachment || ( attachment && attachment.length < 3)"
          accept="image/gif,image/jpeg" (change)="uploadPhoto1($event.target,$event.target.files)" id="photo1"
          style="display:none;" name="name-photo1" ngModel required>
        <input [disabled]="reviewInspection" type="file" *ngIf="attachment && attachment.length === 3"
          accept="image/gif,image/jpeg" (change)="uploadPhoto1($event.target,$event.target.files)" id="photo1"
          style="display:none;" name="name-photo1" ngModel>
        <label for="photo1" class="custom-button-label choose-button">Choose File</label>&nbsp;
        <label *ngIf="photo1Error">{{photo1Error}}</label>
        <label matTooltip="{{photo1Name}}" [ngClass]="{'overflow-name': photo1Name}"
          *ngIf="!photo1Error">{{photo1Name}}</label>
        <button [disabled]="reviewInspection" type="button" class="custom-button-label choose-button left"
          (click)="removePhoto1();">Remove
          File</button>
        <div *ngIf="submitted && !photo1Name" class="alert alert-danger">
          * Please upload the image
        </div>
      </div>
    </div>
    <div class="row h6 text-dark" *ngIf="assetTypeid === '9'">
      <div class="col-5">
        <label class="font-weight-bold inputLabel inspection">Upload Photo 2 :</label>
      </div>
      <div class="col-7">
        <input [disabled]="reviewInspection" type="file" *ngIf="!attachment || ( attachment && attachment.length < 3)"
          accept="image/gif,image/jpeg" (change)="uploadPhoto2($event.target,$event.target.files)" id="photo2"
          style="display:none;" name="name-photo2" ngModel required>
        <input [disabled]="reviewInspection" type="file" *ngIf="attachment && attachment.length === 3"
          accept="image/gif,image/jpeg" (change)="uploadPhoto2($event.target,$event.target.files)" id="photo2"
          style="display:none;" name="name-photo2" ngModel>
        <label for="photo2" class="custom-button-label choose-button">Choose File</label>&nbsp;
        <label *ngIf="photo2Error">{{photo2Error}}</label>
        <label matTooltip="{{photo2Name}}" [ngClass]="{'overflow-name': photo2Name}"
          *ngIf="!photo2Error">{{photo2Name}}</label>
        <button [disabled]="reviewInspection" type="button" class="custom-button-label choose-button left"
          (click)="removePhoto2();">Remove
          File</button>
        <div *ngIf="submitted && !photo2Name" class="alert alert-danger">
          * Please upload the image
        </div>
      </div>
    </div>
    <div class="row h6 text-dark" *ngIf="assetTypeid === '9'">
      <div class="col-5">
        <label class="font-weight-bold inputLabel inspection">Upload Photo 3 : </label>
      </div>
      <div class="col-7">
        <input [disabled]="reviewInspection" type="file" *ngIf="!attachment || ( attachment && attachment.length < 3)"
          accept="image/gif,image/jpeg" (change)="uploadPhoto3($event.target,$event.target.files)" id="photo3"
          style="display:none;" name="name-photo3" ngModel required>
        <input [disabled]="reviewInspection" type="file" *ngIf="attachment && attachment.length === 3"
          accept="image/gif,image/jpeg" (change)="uploadPhoto3($event.target,$event.target.files)" id="photo3"
          style="display:none;" name="name-photo3" ngModel>
        <label for="photo3" class="custom-button-label choose-button">Choose File</label>&nbsp;
        <label *ngIf="photo3Error">{{photo3Error}}</label>
        <label matTooltip="{{photo3Name}}" [ngClass]="{'overflow-name': photo3Name}"
          *ngIf="!photo3Error">{{photo3Name}}</label>
        <button [disabled]="reviewInspection" type="button" class="custom-button-label choose-button left"
          (click)="removePhoto3();">Remove
          File</button>
        <div *ngIf="submitted && !photo3Name" class="alert alert-danger">
          * Please upload the image
        </div>
      </div>
    </div>

    <div class="row h6 text-dark" *ngIf="assetTypeid === '10'">
      <div class="col-5">
        <label class="font-weight-bold inputLabel inspection">Bottle #1 : </label>
      </div>
      <div class="col-7">
        <input [disabled]="reviewInspection" type="file" *ngIf="!attachment || ( attachment && attachment.length < 4)"
          accept="image/gif,image/jpeg" (change)="uploadPhoto1($event.target,$event.target.files)" id="photo1"
          style="display:none;" name="name-photo1" ngModel>
        <input [disabled]="reviewInspection" type="file" *ngIf="attachment && attachment.length === 4"
          accept="image/gif,image/jpeg" (change)="uploadPhoto1($event.target,$event.target.files)" id="photo1"
          style="display:none;" name="name-photo1" ngModel>
        <label for="photo1" class="custom-button-label choose-button">Choose File</label>&nbsp;
        <label *ngIf="photo1Error">{{photo1Error}}</label>
        <label matTooltip="{{photo1Name}}" [ngClass]="{'overflow-name': photo1Name}"
          *ngIf="!photo1Error">{{photo1Name}}</label>
        <button [disabled]="reviewInspection" type="button" class="custom-button-label choose-button left"
          (click)="removePhoto1();">Remove
          File</button>
        <!-- <div *ngIf="submitted && !photo1Name" class="alert alert-danger">
          * Please upload the image
        </div> -->
      </div>
    </div>
    <div class="row h6 text-dark" *ngIf="assetTypeid === '10'">
      <div class="col-5">
        <label class="font-weight-bold inputLabel inspection">Bottle #2 :</label>
      </div>
      <div class="col-7">
        <input [disabled]="reviewInspection" type="file" *ngIf="!attachment || ( attachment && attachment.length < 4)"
          accept="image/gif,image/jpeg" (change)="uploadPhoto2($event.target,$event.target.files)" id="photo2"
          style="display:none;" name="name-photo2" ngModel>
        <input [disabled]="reviewInspection" type="file" *ngIf="attachment && attachment.length === 4"
          accept="image/gif,image/jpeg" (change)="uploadPhoto2($event.target,$event.target.files)" id="photo2"
          style="display:none;" name="name-photo2" ngModel>
        <label for="photo2" class="custom-button-label choose-button">Choose File</label>&nbsp;
        <label *ngIf="photo2Error">{{photo2Error}}</label>
        <label matTooltip="{{photo2Name}}" [ngClass]="{'overflow-name': photo2Name}"
          *ngIf="!photo2Error">{{photo2Name}}</label>
        <button [disabled]="reviewInspection" type="button" class="custom-button-label choose-button left"
          (click)="removePhoto2();">Remove
          File</button>
        <!-- <div *ngIf="submitted && !photo2Name" class="alert alert-danger">
          * Please upload the image
        </div> -->
      </div>
    </div>
    <div class="row h6 text-dark" *ngIf="assetTypeid === '10'">
      <div class="col-5">
        <label class="font-weight-bold inputLabel inspection">Bottle #3 : </label>
      </div>
      <div class="col-7">
        <input [disabled]="reviewInspection" type="file" *ngIf="!attachment || ( attachment && attachment.length < 4)"
          accept="image/gif,image/jpeg" (change)="uploadPhoto3($event.target,$event.target.files)" id="photo3"
          style="display:none;" name="name-photo3" ngModel>
        <input [disabled]="reviewInspection" type="file" *ngIf="attachment && attachment.length === 4"
          accept="image/gif,image/jpeg" (change)="uploadPhoto3($event.target,$event.target.files)" id="photo3"
          style="display:none;" name="name-photo3" ngModel>
        <label for="photo3" class="custom-button-label choose-button">Choose File</label>&nbsp;
        <label *ngIf="photo3Error">{{photo3Error}}</label>
        <label matTooltip="{{photo3Name}}" [ngClass]="{'overflow-name': photo3Name}"
          *ngIf="!photo3Error">{{photo3Name}}</label>
        <button [disabled]="reviewInspection" type="button" class="custom-button-label choose-button left"
          (click)="removePhoto3();">Remove
          File</button>
        <!-- <div *ngIf="submitted && !photo3Name" class="alert alert-danger">
          * Please upload the image
        </div> -->
      </div>
    </div>
    <div class="row h6 text-dark" *ngIf="assetTypeid === '10'">
      <div class="col-5">
        <label class="font-weight-bold inputLabel inspection">Bottle #4 : </label>
      </div>
      <div class="col-7">
        <input [disabled]="reviewInspection" type="file" *ngIf="!attachment || ( attachment && attachment.length < 4)"
          accept="image/gif,image/jpeg" (change)="uploadPhoto4($event.target,$event.target.files)" id="photo4"
          style="display:none;" name="name-photo4" ngModel>
        <input [disabled]="reviewInspection" type="file" *ngIf="attachment && attachment.length === 4"
          accept="image/gif,image/jpeg" (change)="uploadPhoto4($event.target,$event.target.files)" id="photo4"
          style="display:none;" name="name-photo4" ngModel>
        <label for="photo4" class="custom-button-label choose-button">Choose File</label>&nbsp;
        <label *ngIf="photo4Error">{{photo4Error}}</label>
        <label matTooltip="{{photo4Name}}" [ngClass]="{'overflow-name': photo4Name}"
          *ngIf="!photo4Error">{{photo4Name}}</label>
        <button [disabled]="reviewInspection" type="button" class="custom-button-label choose-button left"
          (click)="removePhoto4();">Remove
          File</button>
        <!-- <div *ngIf="submitted && !photo4Name" class="alert alert-danger">
          * Please upload the image
        </div> -->
      </div>
    </div>
    <div class="row h6 text-dark" *ngIf="attachmentOriginal && attachmentOriginal.length > 0">
      <div class="col-5">
        <label class="font-weight-bold inputLabel inspection">Downloaded Files : </label>
      </div>
    </div>
    <div class="row h6 text-dark" *ngIf="attachment">
      <div class="col-7" *ngFor="let image of attachment" style="display:inline-block">


        <a class="font-weight-bold contacts-content-font breakWord text-fields certificate thumbnail" target="_blank"
          style="margin-bottom:10px;"
          *ngIf="image && image['attachment'] && image['attachment'].indexOf('pdf') < 0 && image['imagePlacement']!== 'Simple'"
          href="{{image['attachment']}}">
          <img alt="tegrisfire" src="{{image['attachment']}}" class="thumbnail">
          {{image['imageName']}}
        </a>
        <a class="font-weight-bold contacts-content-font breakWord text-fields certificate" style="margin-bottom:10px;"
          *ngIf="image && image['attachment'] && image['attachment'].indexOf('pdf') >= 0  && image['imagePlacement']!== 'Simple'"
          href="{{image['attachment']}}">
          <img alt="tegrisfire" class="imageClass" src="../../../../../assets/images/pdf.png">
          {{image['imageName']}}
        </a>
      </div>
    </div>
    <hr>
    <div class="offset-3 col-10">
      <button type="button" value="Previous" class="custom-buttons" (click)="backButtonClicked();">
        Back
      </button>
      <button type="submit" appDataInspector class="custom-buttons"
        [ngClass]="{'disabled-button': disableSave || isViewMode}" [disabled]="disableSave || isViewMode">
        Next
      </button>
      <button type="button" class="custom-buttons" (click)="redirectDashboard();">
        Cancel
      </button>
    </div>
  </div>
</form>