import { Routes } from '@angular/router';
import { PageNotFoundComponent } from '@app-shared/components';
import { AuthGuard } from './login/auth.guard';
import { InspectionReportComponent } from './inspection/inspection-report/inspection-report.component';
var ɵ0 = function () {
    return import("./dashboard/dashboard.module.ngfactory").then(function (mod) { return mod.DashboardModuleNgFactory; });
};
var routes = [
    {
        path: 'user',
        canActivate: [AuthGuard],
        loadChildren: ɵ0
    },
    {
        path: 'printReport',
        component: InspectionReportComponent,
        canActivate: [AuthGuard]
    },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
