<app-dialog>
  <span class="dialog-title">
    <span > Property Access Code </span>
    <span class="float-right">
      <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
    </span>
  </span>
  <div class="dialog-content">
    <div class="row">
			<label class="col-5 col-form-label col-form-label-sm inputLabel">
				Property Access Code
			</label>
			<label class="col-7">
				<input type="text" [(ngModel)]="pac"
				class="col-11 col-form-label col-form-label-sm rounded-form-fields" required />
				<div class="mandatory" *ngIf="showError">
					* Please add PAC before submitting.
				</div>
			</label>
		</div>
  </div>
  <div class="dialog-actions">
      <button class="custom-buttons" (click)="submit()">Submit</button>
      <button class="custom-buttons" (click)="close()">Cancel</button>
  </div>
</app-dialog>
