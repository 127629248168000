<app-dialog>
    <span class="dialog-title">
      Select Date
      <span class="float-right">
        <mat-icon class="cursor-pointer" (click)="cancel()">close</mat-icon>
      </span>
    </span>

    <div class="dialog-content">
        <div class="row form-group-padding" *ngIf="data.dateRange.address != null">
            <label class="col-3 col-form-label col-form-label-sm inputLabel">
              Property Address:
            </label>
            <div class="col-9">
              <input class="col-9 col-form-label col-form-label-sm rounded-form-fields"
               [(ngModel)]="data.dateRange.address" />
            </div>
        </div>

        <div class="row form-group-padding" *ngIf="data.dateRange.accessCode != null">
            <label class="col-3 col-form-label col-form-label-sm inputLabel">
              PAC
            </label>
            <div class="col-9 p-0">
              <input class="col-9 col-form-label col-form-label-sm rounded-form-fields"
               [(ngModel)]="data.dateRange.accessCode" />
            </div>
        </div>

        <div class="row form-group-padding">
          <label class="col-3 col-form-label col-form-label-sm inputLabel">
            Date Range:
          </label>
          <div class="col-9">
            <div class="row">
              <div class="col-6" style="padding-left: 0px;">
                <div class="row">
                  <label class="col-3 col-form-label col-form-label-sm inputLabel">From</label>
                  <input class="col-7 form-control form-control-sm rounded-form-fields" [matDatepicker]="pickerFrom"
                    #datepickerFrom type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="data.dateRange.fromDate" id="fromDate">
                  <div class="col-1" style="padding: 0px">
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFrom style="z-index: -999;"></mat-datepicker>
                  </div>
                </div>
              </div>
              <div class="col-6" style="padding-left: 0px;">
                <div class="row">
                  <label class="offset-1 col-2 col-form-label col-form-label-sm inputLabel">To</label>
                  <input class="col-7 form-control form-control-sm rounded-form-fields" [matDatepicker]="pickerTo"
                    #datepickerTo type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="data.dateRange.toDate" id="toDate">
                  <div class="col-1" style="padding: 0px">
                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerTo style="z-index: -999;"></mat-datepicker>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <label class="col-12 p-0">
                Note: Enter Due Date in the following format MM-DD-YYYY
              </label>
            </div>
            <div class="row" *ngIf="checkErrorInFromDate(datepickerFrom.value).length>0">
              <label class="col-12 error">
                *{{fromDateError}}
              </label>
            </div>
            <div class="row" *ngIf="checkErrorInToDate(datepickerTo.value).length>0">
              <label class="col-12 error">
                *{{toDateError}}
              </label>
            </div>
          </div>
        </div>
    </div>
    <div class="dialog-actions">
        <button type="button" class="custom-buttons" (click)="search()" cdkFocusInitial>Select</button>
        <button type="button" class="custom-buttons" (click)="cancel()">Cancel</button>
    </div>
  </app-dialog>
