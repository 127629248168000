var AuthGuard = /** @class */ (function () {
    function AuthGuard(router) {
        this.router = router;
        this.parseJwt = function (token) {
            try {
                return JSON.parse(atob(token.split('.')[1]));
            }
            catch (e) {
                return null;
            }
        };
    }
    AuthGuard.prototype.canActivate = function () {
        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
        this.token = sessionStorage.getItem('token');
        if (this.token && this.token.length > 0) {
            var parsedToken = this.parseJwt(this.token);
            if (Date.now() >= parsedToken.exp * 1000) {
                this.router.navigate(['login/login-form']);
                return false;
            }
            if (this.userDetails.isForcePasswordChanged &&
                !this.router.url.includes('login/login-form') &&
                !this.router.url.includes('login/force-change-password')) {
                // this.router.navigate(["login/force-change-password"]);
                return false;
            }
            else if (this.userDetails.isForcePasswordChanged &&
                this.router.url.includes('login/force-change-password')) {
                return false;
            }
            else if (!this.userDetails.isPreferenceSet &&
                this.router.url.toLowerCase().includes('preference')) {
                return false;
            }
            return true;
        }
        else {
            var url = new URL(window.location.href);
            var params = new URLSearchParams(url.search);
            if (params.has('asp-au') && params.has('asp-ap')) {
                this.router.navigate(['login/login-form'], { queryParams: { 'asp-au': params.get('asp-au'), 'asp-ap': params.get('asp-ap') } });
            }
            else {
                this.router.navigate(['login/login-form']);
            }
        }
    };
    return AuthGuard;
}());
export { AuthGuard };
