import { OnInit } from '@angular/core';
var BackflowComponent = /** @class */ (function () {
    function BackflowComponent() {
    }
    BackflowComponent.prototype.ngOnInit = function () {
    };
    BackflowComponent.prototype.findQuestionId = function (answerType2, questionType, question, inputType) {
        var condition = this.questions.filter(function (x) { return x.questions.answerType2 == answerType2 &&
            x.questions.questionType == questionType &&
            x.questions.question == question; });
        if (condition && condition[0]) {
            // checkbox type - critical
            if (inputType === 'checkbox' && this.qtd[condition[0].questions.questionId]
                && (this.qtd[condition[0].questions.questionId][0] === null
                    || this.qtd[condition[0].questions.questionId][0] === "false")) {
                this.qtd[condition[0].questions.questionId] = false;
            }
            else if (inputType === 'checkbox' && this.qtd[condition[0].questions.questionId]
                && this.qtd[condition[0].questions.questionId][0] === "true") {
                this.qtd[condition[0].questions.questionId] = true;
            }
            if (inputType === 'radio') {
                if (!this.qtd[condition[0].questions.questionId]) {
                    this.qtd[condition[0].questions.questionId] = null;
                }
                else if (Array.isArray(this.qtd[condition[0].questions.questionId])) {
                    this.qtd[condition[0].questions.questionId] = this.qtd[condition[0].questions.questionId][0];
                }
            }
            return condition[0].questions.questionId;
        }
        return 0;
    };
    return BackflowComponent;
}());
export { BackflowComponent };
