import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

import { LoginComponent } from './login.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component';
import { ForceChangePasswordComponent } from './force-change-password/force-change-password.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    children: [
      {
        path: 'login-form',
        component: LoginFormComponent
      },
      {
        path: '',
        redirectTo: 'login-form',
        pathMatch: 'full'
      }
    ]
  },
  {
    path : 'signUp',
    component: SignUpFormComponent
  },
  {
    path: 'login/force-change-password',
    component: ForceChangePasswordComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule {}
