/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../clean-agent/electric-devices/electric-devices.component.ngfactory";
import * as i2 from "../clean-agent/electric-devices/electric-devices.component";
import * as i3 from "../clean-agent/other-devices/other-devices.component.ngfactory";
import * as i4 from "../clean-agent/other-devices/other-devices.component";
import * as i5 from "./fire-communication/fire-communication.component.ngfactory";
import * as i6 from "./fire-communication/fire-communication.component";
import * as i7 from "./fire-alarm.component";
var styles_FireAlarmComponent = [];
var RenderType_FireAlarmComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FireAlarmComponent, data: {} });
export { RenderType_FireAlarmComponent as RenderType_FireAlarmComponent };
export function View_FireAlarmComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-electric-devices", [], null, null, null, i1.View_ElectricDevicesComponent_0, i1.RenderType_ElectricDevicesComponent)), i0.ɵdid(1, 638976, null, 0, i2.ElectricDevicesComponent, [], { questions: [0, "questions"], qtd: [1, "qtd"], reviewInspection: [2, "reviewInspection"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-other-devices", [], null, null, null, i3.View_OtherDevicesComponent_0, i3.RenderType_OtherDevicesComponent)), i0.ɵdid(3, 638976, null, 0, i4.OtherDevicesComponent, [], { questions: [0, "questions"], qtd: [1, "qtd"], reviewInspection: [2, "reviewInspection"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-fire-communication", [], null, null, null, i5.View_FireCommunicationComponent_0, i5.RenderType_FireCommunicationComponent)), i0.ɵdid(5, 638976, null, 0, i6.FireCommunicationComponent, [], { questions: [0, "questions"], qtd: [1, "qtd"], reviewInspection: [2, "reviewInspection"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.questions; var currVal_1 = _co.qtd; var currVal_2 = _co.reviewInspection; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.questions; var currVal_4 = _co.qtd; var currVal_5 = _co.reviewInspection; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.questions; var currVal_7 = _co.qtd; var currVal_8 = _co.reviewInspection; _ck(_v, 5, 0, currVal_6, currVal_7, currVal_8); }, null); }
export function View_FireAlarmComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-fire-alarm", [], null, null, null, View_FireAlarmComponent_0, RenderType_FireAlarmComponent)), i0.ɵdid(1, 638976, null, 0, i7.FireAlarmComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FireAlarmComponentNgFactory = i0.ɵccf("app-fire-alarm", i7.FireAlarmComponent, View_FireAlarmComponent_Host_0, { questions: "questions", qtd: "qtd", reviewInspection: "reviewInspection" }, {}, []);
export { FireAlarmComponentNgFactory as FireAlarmComponentNgFactory };
