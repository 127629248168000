<app-dialog>
    <span class="dialog-title" >
      File Cabinet History
      <span class="float-right">
        <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
      </span>
    </span>
    <div class="dialog-content">
    <table class="def-table">
        <tr class="table-head">
          <th> Name </th>
          <th> Address</th>
          <th> Access Code</th>
        </tr>
        <tr *ngFor="let Property of history">
          <td>
            <label class="tableLabel" style="cursor: pointer;"(click)="searchInFileCabinet(Property)">{{Property.propertyName}}</label>
          </td>
          <td >
            <label class="tableLabel" style="cursor: pointer;" (click)="searchInFileCabinet(Property)" [innerHTML]="Property.address || ''"></label>
          </td>
          <td>
            <label class="tableLabel" style="cursor: pointer;" (click)="searchInFileCabinet(Property)">{{Property.accessCode}}</label>
          </td>
        </tr>
      </table>
  </div>

  <div class="dialog-actions">
      <button class="custom-buttons" (click)="close()">Close</button>

  </div>
</app-dialog>
