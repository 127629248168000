<app-dialog>
  <span class="dialog-title">
    {{data.manager === true ? 'MANAGER ADDRESS' : 'PROPERTY ADDRESS'}}
    <span class="float-right">
      <mat-icon class="cursor-pointer" (click)="cancel()">close</mat-icon>
    </span>
  </span>
  <div class="dialog-content">
      <label class="col-form-label col-form-label-sm inputLabel notes-sub-heading">
          Here's the full address, using standard abbreviations and formatting...
      </label>
    <div class="form-group form-group-padding ">
      <label class="col-form-label col-form-label-sm inputLabel notes-sub-heading">
        {{data.streetLine1}}
      </label>
      <label class="col-form-label col-form-label-sm inputLabel notes-sub-heading" *ngIf="data.streetLine2 && data.streetLine2!='null' && data.streetLine2!='NULL'">
        , {{data.streetLine2}}
      </label>
      <label class="col-form-label col-form-label-sm inputLabel notes-sub-heading">
        , {{data.city}}, {{data.state}}, {{data.zipcode}}
      </label>
      <label class="col-form-label col-form-label-sm inputLabel notes-sub-heading"
      *ngIf="data.subzipcode && data.subzipcode!='null' && data.subzipcode!='NULL'">
        -{{data.subzipcode}}
      </label>
    </div>
  </div>
  <div class="dialog-actions" >
    <button class="custom-buttons" (click)="save()" cdkFocusInitial>Continue</button>
    <button class="custom-buttons" (click)="cancel()">Cancel</button>
  </div>
</app-dialog>
