import * as tslib_1 from "tslib";
import { MatDialogRef, MatPaginator, MatTableDataSource, PageEvent } from '@angular/material';
import { ParentComponent } from '../../shared/components/parent.component';
var ChangeOwnerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ChangeOwnerComponent, _super);
    function ChangeOwnerComponent(dialogRef, data, fileCabService) {
        var _this = _super.call(this) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.fileCabService = fileCabService;
        _this.dataSource = new MatTableDataSource([]);
        _this.columnsToDisplay = ['blank', 'name', 'address'];
        _this.searchText = '';
        _this.unsub = [];
        _this.limit = 10;
        _this.offset = 0;
        return _this;
    }
    ChangeOwnerComponent.prototype.ngAfterViewInit = function () {
        this.search();
    };
    ChangeOwnerComponent.prototype.search = function () {
        var _this = this;
        this.unsub.push(this.fileCabService.getAllOwners(this.limit, this.offset, this.searchText).subscribe(function (res) {
            var _a, e_1, _b;
            _this.owners = res;
            _this.owners = res.contactList;
            _this.paginator.length = res.count;
            _this.dataSource.paginator = _this.paginator;
            _this.dataSource.data = new Array(res.count);
            (_a = _this.dataSource.data).splice.apply(_a, tslib_1.__spread([_this.offset,
                _this.limit], res.contactList));
            _this.dataSource._updateChangeSubscription();
            try {
                for (var _c = tslib_1.__values(_this.owners), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var owner = _d.value;
                    owner.name = owner.firstName + ' ' + owner.lastName;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }));
    };
    ChangeOwnerComponent.prototype.onPaginateChange = function (event) {
        this.offset = event.pageIndex * event.pageSize;
        this.limit = event.pageSize;
        this.search();
    };
    ChangeOwnerComponent.prototype.resetPagination = function () {
        this.offset = 0;
        this.limit = this.environment.pageSize || 10;
        if (this.paginator) {
            this.paginator.pageIndex = 0;
        }
    };
    ChangeOwnerComponent.prototype.removeOwner = function () {
        var _this = this;
        this.unsub.push(this.fileCabService.changeOwner(this.data.id, -1).subscribe(function (res) { _this.dialogRef.close(null); }, function (error) { alert(error.error.message); }));
    };
    ChangeOwnerComponent.prototype.selectOwner = function () {
        var _this = this;
        if (this.selectedOwnerId) {
            if (this.data) {
                this.unsub.push(this.fileCabService.changeOwner(this.data.id, this.selectedOwnerId).subscribe(function (res) { _this.dialogRef.close(null); }, function (error) { alert(error.error.message); }));
            }
            else {
                this.fileCabService.setOwnerDetails(this.dataSource.data.find(function (x) { return x.id === _this.selectedOwnerId; }));
                this.dialogRef.close(null);
            }
        }
    };
    ChangeOwnerComponent.prototype.ngOnDestroy = function () {
        this.unsub.map(function (elem) { return elem.unsubscribe(); });
    };
    ChangeOwnerComponent.prototype.close = function () {
        this.dialogRef.close(null);
    };
    return ChangeOwnerComponent;
}(ParentComponent));
export { ChangeOwnerComponent };
