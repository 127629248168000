import { OnDestroy, OnInit } from "@angular/core";
var SignUpFormComponent = /** @class */ (function () {
    // user
    function SignUpFormComponent(fb, signUpService, propertyService, router) {
        this.fb = fb;
        this.signUpService = signUpService;
        this.propertyService = propertyService;
        this.router = router;
        this.subscriptions = [];
    }
    SignUpFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.buttonDisabled = false;
        this.phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        this.formValidity = true;
        this.resetErrors();
        this.recaptchaVal = "";
        this.subscriptions.push(this.signUpService.getSignUpRoles().subscribe(function (res) {
            res = res.filter(function (x) { return x.name !== "ROLE_RFA"; });
            _this.signUpRoles = res;
        }));
        this.signUpDetails = {
            firstName: null,
            lastName: null,
            email: null,
            cotactNumber: null,
            role: null,
            name: null,
            streetLine1: null,
            streetLine2: null,
            zipcode: null,
            jurisdiction: null,
        };
        this.signUpForm = this.fb.group({
            firstName: [""],
            lastName: [""],
            email: [""],
            phone: [""],
            role: [""],
            orgName: [""],
            Streetline1: [""],
            Streetline2: [""],
            Zipcode: [""],
            JurisdictionName: [""],
            tncCheck: [""],
        });
        this.role = "";
    };
    SignUpFormComponent.prototype.checkRole = function (val) {
        this.role = val;
    };
    SignUpFormComponent.prototype.resetAll = function () {
        this.resetErrors();
    };
    SignUpFormComponent.prototype.signUpNewUser = function (val) {
        this.validateForm(val);
    };
    SignUpFormComponent.prototype.signUp = function (val) {
        var _this = this;
        if (this.formValidity) {
            this.buttonDisabled = true;
            var obj = this.signUpRoles.find(function (item) {
                return item.id === parseInt(_this.signUpForm.value.role, 10);
            });
            this.signUpDetails = {
                firstName: this.signUpForm.value.firstName,
                lastName: this.signUpForm.value.lastName,
                email: this.signUpForm.value.email,
                cotactNumber: this.signUpForm.value.phone,
                name: this.signUpForm.value.orgName,
                role: {
                    id: parseInt(this.signUpForm.value.role, 10),
                    name: this.signUpRoles.find(function (item) {
                        return item.id === parseInt(_this.signUpForm.value.role, 10);
                    }).name,
                    description: this.signUpRoles.find(function (item) {
                        return item.id === parseInt(_this.signUpForm.value.role, 10);
                    }).description,
                },
                streetLine1: this.signUpForm.value.Streetline1,
                streetLine2: this.signUpForm.value.Streetline2,
                zipcode: this.signUpForm.value.Zipcode,
            };
            this.signUpDetails.cotactNumber = this.signUpDetails.cotactNumber.replace(this.phoneRegex, "$1-$2-$3");
            if (this.signUpForm.value.role === "3" ||
                this.signUpForm.value.role === "4") {
                this.signUpDetails.name = this.signUpForm.value.orgName;
            }
            else {
                this.signUpDetails.name = null;
            }
            if (this.signUpForm.value.role === "3") {
                var jusristiction = {
                    id: null,
                    name: this.signUpForm.value.JurisdictionName,
                    status: 0,
                };
                this.signUpDetails.jurisdiction = jusristiction;
            }
            else {
                this.signUpDetails.jurisdiction = null;
            }
            // trim all spaces that are of type string
            Object.keys(this.signUpDetails).map(function (prop) {
                return (_this.signUpDetails[prop] =
                    typeof _this.signUpDetails[prop] === "string"
                        ? _this.signUpDetails[prop].trim()
                        : _this.signUpDetails[prop]);
            });
            this.subscriptions.push(this.signUpService.postSignUpDetails(this.signUpDetails).subscribe(function (res) {
                _this.buttonDisabled = false;
                alert("An email has been sent to " + _this.signUpDetails.email);
                _this.router.navigate(["login/login-form"]);
            }, function (error) {
                _this.buttonDisabled = false;
                if (error.status === 400) {
                    alert(error.error.message);
                    _this.buttonDisabled = false;
                }
                else {
                    alert("Unexpected error occured");
                    _this.buttonDisabled = false;
                }
            }));
        }
        else {
            // set focus to input
        }
    };
    SignUpFormComponent.prototype.resetErrors = function () {
        this.errors = {
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            role: null,
            orgName: null,
            Streetline1: null,
            Zipcode: null,
            JurisdictionName: null,
            tncCheck: null,
            recaptcha: null,
        };
    };
    SignUpFormComponent.prototype.validateForm = function (val) {
        var _this = this;
        this.resetErrors();
        this.formValidity = true;
        //  check form validity and set it accordingly
        this.validateRecaptcha();
        this.validateFirstName();
        this.validateLastName();
        this.validateEmail();
        this.validatePhoneNumber();
        this.validateRole();
        if (this.signUpForm.value.role === "4" ||
            this.signUpForm.value.role === "3") {
            this.validateOrgName();
        }
        if (this.signUpForm.value.role !== "") {
            this.validateStLine1();
            this.validateZip();
            if (this.signUpForm.value.role === "3") {
                this.validateJurisdiction();
            }
        }
        if (!this.signUpForm.value.tncCheck) {
            this.errors.tncCheck = "Please accept Terms & Conditions of TEGRIS";
            this.formValidity = false;
        }
        if (/^([0-9]{5})$/.test(this.signUpForm.value.Zipcode)) {
            this.subscriptions.push(this.propertyService
                .getJurisdictionDetailsFromZipcode(this.signUpForm.value.Zipcode)
                .subscribe(function (res) {
                _this.zipcodeMappingDetails = res;
                if (!_this.zipcodeMappingDetails) {
                    alert("Zipcode does not exist. Please contact support@tegrisfire.com");
                    return;
                }
                _this.signUp(val);
            }, function (error) {
                console.log(error);
            }));
        }
    };
    SignUpFormComponent.prototype.validateFirstName = function () {
        var firstName = this.signUpForm.value.firstName;
        if (!firstName || firstName.length === 0) {
            this.errors.firstName = "Enter First Name of the user";
            this.formValidity = false;
        }
    };
    SignUpFormComponent.prototype.validateLastName = function () {
        var lastName = this.signUpForm.value.lastName;
        if (!lastName || lastName.length === 0) {
            this.errors.lastName = "Enter Last Name of the user";
            this.formValidity = false;
        }
    };
    SignUpFormComponent.prototype.validateEmail = function () {
        var email = this.signUpForm.value.email;
        if (!email || email.length === 0) {
            this.errors.email = "Please enter Email address";
            this.formValidity = false;
        }
        else if (!/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(email)) {
            this.errors.email = "Enter a valid email address like abc@gmail.com";
            this.formValidity = false;
            document.getElementById('email').focus();
        }
    };
    SignUpFormComponent.prototype.validatePhoneNumber = function () {
        var phone = this.signUpForm.value.phone;
        if (!phone || phone.length === 0) {
            this.errors.phone = "Enter Phone Number of the user";
            this.formValidity = false;
        }
        else if (!/^([0-9]{3}[-. ]?[0-9]{3}[-. ]?[0-9]{4})$/.test(phone)) {
            this.errors.phone = "Enter valid Phone Number";
            this.formValidity = false;
        }
    };
    SignUpFormComponent.prototype.validateRole = function () {
        var role = this.signUpForm.value.role;
        if (role === "") {
            this.errors.role = "Select one role for the user";
            this.formValidity = false;
        }
    };
    SignUpFormComponent.prototype.validateOrgName = function () {
        var orgName = this.signUpForm.value.orgName;
        if (!orgName || orgName.length === 0) {
            if (this.signUpForm.value.role === "3") {
                this.errors.orgName = "Enter name of the Authority";
            }
            else if (this.signUpForm.value.role === "4") {
                this.errors.orgName = "Enter Company Name for Inspector";
            }
            this.formValidity = false;
        }
    };
    SignUpFormComponent.prototype.validateStLine1 = function () {
        var stline1 = this.signUpForm.value.Streetline1;
        if (!stline1 || stline1.length === 0) {
            this.errors.Streetline1 = "Enter Street Line 1 details of the address";
            this.formValidity = false;
        }
    };
    SignUpFormComponent.prototype.validateZip = function () {
        var zip = this.signUpForm.value.Zipcode;
        if (!zip || zip.length === 0) {
            this.errors.Zipcode = "Enter Zip Code for the address";
            this.formValidity = false;
        }
        else if (!/^([0-9]{5})$/.test(zip)) {
            this.errors.Zipcode =
                "There is some error validating zip code. Please try again";
            this.formValidity = false;
        }
    };
    SignUpFormComponent.prototype.validateJurisdiction = function () {
        var jurisdiction = this.signUpForm.value.JurisdictionName;
        if (!jurisdiction || jurisdiction.length === 0) {
            this.errors.JurisdictionName = "Enter Jurisdiction Name for Authority";
            this.formValidity = false;
            // show error on screen
            return true;
        }
        else if (!/^[A-Z]{2}, [a-zA-Z0-9$&+_,:;=?@#|'<>.^*()%!-]+( [a-zA-Z0-9$&+_,:;=?@#|'<>.^*()%!-]+)*$/.test(jurisdiction)) {
            this.errors.JurisdictionName =
                "Incorrect Format. Please enter the jurisdiction in following format.";
            this.formValidity = false;
            // show error on screen
            return true;
        }
        // do not show error on screen
        return false;
    };
    SignUpFormComponent.prototype.validateRecaptcha = function () {
        if (!this.recaptchaVal || this.recaptchaVal.length == 0) {
            this.errors.recaptcha = "Enter Captcha value";
            this.formValidity = false;
        }
    };
    SignUpFormComponent.prototype.backToLogin = function () {
        this.router.navigate(["login/login-form"]);
    };
    SignUpFormComponent.prototype.resolved = function (captchaResponse) { };
    SignUpFormComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (x) {
            x.unsubscribe();
        });
    };
    return SignUpFormComponent;
}());
export { SignUpFormComponent };
