import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef , MAT_DIALOG_DATA} from '@angular/material';
import { LoginService } from '../../services/login.service';

export interface DialogData {
  email: string;
}

@Component({
  selector: 'app-email-sent-dialog',
  templateUrl: './email-sent-dialog.component.html',
  styleUrls: ['./email-sent-dialog.component.scss']
})
export class EmailSentDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EmailSentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private loginService: LoginService) { }

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }
}
