import { AfterViewInit, OnInit } from '@angular/core';
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(router) {
        this.router = router;
    }
    DashboardComponent.prototype.ngOnInit = function () {
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
            // todo operlay
            //   $('#dismiss, .overlay').on('click', function () {
            //     $('#sidebar').removeClass('active');
            //     $('.overlay').removeClass('active');
            // });
            //   $('#sidebarCollapse').on('click', function () {
            //     $('#sidebar').toggleClass('active');
            //     $('.overlay').addClass('active');
            //     $('.collapse.in').toggleClass('in');
            //     $('a[aria-expanded=true]').attr('aria-expanded', 'false');
            // });
        });
    };
    DashboardComponent.prototype.ngAfterViewInit = function () {
        // this.user = { name: 'Ms. Kathy', role: Roles.ROLE_OWNER_ADMIN };
        // if (this.user.role === Roles.ROLE_OWNER_ADMIN) {
        //   this.router.navigate(['user/property']);
        // }
    };
    return DashboardComponent;
}());
export { DashboardComponent };
