import { MatDialogRef } from '@angular/material/dialog';
var DelSystemDialog = /** @class */ (function () {
    function DelSystemDialog(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    DelSystemDialog.prototype.ngOnInit = function () {
    };
    DelSystemDialog.prototype.deletionRejected = function () {
        this.dialogRef.close(false);
    };
    DelSystemDialog.prototype.deletionApproved = function () {
        this.dialogRef.close(true);
    };
    return DelSystemDialog;
}());
export { DelSystemDialog };
