import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IOwnerSnapshot } from '../../owner/shared/owner-details/owner-details.component';
import { OwnerCity, Owner, SearchInspectorProperties, OwnerProperty } from '../models/owner.model';

@Injectable({
  providedIn: 'root'
})
export class OwnerService {
  baseUrl = environment.baseUrl;
  enableEditMode = new BehaviorSubject(false);
  ownerPreferences = new BehaviorSubject<Owner>(null);
  saveBasicDetails = new BehaviorSubject(false);
  savedContacts = new BehaviorSubject(null);
  ownerTemp = new BehaviorSubject<Owner>(null);
  formInvalid = new BehaviorSubject(false);
  dashboardProperties = new BehaviorSubject<any>(null);
  ownerProperties = new BehaviorSubject<any>(null);
  preferencesUpdated = new BehaviorSubject<boolean>(null);
  ownerSnaphot: IOwnerSnapshot;
  constructor(private http: HttpClient) { }
  public getOwnerPreferences() {
    const url = this.baseUrl + `/owner/preferences`;
    return this.http.get<Owner>(url);
  }
  public putOwnerPreferences(ownerId: number, owner: Owner) {
    const url = this.baseUrl + `/owner/preferences`;
    return this.http.put<Owner>(url, owner);
  }
  public getAllProperties(params) {
    const url = this.baseUrl + `/properties`;
    return this.http.post<any>(url, params);
  }
  public getOwnerDashboard(ownerId: number) {
    const url = this.baseUrl + `/owner/dashboard/myproperties`;
    return this.http.get<any>(url);
  }
  public getOwnerStatistics() {
    const url = this.baseUrl + `/owner/dashboard/statistics`;
    return this.http.get<any>(url);
  }
  public exportToCsv() {
    const url = this.baseUrl + `/owner/dashboard/myproperties/download`;
    return this.http.get<any>(url);
  }
  public getBidInformationByPropertyId(propertyId: number) {
    const url = this.baseUrl + `/bid/property/${propertyId}/information `;
    return this.http.get<any>(url);
  }
  public getInspectorInformationByPropertyId(propertyId: number) {
    const url = this.baseUrl + `/bids/properties/${propertyId}/inspectors`;
    return this.http.get<any>(url);
  }
  public sendBidInformationByPropertyId(body) {
    const url = this.baseUrl + `/bid`;
    return this.http.post<any>(url, body);
  }
  public sendInspectorInformationBybidId(bidId, body) {
    const url = this.baseUrl + `/bid/${bidId}/notifiedinspectors`;
    return this.http.post<any>(url, body);
  }
  public getInspectionResultIdByInspectionId(inspectionId) {
    const url = this.baseUrl + `/inspection/${inspectionId}/inspectionresult `;
    return this.http.get<any>(url);
  }
  public getAllBids() {
    const url = this.baseUrl + `/bids/properties`;
    return this.http.get<any>(url);

  }
  public fetchUserName(id) {
    const url = this.baseUrl + `/user/details/${id} `;
    return this.http.get<any>(url);
  }
  public getOwnerCities() {
    const url = this.baseUrl + `/owner/search`;
    return this.http.get<Array<OwnerCity>>(url);
  }

  public getInspectors() {
    // const data = { stateCode };
    const url = this.baseUrl + `/owner/inspectorList`;
    return this.http.get<Array<SearchInspectorProperties>>(url);
  }

  public assignProperty(pac, id) {
    const url = this.baseUrl + `/property/assign`;
    return this.http.post<any>(url, {
      accessCode: pac,
      id: id
    });
  }

  public setPreference() {
    const url = this.baseUrl + `/setPreference`;
    return this.http.put(url, {});
  }
}
