<app-dialog>
  <span class="dialog-title">
    Password Generated
    <span class="float-right">
      <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
    </span>
  </span>
  <div class="dialog-content">
    <div style="margin:20px">
      <div class="form-group row form-group-padding custom-form-control">
        <div class="col-12"><label class="col-form-label col-form-label-sm inputLabel" style="font-size: 18px;">
            Your new auto generated password has been sent to your email address: {{this.data.email}}</label></div>

      </div>
    </div>

  </div>
  <div class="dialog-actions">
    <button class="custom-buttons" cdkFocusInitial (click)="close()">OK</button>
  </div>
</app-dialog>
