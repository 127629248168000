import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialogRef } from '@angular/material';
import { ParentComponent } from '../../../shared/components/parent.component';
import { catchError } from 'rxjs/operators';
var SearchPropertyComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SearchPropertyComponent, _super);
    function SearchPropertyComponent(dialogRef, data, propertyService, adminService, loggerService) {
        var _this = _super.call(this) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.propertyService = propertyService;
        _this.adminService = adminService;
        _this.loggerService = loggerService;
        _this.dataSource = new MatTableDataSource([]);
        _this.columnsToDisplay = ['blank', 'name', 'address'];
        _this.searchText = '';
        _this.selectedPropertyId = -1;
        return _this;
    }
    SearchPropertyComponent.prototype.ngOnInit = function () {
    };
    SearchPropertyComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.propertyList = [];
        this.unsub.push(this.propertyService.getPropertyListByJurisdictionId(this.data.userDetailsByRoleId).subscribe(function (res) {
            _this.propertyList = res;
            _this.dataSource.paginator = _this.paginator;
            _this.dataSource.data = _this.propertyList;
        }, function (error) { _this.loggerService.log(error); }));
    };
    SearchPropertyComponent.prototype.ngOnDestroy = function () {
        this.unsub.map(function (elem) { return elem.unsubscribe(); });
    };
    SearchPropertyComponent.prototype.searchByPropName = function () {
        var _this = this;
        var filteredProperties = this.propertyList.filter(function (property) {
            return (property.propertyName || '').toLowerCase().includes(_this.searchText.toLowerCase());
        });
        this.dataSource.data = filteredProperties;
    };
    SearchPropertyComponent.prototype.selectProperty = function (propertyId) {
        this.selectedPropertyId = propertyId;
    };
    SearchPropertyComponent.prototype.previewSamplePDF = function () {
        var _this = this;
        if (this.selectedPropertyId !== -1) {
            var requestBody = tslib_1.__assign({}, this.data);
            this.unsub.push(this.adminService.getTemplatePreview(requestBody, this.selectedPropertyId).subscribe(function (res) {
                window.open(res.data, '_blank');
                setTimeout(function () {
                    _this.unsub.push(_this.adminService.deleteTemplate(res.data)
                        .subscribe(function (res) {
                        // Do nothing
                    }));
                }, 10000);
            }, catchError(function (error) {
                if (error && error.error && error.error.message) {
                    _this.loggerService.log(error.error.message);
                }
                return [];
            })));
        }
    };
    SearchPropertyComponent.prototype.close = function () {
        this.dialogRef.close(null);
    };
    return SearchPropertyComponent;
}(ParentComponent));
export { SearchPropertyComponent };
