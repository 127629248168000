import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-view-report-dialog',
  templateUrl: './view-report-dialog.component.html',
  styleUrls: []
})
export class ViewReportDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ViewReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.data.dialogRef = this.dialogRef;
  }

  cancel(): void {
    this.dialogRef.close('cancelled');
  }

}
