<app-dialog>
    <span class="dialog-title">
        {{data.title}}
        <span class="float-right">
          <mat-icon class="cursor-pointer" (click)="onClose(false)">close</mat-icon>
        </span>
    </span>
    <div class="dialog-content">
        <div class="row" *ngIf="data.name">
            <label class="col-5 col-form-label col-form-label-sm inputLabel">
                Requestor:
            </label>
            <label class="col-7">
                {{data.name}}
            </label>
        </div>
        <div class="row" *ngIf="data.email">
            <label class="col-5 col-form-label col-form-label-sm inputLabel">
                Email:
            </label>
            <label class="col-7">
                {{data.email}}
            </label>
        </div>
        <div class="row">
            <label class="col-5 col-form-label col-form-label-sm inputLabel">
                Comments:
            </label>
            <textarea class="col-7" rows="4" cols="250" maxlength="1000"
                [(ngModel)]="comments"></textarea>
        </div>
    </div>
    <div class="dialog-actions">
        <button type="button" class="custom-buttons" (click)="onClose(true)">Submit</button>
        <button type="button" class="custom-buttons" (click)="onClose(false)">Close</button>
    </div>
</app-dialog>
