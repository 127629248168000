import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Roles } from '../../../shared/models/user.model';
@Component({
  selector: 'app-usps-valid-dialog',
  templateUrl: './usps-popUp-invalid.component.html',
  styleUrls: ['./usps-popUp-invalid.component.scss']
})
export class USPSInvalidPopUpComponent implements OnInit {

  user = null;
  constructor(
    public dialogRef: MatDialogRef<USPSInvalidPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.user =  JSON.parse(localStorage.getItem('userDetails'));

  }

  abort() {
    this.dialogRef.close('abort');
  }

  tryAgain(): void {
    this.dialogRef.close('try again');
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

  continue() {
    this.dialogRef.close('continue');

  }

  checkAccess(){

    console.log(this.user.role);
    

    if(this.user.role['authority'] ==='ROLE_SITE_ADMIN'  
    || this.user.role['authority'] ==='ROLE_AUTHORITY' 
    || this.user.role['id'] === Roles.ROLE_RFA 
    || this.user.role['id'] === Roles.ROLE_AUTHORITY_WORX ){
      return true;
    } else 
      return false;
  } 

}
