<app-dialog>
  <span class="dialog-title">
    Assignment
    <span class="float-right">
      <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
    </span>
  </span>

  <div class="dialog-content">
    <div class="row">
      <div dialog-content class="notes-sub-heading col-3">Assign To: </div>
      <div class="col-7">
        <select class="col-11 col-form-label col-form-label-sm rounded-form-fields no-Padding custom-select"
        [(ngModel)]="selectedUser" >
          <option value="">Select</option>
          <option *ngFor="let AssignedUser of data.users" [value]="AssignedUser.id">
            {{AssignedUser.data}}
          </option>
        </select>
      </div>
    </div>
    <div class="row form-group-padding" *ngIf="selectedUser !== ''">
      <div class="notes-sub-heading col-12">
        <label>
          The assignments for all of the properties that were returned from your search will be changed.
        </label>
      </div>
    </div>
  </div>
  

  <div mat-dialog-action class="dialog-actions">

    <div class="form-group row form-group-padding actions" style="justify-content: center">
      <button class="custom-buttons" (click)="assignProperty()">Assign</button>
      <button class="custom-buttons" (click)="close()">Cancel</button>
    </div>

  </div>

</app-dialog>
