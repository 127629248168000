import { Component, OnInit, Input } from '@angular/core';
import { HeadingQuestion } from '../../../core/models/inspection/inspection.model';

@Component({
  selector: 'app-clean-agent',
  templateUrl: './clean-agent.component.html',
  styleUrls: []
})
export class CleanAgentComponent implements OnInit {
  @Input() questions: Array<HeadingQuestion>;
  @Input() qtd: Array<any>;
  @Input() reviewInspection: boolean;
  constructor() { }

  ngOnInit() {
  }
}
