import * as tslib_1 from "tslib";
import { environment } from '../../../environments/environment';
import { paymentStatic } from '../models/paymentStatic';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PaymentService = /** @class */ (function () {
    function PaymentService(http) {
        this.http = http;
        this.baseUrl = environment.baseUrl;
        this.updateTrigger = new BehaviorSubject(null);
    }
    PaymentService.prototype.generateRequestSignature = function (body, fromInspection) {
        var url = this.baseUrl + "/generateRequestSignature";
        var fullBody = tslib_1.__assign({}, paymentStatic, { retunUrl: window.location.href }, body);
        if (fromInspection) {
            fullBody.retunUrl = fromInspection;
        }
        return this.http.post(url, fullBody);
    };
    PaymentService.prototype.paymentConfirmation = function (body) {
        delete body["status"];
        var url = this.baseUrl + "/returnUrl";
        return this.http.put(url, body);
    };
    PaymentService.prototype.creditSub = function () {
        return this.updateTrigger.pipe(filter(function (res) { return res; }));
    };
    PaymentService.prototype.creditSubUpdate = function (a) {
        this.updateTrigger.next(a);
    };
    PaymentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentService_Factory() { return new PaymentService(i0.ɵɵinject(i1.HttpClient)); }, token: PaymentService, providedIn: "root" });
    return PaymentService;
}());
export { PaymentService };
