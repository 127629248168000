
import * as moment from 'moment';

export const frequencyValues = {
"Monthly":  moment().month(moment().month() + 1),
"Semi Annual":  moment().month( moment().month() + 6),
"Annually":  moment().month( moment().month() + 12),
"Biennial":  moment().month( moment().month() + 24),
"4 Years":  moment().month( moment().month() + 48),
"5 Years":  moment().month( moment().month() + 60),
"Quarterly":  moment().month( moment().month() + 3),
"18 Months":  moment().month( moment().month() + 18),
"3 Years":  moment().month( moment().month() + 36),
"10 Years":  moment().month( moment().month() + 120),
"15 Years":  moment().month( moment().month() + 180),
"20 Years":  moment().month( moment().month() + 240),
"4 Months":  moment().month( moment().month() + 4),
}

// const date = (n) => {
//     const a = new Date();
//     a.setDate(a.getDate() + n);
//     return a
// }