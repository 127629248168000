<app-dialog>
    <span class="dialog-title">
      <span class="float-right">
        <mat-icon class="cursor-pointer" (click)="onClick('no')">close</mat-icon>
      </span>
    </span>
  <div class="dialog-content">
    <div class="form-group row form-group-padding">
      <label style="padding-left: 10px" class="col-form-label col-form-label-sm inputLabel notes-sub-heading">
        Are you sure you want to change Jurisdiction Area for this property?

    </label>
    </div>
  </div>
  <div class="dialog-actions">
    <button type="button" class="custom-buttons" (click)="onClick('yes')" cdkFocusInitial>Yes</button>
    <button type="button" class="custom-buttons" (click)="onClick('no')">No</button>
  </div>
</app-dialog>
