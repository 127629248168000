import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from './user.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {



  constructor(
    private userService: UserService,
    private router: Router,
    private location: Location
  ) { }

  canActivate(): boolean {
    const a = this.userService.userDetails.role.name === 'ROLE_SITE_ADMIN';
    if (a) {
      return a;
    }
    this.location.back();
    return a;
    

  }
}
