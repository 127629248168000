<app-dialog>
  <span class="dialog-title">
    DELETE LETTERHEAD
    <span class="float-right">
      <mat-icon class="cursor-pointer" (click)="cancel()">close</mat-icon>
    </span>
  </span>
  <div class="dialog-content">
    <label class="col-form-label col-form-label-sm inputLabel notes-sub-heading">
      Selected template will be deleted. do you want to delete it?
    </label>
  </div>
  <div class="dialog-actions">
    <button class="custom-buttons" (click)="save()" cdkFocusInitial>Ok</button>
    <button class="custom-buttons" (click)="cancel()">Cancel</button>
  </div>
</app-dialog>
