import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var EmailComponent = /** @class */ (function () {
    function EmailComponent(dialogRef, data, inspectionReportService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.inspectionReportService = inspectionReportService;
    }
    EmailComponent.prototype.ngOnInit = function () {
        this.resetErrors();
        this.loading = false;
    };
    EmailComponent.prototype.resetErrors = function () {
        this.invalidToEmail = '';
        this.invalidCCEmail = '';
    };
    EmailComponent.prototype.send = function () {
        var _this = this;
        this.loading = true;
        this.resetErrors();
        this.invalidToEmail = this.validateEmail(this.data.to);
        this.invalidCCEmail = this.validateEmail(this.data.cc);
        this.invalidCCEmail = this.invalidCCEmail === 'Please enter Email address' ? '' : this.invalidCCEmail;
        if (this.invalidCCEmail.length === 0 || this.invalidToEmail.length === 0) {
            // const formData = new FormData();
            // formData.append('report', this.data.attachment);
            var body = {
                to: this.data.to,
                cc: this.data.cc,
                from: 'tegris.fire@nagarro.com',
                subject: this.data.subject,
                body: this.data.body
            };
            body.body = body.body.replace(/(?:\r\n|\r|\n)/g, '<br/>');
            this.inspectionReportService.mailPdf(this.data.inspectionResultId, body)
                .subscribe(function (res) {
                alert(res['1'][0]);
                // const msg = data[`message`];
                // alert(msg);
                // // console.log(data, "success");
                _this.loading = false;
            }, function (error) {
                console.error(error, 'error');
                _this.loading = false;
            });
        }
        else {
            this.loading = false;
        }
    };
    EmailComponent.prototype.validateEmail = function (email) {
        if (!email || email.length === 0) {
            return 'Please enter Email address';
        }
        else if (!/^([a-zA-Z0-9][a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,3}([ ]*;[ ]*[a-zA-Z0-9][a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,3})*)$/.
            test(email)) {
            return 'Enter a valid email address like abc@gmail.com';
        }
        return '';
    };
    EmailComponent.prototype.onClose = function () {
        this.dialogRef.close();
    };
    return EmailComponent;
}());
export { EmailComponent };
