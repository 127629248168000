<app-dialog>
    <span class="dialog-title">
        <div class="title">Payment Details</div>
        <span class="float-right">
          <mat-icon class="cursor-pointer" (click)="closeDialog()">close</mat-icon>
        </span>
    </span>
    <div class="dialog-content">
        <table mat-table #table multiTemplateDataRows [dataSource]="dataSource">

            <ng-container matColumnDef="blank">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon *ngIf="!element.expanded" class="custom-grid-icons" mat-list-icon
                        (click)="element.expanded = true">add_circle
                    </mat-icon>
                    <mat-icon *ngIf="element.expanded" class="custom-grid-icons" mat-list-icon
                        (click)="element.expanded = false">remove_circle
                    </mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="systemType">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="limitCellSize">
                    <p class="bold">System Type </p>
                    <p class="bold" style="margin-left: 1vw;">{{element.assetType.assetName}} </p>

                </td>
            </ng-container>

            <ng-container matColumnDef="location">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element" class="limitCellSize">
                    <p class="bold">Location </p>
                    <p class="bold" style="margin-left: 1vw;">{{element.assetLocation}} </p>



                </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail" style="background-color: #fbda6f">
                <td mat-cell *matCellDef="let element; let k = dataIndex;" [attr.colspan]="displayedColumns.length">
                    <div class="example-element-detail" *ngIf="element.expanded">
                        <div class="example-element-description">

                            <div *ngIf="element">
                                <div style="margin-bottom:15px; display: flex;  justify-content: flex-end;">

                                    <div style="display: flex; flex-direction: column;  width: 30vw; ">

                                        <div [class.mt-1]="k != 0" *ngFor="let i of element?.groupedPriceList; let k=index;">
                                            <label class="bold">{{i[0].heading}}</label>
                                            <hr>

                                            <div style="display: flex; justify-content: space-between; " *ngFor="let j of i">

                                                <p>{{j.description}}</p>
                                                <p class="bold"><span *ngIf="j.description !== 'Credits used'">$</span>{{j.value}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!element || (element && element.length === 0)">
                                <div class="noRecords">No Records Found</div>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
                [class.example-expanded-row]="element.expanded">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
    </div>
    <div class="dialog-actions">
        <button type="button" class="custom-buttons" (click)="closeDialog()">Close</button>
    </div>
</app-dialog>
