import * as i0 from "@angular/core";
import * as i1 from "./user.service";
import * as i2 from "@angular/common";
var OwnerGuard = /** @class */ (function () {
    function OwnerGuard(userService, location) {
        this.userService = userService;
        this.location = location;
    }
    OwnerGuard.prototype.canActivate = function () {
        var a = this.userService.userDetails.role.name === 'ROLE_OWNER';
        if (a) {
            return a;
        }
        this.location.back();
        return a;
    };
    OwnerGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OwnerGuard_Factory() { return new OwnerGuard(i0.ɵɵinject(i1.UserService), i0.ɵɵinject(i2.Location)); }, token: OwnerGuard, providedIn: "root" });
    return OwnerGuard;
}());
export { OwnerGuard };
