<table cellspacing="0" cellpadding="0" class="sp-top-table" width="100%" style="margin-bottom: 2%;">
  <tbody>
    <tr>
      <td class="header-td" colspan="5">BUILDING SPECIFICATIONS</td>
    </tr>
    <tr>
      <td width="46%">
        <span style="display: inline-block;margin:5px 0 0 10px;float:left">Number of stories</span>
        &nbsp;&nbsp;
        <input type="text" id="anserText_1314" style="width: 60px;float:right;margin-right:10px;" name="anserText_1314"
          [(ngModel)]="qtd[findQuestionId('Number of stories')]" [disabled]="reviewInspection">
      </td>
      <td width="27%" colspan="2">
        <span>Outside air temperature (F)</span> &nbsp;&nbsp;

        <input type="text" id="anserText_1315" style="width: 60px;" name="anserText_1315"
          [(ngModel)]="qtd[findQuestionId('Outside air temperature (F)')]" [disabled]="reviewInspection">
      </td>
    </tr>
    <tr>
      <td width="46%">
        <span style="display: inline-block;margin:5px 0 0 10px;float:left">Number of stair fans</span>
        &nbsp;&nbsp;
        <input type="text" id="anserText_1316" style="width: 60px;float:right;margin-right:10px;" name="anserText_1316"
          [(ngModel)]="qtd[findQuestionId('Number of stair fans')]" [disabled]="reviewInspection">
      </td>
      <td width="27%">
        <span>CFM</span> &nbsp;&nbsp;
        <input type="text" id="anserText_1317" style="width: 60px;" name="anserText_1317"
          [(ngModel)]="qtd[findQuestionId('CFM1')]" [disabled]="reviewInspection">
      </td>
      <td width="27%">
        <span>HP</span> &nbsp;&nbsp;
        <input type="text" id="anserText_1318" style="width: 60px;" name="anserText_1318"
          [(ngModel)]="qtd[findQuestionId('HP1')]" [disabled]="reviewInspection">
      </td>
    </tr>
    <tr>
      <td width="46%">
        <span style="display: inline-block;margin:5px 0 0 10px;float:left">Number of elevator fans</span>
        &nbsp;&nbsp;
        <input type="text" id="anserText_1319" style="width: 60px;float:right;margin-right:10px;" name="anserText_1319"
          [(ngModel)]="qtd[findQuestionId('Number of elevator fans')]" [disabled]="reviewInspection">
      </td>
      <td width="27%">
        <span>CFM</span> &nbsp;&nbsp;
        <input type="text" id="anserText_1320" style="width: 60px;" name="anserText_1320"
          [(ngModel)]="qtd[findQuestionId('CFM2')]" [disabled]="reviewInspection">
      </td>
      <td width="27%">
        <span>HP</span> &nbsp;&nbsp;
        <input type="text" id="anserText_1321" style="width: 60px;" name="anserText_1321"
          [(ngModel)]="qtd[findQuestionId('HP2')]" [disabled]="reviewInspection">
      </td>
    </tr>
    <tr>
      <td width="46%">
        <span style="display: inline-block;margin:5px 0 0 10px;float:left">Floor pressurization fans</span>
        &nbsp;&nbsp;
        <input type="text" id="anserText_1322" style="width: 60px;float:right;margin-right:10px;" name="anserText_1322"
          [(ngModel)]="qtd[findQuestionId('Floor pressurization fans')]" [disabled]="reviewInspection">
      </td>
      <td width="27%">
        <span>CFM</span> &nbsp;&nbsp;
        <input type="text" id="anserText_1323" style="width: 60px;" name="anserText_1323"
          [(ngModel)]="qtd[findQuestionId('CFM3')]" [disabled]="reviewInspection">
      </td>
      <td width="27%">
        <span>HP</span> &nbsp;&nbsp;
        <input type="text" id="anserText_1324" style="width: 60px;" name="anserText_1324"
          [(ngModel)]="qtd[findQuestionId('HP3')]" [disabled]="reviewInspection">
      </td>
    </tr>
  </tbody>
</table>

<table cellspacing="0" cellpadding="0" class="sp-top-table" width="100%" style="margin-bottom: 2%;">
  <tbody>
    <tr>
      <td class="header-td" colspan="5">FAN INFORMATION</td>
    </tr>
    <tr>
      <td width="50%">
        <span style="display: inline-block;margin:5px 0 0 10px;float:left">Rated voltage</span> &nbsp;&nbsp;

        <input type="text" id="anserText_1325" style="width: 60px;float:right;margin-right:10px;" name="anserText_1325"
          [(ngModel)]="qtd[findQuestionId('Rated voltage')]" [disabled]="reviewInspection">
      </td>

      <td width="50%">
        <span style="display: inline-block;margin:5px 0 0 10px;float:left">Actual operating voltage</span>
        &nbsp;&nbsp;

        <input type="text" id="anserText_1326" style="width: 60px;float:right;margin-right:10px;" name="anserText_1326"
          [(ngModel)]="qtd[findQuestionId('Actual operating voltage')]" [disabled]="reviewInspection">
      </td>

    </tr>
    <tr>
      <td width="50%">
        <span style="display: inline-block;margin:5px 0 0 10px;float:left">Rated amperage</span>
        &nbsp;&nbsp;

        <input type="text" id="anserText_1327" style="width: 60px;float:right;margin-right:10px;" name="anserText_1327"
          [(ngModel)]="qtd[findQuestionId('Rated amperage')]" [disabled]="reviewInspection">
      </td>

      <td width="50%">
        <span style="display: inline-block;margin:5px 0 0 10px;float:left">Actual operating amperage</span>
        &nbsp;&nbsp;

        <input type="text" id="anserText_1328" style="width: 60px;float:right;margin-right:10px;" name="anserText_1328"
          [(ngModel)]="qtd[findQuestionId('Actual operating amperage')]" [disabled]="reviewInspection">
      </td>

    </tr>
    <tr>
      <td width="50%">
        <span style="display: inline-block;margin:5px 0 0 10px;float:left">Fan RPM</span> &nbsp;&nbsp;

        <input type="text" id="anserText_1329" style="width: 60px;float:right;margin-right:10px;" name="anserText_1329"
          [(ngModel)]="qtd[findQuestionId('Fan RPM')]" [disabled]="reviewInspection">
      </td>
      <td width="50%">
        <span style="display: inline-block;margin:5px 0 0 10px;float:left">Number of fan belts</span>
        &nbsp;&nbsp;

        <input type="text" id="anserText_1330" style="width: 60px;float:right;margin-right:10px;" name="anserText_1330"
          [(ngModel)]="qtd[findQuestionId('Number of fan belts')]" [disabled]="reviewInspection">
      </td>
    </tr>
    <tr>
      <td width="50%" colspan="2">
        <span style="display: inline-block;margin:5px 0 0 10px;float:left">Fan belt condition</span>
        &nbsp;&nbsp;


        <input type="radio" value="Good" name="radio_1331" id="radio_1331_1"
          [(ngModel)]="qtd[findQuestionId('Fan belt condition')]" [disabled]="reviewInspection">
        &nbsp;&nbsp;
        Good


        <input type="radio" value="Fair" name="radio_1331" id="radio_1331_2"
          [(ngModel)]="qtd[findQuestionId('Fan belt condition')]" [disabled]="reviewInspection">
        &nbsp;&nbsp;
        Fair


        <input type="radio" value="Poor" name="radio_1331" id="radio_1331_3"
          [(ngModel)]="qtd[findQuestionId('Fan belt condition')]" [disabled]="reviewInspection">
        &nbsp;&nbsp;
        Poor
      </td>
    </tr>
  </tbody>
</table>
<table cellspacing="0" cellpadding="0" class="sp-top-table" width="100%" style="margin-bottom: 2%;">
  <tbody>
    <tr>
      <td class="header-td" colspan="8">MEASUREMENTS</td>
    </tr>
    <tr>
      <td width="12%">Floor</td>
      <td width="13%">Inches H2O</td>
      <td width="12%">Floor</td>
      <td width="13%">Inches H2O</td>
      <td width="12%">Floor</td>
      <td width="13%">Inches H2O</td>
      <td width="12%">Floor</td>
      <td width="13%">Inches H2O</td>
    </tr>
    <tr *ngFor="let j of [].constructor(4);let i = index" [attr.data-index]="i">
      <td width="12%">
        <input type="text" style="width:50px;"
          [(ngModel)]="qtd[findQuestionId('Floor1','Measurement1') + '~m~'+ i + '~' + 4]"
          name="qtd-{{findQuestionId('Floor1','Measurement1')}}-{{i}}"
          id="qtd-{{findQuestionId('Floor1','Measurement1')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="13%">
        <input type="text" style="width:57px;"
          [(ngModel)]="qtd[findQuestionId('Inches H2O1','Measurement1') + '~m~'+ i + '~' + 4]"
          name="qtd-{{findQuestionId('Inches H2O1','Measurement1')}}-{{i}}"
          id="qtd-{{findQuestionId('Inches H2O1','Measurement1')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="12%">
        <input type="text" style="width:50px;"
          [(ngModel)]="qtd[findQuestionId('Floor2','Measurement1') + '~m~'+ i + '~' + 4]"
          name="qtd-{{findQuestionId('Floor2','Measurement1')}}-{{i}}"
          id="qtd-{{findQuestionId('Floor2','Measurement1')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="13%">
        <input type="text" style="width:57px;"
          [(ngModel)]="qtd[findQuestionId('Inches H2O2','Measurement1') + '~m~'+ i+ '~' + 4]"
          name="qtd-{{findQuestionId('Inches H2O2','Measurement1')}}-{{i}}"
          id="qtd-{{findQuestionId('Inches H2O2','Measurement1')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="12%">
        <input type="text" style="width:50px;"
          [(ngModel)]="qtd[findQuestionId('Floor3','Measurement1') + '~m~'+ i + '~' + 4]"
          name="qtd-{{findQuestionId('Floor3','Measurement1')}}-{{i}}"
          id="qtd-{{findQuestionId('Floor3','Measurement1')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="13%">
        <input type="text" style="width:57px;"
          [(ngModel)]="qtd[findQuestionId('Inches H2O3','Measurement1') + '~m~'+ i+ '~' + 4]"
          name="qtd-{{findQuestionId('Inches H2O3','Measurement1')}}-{{i}}"
          id="qtd-{{findQuestionId('Inches H2O3','Measurement1')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="12%">
        <input type="text" style="width:50px;"
          [(ngModel)]="qtd[findQuestionId('Floor4','Measurement1') + '~m~'+ i + '~' + 4]"
          name="qtd-{{findQuestionId('Floor4','Measurement1')}}-{{i}}"
          id="qtd-{{findQuestionId('Floor4','Measurement1')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="13%">
        <input type="text" style="width:57px;"
          [(ngModel)]="qtd[findQuestionId('Inches H2O4','Measurement1') + '~m~'+ i + '~' + 4]"
          name="qtd-{{findQuestionId('Inches H2O4','Measurement1')}}-{{i}}"
          id="qtd-{{findQuestionId('Inches H2O4','Measurement1')}}-{{i}}" [disabled]="reviewInspection">
      </td>
    </tr>
  </tbody>
</table>

<table cellspacing="0" cellpadding="0" class="sp-top-table" width="100%" style="margin-bottom: 2%;">
  <tbody>
    <tr>
      <td class="header-td" colspan="9">MEASUREMENTS</td>
    </tr>
    <tr>
      <td width="9%">Floor</td>
      <td width="9%">Floor</td>
      <td width="15%">Difference in H2O</td>
      <td width="9%">Floor</td>
      <td width="9%">Floor</td>
      <td width="15%">Difference in H2O</td>
      <td width="9%">Floor</td>
      <td width="9%">Floor</td>
      <td width="16%">Difference in H2O</td>
    </tr>
    <tr *ngFor="let k of [].constructor(6);let i = index" [attr.data-index]="i">
      <td width="9%">
        <input type="text" style="width:33px;"
          [(ngModel)]="qtd[findQuestionIdSecond('Floor1','Measurement2') + '~m~'+ i + '~' + 6]"
          name="qtd-{{findQuestionIdSecond('Floor1','Measurement2')}}-{{i}}"
          id="qtd-{{findQuestionIdSecond('Floor1','Measurement2')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="9%">
        <input type="text" style="width:33px;"
          [(ngModel)]="qtd[findQuestionIdSecond('Floor2','Measurement2') + '~m~'+ i + '~' + 6]"
          name="qtd-{{findQuestionIdSecond('Floor2','Measurement2')}}-{{i}}"
          id="qtd-{{findQuestionIdSecond('Floor2','Measurement2')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="15%">
        <input type="text" style="width:63px;"
          [(ngModel)]="qtd[findQuestionIdSecond('Difference in H2O1','Measurement2') + '~m~'+ i + '~' + 6]"
          name="qtd-{{findQuestionIdSecond('Difference in H2O1','Measurement2')}}-{{i}}"
          id="qtd-{{findQuestionIdSecond('Difference in H2O1','Measurement2')}}-{{i}}"
          [disabled]="reviewInspection">
      </td>
      <td width="9%">
        <input type="text" style="width:33px;"
          [(ngModel)]="qtd[findQuestionIdSecond('Floor3','Measurement2') + '~m~'+ i + '~' + 6]"
          name="qtd-{{findQuestionIdSecond('Floor3','Measurement2')}}-{{i}}"
          id="qtd-{{findQuestionIdSecond('Floor3','Measurement2')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="9%">
        <input type="text" style="width:33px;"
          [(ngModel)]="qtd[findQuestionIdSecond('Floor4','Measurement2') + '~m~'+ i + '~' + 6]"
          name="qtd-{{findQuestionIdSecond('Floor4','Measurement2')}}-{{i}}"
          id="qtd-{{findQuestionIdSecond('Floor4','Measurement2')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="15%">
        <input type="text" style="width:63px;"
          [(ngModel)]="qtd[findQuestionIdSecond('Difference in H2O2','Measurement2') + '~m~'+ i + '~' + 6]"
          name="qtd-{{findQuestionIdSecond('Difference in H2O2','Measurement2')}}-{{i}}"
          id="qtd-{{findQuestionIdSecond('Difference in H2O2','Measurement2')}}-{{i}}"
          [disabled]="reviewInspection">
      </td>
      <td width="9%">
        <input type="text" style="width:33px;"
          [(ngModel)]="qtd[findQuestionIdSecond('Floor5','Measurement2') + '~m~'+ i + '~' + 6]"
          name="qtd-{{findQuestionIdSecond('Floor5','Measurement2')}}-{{i}}"
          id="qtd-{{findQuestionIdSecond('Floor5','Measurement2')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="9%">
        <input type="text" style="width:33px;"
          [(ngModel)]="qtd[findQuestionIdSecond('Floor6','Measurement2') + '~m~'+ i + '~' + 6]"
          name="qtd-{{findQuestionIdSecond('Floor6','Measurement2')}}-{{i}}"
          id="qtd-{{findQuestionIdSecond('Floor6','Measurement2')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="16%">
        <input type="text" style="width:63px;"
          [(ngModel)]="qtd[findQuestionIdSecond('Difference in H2O3','Measurement2') + '~m~'+ i + '~' + 6]"
          name="qtd-{{findQuestionIdSecond('Difference in H2O3','Measurement2')}}-{{i}}"
          id="qtd-{{findQuestionIdSecond('Difference in H2O3','Measurement2')}}-{{i}}"
          [disabled]="reviewInspection">
      </td>
    </tr>
  </tbody>
</table>
