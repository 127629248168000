import { OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var VerifyPacComponent = /** @class */ (function () {
    function VerifyPacComponent(dialogRef, data, propertyService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.propertyService = propertyService;
        this.subscriptions = [];
    }
    VerifyPacComponent.prototype.ngOnInit = function () {
        this.pac = this.data.pac;
        this.error = '';
    };
    VerifyPacComponent.prototype.save = function () {
        var _this = this;
        if (!this.pac || this.pac === undefined || this.pac.length === 0) {
            this.error = 'Please enter property access code.';
        }
        else {
            this.subscriptions.push(this.propertyService.validatePAC(this.data.PropertyId, this.pac.toUpperCase()).subscribe(function (res) {
                if (res === true) {
                    _this.dialogRef.close(_this.pac);
                }
                else {
                    _this.error = 'Access Code does not belong to the selected property. Re-enter access code or select any other property.';
                }
            }));
        }
    };
    VerifyPacComponent.prototype.cancel = function () {
        this.dialogRef.close('cancelled');
    };
    VerifyPacComponent.prototype.byPass = function () {
        this.dialogRef.close('bypass');
    };
    // 2066 TODO: Remove if not required at all
    // navigate() {
    //   this.dialogRef.close('navigate');
    // }
    VerifyPacComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (x) {
            x.unsubscribe();
        });
    };
    return VerifyPacComponent;
}());
export { VerifyPacComponent };
