import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '@app-shared/components';
import { AuthGuard } from './login/auth.guard';
import { InspectionReportComponent } from './inspection/inspection-report/inspection-report.component';


const routes: Routes = [
  {
    path: 'user',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@app-dashboard/dashboard.module').then(mod => mod.DashboardModule)
  },
  {
    path: 'printReport',
    component: InspectionReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
