import { OnInit } from '@angular/core';
var ExtinguishingAgentComponent = /** @class */ (function () {
    function ExtinguishingAgentComponent() {
        this.systemTypes = ['HALON', 'CO2', 'FM 200', 'OTHER'];
    }
    ExtinguishingAgentComponent.prototype.ngOnInit = function () {
    };
    ExtinguishingAgentComponent.prototype.findQuestionId = function (description) {
        if (this.questions.filter(function (x) { return x.questions.question === description; }) &&
            this.questions.filter(function (x) { return x.questions.question === description; })[0]) {
            return this.questions.filter(function (x) { return x.questions.question === description; })[0].questions.questionId;
        }
        return 0;
    };
    ExtinguishingAgentComponent.prototype.getsystemType = function () {
        if (this.qtd[this.findQuestionId("HALON")] === 'true') {
            this.systemTypeInit = 'HALON';
        }
        else if (this.qtd[this.findQuestionId("CO2")] === 'true') {
            this.systemTypeInit = 'CO2';
        }
        else if (this.qtd[this.findQuestionId("FM 200")] === 'true') {
            this.systemTypeInit = 'FM 200';
        }
        else if (this.qtd[this.findQuestionId("OTHER")] !== '' && this.qtd[this.findQuestionId("OTHER")] !== null) {
            this.systemTypeInit = 'OTHER';
            this.others = true;
        }
        return this.systemTypeInit;
    };
    ExtinguishingAgentComponent.prototype.radioChange = function (event) {
        if ((event.value === 'HALON')) {
            this.qtd[this.findQuestionId("HALON")] = 'true';
            this.qtd[this.findQuestionId("CO2")] = 'false';
            this.qtd[this.findQuestionId("FM 200")] = 'false';
            this.qtd[this.findQuestionId('OTHER')] = '';
            this.others = false;
        }
        else if ((event.value === 'CO2')) {
            this.qtd[this.findQuestionId("HALON")] = 'false';
            this.qtd[this.findQuestionId("CO2")] = 'true';
            this.qtd[this.findQuestionId("FM 200")] = 'false';
            this.qtd[this.findQuestionId('OTHER')] = '';
            this.others = false;
        }
        else if ((event.value === 'FM 200')) {
            this.qtd[this.findQuestionId("HALON")] = 'false';
            this.qtd[this.findQuestionId("CO2")] = 'false';
            this.qtd[this.findQuestionId("FM 200")] = 'true';
            this.qtd[this.findQuestionId('OTHER')] = '';
            this.others = false;
        }
        else if ((event.value === 'OTHER')) {
            this.qtd[this.findQuestionId("HALON")] = 'false';
            this.qtd[this.findQuestionId("CO2")] = 'false';
            this.qtd[this.findQuestionId("FM 200")] = 'false';
            this.others = true;
            this.systemTypeInit = 'OTHER';
        }
    };
    return ExtinguishingAgentComponent;
}());
export { ExtinguishingAgentComponent };
