import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { SharedModule } from '@app-shared/shared.module';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { ForgotPasswordComponent } from './login-form/forgot-passwordDialog/forgot-password/forgot-password.component';
import { EmailSentDialogComponent } from './login-form/email-sent-dialog/email-sent-dialog.component';
import { AuthGuard } from './auth.guard';
import { ForceChangePasswordComponent } from './force-change-password/force-change-password.component';
import { MatProgressSpinnerModule } from '@angular/material';
import { SignUpFormComponent } from '../login/sign-up-form/sign-up-form.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

@NgModule({
  declarations: [
    LoginComponent,
    LoginFormComponent,
    ForgotPasswordComponent,
    EmailSentDialogComponent,
    ForceChangePasswordComponent,
    SignUpFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    LoginRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatProgressSpinnerModule
  ],
  providers: [AuthGuard],
  // exports: [AuthGuard],
  entryComponents: [ForgotPasswordComponent, EmailSentDialogComponent]
})
export class LoginModule { }
