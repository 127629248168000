import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';

@Component({
  selector: 'app-authority-notification-dialog',
  templateUrl: './authority-notification-dialog.component.html',
  styleUrls: ['./authority-notification-dialog.component.scss']
})
export class AuthorityNotificationDialogComponent implements OnInit {

  message: string;
  constructor(public dialogRef: MatDialogRef<any>,  @Inject(MAT_DIALOG_DATA) public data: string) { }

  ngOnInit() {
    this.message = this.data;
  }

  okClicked() {
    this.dialogRef.close(true);
  }

  cancilClicked() {
    this.dialogRef.close(false);
  }

}
