import { ElementRef, Renderer2, EventEmitter } from '@angular/core';
var DateCheckerDirective = /** @class */ (function () {
    function DateCheckerDirective(model, el, renderer) {
        this.model = model;
        this.el = el;
        this.renderer = renderer;
        this.a = new EventEmitter(null);
    }
    DateCheckerDirective.prototype.onBlur = function (e) {
        var currDate = new Date();
        if (!e.target.value) {
            this.renderer.setAttribute(this.el.nativeElement, 'dateNotEntered', 'true');
            this.a.emit({ error: 'Enter Expiration Date' });
            return;
        }
        if (!/^([0-9]{2}-[0-9]{2}-[0-9]{4})$/.test(e.target.value)) {
            this.renderer.setAttribute(this.el.nativeElement, 'notValidDate', 'true');
            this.a.emit({ error: 'Date format should be mm-dd-yyyy' });
            return;
        }
        var dateArray = e.target.value.split('-');
        var enteredDate = new Date(dateArray[2], +dateArray[0] - 1, dateArray[1]);
        if (+dateArray[0] > 12 || +dateArray[0] < 1) {
            this.renderer.setAttribute(this.el.nativeElement, 'notValidDate', 'true');
            this.a.emit({ error: 'Date format should be mm-dd-yyyy' });
            return;
        }
        if (!+enteredDate) {
            this.renderer.setAttribute(this.el.nativeElement, 'notValidDate', 'true');
            this.a.emit({ error: 'Date format should be mm-dd-yyyy' });
            return;
        }
        if (+enteredDate <= +currDate) {
            this.renderer.setAttribute(this.el.nativeElement, 'notFutureDate', 'true');
            this.a.emit({ error: 'Expiration date should be in future' });
            return;
        }
        this.a.emit({ error: null });
        this.renderer.setAttribute(this.el.nativeElement, 'validDate', 'false');
    };
    return DateCheckerDirective;
}());
export { DateCheckerDirective };
