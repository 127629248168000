import { Component, OnInit, Input } from '@angular/core';
import { HeadingQuestion } from '../../../core/models/inspection/inspection.model';


@Component({
  selector: "app-extinguishing-agent",
  templateUrl: "./extinguishing-agent.component.html",
  styleUrls: ["./extinguishing-agent.component.scss"],
})
export class ExtinguishingAgentComponent implements OnInit {
  @Input() questions: Array<HeadingQuestion>;
  @Input() qtd: Array<any>;
  @Input() reviewInspection: boolean;
  systemType: string;
  systemTypes: string[] = ['HALON', 'CO2', 'FM 200', 'OTHER'];
  others;
  systemTypeInit;
  constructor() {}

  ngOnInit() {
  }

  findQuestionId(description) {
    if (
      this.questions.filter((x) => x.questions.question === description) &&
      this.questions.filter((x) => x.questions.question === description)[0]
    ) {
      return this.questions.filter(
        (x) => x.questions.question === description
      )[0].questions.questionId;
    }
    return 0;
  }
  getsystemType(){
   
    if(this.qtd[this.findQuestionId("HALON")] === 'true') {
      this.systemTypeInit = 'HALON';
    } else if(this.qtd[this.findQuestionId("CO2")] === 'true'){
      this.systemTypeInit = 'CO2';
    } else if(this.qtd[this.findQuestionId("FM 200")] === 'true'){
      this.systemTypeInit = 'FM 200';
    } else if(this.qtd[this.findQuestionId("OTHER")] !== '' && this.qtd[this.findQuestionId("OTHER")] !== null ){
      this.systemTypeInit = 'OTHER';
      this.others= true;
    }
    
    return this.systemTypeInit
  }
  radioChange(event) {
    if ((event.value === 'HALON')) {
      this.qtd[this.findQuestionId("HALON")] = 'true';
      this.qtd[this.findQuestionId("CO2")] = 'false';
      this.qtd[this.findQuestionId("FM 200")] = 'false';
      this.qtd[this.findQuestionId('OTHER')] = '';
      this.others = false;
    } else if ((event.value === 'CO2')) {
      this.qtd[this.findQuestionId("HALON")] = 'false';
      this.qtd[this.findQuestionId("CO2")] = 'true';
      this.qtd[this.findQuestionId("FM 200")] = 'false';
      this.qtd[this.findQuestionId('OTHER')] = '';
      this.others = false;
    } else if ((event.value === 'FM 200')) {
      this.qtd[this.findQuestionId("HALON")] = 'false';
      this.qtd[this.findQuestionId("CO2")] = 'false';
      this.qtd[this.findQuestionId("FM 200")] = 'true';
      this.qtd[this.findQuestionId('OTHER')] = '';
      this.others = false;
    } else if ((event.value === 'OTHER')) {
      this.qtd[this.findQuestionId("HALON")] = 'false';
      this.qtd[this.findQuestionId("CO2")] = 'false';
      this.qtd[this.findQuestionId("FM 200")] = 'false';
      this.others = true;
      this.systemTypeInit = 'OTHER';
    }
  }
}
