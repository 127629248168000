import * as tslib_1 from "tslib";
import { environment } from '@environment/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
import * as i2 from "../logger/logger.service";
import * as i3 from "@angular/common/http";
/**
 * This service manage the web push notifications
 * This service provides the functionalities to subscribe & unsubscribe the push notifications.
 * This service uses the [SwPush]{@link https://angular.io/api/service-worker/SwPush} service of ```@angular/service-worker```.
 */
var PushNotificationsService = /** @class */ (function () {
    function PushNotificationsService(swPush, logger, http) {
        this.swPush = swPush;
        this.logger = logger;
        this.http = http;
    }
    /**
     * Subscribe the push notifications. This service prompts user to allow access for web push notifications.
     * This uses the server public key defined in the environment variables to subscribe the notifications.
     */
    PushNotificationsService.prototype.subscribeNotifications = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sub;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.swPush
                            .requestSubscription({
                            serverPublicKey: environment.pushNotificationPublicKey
                        })
                            .catch(function (e) {
                            _this.logger.info('Push notification subscription is declined', e);
                        })];
                    case 1:
                        sub = _a.sent();
                        /** To check the subscription */
                        this.logger.log({ subscription: sub });
                        if (sub) {
                            /** Send the subscription to server in case of no error */
                            this.addSubscription(sub);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Unsubscribe to push notifcation using ```SwPush``` API.
     */
    PushNotificationsService.prototype.unsubscribeToPushNotification = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.swPush.unsubscribe().catch(function (e) {
                            _this.logger.error('Error while unsubscribing the notifications', e);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Save the subscription to the server.
     * @param sub Push notification subscription object
     */
    PushNotificationsService.prototype.addSubscription = function (sub) {
        /** TODO: Save the subscription on the server */
        // this.http.post()
    };
    PushNotificationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PushNotificationsService_Factory() { return new PushNotificationsService(i0.ɵɵinject(i1.SwPush), i0.ɵɵinject(i2.LoggerService), i0.ɵɵinject(i3.HttpClient)); }, token: PushNotificationsService, providedIn: "root" });
    return PushNotificationsService;
}());
export { PushNotificationsService };
