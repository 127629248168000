import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: []
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(
    private matDialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) { }

  ngOnInit() {
  }

  onClick(e) {
    this.matDialogRef.close(e);

  }

}
