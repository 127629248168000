import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-popup-dialog-delete',
  templateUrl: 'confirmation-popup-dialog-delete.component.html',
  //styleUrls: ['./confirmation-popup-dialog-delete.component.scss']
})
export class ConfirmationPopupDialogDeleteComponent {

  comments: string;
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {

  }

  areYouSureNo() {
    this.dialogRef.close(false);
  }

  areYouSureYes(){
    if(!(this.comments)) {
      alert('Please add comments')
      return;
    }
    this.comments = this.comments ? this.comments : '';
    this.dialogRef.close({sub: true, comments: this.comments});
  }
}
