<!-- @format -->

<mat-toolbar>
  <mat-toolbar-row class="customizeRow">
    <app-logo class="logo d-lg-flex"></app-logo>
    <button type="button" id="sidebarCollapse" class="btn">
      <em class="fas fa-align-left" style="color: white"></em>
    </button>
    <div class="d-md-flex w-100 align-items-center justify-content-end pr-2">
      <div class="todays-date">{{ currentDate | date: "dd MMMM, y" }}</div>

      <img
        alt="tegrisfire"
        class="example-icon"
        src="./assets/images/user.png"
      />
      <span class="user-name">
        <div class="row">
          Welcome {{ userDetails.firstName }} {{ userDetails.lastName }}
        </div>
        <div class="row" style="font-size: 16px">
          {{ userDetails.role.description }}
        </div>
        <div
          *ngIf="
            userDetails &&
            userDetails.role &&
            userDetails.role.name === 'ROLE_AUTHORITY'
          "
          class="row"
          style="font-size: 16px"
        >
          {{ userDetails.jurisdiction.name }}
        </div>
      </span>
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
      >
        <img alt="tegrisfire" src="./assets/images/menu.png" />
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="openContactUs()" mat-menu-item>
          <mat-icon class="custom-menu-icons">contact_phone</mat-icon>
          <span class="text">Contact Us</span>
        </button>
        <button routerLink="/login/force-change-password" mat-menu-item>
          <mat-icon class="custom-menu-icons">vpn_key</mat-icon>
          <span class="text">Reset Password</span>
        </button>
        <button mat-menu-item (click)="checkBeforeLogout()">
          <mat-icon class="custom-menu-icons">power_settings_new</mat-icon>
          <span class="text">log out</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
