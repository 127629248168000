import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var StandpipePrvComponent = /** @class */ (function () {
    function StandpipePrvComponent(insService) {
        this.insService = insService;
    }
    StandpipePrvComponent.prototype.ngOnInit = function () {
        this.prv = [{ id: 0 }];
        if (this.insService.getStandPipeRows() > 0) {
            for (var i = 1; i < this.insService.getStandPipeRows(); i++) {
                this.prv.push({ id: i });
            }
        }
        if (this.insService.getPVRRows() && this.insService.getPVRRows().length > 0) {
            this.prv = this.insService.getPVRRows();
            for (var m = 0; m < this.prv.length; m++) {
                this.qtd[this.findQuestionIdSecond('Floor/Stair') + '~standpipe~' + m + '~'] =
                    JSON.parse(JSON.stringify(this.prv[m]['Floor/Stair'])) || '';
                this.qtd[this.findQuestionIdSecond('Inlet-Static(psi)') + '~standpipe~' + m + '~'] =
                    JSON.parse(JSON.stringify(this.prv[m]['Inlet-Static(psi)'])) || '';
                this.qtd[this.findQuestionIdSecond('Outlet-Static(psi)') + '~standpipe~' + m + '~'] =
                    JSON.parse(JSON.stringify(this.prv[m]['Outlet-Static(psi)'])) || '';
                this.qtd[this.findQuestionIdSecond('Inlet-Residual(psi)') + '~standpipe~' + m + '~'] =
                    JSON.parse(JSON.stringify(this.prv[m]['Inlet-Residual(psi)'])) || '';
                this.qtd[this.findQuestionIdSecond('Outlet-Residual(psi)') + '~standpipe~' + m + '~'] =
                    JSON.parse(JSON.stringify(this.prv[m]['Outlet-Residual(psi)'])) || '';
                this.qtd[this.findQuestionIdSecond('Outlet-Flow(gpm)') + '~standpipe~' + m + '~'] =
                    JSON.parse(JSON.stringify(this.prv[m]['Outlet-Flow(gpm)'])) || '';
                this.qtd[this.findQuestionIdSecond('P.R.V.Setting(psi)') + '~standpipe~' + m + '~'] =
                    JSON.parse(JSON.stringify(this.prv[m]['P.R.V.Setting(psi)'])) || '';
            }
        }
        else {
            if (this.insService.getStandPipeRows() <= 0) {
                this.prv = [{ id: 0 }];
            }
        }
    };
    StandpipePrvComponent.prototype.addRow = function () {
        this.prv.push({ id: this.prv.length });
        this.insService.setStandPipeRows(this.prv.length);
    };
    StandpipePrvComponent.prototype.deleteRow = function (index) {
        var e_1, _a;
        this.prv = this.prv.filter(function (x) { return x.id !== index.id; });
        try {
            for (var _b = tslib_1.__values(Object.keys(this.qtd)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var key = _c.value;
                if (key.split('~') && key.indexOf('standpipe') > 0) {
                    delete this.qtd[key];
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.rebuildDOM();
        this.insService.setStandPipeRows(this.prv.length);
    };
    StandpipePrvComponent.prototype.findQuestionIdSecond = function (description) {
        if (this.questions) {
            if (this.questions.filter(function (x) { return x.questions.question === description; }) &&
                this.questions.filter(function (x) { return x.questions.question === description; })[0]) {
                return this.questions.filter(function (x) { return x.questions.question === description; })[0].questions.questionId;
            }
        }
        return 0;
    };
    StandpipePrvComponent.prototype.updateModel = function (question, id, k) {
        this.qtd[this.findQuestionIdSecond(question) + '~standpipe~' + id + '~'] = JSON.parse(JSON.stringify(k[question]));
    };
    StandpipePrvComponent.prototype.rebuildDOM = function () {
        for (var m = 0; m < this.prv.length; m++) {
            this.qtd[this.findQuestionIdSecond('Floor/Stair') + '~standpipe~' + m + '~'] =
                this.prv[m]['Floor/Stair'] ? JSON.parse(JSON.stringify(this.prv[m]['Floor/Stair'])) : '';
            this.qtd[this.findQuestionIdSecond('Inlet-Static(psi)') + '~standpipe~' + m + '~'] =
                this.prv[m]['Inlet-Static(psi)'] ? JSON.parse(JSON.stringify(this.prv[m]['Inlet-Static(psi)'])) : '';
            this.qtd[this.findQuestionIdSecond('Outlet-Static(psi)') + '~standpipe~' + m + '~'] =
                this.prv[m]['Outlet-Static(psi)'] ? JSON.parse(JSON.stringify(this.prv[m]['Outlet-Static(psi)'])) : '';
            this.qtd[this.findQuestionIdSecond('Inlet-Residual(psi)') + '~standpipe~' + m + '~'] =
                this.prv[m]['Inlet-Residual(psi)'] ? JSON.parse(JSON.stringify(this.prv[m]['Inlet-Residual(psi)'])) : '';
            this.qtd[this.findQuestionIdSecond('Outlet-Residual(psi)') + '~standpipe~' + m + '~'] =
                this.prv[m]['Outlet-Residual(psi)'] ? JSON.parse(JSON.stringify(this.prv[m]['Outlet-Residual(psi)'])) : '';
            this.qtd[this.findQuestionIdSecond('Outlet-Flow(gpm)') + '~standpipe~' + m + '~'] =
                this.prv[m]['Outlet-Flow(gpm)'] ? JSON.parse(JSON.stringify(this.prv[m]['Outlet-Flow(gpm)'])) : '';
            this.qtd[this.findQuestionIdSecond('P.R.V.Setting(psi)') + '~standpipe~' + m + '~'] =
                this.prv[m]['P.R.V.Setting(psi)'] ? JSON.parse(JSON.stringify(this.prv[m]['P.R.V.Setting(psi)'])) : '';
        }
    };
    return StandpipePrvComponent;
}());
export { StandpipePrvComponent };
