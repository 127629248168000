import { OnInit } from '@angular/core';
var ShaftComponent = /** @class */ (function () {
    function ShaftComponent() {
    }
    ShaftComponent.prototype.ngOnInit = function () {
    };
    ShaftComponent.prototype.findQuestionId = function (description, table) {
        if (!table) {
            if (this.questions.filter(function (x) { return x.questions.question === description; }) &&
                this.questions.filter(function (x) { return x.questions.question === description; })[0]) {
                return this.questions.filter(function (x) { return x.questions.question === description; })[0].questions.questionId;
            }
        }
        else {
            if (this.questions.filter(function (x) { return x.questions.question === description && x.questions.questionType === table; }) &&
                this.questions.filter(function (x) { return x.questions.question === description && x.questions.questionType === table; })[0]) {
                return this.questions.filter(function (x) { return x.questions.question === description && x.questions.questionType === table; })[0].
                    questions.questionId;
            }
        }
        return 0;
    };
    ShaftComponent.prototype.findQuestionIdSecond = function (description, table) {
        if (this.questions.filter(function (x) { return x.questions.question === description && x.questions.questionType === table; }) &&
            this.questions.filter(function (x) { return x.questions.question === description && x.questions.questionType === table; })[0]) {
            return this.questions.filter(function (x) { return x.questions.question === description && x.questions.questionType === table; })[0].questions.questionId;
        }
        return 0;
    };
    return ShaftComponent;
}());
export { ShaftComponent };
