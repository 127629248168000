import { OnInit, OnDestroy } from '@angular/core';
import { Validators } from '@angular/forms';
import { RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import * as AES from "crypto-js/aes";
import * as UTF8 from "crypto-js/enc-utf8";
import { Roles, LoginInfo, UserLogin, UserDetails } from '../../shared/models/user.model';
import { MatDialog } from '@angular/material';
import { ForgotPasswordComponent } from './forgot-passwordDialog/forgot-password/forgot-password.component';
import { EmailSentDialogComponent } from './email-sent-dialog/email-sent-dialog.component';
var LoginFormComponent = /** @class */ (function () {
    function LoginFormComponent(fb, router, loginService, dialog, ownerService, userService) {
        this.fb = fb;
        this.router = router;
        this.loginService = loginService;
        this.dialog = dialog;
        this.ownerService = ownerService;
        this.userService = userService;
        this.secretKey = "ui87n@!9845%$bh|pqw";
        this.subscriptions = [];
        this.loading = false;
        this.validNewPolicyPassword = true;
        this.disableClick = false;
    }
    LoginFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.router.events.subscribe(function (event) {
            if (event instanceof RouteConfigLoadStart) {
                _this.loading = true;
            }
            else if (event instanceof RouteConfigLoadEnd) {
                _this.loading = false;
            }
        });
        var url = new URL(window.location.href);
        var params = new URLSearchParams(url.search);
        this.resetErrors();
        this.formValidity = true;
        this.showPassword = 'password';
        this.form = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            rememberMe: [false]
        });
        if (params.has('asp-au') && params.has('asp-ap')) {
            var encryptedUsername = params.get('asp-au');
            var encryptedPassword = params.get('asp-ap');
            this.form.value.username = this.decrypt(encryptedUsername.replace(/ /g, "+"));
            this.form.value.password = this.decrypt(encryptedPassword.replace(/ /g, "+"));
            this.loginInfo = {
                email: this.form.value.username,
                password: this.form.value.password
            };
            this.login();
        }
        else {
            this.setValuesFromCookie();
            this.loginInfo = {
                email: this.form.value.username,
                password: this.form.value.password
            };
        }
    };
    LoginFormComponent.prototype.login = function () {
        var _this = this;
        // this.userDetails.user.role.authority
        this.formValidity = true;
        this.resetErrors();
        if (!this.form.value.username || this.form.value.username.trim().length === 0) {
            this.errors.email = 'Please enter Email Address';
            this.formValidity = false;
        }
        else if (this.form.value.username &&
            !/[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*/.test(this.form.value.username.trim())) {
            this.errors.email = 'Enter a valid email address like abc@gmail.com.';
            this.formValidity = false;
        }
        this.form.value.username = this.form.value.username.trim();
        if (!this.form.value.password || this.form.value.password.length === 0) {
            this.errors.password = 'Please enter Password';
            this.formValidity = false;
        }
        if (this.formValidity) {
            this.loginInfo = {
                email: this.form.value.username,
                password: this.form.value.password
            };
            this.disableClick = true;
            this.subscriptions.push(this.loginService.loginUser(this.loginInfo).subscribe(function (response) {
                _this.validateNewPolicyPassword(_this.loginInfo.password);
                _this.userDetails = response;
                _this.loginService.loggedIn$.next(true);
                if (!_this.userDetails.user.isPrimary) {
                    if (_this.userDetails.user.role.id === Roles.ROLE_AUTHORITY_ADMIN) {
                        _this.userDetails.user.role.description = 'Authority User';
                    }
                    else if (_this.userDetails.user.role.id === Roles.ROLE_INSPECTOR_ADMIN) {
                        _this.userDetails.user.role.description = 'Inspector User';
                    }
                    else if (_this.userDetails.user.role.id === Roles.ROLE_OWNER_ADMIN) {
                        _this.userDetails.user.role.description = 'Owner User';
                    }
                }
                _this.loginService.UserDetails$.next(_this.userDetails);
                sessionStorage.setItem('token', _this.loginService.UserDetails$.getValue().token);
                localStorage.setItem('userRole', _this.loginService.UserDetails$.getValue().user.role.name);
                localStorage.setItem('userId', _this.loginService.UserDetails$.getValue().user.id.toString());
                if (_this.userDetails.user.isPrimary) {
                    _this.ownerService.preferencesUpdated.next(_this.userDetails.user.isPreferenceSet);
                    localStorage.setItem('preferencesSet', JSON.stringify(_this.userDetails.user.isPreferenceSet));
                }
                localStorage.setItem('userDetails', JSON.stringify(_this.loginService.UserDetails$.getValue().user));
                _this.userService.userDetails = _this.userDetails.user;
                // this.loginService.UserDetails$.getValue().token
                if (_this.form.value.rememberMe === true) {
                    _this.setCookies();
                }
                _this.user = _this.loginService.UserDetails$.getValue().user;
                // this.snacbar.open('Login successful', 'Ok', { duration: 3 * 1000,
                // });
                if (_this.userDetails.user.isForcePasswordChanged || _this.validNewPolicyPassword === false) {
                    if (_this.validNewPolicyPassword === false) {
                        sessionStorage.setItem('ss-up', _this.encrypt(_this.form.value.password));
                    }
                    var queryParams = {
                        queryParams: {
                            force: 1
                        }
                    };
                    _this.router.navigate(['login/force-change-password'], queryParams);
                }
                else if (!_this.user.isPreferenceSet) {
                    if (_this.user.role.id === Roles.ROLE_OWNER_ADMIN) {
                        _this.router.navigate(['user/owner/preferences']);
                    }
                    else if (_this.user.role.id === Roles.ROLE_AUTHORITY_ADMIN || _this.user.role.id === Roles.ROLE_AUTHORITY_WORX) {
                        _this.router.navigate(['user/authority/authorityPreference']);
                    }
                    else if (_this.user.role.id === Roles.ROLE_INSPECTOR_ADMIN) {
                        _this.router.navigate(['user/inspector/preferencesInspector']);
                    }
                    else if (_this.user.role.id === Roles.ROLE_RFA) {
                        _this.router.navigate(['user/authority/authorityPreference']);
                    }
                }
                else {
                    if (_this.user.role.id === Roles.ROLE_OWNER_ADMIN) {
                        _this.router.navigate(['user/owner/dashboard']);
                    }
                    else if (_this.user.role.id === Roles.ROLE_AUTHORITY_ADMIN || _this.user.role.id === Roles.ROLE_AUTHORITY_WORX) {
                        _this.router.navigate(['user/authority/dashboardAuthority']);
                    }
                    else if (_this.user.role.id === Roles.ROLE_INSPECTOR_ADMIN) {
                        _this.router.navigate(['user/inspector/dashboardInspector']);
                    }
                    else if (_this.user.role.id === Roles.ROLE_RFA) {
                        _this.router.navigate(['user/authority/dashboardAuthority']);
                    }
                }
                if (_this.user.role.id === Roles.ROLE_SITE_ADMIN) {
                    _this.router.navigate(['user/admin/dashboardSiteAdmin']);
                }
                _this.disableClick = false;
            }, function (error) {
                _this.disableClick = false;
                console.log(error);
                _this.errors.apiErrors = 'Unexpected error occured';
                // this.snacbar.open('Login failed', 'Ok', {
                //   duration: 3 * 1000,
                // });
                // if (error.error && error.error.message && error.error.message.includes('password')) {
                //   this.errors.apiErrors = 'Invalid Email/Password.';
                // } else if (error.error && error.error.message && error.error.message.toLowerCase().includes('user is suspended.')) {
                //   this.errors.apiErrors = ' User is suspended.';
                // } else if (error.error && error.error.message && error.error.message.toLowerCase().includes('user')) {
                //   this.errors.apiErrors = 'Email not present in system.';
                // }
                if (error.error && error.error.message) {
                    _this.errors.apiErrors = error.error.message;
                }
            }));
            // this.showPassword = 'password';
            // this.router.navigate(['user']);
            // const formData = this.form.value;
            // const formData = this.form.value; this.userDetails = { name: 'Ms. Kathy' ,user{role: "ROLE_OWNER_ADMIN"} };
        }
    };
    LoginFormComponent.prototype.validateNewPolicyPassword = function (password) {
        if (!password || password.length === 0) {
            this.validNewPolicyPassword = false;
            return;
        }
        else {
            if (password.length < 8 || password.length > 15) {
                this.validNewPolicyPassword = false;
                return;
            }
            if (password.includes('.') || password.includes(',') || password.includes(';') || password.includes(':')) {
                this.validNewPolicyPassword = false;
                return;
            }
            if (password === password.toLowerCase()) {
                this.validNewPolicyPassword = false;
                return;
            }
            if (password === password.toUpperCase()) {
                this.validNewPolicyPassword = false;
                return;
            }
            if (!(/\d/.test(password))) {
                this.validNewPolicyPassword = false;
                return;
            }
            else {
                for (var i = 0; i < password.length - 2; ++i) {
                    if (/\d/.test(password.charAt(i))) {
                        if (password.charAt(i) === password.charAt(i + 1) && password.charAt(i) === password.charAt(i + 2)) {
                            this.validNewPolicyPassword = false;
                            return;
                        }
                    }
                }
            }
        }
    };
    LoginFormComponent.prototype.setValuesFromCookie = function () {
        var cookieArray = document.cookie.split(';');
        var usernameCookie = cookieArray.find(function (emailCookie) {
            return emailCookie.includes('asp-au');
        });
        if (usernameCookie &&
            usernameCookie !== undefined &&
            usernameCookie.length > 0) {
            var splitUsernameCookie = usernameCookie.split('=');
            var usernameValue = splitUsernameCookie[1];
            var decryptedUsernameValue = this.decrypt(usernameValue);
            this.form.controls.username.setValue(decryptedUsernameValue);
        }
        var passwordCookie = cookieArray.find(function (emailCookie) {
            return emailCookie.includes('asp-ap');
        });
        if (passwordCookie &&
            passwordCookie !== undefined &&
            passwordCookie.length > 0) {
            var splitPasswordCookie = passwordCookie.split('=');
            var passwordValue = splitPasswordCookie[1];
            var decryptedPasswordValue = this.decrypt(passwordValue);
            this.form.controls.password.setValue(decryptedPasswordValue);
        }
    };
    LoginFormComponent.prototype.signUp = function () {
        this.router.navigate(['signUp']);
    };
    LoginFormComponent.prototype.setCookies = function () {
        var date = new Date();
        date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
        var expires = '; expires=' + date.toUTCString();
        var encryptedUserName = this.encrypt(this.form.value.username);
        var encryptedPassword = this.encrypt(this.form.value.password);
        document.cookie = "asp-au=" + encryptedUserName + expires;
        document.cookie = "asp-ap=" + encryptedPassword + expires;
    };
    LoginFormComponent.prototype.encrypt = function (val) {
        return AES.encrypt(val, this.secretKey).toString();
    };
    LoginFormComponent.prototype.decrypt = function (encryptedVal) {
        return AES.decrypt(encryptedVal, this.secretKey).toString(UTF8);
    };
    LoginFormComponent.prototype.forgotPassword = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ForgotPasswordComponent, {
            width: '887px',
            height: '580px'
            // data: { description: 'Add Property System', addEditObject: null },
            // disableClose: true,
            // autoFocus: false,
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result.length > 0 && result !== 'cancel') {
                var dialogRef2 = _this.dialog.open(EmailSentDialogComponent, {
                    width: '887px',
                    height: '400px',
                    data: { email: result }
                    // disableClose: true,
                    // autoFocus: false,
                });
                dialogRef.afterClosed().subscribe(function (result) { });
            }
            //   if (result) {
            //     const SysDetail: SystemDetails = result;
            //     this.systemDetails.push(SysDetail);
            //     this.dataSource = new MatTableDataSource(this.systemDetails);
            //     this.dataSource._updateChangeSubscription();
            //     if (this.selects) {
            //       this.selects = false;
            //     }
            //   }
        });
    };
    LoginFormComponent.prototype.toggleShowPassword = function () {
        this.showPassword = this.showPassword === 'password' ? 'text' : 'password';
    };
    LoginFormComponent.prototype.resetErrors = function () {
        this.errors = {
            email: null,
            password: null,
            apiErrors: null
        };
    };
    LoginFormComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (x) {
            x.unsubscribe();
        });
    };
    return LoginFormComponent;
}());
export { LoginFormComponent };
