import { OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import * as moment from "moment";
export var MY_FORMATS = {
    parse: {
        dateInput: "MM-DD-YYYY",
    },
    display: {
        dateInput: "MM-DD-YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "MM-DD-YYYY",
        monthYearA11yLabel: "MMMM YYYY",
    },
};
var ViewDeficiencyDialogComponent = /** @class */ (function () {
    function ViewDeficiencyDialogComponent(dialogRef, data, route, router, inspectionService, fileCabService, loggerService, formBuilder) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.route = route;
        this.router = router;
        this.inspectionService = inspectionService;
        this.fileCabService = fileCabService;
        this.loggerService = loggerService;
        this.formBuilder = formBuilder;
        this.fromDateError = "";
        this.toDateError = "";
        this.formGroup = this.formBuilder.group({
            correctedBy: '',
            correctionComments: new FormControl('', Validators.maxLength(1000)),
            jurisdictionId: Number,
            defDateCorrected: '',
            jurisdictionName: ''
        });
    }
    Object.defineProperty(ViewDeficiencyDialogComponent.prototype, "f", {
        get: function () {
            return this.formGroup.controls;
        },
        enumerable: true,
        configurable: true
    });
    ViewDeficiencyDialogComponent.prototype.ngOnInit = function () {
        this.deficiencies = this.data.deficiencies;
        this.authorityRemarks = this.data.authorityRemarks;
        this.inspectorRemarks = this.data.inspectorRemarks;
        this.userRole = localStorage.getItem("userRole");
        this.nameOfAction = this.data.name;
        this.getJurisdiction();
    };
    ViewDeficiencyDialogComponent.prototype.save = function () {
        this.dialogRef.close("continue");
    };
    ViewDeficiencyDialogComponent.prototype.updateReport = function () {
        if (this.userRole == "ROLE_OWNER") {
            alert('Corrections can only be reported by a certified inspector.  Please contact your inspection company');
            return;
        }
        this.dialogRef.close("update");
        var selectedAsset = this.data.assetId;
        var assetList = "update";
        var propertyID = this.data.propertyId;
        var oldAssetId = this.data.oldAssetId;
        var searchPAC = this.data.searchPAC;
        var reportNumber = this.data.reportNumber;
        var id = this.data.id;
        var limit = this.data.limit;
        var offset = this.data.offset;
        var selectedAssetId = this.data.selectedAssetId;
        this.router.navigate(["user/inspection/updateDeficiency/reviewInspection"], {
            queryParams: {
                selectedAsset: selectedAsset,
                assetList: assetList,
                propertyID: propertyID,
                oldAssetId: oldAssetId,
                id: id,
                searchPAC: searchPAC,
                reportNumber: reportNumber,
                limit: limit,
                offset: offset,
                selectedAssetId: selectedAssetId,
            },
        });
    };
    ViewDeficiencyDialogComponent.prototype.cancel = function () {
        this.dialogRef.close("cancelled");
    };
    ViewDeficiencyDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    ViewDeficiencyDialogComponent.prototype.getJurisdiction = function () {
        var _this = this;
        this.fileCabService.getActiveJurisdictions().subscribe(function (res) {
            _this.jurisdictionList = res;
        }, function (error) {
            _this.loggerService.log(error.error.message);
        });
    };
    ViewDeficiencyDialogComponent.prototype.onSubmit = function (formData) {
        var _this = this;
        this.submittedDef = true;
        if ((this.fromDateError.length == 0 || (this.formattedFromDate && (this.formattedFromDate == 'Invalid date' || this.formattedFromDate == undefined))) && !(this.formGroup.value.correctedBy && this.formGroup.value.correctionComments && this.formGroup.value.defDateCorrected && this.formGroup.value.jurisdictionName)) {
            alert('Please fill missing details in Update Previous Deficiencies pop up');
            return;
        }
        else if (this.fromDateError.length != 0 || !this.formGroup.valid) {
            alert('Please fill missing details in Update Previous Deficiencies pop up');
            return;
        }
        else {
            var name = formData['correctedBy'];
            formData.defDateCorrected = new Date(formData.defDateCorrected._d).toLocaleDateString();
            formData.defDateCorrected = formData.defDateCorrected.replaceAll('/', '-');
            formData.jurisdictionId = this.jurisdictionList.filter(function (jur) { return jur.data == formData.jurisdictionName; })[0].id;
            // this.inspectionService.updatePreviousDefieciencyStatus().subscribe(result)
            delete formData.jurisdictionName;
            this.inspectionService.updatePreviousDefieciencyStatus(formData).subscribe(function (res) {
                alert('Template Saved Successfully');
            }, function (error) {
                if (error.status == 200 && error.error.text == 'OK') {
                    alert('Previous Deficiencies updated successfully.');
                    _this.onNoClick();
                }
                else {
                    alert('Previous Deficiencies not updated successfully.');
                    _this.onNoClick();
                }
            });
        }
        //alert('Previous Deficiencies updated successfully.');
        //this.onNoClick();
    };
    ViewDeficiencyDialogComponent.prototype.checkErrorInFromDate = function (fromDate) {
        this.formattedFromDate = fromDate; //moment(fromDate).format('MM-DD-YYYY').toString();
        this.fromDateError = '';
        if (this.formattedFromDate.length === 0) {
            return this.fromDateError;
        }
        if (this.formattedFromDate.length !== 10) {
            this.fromDateError = '* Enter valid Deficiency Date';
        }
        if (!this.validateDate(this.formattedFromDate)) {
            this.fromDateError = 'Invalid Date Format';
        }
        var date = this.formattedFromDate.split('-');
        if (date.length !== 3 ||
            date[0].length !== 2 ||
            date[1].length !== 2 ||
            date[2].length !== 4) {
            this.fromDateError = ' Enter valid Deficiency Date';
        }
        else if (!moment(this.formattedFromDate, 'MM-DD-YYYY').isValid()) {
            this.fromDateError = ' Enter valid Deficiency Date';
        }
        else if (moment(this.formattedFromDate).isAfter(moment(new Date()))) {
            this.fromDateError =
                ' Future Deficiency Date is not allowed';
        }
        return this.fromDateError;
    };
    ViewDeficiencyDialogComponent.prototype.validateDate = function (inspectionDueDate) {
        var dateformat = /^(0?[1-9]|1[0-2])[\-](0?[1-9]|[1-2][0-9]|3[01])[\-]\d{4}$/;
        // Match the date format through regular expression
        if (inspectionDueDate.match(dateformat)) {
            var operator = inspectionDueDate.split('-');
            // Extract the string into month, date and year
            var datepart = [];
            if (operator.length > 1) {
                datepart = inspectionDueDate.split('-');
            }
            var month = parseInt(datepart[0]);
            var day = parseInt(datepart[1]);
            var year = parseInt(datepart[2]);
            // Create list of days of a month
            var ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
            if (year < 1000 || year > 3000 || month === 0 || month > 12) {
                return '';
            }
            if (month == 1 || month > 2) {
                if (day > ListofDays[month - 1]) {
                    ///This check is for Confirming that the date is not out of its range
                    return '';
                }
            }
            else if (month == 2) {
                var leapYear = false;
                if ((!(year % 4) && year % 100) || !(year % 400)) {
                    leapYear = true;
                }
                if ((leapYear == false) && (day >= 29)) {
                    return '';
                }
                else if ((leapYear == true) && (day > 29)) {
                    return '';
                }
            }
        }
        else {
            return '';
        }
        return inspectionDueDate;
    };
    return ViewDeficiencyDialogComponent;
}());
export { ViewDeficiencyDialogComponent };
