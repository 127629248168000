<!-- this creates a google map on the page with the given lat/lng from -->
<!-- the component as the initial center of the map: -->

<div class="map">
    <div class="row">
        <div class="col-12">
            <div class="custom-flexbox-container custom-snapshot-heading">
                <span class="maps-widget-heading">{{ "Map View"}} </span>
                <span class="maps-widget-heading">
                  <a class="custom-map-link" (click)="openenlargeMap();">Enlarge View</a>
                </span>

            </div>
        </div>
    </div>
    <div>
        <agm-map [zoom]="16" [latitude]="lat" [longitude]="lng" style="height: 423px;">
            <agm-marker *ngFor="let marker of propertyMarker;" [latitude]="marker.lat" [longitude]="marker.lng">
                <agm-info-window [isOpen]="true">{{marker.label}}</agm-info-window>
            </agm-marker>
        </agm-map>
    </div>
</div>
