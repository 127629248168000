import { OnInit, OnDestroy } from '@angular/core';
var InspectionSnapshotComponent = /** @class */ (function () {
    function InspectionSnapshotComponent(inspectorServices, router) {
        this.inspectorServices = inspectorServices;
        this.router = router;
        this.unsubscribe = [];
    }
    InspectionSnapshotComponent.prototype.ngOnInit = function () {
        var _this = this;
        var a = this.inspectorServices.getPendingApprovalPropertyCount().subscribe(function (res) {
            _this.pendingApproval = res;
        });
        this.unsubscribe.push(a);
        var ab = this.inspectorServices.getInspectionSnapshot().subscribe(function (res) { return _this.inspectionSnapshot = res; });
        this.unsubscribe.push(ab);
    };
    InspectionSnapshotComponent.prototype.onFilter = function (key, value) {
        var params = {};
        params[key] = value;
        this.router.navigate(['user', 'general', 'fileCabinet'], { queryParams: params });
    };
    InspectionSnapshotComponent.prototype.navigateToCases = function () {
        this.router.navigate(['user/cases']);
    };
    InspectionSnapshotComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe.forEach(function (res) { return res.unsubscribe(); });
    };
    return InspectionSnapshotComponent;
}());
export { InspectionSnapshotComponent };
