import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { UserDetails } from '../../../../shared/models/user.model';
import { OwnerService } from '../../../../shared/services/owner.service';
import { CartService } from '../../../../inspector/services/cart.service';
import { ConfirmationDialog } from '../../../../inspector/shared/confirmation-popup-dialog/confirmation-dialog.component';
import { LoggerService } from '../../../../core/services/logger/logger.service';
import { StatisticsService } from '../../services/statistics.service';
import { LoginService } from '../../../../login/services/login.service';
import { InspectorService } from '../../../../inspector/services/inspector.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

  public userDetails: UserDetails;
  public currentDate: Date;

  constructor(
    private router: Router,
    private ownerService: OwnerService,
    private cartService: CartService,
    private dialog: MatDialog,
    private loggerService: LoggerService,
    private statService: StatisticsService,
    private loginService: LoginService,
    private inspectorService: InspectorService
  ) { }


  openContactUs() {
    window.location.href = ('https://www.tegrisinc.com/contacts');
  }
  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.currentDate = new Date();

    const cartAlreadyShown = localStorage.getItem('cartDialogShown');
    if (this.userDetails.role.name === 'ROLE_INSPECTOR' && !cartAlreadyShown) {
      localStorage.setItem('cartDialogShown', 'true');

      this.cartService.getCount()
        .subscribe((res: any) => {
          if (res > 0) {
            this.openDialog('login');
          }
        }, err => this.loggerService.log(err));

    }
  }

  checkBeforeLogout() {

    if (this.userDetails.role.name === 'ROLE_INSPECTOR') {

      this.cartService.getCount()
        .subscribe((res: any) => {
          if (res > 0) {
            this.openDialog();
            return;
          }
          this.logout();
        }, err => this.logout());

      return;
    }

    this.logout();
  }

  // clear all subjects here.......
  clearSubjectsBeforeLogout() {
    this.loginService.loggedIn$.next(false);
    this.statService.jurisId$.next(null);
    this.statService.jurisName$.next(null);
    this.ownerService.ownerProperties.next(null);
    this.ownerService.dashboardProperties.next(null);
    this.inspectorService.bidOpportunities.next([]);
    this.inspectorService.isBidOpportunityApiLoaded = false;
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.clearSubjectsBeforeLogout();
    if (environment.env && (environment.env === 'qa'
      || environment.env === 'dev'
      || environment.env === 'pantest'
      || environment.env === 'staging')) {
      this.router.navigate(['login/login-form']);
    } else {
      window.location.href = ('https://www.tegrisinc.com/');
    }
    this.ownerService.preferencesUpdated.next(true);
  }

  openDialog(type = '') {
    const a = this.dialog.open(ConfirmationDialog, {
      data: {
        cartFlag: true,
        content: "There are some item left in cart",
        yesButton: "Move to Cart",
        noButton: "Pay Later"
      }
    });
    a.afterClosed().subscribe(res => {
      if (res) {
        if (!this.router.url.endsWith('cart')) {
          this.router.navigate(['user/inspector/cart']);
        }
        return;
      }

      if (type === 'login') {
        return;
      }

      this.logout();
    });
  }

}
