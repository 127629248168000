import { OnInit } from '@angular/core';
var AppLogoComponent = /** @class */ (function () {
    function AppLogoComponent() {
    }
    AppLogoComponent.prototype.ngOnInit = function () {
        this.isWorx = JSON.parse(localStorage.getItem('userDetails')).role.name === 'ROLE_AUTHORITY_WORX' ?
            true : false;
    };
    return AppLogoComponent;
}());
export { AppLogoComponent };
