import { Injectable, ErrorHandler } from '@angular/core';

import { LoggerService } from '../logger/logger.service';

/**
 * Service to handle exceptions and error
 */
@Injectable({
  providedIn: 'root'
})
export class ExceptionHandlerService implements ErrorHandler {
  /**
   * ExceptionHandlerService constructor
   * @param logger LoggerService to log the values
   */
  constructor(private logger: LoggerService) {}

  /**
   * All the exceptions and errors will be handled by this function
   * @param error Error
   */
  handleError(error: Error) {
    /**
     * TODO: Add any further custom logic for error handling
     * Default: Printing log to server in case of production and on console in case of development
     */
    this.logger.logToServer(error);
  }
}
