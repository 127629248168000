import { MatDialogRef } from '@angular/material';
var AssignPropertyComponent = /** @class */ (function () {
    function AssignPropertyComponent(dialogRef, data, fileCabService, loggerService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.fileCabService = fileCabService;
        this.loggerService = loggerService;
        this.selectedUser = '';
    }
    AssignPropertyComponent.prototype.assignProperty = function () {
        var _this = this;
        this.unsub = this.fileCabService.updatePropertyAssignment(this.selectedUser.split(',')[0], this.data.propertyIds).subscribe(function (result) {
            _this.dialogRef.close('assigned');
        }, function (error) {
            alert(error.error.message);
            _this.loggerService.log(error);
        });
    };
    AssignPropertyComponent.prototype.ngOnDestroy = function () {
        this.unsub && this.unsub.unsubscribe();
    };
    AssignPropertyComponent.prototype.close = function () {
        this.dialogRef.close(null);
    };
    return AssignPropertyComponent;
}());
export { AssignPropertyComponent };
