/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-logo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./app-logo.component";
var styles_AppLogoComponent = [i0.styles];
var RenderType_AppLogoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppLogoComponent, data: {} });
export { RenderType_AppLogoComponent as RenderType_AppLogoComponent };
function View_AppLogoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "tegrisfire"], ["class", "img-responsive logo_img"], ["src", "./assets/images/logo.PNG"]], null, null, null, null, null))], null, null); }
function View_AppLogoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "tegrisfire"], ["class", "img-responsive logo_img"], ["src", "./assets/images/logo_worx.png"]], null, null, null, null, null))], null, null); }
export function View_AppLogoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "custom-logo-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppLogoComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppLogoComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isWorx; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isWorx; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_AppLogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logo", [], null, null, null, View_AppLogoComponent_0, RenderType_AppLogoComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppLogoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppLogoComponentNgFactory = i1.ɵccf("app-logo", i3.AppLogoComponent, View_AppLogoComponent_Host_0, {}, {}, []);
export { AppLogoComponentNgFactory as AppLogoComponentNgFactory };
