<app-dialog>
    <span class="dialog-title">
        <span [innerHTML]="data.title || 'Confirmation'"></span>
        <span class="float-right">
            <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
        </span>
    </span>
    <div class="dialog-content" style="min-height: 15vh;">
        <div style="float: right;">
            <label *ngIf="editMode">
                <button (click)="AddNewCertificate()" matTooltipClass="tooltip-large" matTooltip="Add Certificate"
                    class="custom-buttons-addcert" type="button">
                    <img alt="tegrisfire" src="../../../../assets/images/add_new.png" /> Add Certificate
                </button>
            </label>
        </div>
        <br>
        <div class="form-group">
            <div *ngIf="certificates.data.length == 0"
                style="display: flex; justify-content: center; width: 100%; color: gray; font-size: 30px;">
                Certificates Not Available
            </div>

            <table *ngIf="certificates.data.length > 0" mat-table #table [dataSource]="certificates"
                class="mat-elevation-z8 custom-table" style="width: 100%;">

                <ng-container matColumnDef="certificationName">
                    <mat-header-cell mat-header-cell *matHeaderCellDef> Certification Name </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element" class="p-2" style="display: block;">
                        <div style="display:flex">
                            <input type="text" [(ngModel)]="element.certificationName"
                                [ngModelOptions]="{standalone: true}"
                                class="form-control form-control-sm rounded-form-fields input-without-border-padding"
                                maxlength="40" [disabled]="!editMode" style="display:flex !important;" />
                        </div>
                        <!-- <em class="control-label help-block"
                            *ngIf="editMode && element.certificationName.length === 0  && (element.certificationNumber.length > 0 || element.otherCertExpiryDate.length >0)">
                            <div class="error-messages">
                                Enter Certification Name
                            </div>
                        </em>
                        <em class="control-label help-block" *ngIf="editMode
                            && dataSource.data[i].otherCertificationNumber && dataSource.data[i].otherCertificationNumber.length > 0
                            && (!dataSource.data[i].otherCertExpDate || !dataSource.data[i].otherCertificateImage
                            || (err &&  err['otherCertExpDate'] &&  err.otherCertExpDate[i] && err.otherCertExpDate[i].error))">
                        </em> -->
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="certificationNumber">
                    <mat-header-cell mat-header-cell *matHeaderCellDef> Certification Number</mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element; let i = index;" class="p-2" style="display: block;">
                        <input type="text" [(ngModel)]="element.certificationNumber"
                            class="form-control form-control-sm rounded-form-fields input-without-border-padding"
                            [attr.disabled]="!editMode ? '' : null" [disabled]="!editMode"
                            name="certificationNumber-{{element.id}}" #certificationNumber="ngModel" minlength="5"
                            maxlength="20" />
                        <em class="control-label help-block"
                            *ngIf="certificationNumber && certificationNumber.touched && certificationNumber.invalid && certificationNumber.dirty && (certificationNumber.errors.minlength || certificationNumber.errors.maxlength)">
                            <div class="error-messages">
                                Certification Number is of atleast 5 characters
                            </div>
                        </em>
                        <em class="control-label help-block error-messages" *ngIf="editMode 
                            && element.certificationNumber.length === 0 &&
                            element.otherCertExpiryDate.length > 0">
                            Enter License Number
                        </em>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="otherCertExpiryDate">
                    <mat-header-cell mat-header-cell *matHeaderCellDef> Expiration Date </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element; let i = index" class="p-2" style="display: block;">
                        <div style="display:flex" class="mt-2">
                            <input type="text" appDateChecker appDateChecker
                                (a)="hasErrorInDate($event,i, 'otherCertExpiryDate' )"
                                [(ngModel)]="element.otherCertExpiryDate" [ngModelOptions]="{standalone: true}"
                                class="form-control form-control-sm rounded-form-fields input-without-border-padding"
                                maxlength="12" [disabled]="!editMode" style="display:flex !important;" />
                            <div *ngIf="!editMode && element.otherCertImage">
                                <a [href]="element.otherCertImage" target="_blank">
                                    <label matTooltipClass="tooltip-large" matTooltip="Download Certificate">
                                        <em class="material-icons">arrow_downward</em>
                                    </label>
                                </a>
                            </div>
                            <div *ngIf="editMode" class="image-upload">
                                <label matTooltipClass="tooltip-large"
                                    [matTooltip]="getUploadTooltip(element)">
                                    <label for="file-oCED-{{i}}">
                                        <em class="material-icons">arrow_upward</em>
                                    </label>
                                </label>
                                <!-- <label *ngIf="element.otherCertImage" matTooltipClass="tooltip-large"
                                    matTooltip="Download file">
                                    <label>
                                        <em class="material-icons">arrow_upward</em>
                                    </label>
                                </label> -->
                                <input id="file-oCED-{{i}}" type="file"
                                    accept="application/pdf,image/x-png,image/gif,image/jpeg,,image/tiff"
                                    (change)="setBase64OtherCertificateImage($event,i)" style="display: none;" />
                            </div>

                        </div>
                        <em class="control-label help-block"
                            *ngIf="editMode
                && element.certificationNumber && element.certificationNumber.length > 0
                && (!element.otherCertExpiryDate || !element.otherCertImage
                || (err &&  err['otherCertExpiryDate'] &&  err.otherCertExpiryDate[i] && err.otherCertExpiryDate[i].error))">
                            <div *ngIf="!element.otherCertExpiryDate" class="error-messages">
                                Enter Expiration Date (mm-dd-yyyy)
                            </div>
                            <div *ngIf="(err && err['otherCertExpiryDate'] &&  err.otherCertExpiryDate[i] &&  err.otherCertExpiryDate[i].error && err.otherCertExpiryDate[i].error.length>0)
                && element.otherCertExpiryDate" class="error-messages">
                                {{err.otherCertExpiryDate[i].error}}
                            </div>
                            <div *ngIf="!element.otherCertImage" class="error-messages">
                                Use Arrow to Upload Certificate
                            </div>
                        </em>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions" *ngIf="editMode">
                    <mat-header-cell mat-header-cell *matHeaderCellDef> Actions(s) </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element; let i = index;">
                        <label class="clickableLabel pt-2" (click)="DeleteCertificate(element.id, i)"
                            *ngIf="editMode"><img alt="tegrisfire" src="../../../../assets/images/delete-2.png" />
                        </label>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                <mat-row *matRowDef="let element; columns: columnsToDisplay;let i = dataIndex"
                    class="example-element-row">
                </mat-row>
            </table>
        </div>
    </div>
    <div class="dialog-actions">
        <button class="custom-buttons" (click)="close()">Close</button>
    </div>
</app-dialog>