/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./login.component";
var styles_LoginComponent = [];
var RenderType_LoginComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
export function View_LoginComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["style", "background: #F4F4F4; height: 100vh; margin: 0;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(2, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_LoginComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoginComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i0.ɵccf("app-login", i2.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
