import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
var AuthorityNotificationDialogComponent = /** @class */ (function () {
    function AuthorityNotificationDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    AuthorityNotificationDialogComponent.prototype.ngOnInit = function () {
        this.message = this.data;
    };
    AuthorityNotificationDialogComponent.prototype.okClicked = function () {
        this.dialogRef.close(true);
    };
    AuthorityNotificationDialogComponent.prototype.cancilClicked = function () {
        this.dialogRef.close(false);
    };
    return AuthorityNotificationDialogComponent;
}());
export { AuthorityNotificationDialogComponent };
