<app-dialog>
  <span class="dialog-title">
    Link Property
    <span class="float-right">
      <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
    </span>
  </span>
  <div class="dialog-content">
    <hr />

    <div class="row form-group-padding">
      <div class="col-12">
        <div dialog-content class="notes-sub-heading">Select Property to Link with Case</div>
      </div>
    </div>

    <div class="tableData">
      <table mat-table #table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="blank">
          <mat-header-cell *matHeaderCellDef class="blank-head"></mat-header-cell>
          <mat-cell class="blank-head" *matCellDef="let Property;let i = index;"
          name="userId">
            <mat-radio-group aria-label="Select a Property" [(ngModel)]="selectedPropertyId">
              <mat-radio-button [value]="Property.id" >
              </mat-radio-button>
            </mat-radio-group>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef class="user-name">Name</mat-header-cell>
          <mat-cell *matCellDef="let Property; let i = index;" class="user-name">
            <label class="tableLabel">
              {{Property.buildingName}}
            </label>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="address">
          <mat-header-cell *matHeaderCellDef class="user-address">Address</mat-header-cell>
          <mat-cell *matCellDef="let Property; let i = index;" class="user-address">
            <label class="tableLabel" [innerHTML]="Property.propertyAddress">
            </label>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row *matRowDef="let element; columns: columnsToDisplay;let i = dataIndex" class="example-element-row">
        </mat-row>
      </table>
      <mat-paginator #properties [pageSize]="environment.pageSize" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons
        [length]="dataSource.data.length">
      </mat-paginator>

    </div>
  </div>

      <div class="dialog-actions">

      <div class="form-group row form-group-padding actions">
        <button class="custom-buttons" (click)="selectProperty()">Select Property</button>
        <button class="custom-buttons" (click)="close()">Cancel</button>
      </div>
      </div>


</app-dialog>
