import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SignUpRoles, User } from '../shared/models/signUp.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  // public systemDetails$: BehaviorSubject<SystemDetails[]> = new BehaviorSubject<SystemDetails[]>(null);

  getSignUpRoles(): Observable<SignUpRoles[]> {
    const url = this.baseUrl + '/signup/role';
    return this.http.get<SignUpRoles[]>(url);
  }

  postSignUpDetails(data: User) {
    const url = this.baseUrl + '/signup';
    return this.http.post<User>(url, data);
  }

}
