import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface Address {
  streetLine1: string;
  streetLine2: string;
  zipcode: string;
  subzipcode: string;
  city: string;
  state: string;
  manager?: boolean;
}

@Component({
  selector: 'app-usps-valid-dialog',
  templateUrl: './usps-popUp-valid.component.html',
  styleUrls: ['./usps-popUp-valid.component.scss']
})
export class USPSValidPopUpComponent implements OnInit {

  public addJournalForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<USPSValidPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Address) { }

  ngOnInit() {
    console.log(this.data);
  }

  save() {
    this.dialogRef.close('continue');
  }

  cancel(): void {
    this.dialogRef.close('cancelled');
  }

}
