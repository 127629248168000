<app-dialog>
  <span class="dialog-title">
    Previous Inspection Reports
  <a class="float-right">
    <mat-icon (click)="close()">close</mat-icon>
  </a>
  </span>
  <div class="dialog-content">

  <div class="tableData">
    <table mat-table #table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="inspectReportNo">
        <mat-header-cell *matHeaderCellDef class="report-no">Inspection Report No</mat-header-cell>
        <mat-cell class="report-no" *matCellDef="let report;let i = index;">
          <label class="tableLabel">
            {{report?.inspectionReportNumber}}
          </label>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="inspectDate">
        <mat-header-cell *matHeaderCellDef class="report-inspectDate">Inspected Date</mat-header-cell>
        <mat-cell *matCellDef="let report; let i = index;" class="report-inspectDate">
          <label class="tableLabel">
            {{report?.inspectedDate | date :'MM-dd-yyyy'}}
          </label>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="inspectCompany">
        <mat-header-cell *matHeaderCellDef class="report-inspectCompany">Inspection Company</mat-header-cell>
        <mat-cell *matCellDef="let report; let i = index;" class="report-inspectCompany">
          <label class="tableLabel">
            {{report?.inspectionCompanyName}}
          </label>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="report-actions">Action(s)</mat-header-cell>
        <mat-cell *matCellDef="let report; let i = index;" class="report-actions">
          <label *ngIf = "userRoleId === 2 || userRoleId === 4" (click)="viewReport(report)" class="tableLabel clickableItem">
            View Report
          </label>

                                    <span  class="actionIcons" *ngIf= "(userRoleId == 3 || userRoleId== 9 || userRoleId == 1 || userRoleId == 8)">
                                      <label tooltip="View Report">
                                        <img alt="tegrisfire" src="../../assets/images/icon_view_button.png" height="25px"
                                        (click)="viewReport(report)"/>
                                      </label>
                                    </span>
                                    <span appDataInvestigator appReadOnly class="actionIcons" *ngIf= "(userRoleId == 3 || userRoleId== 9 || userRoleId == 1 || userRoleId == 8)"
                                      >
                                      <label tooltip="Delete Report">
                                        <img alt="tegrisfire" src="../../../../assets/images/delete-2.png" height="25px"
                                        (click)="deleteInspectionReport(report)"/>
                                      </label>
                                    </span>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let element; columns: columnsToDisplay;let i = dataIndex" class="example-element-row">
      </mat-row>
    </table>
    <div class="col-12" *ngIf="dataSource && dataSource.data && dataSource.data.length === 0"
        style="display: flex; justify-content: center; color: grey; opacity: 0.7; font-size: 20px; padding: 2%; border-bottom: 1px solid #d6d6d6">
        No Report(s) found
    </div>
  </div>
</div>

  <div class="dialog-actions">

      <button class="custom-buttons" tabindex="-1" (click)="close()">Close</button>

  </div>
</app-dialog>
