<app-dialog>
  <span class="dialog-title">
    Add Journal Entry
    <span class="float-right">
      <mat-icon class="cursor-pointer" (click)="onNoClick()">close</mat-icon>
    </span>
  </span>
  <div class="add-journal-dialog dialog-content">
    <form [formGroup]="addJournalForm">
      <div class="form-group row form-group-padding">
        <label for="smFormGroupInput"
          class="col-4 col-form-label col-form-label-sm inputLabel notes-sub-heading">Subject <label
            class="mandatory">*</label></label>
        <div class="col-7">
          <input type="text" class="form-control form-control-sm rounded-form-fields" formControlName="subject"
            [(ngModel)]="this.addJournalForm.value.subject" maxlength="100">
        </div>
      </div>
      <div class="form-group row form-group-padding">
        <label for="smFormGroupInput" class="col-4 col-form-label col-form-label-sm inputLabel notes-sub-heading">Access
          Type<label class="mandatory">*</label></label>
        <div class="col-7">
          <select #speceficValue class="rounded-form-fields no-Padding custom-select" formControlName="accessType"
            [(ngModel)]="this.addJournalForm.value.accessType">
            <option value="Private" selected>Private</option>
            <option value="Public">Public</option>
            <option value="Specific">Specific</option>
          </select>
          <div *ngIf="speceficValue.value === 'Specific'" style="margin-top: 14px;" >
            <div class="row">
              <input class="custom-checkbox-jaournal col-4" type="checkbox" (change)="checkAuthority($event)" />
              <input class="custom-checkbox-jaournal col-4" type="checkbox" (change)="checkOwner($event)" />
              <input class="custom-checkbox-jaournal col-4" type="checkbox" (change)="checkInspector($event)"/>
            </div>
            <div class="row" style="text-align: center">
              <label class="col-4 ">Authority</label>
              <label class="col-4 ">Owner</label>
              <label class="col-4 ">Inspector</label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row form-group-padding">
        <label for="smFormGroupInput"
          class="col-4 col-form-label col-form-label-sm inputLabel notes-sub-heading">Content<label
            class="mandatory">*</label></label>
        <div class="col-7">
          <textarea class="col-12 p-0" rows="4" name="comment" formControlName="content"
            [(ngModel)]="this.addJournalForm.value.content"></textarea>
        </div>
      </div>
    </form>
  </div>

  <div class="dialog-actions">
    <button class="custom-buttons" type="button" (click)="onSubmit()" cdkFocusInitial>Submit</button>
    <button class="custom-buttons" button="button" (click)="onNoClick()">Cancel</button>
  </div>
</app-dialog>
