import { Route } from '@angular/router';
import { AuthGuard } from '../login/auth.guard';
import { DashboardComponent } from './dashboard.component';
import { OwnerGuard } from '../shared/services/owner-guard.service';
import { AuthorityGuard } from '../shared/services/authority-guard.service';
import { InspectorGuard } from '../shared/services/inspector-guard.service';
import { AdminGuard } from '../shared/services/admin-guard.service';
import { AuthorityInspectorAdminGuard } from '../shared/services/authority-inspector-admin-guard.service';
import { AuthorityInspectorGuard } from '../shared/services/authority-inspector-guard.service';
var ɵ0 = function () {
    return import("./../owner/owner.module.ngfactory").then(function (mod) { return mod.OwnerModuleNgFactory; });
}, ɵ1 = function () {
    return import("./../property-page/property.module.ngfactory").then(function (mod) { return mod.PropertyModuleNgFactory; });
}, ɵ2 = function () {
    return import("./../file-cabinet/fileCabinet.module.ngfactory").then(function (mod) { return mod.FileCabinetModuleNgFactory; });
}, ɵ3 = function () {
    return import("./../cases/cases.module.ngfactory").then(function (mod) { return mod.CasesModuleNgFactory; });
}, ɵ4 = function () {
    return import("./../metrics/metrics.module.ngfactory").then(function (mod) { return mod.MetricsModuleNgFactory; });
}, ɵ5 = function () {
    return import("./../inspection/inspection.module.ngfactory").then(function (mod) { return mod.InspectionModuleNgFactory; });
}, ɵ6 = function () {
    return import("./../authority/authority.module.ngfactory").then(function (mod) { return mod.AuthorityModuleNgFactory; });
}, ɵ7 = function () {
    return import("./../inspector/inspector.module.ngfactory").then(function (mod) { return mod.InspectorModuleNgFactory; });
}, ɵ8 = function () {
    return import("./../admin/admin.module.ngfactory").then(function (mod) { return mod.AdminModuleNgFactory; });
}, ɵ9 = function () { return import("./../inspector/inspection-result/inspection-result.component.ngfactory").then(function (mod) { return mod.InspectionResultModuleNgFactory; }); };
var routes = [
    {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'owner',
                canActivate: [AuthGuard, OwnerGuard],
                loadChildren: ɵ0
            },
            {
                path: 'addEditProperty',
                canActivate: [AuthGuard],
                loadChildren: ɵ1
            },
            {
                path: 'general',
                canActivate: [AuthGuard, AuthorityInspectorAdminGuard],
                loadChildren: ɵ2
            },
            {
                path: 'cases',
                canActivate: [AuthGuard, AuthorityInspectorGuard],
                loadChildren: ɵ3
            },
            {
                path: 'metrics',
                canActivate: [AuthGuard],
                loadChildren: ɵ4
            },
            {
                path: 'inspection',
                //  canActivate: [AuthGuard , AuthorityInspectorAdminGuard],
                loadChildren: ɵ5
            },
            {
                path: 'authority',
                canActivate: [AuthGuard, AuthorityGuard],
                loadChildren: ɵ6
            },
            {
                path: 'inspector',
                canActivate: [AuthGuard, InspectorGuard],
                loadChildren: ɵ7
            },
            {
                path: 'admin',
                canActivate: [AuthGuard, AdminGuard],
                loadChildren: ɵ8
            },
            {
                path: 'inspectionResult',
                loadChildren: ɵ9,
                canActivate: [AuthGuard, AuthorityInspectorAdminGuard]
            },
            {
                path: '**',
                redirectTo: 'dashboard',
                runGuardsAndResolvers: 'always'
            }
        ],
        runGuardsAndResolvers: 'always'
    }
];
var DashboardRoutingModule = /** @class */ (function () {
    function DashboardRoutingModule() {
    }
    return DashboardRoutingModule;
}());
export { DashboardRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
