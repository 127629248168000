<div class="container p-0" style="max-width: 1920px;">
  <input type="hidden" name="reviewReportComments" value="" id="reviewReportComments" />

  <div style="overflow: visible;">

    <table
      style="width: 100%;
      text-align: left;
      border: 0px;
      border-spacing: 0px;"  
    >

      <thead class="hide-on-print">

        <tr style="float:right" *ngIf="!loading">
          <td class="Button" style="padding-top:20px; padding-right: 20px; ">
            <table style="width: 100%;
              text-align: left;
              border: 0px;
              border-spacing: 0px;">
              <tr>
                <span class="col-3" *ngIf="propId" style="padding:0">
                  <button class="custom-buttons" (click)="emailReport();" type="submit">Email</button>
                </span>
                <span class="col-3" *ngIf="propId" style="padding:0">
                  <span class="col-3" *ngIf="propId" style="padding:0">
                    <button class="custom-buttons" (click)="createPDF();" type="submit">
                      <a [href]="inspectionResultRendering" download target="_blank" style="color:#910123 !important">Create PDF</a></button>
                  </span>
                </span>
                <span class="col-3" style="padding:0">
                  <button class="custom-buttons" type="submit">
                    <a [href]="inspectionResultRendering" download target="_blank" style="color:#910123 !important">Print</a></button>
                </span>
                <span class="col-3" *ngIf="propId" style="padding:0">
                  <button class="custom-buttons" *ngIf="propId" (click)="cancelReport();" type="submit">Cancel</button>
                </span>

              </tr>
            </table>
          </td>
        </tr>

      </thead>
      <tbody *ngIf="!inspectionResultRendering">
        <tr height="100%">
          <td style="width: 100%;" class="print-page">







            <table style="width: 100%;
              text-align: left;
              border: 0px;
              border-spacing: 0px;">
              <input type="hidden" name="pageNumber" value="" id="pageNumber" />
              <input type="hidden" name="comingFrom" value="comingFromComplianceReportShow" id="comingFrom" />
              <input type="hidden" name="ownerUserId" value="3" id="ownerUserId" />
              <input type="hidden" name="prpAssetTypeId" value="" id="prpAssetTypeId" />
              <input type="hidden" name="inspectorUserId" value="70" id="inspectorUserId" />
              <input type="hidden" name="propId" value="3258" id="propId" />
              <input type="hidden" name="reportDataDto.version" value="1" id="version" />
              <input type="hidden" name="reportDataDto.propertyAssetId" value="5996" id="propertyAssetId" />
              <tr id="parentdiv" #printReport>
                <td style="width: 100%; padding:0">
                  <table style="width: 95%;
                    text-align: left;
                    border: 0px;
                    border-spacing: 0px;">
                    <tr>
                      <td style="text-align: left;">
                        <table style="width: 100%;
                          text-align: left;
                          border: 0px;
                          border-spacing: 0px;">
                          <tr>
                            <td style="font-size: smaller;">&nbsp;</td>
                            <td style="font-size: smaller;">
                              <table style="width: 100%;
                                border: 0px;
                                border-spacing: 0px;">
                                <tr>
                                  <td colspan="4" style="width: 100%;">
                                    <table style="width: 100%;
                                      border: 0px;
                                      border-spacing: 0px;">
                                      <tr>

                                        <td style="border-bottom: 3px solid #000000;width: 50%;"
                                          *ngIf="!printBlank || (printBlank && inspectorLetterHead && inspectorLetterHead.headerLogoImage)">
                                          <table style="width: 100%;">
                                            <tbody>
                                              <tr>
                                                <td style="width: 17%;
                                                  text-align: center;">&nbsp;&nbsp;&nbsp;
                                                  <img alt="tegrisfire" style="height:100%;" src="{{inspectorLetterHead?.headerLogoImage}}"
                                                    style="WIDTH: auto; HEIGHT: 80px">
                                                  &nbsp;&nbsp;&nbsp;</td>
                                                <td style="width: 83%;
                                                  text-align: left;
                                                  white-space: nowrap;">
                                                  <p class="letterhead-content"
                                                    [innerHtml]="inspectorLetterHead?.headerText"></p>&nbsp;
                                                  <p>{{inspectorDetails?.address}}</p>

                                                </td>

                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>

                                        <td style="border-bottom: 3px solid #000000; width: 50%"
                                          *ngIf="!printBlank || (printBlank && authorityLetterHead && authorityLetterHead.headerLogoImage)">
                                          <table style="width: 100%;">
                                            <tbody>
                                              <tr class="float-right">
                                                <td style="width: 17%;
                                                  text-align: center;">&nbsp;&nbsp;&nbsp;
                                                  <img alt="tegrisfire" style="height:100%;" src="{{authorityLetterHead?.headerLogoImage}}"
                                                    style="WIDTH: auto; HEIGHT: 80px">
                                                  &nbsp;&nbsp;&nbsp;</td>
                                                <td style="width: 83%;
                                                  text-align: left;
                                                  white-space: nowrap;">
                                                  <p class="letterhead-content reduce-margin"
                                                    [innerHtml]="authorityLetterHead?.headerText">
                                                  </p>&nbsp;

                                                  <p>{{propDetails?.authoriyAddress || inspectionResult?.authoriyAddress}}
                                                  </p>
                                                </td>

                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 0; vertical-align: top;" colspan="4">
                                          <table style="width: 100%;
                                            border-spacing: 0px;">
                                            <tr>
                                              <td
                                                style="border-left: 3px solid #000000; border-right: 3px solid #000000; border-bottom: 3px solid #000000; border-top: 3px solid #000000;
                                                width: 50%; padding: 0; vertical-align:top;">
                                                <table style="width: 100%;
                                                  border: 0px;
                                                  background-color: black;">
                                                  <tr>
                                                    <td style="text-align: center; background-color: black;">
                                                      
                                                        {{inspectionResult?.propertyAsset?.assetType.toUpperCase()}}
                                                        INSPECTION REPORT
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td style="text-align: center; background-color: black;">
                                                      ( One System Per Report)
                                                    </td>
                                                  </tr>

                                                </table>
                                              </td>
                                              <td
                                                style="border-right: 3px solid #000000; border-top: 3px solid #000000; width: 50%; height: 100%">
                                                <table style="width: 100%; border: 0px;">
                                                  <tr>
                                                    <td style="width: 50%; text-align: left;">Today's Date:</td>
                                                    <td *ngIf="propId" style="width: 50%; text-align: left;">
                                                      {{currDate | date: 'MM-dd-yyyy'}}&nbsp;
                                                    </td>
                                                  </tr>
                                                  <tr>

                                                    <td style="width: 50%; text-align: left;">Report Number:</td>
                                                    <td *ngIf="propId" style="width: 50%; text-align: left;">
                                                      {{inspectionResult.inspectionReportNumber }}&nbsp;
                                                    </td>

                                                  </tr>
                                                  <tr>
                                                    <td style="width: 50%; text-align: left;">Inspected Date:</td>
                                                    <td style="width: 50%; text-align: left;">
                                                      {{inspectionResult?.inspectedDate | date: 'MM-dd-yyyy'}}&nbsp;</td>
                                                  </tr>
                                                </table>
                                              </td>
                                            </tr>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="4" style="vertical-align: top;">
                                          <table style="width: 100%; border-spacing: 0;">
                                            <tr>
                                              <td
                                                style="vertical-align: top;width: 50%;padding: 0;border-left: 3px solid #000000; border-right: 3px solid #000000; border-bottom: 3px solid #000000;">
                                                <table style="width: 100%;">
                                                  <tr>
                                                    <td style="width: 50%;">
                                                      <table style="width: 100%;">
                                                        <tr>
                                                          <td style="width: 5%;">

                                                            <mat-checkbox [disabled]="true"></mat-checkbox>
                                                          </td>
                                                          <td style="width: 95%;text-align: left;">Service Test</td>
                                                        </tr>
                                                      </table>
                                                    </td>
                                                    <td style="width: 50%;">
                                                      <table style="width: 100%;">
                                                        <tr>
                                                          <td style="width: 5%;">

                                                            <mat-checkbox [disabled]="true"></mat-checkbox>
                                                          </td>
                                                          <td style="width: 95%;text-align: left;">Repairs</td>
                                                        </tr>
                                                      </table>

                                                    </td>
                                                  </tr>
                                                </table>
                                              </td>
                                              <td
                                                style="width: 50%;border-right: 3px solid #000000; border-bottom: 3px solid #000000;">
                                                <table style="width: 100%;">
                                                  <tr>
                                                    <td style="width: 50%;text-align: left;">Due Date:</td>
                                                    <td style="width: 50%;text-align: left;">
                                                      {{inspectionResult?.propertyAsset?.inspectionDueDate | date: 'MM-dd-yyyy'}}
                                                      &nbsp;</td>
                                                  </tr>
                                                </table>
                                              </td>
                                            </tr>
                                          </table>
                                        </td>
                                      </tr>

                                      <!-- TFPS-162 -->
                                      <tr>
                                        <td colspan="4" style="vertical-align: top;">
                                          <table style="width: 100%;border-spacing: 0;">
                                            <tr>
                                              <td
                                                style="padding: 0;width: 50%;vertical-align: top;border-left: 3px solid #000000; border-right: 3px solid #000000; border-bottom: 3px solid #000000;">
                                                <table style="width: 100%;">
                                                  <tr>
                                                    <td style="width: 50%;">
                                                      <table style="width: 100%;">
                                                        <tr>
                                                          <td style="width: 15%;">
                                                            Inspection Frequency :
                                                          </td>
                                                          <td style="width: 50%;text-align: left;">
                                                            {{inspectionResult?.propertyAsset?.assetInspectionFrequency}}
                                                          </td>
                                                        </tr>
                                                      </table>
                                                    </td>

                                                  </tr>
                                                </table>
                                              </td>

                                            </tr>
                                          </table>
                                        </td>
                                      </tr>

                                      <tr *ngIf="inspectionResult?.hoodType">
                                        <td colspan="4" style="vertical-align: top;">
                                          <table style="width: 100%;border-spacing: 0;">
                                            <tr>
                                              <td
                                                style="padding: 0;width: 100%;vertical-align: top;border-left: 3px solid #000000; border-right: 3px solid #000000; border-bottom: 3px solid #000000;">
                                                <table style="width: 100%;">
                                                  <tr>
                                                    <td style="width: 25%;">
                                                      <table>
                                                        <tr>
                                                          <td style="width: 5%;">
                                                            <mat-checkbox [disabled]="true"
                                                              [checked]="inspectionResult.hoodType == 'Kitchen/Range Hood'">
                                                            </mat-checkbox>
                                                          </td>
                                                          <td style="width: 95%;text-align: left;">Kitchen/Range Hood</td>
                                                        </tr>
                                                      </table>
                                                    </td>
                                                    <td style="width: 25%;">
                                                      <table>
                                                        <tr>
                                                          <td style="width: 5%;">
                                                            <mat-checkbox [disabled]="true"
                                                              [checked]="inspectionResult.hoodType == 'Paint Spray Booth'">
                                                            </mat-checkbox>
                                                          </td>
                                                          <td style="width: 95%;text-align: left;">Paint Spray Booth</td>
                                                        </tr>
                                                      </table>
                                                    </td>
                                                    <td style="width: 25%;">
                                                      <table>
                                                        <tr>
                                                          <td style="width: 5%;">
                                                            <mat-checkbox [disabled]="true"
                                                              [checked]="inspectionResult.hoodType == 'Fume Hood'">
                                                            </mat-checkbox>
                                                          </td>
                                                          <td style="width: 95%;text-align: left;">Fume Hood</td>
                                                        </tr>
                                                      </table>
                                                    </td>
                                                    <td style="width: 25%;">
                                                      <table style="width: 100%;">
                                                        <tr>
                                                          <td style="width: 5%;">
                                                            <mat-checkbox [disabled]="true"
                                                              [checked]="inspectionResult.hoodType !== 'Kitchen/Range Hood' && inspectionResult.hoodType !== 'Paint Spray Booth' && inspectionResult.hoodType !== 'Fume Hood'">
                                                            </mat-checkbox>
                                                          </td>
                                                          <td style="width: 20%;text-align: left;">Other</td>
                                                          <td
                                                            *ngIf="inspectionResult.hoodType !== 'Kitchen/Range Hood' && inspectionResult.hoodType !== 'Paint Spray Booth' && inspectionResult.hoodType !== 'Fume Hood'"
                                                            style="width: 35%;text-align: left;border-bottom: 3px solid #000000;text-align: center;">
                                                            {{inspectionResult?.hoodType}}
                                                          </td>
                                                          <td style="width: 40%;"></td>
                                                        </tr>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </table>
                                              </td>
                                            </tr>
                                          </table>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td colspan="4">
                                          <table style="width: 100%;border-spacing: 0;">
                                            <tr>
                                              <td
                                                style="padding: 0;width: 50%;border-left: 3px solid #000000; border-right: 3px solid #000000; border-bottom: 3px solid #000000;">
                                                <table style="width: 100%;">
                                                  <tr>
                                                    <td style="width: 40%;text-align: left;">System Location :</td>
                                                    <td style="width: 60%;text-align: left;">
                                                      {{inspectionResult?.propertyAsset?.assetLocation}} &nbsp;</td>
                                                  </tr>
                                                  <tr>
                                                    <td style="width: 40%;text-align: left;">Coverage Area :</td>
                                                    <td style="width: 60%;text-align: left;">
                                                      {{inspectionResult?.propertyAsset?.assetCoverage}} &nbsp;</td>
                                                  </tr>
                                                </table>
                                              </td>
                                              <td
                                                style="padding: 0;width: 50%;border-right: 3px solid #000000; border-bottom: 3px solid #000000;">
                                                <table style="width: 100%;">
                                                  <tr>
                                                    <td style="width: 50%;text-align: left;">Certification Given :</td>
                                                    <td style="width: 50%;">
                                                      <table style="width: 100%;">
                                                        <tr>
                                                          <td style="width: 33%;">
                                                            <table style="width: 100%;">
                                                              <tr>
                                                                <td  style="width: 33%;background-color: red;">&nbsp;</td>
                                                                <td  style="width: 33%;text-align: left;">RED</td>
                                                                <td  style="width: 33%;text-align: left;">

                                                                  <mat-checkbox [disabled]="true"
                                                                    [checked]="inspectionResult.certificateGiven == 'Red'">
                                                                  </mat-checkbox>
                                                                </td>
                                                              </tr>
                                                            </table>
                                                          </td>
                                                          <td style="width: 33%;">
                                                            <table style="width: 100%;">
                                                              <tr>
                                                                <td style="width: 33%;background-color: yellow;">&nbsp;</td>
                                                                <td style="width: 33%;text-align: left;">YELLOW</td>
                                                                <td style="width: 33%;text-align: left;">

                                                                  <mat-checkbox [disabled]="true"
                                                                    [checked]="inspectionResult.certificateGiven == 'Yellow'">
                                                                  </mat-checkbox>
                                                                </td>
                                                              </tr>
                                                            </table>
                                                          </td>
                                                          <td style="width: 33%;">
                                                            <table style="width: 100%;">
                                                              <tr>
                                                                <td style="width: 33%;background-color: white;">&nbsp;</td>
                                                                <td style="width: 33%;text-align: left;">WHITE</td>
                                                                <td style="width: 33%;text-align: left;">

                                                                  <mat-checkbox [disabled]="true"
                                                                    [checked]="inspectionResult.certificateGiven == 'White'">
                                                                  </mat-checkbox>
                                                                </td>
                                                              </tr>
                                                            </table>
                                                          </td>
                                                        </tr>
                                                      </table>
                                                    </td>
                                                </table>
                                              </td>
                                            </tr>
                                          </table>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>


                                <tr>
                                  <td colspan="4" align="center" bgcolor="#99ccff"
                                    style="padding-top: 6px; padding-bottom: 6px; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 3px solid #000000;">
                                    <strong><em>PROPERTY CONTACT INFORMATION</em></strong></td>
                                </tr>


                                <tr>
                                  <td colspan="4">
                                    <table width="100%" cellspacing="0" cellpadding="0">
                                      <tr>
                                        <td width="50%" valign="top">
                                          <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                            <tr>
                                              <td colspan="5" align="center"
                                                style="padding-top: 6px; padding-bottom: 6px; border-left: 3px solid #000000; border-right: 3px solid #000000;">
                                                <u><strong>Occupant Information </strong></u></td>
                                            </tr>
                                            <tr>
                                              <td width="40%" colspan="2"
                                                style="border-left: 3px solid #000000; border-top: 1px solid #000000; border-bottom: 1px solid #000000;">
                                                &nbsp;Bldg
                                                Name:</td>
                                              <td width="40%" colspan="2"
                                                style="border-bottom: 1px solid #000000; border-top: 1px solid #000000;">
                                                {{propDetails?.propertyName}}&nbsp;
                                              </td>
                                              <td width="20%"
                                                style="border-bottom: 1px solid #000000; border-top: 1px solid #000000; border-right: 3px solid #000000;">
                                                &nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td colspan="4" style="border-left: 3px solid #000000;">
                                                <table width="100%">
                                                  <tr>
                                                    <td valign="top">&nbsp;Address:</td>
                                                  </tr>
                                                  <tr>
                                                    <td colspan="2" valign="top">&nbsp;&nbsp; {{propDetails?.address1 }}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td colspan="2" valign="top">&nbsp;&nbsp;
                                                      {{propDetails?.jurisdiction}}
                                                      &nbsp;
                                                      &nbsp;
                                                    </td>
                                                  </tr>
                                                </table>
                                              </td>
                                              <td style="border-right: 3px solid #000000;">&nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td width="20%" style="border-left: 3px solid #000000;">&nbsp;</td>
                                              <td width="20%">&nbsp;</td>
                                              <td width="20%">&nbsp;</td>
                                              <td width="20%">&nbsp;</td>
                                              <td width="20%" style="border-right: 3px solid #000000;">
                                                &nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td width="20%" style="border-left: 3px solid #000000;">&nbsp;</td>
                                              <td width="20%">&nbsp;</td>
                                              <td width="20%">&nbsp;</td>
                                              <td width="20%">&nbsp;</td>
                                              <td width="20%" style="border-right: 3px solid #000000;">&nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td width="20%" style="border-left: 3px solid #000000;">&nbsp;</td>
                                              <td width="20%">&nbsp;</td>
                                              <td width="20%">&nbsp;</td>
                                              <td width="20%">&nbsp;</td>
                                              <td width="20%" style="border-right: 3px solid #000000;">&nbsp;</td>
                                            </tr>
                                          </table>
                                        </td>
                                        <td width="50%" valign="top" style="border-right: 3px solid #000000;">
                                          <table width="100%" cellspacing="0" border="0" cellpadding="0">
                                            <tr>
                                              <td colspan="5" align="center"
                                                style="padding-top: 6px; padding-bottom: 6px;"><u><strong>Responsible
                                                    Person </strong></u></td>
                                            </tr>
                                            <tr>
                                              <td width="20%"
                                                style="border-top: 1px solid #000000; border-bottom: 1px solid #000000;">
                                                &nbsp;Name:</td>
                                              <td width="80%" colspan="4"
                                                style="border-top: 1px solid #000000; border-bottom: 1px solid #000000;">

                                                &nbsp;{{propManagerDetails?.firstName}}&nbsp;
                                                {{propManagerDetails?.lastName}}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td colspan="5">
                                                <table>
                                                  <tr>
                                                    <td width="20%" valign="top">&nbsp;Address:</td>
                                                  </tr>


                                                  <tr>
                                                    <td colspan="2">&nbsp;
                                                      {{propManagerDetails?.streetLine1}}&nbsp;

                                                      <br />&nbsp;&nbsp;{{propManagerDetails?.streetLine2}}&nbsp;

                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td width="40%">&nbsp;

                                                      {{propManagerDetails?.zipcode || ''}}



                                                    </td>
                                                  </tr>





                                                </table>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="30%" style="border-bottom: 1px solid #000000;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;Phone :</td>
                                              <td colspan="4" style="border-bottom: 1px solid #000000;">

                                                &nbsp;
                                                {{propManagerDetails?.phoneNum}}&nbsp;


                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="20%" style="border-bottom: 1px solid #000000;">
                                                &nbsp;Email:</td>
                                              <td width="40%" colspan="2" style="border-bottom: 1px solid #000000;">


                                                {{propManagerDetails?.email}}&nbsp;



                                              </td>
                                              <td width="20%" style="border-bottom: 1px solid #000000;">
                                                &nbsp;</td>
                                              <td width="20%" style="border-bottom: 1px solid #000000;">
                                                &nbsp;</td>
                                            </tr>
                                          </table>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>

                                <tr>
                                  <td colspan="4" align="center" bgcolor="#99ccff"
                                    style="padding-top: 6px; padding-bottom: 6px; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 3px solid #000000; border-top: 3px solid #000000;">
                                    <strong><em>INSPECTION AND TESTING CONTRACTOR INFORMATION</em></strong></td>
                                </tr>

                                <tr>
                                  <td colspan="4">
                                    <table width="100%" cellspacing="0" cellpadding="0">
                                      <tr>
                                        <td width="50%" valign="top"
                                          style="border-left: 3px solid #000000; border-right: 3px solid #000000; ">
                                          <table width="100%" cellspacing="0" cellpadding="0">
                                            <tr>
                                              <td colspan="5" align="center"
                                                style="padding-top: 6px; padding-bottom: 6px; border-bottom: 1px solid #000000;">
                                                <u><strong>Contractor Information </strong></u></td>
                                            </tr>
                                            <tr>
                                              <td colspan="2" width="40%" style="border-bottom: 1px solid #000000;">
                                                &nbsp;Company Name:</td>
                                              <td width="40%" colspan="2" style="border-bottom: 1px solid #000000;">
                                                &nbsp; {{inspectorDetails?.name}} &nbsp;</td>
                                              <td width="20%" style="border-bottom: 1px solid #000000;">
                                                &nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td width="20%" style="border-bottom: 1px solid #000000;">
                                                &nbsp;Phone:</td>
                                              <td width="40%" colspan="2" style="border-bottom: 1px solid #000000;">
                                                {{inspectorDetails?.contact}} &nbsp;
                                              </td>
                                              <td width="20%" style="border-bottom: 1px solid #000000;">
                                                &nbsp;</td>
                                              <td width="20%" style="border-bottom: 1px solid #000000;">
                                                &nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td colspan="4">
                                                <table>
                                                  <tr>
                                                    <td width="20%" valign="top">&nbsp;Address:</td>
                                                  </tr>
                                                  <tr>
                                                    <td valign="top" colspan="2" width="60%"
                                                      style="padding-top: 1px; padding-bottom: 1px;">
                                                      &nbsp;&nbsp;&nbsp; {{inspectorDetails?.address}}
                                                      &nbsp;</td>
                                                  </tr>
                                                  <tr>
                                                    <td valign="top" colspan="2"
                                                      style="padding-top: 1px; padding-bottom: 1px;">
                                                      &nbsp;&nbsp;&nbsp; {{inspectorDetails?.streetLine2}}&nbsp;</td>
                                                  </tr>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="20%" style="border-bottom: 1px solid #000000;">
                                                &nbsp;Email:</td>
                                              <td width="40%" colspan="2" style="border-bottom: 1px solid #000000;">
                                                {{inspectorDetails?.email}}&nbsp;</td>
                                              <td width="20%" style="border-bottom: 1px solid #000000;">
                                                &nbsp;</td>
                                              <td width="20%" style="border-bottom: 1px solid #000000;">
                                                &nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td colspan="5" align="left" style="border-bottom: 1px solid #000000;">
                                                &nbsp;State Contractor License #: &nbsp; &nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td colspan="3" align="left"
                                                style="padding-bottom: 0px; border-bottom: 1px solid #000000;">
                                                &nbsp;License #:</td>
                                              <td colspan="2" align="left" style="border-bottom: 1px solid #000000;">
                                                {{inspectorDetails?.licenceNo}}
                                                &nbsp;</td>
                                            </tr>

                                            <tr>
                                              <td>&nbsp;
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;
                                              </td>
                                            </tr>
                                          </table>
                                        </td>
                                        <td width="50%" valign="top" style="border-right: 3px solid #000000;">
                                          <table width="100%" cellspacing="0" cellpadding="0">
                                            <tr>
                                              <td colspan="5" align="center"
                                                style="padding-top: 6px; padding-bottom: 6px; border-bottom: 1px solid #000000; ">
                                                <u><strong>Inspector/Tester Information </strong></u></td>
                                            </tr>
                                            <tr>
                                              <td width="20%" style="border-bottom: 1px solid #000000;">
                                                &nbsp;Name:</td>
                                              <td width="80%" colspan="4" style="border-bottom: 1px solid #000000; ">
                                                {{inspectionResult?.inspectedByName}}&nbsp;</td>

                                            </tr>
                                            <tr>
                                              <td width="20%" style="border-bottom: 1px solid #000000;">
                                                &nbsp;Phone#:</td>
                                              <td width="40%" colspan="2" style="border-bottom: 1px solid #000000;">
                                                {{inspectionResult?.inspectedByCertInspectorContact}}
                                                &nbsp;</td>
                                              <td width="20%" style="border-bottom: 1px solid #000000;">
                                                &nbsp;</td>
                                              <td width="20%" style="border-bottom: 1px solid #000000; ">
                                                &nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td colspan="3" align="center"
                                                style="border-bottom: 2px solid #000000;  font-size: 13px; font-weight: bold;">
                                                &nbsp;</td>
                                              <td colspan="3" align="center"
                                                style="border-bottom: 2px solid #000000; font-size: 13px; font-weight: bold;">
                                                &nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td colspan="5" align="center" style="border-bottom: 2px solid #000000;">
                                                CERTIFICATIONS </td>
                                            </tr>
                                            <tr>
                                              <td align="right" style="border-bottom: 2px solid #000000;" width="40%">
                                                State Certification:&nbsp;</td>
                                              <td style="border-bottom: 2px solid #000000; " width="10%">
                                                {{inspectionResult?.stateCertification}}
                                                &nbsp;</td>
                                              <td style="border-bottom: 2px solid #000000; " colspan="3">&nbsp;
                                              </td>

                                            </tr>
                                            <tr>
                                              <td align="right" style="border-bottom: 2px solid #000000;" width="40%">
                                                National/NICET:&nbsp;</td>
                                              <td style="border-bottom: 2px solid #000000; " width="10%">
                                                {{inspectionResult?.nationalCertification}}

                                                &nbsp;
                                              </td>
                                              <td style="border-bottom: 2px solid #000000; " colspan="3">&nbsp;
                                              </td>
                                            </tr>
                                            <tr>
                                              <td align="right" style="border-bottom: 2px solid #000000;" width="40%">
                                                Other Cert:&nbsp;</td>
                                              <td style="border-bottom: 2px solid #000000; " width="10%">

                                                {{inspectionResult?.otherCertification}}

                                                &nbsp;
                                              </td>
                                              <td style="border-bottom: 2px solid #000000; " width="40%" align="center">
                                                TYPE:
                                              </td>
                                              <td style="border-bottom: 2px solid #000000; " width="10%" align="center">
                                                {{inspectionResult?.certType}}

                                                &nbsp;
                                              </td>
                                              <td style="border-bottom: 2px solid #000000; ">&nbsp;
                                              </td>
                                            </tr>
                                            <tr>
                                              <td align="right" style="border-bottom: 2px solid #000000;">
                                                &nbsp;</td>
                                              <td colspan="4" style="border-bottom: 2px solid #000000; ">
                                                &nbsp;</td>
                                            </tr>

                                            <tr>
                                              <td>&nbsp;
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;
                                              </td>
                                            </tr>
                                          </table>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>


                                <tr>
                                  <td colspan="4" align="center" bgcolor="#99ccff"
                                    style="padding-top: 6px; padding-bottom: 6px; border-left: 3px solid #000000; border-right: 3px solid #000000; border-top: 3px solid #000000; border-bottom: 3px solid #000000;">
                                    <strong><em>COMMERCIAL HOOD MANUFACTURER CERTIFICATIONS</em></strong></td>
                                </tr>

                                <tr>
                                  <td colspan="4" style="padding: 0;">
                                    <table width="100%" cellspacing="0" cellpadding="0"
                                      style="border-bottom: 3px solid #000000;">


                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="4" align="center" bgcolor="#99ccff"
                                    style="padding-top: 6px; padding-bottom: 6px; border-left: 3px solid #000000; border-right: 3px solid #000000; border-top: 3px solid #000000; border-bottom: 3px solid #000000;">
                                    <strong><em>MONITORING INFORMATION</em></strong></td>
                                </tr>

                                <tr>
                                  <td colspan="4" valign="top" style="padding: 0;">
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                      <tr>
                                        <td width="50%" height="100%" valign="top"
                                          style="border-left: 3px solid #000000; border-right: 1px solid #000000; border-bottom: 3px solid #000000;">
                                          <table width="100%" cellspacing="0" cellpadding="0">
                                            <tr>
                                              <td width="60%" colspan="2" align="left"
                                                style="border-bottom: 1px solid #000000;">
                                                &nbsp;Central Station &nbsp;Monitoring?</td>
                                              <td width="40%" style="border-bottom: 1px solid #000000;">
                                                <table width="100%">
                                                  <tr>
                                                    <td width="50%">

                                                      <mat-checkbox [disabled]="true"
                                                        [checked]="inspectionResult.isCentralMonitoring == true">
                                                      </mat-checkbox>
                                                      &nbsp; Y
                                                    </td>
                                                    <td width="50%">

                                                      <mat-checkbox [disabled]="true"
                                                        [checked]="inspectionResult.isCentralMonitoring == false">
                                                      </mat-checkbox>
                                                      &nbsp; N
                                                    </td>
                                                  </tr>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="55%" align="left" style="border-bottom: 1px solid #000000;">
                                                &nbsp;Monitoring Company:</td>
                                              <td colspan="2" align="left" style="border-bottom: 1px solid #000000;">
                                                {{inspectionResult?.monitoringCompany}} &nbsp;
                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="40%" align="left">&nbsp;System Make:</td>
                                              <td colspan="2" align="left">
                                                {{inspectionResult?.manufacturer}} &nbsp;
                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="40%" align="right">&nbsp;</td>
                                              <td colspan="2" align="right">&nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td width="40%" align="right">&nbsp;</td>
                                              <td colspan="2" align="right">&nbsp;</td>
                                            </tr>

                                          </table>
                                        </td>
                                        <td width="50%" height="100%" valign="top"
                                          style="border-bottom: 3px solid #000000; border-right: 3px solid #000000;">
                                          <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                            <tr>
                                              <td colspan="2" align="left" width="50%"
                                                style="border-bottom: 1px solid #000000;">&nbsp;
                                                &nbsp; &nbsp;</td>
                                              <td width="40%" style="border-bottom: 1px solid #000000;">
                                                &nbsp;</td>
                                              <td width="10%" style="border-bottom: 1px solid #000000;">&nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td width="30%" style="border-bottom: 1px solid #000000;" align="left">
                                                &nbsp;Phone #:</td>
                                              <td colspan="3" style="border-bottom: 1px solid #000000;">&nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td width="40%" align="left">&nbsp;System Model:</td>
                                              <td colspan="3">
                                                {{inspectionResult?.modelNumber}} &nbsp;
                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="30%" align="right">&nbsp;</td>
                                              <td colspan="3">&nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td width="30%" align="right">&nbsp;</td>
                                              <td colspan="3">&nbsp;</td>
                                            </tr>

                                          </table>
                                        </td>
                                      </tr>

                                    </table>
                                  </td>
                                </tr>
                              </table>
                              <table width="100%" cellspacing="0" cellpadding="0">

                                <tr>
                                  <td colspan="4" align="center" bgcolor="#99ccff"
                                    style="padding-top: 6px; padding-bottom: 6px; border-left: 3px solid #000000; border-right: 3px solid #000000; border-top: 3px solid #000000; border-bottom: 3px solid #000000;">
                                    <strong><em>DEFICIENCY / CORRECTION DETAILS</em></strong></td>
                                </tr>

                                <tr>
                                  <td colspan="4">
                                    <table width="100%" cellspacing="0" cellpadding="0">
                                      <tr>
                                        <td width="30%" align="left"
                                          style="border-left: 3px solid #000000; border-bottom: 3px solid #000000;">
                                          &nbsp;Any Deficiencies &nbsp;Found?</td>
                                        <td width="20%" colspan="2" align="center"
                                          style="border-bottom: 3px solid #000000; border-right: 1px solid #000000;">
                                          <table width="100%" cellspacing="0" cellpadding="0">
                                            <tr>
                                              <td width="50%">

                                                <mat-checkbox [checked]="(inspectionResult && inspectionResult.inspectionDeficiency && inspectionResult.inspectionDeficiency.length > 0)
                                                  && propId" [disabled]="true"></mat-checkbox>
                                                &nbsp; Y
                                              </td>
                                              <td width="50%">

                                                <mat-checkbox [checked]="(inspectionResult && !inspectionResult.inspectionDeficiency
                                                  || (inspectionResult.inspectionDeficiency && inspectionResult.inspectionDeficiency.length < 0
                                                  ) && propId)" [disabled]="true"></mat-checkbox>
                                                &nbsp; N
                                              </td>
                                            </tr>
                                          </table>
                                        </td>
                                        <td width="30%" colspan="2" align="left"
                                          style="border-bottom: 3px solid #000000;">&nbsp;Any
                                          Deficiencies &nbsp;Remaining?</td>
                                        <td width="20%" colspan="2" align="center"
                                          style="border-bottom: 3px solid #000000; border-right: 3px solid #000000;">
                                          <table width="100%" cellspacing="0" cellpadding="0">
                                            <tr>
                                              <td width="50%">
                                                <mat-checkbox [checked]="!noCorrectionRequired && propId"
                                                  [disabled]="true">
                                                </mat-checkbox>
                                                &nbsp; Y
                                              </td>
                                              <td width="50%">
                                                <mat-checkbox [checked]="noCorrectionRequired && propId"
                                                  [disabled]="true">
                                                </mat-checkbox>
                                                &nbsp; N
                                              </td>
                                            </tr>
                                          </table>
                                        </td>

                                      </tr>
                                      <tr>
                                        <td width="30%" colspan="2" align="center"
                                          style="border-left: 3px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          <u>Deficiency Found</u></td>
                                        <td width="20%" align="center"
                                          style="border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          <u>Comments</u></td>
                                        <td width="10%" align="center"
                                          style="border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          <u>Corrected?</u></td>
                                        <td width="15%" align="center"
                                          style="border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          <u>Correction Date :</u></td>
                                        <td width="15%" align="center"
                                          style="border-bottom: 1px solid #000000; border-right: 3px solid #000000;"
                                          colspan="2">
                                          <u>Corrected By:</u></td>
                                        <td width="15%" align="center"
                                          style="border-bottom: 1px solid #000000; border-right: 3px solid #000000;"
                                          colspan="2">
                                          <u>Attachment:</u></td>
                                      </tr>

                                      <tr *ngFor="let def of inspectionResult?.inspectionDeficiency">
                                        <td colspan="2"
                                          style="border-left: 3px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          {{def.description}}</td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          {{def.correctionComments}}</td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          <input type="checkbox" [checked]="def.isCorrected" [value]="def.isCorrected"
                                            [disabled]="true"></td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          {{def.dateCorrected | date : 'MM-dd-yyyy'}}</td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 3px solid #000000;"
                                          colspan="2">{{def.correctedBy}}
                                        </td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 3px solid #000000;"
                                          colspan="2"><a
                                            class="font-weight-bold contacts-content-font breakWord text-fields certificate"
                                            *ngIf="def && def.image && def.image.imageName"
                                            href="{{def.image.attachment}}" target="_blank">{{def.image.imageName}}</a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2"
                                          style="border-left: 3px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          &nbsp;</td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          &nbsp;</td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          &nbsp;</td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          &nbsp;</td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 3px solid #000000;"
                                          colspan="2">
                                          &nbsp;</td>
                                      </tr>
                                      <tr>
                                        <td colspan="2"
                                          style="border-left: 3px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          &nbsp;</td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          &nbsp;</td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          &nbsp;</td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          &nbsp;</td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 3px solid #000000;"
                                          colspan="2">
                                          &nbsp;</td>
                                      </tr>
                                      <tr>
                                        <td colspan="2"
                                          style="border-left: 3px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          &nbsp;</td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          &nbsp;</td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          &nbsp;</td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          &nbsp;</td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 3px solid #000000;"
                                          colspan="2">
                                          &nbsp;</td>
                                      </tr>
                                      <tr>
                                        <td colspan="2"
                                          style="border-left: 3px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          &nbsp;</td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          &nbsp;</td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          &nbsp;</td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000;">
                                          &nbsp;</td>
                                        <td style="border-bottom: 1px solid #000000; border-right: 3px solid #000000;"
                                          colspan="2">
                                          &nbsp;</td>
                                      </tr>
                                      <tr>
                                        <td colspan="2"
                                          style="border-left: 3px solid #000000; border-bottom: 3px solid #000000; border-right: 1px solid #000000;">
                                          &nbsp;</td>
                                        <td style="border-bottom: 3px solid #000000; border-right: 1px solid #000000;">
                                          &nbsp;</td>
                                        <td style="border-bottom: 3px solid #000000; border-right: 1px solid #000000;">
                                          &nbsp;</td>
                                        <td style="border-bottom: 3px solid #000000; border-right: 1px solid #000000;">
                                          &nbsp;</td>
                                        <td style="border-bottom: 3px solid #000000; border-right: 3px solid #000000;"
                                          colspan="2">
                                          &nbsp;</td>
                                      </tr>


                                    </table>
                                  </td>
                                </tr>
                              </table>
                              <table width="100%" cellspacing="0" cellpadding="0">

                                <tr>
                                  <td colspan="4" align="center" bgcolor="#99ccff"
                                    style="padding-top: 6px; padding-bottom: 6px; border-left: 3px solid #000000; border-right: 3px solid #000000; border-top: 3px solid #000000; border-bottom: 3px solid #000000;">
                                    <strong><em>NOTES</em></strong></td>
                                </tr>

                                <tr>
                                  <td colspan="4">
                                    <table width="100%" cellspacing="0" cellpadding="0">

                                      <tr>
                                        <td colspan="4"
                                          style="border-left: 3px solid #000000; border-bottom: 1px solid #000000;"
                                          width="40%" align="left">&nbsp;Authority Review Notes?</td>
                                        <td colspan="2" width="20%" style="border-bottom: 1px solid #000000;">
                                          <table width="100%" cellspacing="0" cellpadding="0">
                                            <tr>
                                              <td width="50%">

                                                <mat-checkbox [disabled]="true"></mat-checkbox>
                                                &nbsp; Y
                                              </td>
                                              <td width="50%">

                                                <mat-checkbox [disabled]="true"></mat-checkbox>
                                                &nbsp; N
                                              </td>
                                            </tr>
                                          </table>

                                        </td>
                                        <td colspan="3" width="30%" align="center"
                                          style="border-bottom: 1px solid #000000;">
                                          &nbsp;
                                        </td>
                                        <td width="10%"
                                          style="border-bottom: 1px solid #000000; border-right: 3px solid #000000;">
                                          &nbsp;</td>
                                      </tr>
                                      <tr>
                                        <td width="100%" colspan="10" style="border-left: 3px solid #000000; border-bottom: 1px solid #000000; border-right: 3px solid #000000;
                              max-width: 200px; word-wrap: break-word;">

                                          &nbsp;
                                        </td>

                                      </tr>
                                      <tr>
                                        <td width="10%"
                                          style="border-left: 3px solid #000000; border-bottom: 3px solid #000000;">&nbsp;
                                        </td>
                                        <td width="10%" style="border-bottom: 3px solid #000000;">&nbsp;</td>
                                        <td width="10%" style="border-bottom: 3px solid #000000;">&nbsp;</td>
                                        <td width="10%" style="border-bottom: 3px solid #000000;">&nbsp;</td>
                                        <td width="10%" style="border-bottom: 3px solid #000000;">&nbsp;</td>
                                        <td width="10%" style="border-bottom: 3px solid #000000;">&nbsp;</td>
                                        <td width="10%" style="border-bottom: 3px solid #000000;">&nbsp;</td>
                                        <td width="10%" style="border-bottom: 3px solid #000000;">&nbsp;</td>
                                        <td width="10%" style="border-bottom: 3px solid #000000;">&nbsp;</td>
                                        <td width="10%"
                                          style="border-bottom: 3px solid #000000; border-right: 3px solid #000000;">
                                          &nbsp;</td>
                                      </tr>

                                      <tr>
                                        <td colspan="4"
                                          style="border-left: 3px solid #000000; border-bottom: 1px solid #000000;"
                                          width="40%" align="left">&nbsp;Inspection Company Remarks?</td>
                                        <td colspan="2" width="20%" style="border-bottom: 1px solid #000000;">
                                          <table width="100%" cellspacing="0" cellpadding="0">
                                            <tr>
                                              <td width="50%">

                                                <mat-checkbox [disabled]="true"></mat-checkbox>
                                                &nbsp; Y
                                              </td>
                                              <td width="50%">

                                                <mat-checkbox [disabled]="true"></mat-checkbox>
                                                &nbsp; N
                                              </td>
                                            </tr>
                                          </table>

                                        </td>
                                        <td colspan="3" align="center" width="30%"
                                          style="border-bottom: 1px solid #000000;">
                                          &nbsp;
                                        </td>
                                        <td width="10%"
                                          style="border-bottom: 1px solid #000000; border-right: 3px solid #000000;">
                                          &nbsp;</td>
                                      </tr>
                                      <tr>
                                        <td width="100%" colspan="10" style="border-left: 3px solid #000000; border-bottom: 1px solid #000000; border-right: 3px solid #000000;
                              max-width: 200px; word-wrap: break-word;">

                                          &nbsp;
                                        </td>

                                      </tr>
                                      <tr>
                                        <td width="10%"
                                          style="border-left: 3px solid #000000; border-bottom: 3px solid #000000;">&nbsp;
                                        </td>
                                        <td width="10%" style="border-bottom: 3px solid #000000;">&nbsp;</td>
                                        <td width="10%" style="border-bottom: 3px solid #000000;">&nbsp;</td>
                                        <td width="10%" style="border-bottom: 3px solid #000000;">&nbsp;</td>
                                        <td width="10%" style="border-bottom: 3px solid #000000;">&nbsp;</td>
                                        <td width="10%" style="border-bottom: 3px solid #000000;">&nbsp;</td>
                                        <td width="10%" style="border-bottom: 3px solid #000000;">&nbsp;</td>
                                        <td width="10%" style="border-bottom: 3px solid #000000;">&nbsp;</td>
                                        <td width="10%" style="border-bottom: 3px solid #000000;">&nbsp;</td>
                                        <td width="10%"
                                          style="border-bottom: 3px solid #000000; border-right: 3px solid #000000;">
                                          &nbsp;</td>
                                      </tr>

                                    </table>
                                  </td>
                                </tr>
                              </table>


                              <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                style="border-left: 3px solid #000000; border-bottom: 3px solid #000000; border-right: 3px solid #000000; border-top: 3px solid #000000;">




                                <tr>
                                  <td colspan="4">
                                    <table width="100%">
                                      <tr>
                                        <td align="left"><strong>Refer to the local and state standards for a complete
                                            description
                                            of the inspection, testing, and maintenance requirements. The inspection
                                            results
                                            provided in this report are based only on the system's current ability to
                                            operate
                                            and shall not be construed as an evaluation of the system's performance
                                            capabilities
                                            to adequately protect the property. Information in this report may or may not
                                            include
                                            possible performance concerns and should not be considered all inclusive. It
                                            is solely
                                            the property owner's responsibility to determine if a separate investigation
                                            should be
                                            done to ensure the system will perform as designed and that the property is
                                            adequately
                                            protected. It is also the property owner's responsibility to maintain the
                                            system, property
                                            and any possible environmental conditions that may affect the system's
                                            operability and performance.
                                          </strong></td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="4" align="center" bgcolor="#99ccff"
                                    style="padding-top: 6px; padding-bottom: 6px; border-top: 3px solid #000000; border-bottom: 3px solid #000000;">
                                    <strong><em>SYSTEM FUNCTIONALITY</em></strong></td>
                                </tr>
                                <tr>
                                  <app-questions [questions]="questions" [qtd]="qtd" [images]="images"
                                    [printBlank]="printBlank" [inspectionDataforSecondPage]="inspectionDataforSecondPage">
                                  </app-questions>
                                </tr>
                                <tr>
                                  <td>&nbsp;</td>
                                </tr>
                              </table>
                              <table width="100%" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td colspan="10" align="center" bgcolor="#99ccff"
                                    style="padding-top: 6px; padding-bottom: 6px; border-top: 3px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 3px solid #000000;">
                                    <strong><em>INSPECTOR'S DECLARATION</em></strong></td>
                                </tr>
                                <tr>
                                  <td width="10%"
                                    style="border-bottom:3px solid #000000;border-left: 3px solid #000000; border-right: 1px solid #000000;"
                                    align="center">


                                    <mat-checkbox [checked]="true" [disabled]="true"></mat-checkbox>
                                  </td>
                                  <td width="90%" colspan="9"
                                    style="border-bottom:3px solid #000000;border-right: 3px solid #000000;">&nbsp;<span
                                      style="font-weight: normal"> By checking here you are certifying, under penalty of
                                      perjury,
                                      that you are a valid agent of your company representing that the company maintains
                                      all the
                                      necessary licenses and/or certifications to perform this service for this system in
                                      this
                                      jurisdiction
                                      AND THAT the company has properly inspected this system consistent with state and
                                      local
                                      standards AND
                                      THAT the system has been properly tagged or labeled and the property owner or
                                      responsible
                                      person
                                      has been
                                      notified of the inspection results, the system status, and any corrective
                                      actions.</span>
                                  </td>
                                </tr>

                              </table>
                              <table width="100%" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td colspan="10" align="center" bgcolor="#99ccff"
                                    style="padding-top: 6px; padding-bottom: 6px; border-top: 3px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 3px solid #000000;">
                                    <strong><em>AUTHORITY SYSTEM INFORMATION </em></strong></td>
                                </tr>
                                <tr>
                                  <td colspan="10"
                                    style="border-left: 3px solid #000000; border-bottom: 3px solid #000000; border-right: 3px solid #000000;">

                                    &nbsp;&nbsp;
                                  </td>
                                </tr>
                              </table>
                              <table width="100%" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td colspan="10" align="center" bgcolor="#99ccff"
                                    style="padding-top: 6px; padding-bottom: 6px; border-top: 3px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 3px solid #000000;">
                                    <strong><em>AUTHORITY REMARKS </em></strong></td>
                                </tr>
                                <tr>
                                  <td colspan="10"
                                    style="border-left: 3px solid #000000; border-bottom: 3px solid #000000; border-right: 3px solid #000000;">

                                    {{inspectionResult?.authorityRemarks}}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <input type="hidden" name="contextPath" id="contextPath" value="/tegrisfire" />
                    </tr>


                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </tbody>
      <div *ngIf="inspectionResultRendering" id="hide-on-print">
        <pdf-viewer [src]="inspectionResultRendering" [zoom]="2.0" [original-size]="true" [fit-to-page]="false"  (text-layer-rendered)="textLayerRendered($event)"></pdf-viewer>
      </div>
      <tr class="hide-on-print" *ngIf="!loading">
        <td class="Button" style="padding-top:10px; padding-bottom: 20px;  padding-right: 20px;">
          <table align="right" border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td class="Button">
                <table align="right" border="0" cellspacing="0" cellpadding="0" class="hide-on-print">
                  <tr>

                    <!-- Report Action Changes Start-->
                    <span class="col-3" *ngIf="propId" style="padding:0">
                      <button class="custom-buttons" (click)="emailReport();" type="submit">Email</button>
                    </span>
                    <span class="col-3" *ngIf="propId" style="padding:0">
                      <button class="custom-buttons" (click)="createPDF();" type="submit">
                        <a [href]="inspectionResultRendering" download target="_blank" style="color:#910123 !important">Create PDF</a></button>
                    </span>
                    <span class="col-3" style="padding:0">
                      <button class="custom-buttons" type="submit">
                      <a [href]="inspectionResultRendering" download target="_blank" style="color:#910123 !important">Print</a></button>
                    </span>
                    <span class="col-3" *ngIf="propId" style="padding:0">
                      <button class="custom-buttons" *ngIf="propId" (click)="cancelReport();"
                        type="submit">Cancel</button>
                    </span>

                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>

    </table>
  </div>
  <div class="lds-ripple" *ngIf="loading">
    <div></div>
    <div></div>
  </div>
</div>