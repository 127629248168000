<app-dialog *ngIf="nameOfAction != 'updatePreviousDeficiency'">

  <span class="dialog-title">
    <label>
      {{(deficiencies && deficiencies.length > 0) ? 'Deficiencies' : ''}}
    </label>
    <span class="float-right">
      <mat-icon class="cursor-pointer" (click)="onNoClick()">close</mat-icon>
    </span>
   </span>
  <div class="dialog-content">
    <div style="overflow-y: scroll; max-height: 70vh">
      <table class="def-table" *ngIf="deficiencies && deficiencies.length > 0">
        <tr>
          <th> Deficiency </th>
          <th> Date Corrected</th>
          <th> Corrected By</th>
          <th> Correction Comments</th>
          <th> Inspector Report Comments</th>
          <th> Authority Review Report Comments</th>
          <th> Attachment </th>
        </tr>
        <tr *ngFor="let def of deficiencies">
          <td>{{def.description}}</td>
          <td>{{def.isCorrected ? (def.dateCorrected | date : 'MM-dd-yyyy') : ''}}</td>
          <td>{{def.isCorrected ? def.correctedBy : ''}}</td>
          <td>{{def.correctionComments}}</td>
          <td>{{inspectorRemarks}}</td>
          <td>{{authorityRemarks}}</td>
          <td><a class="font-weight-bold contacts-content-font breakWord text-fields certificate defFont"
              *ngIf="def && def.image && def.image[0] && def.image[0].imageName" href="{{def.image[0].attachment}}"
              target="_blank">{{def.image[0].imageName}}</a></td>
        </tr>
      </table>
      <table class="def-table" *ngIf="deficiencies && deficiencies.length === 0 && authorityRemarks">
        <tr>
          <th> Authority Review Report Comments</th>
        </tr>
        <tr>
          <td>{{authorityRemarks}}</td>
        </tr>
      </table>
    </div>
  </div>
  <div class="dialog-actions">
    <button tabindex="-1" appReadOnly appDataInvestigator
    *ngIf="deficiencies && deficiencies.length > 0"
    class="custom-buttons" (click)="updateReport()"
    >Update Report</button>
    <button tabindex="-1" type="button" class="custom-buttons margin-no" (click)="onNoClick()">Close</button>
  </div>
</app-dialog>

<app-dialog *ngIf="nameOfAction == 'updatePreviousDeficiency'">
  <span class="dialog-title">
    <label>
      Update Previous Deficiencies
    </label>
    <span class="float-right">
      <mat-icon class="cursor-pointer" (click)="onNoClick()">close</mat-icon>
    </span>
   </span>
  <div class="dialog-content">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
    <div class="row h6 text-dark form-group">
      <div class="col-12" style="margin-left: 15px">
        <div class="row">
          <table style="width: 100%">

            <tbody>
              <tr>
                <td style = "padding: 20px 20px;">
                  Jurisdiction:<label
                  _ngcontent-bjl-c49=""
                  style="color: red"
                  >*</label>
                  <select
              class="col-12 col-xl-11 col-form-label col-form-label-sm rounded-form-fields no-Padding custom-select" formControlName="jurisdictionName"
              >
              <option value="">Select</option>
              <option  style = "font-weight: 500;" class="form-control"   id="jurisdiction" required  *ngFor="let Jurisdiction of jurisdictionList" [value]="Jurisdiction.data" name="jurisdication" >
                {{Jurisdiction.data}}
              </option>
            </select>
                </td>
                <td style = "padding: 20px 20px;width: 22%;">
                  Deficiency Date Corrected:<label

                  style="color: red;margin-top: 28px; "
                  >*</label>

                  <div  class="date-range">

                      <input class="form-control form-control-sm rounded-form-fields"  formControlName='defDateCorrected' [matDatepicker]="pickerFrom"
                      #datepickerFrom type="text"  id="defDateCorrected'">
                    <div class="cal-icon">
                      <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                      <mat-datepicker  #pickerFrom style="z-index: -999;"></mat-datepicker>
                    </div>
                  </div>




                    <div [hidden]="f.defDateCorrected.untouched" *ngIf="checkErrorInFromDate(datepickerFrom.value).length>0" >
                      <label style="color: red;">
                          *{{fromDateError}}
                      </label>
                  </div>

                  <div  style="font-size:13px;"> Enter Date Corrected in the following
                    format MM-DD-YYYY
                  </div>
                </td>

                <td style = "padding: 20px 20px;">
                  Corrected By:<label
                  _ngcontent-bjl-c49=""
                  style="color: red"
                  >*</label>
                  <div >
                    <input  type="text" required  class="form-control form-control form-control-sm rounded-form-fields" id="correctedBy"
                       formControlName="correctedBy" />

                      </div>
                </td>

                <td style = "padding: 20px 20px;">

                Correction comments:<label
                  _ngcontent-bjl-c49=""
                  style="color: red"
                  >*</label
                >
                <div>
                <textarea
                  rows="3"
                  cols="40"
                  name="additionalRemarks"
                  formControlName="correctionComments"
                  id = 'correctionComments'
                  #correctionComments


                  required

                >
                </textarea>

                <div
                  *ngIf="f.correctionComments.touched &&  f.correctionComments.invalid && f.correctionComments.length > 1000"
                >
                <label style="color: red;">
                  * Maximum 1000 characters allowed.
                </label>
                </div>
              </div>


            </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div style="margin-left : 35%">
    <button  tabindex="-1" appReadOnly appDataInvestigator type="submit" class="custom-buttons">
      Submit</button>
    <button tabindex="-1" type="button" class="custom-buttons margin-no" (click)="onNoClick()">Close</button>
  </div>
  </form>

















</div>
</app-dialog>
