import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { PropertyMarker } from '../../../core/models/property/property.model';

@Component({
  selector: 'app-inspector-details',
  templateUrl: './inspector-details.component.html',
  styleUrls: ['./inspector-details.component.scss']
})
export class InspectorDetailsComponent implements OnInit {

  @Input() propertyMarker: PropertyMarker[];

  isInspector: boolean;
  constructor() { }

  ngOnInit() {
    this.isInspector =
    JSON.parse(localStorage.getItem('userDetails')).role.description.toLowerCase().includes('inspector')
    ? true : false;
  }

}
