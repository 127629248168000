<mat-card class="example-card mat-elevation-z2">
  <mat-card-header class="pl-0">
    <mat-card-title>Statistics</mat-card-title>
  </mat-card-header>
  <mat-card-content class="p-0">
    <div *ngIf="latestBidNotification">
      <div
        class="row m-0 pt-3"
        style="flex-wrap: nowrap"
        (click)="navigateToBidOppurtunity()"
        *ngIf="latestBidNotification"
      >
        <div
          class="latest blink-new col-4"
          style="line-height: normal; margin-right: 10px"
        >
          NEW BID OPPORTUNITY
        </div>
        <div class="col-8 widget-title">
          {{ latestBidNotification?.buildingName }}
        </div>
      </div>
    </div>
    <div class="row m-0 pt-3">
      <div class="col-9 widget-title">Properties Inspected</div>
      <div
        class="col-3 widget-content"
        style="display: flex; align-items: center"
      >
        {{ statistics?.Properties_Inspected }}
      </div>
    </div>
    <div class="row m-0 pt-3">
      <div class="col-9 widget-title">Inspections Complete</div>
      <div
        class="col-3 widget-content"
        style="display: flex; align-items: center"
      >
        {{ statistics?.Inspections_Complete }}
      </div>
    </div>
    <div class="row m-0 pt-3 pb-3">
      <div class="col-9 widget-title">No. of Inspection Credits Remaining</div>
      <div
        class="col-3 widget-content"
        style="display: flex; align-items: center"
      >
        {{ statistics?.Inspection_Credit_Remaining }}
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card class="example-card mat-elevation-z2" style="height: 100px">
  <mat-card-header class="clickableLabel">
    <mat-card-title routerLink="/user/inspector/credits"
      >Purchase Credits</mat-card-title
    >
  </mat-card-header>
</mat-card>

<!-- Authority Statistics is declared in shared module because
        of chances of cyclic dependenciyes between authority module and shared module -->
