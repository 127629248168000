import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MatPaginator, MatTableDataSource, MAT_DIALOG_DATA, PageEvent } from '@angular/material';
import { FileCabinetService } from '../../core/services/file-cabinet/file-cabinet.service';
import { ParentComponent } from '../../shared/components/parent.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-change-owner',
  templateUrl: './change-owner.component.html',
  styleUrls: ['./change-owner.component.scss']
})
export class ChangeOwnerComponent extends ParentComponent {

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @ViewChild('owners', { static: false }) paginator: MatPaginator;
  columnsToDisplay: string[] = ['blank', 'name', 'address'];
  searchText = '';
  owners: any[];
  selectedOwnerId: -1;
  unsub: Subscription[] = [];
  limit: number = 10;
  offset: number = 0;
  pageEvent: PageEvent;

  constructor(public dialogRef: MatDialogRef<ChangeOwnerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fileCabService: FileCabinetService) {
    super();
  }

  ngAfterViewInit() {
    this.search();
  }

  search() {
    this.unsub.push(this.fileCabService.getAllOwners(this.limit, this.offset, this.searchText).subscribe(
      res => {
        this.owners = res;
        this.owners = res.contactList;
            this.paginator.length = res.count;
            this.dataSource.paginator = this.paginator;
            this.dataSource.data = new Array(res.count);
            this.dataSource.data.splice(
              this.offset,
              this.limit,
              ...res.contactList
            );
            this.dataSource._updateChangeSubscription();

        for (const owner of this.owners) {
          owner.name = owner.firstName + ' ' + owner.lastName;
        }
      })
    );
  }

  onPaginateChange(event) {
    this.offset = event.pageIndex * event.pageSize;
    this.limit = event.pageSize;
    this.search();
  }

  resetPagination() {
    this.offset = 0;
    this.limit = this.environment.pageSize || 10;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
  }
  
  removeOwner() {
    this.unsub.push(this.fileCabService.changeOwner(this.data.id, -1).subscribe(
      res => { this.dialogRef.close(null); },
      error => { alert(error.error.message); }
    ));
  }

  selectOwner() {
    if (this.selectedOwnerId) {
      if (this.data) {
        this.unsub.push(this.fileCabService.changeOwner(this.data.id, this.selectedOwnerId).subscribe(
          res => { this.dialogRef.close(null); },
          error => { alert(error.error.message); }
        ));
      } else {
        this.fileCabService.setOwnerDetails(this.dataSource.data.find((x) => x.id === this.selectedOwnerId));
        this.dialogRef.close(null);
      }
    }
  }

  ngOnDestroy() {
    this.unsub.map(elem => elem.unsubscribe());
  }

  close() {
    this.dialogRef.close(null);
  }

}
