/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./usps-popUp-invalid.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/app-dialog/app-dialog.component.ngfactory";
import * as i3 from "../../../shared/components/app-dialog/app-dialog.component";
import * as i4 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "@angular/common";
import * as i7 from "./usps-popUp-invalid.component";
import * as i8 from "@angular/material/dialog";
var styles_USPSInvalidPopUpComponent = [i0.styles];
var RenderType_USPSInvalidPopUpComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_USPSInvalidPopUpComponent, data: {} });
export { RenderType_USPSInvalidPopUpComponent as RenderType_USPSInvalidPopUpComponent };
function View_USPSInvalidPopUpComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["cdkFocusInitial", ""], ["class", "custom-buttons"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.continue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Continue"]))], null, null); }
export function View_USPSInvalidPopUpComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "app-dialog", [], null, null, null, i2.View_AppDialogComponent_0, i2.RenderType_AppDialogComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppDialogComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "span", [["class", "dialog-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "mat-icon", [["class", "cursor-pointer mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(8, 0, null, 1, 3, "div", [["class", "dialog-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "form-group row form-group-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "label", [["class", "col-form-label col-form-label-sm inputLabel notes-sub-heading"], ["style", "padding-left: 10px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" The address provided is not valid "])), (_l()(), i1.ɵeld(12, 0, null, 2, 6, "div", [["class", "dialog-actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_USPSInvalidPopUpComponent_1)), i1.ɵdid(14, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["cdkFocusInitial", ""], ["class", "custom-buttons"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.abort() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Abort"])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "custom-buttons"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.tryAgain() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Try Again"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 6, 0); var currVal_3 = _co.checkAccess(); _ck(_v, 14, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.data.manager === true) ? "MANAGER ADDRESS" : "PROPERTY ADDRESS"); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).inline; var currVal_2 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_1, currVal_2); }); }
export function View_USPSInvalidPopUpComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-usps-valid-dialog", [], null, null, null, View_USPSInvalidPopUpComponent_0, RenderType_USPSInvalidPopUpComponent)), i1.ɵdid(1, 114688, null, 0, i7.USPSInvalidPopUpComponent, [i8.MatDialogRef, i8.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var USPSInvalidPopUpComponentNgFactory = i1.ɵccf("app-usps-valid-dialog", i7.USPSInvalidPopUpComponent, View_USPSInvalidPopUpComponent_Host_0, {}, {}, []);
export { USPSInvalidPopUpComponentNgFactory as USPSInvalidPopUpComponentNgFactory };
