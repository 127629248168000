import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {
  FormGroup} from '@angular/forms';


@Component({
  selector: 'app-del-system-dialog',
  templateUrl: 'app-del-system-dialog.component.html'
})

// const body = this.systemDetails;
export class DelSystemDialog {
  constructor(
    public dialogRef: MatDialogRef<DelSystemDialog>,
    @Inject(MAT_DIALOG_DATA) public data: boolean) {}

  ngOnInit() {

  }

  deletionRejected() {
    this.dialogRef.close(false);
  }

  deletionApproved(){
    this.dialogRef.close(true);
  }
}
