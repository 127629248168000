import * as tslib_1 from "tslib";
import { EnlargeMapComponent } from "../../shared/components/map-view/enlarge-map/enlarge-map.component";
import { VerifyPacComponent } from "../../inspection/inspection-properties/verify-pac/verify-pac.component";
import { CertifyBypassComponent } from "../../inspection/inspection-properties/certify-bypass/certify-bypass.component";
import * as fs from "file-saver";
import * as Excel from "exceljs/dist/exceljs.min.js";
import { ViewDeficiencyDialogComponent } from "../../inspection/view-deficiency-dialog/view-deficiency-dialog.component";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@angular/router";
import * as i3 from "./inspection/inspection.service";
var UtilityService = /** @class */ (function () {
    function UtilityService(dialog, router, inspectionService) {
        this.dialog = dialog;
        this.router = router;
        this.inspectionService = inspectionService;
        this.EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        this.EXCEL_EXTENSION = ".xlsx";
    }
    UtilityService.prototype.openEnlargeMap = function (propertyMarker) {
        var lat = 47.6062;
        var lng = -122.3321;
        if (propertyMarker && propertyMarker.length > 0) {
            lat = propertyMarker[0].lat;
            lng = propertyMarker[0].lng;
        }
        var dialogRef = this.dialog.open(EnlargeMapComponent, {
            height: "840px",
            minWidth: "350px",
            width: "1500px",
            data: {
                properties: propertyMarker,
                latitude: lat,
                longitude: lng,
            },
        });
    };
    UtilityService.prototype.setPropertyOnMap = function (propertyList) {
        var e_1, _a;
        var propertyMarker = [];
        try {
            for (var propertyList_1 = tslib_1.__values(propertyList), propertyList_1_1 = propertyList_1.next(); !propertyList_1_1.done; propertyList_1_1 = propertyList_1.next()) {
                var property = propertyList_1_1.value;
                if (property &&
                    property.latitude !== null &&
                    property.longitude !== null) {
                    propertyMarker.push({
                        propertyId: property.id,
                        lat: property.latitude,
                        lng: property.longitude,
                        label: property.buildingName || property.propertyName,
                    });
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (propertyList_1_1 && !propertyList_1_1.done && (_a = propertyList_1.return)) _a.call(propertyList_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return propertyMarker;
    };
    UtilityService.prototype.setPropertyAndSystemOnMap = function (property, systems) {
        var propertyMarker = [];
        if (property && property.latitude !== null && property.longitude !== null) {
            propertyMarker.push({
                propertyId: property.id,
                lat: property.latitude,
                lng: property.longitude,
                label: property.buildingName || property.propertyName,
            });
        }
        for (var i = 0; i < systems.length && propertyMarker.length < 10; ++i) {
            if (systems[i].latitude !== null && systems[i].longitude !== null) {
                var propName = "";
                var propId = "";
                if (property) {
                    propName = property.buildingName || property.propertyName;
                    propId = property.id && property.id.toString();
                    if (propName && propName.length > 0) {
                        propName = propName + " - ";
                    }
                }
                propertyMarker.push({
                    lat: systems[i].latitude,
                    lng: systems[i].longitude,
                    label: propName + systems[i].assetType,
                    propertyId: propId,
                    propertyAssetId: systems[i].id.toString(),
                });
            }
        }
        return propertyMarker;
    };
    UtilityService.prototype.navigateToInspectionPropertyAsset = function (pac, propertyId, isPACSearch, fileCabinetRedirect, isSimplifiedReportEnabled, isImageUploadEnabled, jurisIDs, userRoleID) {
        var _this = this;
        if (pac === void 0) { pac = "0"; }
        if (isPACSearch === void 0) { isPACSearch = false; }
        if (fileCabinetRedirect === void 0) { fileCabinetRedirect = false; }
        if (userRoleID != 9 && (!pac || pac.length === 0 || isPACSearch)) {
            var dialogRef = this.dialog.open(VerifyPacComponent, {
                width: "800px",
                minWidth: "350px",
                data: {
                    PropertyId: propertyId,
                    pac: pac === "0" ? "" : pac,
                    jurisIDs: jurisIDs,
                },
                disableClose: true,
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result === "cancelled") {
                }
                else if (result === "bypass") {
                    var dialogCertifyRef = _this.dialog.open(CertifyBypassComponent, {
                        width: "800px",
                        height: "500px",
                        minWidth: "350px",
                        disableClose: true,
                    });
                    dialogCertifyRef.afterClosed().subscribe(function (certified) {
                        if (certified) {
                            if (fileCabinetRedirect) {
                                _this.router.navigate([
                                    "user/inspection/searchInspectionProperties/getInspectionAssetsByProperty",
                                ], {
                                    queryParams: {
                                        id: propertyId.toString(),
                                        pac: "0",
                                        fileCabinetRedirect: fileCabinetRedirect,
                                        isSimplifiedReportEnabled: isSimplifiedReportEnabled,
                                        isImageUploadEnabled: isImageUploadEnabled,
                                        jurisIDs: jurisIDs,
                                    },
                                });
                            }
                            else {
                                _this.router.navigate([
                                    "user/inspection/searchInspectionProperties/getInspectionAssetsByProperty",
                                ], {
                                    queryParams: {
                                        id: propertyId.toString(),
                                        pac: "0",
                                        isSimplifiedReportEnabled: isSimplifiedReportEnabled,
                                        isImageUploadEnabled: isImageUploadEnabled,
                                        jurisIDs: jurisIDs,
                                    },
                                });
                            }
                        }
                    });
                    // this.router.navigate(['getInspectionAssetsByProperty'], { queryParams: { id: property.propertyId, pac: '0' } });
                    // 2066 TODO: Remove if not required at all
                    // } else if (result === "navigate") {
                    //   const user: UserDetails = JSON.parse(
                    //     localStorage.getItem("userDetails")
                    //   );
                    //   if (user.role.id === Roles.ROLE_OWNER_ADMIN) {
                    //     this.router.navigate(["user/owner/dashboard"]);
                    //   } else if (
                    //     user.role.id === Roles.ROLE_AUTHORITY_ADMIN ||
                    //     user.role.id === Roles.ROLE_AUTHORITY_WORX
                    //   ) {
                    //     this.router.navigate(["user/authority/dashboardAuthority"]);
                    //   } else if (user.role.id === Roles.ROLE_INSPECTOR_ADMIN) {
                    //     this.router.navigate(["user/inspector/dashboardInspector"]);
                    //   } else if (user.role.id === Roles.ROLE_RFA) {
                    //     this.router.navigate(["user/authority/dashboardAuthority"]);
                    //   } else if (user.role.id === Roles.ROLE_SITE_ADMIN) {
                    //     this.router.navigate(["user/admin/dashboardSiteAdmin"]);
                    //   }
                }
                else {
                    if (fileCabinetRedirect) {
                        _this.router.navigate([
                            "user/inspection/searchInspectionProperties/getInspectionAssetsByProperty",
                        ], {
                            queryParams: {
                                id: propertyId,
                                pac: result,
                                fileCabinetRedirect: fileCabinetRedirect,
                                isSimplifiedReportEnabled: isSimplifiedReportEnabled,
                                isImageUploadEnabled: isImageUploadEnabled,
                                jurisIDs: jurisIDs,
                            },
                        });
                    }
                    else {
                        _this.router.navigate([
                            "user/inspection/searchInspectionProperties/getInspectionAssetsByProperty",
                        ], {
                            queryParams: {
                                id: propertyId,
                                pac: result,
                                isSimplifiedReportEnabled: isSimplifiedReportEnabled,
                                isImageUploadEnabled: isImageUploadEnabled,
                                jurisIDs: jurisIDs,
                            },
                        });
                    }
                }
            });
        }
        else {
            if (userRoleID === 9) {
                pac = "0";
            }
            this.router.navigate([
                "user/inspection/searchInspectionProperties/getInspectionAssetsByProperty",
            ], {
                queryParams: {
                    id: propertyId,
                    pac: pac,
                    fileCabinetRedirect: fileCabinetRedirect,
                    isSimplifiedReportEnabled: isSimplifiedReportEnabled,
                    isImageUploadEnabled: isImageUploadEnabled,
                },
            });
        }
    };
    UtilityService.prototype.eachColumnInRange = function (ws, col1, col2, cb) {
        for (var c = col1; c <= col2; c++) {
            var col = ws.getColumn(c);
            cb(col);
        }
    };
    UtilityService.prototype.autofitColumns = function (ws) {
        // no good way to get text widths
        this.eachColumnInRange(ws, 1, ws.columnCount, function (column) {
            var maxWidth = 15;
            column.eachCell(function (cell) {
                var e_2, _a;
                if (!cell.isMerged && cell.value) {
                    // doesn't handle merged cells
                    var text = "";
                    if (typeof cell.value != "object") {
                        // string, number, ...
                        text = cell.value.toString();
                    }
                    else if (cell.value.richText) {
                        // richText
                        text = cell.value.richText.reduce(function (text, obj) { return text + obj.text.toString(); }, "");
                    }
                    // handle new lines -> don't forget to set wrapText: true
                    var values = text.split(/[\n\r]+/);
                    try {
                        for (var values_1 = tslib_1.__values(values), values_1_1 = values_1.next(); !values_1_1.done; values_1_1 = values_1.next()) {
                            var value = values_1_1.value;
                            var width = value.length;
                            // if (cell.font && cell.font.bold) {
                            //   width *= 1.08; // bolding increases width
                            // }
                            maxWidth = Math.max(maxWidth, width);
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (values_1_1 && !values_1_1.done && (_a = values_1.return)) _a.call(values_1);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                }
            });
            maxWidth += 0.71; // compensate for observed reduction
            maxWidth += 1; // buffer space
            column.width = maxWidth;
        });
    };
    // columnWebWorker(headerArray) {
    //   const worker = new InlineWorker(() => {
    //     // START OF WORKER THREAD CODE
    //     console.log("Start worker thread, wait for postMessage: ");
    //     const calculateCountOfPrimeNumbers = (headerArray) => {
    //       const columns = [];
    //       headerArray.map((key) => {
    //         if (key.toLowerCase().indexOf("date") !== -1) {
    //           columns.push({
    //             header: key,
    //             key: key,
    //             width: key.length < 12 ? 13 : key.length + 3,
    //             style: { numFmt: "mm-dd-yyyy" },
    //           });
    //         } else {
    //           columns.push({
    //             header: key,
    //             key: key,
    //             // width: key.length < 12 ? 13 : key.length + 3
    //           });
    //         }
    //       });
    //       // this is from DedicatedWorkerGlobalScope ( because of that we have postMessage and onmessage methods )
    //       // and it can't see methods of this class
    //       // @ts-ignore
    //       postMessage({
    //         columns: columns,
    //       });
    //     };
    //     // @ts-ignore
    //     onmessage = (evt) => {
    //       console.log("Calculation started: " + new Date());
    //       console.log(evt.data);
    //       calculateCountOfPrimeNumbers(evt.data.headerArray);
    //     };
    //     // END OF WORKER THREAD CODE
    //   });
    //   worker.postMessage({ headerArray: headerArray });
    //   worker.onmessage().subscribe((data) => {
    //     console.log("Calculation done: ", new Date() + " " + data.data);
    //     this._columns = data.data.columns;
    //     worker.terminate();
    //   });
    //   worker.onerror().subscribe((data) => {
    //     console.log(data);
    //   });
    // }
    UtilityService.prototype.chunkIteration = function (arrayToChunk, index, lengthOfChunk, operationFunc, resolve) {
        var _this = this;
        var j, l = arrayToChunk.length;
        for (j = index + lengthOfChunk; index < j && index < l; index += 1) {
            operationFunc(arrayToChunk[index]);
        }
        if (l > index) {
            setTimeout(function () {
                _this.chunkIteration(arrayToChunk, index, lengthOfChunk, operationFunc, resolve);
            }, 10);
        }
        else {
            resolve();
        }
    };
    UtilityService.prototype.exportAsExcelFile = function (reports, fileName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_3, _a, e_4, _b, workbook, worksheet, headerArray, columns, headerArray_1, headerArray_1_1, key, _c, _d, _e, i, column, duration;
            var _this = this;
            return tslib_1.__generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        workbook = new Excel.Workbook();
                        worksheet = workbook.addWorksheet("Data");
                        headerArray = Object.keys(reports[0]);
                        columns = [];
                        try {
                            for (headerArray_1 = tslib_1.__values(headerArray), headerArray_1_1 = headerArray_1.next(); !headerArray_1_1.done; headerArray_1_1 = headerArray_1.next()) {
                                key = headerArray_1_1.value;
                                if (key.toLowerCase().indexOf("date") !== -1) {
                                    columns.push({
                                        header: key,
                                        key: key,
                                        width: key.length < 12 ? 13 : key.length + 3,
                                        style: { numFmt: "mm-dd-yyyy" },
                                    });
                                }
                                else {
                                    columns.push({
                                        header: key,
                                        key: key,
                                    });
                                }
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (headerArray_1_1 && !headerArray_1_1.done && (_a = headerArray_1.return)) _a.call(headerArray_1);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                        // await this.columnWebWorker(headerArray);
                        // const columns = this._columns;
                        worksheet.columns = columns;
                        worksheet.addRows(reports);
                        try {
                            for (_c = tslib_1.__values(columns.entries()), _d = _c.next(); !_d.done; _d = _c.next()) {
                                _e = tslib_1.__read(_d.value, 2), i = _e[0], column = _e[1];
                                if (column.key !== column.key.toUpperCase() &&
                                    column.key.indexOf("_") === -1) {
                                    // if (column.key.toLowerCase().indexOf("date") !== -1) {
                                    //   worksheet.getRow(1).getCell(i + 1).numFmt = "mm-dd-yyyy";
                                    //   worksheet.getRow(1).getCell(i + 1).alignment = {
                                    //     wrapText: false,
                                    //     vertical: "middle",
                                    //     horizontal: "left",
                                    //   };
                                    // }
                                    worksheet.getRow(1).getCell(i + 1).value = this.returnKeyAsWord(column.key);
                                }
                            }
                        }
                        catch (e_4_1) { e_4 = { error: e_4_1 }; }
                        finally {
                            try {
                                if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                            }
                            finally { if (e_4) throw e_4.error; }
                        }
                        worksheet.getRow(1).fill = {
                            type: "gradient",
                            gradient: "path",
                            center: { left: 0.5, top: 0.5 },
                            stops: [
                                { position: 0, color: { argb: "FFBBBBBB" } },
                                { position: 1, color: { argb: "FFBBBBBB" } },
                            ],
                        };
                        worksheet.getRow(1).font = { bold: true };
                        this.autofitColumns(worksheet);
                        this.startTime = performance.now();
                        return [4 /*yield*/, workbook.xlsx.writeBuffer().then(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var blob;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            console.log("1 - ", performance.now() - this.startTime);
                                            blob = new Blob([data], {
                                                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                            });
                                            return [4 /*yield*/, fs.saveAs(blob, fileName + "_export_" + new Date().getTime() + this.EXCEL_EXTENSION)];
                                        case 1:
                                            _a.sent();
                                            console.log("2 - ", performance.now() - this.startTime);
                                            return [2 /*return*/];
                                    }
                                });
                            }); })];
                    case 1:
                        _f.sent();
                        duration = performance.now() - this.startTime;
                        console.log("someMethodIThinkMightBeSlow took " + duration + "ms");
                        return [2 /*return*/];
                }
            });
        });
    };
    // saveAsExcelFile(buffer: any, fileName: string): void {
    //   const data: Blob = new Blob([buffer], { type: this.EXCEL_TYPE });
    //   FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + this.EXCEL_EXTENSION);
    // }
    UtilityService.prototype.returnKeyAsWord = function (key) {
        return (key[0].toUpperCase() +
            key
                .substring(1)
                .replace(/([(A-Z)])/g, " $1")
                .replace(/ +/g, " ")
                .trim());
    };
    UtilityService.prototype.openDeficiencyPopup = function (property, assetId, oldAssetId, name, searchPAC, reportNumber) {
        var _this = this;
        if (searchPAC === void 0) { searchPAC = undefined; }
        if (reportNumber === void 0) { reportNumber = undefined; }
        var id = -1;
        if (name === "Shaft Pressurization") {
            id = 13;
        }
        if (name === "Standpipe") {
            id = 6;
        }
        if (name === "Clean agent") {
            id = 10;
        }
        this.inspectionService.getDateInfo(assetId).subscribe(function (resp) {
            var deficiencies = resp["inspectionDeficiency"];
            var authorityRemarks = resp["authorityReviewRemarks"];
            var inspectorRemarks = resp["inspectionRemarks"];
            if (resp &&
                resp.propertyAsset &&
                resp.propertyAsset.inspectionStatus &&
                resp.propertyAsset.inspectionStatus.description !== null &&
                resp.propertyAsset.inspectionStatus.hasOwnProperty("description") &&
                resp.propertyAsset.inspectionStatus.description ===
                    "Awaiting Re-Submittal") {
                deficiencies = [];
            }
            if (deficiencies && deficiencies.length > 0) {
                var dialogRef = _this.dialog.open(ViewDeficiencyDialogComponent, {
                    width: "1400px",
                    minWidth: "350px",
                    disableClose: true,
                    autoFocus: false,
                    data: {
                        assetId: assetId,
                        propertyId: property,
                        oldAssetId: oldAssetId,
                        id: id,
                        deficiencies: deficiencies,
                        authorityRemarks: authorityRemarks,
                        inspectorRemarks: inspectorRemarks,
                        searchPAC: searchPAC,
                        reportNumber: reportNumber,
                    },
                });
                dialogRef.afterClosed().subscribe(function (res) {
                    if (res === "update") {
                        document.body.scrollTop = 0; // For Safari
                        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                    }
                });
            }
            else {
                var dialogRef = _this.dialog.open(ViewDeficiencyDialogComponent, {
                    width: "500px",
                    minWidth: "350px",
                    disableClose: true,
                    autoFocus: false,
                    data: {
                        assetId: assetId,
                        propertyId: property,
                        oldAssetId: oldAssetId,
                        id: id,
                        deficiencies: deficiencies,
                        authorityRemarks: authorityRemarks,
                        inspectorRemarks: inspectorRemarks,
                        searchPAC: searchPAC,
                        reportNumber: reportNumber,
                    },
                });
            }
        }, function (error) {
            alert(error.error.message);
        });
    };
    UtilityService.prototype.downloadImage = function (image) {
        var fileURL = image, fileName = image.split("/").pop();
        // for non-IE
        if (!window.ActiveXObject) {
            var save = document.createElement("a");
            save.href = fileURL;
            save.target = "_blank";
            save.download = fileName || "unknown";
            var evt = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: false,
            });
            save.dispatchEvent(evt);
            (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
        // for IE < 11
        else if (!!window.ActiveXObject && document.execCommand) {
            var _window = window.open(fileURL, "_blank");
            _window.document.close();
            _window.document.execCommand("SaveAs", true, fileName || fileURL);
            _window.close();
        }
    };
    UtilityService.prototype.openDeficiencyPopup1 = function (property, assetId, oldAssetId, name, limit, offset, selectedAssetId, searchPAC, reportNumber) {
        var _this = this;
        var id = -1;
        if (name === "Shaft Pressurization") {
            id = 13;
        }
        if (name === "Standpipe") {
            id = 6;
        }
        if (name === "Clean agent") {
            id = 10;
        }
        this.inspectionService.getDateInfo(assetId).subscribe(function (resp) {
            var deficiencies = resp["inspectionDeficiency"];
            var authorityRemarks = resp["authorityReviewRemarks"];
            var inspectorRemarks = resp["inspectionRemarks"];
            if (resp &&
                resp.propertyAsset &&
                resp.propertyAsset.inspectionStatus &&
                resp.propertyAsset.inspectionStatus.description !== null &&
                resp.propertyAsset.inspectionStatus.hasOwnProperty("description") &&
                resp.propertyAsset.inspectionStatus.description ===
                    "Awaiting Re-Submittal") {
                deficiencies = [];
            }
            if (deficiencies && deficiencies.length > 0) {
                var dialogRef = _this.dialog.open(ViewDeficiencyDialogComponent, {
                    width: "1400px",
                    minWidth: "350px",
                    disableClose: true,
                    autoFocus: false,
                    data: {
                        assetId: assetId,
                        propertyId: property,
                        oldAssetId: oldAssetId,
                        id: id,
                        deficiencies: deficiencies,
                        authorityRemarks: authorityRemarks,
                        inspectorRemarks: inspectorRemarks,
                        searchPAC: searchPAC,
                        reportNumber: reportNumber,
                        limit: limit,
                        offset: offset,
                        selectedAssetId: selectedAssetId,
                    },
                });
                dialogRef.afterClosed().subscribe(function (res) {
                    if (res === "update") {
                        document.body.scrollTop = 0; // For Safari
                        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                    }
                });
            }
            else {
                var dialogRef = _this.dialog.open(ViewDeficiencyDialogComponent, {
                    width: "500px",
                    minWidth: "350px",
                    disableClose: true,
                    autoFocus: false,
                    data: {
                        assetId: assetId,
                        propertyId: property,
                        oldAssetId: oldAssetId,
                        id: id,
                        deficiencies: deficiencies,
                        authorityRemarks: authorityRemarks,
                        inspectorRemarks: inspectorRemarks,
                        searchPAC: searchPAC,
                        reportNumber: reportNumber,
                        limit: limit,
                        offset: offset,
                        selectedAssetId: selectedAssetId
                    },
                });
            }
        }, function (error) {
            alert(error.error.message);
        });
    };
    UtilityService.prototype.exportAsExcle1 = function (fromDate, toDate, res, authorityName) {
        var _this = this;
        var data = res.metricCount.map(function (object) {
            return tslib_1.__assign({}, object, { ats: '' });
        });
        var workbook = new Excel.Workbook();
        var worksheet = workbook.addWorksheet('Data');
        worksheet.columns = [
            { header: 'Assembly Test Summary', key: 'ats', width: 30, },
            { header: '', key: 'RPBA', width: 10, style: { font: { bold: true } } },
            { header: '', key: 'DCVA', width: 10, style: { font: { bold: true } } },
            { header: '', key: 'PVBA', width: 10, style: { font: { bold: true } } },
            { header: '', key: 'Other', width: 10, style: { font: { bold: true } } },
            { header: '', key: 'Total', width: 10, style: { font: { bold: true } } }
        ];
        row = worksheet.getRow(1);
        row.getCell("A").font = { bold: true, italic: 'true' };
        row = worksheet.getRow(1);
        row.getCell("F").value = authorityName;
        row.getCell("F").font = { with: 50, size: 10, bold: true, italic: true };
        row = worksheet.getRow(2);
        row.getCell("A").value = fromDate.replaceAll("-", "/") + ' - ' + toDate.replaceAll("-", "/");
        row.getCell("A").font = { size: 10, bold: true, italic: 'true' };
        //worksheet.getCell('B1').alignment = { vertical: 'middle', horizontal: 'center' };
        var row = worksheet.addRow([], "n");
        worksheet.addRow({ RPBA: 'RPBA', DCVA: 'DCVA', PVBA: 'PVBA', Other: 'Other', Total: 'TOTAL' }, "n");
        worksheet.addRow({ ats: 'All Assemblies' }, "n");
        row = worksheet.getRow(5);
        row.getCell("A").font = { size: 10, bold: true, underline: 'single' };
        row = worksheet.getRow(4);
        row.getCell("A").border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' } };
        row.getCell("B").alignment = { vertical: 'middle', horizontal: 'center' };
        row.getCell("B").border = { top: { style: 'thin' }, bottom: { style: 'thin' } };
        row.getCell("C").alignment = { vertical: 'middle', horizontal: 'center' };
        row.getCell("C").border = { top: { style: 'thin' }, bottom: { style: 'thin' } };
        row.getCell("D").alignment = { vertical: 'middle', horizontal: 'center' };
        row.getCell("D").border = { top: { style: 'thin' }, bottom: { style: 'thin' } };
        row.getCell("E").alignment = { vertical: 'middle', horizontal: 'center' };
        row.getCell("E").border = { top: { style: 'thin' }, bottom: { style: 'thin' } };
        row.getCell("F").alignment = { vertical: 'middle', horizontal: 'center' };
        row.getCell("F").border = { top: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        data.forEach(function (e) {
            worksheet.addRow({ ats: e.ats, RPBA: e.rpba, DCVA: e.dcva, PVBA: e.pvba, Other: e.other, Total: e.total }, "n");
        });
        row = worksheet.getRow(6);
        row.getCell("A").value = "Number of Assemblies";
        row = worksheet.getRow(7);
        row.getCell("A").value = "Number of Tests Completed";
        row = worksheet.getRow(8);
        row.getCell("A").value = "Number of Passes";
        row = worksheet.getRow(9);
        row.getCell("A").value = "Number of Failures";
        row = worksheet.getRow(10);
        row.getCell("A").value = "Number of New Installations";
        var Row6 = worksheet.getRow(6);
        Row6.eachCell(function (cell) {
            if (cell.value == null) {
                cell.value = 0;
            }
        });
        var Row7 = worksheet.getRow(7);
        Row7.eachCell(function (cell) {
            if (cell.value == null) {
                cell.value = 0;
            }
        });
        workbook.xlsx.writeBuffer().then(function (data) {
            var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, "WorX Annual Report Export_" + new Date().getTime() + _this.EXCEL_EXTENSION);
        });
    };
    UtilityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilityService_Factory() { return new UtilityService(i0.ɵɵinject(i1.MatDialog), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.InspectionService)); }, token: UtilityService, providedIn: "root" });
    return UtilityService;
}());
export { UtilityService };
