<mat-card class="example-card mat-elevation-z2">
  <mat-card-header>
    <mat-card-title>Statistics</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <div *ngIf="latestBidNotification || lowestBidNotification">
      <div class="row" style="flex-wrap: nowrap;" *ngIf="latestBidNotification.inspectorName">
        <p class="latest blink-new col-4" style="line-height: normal; margin-right: 10px;">NEW BID OPPORTUNITY</p>
        <p class="col-8" style="font-weight: 600; font-size: 18px; margin-left: .5vw; line-height: normal">{{latestBidNotification.inspectorName}}</p>
      </div>
      <div class="row" style="flex-wrap: nowrap;" *ngIf="lowestBidNotification.inspectorName">
        <p class="latest col-4" style="margin-right: 10px;">LOWEST</p>
        <p class="col-8" style="font-weight: 600; font-size: 18px; margin-left: .5vw; line-height: normal; word-break: break-all;">{{lowestBidNotification.inspectorName}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-9 widget-title">Properties Owned</div>
      <div class="col-3 widget-content">{{propertiesOwned}}</div>
    </div>
    <div class="row">
      <div class="col-9 widget-title">System Owned</div>
      <div class="col-3 widget-content">{{systemOwned}}</div>
    </div>
    <div class="row">
      <div class="col-8 widget-title">Compliance &#37;</div>
      <div class="col-4 pr-0 widget-content paddingSide10">{{compliancePercentage}} &#37;</div>
    </div>
  </mat-card-content>
</mat-card>

<!-- Owner Statistics is declared in shared module because of
    cyclic dependenciyes between shared modulw and owner module -->
