import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var InspectorService = /** @class */ (function () {
    function InspectorService(http) {
        var _this = this;
        this.http = http;
        this.baseUrl = environment.baseUrl;
        this.formInvalid = new BehaviorSubject(false);
        // downloadPDF(url): any {
        //   // const options = { responseType: ResponseContentType.Blob  };
        //   return this.http.get<Blob>(url,  { headers: new HttpHeaders({
        //     // 'Content-Type': 'application/pdf',
        //     responseType: 'blob'
        //     })
        //   }).pipe(
        //     map(
        //       (res) => {
        //         return new Blob([res['blob']()],
        //         //  { type: 'application/octet-stream' }
        //          { type: 'application/pdf' }
        //          );
        //     },
        //       error => console.log(error)
        //       )
        //   );
        // }
        this.isBidOpportunityApiLoaded = false;
        this.bidOpportunities = new BehaviorSubject([]);
        // -----start
        // storing response of this api in a subject to avoid duplicate api calls
        this.loadBidOpportunities = function () {
            var url = _this.baseUrl + "/bidsNew/opportunities/inspector";
            setTimeout(function () {
                _this.http.get(url).subscribe(function (res) {
                    _this.bidOpportunities.next(res);
                }, function (err) { return (_this.isBidOpportunityApiLoaded = false); });
            }, 1000);
        };
    }
    InspectorService.prototype.getInspectorPreferences = function () {
        var url = this.baseUrl + "/inspector/preferences/companydetails";
        return this.http.get(url);
    };
    InspectorService.prototype.putInspectorPreferences = function (companydetails) {
        var url = this.baseUrl + "/inspector/preferences/companydetails";
        return this.http.put(url, companydetails);
    };
    InspectorService.prototype.getInspectorContacts = function () {
        var url = this.baseUrl + "/inspector/preferences/contactdetails";
        return this.http.get(url);
    };
    InspectorService.prototype.getStates = function () {
        var url = this.baseUrl + "/state";
        return this.http.get(url);
    };
    // getCounties(stateId): Observable<any> {
    //   const url = this.baseUrl + `/county/${stateId}`;
    //   return this.http.get<any>(url);
    // }
    InspectorService.prototype.putInspectorContacts = function (contacts) {
        var url = this.baseUrl + "/inspector/preferences/contactdetails";
        return this.http.put(url, contacts);
    };
    InspectorService.prototype.getPendingApprovalPropertyCount = function () {
        var url = this.baseUrl + "/inspector/preferences/countofPAA";
        return this.http.get(url);
    };
    InspectorService.prototype.getInspectionSnapshot = function () {
        var url = this.baseUrl + "/dashboard/placeholders";
        return this.http.get(url);
    };
    InspectorService.prototype.getRFAInspectionSnapshot = function (jurisdictionId) {
        var url = this.baseUrl + ("/dashboard/RFA/placeholders/" + jurisdictionId);
        return this.http.get(url);
    };
    InspectorService.prototype.putInspectorPreferencesSettings = function (settings) {
        var url = this.baseUrl + "/inspector/preferences/settings";
        return this.http.put(url, settings);
    };
    InspectorService.prototype.getInspectorSettings = function () {
        var url = this.baseUrl + "/inspector/preferences/settings";
        return this.http.get(url);
    };
    InspectorService.prototype.getLetterHead = function () {
        var url = this.baseUrl + "/inspector/preferences/notificationtemplate";
        return this.http.get(url);
    };
    InspectorService.prototype.updateLetterHead = function (body) {
        var url = this.baseUrl + "/inspector/preferences/notificationtemplate";
        return this.http.put(url, body);
    };
    InspectorService.prototype.deleteLetterHead = function (id) {
        var url = this.baseUrl + ("/inspector/preferences/notificationtemplate/" + id);
        return this.http.delete(url);
    };
    InspectorService.prototype.setBidOpportunities = function (bidOpportunities) {
        this.bidOpportunities.next(bidOpportunities);
    };
    InspectorService.prototype.getBidOpportunities = function () {
        if (this.bidOpportunities.getValue().length === 0 &&
            !this.isBidOpportunityApiLoaded) {
            this.isBidOpportunityApiLoaded = true;
            this.loadBidOpportunities();
        }
        return this.bidOpportunities.asObservable();
    };
    // -----end
    InspectorService.prototype.getLatestBidOpportunity = function () {
        var url = this.baseUrl + "/recentBids/properties";
        return this.http.get(url);
    };
    InspectorService.prototype.sendBid = function (body) {
        var url = this.baseUrl + "/bid";
        return this.http.post(url, body);
    };
    InspectorService.prototype.getInspectionCompanies = function () {
        var url = this.baseUrl + "/inspectionCompanyList";
        return this.http.get(url);
    };
    InspectorService.prototype.getPropertyAssetsStatus = function (status) {
        var url = this.baseUrl + ("/property/details/" + status);
        return this.http.get(url);
    };
    InspectorService.prototype.changeToSubmittedForReview = function (params) {
        if (params === void 0) { params = {}; }
        var url = this.baseUrl + "/property/confirmPayment";
        return this.http.post(url, {}, { params: params });
    };
    InspectorService.prototype.getPropertyStatistics = function () {
        var url = this.baseUrl + "/property/statistics";
        return this.http.get(url);
    };
    InspectorService.prototype.setPreference = function () {
        var url = this.baseUrl + "/setPreference";
        return this.http.put(url, {});
    };
    InspectorService.prototype.getListOfAHJsByInspectorId = function (id, searchKey) {
        var url = this.baseUrl + ("/authority/" + id + "/" + searchKey);
        return this.http.get(url);
    };
    InspectorService.prototype.getListOfWorXByInspectorId = function (id, searchKey) {
        var url = this.baseUrl + ("/authorityworkx/" + id + "/" + searchKey);
        return this.http.get(url);
    };
    InspectorService.prototype.getpaymentReportsByDateRange = function (fromDate, toDate) {
        var url = this.baseUrl + "/inspector/paymentreports";
        return this.http.get(url, {
            params: { fromDate: fromDate, toDate: toDate },
        });
    };
    InspectorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InspectorService_Factory() { return new InspectorService(i0.ɵɵinject(i1.HttpClient)); }, token: InspectorService, providedIn: "root" });
    return InspectorService;
}());
export { InspectorService };
