import { Component, OnInit, Input } from '@angular/core';
import { HeadingQuestion } from '../../../core/models/inspection/inspection.model';
@Component({
  selector: 'app-fire-alarm',
  templateUrl: './fire-alarm.component.html',
  styleUrls: []
})
export class FireAlarmComponent implements OnInit {
  @Input() questions: Array<HeadingQuestion>;
  @Input() qtd: Array<any>;
  @Input() reviewInspection: boolean;
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (changes['questions'] && changes['questions'].currentValue) {
      this.questions = [...changes['questions'].currentValue];
    }
  }

}
