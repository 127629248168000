export class UserDetails {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  name: string;
  cotactNumber: string;
  role: UserRole;
  isPreferenceSet?: boolean;
  isPrimary?: boolean;
  isForcePasswordChanged?: boolean;
  jurisdiction?: any;
  streetLine1: string;
  streetLine2?: string;
  zipcode: string;
  accessSpecifier: string;
}

export class PasswordChange {
  newPassword: string;
  oldPassword: string;
}

export class User {
  name: string;
  role: Roles;
  propertiesOwned?: number;
  systemsOwned?: number;
}

export interface LoginInfo {
  token?: string;
  user?: UserDetails;
}

export interface UserLogin {
  email: string;
  password: string;
}

export class UserRole {
  id: number;
  name: string;
  description: string;
  authority: string;
}


export interface QueryParams {
  [key: string]: any;
}

export class MenuOption {
  icon: string;
  name?: string;
  route: string;
  iconActive?: string;
  queryParams?: QueryParams;
}
export enum Roles {
  'ROLE_SITE_ADMIN' = 1,
  'ROLE_OWNER_ADMIN' = 2,
  'ROLE_AUTHORITY_ADMIN' = 3,
  'ROLE_INSPECTOR_ADMIN' = 4,
  'ROLE_OWNER_USER' = 5,
  'ROLE_AUTHORITY_USER' = 6,
  'ROLE_INSPECTOR_USER' = 7,
  'ROLE_RFA' = 8,
  'ROLE_AUTHORITY_WORX' = 9
}
export class Notification {
  message: string;
}
export class Owner {
  name?: string;
  primaryContact?: string;
  email?: string;
}

export interface UserDetailsByRole {
  id: number;
  inspectionCreditsRemaining: number;
  isCommercialCertified: boolean;
  isEligibleForRegistryFee: boolean;
  jurisdiction: {
    dateCreated: string;
    dateUpdated: string;
    id: number;
    name: string;
    status: number
  };
  licenceNo: string;
  mappedUsers: {
    accessSpecifier: string;
    assignment: string;
    contactNumber: string;
    dateCreated: string;
    dateUpdated: string;
    email: string;
    firstName: string;
    id: number;
    isActive: boolean;
    isDeleted: boolean;
    isForceChangedPassword: boolean;
    isPreferenceSet: boolean;
    isPrimary: boolean;
    lastName: string;
    password: string;
    title: string;
    userDetails: {
      id: number;
      inspectionCreditsRemaining: number;
      isCommercialCertified: boolean;
      isEligibleForRegistryFee: boolean;
      jurisdiction: {
        dateCreated: string;
        dateUpdated: string;
        id: number;
        name: string;
        status: number
      };
      licenceNo: string;
      mappedUsers: any[];
      name: string;
      rating: number;
      registryFeeDiscount: number;
      role: string;
      streetLine1: string;
      streetLine2: string;
      title: string;
      websiteUrl: string;
      zipcode: {
        approved: boolean;
        city: string;
        id: number;
        state: string;
        stateCode: string;
        zipCode: string
      }
    }
  }[];
  name: string;
  rating: number;
  registryFeeDiscount: number;
  role: string;
  streetLine1: string;
  streetLine2: string;
  title: string;
  websiteUrl: string;
  zipcode: {
    approved: boolean;
    city: string;
    id: number;
    state: string;
    stateCode: string;
    zipCode: string
  };
}
