import { OnInit, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatDialogRef } from '@angular/material';
var FileCabinetHistoryComponent = /** @class */ (function () {
    function FileCabinetHistoryComponent(dialogRef, data, fileCabService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.fileCabService = fileCabService;
        this.dataSource = new MatTableDataSource([]);
        this.columnsToDisplay = ['name', 'address', 'pac'];
        this.history = [];
        this.subscriptions = [];
    }
    FileCabinetHistoryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.fileCabService.getFileCabinetHistory().subscribe(function (res) {
            _this.history = res;
            _this.dataSource.data = _this.history;
        }, function (error) {
            console.log(error);
        }));
    };
    FileCabinetHistoryComponent.prototype.close = function () {
        this.dialogRef.close(null);
    };
    FileCabinetHistoryComponent.prototype.searchInFileCabinet = function (property) {
        this.dialogRef.close(property);
    };
    FileCabinetHistoryComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (x) {
            x.unsubscribe();
        });
    };
    return FileCabinetHistoryComponent;
}());
export { FileCabinetHistoryComponent };
