import { Component, OnInit, Inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DateAdapter, MatDialogRef, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_DIALOG_DATA } from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { ListData } from "../../core/models/file-cabinet/file-cabinet.model";
import { FileCabinetService } from "../../core/services/file-cabinet/file-cabinet.service";
import { InspectionService } from "../../core/services/inspection/inspection.service";
import { LoggerService } from "../../core/services/logger/logger.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "MM-DD-YYYY",
  },
  display: {
    dateInput: "MM-DD-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "MM-DD-YYYY",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-view-deficiency-dialog",
  templateUrl: "./view-deficiency-dialog.component.html",
  styleUrls: ["./view-deficiency-dialog.component.scss"],
  providers:[{
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE],
  },
  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },]
})
export class ViewDeficiencyDialogComponent implements OnInit {
  deficiencies: {
    description: string;
    correctedDate: string;
    correctedBy: string;
    correctionComments: string;
    inspectorComments: string;
    authComments: string;
    name: string;
  }[];
  authorityRemarks: any;
  inspectorRemarks: any;
  userRole: string;
  nameOfAction: string;
  jurisdictionList: ListData[];
  formGroup;
  fromDateError = "";
  toDateError = "";
  submittedDef:boolean;
  formattedFromDate: string;

  constructor(
    public dialogRef: MatDialogRef<ViewDeficiencyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private route: ActivatedRoute,
    private router: Router,
    private inspectionService: InspectionService,
    private fileCabService: FileCabinetService,
    private loggerService: LoggerService,
    private formBuilder: FormBuilder
  ){
    this.formGroup = this.formBuilder.group({
      correctedBy: '',
      correctionComments: new FormControl('', Validators.maxLength(1000)),
      jurisdictionId: Number,
      defDateCorrected: '',
      jurisdictionName: ''

    });
  }


  get f(){

    return this.formGroup.controls;

  }
  ngOnInit() {
    this.deficiencies = this.data.deficiencies;
    this.authorityRemarks = this.data.authorityRemarks;
    this.inspectorRemarks = this.data.inspectorRemarks;
    this.userRole = localStorage.getItem("userRole");
    this.nameOfAction = this.data.name;
    this.getJurisdiction();
  }

  save() {
    this.dialogRef.close("continue");
  }

  updateReport() {
    if (this.userRole == "ROLE_OWNER") {
      alert('Corrections can only be reported by a certified inspector.  Please contact your inspection company');
      return;
    }
    this.dialogRef.close("update");
    let selectedAsset = this.data.assetId;
    let assetList = "update";
    let propertyID = this.data.propertyId;
    let oldAssetId = this.data.oldAssetId;
    let searchPAC = this.data.searchPAC;
    let reportNumber = this.data.reportNumber;
    let id = this.data.id;
    let limit = this.data.limit;
    let offset = this.data.offset;
    let selectedAssetId = this.data.selectedAssetId
    this.router.navigate(
      ["user/inspection/updateDeficiency/reviewInspection"],
      {
        queryParams: {
          selectedAsset,
          assetList,
          propertyID,
          oldAssetId,
          id,
          searchPAC,
          reportNumber,
          limit,
          offset,
          selectedAssetId,
        },
      }
    );
  }

  cancel(): void {
    this.dialogRef.close("cancelled");
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  getJurisdiction() {
    this.fileCabService.getActiveJurisdictions().subscribe(
      (res) => {

        this.jurisdictionList = res;
      },
      (error) => {
        this.loggerService.log(error.error.message);
      }
    )
  }


  onSubmit(formData) {
    this.submittedDef = true;
    if((this.fromDateError.length == 0 || (this.formattedFromDate && (this.formattedFromDate == 'Invalid date' || this.formattedFromDate == undefined))) && !(this.formGroup.value.correctedBy && this.formGroup.value.correctionComments && this.formGroup.value.defDateCorrected && this.formGroup.value.jurisdictionName)) {
      alert('Please fill missing details in Update Previous Deficiencies pop up');
      return;
    } else if(this.fromDateError.length !=0 || !this.formGroup.valid) {
      alert('Please fill missing details in Update Previous Deficiencies pop up');
      return;
    }

    else {
    var name = formData['correctedBy'];
    formData.defDateCorrected = new Date(formData.defDateCorrected._d).toLocaleDateString();
    formData.defDateCorrected = formData.defDateCorrected.replaceAll('/','-');
    formData.jurisdictionId = this.jurisdictionList.filter(jur => jur.data == formData.jurisdictionName)[0].id;
   // this.inspectionService.updatePreviousDefieciencyStatus().subscribe(result)
   delete formData.jurisdictionName;
   this.inspectionService.updatePreviousDefieciencyStatus(formData).subscribe(
    res => {
      alert('Template Saved Successfully');

    },
    error => {

      if(error.status == 200 && error.error.text == 'OK') {
        alert('Previous Deficiencies updated successfully.');
        this.onNoClick();
      } else {
        alert('Previous Deficiencies not updated successfully.');
        this.onNoClick();
      }
    }

  );
    }
    //alert('Previous Deficiencies updated successfully.');
    //this.onNoClick();
  }
  checkErrorInFromDate(fromDate) {
    this.formattedFromDate = fromDate//moment(fromDate).format('MM-DD-YYYY').toString();
    this.fromDateError = '';
    if (this.formattedFromDate.length === 0) {
      return this.fromDateError;
    }
    if (this.formattedFromDate.length !== 10) {
      this.fromDateError = '* Enter valid Deficiency Date';
    }
    if(!this.validateDate(this.formattedFromDate)) {
      this.fromDateError = 'Invalid Date Format';
    }
    const date = this.formattedFromDate.split('-');
    if (
      date.length !== 3 ||
      date[0].length !== 2 ||
      date[1].length !== 2 ||
      date[2].length !== 4
    ) {
      this.fromDateError = ' Enter valid Deficiency Date';
    } else if (!moment(this.formattedFromDate, 'MM-DD-YYYY').isValid()) {
      this.fromDateError = ' Enter valid Deficiency Date';
    } else if (moment(this.formattedFromDate).isAfter(moment(new Date())))
     {
      this.fromDateError =
        ' Future Deficiency Date is not allowed';
    }
    return this.fromDateError;
  }

  validateDate(inspectionDueDate: any){
    let dateformat = /^(0?[1-9]|1[0-2])[\-](0?[1-9]|[1-2][0-9]|3[01])[\-]\d{4}$/;

    // Match the date format through regular expression
    if(inspectionDueDate.match(dateformat)){
        let operator = inspectionDueDate.split('-');

        // Extract the string into month, date and year
        let datepart = [];
        if (operator.length>1){
          datepart = inspectionDueDate.split('-');
        }
        let month= parseInt(datepart[0]);
        let day = parseInt(datepart[1]);
        let year = parseInt(datepart[2]);

        // Create list of days of a month
        let ListofDays = [31,28,31,30,31,30,31,31,30,31,30,31];
        if(year < 1000 || year > 3000 || month === 0 || month > 12)    {
          return '';
        }
        if (month==1 || month>2){
            if (day>ListofDays[month-1]){
                ///This check is for Confirming that the date is not out of its range
                return '';
            }
        }else if (month==2){
            let leapYear = false;
            if ( (!(year % 4) && year % 100) || !(year % 400)) {
                leapYear = true;
            }
            if ((leapYear == false) && (day>=29)){
                return '';
            }else
            if ((leapYear==true) && (day>29)){
                return '';
            }
        }
    }else{
        return '';
    }
    return inspectionDueDate;
}
}
