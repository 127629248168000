import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { CanActivate } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthorityInspectorGuard implements CanActivate {


  constructor(
    private userService: UserService,
    private location: Location
  ) { }

  canActivate(): boolean {
    const a = this.userService.userDetails.role.name === 'ROLE_AUTHORITY' || this.userService.userDetails.role.name === 'ROLE_INSPECTOR'
      || this.userService.userDetails.role.name === 'ROLE_RFA' || this.userService.userDetails.role.name === 'ROLE_AUTHORITY_WORX';
    if (a) {
      return a;
    }
    this.location.back();
    return a;

  }

}
