import { Component, OnInit, Input } from '@angular/core';
import { HeadingQuestion } from '../../../core/models/inspection/inspection.model';

@Component({
  selector: 'app-backflow',
  templateUrl: './backflow.component.html',
  styleUrls: ['./backflow.component.scss']
})
export class BackflowComponent implements OnInit {

  @Input() questions: Array<HeadingQuestion>;
  @Input() qtd: Array<any>;
  @Input() reviewInspection: boolean;

  constructor() { }
  ngOnInit() {   
  }

  findQuestionId(answerType2, questionType, question, inputType) {
    const condition = this.questions.filter(x => x.questions.answerType2 == answerType2 &&
      x.questions.questionType == questionType &&
      x.questions.question == question);
    if (condition && condition[0]) {
      // checkbox type - critical
      if (inputType === 'checkbox' && this.qtd[condition[0].questions.questionId] 
        && (this.qtd[condition[0].questions.questionId][0] === null
        || this.qtd[condition[0].questions.questionId][0] === "false")) {
        this.qtd[condition[0].questions.questionId] = false;
      } else if (inputType === 'checkbox' && this.qtd[condition[0].questions.questionId]
       && this.qtd[condition[0].questions.questionId][0] === "true") {
        this.qtd[condition[0].questions.questionId] = true;
      }
      if(inputType === 'radio') {
        if (!this.qtd[condition[0].questions.questionId]) {
          this.qtd[condition[0].questions.questionId] = null
        } else if (Array.isArray(this.qtd[condition[0].questions.questionId])) {
          this.qtd[condition[0].questions.questionId] = this.qtd[condition[0].questions.questionId][0];
        }
      }
      return condition[0].questions.questionId;
    }
    return 0;
  }

}
