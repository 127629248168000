import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginInfo, UserDetails, PasswordChange, Roles } from '../../shared/models/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service';
import * as AES from "crypto-js/aes";
import * as UTF8 from "crypto-js/enc-utf8";

@Component({
  selector: 'app-force-change-password',
  templateUrl: './force-change-password.component.html',
  styleUrls: ['./force-change-password.component.scss']
})
export class ForceChangePasswordComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public errors: {
    old: string;
    new: string[];
    confirm: string[];
    newconfirm: string;
    apierror: string;
  };
  formValidity: boolean;
  user: UserDetails;
  passwordChange: PasswordChange;
  userRole: string;
  subscriptions = [];
  forceChange = false;
  private secretKey: string = "ui87n@!9845%$bh|pqw";
  public encOldPwd: string =  '';

  constructor(
    private router: Router, private fb: FormBuilder,
    private aR: ActivatedRoute, private loginService: LoginService) { }


  ngOnInit() {

    this.subscriptions.push(
      this.aR.queryParams.subscribe(params => {
        this.forceChange = params && params.force === '1' ? true : false;
        this.encOldPwd = sessionStorage.getItem('ss-up').split('=')[0];
      })
    );

    this.user = JSON.parse(localStorage.getItem('userDetails'));
    this.userRole = this.user.role.name;
    this.resetErrors();
    this.formValidity = true,
      this.form = this.fb.group({
        oldPwd: ['', Validators.required],
        newPwd: ['', Validators.required],
        confirmPwd: ['', Validators.required]
      });
    if (this.forceChange) {
      this.form.patchValue({
        oldPwd : this.decrypt(this.encOldPwd)
      });
    }
    this.passwordChange = {
      oldPassword: null,
      newPassword: null
    };
  }

  decrypt(encryptedVal): string {
    return AES.decrypt(encryptedVal, this.secretKey).toString(UTF8);
  }

  save() {

    this.formValidity = true;
    this.resetErrors();

    this.validateOldPassword();
    this.validateNewPassword();
    this.validateConfirmPassword();
    this.validateEqualityNewConfirmPassword();

    if (this.formValidity) {
      this.passwordChange = {
        oldPassword: this.form.value.oldPwd,
        newPassword: this.form.value.newPwd
      };
      this.subscriptions.push(
        this.loginService.changePassword(this.passwordChange).subscribe(
          response => {
            this.user.isForcePasswordChanged = false;
            sessionStorage.removeItem('ss-up');
            localStorage.setItem('userDetails', JSON.stringify(this.user));
            alert('Password has been updated');
            if (!this.user.isPreferenceSet) {
              if (this.user.role.id === Roles.ROLE_OWNER_ADMIN) {
                this.router.navigate(['user/owner/preferences']);
              } else if (this.user.role.id === Roles.ROLE_AUTHORITY_ADMIN || this.user.role.id === Roles.ROLE_AUTHORITY_WORX) {
                this.router.navigate(['user/authority/authorityPreference']);
              } else if (this.user.role.id === Roles.ROLE_INSPECTOR_ADMIN) {
                this.router.navigate(['user/inspector/preferencesInspector']);
              } else if (this.user.role.id === Roles.ROLE_RFA) {
                this.router.navigate(['user/authority/authorityPreference']);
              }
            } else {
              if (this.user.role.id === Roles.ROLE_OWNER_ADMIN) {
                this.router.navigate(['user/owner/dashboard']);
              } else if (this.user.role.id === Roles.ROLE_AUTHORITY_ADMIN || this.user.role.id === Roles.ROLE_AUTHORITY_WORX) {
                this.router.navigate(['user/authority/dashboardAuthority']);
              } else if (this.user.role.id === Roles.ROLE_INSPECTOR_ADMIN) {
                this.router.navigate(['user/inspector/dashboardInspector']);
              } else if (this.user.role.id === Roles.ROLE_RFA) {
                this.router.navigate(['user/authority/dashboardAuthority/rfa']);
              }
            }
            if (this.user.role.id === Roles.ROLE_SITE_ADMIN) {
              this.router.navigate(['user/admin/dashboardSiteAdmin']);
            }
            //this.router.navigate(['user/dashboard']);
          },
          error => {
            this.errors.apierror = error.error.message;
          }
        )
      );
    } else {
      console.log(this.errors);
    }


  }

  validateOldPassword() {
    if (!this.form.value.oldPwd || this.form.value.oldPwd.length == 0) {
      this.errors.old = 'Enter Old Password';
      this.formValidity = false;
    }
  }

  validateNewPassword() {
    const password = this.form.value.newPwd;
    if (!password || password.length === 0) {
      this.errors.new.push('Enter New Password');
      this.formValidity = false;
      return;
    } else {
      if (password.length < 8 || password.length > 15) {
        this.formValidity = false;
        this.errors.new.push('Length of Password should be between 8 to 15 ');
      }
      if (password === this.form.value.oldPwd) {
        this.formValidity = false;
        this.errors.new.push('Old Password and New Password are same. Please use different in New Password.');
      }
      if (password.includes('.') || password.includes(',') || password.includes(';') || password.includes(':')) {
        this.formValidity = false;
        this.errors.new.push('Coma, colan, Semicolon, dot cannot be used');
      }
      if (password === password.toLowerCase()) {
        this.formValidity = false;
        this.errors.new.push('Minimum one Upper case character should be used');
      }
      if (password === password.toUpperCase()) {
        this.formValidity = false;
        this.errors.new.push('Minimum one Lower case character should be used');
      }
      if (!(/\d/.test(password))) {
        this.formValidity = false;
        this.errors.new.push('Minimum one digit should be used');
      } else {
        for (let i = 0; i < password.length - 2; ++i) {
          if (/\d/.test(password.charAt(i))) {
            if (password.charAt(i) === password.charAt(i + 1) && password.charAt(i) === password.charAt(i + 2)) {
              this.formValidity = false;
              this.errors.new.push('3 same digits cannot be used together');
              break;
            }
          }
        }
      }

    }

  }

  validateConfirmPassword() {
    const password = this.form.value.confirmPwd;
    if (!password || password.length === 0) {
      this.errors.confirm.push('Enter New Password');
      this.formValidity = false;
      return;
    } else {
      if (password.length < 8 || password.length > 15) {
        this.formValidity = false;
        this.errors.confirm.push('Length of Password should be between 8 to 15 ');
      }
      if (password === this.form.value.oldPwd) {
        this.formValidity = false;
        this.errors.confirm.push('Old Password and New Password are same. Please use different in New Password.');
      }
      if (password.includes('.') || password.includes(',') || password.includes(';') || password.includes(':')) {
        this.formValidity = false;
        this.errors.confirm.push('Coma, colan, Semicolon, dot cannot be used');
      }
      if (password === password.toLowerCase()) {
        this.formValidity = false;
        this.errors.confirm.push('Minimum one Upper case character should be used');
      }
      if (password === password.toUpperCase()) {
        this.formValidity = false;
        this.errors.confirm.push('Minimum one Lower case character should be used');
      }
      if (!(/\d/.test(password))) {
        this.formValidity = false;
        this.errors.confirm.push('Minimum one digit should be used');
      } else {
        for (let i = 0; i < password.length - 2; ++i) {
          if (/\d/.test(password.charAt(i))) {
            if (password.charAt(i) === password.charAt(i + 1) && password.charAt(i) === password.charAt(i + 2)) {
              this.formValidity = false;
              this.errors.confirm.push('3 same digits cannot be used together');
              break;
            }
          }
        }
      }

    }
  }

  validateEqualityNewConfirmPassword() {
    if (this.form.value.newPwd !== this.form.value.confirmPwd) {
      this.errors.newconfirm = 'Your New Password and Confirm Password do not match.';
      this.formValidity = false;
    }
  }


  cancel() {
    sessionStorage.removeItem('ss-up');
    this.user = JSON.parse(localStorage.getItem('userDetails'));
    if (!this.user || this.forceChange === true) {
      localStorage.clear();
      this.router.navigate(['login/login-form']);
    } else {
      if (this.user.role['id'] === Roles.ROLE_OWNER_ADMIN) {
        this.router.navigate(['user/owner/dashboard']);
      } else if (this.user.role['id'] === Roles.ROLE_AUTHORITY_ADMIN || this.user.role.id === Roles.ROLE_AUTHORITY_WORX) {
        this.router.navigate(['user/authority/dashboardAuthority']);
      } else if (this.user.role['id'] === Roles.ROLE_INSPECTOR_ADMIN) {
        this.router.navigate(['user/inspector/dashboardInspector']);
      } else if (this.user.role['id'] === Roles.ROLE_RFA) {
        this.router.navigate(['user/authority/dashboardAuthority']);
      } else {
        this.router.navigate(['user/admin/dashboardSiteAdmin']);
      }
    }

  }

  resetErrors() {
    this.errors = {
      old: null,
      new: [],
      confirm: [],
      newconfirm: null,
      apierror: null
    };
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => {
      x.unsubscribe();
    });
  }

}
