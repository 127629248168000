import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AuthorityStatistics } from './../models/statistics.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  baseUrl = environment.baseUrl;
  jurisId$: BehaviorSubject<any> = new BehaviorSubject(null);
  jurisName$: BehaviorSubject<any> = new BehaviorSubject(null);


  constructor(private http: HttpClient) { }

  public getAuthorityStatistics() {
    const url = this.baseUrl + `/authority/statistics`;
    return this.http.get<AuthorityStatistics>(url);
  }

  public getRFAAuthorityStatistics(jurisIds) {
    const url = this.baseUrl + `/rfa/statistics/${jurisIds}`;
    return this.http.get<AuthorityStatistics>(url);
  }
}
