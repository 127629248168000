import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';



@Component({
  selector: 'confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html'
})

// const body = this.systemDetails;
export class ConfirmationDialog {
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {

  }

  areYouSureNo() {
    this.dialogRef.close(false);
  }

  areYouSureYes(){
    this.dialogRef.close(true);
  }

}
