<app-dialog>
  <span class="dialog-title">
    Primary Contact
    <span class="float-right">
      <mat-icon class="cursor-pointer" (click)="onNoClick()">close</mat-icon>
    </span>
  </span>
  <div class="dialog-content">
      <div class="example-box" [class.highlightedRow]="contact.isManager === true" *ngFor="let contact of contacts; let i = index" [attr.data-index]="i">
        <div class="row custom-contacts-row">
          <div class="col-4 contacts-content">{{contact.firstName}} {{contact.lastName}}</div>
          <div class="col-3 contacts-content"> {{contact.phoneNum}}</div>
          <a [href]="'mailto:' + contact.email" class="col-5 contacts-content number"
            target="_top">{{contact.email}}</a>
        </div>
      </div>
  </div>
</app-dialog>
