import { OnInit } from '@angular/core';
import { Roles } from '../../../../shared/models/user.model';
var AppSideNavComponent = /** @class */ (function () {
    function AppSideNavComponent(ownwerService, router) {
        this.ownwerService = ownwerService;
        this.router = router;
        this.title = 'TEGRIS fire';
        this.linksEnabled = true;
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
    }
    AppSideNavComponent.prototype.openContactUs = function () {
        window.location.href = ('https://www.tegrisinc.com/contacts');
    };
    AppSideNavComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.baseImgUrl = './assets/images/';
        this.user = JSON.parse(localStorage.getItem('userDetails'));
        this.userDepartment = JSON.parse(localStorage.getItem("userDetails")).role.name === "ROLE_AUTHORITY_WORX" ? "TEGRIS WorX" : "TEGRIS Fire";
        this.notification = { message: 'Bid received from AAA Fire Protection, Inc.' };
        this.userRole = localStorage.getItem('userRole');
        /* Menu Options for the Owner Administrator*/
        if (this.user.role.id === Roles.ROLE_OWNER_ADMIN) {
            this.ownwerService.preferencesUpdated.subscribe(function (res) {
                var userDetails = JSON.parse(localStorage.getItem('userDetails'));
                if (userDetails && userDetails['isPreferenceSet']) {
                    _this.linksEnabled = true;
                }
                else {
                    _this.linksEnabled = false;
                }
            });
            this.menuOptions = [{
                    icon: this.baseImgUrl + 'home.png', iconActive: this.baseImgUrl + 'home_Active.png',
                    name: 'My Properties', route: './owner/dashboard'
                },
                {
                    icon: this.baseImgUrl + 'preferences.png', iconActive: this.baseImgUrl + 'preferences_Active.png',
                    name: 'Owner Preferences', route: './owner/preferences'
                },
                {
                    icon: this.baseImgUrl + 'addProperty.png', iconActive: this.baseImgUrl + 'addProperty_Active.png',
                    name: 'Add  New Property', route: './addEditProperty/property'
                },
                {
                    icon: this.baseImgUrl + 'inspectionResult.png', iconActive: this.baseImgUrl + 'deficiencyList.png',
                    name: 'Bid Request', route: './owner/bid'
                },
                // {
                //   icon: this.baseImgUrl + 'deficiencyList.png', iconActive: this.baseImgUrl + 'deficiencyList.png',
                //   name: 'Outstanding Invoices', route: './invoices'
                // },
                {
                    icon: this.baseImgUrl + 'inspection.png', iconActive: this.baseImgUrl + 'inspection.png',
                    name: 'Search For Inspector', route: './owner/searchInspector'
                },
                {
                    icon: this.baseImgUrl + 'metrics.png', iconActive: this.baseImgUrl + 'metrics.png',
                    name: 'Metrics', route: './metrics'
                }
            ];
        }
        /* Menu Options for the Site Administrator*/
        if (this.user.role.id === Roles.ROLE_SITE_ADMIN) {
            this.menuOptions = [{
                    icon: this.baseImgUrl + 'registration_admin.png',
                    iconActive: this.baseImgUrl + 'registration.png', name: 'Registration', route: './admin/dashboardSiteAdmin'
                },
                {
                    icon: this.baseImgUrl + 'FileCabinet.png', iconActive: this.baseImgUrl + 'FileCabinet.png',
                    name: 'File Cabinet', route: './general/fileCabinet'
                },
                {
                    icon: this.baseImgUrl + 'search.png', iconActive: this.baseImgUrl + 'search.png',
                    name: 'Global Search', route: './admin/globalSearch'
                },
                {
                    icon: this.baseImgUrl + 'check-list.png', iconActive: this.baseImgUrl + 'check-list.png',
                    name: 'Update Deficiency Status', route: './inspection/updateDeficiency'
                },
                {
                    icon: this.baseImgUrl + 'fee.png', iconActive: this.baseImgUrl + 'fee.png',
                    name: 'Configure Registry Fee Settings', route: './admin/configureFees'
                },
                {
                    icon: this.baseImgUrl + 'settings.png', iconActive: this.baseImgUrl + 'settings.png',
                    name: 'Configure Notification Templates', route: './admin/configureNotificationTemplate'
                },
                {
                    icon: this.baseImgUrl + 'search.png', iconActive: this.baseImgUrl + 'search.png',
                    name: 'Contact List', route: './admin/contactList'
                },
                {
                    icon: this.baseImgUrl + 'help.png', iconActive: this.baseImgUrl + 'help.png',
                    name: 'Admin Settings', route: './admin/adminSettings'
                },
                {
                    icon: this.baseImgUrl + 'data-import-interface-symbol.png', iconActive: this.baseImgUrl + 'data-import-interface-symbol.png',
                    name: 'Import Data', route: './admin/importPropertyData'
                },
                {
                    icon: this.baseImgUrl + 'inspectionResult.png', iconActive: this.baseImgUrl + 'inspectionResult.png',
                    name: 'Enter Inspection Results', route: './inspection/searchInspectionProperties'
                },
                {
                    icon: this.baseImgUrl + 'check-list.png', iconActive: this.baseImgUrl + 'check-list.png',
                    name: 'Print Blank Report', route: './inspection/printBlankReport'
                },
                // { icon: 'list_alt', name: 'Enter Inspection Result', route: '/inspectionResult' },
                {
                    icon: this.baseImgUrl + 'help.png', iconActive: this.baseImgUrl + 'help.png',
                    name: 'Authority Additional Page', route: './admin/authorityAdditionalPage'
                },
                {
                    icon: this.baseImgUrl + 'metrics.png', iconActive: this.baseImgUrl + 'metrics.png',
                    name: 'Metrics', route: './metrics'
                },
                {
                    icon: this.baseImgUrl + 'search.png', iconActive: this.baseImgUrl + 'search.png',
                    name: 'Configure RFA', route: './admin/configureRFA'
                },
                {
                    icon: this.baseImgUrl + 'search.png', iconActive: this.baseImgUrl + 'search.png',
                    name: 'Configure WorX', route: './admin/configureWorkx'
                }
            ];
        }
        /* Menu Options for the Authority Administrator*/
        if (this.user.role.id === Roles.ROLE_AUTHORITY_ADMIN || this.user.role.id === Roles.ROLE_AUTHORITY_WORX) {
            this.menuOptions = [{
                    icon: this.baseImgUrl + 'home.png', iconActive: this.baseImgUrl + 'home_Active.png',
                    name: 'Dashboard', route: './authority/dashboardAuthority'
                },
                {
                    icon: this.baseImgUrl + 'preferences.png', iconActive: this.baseImgUrl + 'preferences_Active.png',
                    name: 'Authority Preferences', route: './authority/authorityPreference'
                },
                {
                    icon: this.baseImgUrl + 'addProperty.png', iconActive: this.baseImgUrl + 'addProperty_Active.png',
                    name: 'Add  New Property', route: './addEditProperty/property'
                },
                {
                    icon: this.baseImgUrl + 'FileCabinet.png', iconActive: this.baseImgUrl + 'FileCabinet.png',
                    name: 'File Cabinet', route: "./general/fileCabinet",
                    queryParams: {
                        department: this.userDepartment
                    }
                },
                {
                    icon: this.baseImgUrl + 'inspectionResult.png', iconActive: this.baseImgUrl + 'inspectionResult.png',
                    name: 'Review Inspection', route: './inspection/reviewInspectionProperties'
                },
                {
                    icon: this.baseImgUrl + 'inspectionResult.png', iconActive: this.baseImgUrl + 'inspectionResult.png',
                    name: 'Enter Inspection Results', route: './inspection/searchInspectionProperties'
                },
                {
                    icon: this.baseImgUrl + 'addProperty.png', iconActive: this.baseImgUrl + 'addProperty_Active.png',
                    name: 'Custom Report', route: './authority/customReport'
                },
                {
                    icon: this.baseImgUrl + 'check-list.png', iconActive: this.baseImgUrl + 'check-list.png',
                    name: 'Update Deficiency Status', route: './inspection/updateDeficiency'
                },
                {
                    icon: this.baseImgUrl + 'check-list.png', iconActive: this.baseImgUrl + 'check-list.png',
                    name: 'Print Blank Report', route: './inspection/printBlankReport'
                },
                {
                    icon: this.baseImgUrl + 'check-list.png', iconActive: this.baseImgUrl + 'check-list.png',
                    name: 'Contact List', route: './authority/contactList'
                },
                {
                    icon: this.baseImgUrl + 'check-list.png', iconActive: this.baseImgUrl + 'check-list.png',
                    name: 'Configure Inspection Report', route: './inspection/configureInspectionReport'
                },
                {
                    icon: this.baseImgUrl + 'check-list.png', iconActive: this.baseImgUrl + 'check-list.png',
                    name: 'Cases', route: './cases'
                },
                {
                    icon: this.baseImgUrl + 'metrics.png', iconActive: this.baseImgUrl + 'metrics.png',
                    name: 'Metrics', route: './metrics'
                }
            ];
        }
        /* Menu Options for the Inspector Administrator*/
        if (this.user.role.id === Roles.ROLE_INSPECTOR_ADMIN) {
            this.menuOptions = [{
                    icon: this.baseImgUrl + 'home.png', iconActive: this.baseImgUrl + 'home_Active.png',
                    name: 'Dashboard', route: './inspector/dashboardInspector'
                },
                {
                    icon: this.baseImgUrl + 'preferences.png', iconActive: this.baseImgUrl + 'preferences_Active.png',
                    name: 'Inspector Preferences', route: './inspector/preferencesInspector'
                },
                {
                    icon: this.baseImgUrl + 'inspectionResult.png', iconActive: this.baseImgUrl + 'inspectionResult.png',
                    name: 'Enter Inspection Results', route: './inspection/searchInspectionProperties'
                },
                {
                    icon: this.baseImgUrl + 'addProperty.png', iconActive: this.baseImgUrl + 'addProperty_Active.png',
                    name: 'Add/Edit Property Details', route: './inspection/addEditProperty'
                },
                {
                    icon: this.baseImgUrl + 'FileCabinet.png', iconActive: this.baseImgUrl + 'FileCabinet.png',
                    name: 'File Cabinet', route: './general/fileCabinet'
                },
                {
                    icon: this.baseImgUrl + 'check-list.png', iconActive: this.baseImgUrl + 'check-list.png',
                    name: 'Update Deficiency Status', route: './inspection/updateDeficiency'
                },
                {
                    icon: this.baseImgUrl + 'preferences.png', iconActive: this.baseImgUrl + 'preferences_Active.png',
                    name: 'Bid Opportunity', route: './inspector/bidOpportunity'
                },
                {
                    icon: this.baseImgUrl + 'check-list.png', iconActive: this.baseImgUrl + 'check-list.png',
                    name: 'Print Blank Report', route: './inspection/printBlankReport'
                },
                {
                    icon: this.baseImgUrl + 'check-list.png', iconActive: this.baseImgUrl + 'check-list.png',
                    name: 'Cases', route: './cases'
                },
                {
                    icon: this.baseImgUrl + 'metrics.png', iconActive: this.baseImgUrl + 'metrics.png',
                    name: 'Metrics', route: './metrics'
                },
                {
                    icon: this.baseImgUrl + 'check-list.png', iconActive: this.baseImgUrl + 'check-list.png',
                    name: 'List Of AHJs', route: './inspector/listOfAHJs'
                },
                {
                    icon: this.baseImgUrl + 'check-list.png', iconActive: this.baseImgUrl + 'check-list.png',
                    name: 'List Of WorX', route: './inspector/listOfWorX'
                },
                {
                    icon: this.baseImgUrl + 'check-list.png', iconActive: this.baseImgUrl + 'check-list.png',
                    name: 'Reports', route: './inspector/reports'
                }
            ];
        }
        /* Menu Options for the Authority Administrator*/
        if (this.user.role.id === Roles.ROLE_RFA) {
            this.menuOptions = [{
                    icon: this.baseImgUrl + 'home.png', iconActive: this.baseImgUrl + 'home_Active.png',
                    name: 'Dashboard', route: './authority/dashboardAuthority'
                },
                {
                    icon: this.baseImgUrl + 'preferences.png', iconActive: this.baseImgUrl + 'preferences_Active.png',
                    name: 'RFA Preferences', route: './authority/authorityPreference'
                },
                {
                    icon: this.baseImgUrl + 'addProperty.png', iconActive: this.baseImgUrl + 'addProperty_Active.png',
                    name: 'Add  New Property', route: './addEditProperty/property'
                },
                {
                    icon: this.baseImgUrl + 'FileCabinet.png', iconActive: this.baseImgUrl + 'FileCabinet.png',
                    name: 'File Cabinet', route: './general/fileCabinet',
                    queryParams: {
                        department: this.userDepartment
                    }
                },
                {
                    icon: this.baseImgUrl + 'inspectionResult.png', iconActive: this.baseImgUrl + 'inspectionResult.png',
                    name: 'Review Inspection', route: './inspection/reviewInspectionProperties'
                },
                {
                    icon: this.baseImgUrl + 'inspectionResult.png', iconActive: this.baseImgUrl + 'inspectionResult.png',
                    name: 'Enter Inspection Results', route: './inspection/searchInspectionProperties'
                },
                {
                    icon: this.baseImgUrl + 'check-list.png', iconActive: this.baseImgUrl + 'check-list.png',
                    name: 'Update Deficiency Status', route: './inspection/updateDeficiency'
                }
            ];
        }
    };
    return AppSideNavComponent;
}());
export { AppSideNavComponent };
