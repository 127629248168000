import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
// tslint:disable-next-line: max-line-length
import { PropertyDetails, SystemDetails, ContsructionType, PropertyType, ZipcodeMappingDetails, AssetType, States, Cities, ContactListResponse } from '../../models/property/property.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PropertyService = /** @class */ (function () {
    function PropertyService(http) {
        this.http = http;
        // assetType$: BehaviorSubject<AssetType> = new BehaviorSubject({});
        this.baseUrl = environment.baseUrl;
        this.propertyDetails$ = new BehaviorSubject(null);
        this.propertySaved = new BehaviorSubject(false);
        this.isPropAddressValidated$ = new BehaviorSubject(false);
        this.oldProperyDetailsModel$ = new BehaviorSubject(null);
        this.propertyId$ = new BehaviorSubject(-1);
        this.systemDetails$ = new BehaviorSubject(null);
        this.uspsAPIrootUrl = 'https://secure.shippingapis.com/ShippingApi.dll?API=Verify&XML=';
        this.uspsUserId = '808TEGRI1050';
    }
    PropertyService.prototype.getConstructionTypeOptions = function () {
        var url = this.baseUrl + '/code/constructiontype';
        return this.http.get(url);
        // .pipe(map(res => res ));
    };
    PropertyService.prototype.getOccupancyType = function () {
        var url = this.baseUrl + '/code/propertytype';
        return this.http.get(url);
    };
    PropertyService.prototype.getInspectionFreqOptions = function () {
        var url = this.baseUrl + '/code/inspectionfrequency';
        return this.http.get(url);
    };
    PropertyService.prototype.getJurisdictionDetailsFromZipcode = function (zipcode) {
        var url = this.baseUrl + ("/zipcode/" + zipcode + "/details");
        return this.http.get(url);
    };
    PropertyService.prototype.checkDuplicateProperty = function (data) {
        var url = this.baseUrl + '/property/duplicate';
        return this.http.post(url, data);
    };
    PropertyService.prototype.postPropertyDetails = function (data) {
        var url = this.baseUrl + '/property';
        return this.http.post(url, data);
    };
    PropertyService.prototype.postPropertyDetailsWithAsset = function (data) {
        var url = this.baseUrl + '/property/propertyAssets';
        return this.http.post(url, data);
    };
    PropertyService.prototype.putPropertyDetails = function (data, propId) {
        var url = this.baseUrl + ("/property/" + propId);
        return this.http.put(url, data);
    };
    PropertyService.prototype.getAssestTypeOptions = function () {
        var url = this.baseUrl + '/assettype';
        return this.http.get(url);
    };
    PropertyService.prototype.getCustomAssetOptions = function (jurisId) {
        var url = this.baseUrl + ("/property/jurisdiction/" + jurisId + "/assetTypes");
        return this.http.get(url);
    };
    PropertyService.prototype.validateAddressFromUsps = function (addressObj) {
        var addressRequestTemplate = "<AddressValidateRequest USERID=\"" + this.uspsUserId + "\"><Address ID=\"1\"><FirmName/>"
            + ("<Address1>" + addressObj.streetLine2 + "</Address1><Address2>" + addressObj.streetLine1 + "</Address2><City>" + addressObj.city + "</City>")
            + ("<State>" + addressObj.state + "</State><Zip5>" + addressObj.zipcode + "</Zip5><Zip4/></Address></AddressValidateRequest>");
        return this.http.get(this.uspsAPIrootUrl + addressRequestTemplate, { responseType: 'text' });
    };
    PropertyService.prototype.postSystemDetails = function (systemDetails) {
        var url = this.baseUrl + '/propertyAsset';
        return this.http.post(url, systemDetails);
    };
    PropertyService.prototype.putSystemDetails = function (systemDetails) {
        var url = this.baseUrl + '/propertyAsset';
        return this.http.put(url, systemDetails);
    };
    PropertyService.prototype.getAllSystemsOfProperty = function (propId) {
        var url = this.baseUrl + ("/property/" + propId + "/propAsset");
        return this.http.get(url);
    };
    PropertyService.prototype.getAllSystemsOfPropertyRFA = function (propId, jurisId) {
        var url = this.baseUrl + ("/property/" + propId + "/propAsset");
        return this.http.get(url, { params: jurisId });
    };
    PropertyService.prototype.deleteAssetOfProperty = function (id) {
        var url = this.baseUrl + ("/propertyAsset/" + id);
        return this.http.delete(url);
    };
    PropertyService.prototype.getPropertyById = function (id) {
        var url = this.baseUrl + ("/property/" + id);
        return this.http.get(url);
    };
    PropertyService.prototype.getOwnerPropertyList = function (id) {
        var url = this.baseUrl + ("/owner/properties/" + id);
        return this.http.get(url);
    };
    PropertyService.prototype.getManagerPropertyList = function (id) {
        var url = this.baseUrl + ("/manager/properties/" + id);
        return this.http.get(url);
    };
    PropertyService.prototype.getAllStates = function () {
        var url = this.baseUrl + '/state';
        return this.http.get(url);
    };
    PropertyService.prototype.getCitiesByState = function (stateID) {
        var url = this.baseUrl + "/state/" + stateID + "/city";
        return this.http.get(url);
    };
    PropertyService.prototype.validatePAC = function (propertyId, pac) {
        var url = this.baseUrl + "/property/" + propertyId + "/ispacvalid/" + pac;
        return this.http.get(url);
    };
    PropertyService.prototype.deleteProperty = function (propertyId) {
        var url = this.baseUrl + "/property/" + propertyId;
        return this.http.delete(url);
    };
    PropertyService.prototype.deletePropertyPendingApproval = function (propertyId, authorityComments) {
        var url = this.baseUrl + "/ppa/property/";
        return this.http.delete(url, { params: { propertyId: propertyId, authorityComments: authorityComments } });
    };
    PropertyService.prototype.activateProperty = function (propId) {
        var url = this.baseUrl + "/activateProperty/" + propId;
        return this.http.put(url, null);
    };
    PropertyService.prototype.restoreProperty = function (propId) {
        var url = this.baseUrl + "/restore/property/" + propId;
        return this.http.post(url, null);
    };
    PropertyService.prototype.activateSystem = function (propAssetId) {
        var url = this.baseUrl + "/activateAsset/" + propAssetId;
        return this.http.put(url, null);
    };
    PropertyService.prototype.getActiveJurisdictions = function () {
        var url = this.baseUrl + "/jurisdiction";
        return this.http.get(url);
    };
    PropertyService.prototype.getPropertyListByJurisdictionId = function (jurisdictionId) {
        var url = this.baseUrl + "/property/jurisdiction/" + jurisdictionId;
        return this.http.get(url);
    };
    PropertyService.prototype.setAccountMaintainanceFeeForProperty = function (params) {
        var url = this.baseUrl + "/property/accountmaintenancefee";
        return this.http.put(url, {}, { params: params });
    };
    PropertyService.prototype.setSuppressFeeForProperty = function (params) {
        var url = this.baseUrl + "/property/suppressfee";
        return this.http.put(url, {}, { params: params });
    };
    PropertyService.prototype.setTechSupportFeeForProperty = function (params) {
        var url = this.baseUrl + "/property/techsupportfee";
        return this.http.put(url, {}, { params: params });
    };
    PropertyService.prototype.setTechSupportFeeForPropertyAsset = function (params) {
        var url = this.baseUrl + "/propertyAsset/techsupportfee";
        return this.http.put(url, {}, { params: params });
    };
    PropertyService.prototype.putPropertyEditDetails = function (data, propId) {
        var url = this.baseUrl + ("/property/contacts/" + propId);
        return this.http.put(url, data);
    };
    PropertyService.prototype.getNotificationLetter = function (propertyId, notificationType, journalId) {
        var url = this.baseUrl + "/property/notifications";
        return this.http.get(url, { params: { propertyId: propertyId, notificationType: notificationType, journalId: journalId } });
    };
    PropertyService.prototype.getManagerList = function (limit, offset, searchString) {
        var url = this.baseUrl + "/contactList/manager";
        return this.http.get(url, {
            params: {
                offset: offset,
                limit: limit,
                searchString: searchString
            }
        });
    };
    PropertyService.prototype.putPropCoordinated = function (propId, data) {
        var url = this.baseUrl + ("/property/coordinates/" + propId);
        return this.http.put(url, data);
    };
    PropertyService.prototype.putAssetCoordinates = function (data) {
        var url = this.baseUrl + "/propertyAsset/coordinates";
        return this.http.put(url, data);
    };
    PropertyService.prototype.isAddingNewSystemAllowedDataForDepartment = function (propertyId) {
        var url = this.baseUrl + "/property/jurisdiction/addingNewSystemAllowed";
        return this.http.get(url, {
            params: {
                jurisdictionId: propertyId
            }
        });
    };
    PropertyService.prototype.isAddingNewSystemAllowedDataForJuridiction = function (juriId) {
        var url = this.baseUrl + "/property/jurisdiction/addingNewWorxSystemAllowed";
        return this.http.get(url, {
            params: {
                jurisdictionId: juriId
            }
        });
    };
    PropertyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PropertyService_Factory() { return new PropertyService(i0.ɵɵinject(i1.HttpClient)); }, token: PropertyService, providedIn: "root" });
    return PropertyService;
}());
export { PropertyService };
