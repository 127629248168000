import { OnInit } from '@angular/core';
var InspectorDetailsComponent = /** @class */ (function () {
    function InspectorDetailsComponent() {
    }
    InspectorDetailsComponent.prototype.ngOnInit = function () {
        this.isInspector =
            JSON.parse(localStorage.getItem('userDetails')).role.description.toLowerCase().includes('inspector')
                ? true : false;
    };
    return InspectorDetailsComponent;
}());
export { InspectorDetailsComponent };
