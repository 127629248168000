<div class="custom-spacing">
  <span *ngIf="showPencil && isEditDetail !== 'Edit'">
    <button *ngIf="showMessage" class="custom-secondary-buttons" appReadOnly type="button"
    (click)="editProperty()">
      <img alt="tegrisfire" src="./assets/images/edit_icon.png" /> Edit property details
    </button>
  </span>

  <form  *ngIf="!loading && !showPencil || isEditDetail === 'Edit'" [formGroup]="addPropertyForm"
    (ngSubmit)="onSubmit($event)" autocomplete="off">
    <div class="row p-0 ml-0 form-group-margin ">
      <label for="smFormGroupInput"
        class="col-2 p-0 col-form-label col-form-label-sm inputLabel font-weight-bold">Building Name:</label>
      <div class="col-3">
        <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null"  autocomplete="off" type="text" class="form-control form-control-sm rounded-form-fields"
          formControlName="buildingName" #buildingName [value]="addPropertyForm.value.buildingName" maxlength="100" />
      </div>
    </div>
    <div class="row  p-0 ml-0 sub-heading-margin-bottom" *ngIf="isEditDetail !== 'Edit'">
      <label for="smFormGroupInput"
        class="col-2 p-0 col-form-label col-form-label-sm inputLabel font-weight-bold">Status:</label>
      <div class="col-3">
        <select class="col-12 rounded-form-fields no-Padding custom-select"
          [attr.disabled]="isDisabledReadOnly || !(user.role.id == 3 || user.role.id == 1 || user.role.id == 8 || user.role.id == 9) ? 'disabled' : null"
          formControlName="isActive" #Category
          [value]="addPropertyForm.value.isActive">
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div>
    </div>
    <div class="row p-0 ml-0 form-group-margin " *ngIf="isEditDetail !== 'Edit'">
      <div class="col-8 offset-2">
        <label for="smFormGroupInput" class="p-0 col-form-label col-form-label-sm custom-label">
          Only authority can change the status of a property.</label>
      </div>
    </div>

    <div class="row p-0 ml-0 form-group-margin ">
      <label for="smFormGroupInput"
        class="col-2 p-0 col-form-label col-form-label-sm inputLabel font-weight-bold">Occupancy Type:</label>
      <div class="col-3">
        <select [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" class="rounded-form-fields  no-Padding custom-select" formControlName="occupancyType"
          (change)="checkOtherType($event.target.value)" [value]="addPropertyForm.value.occupancyType">
          <option value="">Select</option>
          <option *ngFor="let OccupancyType of occupancyTypeOptions" [value]="OccupancyType.name">
            {{ OccupancyType.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row p-0 ml-0 form-group-margin " *ngIf="occupancySubTypeDisplay">
      <label for="smFormGroupInput"
        class="col-2 p-0 col-form-label col-form-label-sm inputSubLabel tabSpace font-weight-bold">Enter
        Occupancy Sub Type Here:</label>
      <div class="col-3">
        <select [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" class="rounded-form-fields no-Padding custom-select" formControlName="occupancySubType"
          [(ngModel)]="addPropertyForm.value.occupancySubType">
          <option *ngFor="let OccupancySubType of occupancySubTypeOptions" [ngValue]="OccupancySubType.subtypeName">
            {{ OccupancySubType.subtypeName }}
          </option>
        </select>
      </div>
    </div>
    <div class="row p-0 ml-0 form-group-margin " *ngIf="occupancyTypeOther">
      <label for="smFormGroupInput"
        class="col-2 p-0 col-form-label col-form-label-sm inputSubLabel tabSpace font-weight-bold">Enter
        Occupancy Type Here:</label>
      <div class="col-3">
        <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" class="rounded-form-fields form-control form-control-sm" type="text" formControlName="otherOccupancyType"
          [value]="addPropertyForm.value.otherOccupancyType" id="otherOccupancyType" />
        <div class="error" *ngFor="let message of errors.get('otherOccType')">
          *{{ message }}
        </div>
      </div>

    </div>
    <div class="row p-0 ml-0 form-group-margin ">
      <label class="col-2 col-form-label p-0 col-form-label-sm inputLabel font-weight-bold">Construction Type:</label>
      <div class="col-3">
        <select [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" class="rounded-form-fields no-Padding custom-select" formControlName="constructionType"
          [value]="addPropertyForm.value.constructionType" (change)="checkOtherConstructionType($event.target.value)">
          <option value="">Select</option>
          <option *ngFor="let ConstructionType of constructionTypeOptions" [value]="ConstructionType.name">
            {{ ConstructionType.name }}
          </option>
          <option value="Other">Other</option>
        </select>
      </div>
    </div>
    <div class="row p-0 ml-0 form-group-margin " *ngIf="constructionTypeOther">
      <label for="smFormGroupInput"
        class="col-2 p-0 col-form-label col-form-label-sm inputSubLabel tabSpace font-weight-bold">
        Enter Construction Type Here:
      </label>
      <div class="col-3">
        <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" class="rounded-form-fields form-control form-control-sm" type="text"
          formControlName="otherContructionType" [value]="addPropertyForm.value.otherContructionType"
          id="otherConstructType" />
        <div class="error" *ngFor="let message of errors.get('otherConstructType')">
          *{{ message }}
        </div>
      </div>

    </div>
    <div class="row p-0 ml-0 form-group-margin ">
      <label for="smFormGroupInput"
        class="col-2 p-0 col-form-label col-form-label-sm inputLabel font-weight-bold">Number of Stories:</label>
      <div class="col-3">
        <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" type="text" class="form-control form-control-sm rounded-form-fields" formControlName="noOfStories"
          [value]="addPropertyForm.value.noOfStories" maxlength="3" id="noOfStories" />
        <div class="error" *ngFor="let message of errors.get('storiesNumber')">
          <label>*{{ message }}</label>
        </div>
      </div>

    </div>
    <!-- Change Request - Remove HyperLink  -->
    <div class="row p-0 ml-0 form-group-margin ">
      <label for="smFormGroupInput" class="col-2 p-0 col-form-label col-form-label-sm inputLabel font-weight-bold">Year
        Built:
      </label>
      <div class="col-3">
        <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" type="text" class="form-control form-control-sm rounded-form-fields" formControlName="yearBuilt"
          [value]="addPropertyForm.value.yearBuilt" maxlength="4" id="yearBuilt" />
        <div class="error" *ngFor="let message of errors.get('yearBuilt')">
          <label>*{{ message }}</label>
        </div>
      </div>

    </div>
    <div class="row p-0 ml-0 form-group-margin ">
      <label for="smFormGroupInput" class="col-2 p-0 col-form-label col-form-label-sm inputLabel font-weight-bold">Floor
        Area (sq ft):
      </label>
      <div class="col-3">
        <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" type="text" class="form-control form-control-sm rounded-form-fields" formControlName="floorArea"
          [value]="addPropertyForm.value.floorArea" maxlength="9" id="floorArea" />
        <div class="error" *ngFor="let message of errors.get('floorArea')">
          *{{ message }}
        </div>
      </div>

    </div>
    <div formGroupName="address">
      <div class="row pl-0 pr-0 ml-0 sub-heading-margin-bottom sub-heading-padding-top">
        <label for="smFormGroupInput"
          class="col-2 p-0 sub-heading-margin-bottom inputLabel font-weight-bold primaryColor">Property Address:
        </label>
        <div class="col-custom-checkbox" *ngIf="isEditDetail === 'Edit'">
          <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null"  class="custom-checkbox" type="checkbox" (click)="copyAddressEdit($event.target.checked)" />
        </div>
        <div class="col-6" *ngIf="isEditDetail === 'Edit'">
          <label class="custom-message">Copy Manager Address to Property Address.</label>
        </div>
      </div>
      <div class="row p-0 ml-0 form-group-margin">
        <label for="smFormGroupInput"
          class="col-2 pl-0 pr-0 col-form-label col-form-label-sm inputSubLabel tabSpace font-weight-bold">Street Line
          1: <label style="color: red">*</label></label>
        <div class="col-3">
          <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" autocomplete="off" class="rounded-form-fields form-control form-control-sm" type="text" required
            formControlName="streetLine1" [(ngModel)]="addPropertyForm.value.address.streetLine1" maxlength="512"
            id="addressStLine1" />
          <div class="error" *ngFor="let message of errors.get('streetLine1')">
            *{{ message }}
          </div>
        </div>

      </div>
      <div class="row p-0 ml-0 form-group-margin">
        <label for="smFormGroupInput"
          class="col-2 pl-0 pr-0 col-form-label col-form-label-sm inputSubLabel tabSpace font-weight-bold">Apt/ Suite/
          Other:</label>
        <div class="col-3">
          <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" class="rounded-form-fields form-control form-control-sm" type="text" formControlName="streetLine2"
            [(ngModel)]="addPropertyForm.value.address.streetLine2" maxlength="512" />
        </div>
      </div>
      <div class="row p-0 ml-0 form-group-margin">
        <label for="smFormGroupInput"
          class="col-2 pl-0 pr-0 col-form-label col-form-label-sm inputSubLabel tabSpace font-weight-bold">Zip Code:
          <label style="color: red">*</label></label>
        <div class="col-3">
          <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" class="rounded-form-fields form-control form-control-sm" type="text" formControlName="zipcode" required
            [(ngModel)]="addPropertyForm.value.address.zipcode" maxlength="512" maxlength="7" pattern="[a-zA-Z0-9]"
            title="Enter a five digit zipcode" id="addressZipCode" (blur)="populateAddress($event.target.value)" />
          <div class="error" *ngFor="let message of errors.get('zipcode')">
            *{{ message }}
          </div>
        </div>



      </div>
      <div class="row p-0 ml-0 form-group-margin">
        <label for="smFormGroupInput"
          class="col-2 pl-0 pr-0 col-form-label col-form-label-sm inputSubLabel tabSpace font-weight-bold">City: <label
            style="color: red">*</label></label>
        <div class="col-3">
          <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" class="rounded-form-fields form-control form-control-sm" type="text" required formControlName="city"
            [(ngModel)]="addPropertyForm.value.address.city" maxlength="256" id="addressCity" autocomplete="off"
            [readonly]="readOnlyCity" />
          <div class="error" *ngFor="let message of errors.get('city')">
            *{{ message }}
          </div>
        </div>

      </div>
      <div class="row p-0 ml-0 form-group-margin">
        <label for="smFormGroupInput"
          class="col-2 pl-0 pr-0 col-form-label col-form-label-sm inputSubLabel tabSpace font-weight-bold">State:
          <label style="color: red">*</label></label>
        <div class="col-3">
          <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" class="rounded-form-fields form-control form-control-sm" type="text" required formControlName="state"
            [(ngModel)]="addPropertyForm.value.address.state" autocomplete="off" [readonly]="readOnlyState"
            (ngModelChange)="addPropertyForm.value.address.state = $event" maxlength="2" id="addressState" />
          <div class="error" *ngFor="let message of errors.get('state')">
            *{{ message }}
          </div>
        </div>
      </div>
      <div class="row p-0 ml-0 form-group-margin " *ngIf="isEditDetail !== 'Edit'">
        <label for="smFormGroupInput"
          class="col-2 p-0 col-form-label col-form-label-sm inputLabel font-weight-bold">Jurisdiction
          Name:</label>
        <div class="col-3">
          <select [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" class="rounded-form-fields no-Padding custom-select" formControlName="jurisdictionName"
            [(ngModel)]="addPropertyForm.value.address.jurisdictionName" (load)="setJurisdiction($event.target.value)"
            (change)="setJurisdiction($event.target.value)">
            <option value="0" [selected]="zipcodeMappingDetails.selectedJurisdictionId === 0">
              Select</option>
            <option value="-1" [selected]="zipcodeMappingDetails.jurisdictionDto.length === 0 || zipcodeMappingDetails.selectedJurisdictionId==-1">
              Other</option>
            <option *ngFor="let JurisdictionDetails of zipcodeMappingDetails.jurisdictionDto"
              [value]="JurisdictionDetails.id"
              [selected]="zipcodeMappingDetails.selectedJurisdictionId == JurisdictionDetails.id">
              {{ JurisdictionDetails.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div formGroupName="manager">
      <div class="row p-0 ml-0 form-group-margin ">
        <label for="smFormGroupInput"
          class="col-2 p-0 col-form-label col-form-label-sm inputLabel font-weight-bold">Manager:</label>
        <div class="col-3 row">
          <div class="col pr-1">
            <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" type="text" class="form-control form-control-sm rounded-form-fields" style="padding-right: 10px !important;" formControlName="firstName"
              [(ngModel)]="addPropertyForm.value.manager.firstName" placeholder="PROPERTY" maxlength="64"
              id="managerFirstName" autocomplete="off" />
          </div>
          <div class="col pr-0 pl-1">
            <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" type="text" class="form-control form-control-sm rounded-form-fields"  style="padding-right: 10px !important;" formControlName="lastName"
              [(ngModel)]="addPropertyForm.value.manager.lastName" placeholder="MANAGER" maxlength="64"
              id="managerLastName" autocomplete="off" />
          </div>
          <div class="error" *ngFor="let message of errors.get('managerName')">
            *{{ message }}
          </div>
        </div>

      </div>

      <div class="row p-0 ml-0 form-group-margin ">
        <label for="smFormGroupInput"
          class="col-2 p-0 col-form-label col-form-label-sm inputLabel font-weight-bold">Phone Number:
        </label>
        <div class="col-3">
          <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" type="text" class="form-control form-control-sm rounded-form-fields" formControlName="phoneNumber"
            pattern="/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/" id="phonep1" autocomplete="off"
            />
          <div class="error" *ngFor="let message of errors.get('phoneNo')">
            *{{ message }}
          </div>
        </div>

      </div>
      <div class="row p-0 ml-0 sub-heading-margin-bottom">
        <label for="smFormGroupInput" class="col-2 p-0 col-form-label col-form-label-sm inputLabel font-weight-bold">
          Email Address:</label>
        <div class="col-3">
          <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" type="email" id="emailAddress" class="form-control form-control-sm rounded-form-fields"
            formControlName="emailAddress" [value]="addPropertyForm.value.manager.emailAddress" maxlength="64"
            autocomplete="off" />
          <div class="error" *ngFor="let message of errors.get('email')">
            *{{ message }}
          </div>
          <div class="error" *ngFor="let message of errors.get('manager')">
            <label>*{{ message }}</label>
          </div>
        </div>

      </div>
      <div class="row p-0 ml-0 form-group-margin ">
        <div class="col-8 offset-2 ">
          <label for="smFormGroupInput" class="p-0 col-form-label col-form-label-sm custom-label">
            All notifications will be sent to the email address entered
            here</label>
        </div>
      </div>
      <div class="row  pr-0 pl-0 ml-0 sub-heading-margin-bottom sub-heading-padding-top ">
        <label for="smFormGroupInput"
          class="col-2 p-0 sub-heading-margin-bottom inputLabel font-weight-bold primaryColor">
          Manager Address:</label>
        <div class="col-custom-checkbox" *ngIf="isEditDetail !== 'Edit'">
          <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" class="custom-checkbox" type="checkbox" (click)="copyAddress($event.target.checked)" />
        </div>
        <div class="col-6" *ngIf="isEditDetail !== 'Edit'">
          <label class="custom-message">Copy Property Address to Manager Address.</label>
        </div>
      </div>
      <div formGroupName="managerAddress">
        <div class="row  p-0 ml-0 form-group-margin">
          <label for="smFormGroupInput"
            class="col-2 pl-0 pr-0 col-form-label col-form-label-sm inputSubLabel tabSpace font-weight-bold">Street
            Line 1: </label>
          <div class="col-3">
            <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" class="rounded-form-fields form-control form-control-sm" type="text" required
              formControlName="streetLine1" [value]="
                  addPropertyForm.value.manager.managerAddress.streetLine1
                " maxlength="512" autocomplete="off" />
          </div>
        </div>
        <div class="row  p-0 ml-0 form-group-margin">
          <label for="smFormGroupInput"
            class="col-2 pl-0 pr-0 col-form-label col-form-label-sm inputSubLabel tabSpace font-weight-bold">Apt/
            Suite/ Other: </label>
          <div class="col-3">
            <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" class="rounded-form-fields form-control form-control-sm" type="text" formControlName="streetLine2"
              [value]="
                  addPropertyForm.value.manager.managerAddress.streetLine2
                " maxlength="512" autocomplete="off" />
          </div>
        </div>
        <div class="row  p-0 ml-0 form-group-margin">
          <label for="smFormGroupInput"
            class="col-2 pl-0 pr-0 col-form-label col-form-label-sm inputSubLabel tabSpace font-weight-bold">Zip Code:
          </label>
          <div class="col-3">
            <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" class="rounded-form-fields form-control form-control-sm" type="text" formControlName="zipcode"
              [value]="addPropertyForm.value.manager.managerAddress.zipcode" maxlength="7" pattern="[0-9]{5}"
              title="Enter a five digit zipcode" (blur)="populateManagerCityState($event.target.value)"
              autocomplete="off" />
          </div>
        </div>
        <div class="row  p-0 ml-0 form-group-margin">
          <label for="smFormGroupInput"
            class="col-2 pl-0 pr-0 col-form-label col-form-label-sm inputSubLabel tabSpace font-weight-bold">City:
          </label>
          <div class="col-3">
            <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" class="rounded-form-fields form-control form-control-sm" type="text" required formControlName="city"
              [(ngModel)]="addPropertyForm.value.manager.managerAddress.city" maxlength="256" autocomplete="off"
              [readonly]="readOnlyManagerCity" />
          </div>
        </div>
        <div class="row  p-0 ml-0 form-group-margin form-last-field">
          <label for="smFormGroupInput"
            class="col-2 pl-0 pr-0 col-form-label col-form-label-sm inputSubLabel tabSpace font-weight-bold">State:
          </label>
          <div class="col-3">
            <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" class="rounded-form-fields form-control form-control-sm" type="text" required formControlName="state"
              [(ngModel)]="addPropertyForm.value.manager.managerAddress.state" maxlength="2"
              [readonly]="readOnlyManagerState" autocomplete="off" />
              <div class="error" *ngFor="let message of errors.get('managerAddress')">
                <label>*{{ message }}</label>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group  form-group-padding " formGroupName="owner" *ngIf="isEditDetail === 'Edit'">
      <div class="row form-group-padding">
        <label for="smFormGroupInput"
          class="col-4 col-form-label col-form-label-sm inputLabel font-weight-bold">Property
          Owner:</label>
        <div class="col-3">
          <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" autocomplete="off" type="text" class="form-control form-control-sm rounded-form-fields"
            formControlName="name" #owner [(ngModel)]="addPropertyForm.value.owner.name" maxlength="100" readonly
            (click)="openOwnerBox();" />
        </div>
      </div>
      <div class="row form-group-padding">
        <label for="smFormGroupInput" class="col-4 col-form-label col-form-label-sm inputLabel font-weight-bold">Owner
          Phone
          Number:</label>
        <div class="col-3">
          <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" autocomplete="off" type="text" class="form-control form-control-sm rounded-form-fields"
            formControlName="number" #owner [(ngModel)]="addPropertyForm.value.owner.number" readonly
            (click)="openOwnerBox();" />
        </div>
      </div>
      <div class="row form-group-padding">
        <label for="smFormGroupInput" class="col-4 col-form-label col-form-label-sm inputLabel font-weight-bold">Owner
          Address:</label>
        <div class="col-3">
          <input [attr.disabled]="isDisabledReadOnly ? 'disabled' : null" autocomplete="off" type="text" class="form-control form-control-sm rounded-form-fields"
            formControlName="address" #owner [(ngModel)]="addPropertyForm.value.owner.address" maxlength="100" readonly
            (click)="openOwnerBox();" />
        </div>
      </div>
    </div>
    <hr />
    <div class="sub-heading-padding-top">
      <div class="d-flex justify-content-end row ml-md-3 mr-md-3">
        <button class="custom-buttons" appDataInvestigator type="submit" value="Next" [disabled]="creationDisabled"
          *ngIf="isEditDetail !== 'Edit'" (click)="buttonClicked($event.target.innerText)">
          Next
        </button>
        <button class="custom-buttons" type="button" (click)="goBackToPreviousPage()" *ngIf="isEditDetail !== 'Edit'"
          value="Cancel">
          Cancel
        </button>
        <button class="custom-buttons" type="button" (click)="resetFunction()" *ngIf="isEditDetail !== 'Edit'"
          value="Reset">
          Reset
        </button>
        <button class="custom-buttons" appDataInvestigator type="submit"
          [ngClass]="{'custom-button-disabled': nextDisabled || ((userRole === 'ROLE_OWNER' || userRole === 'ROLE_INSPECTOR')  && !propertyService.propertyId$.getValue())}"
          (click)="buttonClicked($event.target.innerText)"
          [disabled]="nextDisabled || ((userRole === 'ROLE_OWNER' || userRole === 'ROLE_INSPECTOR')  && !propertyService.propertyId$.getValue())"
          value="Finish" *ngIf="isEditDetail !== 'Edit'">
          Finish
        </button>
        <button class="custom-buttons" appReadOnly appDataInvestigator type="submit" value="Next"
          *ngIf="isEditDetail === 'Edit'" (click)="buttonClicked($event.target.innerText)">
          Submit
        </button>
        <button class="custom-buttons" type="button" (click)="closeDialog()" *ngIf="isEditDetail === 'Edit'"
          value="Cancel">
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>
