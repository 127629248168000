<app-dialog>
  <span class="dialog-title">
    Property Access Code
    <span class="float-right">
      <mat-icon class="cursor-pointer" (click)="cancel()">close</mat-icon>
    </span>
  </span>

    <div class="dialog-content">
      <div class="form-group row">
        <label class="col-form-label col-6 col-form-label-sm inputLabel">
          Property Access Code:
          <label class='mandatory'>*</label>
        </label>
        <input class="form-control-sm rounded-form-fields" type="text" [(ngModel)]="pac" class="col-6">
        <div *ngIf="error && error.length > 0" class="error">
            *{{error}}
        </div>
      </div>
      <hr />
      <div class="form-group row">
        <label class="col-12 inputLabel">

          <strong>Note</strong>: Access code is uniquely defined for each property and it allows the inspector to enter inspection
          report for all systems belonging to the property.
        </label>
        <label class="col-12 col-form-label col-form-label-sm inputLabel">
          You can get the access code from top right corner of the notification letter or request the same from owner.
        </label>
      </div>

      <div class="form-group row form-group-padding actions">
        <button class="custom-buttons" (click)="save()" >Submit</button>
        <button class="custom-buttons" (click)="cancel()" >Cancel</button>
      </div>

      <hr />
      <div class="row">
        <label class="col-12 col-form-label col-form-label-sm inputLabel">
          Limited access to this property can be obtained by selecting the PAC Bypass button below.
        </label>
      </div>
      <div class="actions">
        <button class="custom-buttons" (click)="byPass()">PAC BYPASS</button>
      </div>
    </div>
</app-dialog>

