import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
var USPSValidPopUpComponent = /** @class */ (function () {
    function USPSValidPopUpComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    USPSValidPopUpComponent.prototype.ngOnInit = function () {
        console.log(this.data);
    };
    USPSValidPopUpComponent.prototype.save = function () {
        this.dialogRef.close('continue');
    };
    USPSValidPopUpComponent.prototype.cancel = function () {
        this.dialogRef.close('cancelled');
    };
    return USPSValidPopUpComponent;
}());
export { USPSValidPopUpComponent };
