<app-dialog>
  <span class="dialog-title">
    <span [innerHTML]="data.title || 'Confirmation'"></span>
    <span class="float-right">
      <mat-icon class="cursor-pointer" (click)="areYouSureNo()">close</mat-icon>
    </span>
  </span>
  <div class="dialog-content">
    <div class="form-group">
      <label class="col-form-label col-form-label-sm inputLabel notes-sub-heading"
        [innerHTML]="data?.content ? data.content : data">
      </label>
    </div>
  </div>
  <div class="dialog-actions">
      <button class="custom-buttons" *ngIf="data?.yesButton !== 'do not show'" (click)="areYouSureYes()">{{ data?.yesButton  ? data.yesButton: 'Yes'}}</button>
      <button class="custom-buttons" (click)="areYouSureNo()">{{ data?.noButton ? data.noButton : 'No'}}</button>
  </div>
</app-dialog>
