/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../enter-inspection/enter-inspection.component.ngfactory";
import * as i2 from "@angular/material/core";
import * as i3 from "@angular/material-moment-adapter";
import * as i4 from "@angular/common";
import * as i5 from "../enter-inspection/enter-inspection.component";
import * as i6 from "@angular/material/dialog";
import * as i7 from "../../core/services/property/property.service";
import * as i8 from "@angular/router";
import * as i9 from "../../core/services/inspection/inspection.service";
import * as i10 from "../../core/services/logger/logger.service";
import * as i11 from "../../authority/shared/services/authority.service";
import * as i12 from "../../core/services/utility.service";
import * as i13 from "../../shared/components/app-dialog/app-dialog.component.ngfactory";
import * as i14 from "../../shared/components/app-dialog/app-dialog.component";
import * as i15 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i16 from "@angular/material/icon";
import * as i17 from "./view-report-dialog.component";
var styles_ViewReportDialogComponent = [];
var RenderType_ViewReportDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ViewReportDialogComponent, data: {} });
export { RenderType_ViewReportDialogComponent as RenderType_ViewReportDialogComponent };
function View_ViewReportDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "app-enter-inspection", [], null, null, null, i1.View_EnterInspectionComponent_0, i1.RenderType_EnterInspectionComponent)), i0.ɵprd(4608, null, i2.DateAdapter, i3.MomentDateAdapter, [i2.MAT_DATE_LOCALE]), i0.ɵprd(512, null, i4.DatePipe, i4.DatePipe, [i0.LOCALE_ID]), i0.ɵdid(3, 4440064, null, 0, i5.EnterInspectionComponent, [i6.MatDialog, i7.PropertyService, i8.Router, i9.InspectionService, i8.ActivatedRoute, i4.DatePipe, i10.LoggerService, i11.AuthorityService, i0.ElementRef, i12.UtilityService, i4.Location], { viewReportData: [0, "viewReportData"] }, null), i0.ɵprd(256, null, i2.MAT_DATE_FORMATS, i5.MY_FORMATS, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ViewReportDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "app-dialog", [], null, null, null, i13.View_AppDialogComponent_0, i13.RenderType_AppDialogComponent)), i0.ɵdid(1, 114688, null, 0, i14.AppDialogComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 5, "span", [["class", "dialog-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" System Inspection Report "])), (_l()(), i0.ɵeld(4, 0, null, null, 3, "span", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "mat-icon", [["class", "cursor-pointer mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_MatIcon_0, i15.RenderType_MatIcon)), i0.ɵdid(6, 9158656, null, 0, i16.MatIcon, [i0.ElementRef, i16.MatIconRegistry, [8, null], [2, i16.MAT_ICON_LOCATION]], null, null), (_l()(), i0.ɵted(-1, 0, ["close"])), (_l()(), i0.ɵeld(8, 0, null, 1, 2, "div", [["class", "dialog-content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ViewReportDialogComponent_1)), i0.ɵdid(10, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, null, 2, 0, "div", [["class", "dialog-actions"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 6, 0); var currVal_2 = _co.data; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 6).inline; var currVal_1 = (((i0.ɵnov(_v, 6).color !== "primary") && (i0.ɵnov(_v, 6).color !== "accent")) && (i0.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_0, currVal_1); }); }
export function View_ViewReportDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-view-report-dialog", [], null, null, null, View_ViewReportDialogComponent_0, RenderType_ViewReportDialogComponent)), i0.ɵdid(1, 114688, null, 0, i17.ViewReportDialogComponent, [i6.MatDialogRef, i6.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewReportDialogComponentNgFactory = i0.ɵccf("app-view-report-dialog", i17.ViewReportDialogComponent, View_ViewReportDialogComponent_Host_0, {}, {}, []);
export { ViewReportDialogComponentNgFactory as ViewReportDialogComponentNgFactory };
