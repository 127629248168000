<app-dialog>
    <span class="dialog-title">
      System Inspection Report
      <span class="float-right">
        <mat-icon class="cursor-pointer" (click)="cancel()">close</mat-icon>
      </span>
    </span>
    <div class="dialog-content">
        <app-enter-inspection 
            *ngIf="data"
            [viewReportData] = "data"
        ></app-enter-inspection>
    </div>
    <div class="dialog-actions" >
      
    </div>
</app-dialog>

