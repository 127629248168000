import { OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
var ParentComponent = /** @class */ (function () {
    function ParentComponent() {
        this.unsub = [];
        this.environment = environment;
    }
    ParentComponent.prototype.ngOnInit = function () {
    };
    ParentComponent.prototype.ngOnDestroy = function () {
        this.unsub.forEach(function (res) {
            res.unsubscribe();
        });
    };
    return ParentComponent;
}());
export { ParentComponent };
var ParentModule = /** @class */ (function () {
    function ParentModule() {
    }
    return ParentModule;
}());
export { ParentModule };
