import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import * as AES from "crypto-js/aes";
import * as UTF8 from "crypto-js/enc-utf8";
import {
  User,
  Roles,
  LoginInfo,
  UserLogin,
  UserDetails
} from '../../shared/models/user.model';
import { LoginService } from '../services/login.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ForgotPasswordComponent } from './forgot-passwordDialog/forgot-password/forgot-password.component';
import { EmailSentDialogComponent } from './email-sent-dialog/email-sent-dialog.component';
import { OwnerService } from '../../shared/services/owner.service';
import { UserService } from '../../shared/services/user.service';


@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit, OnDestroy {
  private secretKey: string = "ui87n@!9845%$bh|pqw";
  public form: FormGroup;
  // showPassword: string;
  loginInfo: UserLogin;
  userDetails: LoginInfo;
  user: UserDetails;
  showPassword: string;
  formValidity: boolean;
  errors: {
    email: string;
    password: string;
    apiErrors: string;
  };
  subscriptions = [];
  loading = false;
  validNewPolicyPassword = true;
  disableClick = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    public dialog: MatDialog,
    private ownerService: OwnerService,
    private userService: UserService,
    // private snacbar: MatSnackBar
  ) { }

  ngOnInit() {

    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
          this.loading = false;
      }
  });

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  this.resetErrors();
  this.formValidity = true;
  this.showPassword = 'password';
  this.form = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
    rememberMe: [false]
  });
  
   if(params.has('asp-au') && params.has('asp-ap'))
   {
    let encryptedUsername = params.get('asp-au');
    let encryptedPassword = params.get('asp-ap');

    this.form.value.username= this.decrypt(encryptedUsername.replace(/ /g,"+"))
    this.form.value.password= this.decrypt(encryptedPassword.replace(/ /g,"+"))
    this.loginInfo = {
      email: this.form.value.username,
      password: this.form.value.password
    };
    this.login() 
    }
   else{
    this.setValuesFromCookie();
    this.loginInfo = {
      email: this.form.value.username,
      password: this.form.value.password
    };
   }
  }

  login() {
    // this.userDetails.user.role.authority
    this.formValidity = true;
    this.resetErrors();

    if (!this.form.value.username || this.form.value.username.trim().length === 0) {
      this.errors.email = 'Please enter Email Address';
      this.formValidity = false;
    } else if (
      this.form.value.username &&
      !/[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*/.test(
        this.form.value.username.trim()
      )
    ) {
      this.errors.email = 'Enter a valid email address like abc@gmail.com.';
      this.formValidity = false;
    }
    this.form.value.username = this.form.value.username.trim();
    if (!this.form.value.password || this.form.value.password.length === 0) {
      this.errors.password = 'Please enter Password';
      this.formValidity = false;
    }

    if (this.formValidity) {
      this.loginInfo = {
        email: this.form.value.username,
        password: this.form.value.password
      };
      this.disableClick = true;
      this.subscriptions.push(
        this.loginService.loginUser(this.loginInfo).subscribe(
          response => {
            this.validateNewPolicyPassword(this.loginInfo.password);
            this.userDetails = response;
            this.loginService.loggedIn$.next(true);
            if (!this.userDetails.user.isPrimary) {
              if (this.userDetails.user.role.id === Roles.ROLE_AUTHORITY_ADMIN) {
                this.userDetails.user.role.description = 'Authority User';
              } else if (this.userDetails.user.role.id === Roles.ROLE_INSPECTOR_ADMIN) {
                this.userDetails.user.role.description = 'Inspector User';
              } else if (this.userDetails.user.role.id === Roles.ROLE_OWNER_ADMIN) {
                this.userDetails.user.role.description = 'Owner User';
              }
            }

            this.loginService.UserDetails$.next(this.userDetails);
            sessionStorage.setItem(
              'token',
              this.loginService.UserDetails$.getValue().token
            );
            localStorage.setItem(
              'userRole',
              this.loginService.UserDetails$.getValue().user.role.name
            );
            localStorage.setItem(
              'userId',
              this.loginService.UserDetails$.getValue().user.id.toString()
            );
            if (this.userDetails.user.isPrimary) {
              this.ownerService.preferencesUpdated.next(this.userDetails.user.isPreferenceSet);
              localStorage.setItem(
                'preferencesSet',
                JSON.stringify(this.userDetails.user.isPreferenceSet)
              );
            }
            localStorage.setItem(
              'userDetails',
              JSON.stringify(this.loginService.UserDetails$.getValue().user)
            );
            this.userService.userDetails = this.userDetails.user;
            // this.loginService.UserDetails$.getValue().token

            if (this.form.value.rememberMe === true) {
              this.setCookies();
            }

            this.user = this.loginService.UserDetails$.getValue().user;
            // this.snacbar.open('Login successful', 'Ok', { duration: 3 * 1000,
            // });

            if (this.userDetails.user.isForcePasswordChanged || this.validNewPolicyPassword === false) {

              if (this.validNewPolicyPassword === false) {
                sessionStorage.setItem('ss-up', this.encrypt(this.form.value.password));
              }

              const queryParams: any = {
                queryParams: {
                  force: 1
                }
              };

              this.router.navigate(['login/force-change-password'], queryParams);
            } else if (!this.user.isPreferenceSet) {
              if (this.user.role.id === Roles.ROLE_OWNER_ADMIN) {
                this.router.navigate(['user/owner/preferences']);
              } else if (this.user.role.id === Roles.ROLE_AUTHORITY_ADMIN || this.user.role.id === Roles.ROLE_AUTHORITY_WORX) {
                this.router.navigate(['user/authority/authorityPreference']);
              } else if (this.user.role.id === Roles.ROLE_INSPECTOR_ADMIN) {
                this.router.navigate(['user/inspector/preferencesInspector']);
              } else if (this.user.role.id === Roles.ROLE_RFA) {
                this.router.navigate(['user/authority/authorityPreference']);
              }
            } else {
              if (this.user.role.id === Roles.ROLE_OWNER_ADMIN) {
                this.router.navigate(['user/owner/dashboard']);
              } else if (this.user.role.id === Roles.ROLE_AUTHORITY_ADMIN || this.user.role.id === Roles.ROLE_AUTHORITY_WORX) {
                this.router.navigate(['user/authority/dashboardAuthority']);
              } else if (this.user.role.id === Roles.ROLE_INSPECTOR_ADMIN) {
                this.router.navigate(['user/inspector/dashboardInspector']);
              } else if (this.user.role.id === Roles.ROLE_RFA) {
                this.router.navigate(['user/authority/dashboardAuthority']);
              }
            }
            if(this.user.role.id === Roles.ROLE_SITE_ADMIN) {
              this.router.navigate(['user/admin/dashboardSiteAdmin']);
            }
            this.disableClick = false;
          },
          error => {
            this.disableClick = false;
            console.log(error);
            this.errors.apiErrors = 'Unexpected error occured';
            // this.snacbar.open('Login failed', 'Ok', {
            //   duration: 3 * 1000,
            // });
            // if (error.error && error.error.message && error.error.message.includes('password')) {
            //   this.errors.apiErrors = 'Invalid Email/Password.';
            // } else if (error.error && error.error.message && error.error.message.toLowerCase().includes('user is suspended.')) {
            //   this.errors.apiErrors = ' User is suspended.';
            // } else if (error.error && error.error.message && error.error.message.toLowerCase().includes('user')) {
            //   this.errors.apiErrors = 'Email not present in system.';
            // }

            if (error.error && error.error.message) {
              this.errors.apiErrors = error.error.message;
            }

          }
        )
      );
      // this.showPassword = 'password';
      // this.router.navigate(['user']);
      // const formData = this.form.value;

      // const formData = this.form.value; this.userDetails = { name: 'Ms. Kathy' ,user{role: "ROLE_OWNER_ADMIN"} };
    }
  }

  validateNewPolicyPassword(password) {
    if (!password || password.length === 0) {
      this.validNewPolicyPassword = false;
      return;
    } else {
      if (password.length < 8 || password.length > 15) {
        this.validNewPolicyPassword = false;
        return;
      }
      if (password.includes('.') || password.includes(',') || password.includes(';') || password.includes(':')) {
        this.validNewPolicyPassword = false;
        return;
      }
      if (password === password.toLowerCase()) {
        this.validNewPolicyPassword = false;
        return;
      }
      if (password === password.toUpperCase()) {
        this.validNewPolicyPassword = false;
        return;
      }
      if (!(/\d/.test(password))) {
        this.validNewPolicyPassword = false;
        return;
      } else {
        for (let i = 0; i < password.length - 2; ++i) {
          if (/\d/.test(password.charAt(i))) {
            if (password.charAt(i) === password.charAt(i + 1) && password.charAt(i) === password.charAt(i + 2)) {
              this.validNewPolicyPassword = false;
              return;
            }
          }
        }
      }

    }

  }

  setValuesFromCookie() {
    const cookieArray = document.cookie.split(';');
    const usernameCookie = cookieArray.find(emailCookie =>
      emailCookie.includes('asp-au')
    );

    if (
      usernameCookie &&
      usernameCookie !== undefined &&
      usernameCookie.length > 0
    ) {
      const splitUsernameCookie = usernameCookie.split('=');
      const usernameValue = splitUsernameCookie[1];
      const decryptedUsernameValue = this.decrypt(usernameValue);
      this.form.controls.username.setValue(decryptedUsernameValue);
    }
    const passwordCookie = cookieArray.find(emailCookie =>
      emailCookie.includes('asp-ap')
    );

    if (
      passwordCookie &&
      passwordCookie !== undefined &&
      passwordCookie.length > 0
    ) {
      const splitPasswordCookie = passwordCookie.split('=');
      const passwordValue = splitPasswordCookie[1];
      const decryptedPasswordValue = this.decrypt(passwordValue);
      this.form.controls.password.setValue(decryptedPasswordValue);
    }
  }

  signUp() {
    this.router.navigate(['signUp']);
  }

  setCookies() {
    const date = new Date();
    date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
    const expires = '; expires=' + date.toUTCString();
    const encryptedUserName = this.encrypt(this.form.value.username);
    const encryptedPassword = this.encrypt(this.form.value.password);
    document.cookie = "asp-au=" + encryptedUserName + expires;
    document.cookie = "asp-ap=" + encryptedPassword + expires;
  }

  encrypt(val): string {
    return AES.encrypt(val, this.secretKey).toString();
  }

  decrypt(encryptedVal): string {
    return AES.decrypt(encryptedVal, this.secretKey).toString(UTF8);
  }

  forgotPassword() {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      width: '887px',
      height: '580px'
      // data: { description: 'Add Property System', addEditObject: null },
      // disableClose: true,
      // autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result && result.length > 0 && result !== 'cancel') {
        const dialogRef2 = this.dialog.open(EmailSentDialogComponent, {
          width: '887px',
          height: '400px',
          data: { email: result }
          // disableClose: true,
          // autoFocus: false,
        });

        dialogRef.afterClosed().subscribe(
          result => {}
          );
      }

      //   if (result) {
      //     const SysDetail: SystemDetails = result;
      //     this.systemDetails.push(SysDetail);
      //     this.dataSource = new MatTableDataSource(this.systemDetails);
      //     this.dataSource._updateChangeSubscription();
      //     if (this.selects) {
      //       this.selects = false;
      //     }
      //   }
    });
  }

  toggleShowPassword() {
    this.showPassword = this.showPassword === 'password' ? 'text' : 'password';
  }

  resetErrors() {
    this.errors = {
      email: null,
      password: null,
      apiErrors: null
    };
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => {
      x.unsubscribe();
    });
  }

}
