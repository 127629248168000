import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpEvent,
  HttpHandler,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { LoginService } from '../../../login/services/login.service';
import { Router } from '@angular/router';
import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class AppHttpInterceptorService implements HttpInterceptor {
  requests = [];
  private readonly propertNotificationHeader = 'PropertyNotificationHeader';
  constructor(private loginService: LoginService, private router: Router, private loaderService: LoaderService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let fullReqUrl = '';

    /** This checks for the request url.
     * If url already contains the base part then it skips to attach the base part to the request URL
     * or if not then it add the base URL part to the requested URL
     */
    const headers = req.headers;
    
    if(!headers.has(this.propertNotificationHeader)){
      this.loaderService.show();
    }else{
      req = req.clone({headers: req.headers.delete(this.propertNotificationHeader)
      });
    }

    if (
      !(
        this.router.url.endsWith('login-form') ||
        this.router.url.endsWith('signUp') ||
        req.url.indexOf('secure.shippingapis.com') >= 0
      )
    ) {
      const authToken = sessionStorage.getItem('token');
      // set the auth token;

      req = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${authToken}`)
      });
    }
    if (req.url.indexOf('http://') > -1 || req.url.indexOf('https://') > -1) {
      if(!headers.has(this.propertNotificationHeader)){
        this.loaderService.show();
      }
      this.addRequest(req);
      return next.handle(req).pipe(
        map(res => {
          if (res instanceof HttpResponse) {
            if(res && res.headers && res.headers.get('Authorization') !== null) {
              sessionStorage.setItem(
                'token',
                res.headers.get('Authorization').split(' ')[1]
              );
            }
          }
          return res
        }),
        catchError(err => this.handlerError(err)),
        finalize(() => {
          this.removeRequest(req);
          if (this.requests.length === 0) {
            this.loaderService.hide();
          }
        })
      );
    } else {
      fullReqUrl = `${environment.baseUrl}${req.url}`;
    }

    // cloning the request for updating the url
    // const dupReq = req.clone({ url: fullReqUrl });

    /**
     * headers can be updated here
     * auth token can be added here to the request
     */
    // const authHeader = this.authService.getAuthToken();
    // const authToken = this.authService.getAuthorizationHeaderValue();

    // set the auth token
    // headers = headers.set('Authorization', authToken);
    // headers = headers.set('AuthorizationKey', authHeader)

    // const authReq = req.clone({ headers, url: fullReqUrl });
    // const authReq = req.clone({ headers, url: req.url });
    // return next.handle(authReq).pipe(
    //   map(res => res),
    //   catchError(err => this.handlerError(err))
    // );
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
  }


  addRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i < 0) {
      this.requests.push(req);
    }
  }
  /**
   * @description Handle Http error
   * @param err Error
   */
  private handlerError(err: HttpErrorResponse) {
    this.loaderService.hide();
    // check for the status code
    switch (err.status) {
      case 401:
        /** TODO
         * when status code is 401 redirect back to unauthorized page (i.e. Login)
         * Show the error message using popup or snackbars
         */
        // this.authService.gotoLogin();
        break;
      case 403:
        /** TODO
         * when status code is 403 redirect back to unauthorized page (i.e. Login)
         * Show the error message using popup or snackbars
         */
        this.router.navigate(["login/login-form"]);
        break;
    }

    return throwError(err);
  }
}
