import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { OwnerContactListResponse } from '../../../shared/models/owner.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AuthorityService = /** @class */ (function () {
    function AuthorityService(http) {
        this.http = http;
        this.baseUrl = environment.baseUrl;
        this.authorityProperties = new BehaviorSubject(null);
        this.authorityPrefenrences = new BehaviorSubject(null);
        this.rfaBasicDetails = new BehaviorSubject(null);
        this.rfaContactsDetails = new BehaviorSubject(null);
        this.rfaSettingsDetails = new BehaviorSubject(null);
        this.rfaJurisdictions = new BehaviorSubject(null);
        this.enableEditMode = new BehaviorSubject(false);
        this.userDetails = null;
        this.filteredInspectorsList = new BehaviorSubject(null);
        this.authorityPreviewFees = new BehaviorSubject(null);
        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    }
    AuthorityService.prototype.getPropertyList = function () {
        var url = this.baseUrl + "/authority/properties";
        return this.http.get(url);
    };
    AuthorityService.prototype.getAuthorityPreferences = function () {
        var url = this.baseUrl + "/authority/preferences";
        return this.http.get(url);
    };
    AuthorityService.prototype.getAuthorityPreferencesBasicRFA = function () {
        var url = this.baseUrl + "/rfa/prefernces/basicDetails";
        return this.http.get(url);
    };
    AuthorityService.prototype.getAuthorityPreferencesContactsRFA = function () {
        var url = this.baseUrl + "/rfa/preferences/contact";
        return this.http.get(url);
    };
    AuthorityService.prototype.getAuthorityPreferencesSettingsRFA = function () {
        var url = this.baseUrl + "/rfa/preferences/settings";
        return this.http.get(url);
    };
    AuthorityService.prototype.updateAuthorityPreferences = function (body) {
        var url = this.baseUrl + "/authority/preferences";
        return this.http.put(url, body);
    };
    AuthorityService.prototype.updateSelectedAssets = function (body, id) {
        var url = this.baseUrl + ("/authority/selectedAsset/" + id);
        return this.http.put(url, body);
    };
    AuthorityService.prototype.updateAuthorityPreferencesBasicRFA = function (body) {
        var url = this.baseUrl + "/rfa/prefernces/basicDetails";
        return this.http.put(url, body);
    };
    AuthorityService.prototype.updateAuthorityPreferencesContactsRFA = function (body) {
        var url = this.baseUrl + "/rfa/preferences/contact";
        return this.http.put(url, body);
    };
    AuthorityService.prototype.updateAuthorityPreferencesSettingsRFA = function (body) {
        var url = this.baseUrl + "/rfa/preferences/settings";
        return this.http.put(url, body);
    };
    AuthorityService.prototype.updateSelectedjurisdictioncontactwithsystems = function (body) {
        var url = this.baseUrl + "/authority/preferences/selectedjurisdictioncontactwithsystems";
        return this.http.put(url, body);
    };
    AuthorityService.prototype.updateAssetsForSecondaryContact = function (body) {
        var url = this.baseUrl + "/authority/selectedAsset/secondaryContact";
        return this.http.put(url, body);
    };
    AuthorityService.prototype.getSelectedjurisdictioncontactwithsystems = function () {
        var url = this.baseUrl + "/authority/preferences/selectedjurisdictioncontactwithsystems";
        return this.http.get(url);
    };
    AuthorityService.prototype.getListOfJurisdictions = function () {
        var url = this.baseUrl + '/authority/preferences/listofjurisdictions';
        return this.http.get(url);
    };
    AuthorityService.prototype.getListOfContacts = function () {
        var url = this.baseUrl + '/authority/preferences/listOfAuthorityContacts';
        return this.http.get(url);
    };
    AuthorityService.prototype.getContactsForJurisdiction = function () {
        var url = this.baseUrl + '/authority/preferences/secondarycontacts';
        return this.http.get(url);
        // return this.http.get<any>(url, { params: { jurisdictionIds} });
    };
    AuthorityService.prototype.getInspectorData = function () {
        var url = this.baseUrl + '/authority/preferences/certifiedinspector';
        return this.http.get(url);
    };
    AuthorityService.prototype.getAccountingFees = function () {
        var url = this.baseUrl + '/authority/preferences/accountingfeesdetails';
        return this.http.get(url);
    };
    AuthorityService.prototype.updateAccountingFees = function (body) {
        var url = this.baseUrl + '/authority/preferences/accountingfeesdetails';
        return this.http.put(url, body);
    };
    AuthorityService.prototype.getLetterHead = function () {
        var url = this.baseUrl + '/authority/preferences/notificationtemplatenew';
        return this.http.get(url, {
            params: {
                templateType: 'LetterHead',
                department: JSON.parse(localStorage.getItem('userDetails')).role.name === 'ROLE_AUTHORITY_WORX' ?
                    'TEGRIS Worx' : 'TEGRIS Fire'
            }
        });
    };
    AuthorityService.prototype.updateLetterHead = function (body) {
        body['department'] = JSON.parse(localStorage.getItem('userDetails')).role.name === 'ROLE_AUTHORITY_WORX' ?
            'TEGRIS Worx' : 'TEGRIS Fire';
        var url = this.baseUrl + '/authority/jurisdiction/preferences/notificationtemplate';
        return this.http.put(url, body);
    };
    AuthorityService.prototype.updateCustomReport = function (assetTypeId, body) {
        var url = this.baseUrl + ("/question/" + assetTypeId);
        return this.http.post(url, body);
    };
    AuthorityService.prototype.createCustomReport = function (body) {
        var url = this.baseUrl + "/question";
        return this.http.post(url, body);
    };
    AuthorityService.prototype.deleteCustomReport = function (assetId) {
        var url = this.baseUrl + ("/delete/asset/" + assetId);
        return this.http.delete(url);
    };
    AuthorityService.prototype.getCustomReportByAssetId = function (id, jid) {
        if (jid === void 0) { jid = this.userDetails.jurisdiction.id; }
        var url = this.baseUrl + ("/question/" + id + "/" + jid);
        return this.http.get(url);
    };
    AuthorityService.prototype.getCustomAssetIds = function () {
        var url = this.baseUrl + "/customasset";
        return this.http.get(url);
    };
    AuthorityService.prototype.getFrequencies = function () {
        var url = this.baseUrl + "/code/inspectionfrequency";
        return this.http.get(url);
    };
    AuthorityService.prototype.setPreference = function () {
        var url = this.baseUrl + "/setPreference";
        return this.http.put(url, {});
    };
    AuthorityService.prototype.getOwners = function () {
        var url = this.baseUrl + "/owner/contactList";
        return this.http.get(url);
    };
    AuthorityService.prototype.getOwnerList = function (limit, offset, searchString, isMobileUser) {
        var url = this.baseUrl + "/contactList/owner";
        return this.http.get(url, {
            params: {
                offset: offset,
                limit: limit,
                searchString: searchString,
                isMobileUser: isMobileUser
            }
        });
    };
    AuthorityService.prototype.getInspectors = function (limit, offset, searchString, isMobileUser) {
        var url = this.baseUrl + "/inspector/contactList";
        return this.http.get(url, {
            params: {
                offset: offset,
                limit: limit,
                searchString: searchString,
                isMobileUser: isMobileUser
            }
        });
    };
    AuthorityService.prototype.sendBulkNotification = function (data) {
        var url = this.baseUrl + "/bulkNotification";
        return this.http.post(url, data);
    };
    AuthorityService.prototype.getInspectionCompanyList = function () {
        var url = this.baseUrl + "/authority/preferences/inspectioncompanies";
        return this.http.get(url);
    };
    AuthorityService.prototype.getAdminInspectionCompanies = function () {
        var url = this.baseUrl + "/admin/inpectioncompanies";
        return this.http.get(url);
    };
    AuthorityService.prototype.getCertifiedInspectorsForACompany = function (inspectionCompanyId) {
        var url = this.baseUrl + ("/authority/preferences/certifiedinspectors/" + inspectionCompanyId);
        return this.http.get(url);
    };
    AuthorityService.prototype.getRFAJurisdication = function () {
        var url = this.baseUrl + "/rfa/jurisdictions";
        return this.http.get(url);
    };
    AuthorityService.prototype.getPropertyRFAList = function (jurisIds) {
        var url = this.baseUrl + ("/rfa/dashboard/properties/" + jurisIds);
        return this.http.get(url);
    };
    AuthorityService.prototype.getBulkMailForInspector = function () {
        var url = this.baseUrl + "/inspector/contactList/bulkEmail";
        return this.http.get(url);
    };
    AuthorityService.prototype.getBulkMailForOwner = function () {
        var url = this.baseUrl + "/contactList/owner/bulkEmail";
        return this.http.get(url);
    };
    AuthorityService.prototype.getBulkMailForManager = function () {
        var url = this.baseUrl + "/contactList/manager/bulkEmail";
        return this.http.get(url);
    };
    AuthorityService.prototype.getContactListExportData = function (role, searchString) {
        var url = this.baseUrl + "/user/contact/export";
        return this.http.get(url, {
            params: {
                role: role,
                searchString: searchString
            }
        });
    };
    AuthorityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthorityService_Factory() { return new AuthorityService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthorityService, providedIn: "root" });
    return AuthorityService;
}());
export { AuthorityService };
