import { OnInit } from "@angular/core";
var CertifyBypassComponent = /** @class */ (function () {
    function CertifyBypassComponent(dialogRef) {
        this.dialogRef = dialogRef;
    }
    CertifyBypassComponent.prototype.ngOnInit = function () {
        this.checked = false;
    };
    CertifyBypassComponent.prototype.cancel = function () {
        this.dialogRef.close(false);
    };
    CertifyBypassComponent.prototype.certify = function () {
        if (this.checked) {
            this.dialogRef.close(true);
        }
        else {
            alert('Please certify PAC BYPASS');
        }
    };
    return CertifyBypassComponent;
}());
export { CertifyBypassComponent };
