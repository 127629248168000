import { Directive, ElementRef, Renderer2, Output, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appDateChecker]',
  host: {'(blur)': 'onBlur($event)'}
})
export class DateCheckerDirective {

  @Output() a: EventEmitter<any> = new EventEmitter(null);
  constructor(
    private model: NgModel,
    private el: ElementRef,
    private renderer: Renderer2
    ) {   }


    onBlur(e) {
      const currDate = new Date();
      if (!e.target.value) {
        this.renderer.setAttribute(this.el.nativeElement, 'dateNotEntered', 'true');
        this.a.emit({error: 'Enter Expiration Date'});
        return;
      }
      if (!/^([0-9]{2}-[0-9]{2}-[0-9]{4})$/.test(e.target.value)) {
        this.renderer.setAttribute(this.el.nativeElement, 'notValidDate', 'true');
        this.a.emit({error: 'Date format should be mm-dd-yyyy'});
        return;
      }
      const dateArray = e.target.value.split('-');
      const enteredDate = new Date(dateArray[2], +dateArray[0] - 1, dateArray[1]);
      if (+dateArray[0] > 12 || +dateArray[0] < 1) {
        this.renderer.setAttribute(this.el.nativeElement, 'notValidDate', 'true');
        this.a.emit({error: 'Date format should be mm-dd-yyyy'});
        return;
      }
      if (!+enteredDate ) {
        this.renderer.setAttribute(this.el.nativeElement, 'notValidDate', 'true');
        this.a.emit({error: 'Date format should be mm-dd-yyyy'});
        return;
      }

      if (+enteredDate <= +currDate) {
        this.renderer.setAttribute(this.el.nativeElement, 'notFutureDate', 'true');
        this.a.emit({error: 'Expiration date should be in future'});
        return;
      }

      this.a.emit({error: null});

      this.renderer.setAttribute(this.el.nativeElement, 'validDate', 'false');

    }

}
