import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PropertyService } from '../../../core/services/property/property.service';

export interface Property {
  PropertyId: string;
  pac: string;
}

@Component({
  selector: 'app-verify-pac',
  templateUrl: './verify-pac.component.html',
  styleUrls: ['./verify-pac.component.scss']
})
export class VerifyPacComponent implements OnInit, OnDestroy {

  pac: string;
  error: string;
  subscriptions = [];
  constructor(
    public dialogRef: MatDialogRef<VerifyPacComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Property,
    private propertyService: PropertyService) { }

  ngOnInit() {
    this.pac = this.data.pac;
    this.error = '';
  }

  save() {
    if (!this.pac || this.pac === undefined || this.pac.length === 0) {
      this.error = 'Please enter property access code.';
    } else {
      this.subscriptions.push(
    this.propertyService.validatePAC(this.data.PropertyId, this.pac.toUpperCase()).subscribe(
      res => {
        if (res === true) {
          this.dialogRef.close(this.pac);
        } else {
         this.error = 'Access Code does not belong to the selected property. Re-enter access code or select any other property.';
        }

      }
    ));
    }
  }

  cancel() {
    this.dialogRef.close('cancelled');
  }

  byPass() {
    this.dialogRef.close('bypass');
  }
  // 2066 TODO: Remove if not required at all
  // navigate() {
  //   this.dialogRef.close('navigate');
  // }

  ngOnDestroy() {
    this.subscriptions.forEach(x => {
      x.unsubscribe();
    });
  }

}
