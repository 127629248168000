import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var InspectionReportService = /** @class */ (function () {
    function InspectionReportService(http) {
        this.http = http;
        this.baseUrl = environment.baseUrl;
    }
    InspectionReportService.prototype.getPropertyDetails = function (id) {
        return this.http.get(this.baseUrl + "/propertyDetails/" + id);
    };
    InspectionReportService.prototype.getThymeLeafReport = function (id, isWaterMarkAllowed) {
        if (isWaterMarkAllowed === "false") {
            return this.http.get(this.baseUrl + "/report_thymeleaf/report/inspection/" + id, {
                params: {
                    isWaterMarkAllowed: isWaterMarkAllowed
                }
            });
        }
        else {
            return this.http.get(this.baseUrl + "/report_thymeleaf/report/inspection/" + id);
        }
    };
    InspectionReportService.prototype.getPropertyManagerDetails = function (id) {
        return this.http.get(this.baseUrl + "/property/manager/" + id);
    };
    InspectionReportService.prototype.getPreviousInspectionReports = function (id) {
        return this.http.get(this.baseUrl + "/inspection/property/" + id + "/previousreports");
    };
    InspectionReportService.prototype.getInspectorDetails = function (id) {
        return this.http.get(this.baseUrl + "/inspector/details/" + id);
    };
    InspectionReportService.prototype.getInspectionResult = function (id) {
        return this.http.get(this.baseUrl + "/inspection/" + id);
    };
    InspectionReportService.prototype.getInspectorLetterHead = function (id, letterHeadType) {
        return this.http.get(this.baseUrl + "/inspector/notification/" + id + "/" + letterHeadType);
    };
    InspectionReportService.prototype.getAuthorityLetterHead = function (id, letterHeadType) {
        // return this.http.get(`${this.baseUrl}/authority/notificationtemplate/${id}/${letterHeadType}`);
        return this.http.get(this.baseUrl + "/authority/preferences/notificationtemplatenew", {
            params: {
                templateType: letterHeadType,
                propertyId: id,
                department: JSON.parse(localStorage.getItem('userDetails')).role.name === 'ROLE_AUTHORITY_WORX' ?
                    'TEGRIS Worx' : 'TEGRIS Fire'
            }
        });
    };
    InspectionReportService.prototype.mailPdf = function (inspectionId, body) {
        var url = this.baseUrl + "/report_thymeleaf/report/mail/inspection/" + inspectionId;
        return this.http.post(url, body);
    };
    InspectionReportService.prototype.createJournalEntry = function (propertyId, propertyJournal) {
        var url = this.baseUrl + "/propertyjournals/report/" + propertyId;
        return this.http.post(url, propertyJournal);
    };
    InspectionReportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InspectionReportService_Factory() { return new InspectionReportService(i0.ɵɵinject(i1.HttpClient)); }, token: InspectionReportService, providedIn: "root" });
    return InspectionReportService;
}());
export { InspectionReportService };
