import { OnInit } from '@angular/core';
import { MatDialogRef, MatTableDataSource } from '@angular/material';
var PaymentDetailsDialogComponent = /** @class */ (function () {
    function PaymentDetailsDialogComponent(dialogRef, data, inspectionService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.inspectionService = inspectionService;
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = ['blank', 'systemType', 'location'];
        this.propertyAssetTotal = {};
        this.total = 0;
    }
    PaymentDetailsDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.unsub = this.inspectionService.getPaymentDetailsById(this.data.join(','))
            .subscribe(function (res) {
            _this.dataSource.data = res;
            _this.calculateTotal(res);
        });
    };
    PaymentDetailsDialogComponent.prototype.ngOnDestroy = function () {
        this.unsub.unsubscribe();
    };
    PaymentDetailsDialogComponent.prototype.calculateTotal = function (list) {
        var _this = this;
        list.forEach(function (res) {
            var total = 0;
            res.priceList.sort(function (a, b) { return a.order - b.order; });
            var groupedPriceList = {};
            res.priceList = res.priceList.filter(function (dat) { return dat.value || dat.description === 'Grand Total'; });
            (res.priceList || []).forEach(function (data) {
                if (!groupedPriceList[data.heading]) {
                    groupedPriceList[data.heading] = [];
                }
                groupedPriceList[data.heading].push(data);
                total += (data.value || 0);
            });
            res.groupedPriceList = Object.keys(groupedPriceList).map(function (data) { return groupedPriceList[data]; });
            total += res.techSupportFee || 0;
            _this.propertyAssetTotal[res.id] = total;
            _this.total += total;
        });
    };
    PaymentDetailsDialogComponent.prototype.closeDialog = function () {
        this.dialogRef.close();
    };
    return PaymentDetailsDialogComponent;
}());
export { PaymentDetailsDialogComponent };
