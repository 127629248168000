<div id="BackflowReport">
    <table class="sample" width="100%">
        <tbody>
            <!-- Row 1 -->
            <tr class="GridRowGray">
                <td align="center" width="20%">Test Phases</td>
                <td align="center" width="20%">DCVA/RPBA Check Valve No. 1</td>
                <td align="center" width="20%">DCVA/RPBA Check Valve No. 2</td>
                <td align="center" width="20%">RPBA</td>
                <td align="center" width="20%">PVBA/SVBA Air Inlet</td>                
            </tr>
            <!-- Row 2 -->
            <tr>
                <td>
                    Initial Test
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <label>Passed
                                        <input [disabled]="reviewInspection" type="radio" 
                                        value="passed"
                                        name="reportDataDto.initialTest.checkVal1.passed"
                                        for="reportDataDto_initialTest_checkVal1_passed_true"
                                        id="reportDataDto_initialTest_checkVal1_passed_true"
                                        [(ngModel)]="qtd[findQuestionId('Test Phases', 'Initial Test', 'Passed', 'radio')]">
                                    </label>
                                </td>
                            <tr>
                                <td  class="checkbox-inline">
                                    <label>Failed
                                        <input [disabled]="reviewInspection" type="radio" value="failed"
                                        name="reportDataDto.initialTest.checkVal1.passed"
                                        for="reportDataDto_initialTest_checkVal1_passed_false"
                                        id="reportDataDto_initialTest_checkVal1_passed_false"
                                        [(ngModel)]="qtd[findQuestionId('Test Phases', 'Initial Test', 'Passed', 'radio')]">
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td>
                    <table>
                        <tbody>
                            <tr>              
                                    <label>Tight?
                                        <input [disabled]="reviewInspection" type="checkbox" class="margin-left-right"
                                        name="reportDataDto.initialTest.checkVal1.tight"
                                        [(ngModel)]="qtd[findQuestionId('Check Valve No. 1', 'Initial Test', 'Tight?', 'checkbox')]">
                                    </label>
                            </tr>
                            <tr>                        
                                    <label>Leaked?
                                        <input [disabled]="reviewInspection" type="checkbox"  class="margin-left-right"
                                        name="reportDataDto.initialTest.checkVal1.leaked"
                                        [(ngModel)]="qtd[findQuestionId('Check Valve No. 1', 'Initial Test', 'Leaked', 'checkbox')]">
                                    </label>
                            </tr>
                            <tr style="display: flex;">
                                <td>
                                    <label>
                                        <input [disabled]="reviewInspection" class="input-50-percent" 
                                        name="reportDataDto.initialTest.checkVal1.PSID"
                                        [(ngModel)]="qtd[findQuestionId('Check Valve No. 1', 'Initial Test', 'PSID', null)]">PSID
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td>
                    <table>
                        <tbody>
                            <td>
                                <tr>                                
                                    <label>Tight?
                                        <input [disabled]="reviewInspection" type="checkbox" 
                                        name="reportDataDto.initialTest.checkVal2.tight"
                                        [(ngModel)]="qtd[findQuestionId('Check Valve No. 2', 'Initial Test', 'Tight?', 'checkbox')]">
                                    </label>
                                </tr>
                                <tr>
                                    <label>Leaked?
                                        <input [disabled]="reviewInspection" type="checkbox" 
                                        name="reportDataDto.initialTest.checkVal2.leaked"
                                        [(ngModel)]="qtd[findQuestionId('Check Valve No. 2', 'Initial Test', 'Leaked', 'checkbox')]">
                                    </label>
                                </tr>
                                <tr>
                                    <label>
                                        <input [disabled]="reviewInspection" class="input-50-percent" 
                                        name="reportDataDto.initialTest.checkVal2.PSID"
                                        [(ngModel)]="qtd[findQuestionId('Check Valve No. 2', 'Initial Test', 'PSID', null)]">PSID
                                    </label>
                                </tr>
                            </td>
                        </tbody>
                    </table>
                </td>
                <td>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <label>
                                        Opened At<input [disabled]="reviewInspection" class="input-50-percent"
                                        name="reportDataDto.initialTest.RPBA.openedAt"
                                        [(ngModel)]="qtd[findQuestionId('RPBA', 'Initial Test', 'Opened At', null)]">PSID
                                    </label>
                                </td>                                
                            </tr>
                            <tr>
                                <td>
                                    <label>
                                        #1 Check<input [disabled]="reviewInspection" class="input-50-percent"
                                        name="reportDataDto.initialTest.RPBA.1check"
                                        [(ngModel)]="qtd[findQuestionId('RPBA', 'Initial Test', '1 Check', null)]">PSID
                                    </label>
                                </td>                                
                            </tr>
                            <tr>
                                <td>
                                    <label>
                                        Air Gap Ok?<input [disabled]="reviewInspection" class="input-50-percent"
                                        name="reportDataDto.initialTest.RPBA.airGapOk"
                                        [(ngModel)]="qtd[findQuestionId('RPBA', 'Initial Test', 'Air Gap Ok?', null)]">
                                    </label>
                                </td>                                
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <label>
                                        Opened At<input [disabled]="reviewInspection" class="input-50-percent"
                                        name="reportDataDto.initialTest.PVBA.openedAt"
                                        [(ngModel)]="qtd[findQuestionId('PVBA/SVBA Air Inlet', 'Initial Test', 'Opened At', null)]">PSID
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Did Not Open
                                        <input [disabled]="reviewInspection" type="checkbox"
                                        name="reportDataDto.initialTest.PVBA.didNotOpen"
                                        [(ngModel)]="qtd[findQuestionId('PVBA/SVBA Air Inlet', 'Initial Test', 'Did Not Open', 'checkbox')]">
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <!-- Row 3 -->
            <tr>
                <td>
                    New Parts And Repairs
                </td>
                <td>
                    <table>
                        <tbody>
                            <tr>
                                <td align="center">Clean</td>
                                <td align="center">Replace</td>
                                <td align="center">Part</td>
                            </tr>
                            <tr>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal1.clean1"
                                    for="reportDataDto_newPartsAndRepairs_checkVal1_clean1_true"
                                    id="reportDataDto_newPartsAndRepairs_checkVal1_clean1_true"
                                    value="clean"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 1', 'New Parts And Repairs', 'Clean 1', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal1.clean1"
                                    for="reportDataDto_newPartsAndRepairs_checkVal1_clean1_false"
                                    id="reportDataDto_newPartsAndRepairs_checkVal1_clean1_false"
                                    value="replace"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 1', 'New Parts And Repairs', 'Clean 1', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" class="part-input" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal1.part1"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 1', 'New Parts And Repairs', 'Part 1', null)]">
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal1.clean2"
                                    for="reportDataDto_newPartsAndRepairs_checkVal1_clean2_true"
                                    id="reportDataDto_newPartsAndRepairs_checkVal1_clean2_true"
                                    value="clean"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 1', 'New Parts And Repairs', 'Clean 2', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal1.clean2"
                                    for="reportDataDto_newPartsAndRepairs_checkVal1_clean2_false"
                                    id="reportDataDto_newPartsAndRepairs_checkVal1_clean2_false"
                                    value="replace"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 1', 'New Parts And Repairs', 'Clean 2', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" class="part-input"  
                                    name="reportDataDto.newPartsAndRepairs.checkVal1.part2"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 1', 'New Parts And Repairs', 'Part 2', null)]">
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal1.clean3"
                                    for="reportDataDto_newPartsAndRepairs_checkVal1_clean3_true"
                                    id="reportDataDto_newPartsAndRepairs_checkVal1_clean3_true"
                                    value="clean"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 1', 'New Parts And Repairs', 'Clean 3', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal1.clean3"
                                    for="reportDataDto_newPartsAndRepairs_checkVal1_clean3_false"
                                    id="reportDataDto_newPartsAndRepairs_checkVal1_clean3_false"
                                    value="replace"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 1', 'New Parts And Repairs', 'Clean 3', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" class="part-input" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal1.part3"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 1', 'New Parts And Repairs', 'Part 3', null)]">
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal1.clean4"
                                    for="reportDataDto_newPartsAndRepairs_checkVal1_clean4_true"
                                    id="reportDataDto_newPartsAndRepairs_checkVal1_clean4_true"
                                    value="clean"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 1', 'New Parts And Repairs', 'Clean 4', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal1.clean4"
                                    for="reportDataDto_newPartsAndRepairs_checkVal1_clean4_false" value="replace"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 1', 'New Parts And Repairs', 'Clean 4', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" class="part-input" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal1.part4"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 1', 'New Parts And Repairs', 'Part 4', null)]">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td>
                    <table>
                        <tbody>
                            <tr>
                                <td align="center">Clean</td>
                                <td align="center">Replace</td>
                                <td align="center">Part</td>
                            </tr>
                            <tr>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal2.clean1"
                                    for="reportDataDto_newPartsAndRepairs_checkVal2_clean1_true"
                                    id="reportDataDto_newPartsAndRepairs_checkVal2_clean1_true"
                                    value="clean"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 2', 'New Parts And Repairs', 'Clean 1', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal2.clean1"
                                    for="reportDataDto_newPartsAndRepairs_checkVal2_clean1_false"
                                    id="reportDataDto_newPartsAndRepairs_checkVal2_clean1_false"
                                    value="replace"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 2', 'New Parts And Repairs', 'Clean 1', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" class="part-input" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal2.part1"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 2', 'New Parts And Repairs', 'Part 1', null)]">
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal2.clean2"
                                    for="reportDataDto_newPartsAndRepairs_checkVal2_clean2_true"
                                    id="reportDataDto_newPartsAndRepairs_checkVal2_clean2_true"
                                    value="clean"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 2', 'New Parts And Repairs', 'Clean 2', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal2.clean2"
                                    for="reportDataDto_newPartsAndRepairs_checkVal2_clean2_false"
                                    id="reportDataDto_newPartsAndRepairs_checkVal2_clean2_false"
                                    value="replace"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 2', 'New Parts And Repairs', 'Clean 2', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" class="part-input" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal2.part2"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 2', 'New Parts And Repairs', 'Part 2', null)]">
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal2.clean3"
                                    for="reportDataDto_newPartsAndRepairs_checkVal2_clean3_true"
                                    id="reportDataDto_newPartsAndRepairs_checkVal2_clean3_true"
                                    value="clean"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 2', 'New Parts And Repairs', 'Clean 3', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal2.clean3"
                                    for="reportDataDto_newPartsAndRepairs_checkVal2_clean3_false"
                                    id="reportDataDto_newPartsAndRepairs_checkVal2_clean3_false"
                                    value="replace"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 2', 'New Parts And Repairs', 'Clean 3', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" class="part-input" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal2.part3"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 2', 'New Parts And Repairs', 'Part 3', null)]">
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal2.clean4"
                                    for="reportDataDto_newPartsAndRepairs_checkVal2_clean4_true"
                                    id="reportDataDto_newPartsAndRepairs_checkVal2_clean4_true"
                                    value="clean"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 2', 'New Parts And Repairs', 'Clean 4', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal2.clean4"
                                    for="reportDataDto_newPartsAndRepairs_checkVal2_clean4_false"
                                    id="reportDataDto_newPartsAndRepairs_checkVal2_clean4_false"
                                    value="replace"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 2', 'New Parts And Repairs', 'Clean 4', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" class="part-input" 
                                    name="reportDataDto.newPartsAndRepairs.checkVal2.part4"
                                    [(ngModel)]="qtd[findQuestionId('Check Valve No. 2', 'New Parts And Repairs', 'Part 4', null)]">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td>
                    <table>
                        <tbody>
                            <tr>
                                <td align="center">Clean</td>
                                <td align="center">Replace</td>
                                <td align="center">Part</td>
                            </tr>
                            <tr>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.RPBA.clean1"
                                    for="reportDataDto_newPartsAndRepairs_RPBA_clean1_true"
                                    id="reportDataDto_newPartsAndRepairs_RPBA_clean1_true"
                                    value="clean"
                                    [(ngModel)]="qtd[findQuestionId('RPBA', 'New Parts And Repairs', 'Clean 1', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.RPBA.clean1"
                                    for="reportDataDto_newPartsAndRepairs_RPBA_clean1_false"
                                    id="reportDataDto_newPartsAndRepairs_RPBA_clean1_false"
                                    value="replace"
                                    [(ngModel)]="qtd[findQuestionId('RPBA', 'New Parts And Repairs', 'Clean 1', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" class="part-input" 
                                    name="reportDataDto.newPartsAndRepairs.RPBA.part1"
                                    [(ngModel)]="qtd[findQuestionId('RPBA', 'New Parts And Repairs', 'Part 1', null)]">
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.RPBA.clean2"
                                    for="reportDataDto_newPartsAndRepairs_RPBA_clean2_true"
                                    id="reportDataDto_newPartsAndRepairs_RPBA_clean2_true"
                                    value="clean"
                                    [(ngModel)]="qtd[findQuestionId('RPBA', 'New Parts And Repairs', 'Clean 2', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.RPBA.clean2"
                                    for="reportDataDto_newPartsAndRepairs_RPBA_clean2_false"
                                    id="reportDataDto_newPartsAndRepairs_RPBA_clean2_false"
                                    value="replace"
                                    [(ngModel)]="qtd[findQuestionId('RPBA', 'New Parts And Repairs', 'Clean 2', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" class="part-input" 
                                    name="reportDataDto.newPartsAndRepairs.RPBA.part2"
                                    [(ngModel)]="qtd[findQuestionId('RPBA', 'New Parts And Repairs', 'Part 2', null)]">
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.RPBA.clean3"
                                    for="reportDataDto_newPartsAndRepairs_RPBA_clean3_true"
                                    id="reportDataDto_newPartsAndRepairs_RPBA_clean3_true"
                                    value="clean"
                                    [(ngModel)]="qtd[findQuestionId('RPBA', 'New Parts And Repairs', 'Clean 3', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.RPBA.clean3"
                                    for="reportDataDto_newPartsAndRepairs_RPBA_clean3_false"
                                    id="reportDataDto_newPartsAndRepairs_RPBA_clean3_false"
                                    value="replace"
                                    [(ngModel)]="qtd[findQuestionId('RPBA', 'New Parts And Repairs', 'Clean 3', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" class="part-input" 
                                    name="reportDataDto.newPartsAndRepairs.RPBA.part3"
                                    [(ngModel)]="qtd[findQuestionId('RPBA', 'New Parts And Repairs', 'Part 3', null)]">
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.RPBA.clean4"
                                    for="reportDataDto_newPartsAndRepairs_RPBA_clean4_true"
                                    id="reportDataDto_newPartsAndRepairs_RPBA_clean4_true"
                                    value="clean"
                                    [(ngModel)]="qtd[findQuestionId('RPBA', 'New Parts And Repairs', 'Clean 4', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" type="radio" 
                                    name="reportDataDto.newPartsAndRepairs.RPBA.clean4"
                                    for="reportDataDto_newPartsAndRepairs_RPBA_clean4_false"
                                    id="reportDataDto_newPartsAndRepairs_RPBA_clean4_false"
                                    value="replace"
                                    [(ngModel)]="qtd[findQuestionId('RPBA', 'New Parts And Repairs', 'Clean 4', 'radio')]">
                                </td>
                                <td>
                                    <input [disabled]="reviewInspection" class="part-input" 
                                    name="reportDataDto.newPartsAndRepairs.RPBA.part4"
                                    [(ngModel)]="qtd[findQuestionId('RPBA', 'New Parts And Repairs', 'Part 4', null)]">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td>
                    <tr>
                        <td>
                            <label>
                                Check Valve Held At<input [disabled]="reviewInspection" 
                                class="input-50-percent"
                                name="reportDataDto.newPartsAndRepairs.PVBA.checkValveHeldAt"
                                 [(ngModel)]="qtd[findQuestionId('PVBA/SVBA Air Inlet', 'New Parts And Repairs', 'Check valve held at', null)]">PSID
                            </label>
                        </td>
                    </tr>                        
                    <tr>
                        <td>
                            <label>
                                Leaked?
                                <input [disabled]="reviewInspection" type="checkbox"
                                name="reportDataDto.newPartsAndRepairs.PVBA.leaked"
                                 [(ngModel)]="qtd[findQuestionId('PVBA/SVBA Air Inlet', 'New Parts And Repairs', 'Leaked', 'checkbox')]">
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>
                                Cleaned
                                <input [disabled]="reviewInspection" type="checkbox"
                                name="reportDataDto.newPartsAndRepairs.PVBA.cleaned"
                                 [(ngModel)]="qtd[findQuestionId('PVBA/SVBA Air Inlet', 'New Parts And Repairs', 'Cleaned', 'checkbox')]">
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>
                                Repaired
                                <input [disabled]="reviewInspection" type="checkbox"
                                name="reportDataDto.newPartsAndRepairs.PVBA.repaired"
                                 [(ngModel)]="qtd[findQuestionId('PVBA/SVBA Air Inlet', 'New Parts And Repairs', 'Repaired', 'checkbox')]">
                            </label>
                        </td>                        
                    </tr>                     
                </td>
            </tr>
            <!-- Row 4 -->
            <tr>
                <td>
                    Test After Repairs
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <label>Passed
                                        <input [disabled]="reviewInspection" type="radio" 
                                        value="passed"
                                        for="reportDataDto_testAfterRepairs_checkVal1_passed_true"
                                        id="reportDataDto_testAfterRepairs_checkVal1_passed_true"
                                        name="reportDataDto.testAfterRepairs.checkVal1.passed"
                                         [(ngModel)]="qtd[findQuestionId('Test Phases', 'Test After Repairs', 'Passed', 'radio')]">
                                    </label>
                                </td>
                            <tr>
                                <td  class="checkbox-inline">
                                    <label>Failed
                                        <input [disabled]="reviewInspection" type="radio" 
                                        value="failed"
                                        name="reportDataDto.testAfterRepairs.checkVal1.passed"
                                        for="reportDataDto_testAfterRepairs_checkVal1_passed_false"
                                        id="reportDataDto_testAfterRepairs_checkVal1_passed_false"
                                         [(ngModel)]="qtd[findQuestionId('Test Phases', 'Test After Repairs', 'Passed', 'radio')]">
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td>
                    <table>
                        <tbody>
                                <tr>
                                    <label>Tight?
                                        <input [disabled]="reviewInspection" type="checkbox" class="margin-left-right"
                                        name="reportDataDto.testAfterRepairs.checkVal1.tight"
                                         [(ngModel)]="qtd[findQuestionId('Check Valve No. 1', 'Test After Repairs', 'Tight?', 'checkbox')]">
                                    </label>
                                </tr>
                                <tr>
                                    <label>Leaked?
                                        <input [disabled]="reviewInspection" type="checkbox" class="margin-left-right"
                                        name="reportDataDto.testAfterRepairs.checkVal1.leaked"
                                         [(ngModel)]="qtd[findQuestionId('Check Valve No. 1', 'Test After Repairs', 'Leaked', 'checkbox')]">
                                    </label>
                               </tr>
                            <tr style="display: flex;">
                                <td>
                                    <label>
                                        <input [disabled]="reviewInspection" class="input-50-percent"
                                        name="reportDataDto.testAfterRepairs.checkVal1.PSID"
                                         [(ngModel)]="qtd[findQuestionId('Check Valve No. 1', 'Test After Repairs', 'PSID', null)]">PSID
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td>
                    <table>
                        <tbody>
                            <td>
                                <tr>
                                    <label>Tight?
                                        <input [disabled]="reviewInspection" type="checkbox" class="margin-left-right"
                                        name="reportDataDto.testAfterRepairs.checkVal2.tight"
                                         [(ngModel)]="qtd[findQuestionId('Check Valve No. 2', 'Test After Repairs', 'Tight?', 'checkbox')]">
                                    </label>
                                </tr>
                                <tr>
                                    <label>Leaked?
                                        <input [disabled]="reviewInspection" type="checkbox" class="margin-left-right"
                                        name="reportDataDto.testAfterRepairs.checkVal2.leaked"
                                         [(ngModel)]="qtd[findQuestionId('Check Valve No. 2', 'Test After Repairs', 'Leaked', 'checkbox')]">
                                    </label>
                              </tr>
                                <tr>
                                    <label>
                                        <input [disabled]="reviewInspection" class="input-50-percent"
                                        name="reportDataDto.testAfterRepairs.checkVal2.PSID"
                                         [(ngModel)]="qtd[findQuestionId('Check Valve No. 2', 'Test After Repairs', 'PSID', null)]">PSID
                                    </label>
                                </tr>
                            </td>
                        </tbody>
                    </table>
                </td>
                <td>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <label>
                                        Opened At<input [disabled]="reviewInspection" class="input-50-percent"
                                        name="reportDataDto.testAfterRepairs.RPBA.openedAt"
                                         [(ngModel)]="qtd[findQuestionId('RPBA', 'Test After Repairs', 'Opened At', null)]">PSID
                                    </label>
                                </td>                                
                            </tr>
                            <tr>
                                <td>
                                    <label>
                                        #1 Check<input [disabled]="reviewInspection" class="input-50-percent"
                                        name="reportDataDto.testAfterRepairs.RPBA.1check"
                                         [(ngModel)]="qtd[findQuestionId('RPBA', 'Test After Repairs', '1 Check', null)]">PSID
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <label>
                                        Air Inlet<input [disabled]="reviewInspection" class="input-50-percent"
                                        name="reportDataDto.testAfterRepairs.PVBA.airInlet"
                                         [(ngModel)]="qtd[findQuestionId('PVBA/SVBA Air Inlet', 'Test After Repairs', 'Air Inlet', null)]">PSID
                                    </label>
                                </td>                                
                            </tr>
                            <tr>
                                <td>
                                    <label>
                                        Check Valve<input [disabled]="reviewInspection" class="input-50-percent"
                                        name="reportDataDto.testAfterRepairs.PVBA.checkValve"
                                         [(ngModel)]="qtd[findQuestionId('PVBA/SVBA Air Inlet', 'Test After Repairs', 'Check Valve', null)]">PSID
                                    </label>
                                </td>                                
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</div>
