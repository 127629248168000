import { LoginComponent } from './login.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component';
import { ForceChangePasswordComponent } from './force-change-password/force-change-password.component';
import { AuthGuard } from './auth.guard';
var routes = [
    {
        path: 'login',
        component: LoginComponent,
        children: [
            {
                path: 'login-form',
                component: LoginFormComponent
            },
            {
                path: '',
                redirectTo: 'login-form',
                pathMatch: 'full'
            }
        ]
    },
    {
        path: 'signUp',
        component: SignUpFormComponent
    },
    {
        path: 'login/force-change-password',
        component: ForceChangePasswordComponent,
        canActivate: [AuthGuard]
    }
];
var LoginRoutingModule = /** @class */ (function () {
    function LoginRoutingModule() {
    }
    return LoginRoutingModule;
}());
export { LoginRoutingModule };
