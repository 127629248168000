import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl, FormArray } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { DuplicatePropertyComponent } from './duplicate-property/duplicate-property.component';
import { USPSInvalidPopUpComponent } from './usps-popUp-invalid/usps-popUp-invalid.component';
import { USPSValidPopUpComponent } from './usps-popUp-valid/usps-popUp-valid.component';
import {
  PropertyDetails, PropertyType, ContsructionType, PropertySubTypes, ZipcodeMappingDetails,
  States, Cities
} from '../../core/models/property/property.model';
import { PropertyService } from '../../core/services/property/property.service';
import { ContactsService } from '../../core/services/property/contacts.service';
import { JournalDetailService } from '../../core/services/property/journal-detail.service';
import { AdminService } from '../../admin/services/admin.service';
import { Roles } from '../../shared/models/user.model';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { ChangeOwnerComponent } from '../../file-cabinet/change-owner/change-owner.component';
import { FileCabinetService } from '../../core/services/file-cabinet/file-cabinet.service';
import { EditPropertyDialogComponent } from '../../inspection/enter-inspection/edit-property-dialog/edit-property-dialog.component';
import { AuthorityService } from '../../authority/shared/services/authority.service';
import { UserService } from '../../shared/services/user.service';

@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss']
})
export class PropertyDetailsComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() screenMode: string;
  @Input() isEditDetail: string;
  @Input() propertyIdForEdit: any;
  @Output() formData = new EventEmitter<any>();
  @Output() errorOccured = new EventEmitter<boolean>(false);
  @Output() close = new EventEmitter<any>();
  public showAdditionZipCode: boolean;

  public addPropertyForm: FormGroup;
  @Input () public propertyDetails: PropertyDetails;
  public errors;
  public uspsOn: boolean;
  public isCanadianZipCode: boolean;
  public isCanadianZipCodeManager: boolean;
  public occupancyTypeOptions: PropertyType[];
  public constructionTypeOptions: ContsructionType[];
  public occupancySubTypeOptions: PropertySubTypes[];
  public occupancySubTypeDisplay: boolean;
  public occupancyTypeOther: boolean;
  public constructionTypeOther: boolean;
  public zipcodeMappingDetails: ZipcodeMappingDetails;
  creationDisabled: boolean;

  public currentButton: string;

  public nextDisabled: boolean;
  selectSubType: PropertySubTypes;
  loading: boolean;
  readOnlyCity: boolean;
  readOnlyState: boolean;
  readOnlyManagerCity: boolean;
  readOnlyManagerState: boolean;
  subscriptions = [];
  phoneRegex: any;
  canadinaZipRegex: any;
  userRole: string;
  allowManagerPopulate: boolean;
  duplicatePropertyID;
  isAuthorityLoggedIn: boolean;
  user: any;
  states: States[];
  cities: Cities[];
  managerCities: Cities[];
  ownerName: any;
  ownerPhone: any;
  ownerPhone1: any;
  ownerAddress: any;
  originalPropertyForm: FormGroup;
  department: any;
  showPencil: boolean;
  editId: any;
  isPPAButActive: boolean;
  showMessage: boolean = false;
  changeInAddress: boolean;
  addressUspsDone: boolean = false;
  managerAddressUspsDone: boolean = false;
  changeInManagerAddress: boolean;
  managerAddressSameAsProp: boolean = false;
  isDisabledReadOnly: boolean = false;
  isDisabledReadOnly1: boolean = false;

  formSubmissionAllowedIfContactNotPresent: boolean = false;
  // selectedStateID: string;
  // selectedManagerStateID: string;
  // selectedCityID: string;
  // selectedManagerCityID: string;
  // public constTypeOptions: ContsructionType[];
  // otherContacts = [];

  constructor(
    public fb: FormBuilder,
    public propertyService: PropertyService,
    public dialog: MatDialog,
    private router: Router,
    private contactsService: ContactsService,
    private journalService: JournalDetailService,
    // private _snackBar: MatSnackBar,
    private siteAdminService: AdminService,
    private fileCabService: FileCabinetService,
    private route: ActivatedRoute,
    private location: Location,
    private authorityService: AuthorityService,
    private userService: UserService
  ) { }
  ngAfterViewChecked(): void {
    if (this.propertyIdForEdit && !this.addPropertyForm.get('address').get('streetLine1').value) {
      this.addPropertyForm.get('buildingName').patchValue(this.propertyDetails ? this.propertyDetails.buildingName : '');
      this.addPropertyForm.get('occupancyType').patchValue(this.propertyDetails ? this.propertyDetails.propertyType : '');
      this.addPropertyForm.get('constructionType').patchValue(this.propertyDetails ? this.propertyDetails.buildingType : '');
      this.addPropertyForm.get('noOfStories').patchValue(this.propertyDetails ? this.propertyDetails.numberOfStories : '');
      this.addPropertyForm.get('yearBuilt').patchValue(this.propertyDetails ? this.propertyDetails.yearBuilt : '');
      this.addPropertyForm.get('floorArea').patchValue(this.propertyDetails ? this.propertyDetails.floorArea : '');
      this.addPropertyForm.get('address').patchValue({
        streetLine1: this.propertyDetails.streetLine1,
        streetLine2: this.propertyDetails.streetLine2 ? this.propertyDetails.streetLine2 : '' ,
        state: this.propertyDetails.zipcode && this.propertyDetails.zipcode.state
        ? this.propertyDetails.zipcode.state
        : '',

        city:this.propertyDetails.zipcode && this.propertyDetails.zipcode.city
        ? this.propertyDetails.zipcode.city
        : '',
        zipcode: this.propertyDetails.zipcode && this.propertyDetails.zipcode.zipcode
        ? this.propertyDetails.zipcode.zipcode
        : '',

      });
      let managerDetails = this.propertyDetails.propertyContacts.find(p => p.isManager === true);
      if (managerDetails) {
        this.addPropertyForm.get('manager').patchValue({
          firstName: managerDetails.firstName ? managerDetails.firstName : '',
          lastName: managerDetails.lastName ? managerDetails.lastName : '',
          phoneNumber: managerDetails.phoneNum ?  managerDetails.phoneNum : '',
          emailAddress: managerDetails.email ? managerDetails.email : '',
          managerAddress: {
            streetLine1: managerDetails.streetLine1 ? managerDetails.streetLine1 : '',
            streetLine2: managerDetails.streetLine2 ? managerDetails.streetLine2 : '',
            zipcode: (managerDetails.zipcode) ? managerDetails.zipcode.zipcode : '',
            city: (managerDetails.zipcode) ? managerDetails.zipcode.city : '',
            state: (managerDetails.zipcode) ? managerDetails.zipcode.state : ''
          }

        });
      }
      this.addPropertyForm.get('owner').patchValue({
        name: this.propertyDetails.ownerId ? this.propertyDetails.ownerId.firstName + ' ' + this.propertyDetails.ownerId.lastName : ' ',
        number: this.propertyDetails.ownerId ? this.propertyDetails.ownerId.cotactNumber : '',
        address: this.propertyDetails.ownerId  ? this.propertyDetails.ownerId.address.replace('<br/>',' ') : '',
        email: this.propertyDetails.ownerId  ? this.propertyDetails.ownerId.email :'',
      });
    }
}

  ngOnInit() {

    this.subscriptions.push(this.route.queryParams
      .subscribe(params => {
        this.department = params.department;
        this.editId = params.id;
        // setting to avoid wrong view rendering
        if (!this.editId) {
          this.showPencil = false;
          this.showMessage = false;
        } else {
          this.showPencil = true;
          this.showMessage = false;
        }
      })
    );
    this.creationDisabled = false;
    this.user = JSON.parse(localStorage.getItem('userDetails'));

    if (this.user) {
      this.isAuthorityLoggedIn = this.user.role.id === Roles.ROLE_SITE_ADMIN
        || this.user.role.id === Roles.ROLE_AUTHORITY_ADMIN || this.user.role.id === Roles.ROLE_AUTHORITY_WORX;
    }
    this.zipcodeMappingDetails = { jurisdictionDto: [] };
    const juristictionArray = [];
    this.userRole = localStorage.getItem('userRole');
    juristictionArray.push(this.user.jurisdiction);
    if (this.userRole === 'ROLE_AUTHORITY' || this.userRole === 'ROLE_AUTHORITY_WORX') {
      this.zipcodeMappingDetails = { jurisdictionDto: juristictionArray };
    }
    this.nextDisabled = false;
    this.readOnlyCity = true;
    this.readOnlyState = true;
    this.readOnlyManagerCity = true;
    this.readOnlyManagerState = true;
    this.showAdditionZipCode = false;
    // this.uspsOn = environment.uspsServiceOn;
    this.isCanadianZipCode = false;
    this.isCanadianZipCodeManager = false;
    this.phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    this.canadinaZipRegex = /^(?!.*[DFIOQUdfioqu])[A-VXYa-vxy][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/;
    this.allowManagerPopulate = false;
    this.states = [];
    this.cities = [];
    // this.selectedStateID = null;
    // this.selectedCityID = null;
    // this.selectedManagerStateID = null;
    // this.selectedManagerCityID = null;
    // this.propertyService.getAllStates().subscribe(
    //   res => {
    //     this.states = res;
    //     this.selectedStateID = this.states[0].id;
    //     this.selectedManagerStateID = this.states[0].id;
    //   }
    // );
    this.subscriptions.push(
      this.propertyService.getOccupancyType().subscribe(
        data => {
          this.occupancyTypeOptions = data;
        },
        error => {
          console.log(error);
          alert('An Error has Occured');
        }
      )
    );

    this.subscriptions.push(
      this.propertyService.getConstructionTypeOptions().subscribe(
        data => {
          this.constructionTypeOptions = data;
        },
        error => {
          console.log(error);
          alert('An Error has Occured');
        }
      )
    );

    // reset errors
    this.resetErrorsMap();

    this.propertyDetails = {
      accessCode: null,
      assignmentId: null,
      buildingName: "",
      buildingType: null,
      createdBy: 0,
      floorArea: null,
      id: null,
      isActive: null,
      isPropertyActive: false,
      jurisdiction: null,
      numberOfStories: null,
      ownerId: null,
      propertyContacts: [],
      propertyType: null,
      propertySubType: null,
      streetLine1: null,
      streetLine2: null,
      yearBuilt: null,
      updatedBy: null,
      zipcode: null
    };


    let juristriction = [] as any;

    juristriction = this.user.jurisdiction;
    this.zipcodeMappingDetails.selectedJurisdictionId = juristriction ? juristriction.id : -1;

    this.addPropertyForm = this.fb.group({
      buildingName: [''],
      isActive: [true],
      occupancyType: [''],
      occupancySubType: [],
      otherOccupancyType: [],
      constructionType: [''],
      otherContructionType: [],
      noOfStories: ['', Validators.maxLength(2)],
      yearBuilt: ['', Validators.pattern('[0-9]{4}')],
      floorArea: [],
      address: this.fb.group({
        streetLine1: ['', Validators.required],
        streetLine2: [''],
        zipcode: ['', Validators.required],
        additionalZipcode: [''],
        city: ['', Validators.required],
        state: ['', Validators.required],
        jurisdictionName: [juristriction ? juristriction.id : -1]
      }),
      manager: this.fb.group({
        firstName: ['PROPERTY'],
        lastName: ['MANAGER'],
        phoneNumber: [''],
        emailAddress: [
          '',
          Validators.pattern('[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+')
        ],
        copyAddress: [false],
        managerAddress: this.fb.group({
          streetLine1: [],
          streetLine2: [],
          zipcode: [],
          city: [],
          state: []
        })
      }),
      owner: this.fb.group({
        name: [''],
        number: [''],
        address: [''],
        email:['']
      })
    });
    // this.updateManagerContactsFlag();
    // property form
    if(this.userService.userDetails && this.userService.userDetails.accessSpecifier === 'READ_ONLY') {
      this.isDisabledReadOnly = true;
      //this.addPropertyForm.controls.buildingName.disable();
      //this.fb.control['buildingName'].disable();
    }
    this.subscriptions.push(
      this.propertyService.propertyId$.subscribe(res => {
        this.errorOccured.emit(false);
        if (+res > 0) {
          this.loading = true;
          this.subscriptions.push(
            this.propertyService
              .getPropertyById(
                this.propertyService.propertyId$.getValue().toString()
              )
              .subscribe(
                response => {
                  this.loading = false;
                  this.propertyService.propertyDetails$.next(response);
                  if (response) {
                    // Code Block for Worx allow edit or not - start
                    if (this.department && this.department.indexOf('Worx') > 0) {
                      this.subscriptions.push(this.authorityService.getAuthorityPreferences().subscribe((res) => {
                        this.authorityService.authorityPrefenrences.next(res);
                        const pref = res;
                        const createdByCurrentUser = (response.createdByEmail === this.user.email ||
                          pref.authorityContacts.exisitingContacts.filter(elem => {
                            elem.emailAddress === response.createdByEmail
                          }).length > 1
                        );
                        if (this.user.role.name === "ROLE_AUTHORITY_WORX"
                          && createdByCurrentUser) {
                          this.showPencil = false;
                          this.showMessage = false;
                        } else {
                          if (this.user.isPPA) {
                            this.showPencil = false;
                            this.showMessage = false;
                          } else {
                            this.showPencil = true;
                            this.showMessage = true;
                          }
                        }
                      }, (error) => {

                      }));
                    } else {
                      this.showPencil = false;
                    }

                    let occupancyType;
                    let occupancySubType;
                    let buildingType;
                    let propertyContact;
                    if (response.propertyContacts &&
                      response.propertyContacts.length > 0) {
                      if (response.propertyContacts.find((x) => x.isManager)) {
                        propertyContact = response.propertyContacts.find((x) => x.isManager);
                      }
                      // this.otherContacts = (response.propertyContacts.filter((x) => !x.isManager));

                    }

                    if (response.propertyType && this.occupancyTypeOptions) {
                      occupancyType = this.occupancyTypeOptions.find(x => {
                        return x.name === response.propertyType;
                      });
                      if (!occupancyType) {
                        occupancyType = this.occupancyTypeOptions[
                          this.occupancyTypeOptions.length - 1
                        ];
                        this.occupancyTypeOther = true;
                      }
                      if (
                        response.propertySubType &&
                        occupancyType &&
                        occupancyType.propertySubtypes &&
                        !this.occupancyTypeOther
                      ) {
                        this.occupancySubTypeDisplay = true;
                        this.occupancySubTypeOptions =
                          occupancyType.propertySubtypes;
                        occupancySubType = occupancyType.propertySubtypes.find(x => {
                          return x.subtypeName === response.propertySubType;
                        });
                      } else {
                        this.occupancySubTypeDisplay = false;
                      }
                    }
                    if (response.buildingType && this.constructionTypeOptions) {
                      buildingType = this.constructionTypeOptions.find(x => {
                        return x.name === response.buildingType;
                      });
                      if (!buildingType) {
                        buildingType = { name: 'Other' };
                        this.constructionTypeOther = true;
                      }
                    }
                    this.addPropertyForm = this.fb.group({
                      buildingName: [
                        response.buildingName ? response.buildingName : ''
                      ],
                      isActive: [
                        response.isActive !== null ? response.isActive : 'true'
                      ],
                      occupancyType: [occupancyType ? occupancyType.name : ''],
                      occupancySubType: [
                        occupancySubType ? occupancySubType.subtypeName : ''
                      ],
                      otherOccupancyType: [
                        occupancyType && occupancyType.name === 'Other'
                          ? response.propertyType
                          : ''
                      ],
                      constructionType: [
                        buildingType ? buildingType.name : ''
                      ],
                      otherContructionType: [
                        buildingType && buildingType.name === 'Other'
                          ? response.buildingType
                          : ''
                      ],
                      noOfStories: [
                        response.numberOfStories || '',
                        Validators.maxLength(2)
                      ],
                      yearBuilt: [
                        response.yearBuilt || '',
                        Validators.pattern('[0-9]{4}')
                      ],
                      floorArea: [response.floorArea || ''],
                      address: this.fb.group({
                        streetLine1: [
                          response.streetLine1 || '',
                          Validators.required
                        ],
                        streetLine2: [response.streetLine2 || ''],
                        zipcode: [
                          response.zipcode && response.zipcode.zipcode
                            ? response.zipcode.zipcode
                            : '',
                          Validators.required
                        ],
                        additionalZipcode: [''],
                        city: [
                          response.zipcode && response.zipcode.city
                            ? response.zipcode.city
                            : '',
                          Validators.required
                        ],
                        state: [
                          response.zipcode && response.zipcode.state
                            ? response.zipcode.state
                            : '',
                          Validators.required
                        ],
                        jurisdictionName: [
                          response.jurisdiction ? response.jurisdiction.id : ''
                        ]
                      }),
                      manager: this.fb.group({
                        firstName: [
                          propertyContact
                            ? propertyContact.firstName
                            : ''
                        ],
                        lastName: [
                          propertyContact
                            ? propertyContact.lastName
                            : ''
                        ],
                        phoneNumber: [
                          propertyContact
                            ? propertyContact.phoneNum
                            : ''
                        ],
                        emailAddress: [
                          propertyContact
                            ? propertyContact.email
                            : '',
                          Validators.pattern(
                            '[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+'
                          )
                        ],
                        copyAddress: [false],
                        managerAddress: this.fb.group({
                          streetLine1: [
                            propertyContact
                              ? propertyContact.streetLine1
                              : ''
                          ],
                          streetLine2: [
                            propertyContact
                              ? propertyContact.streetLine2
                              : ''
                          ],
                          zipcode: [
                            propertyContact &&
                              propertyContact.zipcode &&
                              propertyContact.zipcode.zipcode
                              ? propertyContact.zipcode.zipcode
                              : ''
                          ],
                          city: [
                            propertyContact &&
                              propertyContact.zipcode &&
                              propertyContact.zipcode.city
                              ? propertyContact.zipcode.city
                              : ''
                          ],
                          state: [
                            propertyContact &&
                              propertyContact.zipcode &&
                              propertyContact.zipcode.state
                              ? propertyContact.zipcode.state
                              : ''
                          ]
                        })
                      }),
                      owner: this.fb.group({
                        name: [response.ownerId ? response.ownerId.firstName + ' ' + response.ownerId.lastName : ' '],
                        number: [response.ownerId ? response.ownerId.cotactNumber : ''],
                        address: [response.ownerId ? response.ownerId.address : ''],
                        email: [response.ownerId ? response.ownerId.email : '']
                      })
                    });

                // this.updateManagerContactsFlag();

                  }
                  if (this.userRole === 'ROLE_AUTHORITY' || this.userRole === 'ROLE_RFA' ||
                  this.userRole === 'ROLE_AUTHORITY_WORX' || this.userRole === 'ROLE_SITE_ADMIN') {
                  if (response && response.isPropertyActive) {
                     this.propertyDetails.isPropertyActive = true;
                   } else if (response && !response.isPropertyActive) {
                    this.propertyDetails.isPropertyActive = false;
                   }

                }
                  if (response.zipcode && response.zipcode.zipcode) {
                    this.subscriptions.push(
                      this.propertyService
                        .getJurisdictionDetailsFromZipcode(response.zipcode.zipcode)
                        .subscribe(data => {
                          if (data) {
                            this.zipcodeMappingDetails = data;
                            this.zipcodeMappingDetails.jurisdictionDto = this.zipcodeMappingDetails.jurisdictionDto.filter(
                              res => res.id != -1
                            );
                            if (data && data.city && data.state && this.zipcodeMappingDetails) {
                              this.readOnlyCity = true;
                              this.readOnlyState = true;
                              this.addPropertyForm.get('address').patchValue({
                                jurisdictionName: this.propertyService.propertyDetails$.getValue().jurisdiction.id,
                                state: this.zipcodeMappingDetails.state,
                                city: this.zipcodeMappingDetails.city
                              });
                              if (!this.zipcodeMappingDetails.jurisdictionDto ||
                                this.zipcodeMappingDetails.jurisdictionDto.length === 0) {
                                this.zipcodeMappingDetails.jurisdictionDto = [];
                              }
                              // this.addPropertyForm.value.address.jurisdictionName = this.zipcodeMappingDetails.selectedJurisdictionId;
                            } else {
                              this.zipcodeMappingDetails = { jurisdictionDto: [] };
                              // this.getCitiesByState(this.selectedStateID);
                            }
                          } else {
                            this.zipcodeMappingDetails = { jurisdictionDto: [] };
                          }
                        })
                    );
                  }
                  if (this.addPropertyForm.value.address.zipcode.length === 5) {
                    this.isCanadianZipCode = false;
                  } else {
                    this.isCanadianZipCode = true;
                  }
                  // this.updateManagerContactsFlag();
                },
                error => {
                  console.log(error);
                  alert('An Error has Occured');
                  this.loading = false;
                  this.errorOccured.emit(true);
                }
              )
          );
        }
      })
    );

    this.subscriptions.push(
      this.router.events.subscribe(evnt => {
        if (evnt instanceof NavigationEnd) {
          this.router.navigated = false;
          this.ngOnInit();
          this.propertyService.propertyDetails$.next(null);
          this.propertyService.propertyId$.next(-1);
          this.propertyService.propertySaved.next(false);
          this.contactsService.propertyContacts.next(null);
          this.contactsService.propertyName.next(null);
          this.contactsService.propertyID.next(null);
          this.contactsService.propertyAddress.next(null);
          this.propertyService.systemDetails$.next(null);
          this.journalService.notes.next(null);
          this.journalService.journal.next(null);
          this.occupancySubTypeDisplay = false;
          this.constructionTypeOther = false;
          this.occupancyTypeOther = false;
        }
      })
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.subscriptions.push(this.propertyService.propertyDetails$.subscribe(res => {
        if (res && res.jurisdiction && res.jurisdiction.status === -1) {
          this.zipcodeMappingDetails.selectedJurisdictionId = 0;
        } else if (res !== null) {
          this.zipcodeMappingDetails.selectedJurisdictionId = res.jurisdiction.id;
        }
      }, err => {
        // TODO: Handle Error
      }));
    }, 2000);
  }

  editProperty() {
    const dialogRef = this.dialog.open(EditPropertyDialogComponent, {
      height: '90vh',
      width: '60vw',
      autoFocus: false,
      data: { id: this.editId }
    });
  }

  populateAddress(val: any) {
    this.readOnlyCity = false;
    this.readOnlyState = false;
    let zipcode = this.addPropertyForm.value.address.zipcode;
    this.isCanadianZipCode = false;

    if (zipcode.length === 6) {
      zipcode = zipcode.toUpperCase();
      zipcode = zipcode.substr(0, 3) + ' ' + zipcode.substr(3, 3);
      this.addPropertyForm.value.address.zipcode = zipcode;
    }

    if (this.canadinaZipRegex.test(zipcode)) {
      this.isCanadianZipCode = true;
    }
    if (zipcode && zipcode.length > 0) {
      this.subscriptions.push(
        this.propertyService
          .getJurisdictionDetailsFromZipcode(zipcode)
          .subscribe(data => {
            if (data) {
              this.zipcodeMappingDetails = data;
              this.zipcodeMappingDetails.jurisdictionDto = this.zipcodeMappingDetails.jurisdictionDto.filter(
                res => res.id != -1
              );
              if (data && data.city && data.state && this.zipcodeMappingDetails) {
                this.readOnlyCity = true;
                this.readOnlyState = true;
                this.addPropertyForm.get('address').patchValue({
                  jurisdictionName: this.zipcodeMappingDetails.selectedJurisdictionId,
                  state: this.zipcodeMappingDetails.state,
                  city: this.zipcodeMappingDetails.city
                });
                if (!this.zipcodeMappingDetails.jurisdictionDto || this.zipcodeMappingDetails.jurisdictionDto.length === 0) {
                  this.zipcodeMappingDetails.jurisdictionDto = [];
                }
                // this.addPropertyForm.value.address.jurisdictionName = this.zipcodeMappingDetails.selectedJurisdictionId;
              } else {
                this.zipcodeMappingDetails = { jurisdictionDto: [] };
                // this.getCitiesByState(this.selectedStateID);
              }
            } else {
              this.zipcodeMappingDetails = { jurisdictionDto: [] };
            }
          })
      );
    } else {
      this.readOnlyCity = true;
      this.readOnlyState = true;
      this.zipcodeMappingDetails.selectedJurisdictionId = -1;
      this.addPropertyForm.get('address').patchValue({
        jurisdictionName: this.zipcodeMappingDetails.selectedJurisdictionId,
        state: "",
        city: ""
      });
      if (!this.zipcodeMappingDetails.jurisdictionDto || this.zipcodeMappingDetails.jurisdictionDto.length === 0) {
        this.zipcodeMappingDetails.jurisdictionDto = [];
      }
    }
    // this.subscriptions.push(
    //   this.propertyService
    //     .getJurisdictionDetailsFromZipcode(zipcode)
    //     .subscribe(data => {
    //       if (data && data.state) {
    //         this.readOnlyState = true;
    //         this.zipcodeMappingDetails = data;
    //         this.addPropertyForm.value.address.state =
    //           this.zipcodeMappingDetails && this.zipcodeMappingDetails.state;
    //         if (!this.zipcodeMappingDetails.jurisdictionDto || this.zipcodeMappingDetails.jurisdictionDto.length === 0) {
    //             this.zipcodeMappingDetails.jurisdictionDto = [];
    //           }
    //       }
    //     })
    // );
    // this.subscriptions.push(this.propertyService
    //     .getJurisdictionDetailsFromZipcode(zipcode)
    //     .subscribe(data => {
    //       if (data && data.selectedJurisdictionId) {
    //         this.zipcodeMappingDetails = data;
    //         this.addPropertyForm.value.address.jurisdictionName = this.zipcodeMappingDetails.selectedJurisdictionId;
    //       }
    //     }));
  }

  goBackToPreviousPage() {
    this.location.back();
  }

  redirectToHomePage() {
    this.propertyService.propertyDetails$.next(null);
    this.contactsService.propertyContacts.next(null);
    this.contactsService.propertyName.next(null);
    this.contactsService.propertyID.next(null);
    this.contactsService.propertyAddress.next(null);
    this.propertyService.systemDetails$.next(null);
    this.journalService.notes.next(null);
    this.journalService.journal.next(null);
    if (this.userRole === 'ROLE_OWNER') {
      this.router.navigate(['user/owner/dashboard']);
    }
    if (this.userRole === 'ROLE_AUTHORITY' || this.userRole === 'ROLE_RFA'
      || this.userRole === 'ROLE_AUTHORITY_WORX') {
      this.router.navigate(['user/authority/dashboardAuthority']);
    }
    if (this.userRole === 'ROLE_SITE_ADMIN') {
      this.router.navigate(['user/admin/dashboardSiteAdmin']);
    }
    if (this.userRole === 'ROLE_INSPECTOR') {
      this.router.navigate(['user/inspector/dashboardInspector']);
    }
  }

  populateManagerAddress(val: any) {
    const zipcode = this.addPropertyForm.value.manager.managerAddress.zipcode;
    this.subscriptions.push(
      this.propertyService
        .getJurisdictionDetailsFromZipcode(zipcode)
        .subscribe(data => {
          this.addPropertyForm.value.manager.managerAddress.city = this.zipcodeMappingDetails.city;
          this.addPropertyForm.value.manager.managerAddress.state = this.zipcodeMappingDetails.state;
        })
    );
  }

  onSubmit(val) {
    this.creationDisabled = true;
    let formValidity = true;
    this.resetErrorsMap();

    // other occupancy Type validation
    if (this.occupancyTypeOther === true) {
      if (!this.addPropertyForm.value.otherOccupancyType) {
        const othetOccTypeError: string[] = [];
        othetOccTypeError.push('Enter Occupancy type of property.');
        this.errors.set('otherOccType', othetOccTypeError);
        formValidity = false;
      }
    }

    // other construction Type validation
    if (this.constructionTypeOther === true) {
      if (!this.addPropertyForm.value.otherContructionType) {
        const othetConstructTypeError: string[] = [];
        othetConstructTypeError.push('Enter Construction Type.');
        this.errors.set('otherConstructType', othetConstructTypeError);
        formValidity = false;
      }
    }

    // number of stories
    this.storiesNumberValidation();
    if (this.errors.get('storiesNumber').length > 0) {
      formValidity = false;
    }

    // year build validation check
    this.yearBuildValidation();
    if (this.errors.get('yearBuilt').length > 0) {
      formValidity = false;
    }

    // floor area validation check
    this.floorAreaValidation();
    if (this.errors.get('floorArea').length > 0) {
      formValidity = false;
    }

    // validate street line 1
    this.validateStreetLine1();
    if (this.errors.get('streetLine1').length > 0) {
      formValidity = false;
    }

    // validate zip code
    this.validateZipCode();
    if (this.errors.get('zipcode').length > 0) {
      formValidity = false;
    }

    // validate Additional Zip-code
    this.validateAdditionalZipCode();
    if (this.errors.get('additionalZipcode').length > 0) {
      formValidity = false;
    }

    // validate city
    const city = this.addPropertyForm.value.address.city;
    const cityError: string[] = [];
    if (!city || city.trim().length === 0) {
      cityError.push('City is mandatory.');
      this.errors.set('city', cityError);
      formValidity = false;
    } else if (!/^([a-z A-Z]+)$/.test(city)) {
      cityError.push('City name can only have alphabets.');
      this.errors.set('city', cityError);
      formValidity = false;
    }

    // validate state
    const state = this.addPropertyForm.value.address.state;
    const stateError: string[] = [];
    if (!state || state.trim().length === 0) {
      stateError.push('State is mandatory.');
      this.errors.set('state', stateError);
      formValidity = false;
    } else if (!/^([a-z A-Z]+)$/.test(state)) {
      stateError.push('State name can only have alphabets.');
      this.errors.set('state', stateError);
      formValidity = false;
    }

    // validate phone Number
    const phoneNo: string = this.addPropertyForm.value.manager.phoneNumber;

    if (phoneNo && phoneNo !== " " && phoneNo.length > 0 && !this.phoneRegex.test(phoneNo)) {
      formValidity = false;
      const phoneNumberError: string[] = [];
      phoneNumberError.push('Enter a valid 10 digit phone number.');
      this.errors.set('phoneNo', phoneNumberError);
    }

    // email validation
    this.validateEmail();
    if (this.errors.get('email').length > 0) {
      formValidity = false;
    }

    this.validationForManagerName();
    if (this.errors.get('managerName').length > 0) {
      formValidity = false;
    }

    // manager validation
    this.validateManager();
    if (this.errors.get('manager').length > 0 ||
      this.errors.get('managerAddress').length > 0) {
      formValidity = false;
    }

    if (formValidity) {
      // USPS  Address Validation
      if (
        +this.propertyService.propertyId$.getValue() > 0 ||
        this.propertyService.propertySaved.getValue()
      ) {
        const currentProperty = this.propertyService.propertyDetails$.getValue();
        this.changeInAddress = false;
        this.changeInManagerAddress = false;
        if (!currentProperty.streetLine1) {
          currentProperty.streetLine1 = '';
        }
        if (!currentProperty.streetLine2) {
          currentProperty.streetLine2 = '';
        }
        if (
          this.addPropertyForm.value.address.city !==
          currentProperty.zipcode.city ||
          this.addPropertyForm.value.address.state !==
          currentProperty.zipcode.state ||
          this.addPropertyForm.value.address.zipcode !==
          currentProperty.zipcode.zipcode ||
          this.addPropertyForm.value.address.streetLine1 !==
          currentProperty.streetLine1 ||
          this.addPropertyForm.value.address.streetLine2 !==
          currentProperty.streetLine2
        ) {
          this.changeInAddress = true;
        }
        if(currentProperty.propertyContacts.length > 0) {
          if(currentProperty.propertyContacts.length > 0 &&
            this.addPropertyForm.value.manager.managerAddress.streetLine1 !==
            currentProperty.propertyContacts[0].streetLine1 ||
            this.addPropertyForm.value.manager.managerAddress.streetLine2 !==
            currentProperty.propertyContacts[0].streetLine2 ||
            currentProperty.propertyContacts[0].zipcode !== null &&
            (this.addPropertyForm.value.manager.managerAddress.city !==
            currentProperty.propertyContacts[0].zipcode.city ||
            this.addPropertyForm.value.manager.managerAddress.state !==
            currentProperty.propertyContacts[0].zipcode.state ||
            this.addPropertyForm.value.manager.managerAddress.zipcode !==
            currentProperty.propertyContacts[0].zipcode.zipcode)) {
              this.changeInManagerAddress = true;
            }
        } else {
          if (this.addPropertyForm.value.manager.managerAddress
             &&  this.addPropertyForm.value.manager.managerAddress.streetLine1 !== null
            && this.addPropertyForm.value.manager.managerAddress.city !== null
            && this.addPropertyForm.value.manager.managerAddress.state !== null
            && this.addPropertyForm.value.manager.managerAddress.zipcode !== null &&
            this.addPropertyForm.value.manager.managerAddress.streetLine1.length > 0 &&
            this.addPropertyForm.value.manager.managerAddress.city.length > 0 &&
            this.addPropertyForm.value.manager.managerAddress.state.length > 0 &&
            this.addPropertyForm.value.manager.managerAddress.zipcode.length > 0) {
              this.changeInManagerAddress = true;
            }
        }
        this.subscriptions.push(
          this.siteAdminService.getAdminSettings().subscribe(
            res => {
              this.uspsOn = res.uspsVerification;
              if (this.uspsOn && !this.isCanadianZipCode) {
                if(this.changeInAddress) {
                  this.validateAddressFromUsps(this.changeInAddress);
                } else if(!this.changeInAddress && this.changeInManagerAddress) {
                  this.validateManagerAddressFromUsps();
                } else if(!this.changeInAddress && !this.changeInManagerAddress) {
                  this.prePostForm();
                }
              } else {
                // no pre required
                this.postForm();
              }
            },
            error => {
              console.log(error);
              this.uspsOn = false;
              // no pre required
              this.postForm();
            }
          )
        );
      } else {
        this.subscriptions.push(
          this.siteAdminService.getAdminSettings().subscribe(
            res => {
              this.uspsOn = res.uspsVerification;
              if (this.uspsOn && !this.isCanadianZipCode) {
                this.changeInAddress = true;
                this.validateAddressFromUsps();
                if (
                  this.addPropertyForm.value.manager.managerAddress &&
                  this.addPropertyForm.value.manager.managerAddress.streetLine1 !== null &&
                  this.addPropertyForm.value.manager.managerAddress.streetLine1.length > 0 &&
                  this.addPropertyForm.value.manager.managerAddress.city !== null &&
                  this.addPropertyForm.value.manager.managerAddress.city.length > 0 &&
                  this.addPropertyForm.value.manager.managerAddress.state !== null &&
                  this.addPropertyForm.value.manager.managerAddress.state.length > 0 &&
                  this.addPropertyForm.value.manager.managerAddress.zipcode !== null &&
                  this.addPropertyForm.value.manager.managerAddress.zipcode.length > 0
                ) {
                    this.changeInManagerAddress = true;
                  } else {
                    this.changeInManagerAddress = false;
                  }
              } else {

                this.nextDisabled = true;
                // no pre required
                if (this.userRole === 'ROLE_OWNER' || this.userRole === 'ROLE_INSPECTOR') {
                  this.triggerContinueAlert();
                } else {
                  this.postForm();
                }
                this.nextDisabled = false;
              }
            }, error => {
              console.log(error);
              this.uspsOn = false;
              // no pre required
              this.postForm();
            }
          )
        );
      }
    } else {
      this.creationDisabled = false;
      if (this.errors.get('otherOccType').length > 0) {
        document.getElementById('otherOccupancyType').focus();
      } else if (this.errors.get('otherConstructType').length > 0) {
        document.getElementById('otherConstructType').focus();
      } else if (this.errors.get('storiesNumber').length > 0) {
        document.getElementById('noOfStories').focus();
      } else if (this.errors.get('yearBuilt').length > 0) {
        document.getElementById('yearBuilt').focus();
      } else if (this.errors.get('floorArea').length > 0) {
        document.getElementById('floorArea').focus();
      } else if (this.errors.get('streetLine1').length > 0) {
        document.getElementById('addressStLine1').focus();
      } else if (this.errors.get('zipcode').length > 0) {
        document.getElementById('addressZipCode').focus();
      } else if (this.errors.get('additionalZipcode').length > 0) {
        document.getElementById('addressAdditionalZipCode').focus();
      } else if (this.errors.get('city').length > 0) {
        document.getElementById('addressCity').focus();
      } else if (this.errors.get('state').length > 0) {
        document.getElementById('addressState').focus();
      } else if (
        this.errors.get('phoneNo').length > 0 ||
        this.errors.get('manager').length > 0
      ) {
        document.getElementById('phonep1').focus();
      } else if (this.errors.get('email').length > 0) {
        document.getElementById('emailAddress').focus();
      } else if (this.errors.get('managerName').length > 0) {
        if (this.errors.get('managerName')[0].includes('first name')) {
          document.getElementById('managerFirstName').focus();
        } else {
          document.getElementById('managerLastName').focus();
        }
      }
    }
  }

  triggerContinueAlert() {
    if (this.userRole === 'ROLE_INSPECTOR' && this.isEditDetail === 'Edit') {
      this.postForm();
    // }else if(!this.propertyService.propertyId$.getValue()){// create property flow
    //   this.postForm();
    //
    }else if (confirm("The information you provided will be sent to the fire marshal's office for approval. The property will be in your File Cabinet and marked red as inactive until it is approved.\nDo you want to continue?")) {
      this.postForm();
    } else {
      this.creationDisabled = false;
    }
  }

  checkOtherType(occType: string) {
    if (occType.length === 0) {
      this.occupancySubTypeDisplay = false;
      this.occupancyTypeOther = false;
    } else {
      if (occType === 'Other') {
        this.occupancyTypeOther = true;
        this.occupancySubTypeDisplay = false;
      } else {
        this.occupancyTypeOther = false;

        const obj: PropertyType = this.occupancyTypeOptions.find(
          innerObj => innerObj.name === occType
        );
        if (obj != null && obj.propertySubtypes.length > 0) {
          this.occupancySubTypeDisplay = true;
        } else {
          this.occupancySubTypeDisplay = false;
        }
        this.occupancySubTypeOptions = obj.propertySubtypes;
        if (
          this.occupancySubTypeOptions &&
          this.occupancySubTypeOptions.length > 0
        ) {
          this.addPropertyForm.value.occupancySubType = this.occupancySubTypeOptions[0].subtypeName;
        }
      }
    }
  }

  checkOtherConstructionType(constructType: string) {
    if (constructType === 'Other') {
      this.constructionTypeOther = true;
    } else {
      this.constructionTypeOther = false;
    }
  }

  copyAddress(checked: boolean) {
    if (checked) {
      this.managerAddressSameAsProp = true;
      // this.addPropertyForm.value.manager.managerAddress. = ;
      // this.addPropertyForm.value.manager.managerAddress. = ;
      // this.addPropertyForm.value.manager.managerAddress.zipcode = ;
      this.addPropertyForm.get('manager').get('managerAddress').patchValue({
        streetLine1: this.addPropertyForm.value.address.streetLine1,
        streetLine2: this.addPropertyForm.value.address.streetLine2,
        zipcode: this.addPropertyForm.value.address.zipcode,
        state: this.addPropertyForm.value.address.state,
        city: this.addPropertyForm.value.address.city
      });
      // this.addPropertyForm.value.manager.managerAddress.city = this.addPropertyForm.value.address.city;
      // this.addPropertyForm.value.manager.managerAddress.state = ;
      if (this.readOnlyCity) {
        this.readOnlyManagerCity = true;
        this.readOnlyManagerState = true;
      } else {
        this.readOnlyManagerCity = false;
        this.readOnlyManagerState = false;

        // this.selectedManagerStateID = this.states.find(item => {
        //   return item.id.trim() === this.addPropertyForm.value.manager.managerAddress.state;
        // }).id;

        // this.subscriptions.push(
        // this.propertyService.getCitiesByState(this.selectedManagerStateID).subscribe(
        //     res => {
        //       this.managerCities = res;
        //       this.selectedManagerCityID = this.managerCities.find(item => {
        //         return item.data.trim() === this.addPropertyForm.value.manager.managerAddress.city;
        //       }).data;
        //       this.setManagerCity(this.selectedManagerCityID);
        //     }
        //   )
        // );

      }
    } else {
      this.managerAddressSameAsProp = false;
    }
  }
  copyAddressEdit(checked: boolean) {
    if (checked) {
      if (this.addPropertyForm.value.manager && this.addPropertyForm.value.manager.managerAddress
        && this.addPropertyForm.value.manager.managerAddress.streetLine1
        && this.addPropertyForm.value.manager.managerAddress.zipcode &&
        this.addPropertyForm.value.manager.managerAddress.state &&
        this.addPropertyForm.value.manager.managerAddress.city) {
        this.addPropertyForm.get('address').patchValue({
          streetLine1: this.addPropertyForm.value.manager.managerAddress.streetLine1,
          streetLine2: this.addPropertyForm.value.manager.managerAddress.streetLine2,
          zipcode: this.addPropertyForm.value.manager.managerAddress.zipcode,
          state: this.addPropertyForm.value.manager.managerAddress.state,
          city: this.addPropertyForm.value.manager.managerAddress.city
        });
        if (this.readOnlyManagerCity) {
          this.readOnlyCity = true;
          this.readOnlyState = true;
        } else {
          this.readOnlyCity = false;
          this.readOnlyState = false;
        }
      }
    }
  }
  buttonClicked(buttonText: any) {
    this.currentButton = buttonText;
  }

  moveToNextPhoneTab(value, nextTabID) {
    if (value.length >= 3) {
      document.getElementById(nextTabID).focus();
    }
  }

  storiesNumberValidation() {
    const floors = this.addPropertyForm.value.noOfStories;
    if (floors) {
      const storiesNumberErrors: string[] = [];
      if (
        !/^([0-9]{0,3})$/.test(floors) ||
        parseInt(floors, 10) < 1 ||
        parseInt(floors, 10) > 999
      ) {
        storiesNumberErrors.push(
          'Enter a numeric value for Number of Stories greater than or equal to 1 and less than or equal to 999.'
        );
      } else {
        this.addPropertyForm.value.noOfStories = parseInt(floors, 10);
      }
      this.errors.set('storiesNumber', storiesNumberErrors);
    }
  }

  validateStreetLine1() {
    const stL1 = this.addPropertyForm.value.address.streetLine1;
    if (!stL1) {
      const streetLine1Error: string[] = [];
      streetLine1Error.push('Street Line 1 is mandatory');
      this.errors.set('streetLine1', streetLine1Error);
    }
  }

  yearBuildValidation() {
    const builtYear = this.addPropertyForm.value.yearBuilt;
    if (builtYear) {
      const builtYearErrors: string[] = [];
      if (
        !/^([0-9]{4})$/.test(builtYear) ||
        (parseInt(builtYear, 10) > new Date().getFullYear() ||
          parseInt(builtYear, 10) < 1600)
      ) {
        builtYearErrors.push(
          'Enter numeric value for Year Built of property greater than or equal to 1600 and less than or equal to current year.'
        );
      }
      this.errors.set('yearBuilt', builtYearErrors);
    }
  }

  floorAreaValidation() {
    const floorArea = this.addPropertyForm.value.floorArea;
    const floorAreaErrors: string[] = [];
    if (floorArea && !/^([0-9]+)$/.test(floorArea)) {
      floorAreaErrors.push('Enter numeric value for Floor Area of property.');
    }
    this.errors.set('floorArea', floorAreaErrors);
  }

  validateEmail() {
    const emailAddressErrors: string[] = [];

    if (
      this.addPropertyForm.value.manager.emailAddress &&
      this.addPropertyForm.value.manager.emailAddress !== " " &&
      !/[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*/.test(
        this.addPropertyForm.value.manager.emailAddress
      )
      // this.addPropertyForm.controls.manager.controls.emailAddress.isActive === 'INVALID'
    ) {
      emailAddressErrors.push(
        'Enter a valid email address like abc@gmail.com.'
      );
    }

    this.errors.set('email', emailAddressErrors);
  }

  validateManager() {
    const managerErrors: string[] = [];
    const managerAddressErrors: string[] = [];
    const stl1: string = this.addPropertyForm.value.manager.managerAddress
      .streetLine1;
    const stl2: string = this.addPropertyForm.value.manager.managerAddress
      .streetLine2;
    const city: string = this.addPropertyForm.value.manager.managerAddress.city;
    const state: string = this.addPropertyForm.value.manager.managerAddress
      .state;
    const zip: string = this.addPropertyForm.value.manager.managerAddress
      .zipcode;

    const managerNamePresent: boolean = (this.addPropertyForm.value.manager.firstName !== null && this.addPropertyForm.value.manager.firstName.lenght > 0) ||
      (this.addPropertyForm.value.manager.lastName !== null && this.addPropertyForm.value.manager.lastName.length > 0);

    const nameNotPropertyManager: boolean = this.addPropertyForm.value.manager.firstName !== 'PROPERTY' &&
      this.addPropertyForm.value.manager.lastName !== 'MANAGER';

    const addressEntered: boolean = ((stl1 !== null && stl1.length > 0) || (stl2 !== null && stl2.length > 0) ||
      (city !== null && city.length > 0) || (state !== null && state.length > 0) || (zip !== null && zip.length > 0));

    const contactNotEntered: boolean = !this.addPropertyForm.value.manager.phoneNumber &&
      !this.addPropertyForm.value.manager.emailAddress;

    if (addressEntered && (zip.length === 0 || stl1.length === 0)) {
      managerAddressErrors.push('Enter Manager details before proceeding further.');
    }

    // // TEGFE-3326 contact and adress are independent in case of manager.
    if (addressEntered && contactNotEntered && !nameNotPropertyManager) {

      this.formSubmissionAllowedIfContactNotPresent = true;

      // managerErrors.push('Enter Manager Phone Number and/or Email Address before proceeding further.');
    }else {
      this.formSubmissionAllowedIfContactNotPresent = false;
    }

    if ((managerNamePresent && (nameNotPropertyManager))
      && contactNotEntered) {
      managerErrors.push('Enter Manager Phone Number and/or Email Address before proceeding further.');
    }
    this.errors.set('manager', managerErrors);
    this.errors.set('managerAddress', managerAddressErrors);
  }

  resetErrorsMap() {
    this.errors = new Map([
      ['otherOccType', []],
      ['otherConstructType', []],
      ['storiesNumber', []],
      ['yearBuilt', []],
      ['floorArea', []],
      ['streetLine1', []],
      ['zipcode', []],
      ['additionalZipcode', []],
      ['city', []],
      ['state', []],
      ['phoneNo', []],
      ['email', []],
      ['manager', []],
      ['managerName', []]
    ]);
  }

  validateAddressFromUsps(putForm?: boolean) {
    this.nextDisabled = true;
    if (
      this.addPropertyForm.value.address.streetLine1 != null ||
      this.addPropertyForm.value.address.additionalZipcode != null
    ) {
      this.subscriptions.push(
        this.propertyService
          .validateAddressFromUsps(this.addPropertyForm.value.address)
          .subscribe(
            async (res: any) => {
              this.nextDisabled = false;
              if (res.includes('<Error>')) {
                this.propertyService.isPropAddressValidated$.next(false);
                await this.openInvalidAddressPopUp();
              } else {
                await this.openValidAddressPopup(res);
              }
            },
            error => {
              // no pre required
              this.postForm();
            }
          )
      );
    }
  }

  validateAdditionalZipCode() {
    const addZipCode = this.addPropertyForm.value.address.additionalZipcode;

    if (addZipCode && !/^([0-9]{4})$/.test(addZipCode)) {
      const addZipCodeErrors: string[] = [];
      addZipCodeErrors.push(
        'Please enter valid numeric sub zip code of property address.'
      );
      this.errors.set('additionalZipcode', addZipCodeErrors);
    }
  }

  validateZipCode() {
    const zipCode = this.addPropertyForm.value.address.zipcode;
    const zipCodeError: string[] = [];
    this.isCanadianZipCode = false;

    if (this.canadinaZipRegex.test(zipCode)) {
      this.isCanadianZipCode = true;
    } else if (!zipCode || !/^([0-9]{5})$/.test(zipCode)) {
      zipCodeError.push(
        'Please enter valid numeric zip code of property address.'
      );
    }

    this.errors.set('zipcode', zipCodeError);
  }

  validationForManagerName() {
    if (this.addPropertyForm.value.manager.firstName) {
      this.addPropertyForm.value.manager.firstName = this.addPropertyForm.value.manager.firstName.trim();
    }
    if (this.addPropertyForm.value.manager.lastName) {
      this.addPropertyForm.value.manager.lastName = this.addPropertyForm.value.manager.lastName.trim();
    }
    let firstName: string = this.addPropertyForm.value.manager.firstName;
    let lastName: string = this.addPropertyForm.value.manager.lastName;
    const stl1: string = this.addPropertyForm.value.manager.managerAddress
      .streetLine1;
    const stl2: string = this.addPropertyForm.value.manager.managerAddress
      .streetLine2;
    const city: string = this.addPropertyForm.value.manager.managerAddress.city;
    const state: string = this.addPropertyForm.value.manager.managerAddress
      .state;
    const zip: string = this.addPropertyForm.value.manager.managerAddress
      .zipcode;

    const managerNameErrors: string[] = [];

    if (
      firstName &&
      firstName.length > 0 &&
      (!lastName || lastName.trim().length === 0)
    ) {
      this.addPropertyForm.value.manager.firstName = 'PROPERTY';
      firstName = 'PROPERTY';
      // managerNameErrors.push('Please enter manager last Name');
    } else if (
      lastName &&
      lastName.length > 0 &&
      (!firstName || firstName.length === 0)
    ) {
      this.addPropertyForm.value.manager.lastName = 'MANAGER';
      lastName = 'MANAGER';
      // managerNameErrors.push('Please enter manager first Name');
    } else if (
      (!lastName || lastName.length === 0) &&
      (!firstName || firstName.length === 0) &&
      ((stl1 && stl1.length > 0) ||
        (stl2 && stl2.length > 0) ||
        (city && city.length > 0) ||
        (state && state.length > 0) ||
        (zip && zip.length > 0))
    ) {
      this.addPropertyForm.get('manager').patchValue({
        firstName: 'PROPERTY',
        lastName: 'MANAGER'
      });
      // this.addPropertyForm.value.manager.firstName = 'PROPERTY';
      firstName = 'PROPERTY';
      // this.addPropertyForm.value.manager.lastName = 'MANAGER';
      lastName = 'MANAGER';
      // managerNameErrors.push('Please enter manager first Name');
      // managerNameErrors.push('Please enter manager last Name');
    }
    this.errors.set('managerName', managerNameErrors);
  }

  setJurisdiction(val) {
    this.addPropertyForm.value.address.jurisdictionName = val;
    if (
      (this.user.role.id == Roles.ROLE_AUTHORITY_ADMIN ||
        this.user.role.id == Roles.ROLE_AUTHORITY_USER || this.user.role.id === Roles.ROLE_AUTHORITY_WORX
      ) &&
      this.zipcodeMappingDetails.selectedJurisdictionId != val
    ) {
      const a = this.dialog.open(ConfirmationDialogComponent, {
        width: '50vw',
        height: '40vh',
      });

      this.subscriptions.push(a.afterClosed().subscribe(res => {
        if (res === 'no') {

          this.addPropertyForm.value.address.jurisdictionName = this.zipcodeMappingDetails.selectedJurisdictionId;
        } else {
          if (this.user.jurisdiction.id === -1) {
            this.propertyDetails.isPropertyActive = false;
          }
        }
      }));
    } else {
      if (this.user && this.user.jurisdiction && this.user.jurisdiction.id && this.user.jurisdiction.id === -1) {
        this.propertyDetails.isPropertyActive = false;
      }
    }
  }

  compare(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  openManagerValidAddressPopup(res: string) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(res, 'text/xml');

    let stLine2: string = null;
    if (
      xmlDoc.getElementsByTagName('Address1')[0] &&
      xmlDoc.getElementsByTagName('Address1')[0].childNodes[0]
    ) {
      stLine2 = xmlDoc.getElementsByTagName('Address1')[0].childNodes[0]
        .nodeValue;
      if (stLine2 && stLine2.toLowerCase() === 'null') {
        stLine2 = null;
      }
    }

    let subZip: string = null;
    if (
      xmlDoc.getElementsByTagName('Zip4')[0] &&
      xmlDoc.getElementsByTagName('Zip4')[0].childNodes[0]
    ) {
      subZip = xmlDoc.getElementsByTagName('Zip4')[0].childNodes[0].nodeValue;
      if (subZip && subZip.toLowerCase() === 'null') {
        subZip = null;
      }
    }

    this.addPropertyForm.value.manager.managerAddress.streetLine1 = xmlDoc.getElementsByTagName(
      'Address2'
    )[0].childNodes[0].nodeValue;
    this.addPropertyForm.value.manager.managerAddress.streetLine2 = stLine2;
    (this.addPropertyForm.value.manager.managerAddress.city = xmlDoc.getElementsByTagName(
      'City'
    )[0].childNodes[0].nodeValue),
      (this.addPropertyForm.value.manager.managerAddress.state = xmlDoc.getElementsByTagName(
        'State'
      )[0].childNodes[0].nodeValue),
      (this.addPropertyForm.value.manager.managerAddress.zipcode = xmlDoc.getElementsByTagName(
        'Zip5'
      )[0].childNodes[0].nodeValue),
      (this.addPropertyForm.value.manager.managerAddress.additionalZipcode = subZip);


    const dialogRef = this.dialog.open(USPSValidPopUpComponent, {
      width: '735px',
      height: '350px',
      data: {
        streetLine1: this.addPropertyForm.value.manager.managerAddress.streetLine1,
        streetLine2: stLine2,
        city: this.addPropertyForm.value.manager.managerAddress.city,
        state: this.addPropertyForm.value.manager.managerAddress.state,
        zipcode: this.addPropertyForm.value.manager.managerAddress.zipcode,
        subzipcode: subZip,
        manager: true
      },
      disableClose: true,
      autoFocus: false
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe(result => {
        this.managerAddressUspsDone = true;
        if (result === 'continue') {
          this.addPropertyForm.value.manager.managerAddress.streetLine1 = xmlDoc.getElementsByTagName(
            'Address2'
          )[0].childNodes[0].nodeValue;
          this.addPropertyForm.value.manager.managerAddress.streetLine2 = stLine2;
          this.addPropertyForm.value.manager.managerAddress.city = xmlDoc.getElementsByTagName(
            'City'
          )[0].childNodes[0].nodeValue;
          this.addPropertyForm.value.manager.managerAddress.state = xmlDoc.getElementsByTagName(
            'State'
          )[0].childNodes[0].nodeValue;
          this.addPropertyForm.value.manager.managerAddress.zipcode = xmlDoc.getElementsByTagName(
            'Zip5'
          )[0].childNodes[0].nodeValue;
          this.addPropertyForm.value.manager.managerAddress.additionalZipcode = subZip;
          this.prePostForm();
        } else if (result === 'cancelled') {
          this.currentButton = null;
          this.creationDisabled = false;
        }
      })
    );
    //  rest api call to post -> this.service.postPropertyDetails(this.propertyDetails);
  }

  validateManagerAddressFromUsps() {
    this.nextDisabled = true;
    if (
      this.addPropertyForm.value.manager.managerAddress.streetLine1 != null ||
      this.addPropertyForm.value.manager.managerAddress.additionalZipcode != null
    ) {
      this.subscriptions.push(
        this.propertyService
          .validateAddressFromUsps(this.addPropertyForm.value.manager.managerAddress)
          .subscribe(
            async (res: any) => {
              this.nextDisabled = false;
              if (res.includes('<Error>')) {
                this.propertyService.isPropAddressValidated$.next(false);
                await this.openInvalidAddressPopUp(true);
              } else {
                await this.openManagerValidAddressPopup(res);
              }
            },
            error => {
              this.postForm();
            }
          )
      );
    }
  }

  prePostForm() {
    let shouldPost: boolean = false;
    let noChange: boolean = false;
    if(
      (this.changeInAddress && this.addressUspsDone) &&
      (this.changeInManagerAddress && this.managerAddressUspsDone)
    ) {
      shouldPost = true;

    }
    if(!this.changeInAddress && !this.changeInManagerAddress) {
      shouldPost = true;
    }

    if(!this.changeInAddress && this.changeInManagerAddress && this.managerAddressUspsDone) {
      shouldPost = true;
    }

    if(!this.changeInManagerAddress && this.changeInAddress && this.addressUspsDone) {
      shouldPost = true;
    }
    // if(!this.changeInAddress && !this.changeInManagerAddress && !this.addressUspsDone &&
    //    !this.managerAddressUspsDone) {
    //   noChange = true;
    // }
    if(shouldPost) {
      if (
        this.userRole === "ROLE_OWNER" ||
        this.userRole === "ROLE_INSPECTOR"
      ) {
        this.triggerContinueAlert();
      } else {
        this.postForm();
      }
    }
  }

  postForm() {
    this.propertyDetails.buildingName = this.addPropertyForm.value.buildingName;
    this.propertyDetails.isActive = this.addPropertyForm.value.isActive;
    if (this.isPPAButActive) {
      this.propertyDetails.isActive = this.isPPAButActive;
    }
    if (this.addPropertyForm.value.occupancyType !== 'Other') {
      this.propertyDetails.propertyType = this.addPropertyForm.value.occupancyType;
      this.propertyDetails.propertySubType = this.addPropertyForm.value.occupancySubType;
    } else {
      this.propertyDetails.propertyType = this.addPropertyForm.value.otherOccupancyType;
    }
    if (this.addPropertyForm.value.constructionType !== 'Other') {
      this.propertyDetails.buildingType = this.addPropertyForm.value.constructionType;
    } else {
      this.propertyDetails.buildingType = this.addPropertyForm.value.otherContructionType;
    }
    const selectedJurisdiction = (this.zipcodeMappingDetails.jurisdictionDto.filter(
      d => d.id === +this.addPropertyForm.value.address.jurisdictionName) as Array<any>)[0] as any;
    if (!selectedJurisdiction && (this.propertyService.propertyDetails$.getValue() !== null &&
      parseInt(this.addPropertyForm.value.address.jurisdictionName) !== -1)) {
      this.propertyDetails.jurisdiction = this.propertyService.propertyDetails$.getValue().jurisdiction;
    } else {
      this.propertyDetails.jurisdiction = {
        id: parseInt(this.addPropertyForm.value.address.jurisdictionName, 10),
        name: ((selectedJurisdiction || { name: '' }).name) || 'Other',
        status: 2
      };
    }
    this.propertyDetails.createdBy = 0;
    this.propertyDetails.numberOfStories = this.addPropertyForm.value.noOfStories;
    this.propertyDetails.yearBuilt = this.addPropertyForm.value.yearBuilt;
    this.propertyDetails.floorArea = this.addPropertyForm.value.floorArea;
    this.propertyDetails.streetLine1 = this.addPropertyForm.value.address.streetLine1;
    this.propertyDetails.streetLine2 = this.addPropertyForm.value.address.streetLine2;
    this.propertyDetails.zipcode = {
      city: this.addPropertyForm.value.address.city,
      id: null,
      isApproved: null,
      state: this.addPropertyForm.value.address.state,
      zipcode: this.addPropertyForm.value.address.zipcode
    };
    if (this.zipcodeMappingDetails) {
      this.propertyDetails.zipcode.id = this.zipcodeMappingDetails.id;
    }
    this.propertyDetails.propertyContacts = [];
    if (
      this.addPropertyForm.value.manager.phoneNumber ||
      this.addPropertyForm.value.manager.emailAddress ||
      this.formSubmissionAllowedIfContactNotPresent
    ) {
      this.propertyDetails.propertyContacts = [
        {
          displaySequence: 1,
          email: this.addPropertyForm.value.manager.emailAddress,
          firstName: this.addPropertyForm.value.manager.firstName,
          lastName: this.addPropertyForm.value.manager.lastName,
          isManager: true,
          responsibility: 'Manager',
          secondaryContacts: null,
          streetLine1: this.addPropertyForm.value.manager.managerAddress
            .streetLine1,
          streetLine2: this.addPropertyForm.value.manager.managerAddress
            .streetLine2,
          phoneNum: null,
          zipcode: {
            city: this.addPropertyForm.value.manager.managerAddress.city,
            state: this.addPropertyForm.value.manager.managerAddress.state,
            zipcode: this.addPropertyForm.value.manager.managerAddress.zipcode
          }
        }];

      if (
        +this.propertyService.propertyId$.getValue() > 0 ||
        this.propertyService.propertySaved.getValue()
      ) {
        this.propertyService.propertyDetails$
          .getValue()
          .propertyContacts.forEach(x => {
            if (x.isManager) {
              this.propertyDetails.propertyContacts[0].id = x.id;
            }
          });
      }
      if (this.addPropertyForm.value.manager.phoneNumber) {
        this.propertyDetails.propertyContacts[0].phoneNum = this.addPropertyForm.value.manager.phoneNumber.replace(
          this.phoneRegex,
          '$1-$2-$3'
        );
      }

      if ((this.propertyDetails.propertyContacts[0].phoneNum !== null && this.propertyDetails.propertyContacts[0].phoneNum.length > 0)
        || (this.propertyDetails.propertyContacts[0].email !== null && this.propertyDetails.propertyContacts[0].email.length > 0)) {
        if (!this.propertyDetails.propertyContacts[0].firstName || this.propertyDetails.propertyContacts[0].firstName.length === 0) {
          this.propertyDetails.propertyContacts[0].firstName = 'PROPERTY';
          this.addPropertyForm.get('manager').patchValue({
            firstName: 'PROPERTY',
          });
        }
        if (!this.propertyDetails.propertyContacts[0].lastName || this.propertyDetails.propertyContacts[0].lastName.length === 0) {
          this.propertyDetails.propertyContacts[0].lastName = 'MANAGER';
          this.addPropertyForm.get('manager').patchValue({
            lastName: 'MANAGER'
          });
        }
      }
    }

    // if (this.otherContacts.length > 0) {
    //   for (const contact of this.otherContacts) {
    //     this.propertyDetails.propertyContacts.push(contact);
    //   }
    // }

    if (this.isEditDetail !== 'Edit') {
      if (
        +this.propertyService.propertyId$.getValue() > 0 ||
        this.propertyService.propertySaved.getValue()
      ) {
        const propertyId =
          +this.propertyService.propertyId$.getValue() > 0
            ? +this.propertyService.propertyId$.getValue()
            : this.propertyService.propertyDetails$.getValue().id;
        if (this.userRole === 'ROLE_OWNER' || this.userRole === 'ROLE_INSPECTOR') {
          const changeDetected = this.addPropertyForm.dirty;
          if (changeDetected) {
            this.propertyDetails.isPropertyActive = false;
          } else {
            this.propertyDetails.isPropertyActive = true;
          }
        }
        this.subscriptions.push(
          this.propertyService
            .putPropertyDetails(this.propertyDetails, propertyId)
            .subscribe(
              res => {
                this.creationDisabled = false;
                if (this.currentButton.trim() === 'Next') {
                  this.propertyService.propertyDetails$.next(res);
                  this.propertyService.oldProperyDetailsModel$.next({
                    ...this.propertyDetails
                  });
                  this.formData.emit(res);
                } else if (this.currentButton === 'Finish') {
                  // 1777 - Fix
                  // this.redirectToHomePage();
                  this.goBackToPreviousPage();
                }
              },
              error => {
                console.log(error);
                if (
                  error &&
                  error.error &&
                  error.error.message &&
                  error.error.message.includes('Property Already exists')
                ) {
                  // const dialogRef = this.dialog.open(DuplicatePropertyComponent, {
                  //   width: '735px',
                  //   height: '350px',
                  //   disableClose: true,
                  //   autoFocus: false
                  // });
                } else {
                  alert('Unexpected error occured');
                }
                this.currentButton = null;
              }
            )
        );
      } else {

        if (this.currentButton.trim() === 'Next' || this.currentButton.trim() === 'Finish') {
            this.checkDuplicateProperty();
          }
      }
    } else {
      if (this.userRole === 'ROLE_OWNER' || this.userRole === 'ROLE_INSPECTOR') {
        const changeDetected = this.addPropertyForm.dirty;
        if (changeDetected) {
          this.propertyDetails.isPropertyActive = false;
        } else {
          this.propertyDetails.isPropertyActive = true;
        }
      }
     this.propertyDetails.owner=this.addPropertyForm.value.owner;
      this.subscriptions.push(
        this.propertyService
          .putPropertyEditDetails(this.propertyDetails, this.propertyIdForEdit)
          .subscribe((res) => {
            if (res) {
              this.closeDialog();
              alert('Data has been updated and Recommendation has been sent to the concerned Authority.');
            }
          }));
    }


    //  rest api call to post -> this.service.postPropertyDetails(this.propertyDetails);
  }
  checkDuplicateProperty(){
    this.subscriptions.push(
      this.propertyService
        .checkDuplicateProperty(this.propertyDetails)
        .subscribe(
          res => {
            this.creationDisabled = false;
            if (!res) {

              if(this.userRole === "ROLE_AUTHORITY" || this.userRole === "ROLE_SITE_ADMIN" || this.userRole === 'ROLE_AUTHORITY_WORX' || this.userRole === 'ROLE_RFA' ){
                this.subscriptions.push(
                  this.propertyService
                    .postPropertyDetails(this.propertyDetails)
                    .subscribe(
                      res => {
                        this.creationDisabled = false;
                        if (res.streetLine1) {
                          console.log(res);
                          this.propertyService.propertySaved.next(true);
                          if (this.currentButton.trim() === 'Next') {
                            this.propertyService.propertyDetails$.next(res);
                            this.propertyService.oldProperyDetailsModel$.next({
                              ...this.propertyDetails
                            });
                            this.formData.emit(res);
                          } else if (this.currentButton.trim() === 'Finish') {
                            this.goBackToPreviousPage();
                          }
                        }
                      },
                      error => {
                        if (
                          error &&
                          error.error &&
                          error.error.message &&
                          error.error.message.includes('Property Already exists')
                        ) {
                          alert('This address is already registered with TEGRIS FireTM.');
                        } else {
                          alert('Unexpected error occured');
                        }
                       // this.currentButton = null;
                      }
                    ));
              }else{
                this.propertyService.propertySaved.next(true);
                this.propertyService.propertyDetails$.next(this.propertyDetails);
                this.propertyService.oldProperyDetailsModel$.next({
                  ...this.propertyDetails
                });
                this.creationDisabled = false;
                this.formData.emit(this.propertyDetails);
              }
            } else {
              this.duplicatePropertyID = res;
              const dialogRef = this.dialog.open(DuplicatePropertyComponent, {
                width: '735px',
                height: '350px',
                disableClose: true,
                autoFocus: false
              });

              this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed');
                if (result === 'continue') {
                  this.propertyService.propertyId$.next(this.duplicatePropertyID);
                  this.subscriptions.push(

                    this.propertyService
                      .getPropertyById(
                        this.duplicatePropertyID
                      )
                      .subscribe(
                        response => {
                          this.loading = false;
                          this.propertyService.propertyDetails$.next(response);
                          if (response) {
                            let occupancyType;
                            let occupancySubType;
                            let buildingType;
                            if (response.propertyType && this.occupancyTypeOptions) {
                              occupancyType = this.occupancyTypeOptions.find(x => {
                                return x.name === response.propertyType;
                              });
                              if (!occupancyType) {
                                occupancyType = this.occupancyTypeOptions[
                                  this.occupancyTypeOptions.length - 1
                                ];
                                this.occupancyTypeOther = true;
                              }
                              if (
                                response.propertySubType &&
                                occupancyType &&
                                occupancyType.propertySubtypes &&
                                !this.occupancyTypeOther
                              ) {
                                this.occupancySubTypeDisplay = true;
                                this.occupancySubTypeOptions =
                                  occupancyType.propertySubtypes;
                                occupancySubType = occupancyType.propertySubtypes.find(x => {
                                  return x.subtypeName === response.propertySubType;
                                });
                              } else {
                                this.occupancySubTypeDisplay = false;
                              }
                            }
                            if (response.buildingType && this.constructionTypeOptions) {
                              buildingType = this.constructionTypeOptions.find(x => {
                                return x.name === response.buildingType;
                              });
                              if (!buildingType) {
                                buildingType = { name: 'Other' };
                                this.constructionTypeOther = true;
                              }
                            }
                            this.addPropertyForm = this.fb.group({
                              buildingName: [
                                response.buildingName ? response.buildingName : ''
                              ],
                              isActive: [
                                response.isActive !== null ? response.isActive : 'true'
                              ],
                              occupancyType: [occupancyType ? occupancyType.name : ''],
                              occupancySubType: [
                                occupancySubType ? occupancySubType.subtypeName : ''
                              ],
                              otherOccupancyType: [
                                occupancyType && occupancyType.name === 'Other'
                                  ? response.propertyType
                                  : ''
                              ],
                              constructionType: [
                                buildingType ? buildingType.name : ''
                              ],
                              otherContructionType: [
                                buildingType && buildingType.name === 'Other'
                                  ? response.buildingType
                                  : ''
                              ],
                              noOfStories: [
                                response.numberOfStories || '',
                                Validators.maxLength(2)
                              ],
                              yearBuilt: [
                                response.yearBuilt || '',
                                Validators.pattern('[0-9]{4}')
                              ],
                              floorArea: [response.floorArea || ''],
                              copyAddressEdit: [false],
                              address: this.fb.group({
                                streetLine1: [
                                  response.streetLine1 || '',
                                  Validators.required
                                ],
                                streetLine2: [response.streetLine2 || ''],
                                zipcode: [
                                  response.zipcode && response.zipcode.zipcode
                                    ? response.zipcode.zipcode
                                    : '',
                                  Validators.required
                                ],
                                additionalZipcode: [''],
                                city: [
                                  response.zipcode && response.zipcode.city
                                    ? response.zipcode.city
                                    : '',
                                  Validators.required
                                ],
                                state: [
                                  response.zipcode && response.zipcode.state
                                    ? response.zipcode.state
                                    : '',
                                  Validators.required
                                ],
                                jurisdictionName: [
                                  response.jurisdiction ? response.jurisdiction.id : ''
                                ]
                              }),
                              manager: this.fb.group({
                                firstName: [
                                  response.propertyContacts &&
                                    response.propertyContacts.length > 0
                                    ? response.propertyContacts[0].firstName
                                    : ''
                                ],
                                lastName: [
                                  response.propertyContacts &&
                                    response.propertyContacts.length > 0
                                    ? response.propertyContacts[0].lastName
                                    : ''
                                ],
                                phoneNumber: [
                                  response.propertyContacts &&
                                    response.propertyContacts.length > 0
                                    ? response.propertyContacts[0].phoneNum
                                    : ''
                                ],
                                emailAddress: [
                                  response.propertyContacts &&
                                    response.propertyContacts.length > 0
                                    ? response.propertyContacts[0].email
                                    : '',
                                  Validators.pattern(
                                    '[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+'
                                  )
                                ],
                                copyAddress: [false],
                                managerAddress: this.fb.group({
                                  streetLine1: [
                                    response.propertyContacts &&
                                      response.propertyContacts.length > 0
                                      ? response.propertyContacts[0].streetLine1
                                      : ''
                                  ],
                                  streetLine2: [
                                    response.propertyContacts &&
                                      response.propertyContacts.length > 0
                                      ? response.propertyContacts[0].streetLine2
                                      : ''
                                  ],
                                  zipcode: [
                                    response.propertyContacts &&
                                      response.propertyContacts.length > 0 &&
                                      response.propertyContacts[0].zipcode &&
                                      response.propertyContacts[0].zipcode.zipcode
                                      ? response.propertyContacts[0].zipcode.zipcode
                                      : ''
                                  ],
                                  city: [
                                    response.propertyContacts &&
                                      response.propertyContacts.length > 0 &&
                                      response.propertyContacts[0].zipcode &&
                                      response.propertyContacts[0].zipcode.city
                                      ? response.propertyContacts[0].zipcode.city
                                      : ''
                                  ],
                                  state: [
                                    response.propertyContacts &&
                                      response.propertyContacts.length > 0 &&
                                      response.propertyContacts[0].zipcode &&
                                      response.propertyContacts[0].zipcode.state
                                      ? response.propertyContacts[0].zipcode.state
                                      : ''
                                  ]
                                })
                              })
                            });
                          }
                        },
                        error => {
                          console.log(error);
                          alert('An Error has Occured');
                          this.loading = false;
                          this.errorOccured.emit(true);
                        }
                      )
                  );
                } else {
                  this.propertyService.propertyId$.next(null);
                }
              }));
            }
          },
          error => {
            console.log(error);
            if (
              error &&
              error.error &&
              error.error.message &&
              error.error.message.includes('Property Already exists')
            ) {
              alert('This address is already registered with TEGRIS FireTM.');
            } else {
              alert('Unexpected error occured');
            }
            this.currentButton = null;
          }
        )
    );
  }


  openValidAddressPopup(res: string) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(res, 'text/xml');

    let stLine2: string = null;
    if (
      xmlDoc.getElementsByTagName('Address1')[0] &&
      xmlDoc.getElementsByTagName('Address1')[0].childNodes[0]
    ) {
      stLine2 = xmlDoc.getElementsByTagName('Address1')[0].childNodes[0]
        .nodeValue;
      if (stLine2 && stLine2.toLowerCase() === 'null') {
        stLine2 = null;
      }
    }

    let subZip: string = null;
    if (
      xmlDoc.getElementsByTagName('Zip4')[0] &&
      xmlDoc.getElementsByTagName('Zip4')[0].childNodes[0]
    ) {
      subZip = xmlDoc.getElementsByTagName('Zip4')[0].childNodes[0].nodeValue;
      if (subZip && subZip.toLowerCase() === 'null') {
        subZip = null;
      }
    }

    this.addPropertyForm.value.address.streetLine1 = xmlDoc.getElementsByTagName(
      'Address2'
    )[0].childNodes[0].nodeValue;
    this.addPropertyForm.value.address.streetLine2 = stLine2;
    (this.addPropertyForm.value.address.city = xmlDoc.getElementsByTagName(
      'City'
    )[0].childNodes[0].nodeValue),
      (this.addPropertyForm.value.address.state = xmlDoc.getElementsByTagName(
        'State'
      )[0].childNodes[0].nodeValue),
      (this.addPropertyForm.value.address.zipcode = xmlDoc.getElementsByTagName(
        'Zip5'
      )[0].childNodes[0].nodeValue),
      (this.addPropertyForm.value.address.additionalZipcode = subZip);


    const dialogRef = this.dialog.open(USPSValidPopUpComponent, {
      width: '735px',
      height: '350px',
      data: {
        streetLine1: this.addPropertyForm.value.address.streetLine1,
        streetLine2: stLine2,
        city: this.addPropertyForm.value.address.city,
        state: this.addPropertyForm.value.address.state,
        zipcode: this.addPropertyForm.value.address.zipcode,
        subzipcode: subZip,
        manager: false
      },
      disableClose: true,
      autoFocus: false
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe(result => {
        this.addressUspsDone = true;
        if (result === 'continue') {
          this.addPropertyForm.value.address.streetLine1 = xmlDoc.getElementsByTagName(
            'Address2'
          )[0].childNodes[0].nodeValue;
          this.addPropertyForm.value.address.streetLine2 = stLine2;
          this.addPropertyForm.value.address.city = xmlDoc.getElementsByTagName(
            'City'
          )[0].childNodes[0].nodeValue;
          this.addPropertyForm.value.address.state = xmlDoc.getElementsByTagName(
            'State'
          )[0].childNodes[0].nodeValue;
          this.addPropertyForm.value.address.zipcode = xmlDoc.getElementsByTagName(
            'Zip5'
          )[0].childNodes[0].nodeValue;
          this.addPropertyForm.value.address.additionalZipcode = subZip;
          if(this.changeInManagerAddress) {
            this.validateManagerAddressFromUsps();
          }
          this.prePostForm();
        } else if (result === 'cancelled') {
          this.currentButton = null;
          this.creationDisabled = false;
        }
      })
    );
    //  rest api call to post -> this.service.postPropertyDetails(this.propertyDetails);
  }
  openInvalidAddressPopUp(manager: boolean = false) {
    let data;
    if(manager) {
      data = {
        manager: true
      }
    }else {
      data = {
        manager: false
      }
    }
    const dialogRef = this.dialog.open(USPSInvalidPopUpComponent, {
      width: '600px',
      height: '300px',
      disableClose: true,
      autoFocus: false,
      data: data
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe(result => {
        if(manager) {
          this.managerAddressUspsDone = true;
        }else {
          this.addressUspsDone = true;
        }
        if (result === 'abort') {
          // 2521 - fix
          this.location.back();
        } else if (result === 'try again') {
          this.creationDisabled = false;
        } else if (result === 'cancel') {
          this.currentButton = null;
          this.creationDisabled = false;
        } else if (result === 'continue') {
          if(!manager) {
            if(this.changeInManagerAddress) {
              this.validateManagerAddressFromUsps();
            }
          }
          this.prePostForm();
        }
      })
    );
  }

  resetFunction() {
    this.propertyService.isPropAddressValidated$.next(false)
    if (
      this.propertyService.propertyId$.getValue() ||
      this.propertyService.propertySaved.getValue()
    ) {
      this.subscriptions.push(
        this.propertyService
          .getPropertyById(
            this.propertyService.propertyId$.getValue().toString()
          )
          .subscribe(response => {
            this.loading = false;
            this.propertyService.propertyDetails$.next(response);
            if (response) {
              let occupancyType;
              let occupancySubType;
              let buildingType;
              if (response.propertyType && this.occupancyTypeOptions) {
                occupancyType = this.occupancyTypeOptions.find(x => {
                  return x.name === response.propertyType;
                });
                if (
                  response.propertySubType &&
                  occupancyType &&
                  occupancyType.propertySubtypes
                ) {
                  this.occupancySubTypeDisplay = true;
                  this.occupancySubTypeOptions =
                    occupancyType.propertySubtypes;
                  occupancySubType = occupancyType.propertySubtypes.find(
                    x => {
                      return x.subtypeName === response.propertySubType;
                    }
                  );
                } else {
                  this.occupancySubTypeDisplay = false;
                }
              }
              if (response.buildingType) {
                buildingType = this.constructionTypeOptions.find(x => {
                  return x.name === response.buildingType;
                });
              }
              this.addPropertyForm = this.fb.group({
                buildingName: [
                  response.buildingName ? response.buildingName : ''
                ],
                isActive: [response.isActive !== null ? response.isActive : 'true'],
                occupancyType: [occupancyType ? occupancyType.name : ''],
                occupancySubType: [
                  occupancySubType ? occupancySubType.subtypeName : ''
                ],
                otherOccupancyType: [''],
                constructionType: [
                  buildingType && buildingType[0] ? buildingType[0].id : ''
                ],
                otherContructionType: [],
                noOfStories: [
                  response.numberOfStories || '',
                  Validators.maxLength(2)
                ],
                yearBuilt: [
                  response.yearBuilt || '',
                  Validators.pattern('[0-9]{4}')
                ],
                floorArea: [response.floorArea || ''],
                address: this.fb.group({
                  streetLine1: [
                    response.streetLine1 || '',
                    Validators.required
                  ],
                  streetLine2: [response.streetLine2 || ''],
                  zipcode: [
                    response.zipcode && response.zipcode.zipcode
                      ? response.zipcode.zipcode
                      : '',
                    Validators.required
                  ],
                  additionalZipcode: [''],
                  city: [
                    response.zipcode && response.zipcode.city
                      ? response.zipcode.city
                      : '',
                    Validators.required
                  ],
                  state: [
                    response.zipcode && response.zipcode.state
                      ? response.zipcode.state
                      : '',
                    Validators.required
                  ],
                  jurisdictionName: [
                    response.jurisdiction ? response.jurisdiction.id : ''
                  ]
                }),
                manager: this.fb.group({
                  firstName: [
                    response.propertyContacts &&
                      response.propertyContacts.length > 0
                      ? response.propertyContacts[0].firstName
                      : ''
                  ],
                  lastName: [
                    response.propertyContacts &&
                      response.propertyContacts.length > 0
                      ? response.propertyContacts[0].lastName
                      : ''
                  ],
                  phoneNumber: [
                    response.propertyContacts &&
                      response.propertyContacts.length > 0
                      ? response.propertyContacts[0].phoneNum
                      : ''
                  ],
                  emailAddress: [
                    response.propertyContacts &&
                      response.propertyContacts.length > 0
                      ? response.propertyContacts[0].email
                      : '',
                    Validators.pattern(
                      '[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+'
                    )
                  ],
                  copyAddress: [false],
                  managerAddress: this.fb.group({
                    streetLine1: [
                      response.propertyContacts &&
                        response.propertyContacts.length > 0
                        ? response.propertyContacts[0].streetLine1
                        : ''
                    ],
                    streetLine2: [
                      response.propertyContacts &&
                        response.propertyContacts.length > 0
                        ? response.propertyContacts[0].streetLine2
                        : ''
                    ],
                    zipcode: [
                      response.propertyContacts &&
                        response.propertyContacts.length > 0 &&
                        response.propertyContacts[0].zipcode &&
                        response.propertyContacts[0].zipcode.zipcode
                        ? response.propertyContacts[0].zipcode.zipcode
                        : ''
                    ],
                    city: [
                      response.propertyContacts &&
                        response.propertyContacts.length > 0 &&
                        response.propertyContacts[0].zipcode &&
                        response.propertyContacts[0].zipcode.city
                        ? response.propertyContacts[0].zipcode.city
                        : ''
                    ],
                    state: [
                      response.propertyContacts &&
                        response.propertyContacts.length > 0 &&
                        response.propertyContacts[0].zipcode &&
                        response.propertyContacts[0].zipcode.state
                        ? response.propertyContacts[0].zipcode.state
                        : ''
                    ]
                  })
                })
              });
            }
          })
      );
    } else {
      this.addPropertyForm = this.fb.group({
        buildingName: [''],
        isActive: ['true'],
        occupancyType: [''],
        occupancySubType: [],
        otherOccupancyType: [],
        constructionType: [''],
        otherContructionType: [],
        noOfStories: ['', Validators.maxLength(2)],
        yearBuilt: ['', Validators.pattern('[0-9]{4}')],
        floorArea: [],
        address: this.fb.group({
          streetLine1: ['', Validators.required],
          streetLine2: [''],
          zipcode: ['', Validators.required],
          additionalZipcode: [''],
          city: ['', Validators.required],
          state: ['', Validators.required],
          jurisdictionName: [-1]
        }),
        manager: this.fb.group({
          firstName: [],
          lastName: [],
          phoneNumber: [''],
          emailAddress: [
            '',
            Validators.pattern('[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+')
          ],
          copyAddress: [false],
          managerAddress: this.fb.group({
            streetLine1: [],
            streetLine2: [],
            zipcode: [],
            city: [],
            state: []
          })
        })
      });
      this.resetErrorsMap();
      if (this.addPropertyForm.value.address.jurisdictionName == null) {
        this.addPropertyForm.value.address.jurisdictionName = -1;
      }
      this.checkOtherType('');
    }
  }

  // updateManagerContactsFlag() {
  // if (this.addPropertyForm.value.manager.firstName
  //   && this.addPropertyForm.value.manager.firstName.length > 0
  //   && this.addPropertyForm.value.manager.lastName
  //   && this.addPropertyForm.value.manager.lastName.length > 0) {
  //   this.allowManagerPopulate = true;
  // } else {
  //   this.allowManagerPopulate = false;
  //   this.addPropertyForm.get('manager').patchValue({
  //     emailAddress: null,
  //     phoneNumber: null
  //   });
  //   // this.propertyContact
  // }
  // }

  getCitiesByState(StateID) {

    // this.addPropertyForm.value.address.state = this.states.find(item => {
    //   return item.id == StateID;
    // }).id;
    // this.subscriptions.push(
    //   this.propertyService.getCitiesByState(StateID).subscribe(
    //     res => {
    //       this.cities = res;
    //       this.selectedCityID = this.cities[0].data;
    //       this.setCity(this.selectedCityID);
    //     }
    //   )
    // );
  }

  setCity(cityID) {
    this.addPropertyForm.value.address.city = this.cities.find(item => {
      return item.data == cityID;
    }).data;
  }

  populateManagerCityState(val: any) {
    this.readOnlyManagerCity = false;
    this.readOnlyManagerState = false;
    let zipcode = this.addPropertyForm.value.manager.managerAddress.zipcode;
    this.isCanadianZipCodeManager = false;

    if (zipcode.length === 6) {
      zipcode = zipcode.toUpperCase();
      zipcode = zipcode.substr(0, 3) + ' ' + zipcode.substr(3, 3);
      this.addPropertyForm.value.manager.managerAddress.zipcode = zipcode;
    }

    if (this.canadinaZipRegex.test(zipcode)) {
      this.isCanadianZipCodeManager = true;
    }
    if (zipcode && zipcode.length > 0) {
      this.subscriptions.push(
        this.propertyService
          .getJurisdictionDetailsFromZipcode(zipcode)
          .subscribe(data => {
            if (data) {
              if (data && data.city && data.state) {
                this.readOnlyManagerCity = true;
                this.readOnlyManagerState = true;
                this.addPropertyForm.get('manager').get('managerAddress').patchValue({
                  state: data.state,
                  city: data.city
                });
              }
              // else if (!this.isCanadianZipCodeManager) {
              //   this.getManagerCitiesByState(this.selectedManagerStateID);
              // }
            }
            // else if (!this.isCanadianZipCodeManager) {
            //   this.getManagerCitiesByState(this.selectedManagerStateID);
            // }
          }, err => {
            console.log(err);
          })
      );
    } else {
      this.addPropertyForm.get('manager').get('managerAddress').patchValue({
        state: "",
        city: ""
      });
      this.readOnlyManagerCity = true;
      this.readOnlyManagerState = true;
    }
  }

  getManagerCitiesByState(StateID) {

    // if (StateID) {
    //   this.addPropertyForm.value.manager.managerAddress.state = this.states.find(item => {
    //     return item.id === StateID;
    //   }).id;
    //   this.subscriptions.push(
    //     this.propertyService.getCitiesByState(StateID).subscribe(
    //       res => {
    //         this.managerCities = res;
    //         this.selectedManagerCityID = this.managerCities[0].data;
    //         this.setManagerCity(this.selectedManagerCityID);
    //       }
    //     )
    //   );
    // } else {
    //   // this.selectedManagerStateID = null;
    //   // this.selectedManagerCityID = null;
    //   this.managerCities = [];
    //   this.addPropertyForm.get('manager').get('managerAddress').patchValue({
    //     city: null,
    //     state: null
    //   });
    // }
  }

  setManagerCity(cityID) {
    const cityVar = this.managerCities.find(item => {
      return item.data === cityID;
    }).data;
    this.addPropertyForm.get('manager').get('managerAddress').patchValue({
      city: cityVar
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => {
      x.unsubscribe();
    });
  }

  closeDialog() {
    this.close.emit();
  }
  openOwnerBox() {
    const a = this.dialog.open(ChangeOwnerComponent, {
      width: '800px',
      maxHeight: '99vh',
      // height: '800px',
      autoFocus: false
    });

    this.subscriptions.push(a.afterClosed().subscribe(res => {
      const owner = this.fileCabService.getOwnerDetails();
      setTimeout(() => {
        this.addPropertyForm.patchValue({
          owner: {
            name: owner['firstName'] + ' ' + owner['lastName'],
            number: owner['contactNumber'],
            address: owner['address'],
            email: owner['email']
          }
        });
      });
    }));
  }
}
