import { OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MatTableDataSource, } from "@angular/material";
import { ConfirmationPopupDialogDeleteComponent } from "../../../inspector/shared/confirmation-popup-dialog-delete/confirmation-popup-dialog-delete.component";
var ViewPreviousReportsComponent = /** @class */ (function () {
    function ViewPreviousReportsComponent(dialogRef, inspectService, dialog, inspectionSystem) {
        this.dialogRef = dialogRef;
        this.inspectService = inspectService;
        this.dialog = dialog;
        this.inspectionSystem = inspectionSystem;
        this.subscriptions = [];
        this.dataSource = new MatTableDataSource([]);
        this.inspectionSystemInfo = {};
        this.unsub = [];
    }
    ViewPreviousReportsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userRoleId = JSON.parse(localStorage.getItem("userDetails")).role.id;
        this.userRoleName = JSON.parse(localStorage.getItem("userDetails")).role.name;
        this.columnsToDisplay = [
            "inspectReportNo",
            "inspectDate",
            "inspectCompany",
            "actions",
        ];
        if (this.inspectionSystem.sys.inspectionReport.previousReports &&
            this.inspectionSystem.sys.inspectionReportNumber) {
            this.inspectionSystem.sys.inspectionReport.previousReports =
                this.inspectionSystem.sys.inspectionReport.previousReports.filter(function (report) {
                    return report.inspectionReportNumber.toString() ===
                        _this.inspectionSystem.sys.inspectionReportNumber.toString();
                }) || [];
        }
        if (this.inspectionSystem.sys.inspectionReport.previousReports &&
            this.inspectionSystem.sys.inspectionReport.previousReports.length > 0 &&
            this.inspectionSystem.sys.backflowSerialNumber &&
            this.inspectionSystem.sys.backflowSerialNumber.length > 0) {
            this.inspectionSystem.sys.inspectionReport.previousReports =
                this.inspectionSystem.sys.inspectionReport.previousReports.filter(function (report) {
                    return report.backflowSerialNo.toLowerCase() ===
                        _this.inspectionSystem.sys.backflowSerialNumber.toLowerCase();
                }) || [];
        }
        this.inspectionSystem.sys.inspectionReport.previousReports.sort(function (a, b) {
            return b.inspectionReportNumber - a.inspectionReportNumber;
        });
        this.dataSource.data =
            this.inspectionSystem.sys.inspectionReport.previousReports || [];
        this.inspectionSystemInfo = this.inspectionSystem.sys.inspectionReport;
    };
    ViewPreviousReportsComponent.prototype.ngOnDestroy = function () {
        this.unsub.forEach(function (res) { return res.unsubscribe(); });
    };
    ViewPreviousReportsComponent.prototype.close = function () {
        this.dialogRef.close(null);
    };
    ViewPreviousReportsComponent.prototype.viewReport = function (report) {
        window.open(window.location.origin + "/printReport?propId=" + this.inspectionSystem.prop.id + "&inspectedBy=" + this.inspectionSystemInfo.inspectedById + "&previousResultId=" + report.inspectionResultId + "&id=" + this.inspectionSystem.sys.assetTypeId, "_blank");
    };
    ViewPreviousReportsComponent.prototype.deleteInspectionReport = function (reports) {
        var _this = this;
        // this.addToHistory(property.id);
        var dialogRef = this.dialog.open(ConfirmationPopupDialogDeleteComponent, {
            width: "500px",
            minWidth: "350px",
            height: "300px",
            autoFocus: false,
            disableClose: true,
            data: "Are you sure you want to delete this report?",
        });
        dialogRef.afterClosed().subscribe(function (res) {
            if (res) {
                _this.subscriptions.push(_this.inspectService.deleteInspectionPrevious(0, reports.inspectionResultId, res.comments).subscribe(function (result) {
                    alert("Report Deleted");
                    _this.inspectService.getPreviousReports(_this.inspectionSystem.sys.inspectionReport.propertyAssetId).subscribe(function (res) {
                        if (res.previousReports != null) {
                            _this.dataSource.data = res.previousReports || [];
                            _this.dataSource.data = _this.sortData(_this.dataSource.data);
                            //this.dataSource.data = this.dataSource.data.sort((a,b) => new Date(b.inspectedDate) - new Date(a.inspectedDate));
                        }
                        else {
                            _this.close();
                        }
                    });
                    //this.searchFileCabinetProperties();
                    //this.ngOnInit();
                    //this.close();
                }, function (error) {
                    if (error.error && error.error.message) {
                        alert(error.error.message);
                    }
                    else {
                        alert("Unexpected Error");
                    }
                }));
            }
        });
    };
    ViewPreviousReportsComponent.prototype.sortData = function (data) {
        return data.sort(function (a, b) {
            return new Date(b.inspectedDate) - new Date(a.inspectedDate);
        });
    };
    return ViewPreviousReportsComponent;
}());
export { ViewPreviousReportsComponent };
