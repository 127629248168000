<table cellspacing="0" cellpadding="0" class="fire-dampner" width="100%" #fireSmokeDamperTable>
  <tbody>
    <tr class="header-tr">
      <td class="header-td text-center" colspan="10">DAMPER IDENTIFICATION
      </td>
    </tr>
    <tr>
      <td width="11%" class="text-center color-grey"><strong>Damper ID#:</strong></td>
      <td width="13%" class="text-center color-grey"><strong>Acc. Type:</strong></td>
      <td width="13%" class="text-center color-grey"><strong>Type:</strong></td>
      <td width="11%" class="text-center color-grey"><strong>Location:</strong></td>
      <td width="10%" class="text-center color-grey"><strong>Test 1</strong></td>
      <td width="10%" class="text-center color-grey"><strong>Test 2</strong></td>
      <td width="10%" class="text-center color-grey"><strong>Test 3</strong></td>
      <td width="16%" class="text-center color-grey"><strong>Comments:</strong></td>
      <td width="7%" class="text-center color-grey" *ngIf="!reviewInspection">&nbsp;&nbsp;&nbsp;</td>
    </tr>

    <tr *ngFor="let k of fireAndSmokeTable ;let i = index" class="damper-data">

      <!-- Damper ID -->
      <td width="10%" valign="top">
        <input (change)="updateModel('Damper ID#:', i, k, 'id')" class="damper-input" [class.warning-label]="isError && k.id == ''" type="text" name="" id="" [(ngModel)]="k.id" [disabled]="reviewInspection">
      </td>

      <!-- Account Type -->
      <td width="13%" valign="top">
        <select [class.disabled-select]="reviewInspection" (change)="updateModel('Acc. Type:', i, k, 'accountType')" name="" id="" class="custom-select-fsd" [(ngModel)]="k.accountType" [class.warning-label]="isError && k.accountType == ''">
          <option [disabled]="reviewInspection" *ngFor="let o of accountTypeOptions" [value]="o.id" [selected]="o.isSelected && !reviewInspection">{{o.label}}</option>
        </select>
        <input *ngIf="k.accountType.toLowerCase() === 'other'" class="damper-input mt-2 warning-label" [class.warning-label]="isError && k.otherAccountType == ''" type="text" [(ngModel)]="k.otherAccountType" (change)="updateModel('Acc. Type:', i, k, 'accountType')" [disabled]="reviewInspection">
        <div class="the-count">&nbsp;</div>
      </td>

      <!-- Type -->
      <td width="13%" valign="top">
        <select [class.disabled-select]="reviewInspection" (change)="updateModel('Type:', i, k, 'fireAndDamperType')" name="" id="" class="custom-select-fsd" [class.warning-label]="isError && k.fireAndDamperType == ''"
          [(ngModel)]="k.fireAndDamperType" >
          <option [disabled]="reviewInspection" *ngFor="let o of typeOption" [value]="o.id" [selected]="o.isSelected && !reviewInspection">{{o.label}}</option>
        </select>
        <input [disabled]="reviewInspection" *ngIf="k.fireAndDamperType.toLowerCase() === 'other'" class="damper-input mt-2 warning-label" [class.warning-label]="isError && k.otherFireAndDamperType == ''" type="text" [(ngModel)]="k.otherFireAndDamperType" (change)="updateModel('Type:', i, k, 'fireAndDamperType')">
        <div class="the-count">&nbsp;</div>
      </td>

      <!-- Location -->
      <td width="10%" valign="top">
        <input [disabled]="reviewInspection" (change)="updateModel('Location:', i, k, 'location')" class="damper-input " type="text" name="" id="" [(ngModel)]="k.location" [class.warning-label]="isError && k.location == ''"
          maxlength="250">
        <div class="the-count">
          <span>{{k.location.length}}</span>
          <span>/ 250</span>
        </div>
      </td>

      <!-- Test 1 -->
      <td width="8%" valign="top">
        <div class="row">
          <div class="col margin-7">
            <input [disabled]="reviewInspection" (change)="updateModel('Test 1', i, k, 'testOne')" type="radio" value="Passed" [name]="'type1-'+i"
              [(ngModel)]="k.testOne">&nbsp;<label [class.black-label]="reviewInspection" [class.warning-label]="isError && k.testOne == ''">Passed</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input [disabled]="reviewInspection" (change)="updateModel('Test 1', i, k, 'testOne')" type="radio" value="Failed" [name]="'type1-'+i"
              [(ngModel)]="k.testOne">&nbsp;<label [class.black-label]="reviewInspection" [class.warning-label]="isError && k.testOne == ''">Failed</label>
          </div>
        </div>
      </td>

      <!-- Test 2 -->
      <td width="8%" valign="top">
        <div class="row">
          <div class="col margin-7">
            <input [disabled]="reviewInspection" (change)="updateModel('Test 2', i, k, 'testTwo')" type="radio" value="Passed" [name]="'type2-'+i"
              [(ngModel)]="k.testTwo">&nbsp;<label [class.black-label]="reviewInspection" [class.warning-label]="isError && k.testTwo == ''">Passed</label>
          </div> 
        </div>
        <div class="row">
          <div class="col">
            <input [disabled]="reviewInspection" (change)="updateModel('Test 2', i, k, 'testTwo')" type="radio" value="Failed" [name]="'type2-'+i"
              [(ngModel)]="k.testTwo">&nbsp;<label [class.black-label]="reviewInspection" [class.warning-label]="isError && k.testTwo == ''">Failed</label>
          </div>
        </div>
      </td>

      <!-- Test 3 -->
      <td width="8%" valign="top">
        <div class="row">
          <div class="col margin-7">
            <input [disabled]="reviewInspection" (change)="updateModel('Test 3', i, k, 'testThree')" type="radio" value="Passed" [name]="'type3-'+i"
              [(ngModel)]="k.testThree">&nbsp;<label [class.black-label]="reviewInspection" [class.warning-label]="isError && k.testThree == ''">Passed</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input [disabled]="reviewInspection" (change)="updateModel('Test 3', i, k, 'testThree')" type="radio" value="Failed" [name]="'type3-'+i"
              [(ngModel)]="k.testThree">&nbsp;<label [class.black-label]="reviewInspection" [class.warning-label]="isError && k.testThree == ''">Failed</label>
          </div>
        </div>
      </td>

      <!-- Comments -->
      <td width="15%" valign="top">
        <input [disabled]="reviewInspection" (change)="updateModel('Comments:', i, k, 'comments')" class="damper-input " type="text" name="" id="" [(ngModel)]="k.comments"
          maxlength="250">
        <div class="the-count">
          <span>{{k.comments.length}}</span>
          <span>/ 250</span>
        </div>
      </td>

      <!-- Actions -->
      <td width="7%" *ngIf="!reviewInspection">
        <button *ngIf="fireAndSmokeTable.length > 1" type="button" class="cust-but-damper"
          (click)="removeRow(i)">Delete</button>
        <button *ngIf="i+1 == fireAndSmokeTable.length" type="button" class="cust-but-damper"
          (click)="addNewRow()">Add</button>
        <br>
        <div class="the-count">&nbsp;</div>
      </td>
    </tr>
  </tbody>
</table>

<div class="row" style="margin-bottom: 2%;">
  <div class="col-12">
    <div *ngIf="showErrorMessage && isErrorInTable" class="alert alert-danger error">
      * Please enter missing damper identifications
    </div>
  </div>
</div>

<div class="row" #fireSmokeDamperBinderquestion>
  <div class="col-6">
    <label class="inputLabel  inspection">Binder left on-site?<label style="color: red;">*</label></label>
    <!-- Binder left on-site? -->
  </div>
  <div class="col-6">
    <mat-radio-group class="example-radio-group" id="binderOnSite" name="binderleftonsite" [(ngModel)]="binderQuestion" (change)="selectBinderCheckbox()" [disabled]="reviewInspection">
      <mat-radio-button class="example-radio-button" value="Yes">
        Yes&nbsp;
      </mat-radio-button>
      <mat-radio-button class="example-radio-button" value="No">
        No&nbsp;
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div *ngIf="isError && (binderQuestion == null || binderQuestion == '')" class="alert alert-danger error">
      * Please select appropriate option
    </div>
  </div>
</div>