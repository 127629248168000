<app-dialog>

  <span class="dialog-title">
    Search Owner
    <span class="float-right">
      <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
    </span>
  </span>

  <div class="dialog-content">

      <div class="row form-group-padding">
        <div class="col-12">
          <div dialog-content class="notes-sub-heading">New owner must exist in the system.</div>
        </div>
      </div>
      <div class="row form-group-padding">
        <div class="col-2 notes-sub-heading">Search</div>
        <div class="col-6">
          <input type="text" class="col-11 col-form-label col-form-label-sm rounded-form-fields"
            [(ngModel)]="searchText" (keydown.enter)="resetPagination(); search()" />
        </div>
        <button (click)="resetPagination(); search()" class="custom-buttons" style="margin: 0px !important;">Go</button>
      </div>

      <div class="tableData">
        <table mat-table #table [dataSource]="dataSource" class="mat-elevation-z8">

          <ng-container matColumnDef="blank">
            <mat-header-cell *matHeaderCellDef class="blank-head"></mat-header-cell>
            <mat-cell class="blank-head" *matCellDef="let owner;let i = index;">
              <input type="radio" [value]="owner.id" name="userId" [(ngModel)]="selectedOwnerId" />
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef class="user-name">Name</mat-header-cell>
            <mat-cell *matCellDef="let owner; let i = index;" class="user-name">
              <label class="tableLabel">
                {{owner.name}}
              </label>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="address" class="user-address">
            <mat-header-cell *matHeaderCellDef class="user-address">Address</mat-header-cell>
            <mat-cell *matCellDef="let owner; let i = index;">
              <label class="tableLabel" [innerHTML]="owner.address">
              </label>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
          <mat-row *matRowDef="let element; columns: columnsToDisplay;let i = dataIndex" class="example-element-row">
          </mat-row>
        </table>
        <mat-paginator #owners [pageSize]="environment.pageSize" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons
          (page)="pageEvent = $event; onPaginateChange($event)"
          [length]="dataSource.data.length">
        </mat-paginator>

      </div>


  </div>

  <div class="dialog-actions">
    <div class="form-group row form-group-padding actions">
      <button class="custom-buttons" *ngIf="data!=null" (click)="removeOwner()">Remove Owner</button>
      <button class="custom-buttons" (click)="selectOwner()">Select Owner</button>
      <button class="custom-buttons" (click)="close()">Cancel</button>
    </div>
  </div>

</app-dialog>
