import { ErrorHandler } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "../logger/logger.service";
/**
 * Service to handle exceptions and error
 */
var ExceptionHandlerService = /** @class */ (function () {
    /**
     * ExceptionHandlerService constructor
     * @param logger LoggerService to log the values
     */
    function ExceptionHandlerService(logger) {
        this.logger = logger;
    }
    /**
     * All the exceptions and errors will be handled by this function
     * @param error Error
     */
    ExceptionHandlerService.prototype.handleError = function (error) {
        /**
         * TODO: Add any further custom logic for error handling
         * Default: Printing log to server in case of production and on console in case of development
         */
        this.logger.logToServer(error);
    };
    ExceptionHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExceptionHandlerService_Factory() { return new ExceptionHandlerService(i0.ɵɵinject(i1.LoggerService)); }, token: ExceptionHandlerService, providedIn: "root" });
    return ExceptionHandlerService;
}());
export { ExceptionHandlerService };
