import { OnInit } from "@angular/core";
var InspectorStatisticsComponent = /** @class */ (function () {
    function InspectorStatisticsComponent(inspectorService, paymentService, router) {
        this.inspectorService = inspectorService;
        this.paymentService = paymentService;
        this.router = router;
        this.user = null;
        this.latestBidNotification = null;
    }
    InspectorStatisticsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user = JSON.parse(localStorage.getItem("userDetails"));
        setTimeout(function () {
            _this.inspectorService.getLatestBidOpportunity().subscribe(function (res) {
                if (res && res.id) {
                    _this.latestBidNotification = res;
                }
            }, function (error) {
                // TODO: Handle error
                console.log(error);
            });
        }, 1000);
        this.getInspectorStatistics();
        this.paymentService.creditSub().subscribe(function (res) {
            _this.getInspectorStatistics();
        });
    };
    InspectorStatisticsComponent.prototype.getInspectorStatistics = function () {
        var _this = this;
        this.inspectorService
            .getPropertyStatistics()
            .subscribe(function (res) { return (_this.statistics = res); });
    };
    InspectorStatisticsComponent.prototype.purchaseCredits = function () { };
    InspectorStatisticsComponent.prototype.navigateToBidOppurtunity = function () {
        this.router.navigate(["./user/inspector/bidOpportunity"], {
            queryParams: { propertyId: "" },
        });
    };
    return InspectorStatisticsComponent;
}());
export { InspectorStatisticsComponent };
