<app-dialog>
    <span class="dialog-title">
      Certification
      <span class="float-right">
        <mat-icon class="cursor-pointer" (click)="cancel()">close</mat-icon>
      </span>
    </span>
    <div class="dialog-content">
        <input type="checkbox" [(ngModel)]="checked" class="col-1" style="
          float: left;margin-top: 2vh;height: 25px">
        <label class="col-form-label col-form-label-sm inputLabel notes-sub-heading col-11">
            "By clicking here you are certifying, under penalties of perjury, that this property owner has specifically granted
            you access for the sole purpose of inputting an inspection report for which you are responsible for and that you
            understand and will adhere to the terms of use in TEGRIS Fire. Your bypassed access is being tracked in the system
            and will be monitored for misuse."
          </label>
    </div>
    <div class="dialog-actions" >
      <button class="custom-buttons" (click)="certify()">Submit</button>
      <button class="custom-buttons" (click)="cancel()">Cancel</button>
    </div>
  </app-dialog>

