/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/components/app-dialog/app-dialog.component.ngfactory";
import * as i2 from "../../../shared/components/app-dialog/app-dialog.component";
import * as i3 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "@angular/common";
import * as i6 from "./confirmation-dialog.component";
import * as i7 from "@angular/material/dialog";
var styles_ConfirmationDialog = [];
var RenderType_ConfirmationDialog = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmationDialog, data: {} });
export { RenderType_ConfirmationDialog as RenderType_ConfirmationDialog };
function View_ConfirmationDialog_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "custom-buttons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.areYouSureYes() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.data == null) ? null : _co.data.yesButton) ? _co.data.yesButton : "Yes"); _ck(_v, 1, 0, currVal_0); }); }
export function View_ConfirmationDialog_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "app-dialog", [], null, null, null, i1.View_AppDialogComponent_0, i1.RenderType_AppDialogComponent)), i0.ɵdid(1, 114688, null, 0, i2.AppDialogComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 5, "span", [["class", "dialog-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "span", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "mat-icon", [["class", "cursor-pointer mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.areYouSureNo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i0.ɵdid(6, 9158656, null, 0, i4.MatIcon, [i0.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], null, null), (_l()(), i0.ɵted(-1, 0, ["close"])), (_l()(), i0.ɵeld(8, 0, null, 1, 2, "div", [["class", "dialog-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "label", [["class", "col-form-label col-form-label-sm inputLabel notes-sub-heading"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, 2, 4, "div", [["class", "dialog-actions"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmationDialog_1)), i0.ɵdid(13, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(14, 0, null, null, 1, "button", [["class", "custom-buttons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.areYouSureNo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(15, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 6, 0); var currVal_4 = (((_co.data == null) ? null : _co.data.yesButton) !== "do not show"); _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.data.title || "Confirmation"); _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 6).inline; var currVal_2 = (((i0.ɵnov(_v, 6).color !== "primary") && (i0.ɵnov(_v, 6).color !== "accent")) && (i0.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = (((_co.data == null) ? null : _co.data.content) ? _co.data.content : _co.data); _ck(_v, 10, 0, currVal_3); var currVal_5 = (((_co.data == null) ? null : _co.data.noButton) ? _co.data.noButton : "No"); _ck(_v, 15, 0, currVal_5); }); }
export function View_ConfirmationDialog_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "confirmation-dialog", [], null, null, null, View_ConfirmationDialog_0, RenderType_ConfirmationDialog)), i0.ɵdid(1, 114688, null, 0, i6.ConfirmationDialog, [i7.MatDialogRef, i7.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmationDialogNgFactory = i0.ɵccf("confirmation-dialog", i6.ConfirmationDialog, View_ConfirmationDialog_Host_0, {}, {}, []);
export { ConfirmationDialogNgFactory as ConfirmationDialogNgFactory };
