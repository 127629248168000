/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-cabinet-history.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/components/app-dialog/app-dialog.component.ngfactory";
import * as i3 from "../../shared/components/app-dialog/app-dialog.component";
import * as i4 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "@angular/common";
import * as i7 from "./file-cabinet-history.component";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../core/services/file-cabinet/file-cabinet.service";
var styles_FileCabinetHistoryComponent = [i0.styles];
var RenderType_FileCabinetHistoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileCabinetHistoryComponent, data: {} });
export { RenderType_FileCabinetHistoryComponent as RenderType_FileCabinetHistoryComponent };
function View_FileCabinetHistoryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [["class", "tableLabel"], ["style", "cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.searchInFileCabinet(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "label", [["class", "tableLabel"], ["style", "cursor: pointer;"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.searchInFileCabinet(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "label", [["class", "tableLabel"], ["style", "cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.searchInFileCabinet(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.propertyName; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_v.context.$implicit.address || ""); _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.accessCode; _ck(_v, 8, 0, currVal_2); }); }
export function View_FileCabinetHistoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "app-dialog", [], null, null, null, i2.View_AppDialogComponent_0, i2.RenderType_AppDialogComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppDialogComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "span", [["class", "dialog-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" File Cabinet History "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "mat-icon", [["class", "cursor-pointer mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(8, 0, null, 1, 10, "div", [["class", "dialog-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 9, "table", [["class", "def-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 6, "tr", [["class", "table-head"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Name "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Address"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Access Code"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileCabinetHistoryComponent_1)), i1.ɵdid(18, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(19, 0, null, 2, 2, "div", [["class", "dialog-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "button", [["class", "custom-buttons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 6, 0); var currVal_2 = _co.history; _ck(_v, 18, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).inline; var currVal_1 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_0, currVal_1); }); }
export function View_FileCabinetHistoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-file-cabinet-history", [], null, null, null, View_FileCabinetHistoryComponent_0, RenderType_FileCabinetHistoryComponent)), i1.ɵdid(1, 245760, null, 0, i7.FileCabinetHistoryComponent, [i8.MatDialogRef, i8.MAT_DIALOG_DATA, i9.FileCabinetService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileCabinetHistoryComponentNgFactory = i1.ɵccf("app-file-cabinet-history", i7.FileCabinetHistoryComponent, View_FileCabinetHistoryComponent_Host_0, {}, {}, []);
export { FileCabinetHistoryComponentNgFactory as FileCabinetHistoryComponentNgFactory };
