/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./electric-devices/electric-devices.component.ngfactory";
import * as i2 from "./electric-devices/electric-devices.component";
import * as i3 from "./other-devices/other-devices.component.ngfactory";
import * as i4 from "./other-devices/other-devices.component";
import * as i5 from "./communication-devices/communication-devices.component.ngfactory";
import * as i6 from "./communication-devices/communication-devices.component";
import * as i7 from "./clean-agent.component";
var styles_CleanAgentComponent = [];
var RenderType_CleanAgentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CleanAgentComponent, data: {} });
export { RenderType_CleanAgentComponent as RenderType_CleanAgentComponent };
export function View_CleanAgentComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-electric-devices", [], null, null, null, i1.View_ElectricDevicesComponent_0, i1.RenderType_ElectricDevicesComponent)), i0.ɵdid(1, 638976, null, 0, i2.ElectricDevicesComponent, [], { questions: [0, "questions"], qtd: [1, "qtd"], reviewInspection: [2, "reviewInspection"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-other-devices", [], null, null, null, i3.View_OtherDevicesComponent_0, i3.RenderType_OtherDevicesComponent)), i0.ɵdid(3, 638976, null, 0, i4.OtherDevicesComponent, [], { questions: [0, "questions"], qtd: [1, "qtd"], reviewInspection: [2, "reviewInspection"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-communication-devices", [], null, null, null, i5.View_CommunicationDevicesComponent_0, i5.RenderType_CommunicationDevicesComponent)), i0.ɵdid(5, 638976, null, 0, i6.CommunicationDevicesComponent, [], { questions: [0, "questions"], qtd: [1, "qtd"], reviewInspection: [2, "reviewInspection"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.questions; var currVal_1 = _co.qtd; var currVal_2 = _co.reviewInspection; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.questions; var currVal_4 = _co.qtd; var currVal_5 = _co.reviewInspection; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.questions; var currVal_7 = _co.qtd; var currVal_8 = _co.reviewInspection; _ck(_v, 5, 0, currVal_6, currVal_7, currVal_8); }, null); }
export function View_CleanAgentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-clean-agent", [], null, null, null, View_CleanAgentComponent_0, RenderType_CleanAgentComponent)), i0.ɵdid(1, 114688, null, 0, i7.CleanAgentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CleanAgentComponentNgFactory = i0.ɵccf("app-clean-agent", i7.CleanAgentComponent, View_CleanAgentComponent_Host_0, { questions: "questions", qtd: "qtd", reviewInspection: "reviewInspection" }, {}, []);
export { CleanAgentComponentNgFactory as CleanAgentComponentNgFactory };
