import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import * as moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-DD-YYYY'
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MM-DD-YYYY',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-filter-date-dialog',
  templateUrl: './filter-date-dialog.component.html',
  styleUrls: [],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}
  ]
})
export class FilterDateDialogComponent implements OnInit{

  toDateError = '';
  fromDateError = '';

  constructor(
    public dialogRef: MatDialogRef<FilterDateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.data.dateRange.fromDate = moment(this.data.dateRange.fromDate, 'MM-DD-YYYY');
    this.data.dateRange.toDate = moment(this.data.dateRange.toDate, 'MM-DD-YYYY');
  }

  checkErrorInFromDate(fromDate) {
    fromDate = moment(this.data.dateRange.fromDate.toString()).format('MM-DD-YYYY');
    const toDate = moment(this.data.dateRange.toDate.toString()).format('MM-DD-YYYY');
    this.fromDateError = '';
    if (fromDate.length === 0) {
      return this.fromDateError;
     }
    if (fromDate.length !== 10) {
      this.fromDateError = 'Enter Valid Date';
    }
    const date = fromDate.split('-');
    if (date.length !== 3 ||
      date[0].length !== 2 ||
      date[1].length !== 2 ||
      date[2].length !== 4) {
        this.fromDateError = 'Enter Valid Date';
    } else if (!moment(fromDate, 'MM-DD-YYYY').isValid()) {
      this.fromDateError =  'Enter Valid Date';
    } else if (
      moment(fromDate).isAfter(moment(), 'day')
    ) {
      this.fromDateError =  'Date should not be in future';
    } else if (fromDate && +moment(fromDate, 'MM-DD-YYYY')
     > +moment(toDate, 'MM-DD-YYYY')) {
      this.toDateError =  'To Date should be after From Date';
    }
    return this.fromDateError;
  }

  checkErrorInToDate(toDate) {
    this.toDateError = '';
    const fromDate = moment(this.data.dateRange.fromDate.toString()).format('MM-DD-YYYY');
    toDate = moment(this.data.dateRange.toDate.toString()).format('MM-DD-YYYY');
    if (toDate.length === 0) {
     return this.toDateError;
    }
    if (toDate.length !== 10) {
      this.toDateError = 'Enter Valid Date';
    }
    const date = toDate.split('-');
    if (date.length !== 3 ||
      date[0].length !== 2 ||
      date[1].length !== 2 ||
      date[2].length !== 4) {
        this.toDateError = 'Enter Valid Date';
    } else if (!moment(toDate, 'MM-DD-YYYY').isValid()) {
      this.toDateError =  'Enter Valid Date';
    } else if (
      moment(toDate).isAfter(moment(), 'day')
    ) {
      this.toDateError =  'Date should not be in future';
    } else if (fromDate &&
      +moment(fromDate, 'MM-DD-YYYY') > +moment(toDate, 'MM-DD-YYYY')) {
      this.toDateError =  'To Date should be after From Date';
    }
    return this.toDateError;
  }

  search() {
    if (this.data.dateRange.address !== null) {
      this.data.dateRange.address = this.data.dateRange.address ? this.data.dateRange.address.trim() : this.data.dateRange.address;
    }
    if (this.data.dateRange.accessCode !== null) {
      this.data.dateRange.accessCode = this.data.dateRange.accessCode ?
       this.data.dateRange.accessCode.trim() : this.data.dateRange.accessCode;
    }
    this.dialogRef.close({action: 'search', dateRange: this.data.dateRange});
  }

  cancel() {
    this.dialogRef.close({action: 'cancel'});
  }

}
