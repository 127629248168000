<div>
  <app-dialog>
    <span class="dialog-title">
      Recover Your Password
      <span class="float-right">
        <mat-icon class="cursor-pointer" (click)="cancel()">close</mat-icon>
      </span>
    </span>
    <div class="dialog-content">
      <label class="col-form-label col-form-label-sm inputLabel notes-sub-heading">
        To recover you password enter the email address that you mentioned while signing up with TEGRIS & click on
        Recover button.
      </label>

      <div style="margin:20px">
        <div class="form-group row form-group-padding custom-form-control">
          <div class="col-2"><label class="col-form-label col-form-label-sm inputLabel"
              style="font-size: 18px;"><strong>Email:<label class="colorRed">*</label></strong></label></div>
          <div class="col-10">
            <input type="email" class="form-control form-control-sm rounded-form-fields"
              pattern="[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*"
              [(ngModel)]="userEmail.email" />
          </div>
          <div class="offset-2 col-10 col-form-label col-form-label-sm error" *ngIf="error && error.length>0"
            style="font-size: 18px; margin-top: -2vh;">
            *{{error}}
          </div>
        </div>
        <hr class="custom-form-control">

        <div class="form-group row form-group-padding">
          <div class="col-12"><label class="col-form-label col-form-label-sm inputLabel"
              style="font-size: 18px"><strong>Note:</strong> An email with new password will be sent to your account.</label>
          </div>
        </div>

      </div>

    </div>
    <div class="dialog-actions">
      <button class="custom-buttons" cdkFocusInitial (click)="recover()">Recover</button>
      <button class="custom-buttons" (click)="cancel()">Cancel</button>
    </div>
  </app-dialog>
  <div class="lds-ripple" *ngIf="loading">
    <div></div>
    <div></div>
  </div>
</div>
