import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FileCabinetService } from '../../core/services/file-cabinet/file-cabinet.service';
import { FileCabinetHistory } from '../../core/models/file-cabinet/file-cabinet.model';

@Component({
  selector: 'app-file-cabinet-history',
  templateUrl: './file-cabinet-history.component.html',
  styleUrls: ['./file-cabinet-history.component.scss']
})
export class FileCabinetHistoryComponent implements OnInit, OnDestroy {

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  columnsToDisplay = ['name', 'address', 'pac'];
  history: FileCabinetHistory[] = [];
  subscriptions = [];

  constructor(
    public dialogRef: MatDialogRef<FileCabinetHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fileCabService: FileCabinetService) { }

  ngOnInit() {
    this.subscriptions.push(
    this.fileCabService.getFileCabinetHistory().subscribe(
      res => {
        this.history = res;
        this.dataSource.data = this.history;
      },
      error => {
        console.log(error);
      }
    )
    );

  }

  close() {
    this.dialogRef.close(null);
  }

  searchInFileCabinet(property) {
    this.dialogRef.close(property);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
      }
    );
  }


}
