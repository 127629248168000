<div class="lds-ripple" *ngIf="loading">
  <div></div>
  <div></div>
</div>
<div>
<app-dialog>
  <div class="notes-heading-container max-width-100 dialog-title">
    <div class="col-11">
      <h1  class="notes-heading" >Email Report</h1>
    </div>
  </div>
  <div class="dialog-content">

  <div class="row form-group-padding max-width-100">

    <div  class="notes-sub-heading col-3 padding-10">To </div>
    <div class="col-7">
      <input type="email" [(ngModel)]="data.to"
        class="col-11 col-form-label col-form-label-sm rounded-form-fields" />
    </div>
    <div class="offset-3 col-9" *ngIf="invalidToEmail.length > 0">
     * {{invalidToEmail}}
    </div>
  </div>

  <div class="row form-group-padding max-width-100">
    <div class="notes-sub-heading col-3 padding-10">CC </div>
    <div class="col-7">
      <input type="email" [(ngModel)]="data.cc"
        class="col-11 col-form-label col-form-label-sm rounded-form-fields" />
    </div>
    <div class="offset-3 col-9" *ngIf="invalidCCEmail.length > 0">
      * {{invalidCCEmail}}
     </div>
  </div>

  <div class="row form-group-padding max-width-100">
    <div  class="notes-sub-heading col-3 padding-10">Subject </div>
    <div class="col-7">
      <input type="text" class="col-11 col-form-label col-form-label-sm rounded-form-fields"
        [(ngModel)]="data.subject" maxlength="100"/>
    </div>
  </div>

  <div class="row form-group-padding max-width-100">
    <div  class="notes-sub-heading col-3 padding-10">Attachment </div>
    <div class="col-7">
      <input type="text" disabled class="col-11 col-form-label col-form-label-sm rounded-form-fields"
        value="InspectionReport.pdf" />
    </div>
  </div>

  <div class="row form-group-padding max-width-100">
    <div  class="notes-sub-heading col-3 padding-10">Body </div>
    <mat-form-field class="col-7">
      <textarea class="col-11 col-form-label col-form-label-sm rounded-form-fields padding-15"
        [(ngModel)]="data.body" matInput cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="8">
      </textarea>
    </mat-form-field>

  </div>
  </div >
</app-dialog>
  <div  class="custom-hide-overflow row max-width-100 ">

    <div class="form-group row form-group-padding offset-4 ">
      <button class="custom-buttons " (click)="send()">Send</button>
      <button class="custom-buttons " (click)="onClose()">Close</button>
    </div>

  </div>

  <div class="row form-group max-width-100 dialog-content">
    <div class="offset-2 col-8">
      Note: Multiple addresses can be entered, separated by a semicolon (;)
    </div>
  </div>

</div>
