import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var LoaderService = /** @class */ (function () {
    function LoaderService() {
        this.isLoading = new Subject();
    }
    LoaderService.prototype.show = function () {
        this.isLoading.next(true);
    };
    LoaderService.prototype.hide = function () {
        this.isLoading.next(false);
    };
    LoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(); }, token: LoaderService, providedIn: "root" });
    return LoaderService;
}());
export { LoaderService };
