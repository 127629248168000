import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '../login/auth.guard';
import { DashboardComponent } from './dashboard.component';
import { OwnerGuard } from '../shared/services/owner-guard.service';
import { AuthorityGuard } from '../shared/services/authority-guard.service';
import { InspectorGuard } from '../shared/services/inspector-guard.service';
import { AdminGuard } from '../shared/services/admin-guard.service';
import { AuthorityInspectorAdminGuard } from '../shared/services/authority-inspector-admin-guard.service';
import { AuthorityInspectorGuard } from '../shared/services/authority-inspector-guard.service';


const routes: Route[] = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'owner',
        canActivate: [AuthGuard, OwnerGuard],
        loadChildren: () =>
          import('../owner/owner.module').then(mod => mod.OwnerModule)
      },
      {
        path: 'addEditProperty',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('../property-page/property.module').then(mod => mod.PropertyModule)
      },
      {
        path: 'general',
        canActivate: [AuthGuard, AuthorityInspectorAdminGuard],
        loadChildren: () =>
          import('../file-cabinet/fileCabinet.module').then(mod => mod.FileCabinetModule)
      },
      {
        path: 'cases',
        canActivate: [AuthGuard, AuthorityInspectorGuard],
        loadChildren: () =>
          import('../cases/cases.module').then(mod => mod.CasesModule)
      },
      {
        path: 'metrics',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('../metrics/metrics.module').then(mod => mod.MetricsModule)
      },
      {
        path: 'inspection',
      //  canActivate: [AuthGuard , AuthorityInspectorAdminGuard],
        loadChildren: () =>
          import('../inspection/inspection.module').then(mod => mod.InspectionModule)
      },
      {
        path: 'authority',
        canActivate: [AuthGuard, AuthorityGuard],
        loadChildren: () =>
          import('../authority/authority.module').then(mod => mod.AuthorityModule)
      },
      {
        path: 'inspector',
        canActivate: [AuthGuard, InspectorGuard],
        loadChildren: () =>
          import('../inspector/inspector.module').then(mod => mod.InspectorModule)
      },
      {
        path: 'admin',
        canActivate: [AuthGuard, AdminGuard],
        loadChildren: () =>
          import('../admin/admin.module').then(mod => mod.AdminModule)
      },
      {
        path: 'inspectionResult',
        loadChildren: () => import('../inspector/inspection-result/inspection-result.component').then(mod => mod.InspectionResultModule),
        canActivate: [AuthGuard, AuthorityInspectorAdminGuard]
      },
      {
        path: '**',
        redirectTo: 'dashboard',
        runGuardsAndResolvers: 'always'
      }
    ],
    runGuardsAndResolvers: 'always'
  }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
