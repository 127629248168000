import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Roles } from '../../../../shared/models/user.model';
var DeleteDialogComponent = /** @class */ (function () {
    function DeleteDialogComponent(dialogRef, data, inspectorService, authService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.inspectorService = inspectorService;
        this.authService = authService;
    }
    DeleteDialogComponent.prototype.ngOnInit = function () {
        this.user = JSON.parse(localStorage.getItem('userDetails'));
    };
    DeleteDialogComponent.prototype.save = function () {
        if (this.user.role.id === Roles.ROLE_AUTHORITY_ADMIN ||
            this.user.role.id === Roles.ROLE_AUTHORITY_WORX || this.user.role.id === Roles.ROLE_RFA) {
            this.inspectorService.deleteLetterHead(this.data.id).subscribe(function (x) {
            });
        }
        else {
            this.inspectorService.deleteLetterHead(this.data.id).subscribe(function (x) {
            });
        }
        this.dialogRef.close('continue');
    };
    DeleteDialogComponent.prototype.cancel = function () {
        this.dialogRef.close('cancelled');
    };
    return DeleteDialogComponent;
}());
export { DeleteDialogComponent };
