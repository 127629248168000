import { OnInit, OnDestroy } from '@angular/core';
var ForgotPasswordComponent = /** @class */ (function () {
    function ForgotPasswordComponent(dialogRef, loginService) {
        this.dialogRef = dialogRef;
        this.loginService = loginService;
        this.subscriptions = [];
    }
    ForgotPasswordComponent.prototype.ngOnInit = function () {
        this.loading = false;
        this.userEmail = {
            email: ''
        };
        this.error = '';
    };
    ForgotPasswordComponent.prototype.recover = function () {
        var _this = this;
        this.error = '';
        this.userEmail.email = this.userEmail.email.trim();
        if (!this.userEmail.email || this.userEmail.email.length === 0) {
            this.error = 'Please enter email address.';
        }
        if (this.error === '') {
            this.loading = true;
            this.subscriptions.push(this.loginService.recoverPassword(this.userEmail).subscribe(function (data) {
                _this.loading = false;
                _this.dialogRef.close(_this.userEmail.email);
            }, function (error) {
                _this.loading = false;
                if (error.message && error.message.length > 0) {
                    _this.error = error.error.message;
                }
            }));
        }
    };
    ForgotPasswordComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ForgotPasswordComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (x) {
            x.unsubscribe();
        });
    };
    return ForgotPasswordComponent;
}());
export { ForgotPasswordComponent };
