import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var EditPropertyDialogComponent = /** @class */ (function () {
    function EditPropertyDialogComponent(dialogRef, data, propService, route) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.propService = propService;
        this.route = route;
    }
    EditPropertyDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isEditDetail = 'Edit';
        this.propDetails = {};
        this.propertyIdForEdit = this.data.id;
        this.propService.getPropertyById(this.data.id).subscribe(function (res) {
            if (res) {
                _this.propDetails = res;
                if (_this.propDetails && _this.propDetails.propertyContacts && _this.propDetails.propertyContacts[0]) {
                    _this.managerAddress = _this.propDetails.propertyContacts[0].streetLine1;
                    if (_this.propDetails.propertyContacts[0].streetLine2) {
                        _this.managerAddress += _this.propDetails.propertyContacts[0].streetLine2;
                    }
                    _this.managerAddress += " " + _this.propDetails.propertyContacts[0].zipcode.city + "\n           " + _this.propDetails.propertyContacts[0].zipcode.state + ", " + _this.propDetails.propertyContacts[0].zipcode.zipcode;
                }
            }
        });
    };
    EditPropertyDialogComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    return EditPropertyDialogComponent;
}());
export { EditPropertyDialogComponent };
