import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnDestroy, OnInit } from '@angular/core';
import * as jsPDF from 'jspdf';
import { switchMap, tap } from 'rxjs/operators';
import { EmailComponent } from './email/email.component';
var InspectionReportComponent = /** @class */ (function () {
    function InspectionReportComponent(
    // private sendMailService: SendmailService,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    // public dialogRef: MatDialogRef<any>,
    inspectionReportService, ar, inspectionService, dialog, authorityService, inspectorService, adminService, sanitizer) {
        this.inspectionReportService = inspectionReportService;
        this.ar = ar;
        this.inspectionService = inspectionService;
        this.dialog = dialog;
        this.authorityService = authorityService;
        this.inspectorService = inspectorService;
        this.adminService = adminService;
        this.sanitizer = sanitizer;
        this.propDetails = {};
        this.propManagerDetails = {};
        this.inspectorDetails = {};
        this.inspectionResult = {};
        this.currDate = new Date();
        this.unsub = [];
        this.inspectorLetterHead = {};
        this.authorityLetterHead = {};
        this.deficiencies = [];
    }
    InspectionReportComponent.prototype.unloadHandler = function (event) {
        if (this.inspectionResultRendering.length > 0) {
            this.unsub.push(this.adminService.deleteTemplate(this.inspectionResultRendering)
                .subscribe(function (res) {
                // Do nothing
            }));
        }
    };
    InspectionReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inspectionDataforSecondPage = {};
        this.userRole = localStorage.getItem('userRole');
        this.images = {};
        this.prvRows = [];
        this.hoseRows = [];
        this.sequence = [];
        // console.log(jsPDF)
        this.assetTypeId = null;
        this.propId = null;
        this.inspectionResultRendering = [];
        this.qtd = {};
        // console.log(this.data.previousResultId);
        this.ar.queryParams
            .subscribe(function (params) {
            // console.log(params); // {order: 'popular'}
            var reportId = params.previousResultId;
            _this.assetTypeId = params.assetTypeId;
            _this.propId = params.propId;
            _this.inspectionResultId = +params["previousResultId"];
            _this.inspectionReportService.getInspectionResult(_this.inspectionResultId).subscribe(function (res) {
                _this.inspectionResult = res;
                _this.inspectionDataforSecondPage.inspectionType = res['inspectionType'];
                _this.inspectionDataforSecondPage.inspectionAttachment = res['inspectionAttachment'];
            });
            if (reportId) {
                _this.loading = true;
                _this.printBlank = false;
                var isWaterMarkAllowed = params.isWaterMarkAllowed;
                _this.inspectionReportService.getThymeLeafReport(reportId, isWaterMarkAllowed).subscribe(function (res) {
                    if (res) {
                        _this.inspectionResultRendering = res['1'][0];
                        _this.inspectionResultRenderingIframe = _this.sanitizer.bypassSecurityTrustResourceUrl(res['1'][0]);
                    }
                }, function (err) {
                    alert("Report generation failed");
                });
                // this.inspectionService.getPropertyDetailsById(this.propId).pipe(map(res => {
                //   if (res) {
                //     return res;
                //   }
                // }), switchMap(property => {
                //   return this.inspectionService.getQuestionsByReportId(reportId, +property.jurisdictionId);
                // })).subscribe((res) => {
                //   if (res) {
                //     this.questions = res.sort((a, b) => {
                //       return a.questions.sequence - b.questions.sequence;
                //     });
                //     this.questions.forEach((question) => {
                //       if (question && question.questions) {
                //         this.qtd[question.questions.questionId] = question.questions.answer;
                //         if (question.questions.answer && question.questions.answer[0] === 'N/A' && question.questions.optionType === 'Text'
                //           && question.questions.options) {
                //           this.qtd[question.questions.questionId + 'checkbox'] = true;
                //           this.sequence.push(question.questions.sequence + 1);
                //         }
                //       }
                //       if (question.questions.optionType === 'Table') {
                //         if (question.questions.answer && question.questions.answer[0]) {
                //           const finalAnswer = question.questions.answer[0].split('~');
                //           for (let i = 0; i < finalAnswer.length; i++) {
                //             this.qtd[question.questions.questionId + '~m~' + i + '~' + finalAnswer.length] = finalAnswer[i];
                //           }
                //         }
                //       }
                //       if (question.questions.questionType === 'PRV') {
                //         if (question.questions.answer && question.questions.answer[0]) {
                //           this.inspectionDataforSecondPage.showPRV = true;
                //           const finalAnswer = question.questions.answer[0].split('~');
                //           if (this.prvRows.length < finalAnswer.length) {
                //             for (let k = 0; k < finalAnswer.length; k++) {
                //               this.prvRows.push({ id: k });
                //             }
                //           }
                //           this.inspectionService.setStandPipeRows(finalAnswer.length);
                //           for (let i = 0; i < finalAnswer.length; i++) {
                //             this.prvRows[i][question.questions.question] = finalAnswer[i];
                //           }
                //         }
                //       }
                //       if (question.questions.questionType === 'ClassII') {
                //         if (question.questions.answer && question.questions.answer[0]) {
                //           this.inspectionDataforSecondPage.showHose = true;
                //           const finalAnswer = question.questions.answer[0].split('~');
                //           if (this.hoseRows.length < finalAnswer.length) {
                //             for (let k = 0; k < finalAnswer.length; k++) {
                //               this.hoseRows.push({ id: k });
                //             }
                //           }
                //           this.inspectionService.setHoseCount(finalAnswer.length);
                //           for (let i = 0; i < finalAnswer.length; i++) {
                //             this.hoseRows[i][question.questions.question] = finalAnswer[i];
                //           }
                //         }
                //       }
                //       if (question && question.questions && question.questions.images && question.questions.images.length > 0) {
                //         const imagesForNextScreen = [];
                //         question.questions.images.forEach((x) => {
                //           imagesForNextScreen.push({ imageContent: x.imageContent, imagePlacement: x.imagePlacement });
                //         });
                //         this.images[question.questions.questionId] = imagesForNextScreen;
                //         if (question.questions.question === 'Pictures of Hydrant (front and back wide enough to show clearance)') {
                //           if (question.questions.images) {
                //             if (question.questions.images.filter((x) => x.imagePlacement === 'Front').length === 0) {
                //               //this.noFrontImage = true;
                //             }
                //             if (question.questions.images.filter((x) => x.imagePlacement === 'Back').length === 0) {
                //              // this.noBackImage = true;
                //             }
                //           }
                //         }
                //       }
                //       if (question && question.questions && question.questions.subquestion) {
                //         question.questions.subquestion.forEach((x) => {
                //           if (x && x.images && x.images.length > 0) {
                //             const imagesForNextScreen = [];
                //             x.images.forEach((y) => {
                //               imagesForNextScreen.push({ imageContent: y.imageContent, imagePlacement: y.imagePlacement });
                //             });
                //             this.images[x.questionId] = imagesForNextScreen;
                //           }
                //           this.qtd[x.questionId] = x.answer ? x.answer[0] : null;
                //           if (x.optionType === 'Radio') {
                //             x.options = x.options.sort((a, b) => {
                //               return a.sequence - b.sequence;
                //             });
                //           }
                //           if (x.answer && x.answer[0] === 'N/A' && x.optionType === 'Text' && x.options) {
                //             this.qtd[x.questionId + 'checkbox'] = true;
                //           }
                //           if (x.subquestion && x.subquestion.length > 0) {
                //             if (x.subquestion[0].optionType === 'Text') {
                //               if (x.subquestion[0].answer) {
                //                 this.qtd[x.subquestion[0].questionId] = x.subquestion[0].answer[0];
                //               }
                //             } else {
                //               if (x.subquestion[0].answer && x.subquestion[0].answer.length > 0) {
                //                 // tslint:disable-next-line: prefer-for-of
                //                 for (let i = 0; i < x.subquestion[0].answer.length; i++) {
                //                   this.qtd[x.subquestion[0].questionId + '*o*' + x.subquestion[0].answer[i]] = true;
                //                 }
                //               }
                //             }
                //           }
                //         });
                //       }
                //       if (question && question.questions.optionType === 'Radio' ||
                //         question.questions.questionType === 'Test Results Acceptable') {
                //         if (question.questions.answer) {
                //           this.qtd[question.questions.questionId] = question.questions.answer[0];
                //         }
                //         question.questions.options = question.questions.options.sort((a, b) => {
                //           return a.sequence - b.sequence;
                //         });
                //         if (question && question.questions.subquestion) {
                //           question.questions.subquestion.forEach((x) => {
                //             this.qtd[x.questionId] = x.answer ? x.answer[0] : null;
                //             if (x.optionType === 'Radio') {
                //               x.options = x.options.sort((a, b) => {
                //                 return a.sequence - b.sequence;
                //               });
                //             }
                //           });
                //         }
                //       }
                //       if (question && question.questions.optionType === 'Checkbox') {
                //         question.questions.options = question.questions.options.sort((a, b) => {
                //           return a.sequence - b.sequence;
                //         });
                //         if (question.questions.answer && question.questions.answer.length > 0) {
                //           // tslint:disable-next-line: prefer-for-of
                //           for (let i = 0; i < question.questions.answer.length; i++) {
                //             this.qtd[question.questions.questionId + '*o*' + question.questions.answer[i]] = true;
                //           }
                //         }
                //       }
                //     });
                //     this.inspectionService.sePVRRows(this.prvRows);
                //     this.inspectionService.setHoseRows(this.hoseRows);
                //     this.inspectionDataforSecondPage.sequence = this.sequence;
                //   } else {
                //     this.questions = [];
                //   }
                // });
            }
            else if (_this.assetTypeId) {
                _this.loading = false;
                _this.inspectionResultRendering = null;
                if (_this.userRole === 'ROLE_AUTHORITY' || _this.userRole === 'ROLE_AUTHORITY_WORX') {
                    _this.authorityService.getLetterHead().subscribe(function (x) {
                        _this.authorityLetterHead = x;
                    });
                }
                else {
                    _this.inspectorService.getLetterHead().subscribe(function (response) {
                        _this.inspectorLetterHead = response;
                    });
                }
                _this.printBlank = true;
                _this.inspectionService.setStandPipeRows(5);
                _this.inspectionService.setHoseCount(5);
                var jurisdicationId = 0;
                var userDetails = JSON.parse(localStorage.getItem('userDetails'));
                if (userDetails.jurisdiction) {
                    jurisdicationId = userDetails.jurisdiction.id;
                }
                _this.inspectionService
                    .getQuestions(+_this.assetTypeId, jurisdicationId)
                    .subscribe(function (res) {
                    if (res) {
                        _this.questions = res.sort(function (a, b) {
                            return a.questions.sequence - b.questions.sequence;
                        });
                    }
                });
            }
        });
    };
    InspectionReportComponent.prototype.ngOnDestroy = function () {
        this.unsub.forEach(function (res) { return res.unsubscribe(); });
    };
    InspectionReportComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var params = {};
        if (!this.ar.snapshot.queryParams.propId) {
            return;
        }
        var unsub = this.ar.queryParams.pipe(switchMap(function (res) {
            params = res;
            return _this.inspectionReportService.getPropertyDetails(res["propId"]);
        }), tap(function (res) {
            _this.propDetails = res;
            // console.log(this.propDetails);
            var a = (_this.propDetails["ownerAddress"] || '').split(',');
            _this.propDetails["jurisdiction"] = a.splice(-3).join(',');
            _this.propDetails["address1"] = a.join(',');
        }), switchMap(function (res) {
            return _this.inspectionReportService.getPropertyManagerDetails(params["propId"]);
        }), tap(function (res) { return (_this.propManagerDetails = res); }), switchMap(function (res) { return _this.inspectionReportService.getInspectionResult(+params["previousResultId"]); }), tap(function (res) {
            _this.inspectionResult = res;
            _this.inspectionDataforSecondPage.inspectionType = res['inspectionType'];
            _this.inspectionDataforSecondPage.inspectionAttachment = res['inspectionAttachment'];
            if (_this.inspectionResult.inspectionDeficiency && _this.inspectionResult.inspectionDeficiency.length > 0) {
                var filteredDefs = _this.inspectionResult.inspectionDeficiency.filter(function (x) { return x.isCorrected; }).length;
                if (filteredDefs === _this.inspectionResult.inspectionDeficiency.length) {
                    _this.noCorrectionRequired = true;
                }
                else {
                    _this.noCorrectionRequired = false;
                }
            }
        }), 
        // switchMap(res => this.inspectionReportService.getPreviousInspectionReports(this.data.prop.id)),
        // filter((res: any) => res.filter(dat => dat.propertyAssetId === this.data.sys.id)[0].inpectedById),
        switchMap(function (res) {
            // const a = res.filter(dat => dat.propertyAssetId === this.data.sys.id)[0];
            return _this.inspectionReportService.getInspectorDetails(_this.inspectionResult["inspectedById"]);
        }), tap(function (res) { return _this.inspectorDetails = res; }), switchMap(function (res) { return _this.inspectionReportService.getInspectorLetterHead(_this.inspectionResult["inspectedById"], 'LetterHead'); }), tap(function (res) { return _this.inspectorLetterHead = res; }), switchMap(function (res) { return _this.inspectionReportService.getAuthorityLetterHead(+params["propId"], 'LetterHead'); }), tap(function (res) { return _this.authorityLetterHead = res; }))
            .subscribe(function (res) {
            // console.log(res)
            if (_this.inspectionResult.propertyAsset.isActive) {
                _this.addWaterMark(false, _this.inspectionResult.propertyAsset.inspectionStatus.name === 'Submitted_For_Review');
            }
        });
        this.unsub.push(unsub);
    };
    InspectionReportComponent.prototype.emailReport = function () {
        var dialogRef = this.dialog.open(EmailComponent, {
            width: '800px',
            data: {
                to: '',
                cc: '',
                subject: 'Inspection Report',
                // attachment: blob,
                body: '',
                inspectionResultId: this.inspectionResultId
            }
        });
        // this.inspectionReportService.sendPdf(formData)
        //   .subscribe(data => {
        //     const msg = data[`message`];
        //     alert(msg);
        //     // console.log(data, 'success');
        //   }, error => {
        //     console.error(error, 'error');
        //   });
        // });
    };
    InspectionReportComponent.prototype.convertToPdf = function () {
        var doc = new jsPDF();
        doc.addHTML(document.getElementById('parentdiv'), function () {
            doc.save("report.pdf");
        });
    };
    InspectionReportComponent.prototype.createPDF = function () {
        var propertyJournal = {
            body: 'Save Pdf',
            accessControl: 'Private',
            subject: 'Save Inspection Report as PDF'
        };
        this.inspectionReportService.createJournalEntry(this.propId, propertyJournal).subscribe(function (res) { });
        // this.inspectionReportService.getReport(this.inspectionResultRendering).subscribe((res) => {
        // });
        // window.scrollTo(0, 0);
        // if (this.inspectionResult.propertyAsset.isActive) {
        //   this.addWaterMark(true, this.inspectionResult.propertyAsset.inspectionStatus.name === 'Submitted_For_Review');
        // }
        // const HTML_WIDTH = document.querySelector('#parentdiv').clientWidth;
        // const HTML_HEIGHT = document.querySelector('#parentdiv').clientHeight;
        // const TOP_LEFT_MARGIN = 15;
        // const PDF_WIDTH = HTML_WIDTH + (TOP_LEFT_MARGIN * 2);
        // const PDF_HEIGHT = (PDF_WIDTH * 1.5) + (TOP_LEFT_MARGIN * 2);
        // const CANVAS_IMAGE_WIDTH = HTML_WIDTH;
        // const CANVAS_IMAGE_HEIGHT = HTML_HEIGHT;
        // const totalPDFPages = Math.ceil(HTML_HEIGHT / PDF_HEIGHT) - 1;
        // console.log(totalPDFPages);
        // html2canvas(document.querySelector('#parentdiv')).then(canvas => {
        //   // const pdf = new jsPDF('p', 'pt', [PDF_WIDTH, PDF_HEIGHT]);
        //   // const imgData = canvas.toDataURL('image/jpeg', 1.0);
        //   // pdf.addImage(imgData, 0, 0, canvas.width, canvas.height);
        //   // pdf.save('report.pdf');
        //   canvas.getContext('2d');
        //   // console.log(canvas.height+'  '+canvas.width);
        //   // console.log(canvas)
        //   const imgData = canvas.toDataURL('image/jpeg', 1.0);
        //   // const i = document.createElement('img');
        //   // i.src = imgData
        //   // document.body.appendChild(i);
        //   const pdf = new jsPDF('p', 'pt', [PDF_WIDTH, PDF_HEIGHT]);
        //   pdf.addImage(imgData, 'JPG', TOP_LEFT_MARGIN, TOP_LEFT_MARGIN, CANVAS_IMAGE_WIDTH, CANVAS_IMAGE_HEIGHT);
        //   for (let i = 1; i <= totalPDFPages; i++) {
        //     pdf.addPage(PDF_WIDTH, PDF_HEIGHT);
        //     pdf.addImage(
        //       imgData, 'JPG', TOP_LEFT_MARGIN, - (PDF_HEIGHT * i) + (TOP_LEFT_MARGIN * 4), CANVAS_IMAGE_WIDTH, CANVAS_IMAGE_HEIGHT);
        //   }
        //   pdf.save('HTML-Document.pdf');
        //   this.removeVisibleWaterMark();
        // });
    };
    InspectionReportComponent.prototype.addWaterMark = function (visible, submittedForReview) {
        if (visible === void 0) { visible = false; }
        if (submittedForReview === void 0) { submittedForReview = false; }
        var p = document.querySelector('#parentdiv');
        if (p !== null) {
            var pHeight = p.scrollHeight;
            var windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
            var noOfWatermark = pHeight / windowHeight;
            noOfWatermark = Math.ceil(noOfWatermark / 2);
            for (var i = 0; i < noOfWatermark; i++) {
                var elem = document.createElement('div');
                elem.className = "" + (visible ? 'visible-water-mark' : 'water-mark');
                elem.style.cssText = "\n      top: " + 60 * (i === 0 ? i + 1 : i + (visible ? 2.5 : 1)) + "vh;\n      ";
                if (submittedForReview) {
                    elem.style.cssText += "\n          font-size: 8vw;\n          left: -15vw;\n          width: 133%;\n        ";
                }
                elem.innerText = submittedForReview ? 'Under Review by Fire Marshal' : 'Unofficial Copy';
                p.appendChild(elem);
            }
        }
    };
    InspectionReportComponent.prototype.printTheReport = function () {
        // let iframe = this._printIframe;
        // if (!this._printIframe) {
        //   iframe = this._printIframe = document.createElement('iframe');
        //   document.body.appendChild(iframe);
        //   iframe.style.display = 'none';
        //   iframe.onload = function() {
        //     setTimeout(function() {
        //       iframe.focus();
        //       iframe.contentWindow.print();
        //     }, 1);
        //   };
        // }
        // iframe.src = `${this.inspectionResultRendering}`;
        // window.open(`${this.inspectionResultRendering}`).print();
        var propertyJournal = {
            body: 'Print',
            accessControl: 'Private',
            subject: 'Print Inspection Report'
        };
        this.inspectionReportService.createJournalEntry(this.propId, propertyJournal).subscribe(function (res) { });
        window.print();
    };
    InspectionReportComponent.prototype.cancelReport = function () {
        window.open(location.pathname, '_self').close();
    };
    InspectionReportComponent.prototype.removeVisibleWaterMark = function () {
        var e_1, _a;
        var a = document.querySelectorAll('.visible-water-mark');
        try {
            for (var a_1 = tslib_1.__values(a), a_1_1 = a_1.next(); !a_1_1.done; a_1_1 = a_1.next()) {
                var i = a_1_1.value;
                i.parentNode.removeChild(i);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (a_1_1 && !a_1_1.done && (_a = a_1.return)) _a.call(a_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    InspectionReportComponent.prototype.textLayerRendered = function (e) {
        // console.log('(text-layer-rendered)', e);
        this.loading = false;
    };
    return InspectionReportComponent;
}());
export { InspectionReportComponent };
