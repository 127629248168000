import { Component, OnInit, Inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material";


@Component({
  selector: "app-delete-request-dialog",
  templateUrl: "./delete-request-dialog.component.html",
  styleUrls: [],
})
export class DeleteRequestDialogComponent implements OnInit {

  comments: string;

  constructor(
    public dialogRef: MatDialogRef<DeleteRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
  }

  onClose(submit: boolean) {
    // let response: any;
    // response.sub = submit;
    this.comments = this.comments ? this.comments : '';
    this.dialogRef.close({sub: submit, comments: this.comments});
  }
}
