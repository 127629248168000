import { MatDialogRef } from '@angular/material/dialog';
var AssignPropertyDialogComponent = /** @class */ (function () {
    function AssignPropertyDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.pac = "";
    }
    AssignPropertyDialogComponent.prototype.ngOnInit = function () {
        this.showError = false;
    };
    AssignPropertyDialogComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    AssignPropertyDialogComponent.prototype.submit = function () {
        if (this.pac.length > 0) {
            this.showError = false;
            this.dialogRef.close(this.pac);
        }
        else {
            this.showError = true;
        }
    };
    return AssignPropertyDialogComponent;
}());
export { AssignPropertyDialogComponent };
