import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";

@Component({
  selector: "app-certify-bypass",
  templateUrl: "./certify-bypass.component.html",
  styleUrls: ["./certify-bypass.component.scss"]
})
export class CertifyBypassComponent implements OnInit {
  checked: boolean;

  constructor(public dialogRef: MatDialogRef<CertifyBypassComponent>) {}

  ngOnInit() {
    this.checked = false;
  }

  cancel() {
    this.dialogRef.close(false);
  }

  certify() {
    if (this.checked) {
      this.dialogRef.close(true);
    } else {
      alert('Please certify PAC BYPASS');
    }
  }
}
