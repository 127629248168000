import { OnInit } from '@angular/core';
var MapViewComponent = /** @class */ (function () {
    function MapViewComponent(utilityService) {
        this.utilityService = utilityService;
        this.lat = 47.6062; // latitude of area
        this.lng = -122.3321; // longitude of area
    }
    MapViewComponent.prototype.ngOnInit = function () { };
    MapViewComponent.prototype.openenlargeMap = function () {
        this.utilityService.openEnlargeMap(this.propertyMarker);
    };
    return MapViewComponent;
}());
export { MapViewComponent };
