import { Component, OnInit } from "@angular/core";
import { InspectorService } from "../../../../../inspector/services/inspector.service";
import * as moment from "moment";
import { PaymentService } from "../../../../../shared/services/payment.service";
import { Router } from "@angular/router";
@Component({
  selector: "app-inspector-statistics",
  templateUrl: "./inspector-statistics.component.html",
  styleUrls: ["./inspector-statistics.component.scss"],
})
export class InspectorStatisticsComponent implements OnInit {
  user = null;
  latestBidNotification = null;
  statistics: InscpectionStatistics;
  constructor(
    private inspectorService: InspectorService,
    private paymentService: PaymentService,
    private router: Router
  ) {}

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("userDetails"));
    setTimeout(() => {
      this.inspectorService.getLatestBidOpportunity().subscribe(
        (res) => {
          if (res && res.id) {
            this.latestBidNotification = res;
          }
        },
        (error) => {
          // TODO: Handle error
          console.log(error);
        }
      );
    }, 1000);
    this.getInspectorStatistics();
    this.paymentService.creditSub().subscribe((res) => {
      this.getInspectorStatistics();
    });
  }

  getInspectorStatistics() {
    this.inspectorService
      .getPropertyStatistics()
      .subscribe((res: InscpectionStatistics) => (this.statistics = res));
  }

  purchaseCredits() {}

  navigateToBidOppurtunity() {
    this.router.navigate(["./user/inspector/bidOpportunity"], {
      queryParams: { propertyId: "" },
    });
  }
}

export interface InscpectionStatistics {
  Properties_Inspected: number;
  Inspections_Complete: number;
  Inspection_Credit_Remaining: number;
}
