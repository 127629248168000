import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData, JournalComponent } from '../journal.component';
import { PropertyService } from '../../../core/services/property/property.service';
import { JournalDetailService } from '../../../core/services/property/journal-detail.service';
import { PropertyJournalRequest } from '../../../core/models/property/journal.model';

@Component({
  selector: 'app-add-journal-dialog',
  templateUrl: './add-journal-dialog.component.html',
  styleUrls: ['./add-journal-dialog.component.scss']
})
export class AddJournalDialogComponent implements OnInit {

  public addJournalForm: FormGroup;
  public journalFormPayload = {} as PropertyJournalRequest;
  public isSpecefic: boolean;
  public isAuthority: boolean;
  public isOwner: boolean;
  public isInspector: boolean;

  constructor(
    public dialogRef: MatDialogRef<JournalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public fb: FormBuilder, private journalDetialServices: JournalDetailService,
    // private _snackBar: MatSnackBar,
    private propertyService: PropertyService) { }

  ngOnInit() {
    this.addJournalForm = this.fb.group({
      subject: [, Validators.required],
      accessType: ['Private', Validators.required],
      content: [, Validators.required]
    });

    this.isAuthority = false;
    this.isOwner = false;
    this.isInspector = false;
  }

  checkAuthority(event) {
    this.isAuthority = event.target.checked ? true : false;
  }
  checkOwner(event) {
    this.isOwner = event.target.checked ? true : false;
  }
  checkInspector(event) {
    this.isInspector = event.target.checked ? true : false;
  }

  setAccessType() : string{
    let accessType = this.addJournalForm.value.accessType.toString();
    if(accessType === 'Public'){
      return 'Public';
    } else if (accessType === 'Private'){
      return 'Private';
    } else if (accessType === 'Specific'){
      if(this.isAuthority && this.isInspector && this.isOwner){
        return 'Specific/Inspector,Authority,Owner';
      }else if(this.isInspector && this.isAuthority){
        return 'Specific/Inspector,Authority';
      }else if(this.isOwner && this.isInspector){
        return 'Specific/Owner,Inspector';
      }else if(this.isOwner && this.isAuthority) {
        return 'Specific/Owner,Authority';
      }else if(this.isInspector) {
        return 'Specific/Inspector';
      }else if(this.isOwner){
        return 'Specific/Owner';
      }else if (this.isAuthority){
        return 'Specific/Authority';
      }
    }
    return 'null';
  }

  onSubmit() {
    let accessType = this.setAccessType();
    if (this.addJournalForm.valid && accessType) {
      this.journalFormPayload = {
        subject: this.addJournalForm.value.subject.toString(),
        accessControl: accessType,
        body: this.addJournalForm.value.content.toString(),
        inspectionId: 0
      };
      const propertyId = this.data.propertyId || this.propertyService.propertyId$.getValue();
      this.journalDetialServices.postJournalEntry(this.journalFormPayload, propertyId).subscribe((res: any) => {
        alert('Journal Saved Successfully');
        this.dialogRef.close();
      }, (error: any) => {
        console.log(error);
        this.dialogRef.close();
      });
    } else {
      console.error('Empty Form');
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
