import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import * as moment from 'moment';
export var MY_FORMATS = {
    parse: {
        dateInput: 'MM-DD-YYYY'
    },
    display: {
        dateInput: 'MM-DD-YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'MM-DD-YYYY',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};
var ɵ0 = { useUtc: true };
var FilterDateDialogComponent = /** @class */ (function () {
    function FilterDateDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.toDateError = '';
        this.fromDateError = '';
    }
    FilterDateDialogComponent.prototype.ngOnInit = function () {
        this.data.dateRange.fromDate = moment(this.data.dateRange.fromDate, 'MM-DD-YYYY');
        this.data.dateRange.toDate = moment(this.data.dateRange.toDate, 'MM-DD-YYYY');
    };
    FilterDateDialogComponent.prototype.checkErrorInFromDate = function (fromDate) {
        fromDate = moment(this.data.dateRange.fromDate.toString()).format('MM-DD-YYYY');
        var toDate = moment(this.data.dateRange.toDate.toString()).format('MM-DD-YYYY');
        this.fromDateError = '';
        if (fromDate.length === 0) {
            return this.fromDateError;
        }
        if (fromDate.length !== 10) {
            this.fromDateError = 'Enter Valid Date';
        }
        var date = fromDate.split('-');
        if (date.length !== 3 ||
            date[0].length !== 2 ||
            date[1].length !== 2 ||
            date[2].length !== 4) {
            this.fromDateError = 'Enter Valid Date';
        }
        else if (!moment(fromDate, 'MM-DD-YYYY').isValid()) {
            this.fromDateError = 'Enter Valid Date';
        }
        else if (moment(fromDate).isAfter(moment(), 'day')) {
            this.fromDateError = 'Date should not be in future';
        }
        else if (fromDate && +moment(fromDate, 'MM-DD-YYYY')
            > +moment(toDate, 'MM-DD-YYYY')) {
            this.toDateError = 'To Date should be after From Date';
        }
        return this.fromDateError;
    };
    FilterDateDialogComponent.prototype.checkErrorInToDate = function (toDate) {
        this.toDateError = '';
        var fromDate = moment(this.data.dateRange.fromDate.toString()).format('MM-DD-YYYY');
        toDate = moment(this.data.dateRange.toDate.toString()).format('MM-DD-YYYY');
        if (toDate.length === 0) {
            return this.toDateError;
        }
        if (toDate.length !== 10) {
            this.toDateError = 'Enter Valid Date';
        }
        var date = toDate.split('-');
        if (date.length !== 3 ||
            date[0].length !== 2 ||
            date[1].length !== 2 ||
            date[2].length !== 4) {
            this.toDateError = 'Enter Valid Date';
        }
        else if (!moment(toDate, 'MM-DD-YYYY').isValid()) {
            this.toDateError = 'Enter Valid Date';
        }
        else if (moment(toDate).isAfter(moment(), 'day')) {
            this.toDateError = 'Date should not be in future';
        }
        else if (fromDate &&
            +moment(fromDate, 'MM-DD-YYYY') > +moment(toDate, 'MM-DD-YYYY')) {
            this.toDateError = 'To Date should be after From Date';
        }
        return this.toDateError;
    };
    FilterDateDialogComponent.prototype.search = function () {
        if (this.data.dateRange.address !== null) {
            this.data.dateRange.address = this.data.dateRange.address ? this.data.dateRange.address.trim() : this.data.dateRange.address;
        }
        if (this.data.dateRange.accessCode !== null) {
            this.data.dateRange.accessCode = this.data.dateRange.accessCode ?
                this.data.dateRange.accessCode.trim() : this.data.dateRange.accessCode;
        }
        this.dialogRef.close({ action: 'search', dateRange: this.data.dateRange });
    };
    FilterDateDialogComponent.prototype.cancel = function () {
        this.dialogRef.close({ action: 'cancel' });
    };
    return FilterDateDialogComponent;
}());
export { FilterDateDialogComponent };
export { ɵ0 };
