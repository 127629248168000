import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SignUpService = /** @class */ (function () {
    function SignUpService(http) {
        this.http = http;
        this.baseUrl = environment.baseUrl;
    }
    // public systemDetails$: BehaviorSubject<SystemDetails[]> = new BehaviorSubject<SystemDetails[]>(null);
    SignUpService.prototype.getSignUpRoles = function () {
        var url = this.baseUrl + '/signup/role';
        return this.http.get(url);
    };
    SignUpService.prototype.postSignUpDetails = function (data) {
        var url = this.baseUrl + '/signup';
        return this.http.post(url, data);
    };
    SignUpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SignUpService_Factory() { return new SignUpService(i0.ɵɵinject(i1.HttpClient)); }, token: SignUpService, providedIn: "root" });
    return SignUpService;
}());
export { SignUpService };
