import { OnInit } from '@angular/core';
var LoaderComponent = /** @class */ (function () {
    function LoaderComponent(loaderService) {
        this.loaderService = loaderService;
        this.color = 'primary';
        this.mode = 'indeterminate';
        this.value = 50;
    }
    LoaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loaderService.isLoading.subscribe(function (res) {
            _this.loading = res;
        });
    };
    return LoaderComponent;
}());
export { LoaderComponent };
