import { Component, OnInit, Input } from '@angular/core';
import { HeadingQuestion } from '../../../../core/models/inspection/inspection.model';

@Component({
  selector: 'app-fire-communication',
  templateUrl: './fire-communication.component.html',
  styleUrls: []
})
export class FireCommunicationComponent implements OnInit {
  @Input() questions: Array<HeadingQuestion>;
  @Input() qtd: Array<any>;
  @Input() reviewInspection: boolean;
  communicationTable;
  constructor() { }

  ngOnInit() {
    this.init();
  }

  init() {
    this.communicationTable = {
      phone: {
        'unitsInBuilding': this.findAnswerbyQuestion('Phone', 'unitsInBuilding') || '',
        'unitsTested': this.findAnswerbyQuestion('Phone', 'unitsTested') || '',
        'resultsAcceptable': this.findAnswerbyQuestion('Phone', 'resultsAcceptable') || 'N/A'
      },
      cellular: {
        'unitsInBuilding': this.findAnswerbyQuestion('Cellular', 'unitsInBuilding') || '',
        'unitsTested': this.findAnswerbyQuestion('Cellular', 'unitsTested') || '',
        'resultsAcceptable': this.findAnswerbyQuestion('Cellular', 'resultsAcceptable') || 'N/A'
      },
      radio: {
        'unitsInBuilding': this.findAnswerbyQuestion('Radio', 'unitsInBuilding') || '',
        'unitsTested': this.findAnswerbyQuestion('Radio', 'unitsTested') || '',
        'resultsAcceptable': this.findAnswerbyQuestion('Radio', 'resultsAcceptable') || 'N/A'
      }
    }
  }

  ngOnChanges(changes) {
    if (changes['questions'] && changes['questions'].currentValue) {
      this.questions = [...changes['questions'].currentValue];
      this.init();
    }
  }

  findAnswerbyQuestion(question, description) {
    let myQuestion = this.questions.filter(x => x.questions.question === question)
    if (myQuestion.length > 0) {
      if (myQuestion[0].questions &&
        myQuestion[0].questions.tableAnswers &&
        myQuestion[0].questions.tableAnswers.length > 0 &&
        myQuestion[0].questions.tableAnswers[0][description]) {
        this.updateModel(question, description, myQuestion[0].questions.tableAnswers[0][description]);
        return myQuestion[0].questions.tableAnswers[0][description]
      } else {
        if (description === 'resultsAcceptable') {
          this.updateModel(question, description, 'N/A');
          return 'N/A'
        } else {
          return '';
        }
      }
    }
    return;
  }

  findQuestionId(description: string) {
    let myQuestion = this.questions.filter(x => x.questions.question === description)
    if (myQuestion.length > 0) {
      return myQuestion[0].questions.questionId;
    }
    return 0;
  }

  updateModel(question, column, value) {
    const id = this.findQuestionId(question);
    if (!this.qtd[id] || typeof this.qtd[id][0] === 'string') {
      this.qtd[id] = {};
    }
    if (column === 'unitsInBuilding') {
      this.qtd[id][column] = value;
    } else if (column === 'unitsTested') {
      this.qtd[id][column] = value;
    } else if (column === 'resultsAcceptable') {
      this.qtd[id][column] = value;
    }
  }

}



