import { Component, Input, OnInit } from '@angular/core';
import { HeadingQuestion } from '../../../core/models/inspection/inspection.model';

@Component({
  selector: 'app-shaft',
  templateUrl: './shaft.component.html',
  styleUrls: ['./shaft.component.scss']
})
export class ShaftComponent implements OnInit {
  @Input() questions: Array<HeadingQuestion>;
  @Input() qtd: Array<any>;
  @Input() reviewInspection: boolean;
  constructor() { }

  ngOnInit() {
  }

  findQuestionId(description, table?) {
    if (!table) {
      if (this.questions.filter(x => x.questions.question === description) &&
        this.questions.filter(x => x.questions.question === description)[0]) {
        return this.questions.filter(x => x.questions.question === description)[0].questions.questionId;
      }
    } else {
      if (this.questions.filter(x => x.questions.question === description && x.questions.questionType === table) &&
        this.questions.filter(x => x.questions.question === description && x.questions.questionType === table)[0]) {
        return this.questions.filter(x => x.questions.question === description && x.questions.questionType === table)[0].
        questions.questionId;
      }
    }
    return 0;

  }


  findQuestionIdSecond(description, table) {
    if (this.questions.filter(x => x.questions.question === description && x.questions.questionType === table) &&
      this.questions.filter(x => x.questions.question === description && x.questions.questionType === table)[0]) {
      return this.questions.filter(x => x.questions.question === description && x.questions.questionType === table)[0].questions.questionId;
    }
    return 0;
  }
}
