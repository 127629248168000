import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// Dynamic Js - start
var head = document.getElementsByTagName('head')[0];
var js = document.createElement("script");

js.type = "text/javascript";

if (environment.baseUrl === 'https://api.tegrisfire.com/tegrisfire-backend/api')
{
    js.src = "https://static-na.payments-amazon.com/OffAmazonPayments/us/js/Widgets.js";
}
else
{
    js.src = "https://static-na.payments-amazon.com/OffAmazonPayments/us/sandbox/js/Widgets.js";
}

head.appendChild(js);
// Dynamic Js - end

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
