import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FileCabinetService } from '../../core/services/file-cabinet/file-cabinet.service';
import { LoggerService } from '../../core/services/logger/logger.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-assign-property',
  templateUrl: './assign-property.component.html',
  styleUrls: ['./assign-property.component.scss']
})
export class AssignPropertyComponent {

  selectedUser = '';
  unsub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<AssignPropertyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fileCabService: FileCabinetService,
    private loggerService: LoggerService
    ) { }

  assignProperty() {
    this.unsub = this.fileCabService.updatePropertyAssignment(this.selectedUser.split(',')[0], this.data.propertyIds).subscribe(
      result => {
        this.dialogRef.close('assigned');
      },
      error => {
        alert(error.error.message);
        this.loggerService.log(error); 
      }
    );
    
  }

  ngOnDestroy() {
    this.unsub && this.unsub.unsubscribe();
  }

  close(){
    this.dialogRef.close(null);
  }

}
