<div style="background: #F4F4F4; height: 100vh; margin: 0; width:100%">
  <div class="mt-sm-5 w-100">
<div class="container login-container mt-sm-5 p-0">
  <div class="log-div row">
     <div class="logo">
        <img alt="tegrisfire" src='./assets/images/tegris _logo.png' />
     </div>
     <form class="login-container-inner sign-up-inner " [formGroup]="signUpForm" (ngSubmit)="signUpNewUser($event)" autocomplete="off">
     <div class="form-title">
        Create a New Account
     </div>
     <div class="form-inner-wrapper">
     <div class="form-group row form-group-padding custom-form-control first-col-margin">
        <div class="col-12">
           <div class="row">
              <div class="col-4">
                 <label class="col-form-label col-form-label-sm inputLabel" style="font-size: 18px">First Name:<label class="colorRed">*</label></label>
              </div>
              <div class="col-8">
                 <input type="text" class="form-control form-control-sm rounded-form-fields" formControlName="firstName" />
                 <div *ngIf="errors.firstName && errors.firstName.length>0" class="error">
                  *{{errors.firstName}}
               </div>
              </div>

           </div>
     </div>
     </div>
     <div class="form-group row form-group-padding custom-form-control first-col-margin">
        <div class="col-12">
           <div class="row">
              <div class="col-4"><label class="col-form-label col-form-label-sm inputLabel"
                 >Last Name:<label class="colorRed">*</label></label></div>
              <div class="col-8">
                 <input type="text" class="form-control form-control-sm rounded-form-fields" formControlName="lastName" />
                 <div *ngIf="errors.lastName && errors.lastName.length>0" class="error">
                  *{{errors.lastName}}
               </div>
              </div>

           </div>
        </div>
    </div>
    <div class="form-group row form-group-padding custom-form-control first-col-margin">
        <div class="col-12">
           <div class="row">
              <div class="col-4"><label class="col-form-label col-form-label-sm inputLabel"
                 style="font-size: 18px">Phone
                 Number:<label class="colorRed">*</label></label>
              </div>
              <div class="col-8">
                 <input type="text" class="form-control form-control-sm rounded-form-fields" formControlName="phone" />
                 <div *ngIf="errors.phone && errors.phone.length>0" class="error">
                  *{{errors.phone}}
               </div>
              </div>

           </div>
        </div>
    </div>

     <div class="form-group row form-group-padding custom-form-control">
        <div class="col-12">
           <div class="row">
              <div class="col-4"><label class="col-form-label col-form-label-sm inputLabel"
                 >Email Address:<label class="colorRed">*</label></label></div>
              <div class="col-8">
                 <input type="text" id = "email" pattern="[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*" class="form-control form-control-sm rounded-form-fields" formControlName="email" />
                 <label *ngIf="!errors.email" class="col-form-label col-form-label-sm helpLabel">
                  Enter valid Email Address like abc@gmail.com
                  </label>
                  <div *ngIf="errors.email" class="error">
                  *{{errors.email}}
                  </div>
              </div>
              <div>

              </div>
           </div>
        </div>
    </div>
     <div class="form-group row form-group-padding custom-form-control">
        <div class="col-12">
           <div class="row">
              <div class="col-4"><label class="col-form-label col-form-label-sm inputLabel"
                 >Role:<label class="colorRed">*</label></label></div>
              <div class="col-md-8">
                 <select class="rounded-form-fields  no-Padding custom-selectrounded-form-fields  no-Padding custom-select ui-select-placeholder"
                    [value]="signUpForm.value.role" formControlName="role">
                    <option value="">Select</option>
                    <option *ngFor="let SignUpRoles of signUpRoles" [value]="SignUpRoles.id">
                    {{ SignUpRoles.description }}
                    </option>
                 </select>
                 <div *ngIf="errors.role && errors.role.length>0" class="error">
                  *{{errors.role}}
               </div>
              </div>
           </div>

        </div>
     </div>

     <div *ngIf="signUpForm.value.role==2">
        <div class="form-group row form-group-padding custom-form-control">
           <div class="col-12">
              <label class="col-form-label col-form-label-sm inputLabel colorThemeRed">
              Owner Details
              </label>
           </div>
        </div>

        <div class="form-group row form-group-padding custom-form-control">
           <div class="col-12"><label class="col-form-label col-form-label-sm inputLabel">Owner
              Address:</label>
           </div>
        </div>
        <div class="form-group row form-group-padding custom-form-control">
           <div class="col-12">
              <div class="row">
                 <div class="col-4"><label class="col-form-label col-form-label-sm inputLabel"
                    >Street Line 1:<label class="colorRed">*</label></label></div>
                 <div class="col-8">
                    <input type="text" class="form-control form-control-sm rounded-form-fields"
                       formControlName="Streetline1" />
                       <div *ngIf="errors.Streetline1 && errors.Streetline1.length>0" class="error">
                        *{{errors.Streetline1}}
                     </div>
                 </div>

              </div>
           </div>
        </div>
        <div class="form-group row form-group-padding custom-form-control">
     <div class="col-12">
              <div class="row">
           <div class="col-4"><label class="col-form-label col-form-label-sm inputLabel"
              >Apt/Suite/Other:</label></div>
           <div class="col-8">
              <input type="text" class="form-control form-control-sm rounded-form-fields" formControlName="Streetline2" />
           </div>
     </div>
     </div>
        </div>
        <div class="form-group row form-group-padding custom-form-control">
           <div class="col-12">
              <div class="row">
                 <div class="col-4"><label class="col-form-label col-form-label-sm inputLabel"
                    >Zip
                    Code:<label class="colorRed">*</label></label>
                 </div>
                 <div class="col-8">
                    <input type="text" class="form-control form-control-sm rounded-form-fields" maxlength=5
                       formControlName="Zipcode" />
                       <div *ngIf="errors.Zipcode && errors.Zipcode.length>0" class="error">
                        *{{errors.Zipcode}}
                     </div>
                 </div>

              </div>
           </div>
        </div>
     </div>
     <div *ngIf="signUpForm.value.role==3">
        <div class="form-group row form-group-padding custom-form-control">
           <div class="col-12">
              <label class="col-form-label col-form-label-sm inputLabel colorThemeRed">
              Authority Details
              </label>
           </div>
        </div>

        <div class="form-group row form-group-padding custom-form-control first-col-margin">
           <div class="col-12">
              <div class="row">
                 <div class="col-4"><label class="col-form-label col-form-label-sm inputLabel"
                    >Authority Name:<label class="colorRed">*</label></label>
                 </div>
                 <div class="col-8">
                    <input type="text" class="form-control form-control-sm rounded-form-fields" formControlName="orgName" />
          <label class="col-form-label col-form-label-sm"> Example: Seattle Fire
                    Department</label>
                    <div *ngIf="errors.orgName && errors.orgName.length>0" class="error">
                      *{{errors.orgName}}
                   </div>
                 </div>


              </div>
           </div>
        </div>
         <div class="form-group row form-group-padding custom-form-control first-col-margin">
           <div class="col-12"><label class="col-form-label col-form-label-sm inputLabel"
             >Authority Address:</label>
           </div>
        </div>
        <div class="form-group row form-group-padding custom-form-control first-col-margin">
           <div class="col-12">
              <div class="row">
                 <div class="col-4"><label class="col-form-label col-form-label-sm inputLabel"
                    >Street Line 1:<label class="colorRed">*</label></label></div>
                 <div class="col-8">
                    <input type="text" class="form-control form-control-sm rounded-form-fields"
                       formControlName="Streetline1" />
                       <div *ngIf="errors.Streetline1 && errors.Streetline1.length>0" class="error">
                        *{{errors.Streetline1}}
                     </div>
                 </div>

              </div>
           </div>
        </div>
        <div class="form-group row form-group-padding custom-form-control first-col-margin">
           <div class="col-4"><label class="col-form-label col-form-label-sm inputLabel"
              >Apt/Suite/Other:</label></div>
           <div class="col-8">
              <input type="text" class="form-control form-control-sm rounded-form-fields" formControlName="Streetline2" />
           </div>
        </div>
        <div class="form-group row form-group-padding custom-form-control first-col-margin">
           <div class="col-12">
              <div class="row">
                 <div class="col-4"><label class="col-form-label col-form-label-sm inputLabel"
                    style="font-size: 18px">Zip
                    Code:<label class="colorRed">*</label></label>
                 </div>
                 <div class="col-8">
                    <input type="text" class="form-control form-control-sm rounded-form-fields" maxlength=5
                       formControlName="Zipcode" />
                       <div *ngIf="errors.Zipcode && errors.Zipcode.length>0" class="error">
                        *{{errors.Zipcode}}
                     </div>
                 </div>

              </div>
           </div>
        </div>
         <div class="form-group row form-group-padding custom-form-control first-col-margin">
           <div class="col-12">
              <div class="row">
                 <div class="col-4"><label class="col-form-label col-form-label-sm inputLabel"
                    style="font-size: 18px">Jurisdiction Name:
                    <label class="colorRed">*</label></label>
                 </div>
                 <div class="col-8">
                    <input type="text" class="form-control form-control-sm rounded-form-fields"
                       formControlName="JurisdictionName"/>
                       <div class="error" *ngIf="errors.JurisdictionName && errors.JurisdictionName.length>0">
                         *{{errors.JurisdictionName}}
                        </div>
                        <label class="col-form-label col-form-label-sm">
                          Examples: WA, Seattle ; WA, City of Monroe
                        </label>
                 </div>


              </div>
           </div>
        </div>
     </div>
     <div *ngIf="signUpForm.value.role==4">
        <div class="form-group row ">
           <div class="col-12">
              <label class="col-form-label col-form-label-sm inputLabel colorThemeRed">
              Inspector Details
              </label>
           </div>
        </div>

        <div class="form-group row form-group-padding custom-form-control first-col-margin">
           <div class="col-12">
              <div class="row">
                 <div class="col-4"><label class="col-form-label col-form-label-sm inputLabel"
                    >Company Name:<label class="colorRed">*</label></label>
                 </div>
                 <div class="col-8">
                    <input type="text" class="form-control form-control-sm rounded-form-fields" formControlName="orgName" />
          <label class="col-form-label col-form-label-sm"> Example: ABC Inc.</label>
          <div *ngIf="errors.orgName && errors.orgName.length>0" class="error">
            *{{errors.orgName}}
         </div>
                 </div>


              </div>
           </div>
        </div>
       <div class="form-group row form-group-padding custom-form-control first-col-margin">
           <div class="col-12">
              <div class="">
                 <div><label class="col-form-label col-form-label-sm inputLabel"
                    >Company Address:</label>
                 </div>
              </div>
           </div>
        </div>
       <div class="form-group row form-group-padding custom-form-control first-col-margin">
           <div class="col-12">
              <div class="row">
                 <div class="col-4"><label class="col-form-label col-form-label-sm inputLabel"
                    >Street Line 1:<label class="colorRed">*</label></label></div>
                 <div class="col-8">
                    <input type="text" class="form-control form-control-sm rounded-form-fields"
                       formControlName="Streetline1" />
                       <div *ngIf="errors.Streetline1 && errors.Streetline1.length>0" class="error">
                        *{{errors.Streetline1}}
                     </div>
                 </div>

              </div>
           </div>
        </div>
        <div class="form-group row form-group-padding custom-form-control first-col-margin">
           <div class="col-4"><label class="col-form-label col-form-label-sm inputLabel"
              >Apt/Suite/Other:</label></div>
           <div class="col-8">
              <input type="text" class="form-control form-control-sm rounded-form-fields" formControlName="Streetline2" />
           </div>
        </div>
        <div class="form-group row form-group-padding custom-form-control first-col-margin">
           <div class="col-12">
              <div class="row">
                 <div class="col-4"><label class="col-form-label col-form-label-sm inputLabel"
                    >Zip
                    Code:<label class="colorRed">*</label></label>
                 </div>
                 <div class="col-8">
                    <input type="text" class="form-control form-control-sm rounded-form-fields" maxlength=5
                       formControlName="Zipcode" />
                       <div *ngIf="errors.Zipcode && errors.Zipcode.length>0" class="error">
                        *{{errors.Zipcode}}
                     </div>
                 </div>

              </div>
           </div>
        </div>
     </div>
     <div class="form-group row form-group-padding custom-form-control first-col-margin">
        <div class="col-12">
           <div class="row">
              <div class="col-12 captcha text-right">
                 <re-captcha siteKey="6Lf_pOkZAAAAAFL0Fp0Ry8szB8dZf705XAHhrO4L" (resolved)="resolved($event)"
                 [(ngModel)]="recaptchaVal" #captchaControl="ngModel" [ngModelOptions]="{standalone: true}">
                 </re-captcha>
                 <label style="text-decoration: underline" (click)="recaptchaVal = ''" style="cursor:pointer">Reset Captcha</label>
                 <div *ngIf="errors.recaptcha && errors.recaptcha.length>0" class="error">
                    *{{errors.recaptcha}}
                 </div>
              </div>

           </div>
        </div>
     </div>
    <div class="form-group row form-group-padding custom-form-control first-col-margin">
        <div class="col-12 text-center">
           <span class="col-custom-checkbox">
           <input class="custom-checkbox" type="checkbox" formControlName="tncCheck" />
           </span>
           <span>
           <label class="custom-message">I agree to <a href="http://www.tegrisinc.com/legal/tc">Terms & Conditions</a></label>
           </span>
        </div>
        <div *ngIf="errors.tncCheck && errors.tncCheck.length>0" class="error col-12 text-center">
           *{{errors.tncCheck}}
        </div>
     </div>
    <div class="form-group row form-group-padding custom-form-control first-col-margin">
        <button class="custom-buttons sign-up-button" type="submit" [disabled]="buttonDisabled">
        Sign up
        </button>


     </div>
     <div class="form-group row form-group-padding custom-form-control first-col-margin">
       <div class="col-12 pl-0 pr-0">
       <div class="row">
      <div class="col-6">
      <button class="custom-buttons float-left" type="reset" (click)="resetAll()">
        Reset
        </button>
      </div>
      <div class="col-6 text-right">
        <button class="custom-buttons" type="button" (click)="backToLogin()">
          Cancel
          </button>
        </div>
     </div>
    </div>
    </div>
    </div>
     </form>
  </div>
</div>
</div>
</div>
