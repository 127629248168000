import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './app-logo.component.html',
  styleUrls: ['./app-logo.component.scss']
})
export class AppLogoComponent implements OnInit {
  constructor() {}
  isWorx: boolean;

  ngOnInit() {

    this.isWorx = JSON.parse(localStorage.getItem('userDetails')).role.name === 'ROLE_AUTHORITY_WORX' ?
      true : false

  }
}
