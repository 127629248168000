<app-dialog>
  <span class="dialog-title">
    Delete System
    <span class="float-right">
      <mat-icon class="cursor-pointer" (click)="deletionRejected()">close</mat-icon>
    </span>
  </span>
  <div class="dialog-content">
    <div class="form-group form-group-padding">
      <label style="padding-left: 10px" class="col-form-label col-form-label-sm inputLabel notes-sub-heading">
        Are you sure you want to delete this item ?
      </label>
    </div>
  </div>
  <div class="dialog-actions">
      <button class="custom-buttons" (click)="deletionApproved()">Delete</button>
      <button class="custom-buttons" (click)="deletionRejected()">Cancel</button>
  </div>
</app-dialog>
