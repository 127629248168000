import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var StandpipeHoseComponent = /** @class */ (function () {
    function StandpipeHoseComponent(insService) {
        this.insService = insService;
    }
    StandpipeHoseComponent.prototype.ngOnInit = function () {
        this.hose = [{ id: 0 }];
        if (this.insService.getHoseCount() > 0) {
            for (var i = 1; i < this.insService.getHoseCount(); i++) {
                this.hose.push({ id: i });
            }
        }
        if (this.insService.getHoseRows() && this.insService.getHoseRows().length > 0) {
            this.hose = this.insService.getHoseRows();
            for (var m = 0; m < this.hose.length; m++) {
                this.qtd[this.findQuestionIdSecond('Hose Location(floor/Stair)') + '~hose~' + m + '~'] =
                    JSON.parse(JSON.stringify(this.hose[m]['Hose Location(floor/Stair)'])) || '';
                this.qtd[this.findQuestionIdSecond('Size(inches)') + '~hose~' + m + '~'] =
                    JSON.parse(JSON.stringify(this.hose[m]['Size(inches)'])) || '';
                this.qtd[this.findQuestionIdSecond('Length(ft)') + '~hose~' + m + '~'] =
                    JSON.parse(JSON.stringify(this.hose[m]['Length(ft)'])) || '';
                this.qtd[this.findQuestionIdSecond('Evaluation') + '~hose~' + m + '~'] =
                    JSON.parse(JSON.stringify(this.hose[m].Evaluation)) || '';
                this.qtd[this.findQuestionIdSecond('Test Psi') + '~hose~' + m + '~'] =
                    JSON.parse(JSON.stringify(this.hose[m]['Test Psi'])) || '';
                this.qtd[this.findQuestionIdSecond('Comments') + '~hose~' + m + '~'] =
                    JSON.parse(JSON.stringify(this.hose[m].Comments)) || '';
            }
        }
        else {
            if (this.insService.getHoseRows() <= 0) {
                this.hose = [{ id: 0 }];
            }
        }
    };
    StandpipeHoseComponent.prototype.addRow = function () {
        this.hose.push({ id: this.hose.length });
        this.insService.setHoseCount(this.hose.length);
    };
    StandpipeHoseComponent.prototype.deleteRow = function (index) {
        var e_1, _a;
        this.hose = this.hose.filter(function (x) { return x.id !== index.id; });
        try {
            for (var _b = tslib_1.__values(Object.keys(this.qtd)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var key = _c.value;
                if (key.split('~') && key.indexOf('hose') > 0) {
                    delete this.qtd[key];
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.rebuildDOM();
        this.insService.setHoseCount(this.hose.length);
    };
    StandpipeHoseComponent.prototype.findQuestionIdSecond = function (description) {
        if (this.questions) {
            if (this.questions.filter(function (x) { return x.questions.question === description; }) &&
                this.questions.filter(function (x) { return x.questions.question === description; })[0]) {
                return this.questions.filter(function (x) { return x.questions.question === description; })[0].questions.questionId;
            }
        }
        return 0;
    };
    StandpipeHoseComponent.prototype.updateModel = function (question, id, k) {
        this.qtd[this.findQuestionIdSecond(question) + '~hose~' + id + '~'] = JSON.parse(JSON.stringify(k[question]));
        console.log(this.qtd);
    };
    StandpipeHoseComponent.prototype.rebuildDOM = function () {
        for (var m = 0; m < this.hose.length; m++) {
            this.qtd[this.findQuestionIdSecond('Hose Location(floor/Stair)') + '~hose~' + m + '~'] =
                this.hose[m]['Hose Location(floor/Stair)'] ? JSON.parse(JSON.stringify(this.hose[m]['Hose Location(floor/Stair)'])) : '';
            this.qtd[this.findQuestionIdSecond('Size(inches)') + '~hose~' + m + '~'] =
                this.hose[m]['Size(inches)'] ? JSON.parse(JSON.stringify(this.hose[m]['Size(inches)'])) : '';
            this.qtd[this.findQuestionIdSecond('Length(ft)') + '~hose~' + m + '~'] =
                this.hose[m]['Length(ft)'] ? JSON.parse(JSON.stringify(this.hose[m]['Length(ft)'])) : '';
            this.qtd[this.findQuestionIdSecond('Evaluation') + '~hose~' + m + '~'] =
                this.hose[m].Evaluation ? JSON.parse(JSON.stringify(this.hose[m].Evaluation)) : '';
            this.qtd[this.findQuestionIdSecond('Test Psi') + '~hose~' + m + '~'] =
                this.hose[m]['Test Psi'] ? JSON.parse(JSON.stringify(this.hose[m]['Test Psi'])) : '';
            this.qtd[this.findQuestionIdSecond('Comments') + '~hose~' + m + '~'] =
                this.hose[m].Comments ? JSON.parse(JSON.stringify(this.hose[m].Comments)) : '';
        }
    };
    return StandpipeHoseComponent;
}());
export { StandpipeHoseComponent };
