<app-dialog>
  <span class="dialog-title">
    <span class="title">Edit Property/Owner details</span>
    <span class="float-right">
      <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
    </span>
  </span>
  <div class="dialog-content">
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-5">
            <label class=" inputLabel inspection">Building Name:</label>
          </div>
          <div class="col-7">
            <label class=" contacts-content-font breakWord text-fields"
              *ngIf="propDetails">{{propDetails.buildingName}}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <label class=" inputLabel inspection">Occupancy Type:</label>
          </div>
          <div class="col-7">
            <label class=" contacts-content-font breakWord text-fields"
              *ngIf="propDetails">{{propDetails.propertyType}}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <label class=" inputLabel inspection">Construction Type:</label>
          </div>
          <div class="col-7">
            <label class=" contacts-content-font breakWord text-fields"
              *ngIf="propDetails">{{propDetails.buildingType}}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <label class=" inputLabel inspection">Floor Area(sq ft):</label>
          </div>
          <div class="col-7">
            <label class=" contacts-content-font breakWord text-fields"
              *ngIf="propDetails">{{propDetails.floorArea}}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <label class=" inputLabel inspection">Manager:</label>
          </div>
          <div class="col-7">
            <label class=" contacts-content-font breakWord text-fields"
              *ngIf="propDetails && propDetails.propertyContacts && propDetails.propertyContacts[0] && propDetails.propertyContacts[0].isManager === true">{{propDetails.propertyContacts[0].firstName + ' ' + propDetails.propertyContacts[0].lastName }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <label class=" inputLabel inspection">Manager Address:</label>
          </div>
          <div class="col-7">
            <label class=" contacts-content-font breakWord text-fields" [innerHTML]="managerAddress || ''"></label>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <label class=" inputLabel inspection">Property Address:</label>
          </div>
          <div class="col-7">
            <label class=" contacts-content-font breakWord text-fields"
              *ngIf="propDetails" [innerHTML]="propDetails.propertyAddress || ''"></label>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <label class=" inputLabel inspection">Property Owner:</label>
          </div>
          <div class="col-7">
            <label class=" contacts-content-font breakWord text-fields"
              *ngIf="propDetails && propDetails.ownerId">{{propDetails.ownerId.firstName + " " + propDetails.ownerId.lastName}}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <label class=" inputLabel inspection">Owner Address:</label>
          </div>
          <div class="col-7">
            <label class=" contacts-content-font breakWord text-fields"
              *ngIf="propDetails && propDetails.ownerId" [innerHTML]="propDetails.ownerId.address || ''"></label>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="row">
          <div class="col-5">
            <label class=" inputLabel inspection">Status:</label>
          </div>
          <div class="col-7">
            <label class=" contacts-content-font breakWord text-fields"
              *ngIf="propDetails">{{propDetails.isActive === true ? 'Active': 'Inactive' }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <label class=" inputLabel inspection">Occupancy Sub Type:</label>
          </div>
          <div class="col-7">
            <label class=" contacts-content-font breakWord text-fields"
              *ngIf="propDetails">{{propDetails.propertySubType}}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <label class=" inputLabel inspection">Number of Stories:</label>
          </div>
          <div class="col-7">
            <label class=" contacts-content-font breakWord text-fields"
              *ngIf="propDetails">{{propDetails.numberOfStories}}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <label class=" inputLabel inspection">Year Build:</label>
          </div>
          <div class="col-7">
            <label class=" contacts-content-font breakWord text-fields"
              *ngIf="propDetails">{{propDetails.yearBuilt}}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <label class=" inputLabel inspection">Phone Number:</label>
          </div>
          <div class="col-7">
            <label class=" contacts-content-font breakWord text-fields"
              *ngIf="propDetails && propDetails.propertyContacts && propDetails.propertyContacts[0] && propDetails.propertyContacts[0].isManager === true">{{propDetails.propertyContacts[0].phoneNum}}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <label class=" inputLabel inspection">Email Address:</label>
          </div>
          <div class="col-7">
            <label class=" contacts-content-font breakWord text-fields"
              *ngIf="propDetails && propDetails.propertyContacts && propDetails.propertyContacts[0] && propDetails.propertyContacts[0].isManager === true">{{propDetails.propertyContacts[0].email}}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <label class=" inputLabel inspection"></label>
          </div>
          <div class="col-7">
            <label class=" contacts-content-font breakWord text-fields"></label>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <label class=" inputLabel inspection">Owner Phone No.:</label>
          </div>
          <div class="col-7">
            <label class=" contacts-content-font breakWord text-fields"
              *ngIf="propDetails && propDetails.ownerId">{{propDetails.ownerId.cotactNumber }}</label>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
    <div class="paa" style="color:#c20c0b;font-size:20px;font-weight: bold;">Recommended Changes Pending Authority
      Approval
    </div>
    <hr>
    <div style="margin: 0 -30px; width: 100%;">
    <app-property-details [isEditDetail]='isEditDetail' (close)="close();" [propertyIdForEdit]='propertyIdForEdit' [propertyDetails]="propDetails">
    </app-property-details>
  </div>
  </div>
</app-dialog>


