import { ElementRef } from '@angular/core';
var FormFocusInvalidDirective = /** @class */ (function () {
    function FormFocusInvalidDirective(el) {
        this.el = el;
    }
    FormFocusInvalidDirective.prototype.onFormSubmit = function () {
        var invalidControl = this.el.nativeElement.querySelector('.ng-invalid');
        if (invalidControl) {
            invalidControl.focus();
            invalidControl.scrollIntoView();
        }
    };
    return FormFocusInvalidDirective;
}());
export { FormFocusInvalidDirective };
