import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var JournalDetailService = /** @class */ (function () {
    function JournalDetailService(http) {
        this.http = http;
        this.baseUrl = environment.baseUrl;
        this.notes = new BehaviorSubject(null);
        this.journal = new BehaviorSubject(null);
    }
    // GET Methods
    JournalDetailService.prototype.getNotesForProperty = function (propertyId) {
        var url = this.baseUrl + ("/property/" + propertyId + "/propertyNote");
        return this.http.get(url);
    };
    JournalDetailService.prototype.getPropertyContactsByPropertyId = function (propertyId) {
        var url = this.baseUrl + ("/property/" + propertyId + "/propertyContact");
        return this.http.get(url);
    };
    JournalDetailService.prototype.getJournalDetails = function (propertyId) {
        var url = this.baseUrl + ("/propertyjournals/" + propertyId);
        return this.http.get(url);
    };
    JournalDetailService.prototype.getAssetJournals = function (propertyId) {
        var url = this.baseUrl + ("/propertyAssetJournals/" + propertyId);
        return this.http.get(url);
    };
    JournalDetailService.prototype.getNoteByNoteId = function (noteId) {
        var url = this.baseUrl + ("/propertyNote/" + noteId);
        return this.http.get(url);
    };
    // POST Methods
    JournalDetailService.prototype.postNotesForProperty = function (data) {
        var url = this.baseUrl + ("/property/" + data[0].propertyId + "/propertyNote");
        return this.http.post(url, data);
    };
    JournalDetailService.prototype.postJournalEntry = function (data, propertyId) {
        var url = this.baseUrl + ("/propertyjournals/" + propertyId);
        return this.http.post(url, data);
    };
    // PUT Methods
    JournalDetailService.prototype.putNotesDisplaySequence = function (data) {
        var url = this.baseUrl + '/propertyNote';
        return this.http.put(url, data);
    };
    JournalDetailService.prototype.putNotesForProperty = function (data) {
        var url = this.baseUrl + "/propertyNote";
        return this.http.put(url, data);
    };
    JournalDetailService.prototype.putPropertyContactsDisplaySequence = function (data) {
        var url = this.baseUrl + '/propertyContact';
        return this.http.put(url, data);
    };
    // DELETE Methods
    JournalDetailService.prototype.deleteNote = function (noteId) {
        var url = this.baseUrl + "/propertyNote/" + noteId;
        return this.http.delete(url);
    };
    JournalDetailService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JournalDetailService_Factory() { return new JournalDetailService(i0.ɵɵinject(i1.HttpClient)); }, token: JournalDetailService, providedIn: "root" });
    return JournalDetailService;
}());
export { JournalDetailService };
