import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
// Dynamic Js - start
var head = document.getElementsByTagName('head')[0];
var js = document.createElement("script");
js.type = "text/javascript";
if (environment.baseUrl === 'https://api.tegrisfire.com/tegrisfire-backend/api') {
    js.src = "https://static-na.payments-amazon.com/OffAmazonPayments/us/js/Widgets.js";
}
else {
    js.src = "https://static-na.payments-amazon.com/OffAmazonPayments/us/sandbox/js/Widgets.js";
}
head.appendChild(js);
// Dynamic Js - end
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch(function (err) { return console.error(err); });
