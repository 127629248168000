import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
var ContactDialogComponent = /** @class */ (function () {
    function ContactDialogComponent(dialogRef, data, fb, journalDetailServices) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.fb = fb;
        this.journalDetailServices = journalDetailServices;
    }
    ContactDialogComponent.prototype.ngOnInit = function () {
        this.getAllContacts();
    };
    ContactDialogComponent.prototype.getAllContacts = function () {
        var _this = this;
        this.journalDetailServices
            .getPropertyContactsByPropertyId(this.data.propertyId)
            .subscribe(function (res) {
            res.sort(function (a, b) {
                return a.displaySequence - b.displaySequence;
            });
            _this.contacts = res;
            if (_this.contacts.length > 5) {
                _this.contacts.length = 5;
            }
            console.log(res);
        });
    };
    ContactDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    // drop(event: CdkDragDrop<string[]>) {
    //   moveItemInArray(this.contacts, event.previousIndex, event.currentIndex);
    //   for (let i = 0; i < this.contacts.length; i++){
    //     this.contacts[i].displaySequence = i;
    //   }
    //   this.putContactsDisplaySequence(this.contacts);
    // }
    ContactDialogComponent.prototype.putContactsDisplaySequence = function (data) {
        this.journalDetailServices.putPropertyContactsDisplaySequence(data).subscribe(function (res) {
        }, function (error) {
            console.log('Error while updating diplay sequence');
        });
    };
    return ContactDialogComponent;
}());
export { ContactDialogComponent };
