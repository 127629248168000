import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ParentComponent } from '../../../shared/components/parent.component';
import { PropertyService } from '../../../core/services/property/property.service';
import { InspectionPropertyInfo } from '../../../core/models/inspection/inspection.model';
import { LoggerService } from '../../../core/services/logger/logger.service';
import { NotificationTemplate } from '../../models/admin.model';
import { AdminService } from '../../services/admin.service';
import { switchMap, catchError } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-property',
  templateUrl: './search-property.component.html',
  styleUrls: ['./search-property.component.scss']
})
export class SearchPropertyComponent extends ParentComponent implements OnInit {

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @ViewChild('properties', { static: false }) paginator: MatPaginator;
  columnsToDisplay: string[] = [ 'blank', 'name', 'address'];
  searchText = '';
  propertyList: InspectionPropertyInfo[];
  selectedPropertyId = -1;
  unsub: Subscription[];

  constructor(public dialogRef: MatDialogRef<SearchPropertyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private propertyService: PropertyService,
    private adminService: AdminService,
    private loggerService: LoggerService) {
    super();
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.propertyList = [];
    this.unsub.push(this.propertyService.getPropertyListByJurisdictionId(this.data.userDetailsByRoleId).subscribe(
      res => {
        this.propertyList = res;
        this.dataSource.paginator = this.paginator
        this.dataSource.data = this.propertyList;
      },
      error => {this.loggerService.log(error); }
    ));
  }

  ngOnDestroy() {
    this.unsub.map(elem => elem.unsubscribe());
  }

  searchByPropName() {
    const filteredProperties = this.propertyList.filter(
      property =>
      (property.propertyName || '').toLowerCase().includes(this.searchText.toLowerCase())
    );
    this.dataSource.data = filteredProperties;
  }

  selectProperty(propertyId) {
    this.selectedPropertyId = propertyId;
  }

  previewSamplePDF() {
    if (this.selectedPropertyId !== -1) {
      const requestBody: NotificationTemplate = {
        ...this.data,
      };
      this.unsub.push(this.adminService.getTemplatePreview(requestBody, this.selectedPropertyId).subscribe(
        res => {
          window.open(res.data, '_blank');
          setTimeout(() => {
            this.unsub.push(this.adminService.deleteTemplate(res.data)
              .subscribe(res => {
                // Do nothing
              }))
          }, 10000);
        },
        catchError(
          error => {
            if (error && error.error && error.error.message) {
              this.loggerService.log(error.error.message);
            }
            return [];
          }
        )
      ));
    }
  }

  close() {
    this.dialogRef.close(null);
  }

}
