import { MatDialogRef } from '@angular/material/dialog';
var ConfirmationDialog = /** @class */ (function () {
    function ConfirmationDialog(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    ConfirmationDialog.prototype.ngOnInit = function () {
    };
    ConfirmationDialog.prototype.areYouSureNo = function () {
        this.dialogRef.close(false);
    };
    ConfirmationDialog.prototype.areYouSureYes = function () {
        this.dialogRef.close(true);
    };
    return ConfirmationDialog;
}());
export { ConfirmationDialog };
