/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./email-sent-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/app-dialog/app-dialog.component.ngfactory";
import * as i3 from "../../../shared/components/app-dialog/app-dialog.component";
import * as i4 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "./email-sent-dialog.component";
import * as i7 from "@angular/material/dialog";
import * as i8 from "../../services/login.service";
var styles_EmailSentDialogComponent = [i0.styles];
var RenderType_EmailSentDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmailSentDialogComponent, data: {} });
export { RenderType_EmailSentDialogComponent as RenderType_EmailSentDialogComponent };
export function View_EmailSentDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "app-dialog", [], null, null, null, i2.View_AppDialogComponent_0, i2.RenderType_AppDialogComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppDialogComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "span", [["class", "dialog-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Password Generated "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "mat-icon", [["class", "cursor-pointer mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(8, 0, null, 1, 5, "div", [["class", "dialog-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["style", "margin:20px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "form-group row form-group-padding custom-form-control"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "label", [["class", "col-form-label col-form-label-sm inputLabel"], ["style", "font-size: 18px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" Your new auto generated password has been sent to your email address: ", ""])), (_l()(), i1.ɵeld(14, 0, null, 2, 2, "div", [["class", "dialog-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["cdkFocusInitial", ""], ["class", "custom-buttons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 6).inline; var currVal_1 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_2 = _co.data.email; _ck(_v, 13, 0, currVal_2); }); }
export function View_EmailSentDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-email-sent-dialog", [], null, null, null, View_EmailSentDialogComponent_0, RenderType_EmailSentDialogComponent)), i1.ɵdid(1, 114688, null, 0, i6.EmailSentDialogComponent, [i7.MatDialogRef, i7.MAT_DIALOG_DATA, i8.LoginService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmailSentDialogComponentNgFactory = i1.ɵccf("app-email-sent-dialog", i6.EmailSentDialogComponent, View_EmailSentDialogComponent_Host_0, {}, {}, []);
export { EmailSentDialogComponentNgFactory as EmailSentDialogComponentNgFactory };
