import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserDetails } from '../shared/models/user.model';

@Injectable()
export class AuthGuard implements CanActivate {
  public userDetails: UserDetails;
  public token: string;

  constructor(private router: Router) {}

  canActivate(): boolean {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.token = sessionStorage.getItem('token');
    
    if (this.token && this.token.length > 0) {
      const parsedToken = this.parseJwt(this.token);
      if (Date.now() >= parsedToken.exp * 1000) {
        this.router.navigate(['login/login-form']);
        return false;
      }
      if (
        this.userDetails.isForcePasswordChanged &&
        !this.router.url.includes('login/login-form') &&
        !this.router.url.includes('login/force-change-password')
      ) {
        // this.router.navigate(["login/force-change-password"]);
        return false;
      } else if (
        this.userDetails.isForcePasswordChanged &&
        this.router.url.includes('login/force-change-password')
      ) {
        return false;
      } else if (
        !this.userDetails.isPreferenceSet &&
        this.router.url.toLowerCase().includes('preference')
      ) {
        return false;
      }
      return true;
    } else {
      
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
       if(params.has('asp-au') && params.has('asp-ap'))
       {
        this.router.navigate(['login/login-form'], { queryParams: { 'asp-au': params.get('asp-au'), 'asp-ap': params.get('asp-ap')} });
       }
       else{
      this.router.navigate(['login/login-form']);

       }
    }
  }

  parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };
}
