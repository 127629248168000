import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { MatDialogRef, MatDialog, } from "@angular/material/dialog";
import * as moment from "moment";
import { frequencyValues } from "./config";
import { Roles } from "../../shared/models/user.model";
import { DatePipe } from "@angular/common";
export var MY_FORMATS = {
    parse: {
        dateInput: "MM-DD-YYYY",
    },
    display: {
        dateInput: "MM-DD-YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "MM-DD-YYYY",
        monthYearA11yLabel: "MMMM YYYY",
    },
};
var ɵ0 = { useUtc: true };
var AddSystemDialog = /** @class */ (function () {
    function AddSystemDialog(dialogRef, data, propertyService, contactsService, inspectorService, inspectionService, dialog, datepipe) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.propertyService = propertyService;
        this.contactsService = contactsService;
        this.inspectorService = inspectorService;
        this.inspectionService = inspectionService;
        this.dialog = dialog;
        this.datepipe = datepipe;
        this.subscriptions = [];
        this.systemDetails = null;
        this.maxDate = null;
        this.minDate = null;
        this.user = null;
        this.assetAlreadyExist = false;
        this.newProperty = false;
    }
    AddSystemDialog.prototype.ngOnInit = function () {
        var _this = this;
        this.userRole = localStorage.getItem("userRole");
        this.userDepartment =
            JSON.parse(localStorage.getItem("userDetails")).role.name ===
                "ROLE_AUTHORITY_WORX"
                ? "TEGRIS Worx"
                : "TEGRIS Fire";
        this.user = JSON.parse(localStorage.getItem("userDetails"));
        this.newProperty = this.data.isNewProperty;
        this.inspectorService.getInspectorSettings().subscribe(function (x) {
            _this.settings = x;
        });
        if (!(this.user.role.id === Roles.ROLE_SITE_ADMIN ||
            this.user.role.id === Roles.ROLE_AUTHORITY_ADMIN ||
            this.user.role.id === Roles.ROLE_AUTHORITY_USER ||
            this.user.role.id === Roles.ROLE_AUTHORITY_WORX ||
            this.user.role.id === Roles.ROLE_RFA)) {
            this.minDate = new Date();
            this.minDate.setDate(this.minDate.getDate() - 45);
        }
        this.description = this.data.description;
        this.validDetails = true;
        this.errors = {
            systemType: null,
            assetName: null,
            inspectFreq: null,
            systemLocation: null,
            assetCoverage: null,
        };
        this.subscriptions.push(this.propertyService
            .getCustomAssetOptions(this.data.jurisId)
            .subscribe(function (data) {
            _this.assetTypes = data;
            _this.SystemTypes = tslib_1.__spread(new Set(_this.assetTypes.map(function (items) { return items.systemType; })));
            if (_this.data.addEditObject == null) {
                _this.systemDetails = {
                    assetCoverage: "",
                    assetInspectionFrequency: "",
                    assetLocation: "",
                    assetType: "",
                    inspectionDueDate: "",
                    isActive: true,
                    isLocationFinalized: false,
                    propertyId: _this.data.propId,
                    systemType: "",
                };
                _this.systemDetails.systemType = _this.userDepartment;
                _this.populateAssetType();
                _this.populateInspectFreq();
            }
            else {
                _this.systemDetails = {
                    assetCoverage: _this.data.addEditObject.assetCoverage,
                    assetInspectionFrequency: _this.data.addEditObject.assetInspectionFrequency,
                    assetLocation: _this.data.addEditObject.assetLocation,
                    assetType: _this.data.addEditObject.assetType,
                    id: _this.data.addEditObject.id,
                    inspectionDueDate: _this.data.addEditObject.inspectionDueDate,
                    isActive: _this.data.addEditObject.isActive,
                    isLocationFinalized: _this.data.addEditObject.isLocationFinalized,
                    propertyId: _this.data.addEditObject.propertyId,
                    systemType: _this.data.addEditObject.systemType,
                };
                _this.populateAssetType();
                _this.maxDateByFrequency();
            }
        }));
        this.subscriptions.push(this.propertyService.getInspectionFreqOptions().subscribe(function (data) {
            _this.inspectionFreqOptions = data;
        }));
        this.subscriptions.push(this.propertyService
            .isAddingNewSystemAllowedDataForDepartment(this.data.jurisId)
            .subscribe(function (res) {
            _this.isAddingNewSystemAllowedData = res;
        }));
        this.isAddingNewSystemAllowedWorx = null;
        this.subscriptions.push(this.propertyService
            .isAddingNewSystemAllowedDataForJuridiction(this.data.jurisId)
            .subscribe(function (res) {
            _this.isAddingNewSystemAllowedWorx = res;
        }));
        setTimeout(function () {
            console.log(_this.systemDetails);
            console.log(_this.userRole), console.log(_this.newProperty);
        }, 5000);
    };
    AddSystemDialog.prototype.ngAfterViewInit = function () {
        var _this = this;
        var jurisId = this.data.jurisId;
        if (jurisId && jurisId !== null) {
            this.inspectionService.getAllowedSystems(jurisId).subscribe(function (res) {
                _this.listOfAllowedSystems = res;
            });
        }
    };
    AddSystemDialog.prototype.enableDisableField = function (condition) {
        if (((this.userRole === 'ROLE_AUTHORITY' || this.userRole === "ROLE_RFA") && this.systemDetails.systemType === 'TEGRIS WorX') ||
            this.userRole === 'ROLE_AUTHORITY_WORX' && this.systemDetails.systemType === 'TEGRIS Fire') {
            return true;
        }
        return condition;
    };
    AddSystemDialog.prototype.save = function () {
        var _this = this;
        if (this.systemDetails &&
            this.systemDetails.systemType === "TEGRIS WorX" &&
            this.isAddingNewSystemAllowedWorx &&
            (!this.isAddingNewSystemAllowedWorx["TEGRIS WorX"] ||
                this.isAddingNewSystemAllowedWorx["TEGRIS WorX"] === false)) {
            alert("There is no Tegris WorX account for this jurisdiction. Please contact support@tegrisfire.com for additional information");
            return;
        }
        var isAddingNewSystemAllowed;
        isAddingNewSystemAllowed =
            this.isAddingNewSystemAllowedData[this.systemDetails.systemType];
        var assetId = -1;
        if (this.settings) {
            if (!this.settings["assetTypes"].find(function (x) { return x.data === _this.systemDetails.assetType; }) &&
                this.systemDetails.assetType.indexOf("Custom") < 0) {
                if (this.assetTypes.find(function (item) {
                    return item.assetTypeName === _this.systemDetails.assetType;
                })) {
                    assetId = this.assetTypes.find(function (item) {
                        return item.assetTypeName === _this.systemDetails.assetType;
                    }).id;
                }
                if (assetId > 0) {
                    if (confirm("System " + this.systemDetails.assetType + " is not associated with your Inspection Company's profile. Click ok to add this system to your profile.")) {
                        this.settings["assetTypes"].push({
                            id: assetId,
                            data: this.systemDetails.assetType,
                        });
                        this.inspectorService.putInspectorPreferencesSettings(this.settings);
                    }
                    else {
                        this.disableButton = false;
                        return;
                    }
                }
            }
        }
        this.errors = {
            systemType: null,
            assetName: null,
            inspectFreq: null,
            systemLocation: null,
            assetCoverage: null,
        };
        if (!this.systemDetails.systemType ||
            this.systemDetails.systemType.length === 0) {
            this.errors.systemType = "Enter department type of system.";
            this.validDetails = false;
        }
        if (!this.systemDetails.assetType ||
            this.systemDetails.assetType.length === 0) {
            this.errors.assetName = "Enter system name of system.";
            this.validDetails = false;
        }
        if (!this.systemDetails.assetInspectionFrequency ||
            this.systemDetails.assetInspectionFrequency.length === 0) {
            this.errors.inspectFreq = "Enter Inspection Frequency of system.";
            this.validDetails = false;
        }
        if (!this.systemDetails.inspectionDueDate ||
            this.systemDetails.inspectionDueDate.length === 0) {
            this.dateErrorMessage = "Enter Inspection Due Date";
            this.validDetails = false;
        }
        if (this.systemDetails.assetLocation &&
            this.systemDetails.assetLocation.length > 128) {
            this.errors.systemLocation =
                "System Location should not be more than 128 characters.";
            this.validDetails = false;
        }
        if (this.systemDetails.assetCoverage &&
            this.systemDetails.assetCoverage.length > 128) {
            this.errors.assetCoverage =
                "Coverage Area should note be more than 128 characters.";
            this.validDetails = false;
        }
        if (this.systemDetails.assetType &&
            this.systemDetails.assetType.length > 0) {
            if (this.listOfAllowedSystems &&
                this.listOfAllowedSystems.findIndex(function (elem) { return elem.assetTypeName === _this.systemDetails.assetType; }) < 0) {
                this.validDetails = false;
                alert("The Fire Marshal is not currently accepting reports for this type of system.");
            }
        }
        if (this.validDetails === true) {
            if (this.data.description.includes("Add")) {
                // this.systemDetails.inspectionDueDate = this.systemDetails.inspectionDueDate.format('YYYY-MM-DD');
                // const newDate = new Date(this.systemDetails.inspectionDueDate._d);
                // newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
                // this.systemDetails.inspectionDueDate = newDate;
                // this.systemDetails.inspectionDueDate = this.datepipe.transform(this.systemDetails.inspectionDueDate, 'yyyy-MM-dd');
                this.systemDetails.inspectionDueDate = moment(this.systemDetails.inspectionDueDate).format("YYYY-MM-DD");
                this.systemDetails.additionalInformation = {
                    isNewProperty: true && this.newProperty,
                };
                if (isAddingNewSystemAllowed &&
                    (this.user.role.id == Roles.ROLE_OWNER_ADMIN ||
                        this.user.role.id == Roles.ROLE_OWNER_USER ||
                        this.user.role.id == Roles.ROLE_INSPECTOR_ADMIN ||
                        this.user.role.id == Roles.ROLE_INSPECTOR_USER)) {
                    if (confirm("The information you provided will be sent to the Fire Marshal's office for approval." +
                        "The new system will be in your File Cabinet and marked as inactive.  You will receive an email notification" +
                        " once it is reviewed.  Do you want to continue?")) {
                        this.systemDetails.isActive = false;
                        this.performApiCalls();
                    }
                }
                else {
                    this.performApiCalls();
                }
            }
            else if (this.data.description.includes("Edit")) {
                this.systemDetails.inspectionDueDate = moment(this.systemDetails.inspectionDueDate).format("YYYY-MM-DD");
                if (this.IsUserInspectorOrAdmin()) {
                    this.dialogRef.close(this.systemDetails);
                    return;
                }
                this.disableButton = true;
                this.subscriptions.push(this.propertyService.putSystemDetails(this.systemDetails).subscribe(function (res) {
                    _this.disableButton = false;
                    _this.systemDetails = res;
                    _this.dialogRef.close(_this.systemDetails);
                }, function (error) {
                    _this.disableButton = false;
                    console.log(error);
                }));
            }
        }
    };
    AddSystemDialog.prototype.IsUserInspectorOrAdmin = function () {
        return (this.user.role.id == Roles.ROLE_INSPECTOR_ADMIN ||
            this.user.role.id == Roles.ROLE_INSPECTOR_USER ||
            this.user.role.id == Roles.ROLE_OWNER_ADMIN ||
            this.user.role.id === Roles.ROLE_OWNER_USER);
    };
    AddSystemDialog.prototype.performApiCalls = function () {
        var _this = this;
        if (this.IsUserInspectorOrAdmin() && this.systemDetails.propertyId === null) {
            if (this.data.localSystemDetails && this.data.localSystemDetails.length > 0 && this.systemDetails.isActive) {
                var existingSystems = this.data.localSystemDetails.filter(function (o) { return o.assetType === _this.systemDetails.assetType && o.assetLocation === _this.systemDetails.assetLocation; });
                if (existingSystems.length > 0) {
                    alert('Asset already exists at same location.');
                    return;
                }
            }
            console.log('api call restricted');
            this.dialogRef.close(this.systemDetails);
            return;
        }
        if (this.systemDetails && this.systemDetails.propertyId === null) {
            this.subscriptions.push(this.propertyService
                .postPropertyDetails(this.propertyService.propertyDetails$.getValue())
                .subscribe(function (res) {
                //  this.creationDisabled = false;
                if (res.streetLine1) {
                    _this.propertyService.propertySaved.next(true);
                    _this.propertyService.propertyDetails$.next(res);
                    _this.propertyService.propertyId$.next(res.id);
                    _this.contactsService.propertyID.next(res.id);
                    _this.propertyService.oldProperyDetailsModel$.next(tslib_1.__assign({}, _this.propertyService.propertyDetails$.getValue()));
                    _this.systemDetails.propertyId = res.id;
                    _this.postPropertyContats(res.id);
                    _this.postSystemDetails();
                }
                else {
                    alert("Duplicate address"); //todo will moved in first screen
                }
            }, function (error) {
                console.log(error);
                if (error &&
                    error.error &&
                    error.error.message &&
                    error.error.message.includes("Property Already exists")) {
                    alert("This address is already registered with TEGRIS FireTM.");
                }
                else {
                    alert("Unexpected error occured");
                }
                // this.currentButton = null;
            }));
        }
        else {
            this.postSystemDetails();
        }
    };
    AddSystemDialog.prototype.postSystemDetails = function () {
        var _this = this;
        this.disableButton = true;
        this.subscriptions.push(this.propertyService.postSystemDetails(this.systemDetails).subscribe(function (res) {
            _this.disableButton = false;
            _this.systemDetails = res;
            _this.dialogRef.close(_this.systemDetails);
        }, function (error) {
            _this.disableButton = false;
            alert(error.error.message);
        }));
    };
    AddSystemDialog.prototype.postPropertyContats = function (propertyId) {
        if (this.contactsService.newpropertyContacts.getValue() &&
            this.contactsService.newpropertyContacts.getValue().length > 0) {
            this.subscriptions.push(this.contactsService
                .addNewContacts(this.contactsService.newpropertyContacts.value, propertyId)
                .subscribe(function (res) {
                // alert('contacts saved successfully');
            }));
        }
    };
    AddSystemDialog.prototype.close = function () {
        this.dialogRef.close();
    };
    // toFormattedDate(inspectionDueDate: any){
    //   if(moment(inspectionDueDate).isValid()){
    //     return moment(inspectionDueDate).format("MM-DD-YYYY")
    //   }
    //   return '';
    // }
    AddSystemDialog.prototype.validateDate = function (inspectionDueDate) {
        var dateformat = /^(0?[1-9]|1[0-2])[\-](0?[1-9]|[1-2][0-9]|3[01])[\-]\d{4}$/;
        // Match the date format through regular expression
        if (inspectionDueDate.match(dateformat)) {
            var operator = inspectionDueDate.split("-");
            // Extract the string into month, date and year
            var datepart = [];
            if (operator.length > 1) {
                datepart = inspectionDueDate.split("-");
            }
            var month = parseInt(datepart[0]);
            var day = parseInt(datepart[1]);
            var year = parseInt(datepart[2]);
            // Create list of days of a month
            var ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
            if (year < 1000 || year > 3000 || month === 0 || month > 12) {
                return "";
            }
            if (month == 1 || month > 2) {
                if (day > ListofDays[month - 1]) {
                    ///This check is for Confirming that the date is not out of its range
                    return "";
                }
            }
            else if (month == 2) {
                var leapYear = false;
                if ((!(year % 4) && year % 100) || !(year % 400)) {
                    leapYear = true;
                }
                if (leapYear == false && day >= 29) {
                    return "";
                }
                else if (leapYear == true && day > 29) {
                    return "";
                }
            }
        }
        else {
            return "";
        }
        return inspectionDueDate;
    };
    AddSystemDialog.prototype.getErrorMessage = function (pickerInput) {
        if (typeof this.systemDetails.inspectionDueDate !== "undefined") {
            if (!pickerInput || pickerInput === "") {
                this.validDetails = false;
                this.dateErrorMessage = "*Please Choose a date";
                return this.dateErrorMessage;
            }
            else if (pickerInput.length !== 10 ||
                pickerInput.indexOf("/") >= 0 ||
                pickerInput.indexOf("-") < 0) {
                this.validDetails = false;
                this.dateErrorMessage = "*Invalid Date Format";
                return "*Invalid Date Format";
            }
            else if (!this.validateDate(pickerInput)) {
                this.validDetails = false;
                this.dateErrorMessage = "*Invalid Date Format";
                return "*Invalid Date Format";
            }
            else if (this.minDate &&
                this.maxDate &&
                (+this.minDate > +this.systemDetails.inspectionDueDate ||
                    +this.systemDetails.inspectionDueDate > +this.maxDate)) {
                this.validDetails = false;
                return "Please enter past date up to 45 days or future date up to one inspection cycle.";
            }
            else {
                var date = pickerInput.split("-");
                if (date.length !== 3 ||
                    date[0].length !== 2 ||
                    date[1].length !== 2 ||
                    date[2].length !== 4) {
                    this.validDetails = false;
                    this.dateErrorMessage = "*Invalid Date Format";
                }
                else if (!moment(pickerInput, "MM-DD-YYYY").isValid()) {
                    this.validDetails = false;
                    this.dateErrorMessage = "*Invalid Date";
                }
                else {
                    this.dateErrorMessage = "";
                    this.validDetails = true;
                    //this.disableButton = false;
                    // this.systemDetails.inspectionDueDate = moment(pickerInput).toDate();
                }
            }
        }
        if (this.assetAlreadyExist) {
            this.assetAlreadyExist = false;
            //this.disableButton = false;
            return "Asset type already present at this location. Kindly choose some other location or try adding another asset.";
        }
    };
    AddSystemDialog.prototype.updateCalcs = function (event) {
        console.log(moment(event.value).toDate());
        this.dateErrorMessage = "";
    };
    AddSystemDialog.prototype.toFormattedDate = function (formattedDate) {
        // if (formattedDate !== '') {
        //   const fullDate = new Date(formattedDate);
        //   const date =
        //     fullDate.getDate() < 10 ? '0' + fullDate.getDate() : fullDate.getDate();
        //   const month =
        //     fullDate.getMonth() < 10
        //       ? '0' + (fullDate.getMonth() + 1)
        //       : fullDate.getMonth() + 1;
        //   this.systemDetails.inspectionDueDate =  `${month}-${date}-${fullDate.getFullYear()}`;
        // }
        // this.systemDetails.inspectionDueDate =  '';
    };
    //asset typ asset inspection freq
    AddSystemDialog.prototype.populateAssetType = function () {
        var sysType = this.systemDetails.systemType;
        // this.systemDetails.assetType = '';
        // this.systemDetails.assetInspectionFrequency = '';
        if (sysType && sysType.length > 0) {
            this.SystemTypesList = this.assetTypes
                .filter(function (items) { return items.systemType === sysType; })
                .map(function (assets) { return assets.assetTypeName; });
        }
        else {
            this.SystemTypesList = null;
        }
    };
    AddSystemDialog.prototype.populateInspectFreq = function () {
        var assetName = this.systemDetails.assetType;
        if (assetName && assetName.length > 0) {
            this.InspectionFrequency = this.assetTypes.find(function (item) {
                return item.assetTypeName === assetName;
            }).inspectionFrequencyDescription;
            this.systemDetails.assetInspectionFrequency = this.InspectionFrequency;
        }
        else {
            this.systemDetails.assetInspectionFrequency = null;
            this.InspectionFrequency = null;
        }
        this.maxDateByFrequency();
    };
    AddSystemDialog.prototype.maxDateByFrequency = function () {
        if (!(this.user.role.id == Roles.ROLE_SITE_ADMIN ||
            this.user.role.id == Roles.ROLE_AUTHORITY_ADMIN ||
            this.user.role.id == Roles.ROLE_AUTHORITY_USER ||
            this.user.role.id === Roles.ROLE_AUTHORITY_WORX ||
            this.user.role.id === Roles.ROLE_RFA)) {
            var maxDate = frequencyValues[this.systemDetails.assetInspectionFrequency];
            this.maxDate = maxDate;
        }
    };
    AddSystemDialog.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (x) {
            x.unsubscribe();
        });
    };
    return AddSystemDialog;
}());
export { AddSystemDialog };
export { ɵ0 };
