<div class="flexbox-container">
  <div class="row" style="width: 100%;">
    <div [ngClass]="isViewMode ? 'col-12': 'col-9'">
      <div class="custom-padding">
        <span class="custom-container-headings">{{
          "Inspection Result"
          }}</span>

      </div>
      <div class="inspection col-12">
        <div class="row form-group-padding" *ngIf="!showInspectionQuestions && isSimplifiedReportEnabled">
          <label class="col-4 col-form-label col-form-label-sm inputLabel" style="font-weight: bold;">
            Type of Report
          </label>
          <div class="col-6">
            <select [disabled]="(assetTypeName && assetTypeName.indexOf('Custom') >=0) || isViewMode"
              [(ngModel)]="selectedReport" class="rounded-form-fields no-Padding custom-select">
              <option *ngFor="let report of reports" class="rounded-form-fields no-Padding" value="{{ report.id }}">
                {{report['data']}}
              </option>
            </select>
          </div>
        </div>
        <label class="heading">
          Property/Owner Details
        </label>
        <hr>
        <div class="row h6 text-dark">
          <div class="col-6">
            <div class="row">
              <div class="col-5">
                <label class="font-weight-bold inputLabel inspection">Property Name:</label>
              </div>
              <div class="col-7">
                <label class="font-weight-bold contacts-content-font breakWord text-fields">{{property.name}}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-5">
                <label class="font-weight-bold inputLabel inspection">Property Address:</label>
              </div>
              <div class="col-7">
                <label class="font-weight-bold contacts-content-font breakWord text-fields"
                  [innerHTML]="property.address || ''"></label>
              </div>
            </div>
            <div class="row">
              <div class="col-5">
                <label class="font-weight-bold inputLabel inspection">Property Email Address:</label>
              </div>
              <div class="col-7">
                <label class="font-weight-bold contacts-content-font breakWord text-fields">{{propertyEmail}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class="col-5">
                <label class="font-weight-bold inputLabel inspection">Property Manager:</label>
              </div>
              <div class="col-7">
                <label
                  class="font-weight-bold contacts-content-font breakWord text-fields">{{property.manager.name}}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-5">
                <label class="font-weight-bold inputLabel inspection">Property Owner:</label>
              </div>
              <div class="col-7">
                <label class="font-weight-bold contacts-content-font breakWord text-fields">{{owner.name}}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-5">
                <label class="font-weight-bold inputLabel inspection">Owner Address:</label>
              </div>
              <div class="col-7">
                <label class="font-weight-bold contacts-content-font breakWord text-fields"
                  [innerHTML]="owner.address || ''"></label>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col-5">
                <label class="font-weight-bold inputLabel inspection">Manager Phone No.:</label>
              </div>
              <div class="col-7">
                <label
                  class="font-weight-bold contacts-content-font breakWord text-fields">{{property.manager.number}}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-5">
                <label class="font-weight-bold inputLabel inspection">Owner Phone No:</label>
              </div>
              <div class="col-7">
                <label
                  class="font-weight-bold contacts-content-font breakWord text-fields">{{owner.primaryContact}}</label>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div *ngIf="showInspectionQuestions" class="enter-Inspection">
          <label class="heading">
            Disclaimer
          </label>
          <hr>
          <div class="row h6 text-dark">
            <div class="col-12 disclaimer">
              Refer to the local and state standards for a complete description of the inspection, testing, and
              maintenance requirements. The inspection results provided in this report are based only on the system's
              current ability to operate and shall not be construed as an evaluation of the system's performance
              capabilities to adequately protect the property. Information in this report may or may not include
              possible
              performance concerns and should not be considered all inclusive. It is solely the property owner's
              responsibility to determine if a separate investigation should be done to ensure the system will perform
              as
              designed and that the property is adequately protected. It is also the property owner's responsibility
              to
              maintain the system, property and any possible environmental conditions that may affect the system's
              operability and performance.
            </div>
          </div>
          <hr>
          <label class="heading" *ngIf="selectedReport !== 'SIMPLIFIED_REPORT'">
            Questionnaire
          </label>
          <label class="heading" *ngIf="selectedReport === 'SIMPLIFIED_REPORT'">
            A Simplified Report allows you to input your company report by using the “Upload File” button below.
          </label>
          <hr>
          <app-questions [questions]="questions" [qtd]="qtd" [inspectionResult]="inspectionResult"
            (backButton)='backButton()' (emitErrorMessages)="showErrorMessages($event);"
            [dialogRef]="viewReportData && viewReportData.dialogRef ? viewReportData.dialogRef : ''"
            [viewReportData]="viewReportData"
            [inspectionDataforSecondPage]="inspectionDataforSecondPage" [images]="images"></app-questions>
        </div>

        <div *ngIf="!showInspectionQuestions">
          <label class="heading">
            General details
          </label>
          <hr>
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-5">
                  <label class="font-weight-bold inputLabel inspection">System Type:</label>
                </div>
                <div class="col-6">
                  <label class="font-weight-bold contacts-content-font breakWord text-fields">{{assetTypeName}}</label>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-5">
                  <label class="font-weight-bold inputLabel inspection">Property Access Code:</label>
                </div>
                <div class="col-7">
                  <label
                    class="font-weight-bold contacts-content-font breakWord text-fields">{{property.accessCode}}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="commercialHood">
            <div class="col-12">
              <div class="row">
                <div class="col-3">
                  <label class="font-weight-bold inputLabel inspection">Hood Type:<label
                      style="color: red">*</label></label>
                </div>
                <div class="col-9">
                  <div style="display:inline;margin-right:2px;">
                    <input [disabled]="isViewMode" id="kitchen" type="radio" value="Kitchen/Range Hood" name="kitchen"
                      ngModel required [(ngModel)]="hoodSelected" (change)="setHood($event.target.value)">&nbsp;
                    <label class="inputmessage text-fields" style="font-weight: bold;">Kitchen/Range
                      Hood</label>&nbsp;&nbsp;&nbsp;
                    <input [disabled]="isViewMode" id="paintBooth" type="radio" value="Paint Spray Booth"
                      name="paintBooth" ngModel required [(ngModel)]="hoodSelected"
                      (change)="setHood($event.target.value)">&nbsp;
                    <label class="inputmessage text-fields" style="font-weight: bold;">Paint Spray
                      Booth</label>&nbsp;&nbsp;&nbsp;
                    <input [disabled]="isViewMode" id="fume" type="radio" value="Fume Hood" name="fume" ngModel required
                      [(ngModel)]="hoodSelected" (change)="setHood($event.target.value)">&nbsp;
                    <label class="inputmessage text-fields" style="font-weight: bold;">Fume
                      Hood</label>&nbsp;&nbsp;&nbsp;
                    <input [disabled]="isViewMode" id="others" type="radio" value="Others" name="others" ngModel
                      required [(ngModel)]="hoodSelected" (change)="setHood($event.target.value)">&nbsp;
                    <label class="inputmessage text-fields" style="font-weight: bold;">Others</label>&nbsp;&nbsp;&nbsp;
                    <input [disabled]="isViewMode" id="othersText" type="input" name="othersText" ngModel required
                      [(ngModel)]="othersText" *ngIf="hoodSelected ==='Others'" class="rounded-form-fields"
                      style="padding:5px;width:150px" (change)="setHood($event.target.value , true)">&nbsp;
                  </div>
                  <div *ngIf="submitted && checkHoodSelected() " class="alert alert-danger">
                    * Enter Hood Type.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-5">
                  <label class="font-weight-bold inputLabel inspection">System Location:<label
                      style="color: red">*</label></label>
                </div>
                <div class="col-7">
                  <input [disabled]="isViewMode" type="text" class="rounded-form-fields" name="system-location"
                    id="systemLocation" [(ngModel)]="systemLocation" required
                    (change)="setSystemLocation($event.target.value)" />
                  <div class="subMessages"> example: Riser Room, 2nd Fl. Electrical Room.</div>
                  <div *ngIf="submitted && !systemLocation" class="alert alert-danger">
                    * Enter System Location field.
                  </div>
                  <div *ngIf="submitted && systemLocation && systemLocation.length > 128" class="alert alert-danger">
                    * System Location should not be more than 128 characters.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-5">
                  <label *ngIf="assetTypeName.toUpperCase() !== 'BACKFLOW'"
                    class="font-weight-bold inputLabel inspection">Coverage Area:<label
                      style="color: red">*</label></label>
                  <label *ngIf="assetTypeName.toUpperCase() === 'BACKFLOW'"
                    class="font-weight-bold inputLabel inspection">Route & Service:<label
                      style="color: red">*</label></label>
                </div>
                <div class="col-7">
                  <input [disabled]="isViewMode" type="text" class="rounded-form-fields" name="coverage-area"
                    id="coverageArea" [(ngModel)]="coverageArea" required
                    (change)="setSystemCoverage($event.target.value)" />
                  <div *ngIf="assetTypeName.toUpperCase() !== 'BACKFLOW'" class="subMessages">example: Whole Bldg.,
                    Basement Area, Office only.</div>
                  <div *ngIf="assetTypeName.toUpperCase() === 'BACKFLOW'" class="subMessages">examples: 03-0046,
                    12-1226, 37-0001, 27-00.
                  </div>
                  <div *ngIf="submitted && !coverageArea" class="alert alert-danger">
                    * Enter Coverage Area field.
                  </div>
                  <div
                    *ngIf="assetTypeName.toUpperCase() !== 'BACKFLOW' && submitted && coverageArea && coverageArea.length > 128"
                    class="alert alert-danger">
                    * Coverage Area should not be more than 128 characters.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-5">
                  <label class="font-weight-bold inputLabel inspection">Jurisdiction Name:</label>
                </div>
                <div class="col-7">
                  <label
                    class="font-weight-bold contacts-content-font breakWord text-fields">{{property.juristrictionName}}</label>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-5">
                  <label class="font-weight-bold inputLabel inspection">State Contractor License No.</label>
                </div>
                <div class="col-7">
                  <label
                    class="font-weight-bold contacts-content-font breakWord text-fields">{{contractorLicense}}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-5">
                  <label class="font-weight-bold inputLabel inspection">Inspection Frequency:</label>
                </div>
                <div class="col-7">
                  <label
                    class="font-weight-bold contacts-content-font breakWord text-fields">{{inspectionFrequency}}</label>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-4">
                  <label class="font-weight-bold inputLabel inspection">Certification Given :</label>
                </div>
                <div class="col-8">
                  <div style="display:inline;margin-right:2px;">
                    <input [disabled]="isViewMode" id="red" type="radio" value="Red" name="red" ngModel
                      [(ngModel)]="certificationGiven" (change)="setColor($event.target.value)">&nbsp;
                    <label class="inputmessage text-fields">Red</label>&nbsp;&nbsp;&nbsp;
                    <input [disabled]="isViewMode" id="yellow" type="radio" value="Yellow" name="yellow" ngModel
                      [(ngModel)]="certificationGiven" (change)="setColor($event.target.value)">&nbsp;
                    <label class="inputmessage text-fields">Yellow</label>&nbsp;&nbsp;&nbsp;
                    <input [disabled]="isViewMode" id="white" type="radio" value="White" name="white" ngModel
                      [(ngModel)]="certificationGiven" (change)="setColor($event.target.value)">&nbsp;
                    <label class="inputmessage text-fields">White</label>&nbsp;&nbsp;&nbsp;
                    <button [disabled]="isViewMode" md-button class="restore-button" (click)="clear()">Clear</button>
                  </div>
                  <div class="subMessages">* Per Local Authority if Required</div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <label class="heading">
            Certification details
          </label>
          <hr>
          <div class="row">

            <div class="col-6" *ngIf="userRole !== 'ROLE_INSPECTOR'">
              <div class="row">
                <div class="col-5">
                  <label class="font-weight-bold inputLabel inspection">Inspection Company:<label
                      style="color: red">*</label></label>
                </div>
                <div class="col-6">
                  <select [disabled]="isViewMode" class="rounded-form-fields no-Padding custom-select"
                    [(ngModel)]="inspectionResult.inspectedById" (change)="getCertifiedInspectors();">
                    <option *ngFor=" let Company of inspectionCompanies" [value]="Company.id">
                      {{Company.data}}
                    </option>
                  </select>
                  <div *ngIf="submitted && inspectionResult.inspectedById < 0" class="alert alert-danger">
                    * Enter Inspection Company.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-5">
                  <label class="font-weight-bold inputLabel inspection">Inspected By:<label
                      style="color: red">*</label></label>
                </div>
                <div class="col-6">
                  <select [disabled]="isViewMode" class="rounded-form-fields no-Padding custom-select"
                    [(ngModel)]="inspectionResult.inspectedByCertInspectorId" (change)="onChange($event)"
                    ng-options="i.value as (i.label + '-' + i.value) for inspector in inspectors">
                    <ng-container *ngFor=" let inspector of inspectors">


                      <option [value]="inspector.id" [ngStyle]="getInspectionOptionsStyle(inspector)">
                        {{inspector.firstName + ' ' + inspector.middleName + ' ' + inspector.lastName }}
                      </option>



                      <!-- <option *ngIf = "inspector.isBlocked !== true " [value]="inspector.id">
                      {{inspector.firstName + ' ' + inspector.middleName + ' ' + inspector.lastName }}
                    </option>
                    <option  *ngIf = "inspector.isBlocked === true "  [value]="inspector.id" style="background-color: lightgray;">
                      {{inspector.firstName + ' ' + inspector.middleName + ' ' + inspector.lastName }}
                    </option> -->
                    </ng-container>
                  </select>
                  <div *ngIf="submitted && (inspectionResult.blockedInspector || !(inspectionResult.inspectedByCertInspectorId))" class="alert alert-danger">
                    * Enter a valid Inspector.
                  </div>
                  <div *ngIf="submitted && inspectionResult.inspectedByCertInspectorId < 0" class="alert alert-danger">
                    <!-- * Enter Inspected By field.-->
                    <label *ngIf="userRole === 'ROLE_INSPECTOR'">* Check Preferences for valid Inspector
                      Certifications.</label>
                    <label *ngIf="userRole !== 'ROLE_INSPECTOR'">* Enter Inspected By field.</label>
                  </div>
                  <div *ngIf="submitted && isInspectorExpired" class="alert alert-danger">
                    <!-- * Enter Inspected By field.-->
                    <label>* Check Inspector Preferences for valid Certifications.</label>
                  </div>
                </div>

              </div>
            </div>

            <div class="col-6" *ngIf="userRole === 'ROLE_INSPECTOR'">
              <div class="row">
                <div class="col-5">
                  <label class="font-weight-bold inputLabel inspection">State Certification No.</label>
                </div>
                <div class="col-7">
                  <a class="font-weight-bold contacts-content-font breakWord text-fields certificate"
                    *ngIf="certificateURL" (click)="newTabImage(certificateURL)">{{stateCertificationNumber}}</a>
                  <label class="font-weight-bold contacts-content-font breakWord text-fields"
                    *ngIf="!certificateURL">{{stateCertificationNumber}}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="userRole === 'ROLE_INSPECTOR'">
            <div class="col-6">
              <div class="row">
                <div class="col-5">
                  <label class="font-weight-bold inputLabel inspection">National/NICET No.:</label>
                </div>
                <div class="col-7">
                  <label
                    class="font-weight-bold contacts-content-font breakWord text-fields">{{nationalNICETNumber}}</label>
                </div>
              </div>
            </div>
            <!-- <div class="col-6" *ngIf="userRole === 'ROLE_INSPECTOR'">
              <div class="row">
                <div class="col-5">
                  <label class="font-weight-bold inputLabel inspection">Other Cert.</label>
                </div>
                <div class="col-7">
                  <label
                    class="font-weight-bold contacts-content-font breakWord text-fields">{{otherCert}}</label> -->
            <!-- <input [disabled]="isViewMode" type="text" class="rounded-form-fields" name="otherCert" id="otherCert" [(ngModel)]="otherCert"
                    (change)="setOtherCert($event.target.value)" /> -->
            <!-- </div>
              </div>
            </div> -->
          </div>
          <div *ngIf="userRole === 'ROLE_INSPECTOR'">
            <div class="row" *ngFor="let certificates of additionalCertificateList">
              <div class="col-6">
                <div class="row">
                  <div class="col-5">
                    <label class="font-weight-bold inputLabel inspection">{{certificates.certificationName ? certificates.certificationName : "Additional Certification"}}&nbsp;No.:</label>
                  </div>
                  <div class="col-7">
                    <label class="font-weight-bold contacts-content-font breakWord text-fields">{{certificates.certificationNumber}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="userRole === 'ROLE_INSPECTOR'">
            <div class="col-6">
              <div class="row">
                <div class="col-5">
                  <label class="font-weight-bold inputLabel inspection">Phone No.:</label>
                </div>
                <div class="col-7">
                  <label
                    class="font-weight-bold contacts-content-font breakWord text-fields">{{inspectorphoneNum}}</label>
                </div>
              </div>
            </div>
            <!-- <div class="col-6">
              <div class="row">
                <div class="col-5">
                  <label class="font-weight-bold inputLabel inspection">Type</label>
                </div>
                <div class="col-7">
                  <label class="font-weight-bold contacts-content-font breakWord text-fields" name="inspectorType" id="inspectorType"
                    [(ngModel)]="inspectorType" (change)='setType($event.target.value);' >{{inspectorType}}</label>
                </div>
              </div>
            </div> -->
          </div>
          <hr *ngIf="assetTypeName.toUpperCase() !== 'BACKFLOW'">
          <label *ngIf="assetTypeName.toUpperCase() !== 'BACKFLOW'" class="heading">
            Monitoring details
          </label>
          <hr *ngIf="assetTypeName.toUpperCase() !== 'BACKFLOW'">
          <div *ngIf="assetTypeName.toUpperCase() !== 'BACKFLOW'" class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-5">
                  <label class="font-weight-bold inputLabel inspection">Central station monitoring?</label>
                </div>
                <div class="col-7">
                  <div style="display:inline;margin-right:2px;">
                    <input [disabled]="isViewMode" id="yes" type="radio" value="Yes" name="yes_c" ngModel required
                      [(ngModel)]="monitoringOption" (change)="setCentralMonitor($event.target.value)">&nbsp;
                    <label class="inputmessage text-fields">Yes</label>&nbsp;&nbsp;&nbsp;
                    <input [disabled]="isViewMode" id="no" type="radio" value="No" name="no_c" ngModel required
                      [(ngModel)]="monitoringOption" (change)="setCentralMonitor($event.target.value)">&nbsp;
                    <label class="inputmessage text-fields">No</label>&nbsp;&nbsp;&nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="monitoringOption === 'Yes'">
            <div class="col-6">
              <div class="row">
                <div class="col-5">
                  <label class="font-weight-bold inputLabel inspection">Monitoring Company Name:</label>
                </div>
                <div class="col-7">
                  <input [disabled]="isViewMode" type="text" class="rounded-form-fields no-Padding"
                    name="monitoringCompanyName" id="monitoringCompanyName" [(ngModel)]="monitoringCompanyName"
                    (change)="setMonitorCompany($event.target.value)" />
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="monitoringOption === 'Yes'">
            <div class="col-6">
              <div class="row">
                <div class="col-5">
                  <label class="font-weight-bold inputLabel inspection">Control Panel Manufacturer:</label>
                </div>
                <div class="col-7">
                  <input [disabled]="isViewMode" type="text" class="rounded-form-fields no-Padding" name="Model Number:"
                    id="panelManufacturer" [(ngModel)]="panelManufacturer"
                    (change)="setControlPanel($event.target.value)" />
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="row">
                <div class="col-5">
                  <label class="font-weight-bold inputLabel inspection no-Padding">Model Number:</label>
                </div>
                <div class="col-7">
                  <input [disabled]="isViewMode" type="text" class="rounded-form-fields" name="Model Number:"
                    id="modelNumber" [(ngModel)]="modelNumber" (change)="setModelNumber($event.target.value)" />
                </div>
              </div>
            </div>
          </div>
          <hr>
          <label class="heading">
            Deficiency details
          </label>
          <hr>
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-5">
                  <label class="font-weight-bold inputLabel inspection">Any deficiency found?</label>
                </div>
                <div class="col-7">
                  <div style="display:inline;margin-right:2px;">
                    <input [disabled]="isViewMode" id="yes_d" type="radio" value="Yes" name="yes_d" ngModel required
                      [(ngModel)]="defOption" (change)="setDeficiency($event.target.value)">&nbsp;
                    <label class="inputmessage text-fields">Yes</label>&nbsp;&nbsp;&nbsp;
                    <input [disabled]="isViewMode" id="no_d" type="radio" value="No" name="no_d" ngModel required
                      [(ngModel)]="defOption" (change)="setDeficiency($event.target.value)">&nbsp;
                    <label class="inputmessage text-fields">No</label>&nbsp;&nbsp;&nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="inspectionResult && inspectionResult['isDeficiencyFound']">
            <div class="col-6 def-message">
              List each deficiency separately. Use the "Add" button to enter additional deficiencies. If the deficiency
              was corrected during the inspection check the box and enter appropriate comments.
            </div>
          </div>
          <div class="col-12" *ngIf="inspectionResult && inspectionResult['isDeficiencyFound']">
            <div class="row">
              <div class="col-2 custom-offset">
                <label class="font-weight-bold inputLabel inspection">Corrected</label>
              </div>
              <div class="col-5">
                <label class="font-weight-bold inputLabel inspection">Deficiency </label>
              </div>
            </div>
            <div *ngFor="let deficiency of deficiencies;let i = index" [attr.data-index]="i" class="row">
              <div class="col-3">
                <div class="row">
                  <button [disabled]="isViewMode" type="button" value="Add" class="custom-buttons def-button"
                    *ngIf="deficiencies.length !== 1" (click)="deleteDeficiency(deficiency);">
                    Delete
                  </button>
                  <button [disabled]="isViewMode" type="button" value="Add" class="custom-buttons def-button"
                    *ngIf="i === deficiencies.length-1" (click)="addDeficiency();">
                    Add
                  </button>
                </div>
              </div>
              <div class="col-1">
                <input [disabled]="isViewMode" id="checkbox_{{i}}" [value]='deficiency.corrected' type="checkbox"
                  name="qtd-check-{{deficiency.id}}" ngModel required [(ngModel)]="deficiency.isCorrected"
                  (change)="setCorrectionDate($event.target.value,deficiency);" style="margin-top:15px;">
              </div>
              <div class="col-8">
                <div class="row">
                  <div class="col-5">
                    <input [disabled]="isViewMode" id="text_{{i}}" type="text" class="rounded-form-fields"
                      name="text-{{deficiency.id}}" #defDescription="ngModel" required
                      style="width: 100%;margin-top:5px;" [(ngModel)]="deficiency.description">
                    <div *ngIf="submitted && !deficiency.description" class="alert alert-danger">
                      * Enter deficiency description for all deficiencies found.
                    </div>
                  </div>
                  <div class="col-6">
                    <input [disabled]="isViewMode" type="file" accept="image/gif,image/jpeg,image/png"
                      (change)="uploadFile(deficiency,$event.target,$event.target.files);" id="img-{{i}}"
                      style="display:none;">
                    <label for="img-{{i}}" class="custom-button-label choose-button-def">Upload Image</label>&nbsp;
                    <label *ngIf="deficiency.imageUploadError">{{deficiency.imageUploadError}}</label>
                    <a class="font-weight-bold contacts-content-font breakWord text-fields certificate"
                      *ngIf="deficiency && deficiency.image && deficiency.image[0]  && deficiency.image[0].imageName && deficiency.image[0].attachment"
                      href="{{deficiency.image[0].attachment}}" target="_blank">{{deficiency.image[0].imageName}}</a>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="deficiency.isCorrected">
                <div class="col-12">
                  <label class="heading" style="margin-left:15px;">
                    Corrections Made
                  </label>
                  <hr>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <div class="row">
                          <div class="col-5">
                            <label class="font-weight-bold inputLabel inspection">Date Corrected:</label>
                          </div>
                          <div class="col-7">
                            <div mat-form-field>
                              <input [disabled]="isViewMode" matInput [matDatepicker]="picker"
                                placeholder="Choose a date" class="form-control form-control-sm rounded-form-fields"
                                name="system-date-{{i}}" [(ngModel)]="deficiency.date"
                                (dateChange)="updateDeficiencyDate(deficiency, $event.target.value);"
                                (blur)="updateDeficiencyDate(deficiency, $event.target.value);">
                              <mat-datepicker-toggle matSuffix [for]="picker" class="inspectToggle">
                              </mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>
                            </div>
                            <div class="subMessages" style="font-size:14px;"> Enter Date Corrected in the following
                              format MM-DD-YYYY
                            </div>
                            <div *ngIf="submitted && checkForError(deficiency.date)" class="alert alert-danger">
                              {{getErrorMsgDate(deficiency.date)}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-5">
                            <label class="font-weight-bold inputLabel inspection">Corrected By:</label>
                          </div>
                          <div class="col-7">
                            <input [disabled]="isViewMode" type="text" class="rounded-form-fields"
                              name="system-corrected{{id}}" id="def-correctedBy-{{deficiency.id}}"
                              [(ngModel)]="deficiency.correctedBy" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="row">
                          <div class="col-5">
                            <label class="font-weight-bold inputLabel inspection">Inspector Comments: </label>
                          </div>
                          <div class="col-7">
                            <textarea rows="4" cols="70" name="commets{{i}}" #corComm="ngModel"
                              [(ngModel)]="deficiency.correctionComments" required>
                            </textarea>
                            <div *ngIf="deficiency.correctionComments && deficiency.correctionComments.length > 1000"
                              class="alert alert-danger" style="width: 30vw;">
                              * Maximum 1000 characters allowed.
                            </div>
                            <div *ngIf="submitted" [hidden]="corComm.valid" class="alert alert-danger">
                              * Enter correction comments for all deficiencies found.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
              </div>
            </div>
          </div>
          <div class="offset-3 col-10">
            <button type="submit" (click)="nextPage();" class="custom-buttons">
              Next
            </button>
            <button type="button" (click)="redirectDashboard();" class="custom-buttons">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3" style="margin-top:2%;" *ngIf="!isViewMode">
      <app-inspector-details [propertyMarker]="propertyMarker"></app-inspector-details>
    </div>
  </div>