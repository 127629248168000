import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
var AddNotesDialogComponent = /** @class */ (function () {
    function AddNotesDialogComponent(dialogRef, data, fb, journalDetailService, 
    // private _snackBar: MatSnackBar ,
    propertyService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.fb = fb;
        this.journalDetailService = journalDetailService;
        this.propertyService = propertyService;
        this.notesPayload = {};
        this.disableSubmit = false;
    }
    AddNotesDialogComponent.prototype.ngOnInit = function () {
        this.addNotesForm = this.fb.group({
            noteHeading: [, Validators.required],
            accessType: ['Private', Validators.required],
            note: [, Validators.required]
        });
        this.isAuthority = false;
        this.isOwner = false;
        this.isInspector = false;
        if (this.data.dialogOption === 'edit') {
            this.addNotesForm.patchValue({
                noteHeading: this.data.note.noteHeader,
                accessType: this.data.note.accessControl,
                note: this.data.note.noteBody
            });
        }
    };
    AddNotesDialogComponent.prototype.checkAuthority = function (event) {
        this.isAuthority = event.target.checked ? true : false;
    };
    AddNotesDialogComponent.prototype.checkOwner = function (event) {
        this.isOwner = event.target.checked ? true : false;
    };
    AddNotesDialogComponent.prototype.checkInspector = function (event) {
        this.isInspector = event.target.checked ? true : false;
    };
    AddNotesDialogComponent.prototype.setAccessType = function () {
        var accessType = this.addNotesForm.value.accessType.toString();
        if (accessType === 'Public') {
            return 'Public';
        }
        else if (accessType === 'Private') {
            return 'Private';
        }
        else if (accessType === 'Specific') {
            if (this.isAuthority && this.isInspector && this.isOwner) {
                return 'Specific/Inspector,Authority,Owner';
            }
            else if (this.isInspector && this.isAuthority) {
                return 'Specific/Inspector,Authority';
            }
            else if (this.isOwner && this.isInspector) {
                return 'Specific/Owner,Inspector';
            }
            else if (this.isOwner && this.isAuthority) {
                return 'Specific/Owner,Authority';
            }
            else if (this.isInspector) {
                return 'Specific/Inspector';
            }
            else if (this.isOwner) {
                return 'Specific/Owner';
            }
            else if (this.isAuthority) {
                return 'Specific/Authority';
            }
        }
        return 'null';
    };
    AddNotesDialogComponent.prototype.onSubmit = function () {
        var _this = this;
        this.disableSubmit = true;
        var accessType = this.setAccessType();
        if (this.addNotesForm.valid && accessType) {
            var lastIndex = this.journalDetailService.notes.getValue() ? this.journalDetailService.notes.getValue().length : 0;
            this.notesPayload = [{
                    displaySequence: ++lastIndex,
                    noteHeader: this.addNotesForm.value.noteHeading.toString(),
                    accessControl: accessType,
                    noteBody: this.addNotesForm.value.note.toString(),
                    propertyId: this.data.propertyId || this.propertyService.propertyId$.getValue()
                }];
            if (this.data.dialogOption === 'edit') {
                this.notesPayload[0].id = this.data.note.id;
                this.notesPayload[0].displaySequence = this.data.note.displaySequence;
                this.journalDetailService.postNotesForProperty(this.notesPayload).subscribe(function (res) {
                    _this.disableSubmit = false;
                    _this.dialogRef.close();
                }, function (error) {
                    _this.disableSubmit = false;
                    console.log(error);
                    _this.dialogRef.close();
                });
            }
            else {
                this.journalDetailService.postNotesForProperty(this.notesPayload).subscribe(function (res) {
                    _this.disableSubmit = false;
                    alert('Note Saved Successfully');
                    _this.dialogRef.close();
                }, function (error) {
                    _this.disableSubmit = false;
                    console.log(error);
                    _this.dialogRef.close();
                });
            }
        }
        else {
            this.disableSubmit = false;
            console.error('Empty add note dialod');
        }
    };
    AddNotesDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return AddNotesDialogComponent;
}());
export { AddNotesDialogComponent };
