import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
var EnlargeMapComponent = /** @class */ (function () {
    function EnlargeMapComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.lat = 47.6062; // latitude of area
        this.lng = -122.3321; // longitude of area
    }
    EnlargeMapComponent.prototype.ngOnInit = function () {
        this.propertyMarker = this.data.properties;
        this.lat = this.data.latitude;
        this.lng = this.data.longitude;
    };
    EnlargeMapComponent.prototype.mapReady = function (event) {
        this.map = event;
        this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('close'));
    };
    EnlargeMapComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    EnlargeMapComponent.prototype.setPoints = function (e) {
        // console.log(e);
    };
    return EnlargeMapComponent;
}());
export { EnlargeMapComponent };
