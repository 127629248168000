<div style="margin: 5px;">
        <agm-map [fullscreenControl]="true" [zoom]="13" [latitude]="lat" [longitude]="lng" style="height: 830px;"
                (mapReady)="mapReady($event)">
                <mat-icon id="close" class="custom-close-map" (click)="close()" mat-list-icon>close</mat-icon>
                <agm-marker *ngFor="let marker of propertyMarker;" [latitude]="marker.lat"
                [longitude]="marker.lng" [markerDraggable]="true" (dragEnd)="setPoints($event)">
                        <agm-info-window [isOpen]="true">{{marker.label}}</agm-info-window>
                </agm-marker>
        </agm-map>
</div>
