import { DatePipe, Location } from "@angular/common";
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { forkJoin } from "rxjs";
import { map, mergeMap, switchMap } from "rxjs/operators";
import {
  CertifiedInspector,
  Deficiency,
  Error,
  HeadingQuestion,
  InspectionData,
  InspectionDTO,
  System,
} from "../../core/models/inspection/inspection.model";
import { InspectionService } from "../../core/services/inspection/inspection.service";
import { LoggerService } from "../../core/services/logger/logger.service";
import { PropertyService } from "../../core/services/property/property.service";
import { AuthorityService } from "../../authority/shared/services/authority.service";
import { ListData } from "../../core/models/file-cabinet/file-cabinet.model";
import { PropertyMarker } from "../../core/models/property/property.model";
import { UtilityService } from "../../core/services/utility.service";
import { additionalCertificates } from "../../inspector/models/inspector.model";

export const MY_FORMATS = {
  parse: {
    dateInput: "MM-DD-YYYY",
  },
  display: {
    dateInput: "MM-DD-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "MM-DD-YYYY",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-enter-inspection",
  templateUrl: "./enter-inspection.component.html",
  styleUrls: ["./enter-inspection.component.scss"],
  providers: [
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class EnterInspectionComponent implements OnInit, OnDestroy {
  @Input() viewReportData: any;

  questions: Array<HeadingQuestion>;
  property = {
    name: "",
    address: "",
    emailAddress: "",
    accessCode: "",
    juristrictionName: "",
    jurisdictionId: "",
    manager: { name: "", number: "" },
  };
  owner = { name: "", address: "", primaryContact: "" };
  systemLocation;
  coverageArea;
  showInspectionQuestions: boolean;
  inspectionFrequency;
  contractorLicense;
  certifications = { options: [] };
  systems: Array<System>;
  inspectors: Array<CertifiedInspector>;
  inspectionCompanies: ListData[];
  stateCertificationNumber: string;
  additionalCertificateList: additionalCertificates[];
  nationalNICETNumber: string;
  otherCert: string;
  inspectorphoneNum: string;
  inspectorType: string;
  monitoringCompanyName: string;
  panelManufacturer: string;
  modelNumber: string;
  monitoring = { options: [] };
  deficiency = { options: [] };
  userRole: string;
  id: any;
  propertyId: any;
  selectedSystemId: any;
  certificateURL: any;
  errorMessages: any;
  selectedInspectorID: number = -1;
  certificationGiven: string;
  monitoringOption: any;
  defOption: any;
  subscriptions: any;
  propertyEmail: string;
  fileName: string;
  imageUploadError: string;
  base64logoImage: string;
  image: string;
  images: any;
  commercialHood;
  questionModelVersion: any;
  sequence: any;
  noFrontImage: any;
  noBackImage: any;
  prvRow = {};
  prvRows = [];
  hoseRows = [];
  showPRV: any;
  addEditProperty: boolean;
  configureInspectionReport: boolean;
  reports: Object[];
  selectedReport: string;
  jurisdicationId: number;
  isSimplifiedReportEnabled: any;
  isImageUploadEnabled: any;
  qmv: any;
  useLatest: boolean;
  submitted: boolean;
  propertyMarker: PropertyMarker[] = [];
  blockedByAuthorityName: string;
  blockedByAuthorityEmail: string;
  isBlocked: boolean;
  method: string;
  isInspectorExpired: boolean;

  constructor(
    public dialog: MatDialog,
    private propertyService: PropertyService,
    private router: Router,
    private inspectionService: InspectionService,
    private route: ActivatedRoute,
    private datepipe: DatePipe,
    private loggerService: LoggerService,
    private authorityService: AuthorityService,
    private el: ElementRef,
    private utilityService: UtilityService,
    private location: Location, 
  ) {}
  @ViewChild("formQuestion", { static: false }) testForm: NgForm;
  qtd: any;
  inspectionResult: InspectionDTO;
  deficiencyList: Array<any>;
  deficiencies: Array<Deficiency>;
  inspectionReportID: any;
  isViewMode: boolean;
  inspectionDataforSecondPage: InspectionData;
  hoodSelected;
  othersText;
  pac: any;
  phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  date;
  userDetails = null;
  assetTypeName: string;
  ngOnInit() {
    this.additionalCertificateList = []
    this.submitted = false;
    this.assetTypeName = "";
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.defOption = "No";
    this.monitoringOption = "No";
    this.userRole = localStorage.getItem("userRole");
    this.reports = [
      { id: "STANDARD_REPORT", data: "Standard Reports" },
      { id: "SIMPLIFIED_REPORT", data: "Simplified Reports" },
    ];
    if (window.location.href.indexOf("addEditProperty") > 0) {
      this.addEditProperty = true;
    } else {
      this.addEditProperty = false;
    }
    this.sequence = [];
    this.images = {};
    this.inspectors = [];
    this.systems = [];
    this.subscriptions = [];
    this.inspectionDataforSecondPage = {};
    this.errorMessages = [];
    this.showInspectionQuestions = false;
    this.property.name = "";
    this.property.address = "";
    this.property.emailAddress = "";
    this.property.accessCode = "";
    this.property.juristrictionName = "";
    this.property.manager.name = "";
    this.property.manager.number = "";
    this.owner.name = "";
    this.owner.address = "";
    this.owner.primaryContact = "";
    this.deficiencyList = [];
    this.inspectionResult = {};
    this.inspectionResult.isDeficiencyFound = false;
    this.inspectionResult.isCentralMonitoring = false;
    this.qtd = {};
    this.questions = [];
    this.subscriptions.push(
      this.propertyService.getAssestTypeOptions().subscribe((res) => {
        this.systems = res;
      })
    );

    this.systemLocation = "";
    this.coverageArea = "";
    this.inspectionFrequency = "Annually";
    this.contractorLicense = "";
    this.certifications.options = [
      {
        id: 7,
        name: "red",
      },
      {
        id: 8,
        name: "yellow",
      },
      {
        id: 9,
        name: "white",
      },
    ];
    this.stateCertificationNumber = "";
    this.nationalNICETNumber = "";
    this.inspectorphoneNum = "";

    /* Central Monitor Details */

    this.monitoring.options = [
      {
        id: 1,
        name: "Yes",
      },
      {
        id: 2,
        name: "No",
      },
    ];

    this.deficiency.options = [
      {
        id: 1,
        name: "Yes",
      },
      {
        id: 2,
        name: "No",
      },
    ];
    /* Deficiencies*/
    this.deficiencies = [{ index: 0 }];
    this.subscriptions.push(
      this.route.queryParams.subscribe((params) => {
        let assetId;
        if (
          this.router.url.indexOf("fileCabinet") > -1 ||
          this.router.url.indexOf("dashboardAuthority") > -1
        ) {
          this.id = this.viewReportData.id;
          this.propertyId = this.viewReportData.propId;
          this.pac = this.viewReportData.pac;
          this.isViewMode =
            this.viewReportData.viewMode ||
            this.userDetails.accessSpecifier === "true";
          this.inspectionReportID = this.viewReportData.editMode;
          this.isImageUploadEnabled = this.viewReportData.isImageUploadEnabled;
          this.isSimplifiedReportEnabled =
            this.viewReportData.isSimplifiedReportEnabled;
          this.inspectionResult.propertyAsset = { id: -1 };
          this.inspectionResult.propertyAsset.id = this.viewReportData.assetId;
          assetId = this.viewReportData.assetId;
          this.method = params.method;
        } else {
          this.id = params.id;
          this.propertyId = params.propId;
          this.pac = params.pac;
          const viewMode: boolean = params.viewMode === "true" ? true : false;
          this.isViewMode =
            viewMode || this.userDetails.accessSpecifier === "true";
          this.inspectionReportID = params.editMode;
          this.isImageUploadEnabled = params.isImageUploadEnabled === "true";
          this.isSimplifiedReportEnabled =
            params.isSimplifiedReportEnabled === "true";
          this.inspectionResult.propertyAsset = { id: -1 };
          this.inspectionResult.propertyAsset.id = params.assetId;
          assetId = params.assetId;
          this.method = params.method;
        }
        this.qmv = params.qmv;
        if (this.id) {
          this.selectedSystemId = this.id;
          this.subscriptions.push(
            this.inspectionService
              .getAssetName(this.selectedSystemId)
              .subscribe((x) => {
                this.assetTypeName = x["assetName"];
                this.inspectionDataforSecondPage.accessSpecifier =
                  x.accessSpecifier;
              })
          );
          if (this.selectedSystemId === "5") {
            this.commercialHood = true;
          } else {
            this.commercialHood = false;
          }
          this.inspectionFrequency = this.systems.find(
            (x) => x.id === this.selectedSystemId
          )
            ? this.systems.find((x) => x.id === this.selectedSystemId)
                .inspectionFrequencyDescription
            : "";
        }
        if (
          this.inspectionReportID === "false" &&
          this.method != "inspectReport" &&
          this.method != "editReport"
        ) {
          this.subscriptions.push(
            this.inspectionService
              .getPropertyDetailsById(this.propertyId)
              .pipe(
                map((res) => {
                  if (res) {
                    this.property.name = res.propertyName;
                    this.property.address = res.propertyAddress;
                    this.property.emailAddress = res.propertyEmail;
                    this.jurisdicationId = res.jurisdictionId;
                    if (+this.pac !== 0) {
                      this.property.accessCode = res.propertyAccessCode;
                    } else {
                      this.property.accessCode = "";
                    }
                    this.property.juristrictionName = res.jurisdiction;

                    /* Owner Details*/
                    this.owner.name = res.propertyOwner;
                    this.owner.address = res.ownerAddress;
                    this.owner.primaryContact = res.ownerContactNumber
                      ? res.ownerContactNumber.replace(
                          this.phoneRegex,
                          "$1-$2-$3"
                        )
                      : "";
                    return res;
                  }
                }),
                mergeMap((property) => {
                  const questions = this.inspectionService.getQuestions(
                    this.id,
                    +property.jurisdictionId,
                    assetId
                  );
                  const questionVersion =
                    this.inspectionService.getQuestionVersion(
                      this.id,
                      +property.jurisdictionId
                    );
                  const authSettings =
                    this.inspectionService.getAuthoritySettings(
                      this.id,
                      +property.jurisdictionId
                    );
                  if (this.userRole === "ROLE_INSPECTOR") {
                    this.subscriptions.push(
                      this.inspectionService
                        .getInspectorList(+property.jurisdictionId, +assetId)
                        .subscribe((res) => {
                          if (res) {
                            let inspectors = [];
                            inspectors = res;
                            inspectors.unshift({
                              id: -1,
                              firstName: "Select",
                              middleName: "",
                              lastName: "",
                              isBlocked: false,
                            });
                            this.inspectors = inspectors;
                            let preSelectedInspector = inspectors.find(
                              (insp) => insp.isSelected === true
                            );
                            this.selectedInspectorID =
                              preSelectedInspector &&
                              !preSelectedInspector.isBlocked
                                ? preSelectedInspector.id
                                : -1;
                            this.inspectionResult.inspectedByCertInspectorId =
                              this.selectedInspectorID;

                            // TEGFE-3047: certificate details and other fields were not visible when new report is filled.
                            this.inspectorphoneNum = this.inspectors.find(
                              (x) => x.id === +this.selectedInspectorID
                            )
                              ? this.inspectors
                                  .find(
                                    (x) => x.id === this.selectedInspectorID
                                  )
                                  .phoneNumber.replace(
                                    this.phoneRegex,
                                    "$1-$2-$3"
                                  )
                              : "";
                            this.stateCertificationNumber =
                              this.inspectors.find(
                                (x) => x.id === this.selectedInspectorID
                              )
                                ? this.inspectors.find(
                                    (x) => x.id === this.selectedInspectorID
                                  ).stateCertificate
                                : "";

                            this.additionalCertificateList = this.inspectors.find(
                              (x) => x.id === this.selectedInspectorID && !x.isBlocked && !this.isInspectorCertificateExpired(x)
                            ) ? this.additionalCertificateList = this.inspectors.find(
                              (x) => x.id === this.selectedInspectorID
                            ).additionalCertificateDtoList : [];

                            this.certificateURL = this.inspectors.find(
                              (x) => x.id === this.selectedInspectorID
                            )
                              ? this.inspectors.find(
                                  (x) => x.id === this.selectedInspectorID
                                ).stateCertificateImage
                              : "";
                            this.nationalNICETNumber = this.inspectors.find(
                              (x) => x.id === this.selectedInspectorID
                            )
                              ? this.inspectors.find(
                                  (x) => x.id === this.selectedInspectorID
                                ).nationalCertificate
                              : "";
                            this.otherCert = this.inspectors.find(
                              (x) => x.id === this.selectedInspectorID
                            )
                              ? this.inspectors.find(
                                  (x) => x.id === this.selectedInspectorID
                                ).otherCertificate
                              : "";
                            this.isBlocked = this.inspectors.find(
                              (x) => x.id === this.selectedInspectorID
                            )
                              ? this.inspectors.find(
                                  (x) => x.id === this.selectedInspectorID
                                ).isBlocked
                              : false;
                            this.blockedByAuthorityName = this.inspectors.find(
                              (x) => x.id === this.selectedInspectorID
                            )
                              ? this.inspectors.find(
                                  (x) => x.id === this.selectedInspectorID
                                ).blockedByAuthorityName
                              : "";
                            this.blockedByAuthorityEmail = this.inspectors.find(
                              (x) => x.id === this.selectedInspectorID
                            )
                              ? this.inspectors.find(
                                  (x) => x.id === this.selectedInspectorID
                                ).blockedByAuthorityEmail
                              : "";
                            this.inspectorType = this.inspectors.find(
                              (x) => x.id === this.selectedInspectorID
                            )
                              ? this.inspectors.find(
                                  (x) => x.id === this.selectedInspectorID
                                ).type
                              : "";

                            this.setOtherCert(this.otherCert);
                          }
                        })
                    );
                  }
                  // if (this.userRole !== 'ROLE_INSPECTOR') {
                  //   this.subscriptions.push(this.authorityService.getInspectionCompanyList().subscribe((res) => {
                  //     if (res) {
                  //       this.inspectionCompanies = res;
                  //       this.inspectionCompanies.unshift({ id: -1, data: 'Select' });
                  //       this.selectedInspectorID = -1;
                  //       this.inspectionResult.inspectedById = -1;
                  //       this.inspectionResult.inspectedByCertInspectorId = -1;
                  //     }
                  //   }));
                  // }
                  return forkJoin([questions, questionVersion, authSettings]);
                })
              )
              .subscribe((res) => {

                this.loggerService.pushToServer({
                  Line: 'enter-inspection.component.ts: 503',
                  API: `/propertyDetails/${this.propertyId}`,
                  Response: res
                })

                if (res && res[1]) {
                  if (
                    res[1]["asset_type_version"] &&
                    res[1]["asset_type_version"].id
                  ) {
                    this.inspectionResult.questionModelVersion =
                      res[1]["asset_type_version"].id;
                  }
                  if (
                    res[1]["jurisdiction_model_version"] &&
                    res[1]["jurisdiction_model_version"].id
                  ) {
                    this.inspectionResult.jurisdictionModelVersion =
                      res[1]["jurisdiction_model_version"].id;
                  }
                  if (
                    res[1]["footer_model_version"] &&
                    res[1]["footer_model_version"].id
                  ) {
                    this.inspectionResult.authorityMappedAttributesModelVersion =
                      res[1]["footer_model_version"].id;
                  }
                  if (res && res[2]) {
                    this.inspectionDataforSecondPage.authorityRemarks =
                      res[2]["remarks"];
                  }
                }
                if (res && res[0]) {
                  this.inspectionService.setStandPipeRows(0);
                  this.inspectionService.sePVRRows(null);
                  this.inspectionService.setHoseCount(0);
                  this.inspectionService.setHoseRows(null);
                  this.questions = res[0].sort((a, b) => {
                    return a.questions.sequence - b.questions.sequence;
                  });
                  this.questions.forEach((question) => {
                    if (question && question.questions.optionType === "Radio") {
                      question.questions.options =
                        question.questions.options.sort((a, b) => {
                          return a.sequence - b.sequence;
                        });
                    }
                    if (question && question.questions.subquestion) {
                      question.questions.subquestion.forEach((x) => {
                        if (x.optionType === "Radio") {
                          x.options = x.options.sort((a, b) => {
                            return a.sequence - b.sequence;
                          });
                        }
                      });
                    }
                    if (
                      question &&
                      question.questions.optionType === "Checkbox"
                    ) {
                      question.questions.options =
                        question.questions.options.sort((a, b) => {
                          return a.sequence - b.sequence;
                        });
                    }
                    if (this.assetTypeName === "Backflow") {
                      this.questions = res[0].sort((a, b) => {
                        return a.questions.sequence - b.questions.sequence;
                      });
                      this.questions.forEach((question) => {
                        if (question && question.questions) {
                          this.qtd[question.questions.questionId] =
                            question.questions.answer;
                          if (
                            question.questions.answer &&
                            question.questions.answer[0] === "N/A" &&
                            question.questions.optionType === "Text" &&
                            question.questions.options
                          ) {
                            this.qtd[
                              question.questions.questionId + "checkbox"
                            ] = true;
                            this.sequence.push(question.questions.sequence + 1);
                          }
                        }
                        if (
                          question &&
                          question.questions.optionType === "Checkbox"
                        ) {
                          question.questions.options =
                            question.questions.options.sort((a, b) => {
                              return a.sequence - b.sequence;
                            });
                          if (
                            question.questions.answer &&
                            question.questions.answer.length > 0
                          ) {
                            // tslint:disable-next-line: prefer-for-of
                            for (
                              let i = 0;
                              i < question.questions.answer.length;
                              i++
                            ) {
                              this.qtd[
                                question.questions.questionId +
                                  "*o*" +
                                  question.questions.answer[i]
                              ] = true;
                            }
                          }
                        }
                      });
                    }
                  });
                } else {
                  this.questions = [];
                }
              })
          );
          if (this.userRole !== "ROLE_INSPECTOR") {
            this.subscriptions.push(
              this.inspectionService
                .getInspectionCompanyDetails(this.propertyId)
                .subscribe((res) => {
                  if (res) {
                    let inspectionCompanies = [];
                    inspectionCompanies = res;
                    inspectionCompanies.unshift({ id: -1, data: "select" });
                    this.inspectionCompanies = inspectionCompanies;
                    this.selectedInspectorID = -1;
                    this.inspectionResult.inspectedById =
                      this.inspectionResult.inspectedById &&
                      this.inspectionResult.inspectedById !== -1
                        ? this.inspectionResult.inspectedById
                        : -1;
                  }
                })
            );
          }
          this.selectedReport = this.reports[0]["id"];
        } else if (this.method == "inspectReport") {
          console.log("In Inspect Report");
          this.subscriptions.push(
            this.inspectionService.getAssetInfo(assetId).subscribe(
              (resp) => {

                this.loggerService.pushToServer({
                  Line: 'enter-inspection.component.ts: 650',
                  API: `/propertyAsset/${assetId}`,
                  Response: resp
                })

                this.inspectionDataforSecondPage.id = resp.id;
                this.inspectionDataforSecondPage.authorityRemarks =
                  resp.authorityRemarks;
                this.inspectionDataforSecondPage.inspectedDate =
                  resp.inspectedDate;
                this.inspectionDataforSecondPage.inspectionAttachment =
                  resp.inspectionAttachment;
                this.inspectionDataforSecondPage.inspectionRemarks =
                  resp.inspectionRemarks;
                this.inspectionDataforSecondPage.isInspectorCustomArea =
                  resp.isInspectorCustomArea;
                this.inspectionDataforSecondPage.systemStatus =
                  resp.systemStatus;
                this.inspectionResult.inspectedById = resp.inspectedById;
                this.inspectionResult.inspectedByCertInspectorId =
                  resp.inspectedByCertInspectorId;
                this.inspectionResult.certType = resp.certType;
                this.inspectionResult.certificateGiven = resp.certificateGiven;
                this.inspectionResult.isCentralMonitoring =
                  resp.isCentralMonitoring;
                this.inspectionResult.monitoringCompany =
                  resp.monitoringCompany;
                this.inspectionResult.manufacturer = resp.manufacturer;
                this.inspectionResult.modelNumber = resp.modelNumber;
                this.inspectionResult.questionModelVersion =
                  resp.questionModelVersion;
                // TODO: Remove commented code
                // if (+this.qmv > resp.questionModelVersion) {
                //   this.inspectionResult.questionModelVersion = this.qmv;
                //   // 2140 - bug fix
                //   // this.useLatest = true;
                // } else {
                //   this.inspectionResult.questionModelVersion = resp.questionModelVersion;
                //   // this.useLatest = false;
                // }
                this.inspectionResult.jurisdictionModelVersion =
                  resp.jurisdictionModelVersion;
                this.inspectionResult.authorityMappedAttributesModelVersion =
                  resp.authorityMappedAttributesModelVersion;
                if (resp.inspectionType === "SIMPLIFIED_REPORT") {
                  this.selectedReport = "SIMPLIFIED_REPORT";
                } else {
                  this.selectedReport = "STANDARD_REPORT";
                }

                if (resp) {
                  this.changeHoodTypeToLastInspection(resp);
                }
                this.inspectionResult.otherCertification =
                  resp.otherCertification;
                //this.inspectionResult.propertyAsset.assetLocation = resp.propertyAsset.assetLocation;
                //this.inspectionResult.propertyAsset.assetCoverage = resp.propertyAsset.assetCoverage;
                /* deficiencies */
                if (
                  resp.inspectionDeficiency &&
                  resp.inspectionDeficiency.length > 0
                ) {
                  this.defOption = "Yes";
                  this.inspectionResult.isDeficiencyFound = true;
                  const deficiency: Array<Deficiency> = JSON.parse(
                    JSON.stringify(resp.inspectionDeficiency)
                  );
                  deficiency.forEach((x) => {
                    if (x.dateCorrected) {
                      x.date = x.dateCorrected;
                    }
                  });
                  this.deficiencies = deficiency;
                } else {
                  this.defOption = "No";
                  this.inspectionResult.isDeficiencyFound = false;
                }
                this.monitoringOption = resp.isCentralMonitoring ? "Yes" : "No";
                // this.inspectorType = resp.certType;
                this.certificationGiven = resp.certificateGiven;
                this.monitoringCompanyName = resp.monitoringCompany;
                this.panelManufacturer = resp.manufacturer;
                this.modelNumber = resp.modelNumber;
                /* get inspectors data and populate selected inspectors details */
                // if (this.userRole !== 'ROLE_INSPECTOR') {
                //   this.subscriptions.push(this.inspectionService.getInspectionCompanyDetails(this.propertyId).subscribe((res) => {
                //     if (res) {
                //       let inspectors = [];
                //       inspectors = res;
                //       inspectors.unshift({ id: -1, data: 'select' });
                //       this.inspectors = inspectors;
                //       this.selectedInspectorID = resp.inspectedById;
                //       // tslint:disable-next-line: no-shadowed-variable
                //       this.subscriptions.push(this.inspectionService.getInspectorDetails(resp.inspectedById).subscribe((resp) => {
                //         if (resp) {
                //           this.contractorLicense = resp.licenseNumber;
                //           this.inspectionDataforSecondPage.address = resp.address;
                //           this.inspectionDataforSecondPage.name = resp.name;
                //           this.inspectionDataforSecondPage.contact = resp.contact ?
                //             resp.contact.replace(this.phoneRegex, '$1-$2-$3') : '';
                //         }
                //       }));
                //     }
                //   }));
                // }
                if (resp) {
                  this.inspectionService
                    .getPropertyDetailsById(this.propertyId)
                    .pipe(
                      map((res) => {
                        if (res) {
                          this.property.name = res.propertyName;
                          this.property.address = res.propertyAddress;
                          this.property.emailAddress = res.propertyEmail;
                          this.jurisdicationId = res.jurisdictionId;
                          if (+this.pac !== 0) {
                            this.property.accessCode = res.propertyAccessCode;
                          } else {
                            this.property.accessCode = "";
                          }
                          this.property.juristrictionName = res.jurisdiction;

                          /* Owner Details*/
                          this.owner.name = res.propertyOwner;
                          this.owner.address = res.ownerAddress;
                          this.owner.primaryContact = res.ownerContactNumber
                            ? res.ownerContactNumber.replace(
                                this.phoneRegex,
                                "$1-$2-$3"
                              )
                            : "";
                          return res;
                        }
                      }),
                      switchMap((property: any) => {
                        this.inspectionService
                          .getQuestionVersion(this.id, +property.jurisdictionId)
                          .subscribe((x) => {

                            this.loggerService.pushToServer({
                              Line: 'enter-inspection.component.ts: 790',
                              API: `/question/version/${this.id}/${property.jurisdictionId}`,
                              Response: x
                            })

                            if (
                              x &&
                              x["asset_type_version"] &&
                              x["asset_type_version"].id
                            ) {
                              this.inspectionResult.questionModelVersion =
                                x["asset_type_version"].id;
                            }
                            if (
                              x["jurisdiction_model_version"] &&
                              x["jurisdiction_model_version"].id
                            ) {
                              this.inspectionResult.jurisdictionModelVersion =
                                x["jurisdiction_model_version"].id;
                            }
                            if (
                              x["footer_model_version"] &&
                              x["footer_model_version"].id
                            ) {
                              this.inspectionResult.authorityMappedAttributesModelVersion =
                                x["footer_model_version"].id;
                            }
                          });
                        if (this.userRole === "ROLE_INSPECTOR") {
                          this.subscriptions.push(
                            this.inspectionService
                              .getInspectorList(
                                +property.jurisdictionId,
                                +assetId
                              )
                              .subscribe((res) => {
                                if (res) {
                                  let inspectors: Array<CertifiedInspector>;
                                  //const result
                                  inspectors = res;
                                  this.inspectors = inspectors;
                                  inspectors.unshift({
                                    id: -1,
                                    firstName: "Select",
                                    middleName: "",
                                    lastName: "",
                                    isBlocked: false,
                                  });
                                  //this.inspectors = inspectors;

                                  let preSelectedInspector = inspectors.find(
                                    (x) => x.isSelected === true
                                  ) as CertifiedInspector;

                                  if (
                                    this.isInspectorCertificateExpired(
                                      preSelectedInspector
                                    )
                                  )
                                    this.isInspectorExpired = true;

                                  this.selectedInspectorID =
                                    preSelectedInspector &&
                                    !preSelectedInspector.isBlocked
                                      ? preSelectedInspector.id
                                      : -1;
                                  this.inspectionResult.inspectedByCertInspectorId =
                                    this.selectedInspectorID;

                                  //this.selectedInspectorID =   !this.inspectors.find((x) => x.id === + resp.inspectedByCertInspectorId).isBlocked
                                  // ? resp.inspectedByCertInspectorId :-1 ;

                                  //this.inspectionResult.inspectedByCertInspectorId =this.selectedInspectorID;

                                  // TEGFE-3047: certificate details and other fields were not visible when new report is filled.
                                  this.inspectorphoneNum = this.inspectors.find(
                                    (x) => x.id === +this.selectedInspectorID
                                  )
                                    ? this.inspectors
                                        .find(
                                          (x) =>
                                            x.id === this.selectedInspectorID
                                        )
                                        .phoneNumber.replace(
                                          this.phoneRegex,
                                          "$1-$2-$3"
                                        )
                                    : "";
                                  this.stateCertificationNumber =
                                    this.inspectors.find(
                                      (x) => x.id === this.selectedInspectorID
                                    )
                                      ? this.inspectors.find(
                                          (x) =>
                                            x.id === this.selectedInspectorID
                                        ).stateCertificate
                                      : "";

                                      this.additionalCertificateList = this.inspectors.find(
                                        (x) => x.id === this.selectedInspectorID && !x.isBlocked  && !this.isInspectorCertificateExpired(x)
                                      ) ? this.additionalCertificateList = this.inspectors.find(
                                        (x) => x.id === this.selectedInspectorID
                                      ).additionalCertificateDtoList : [];
                                  
                                  this.certificateURL = this.inspectors.find(
                                    (x) => x.id === this.selectedInspectorID
                                  )
                                    ? this.inspectors.find(
                                        (x) => x.id === this.selectedInspectorID
                                      ).stateCertificateImage
                                    : "";
                                  this.nationalNICETNumber =
                                    this.inspectors.find(
                                      (x) => x.id === this.selectedInspectorID
                                    )
                                      ? this.inspectors.find(
                                          (x) =>
                                            x.id === this.selectedInspectorID
                                        ).nationalCertificate
                                      : "";
                                  this.otherCert = this.inspectors.find(
                                    (x) => x.id === this.selectedInspectorID
                                  )
                                    ? this.inspectors.find(
                                        (x) => x.id === this.selectedInspectorID
                                      ).otherCertificate
                                    : "";
                                  this.isBlocked = this.inspectors.find(
                                    (x) => x.id === this.selectedInspectorID
                                  )
                                    ? this.inspectors.find(
                                        (x) => x.id === this.selectedInspectorID
                                      ).isBlocked
                                    : false;
                                  this.blockedByAuthorityName =
                                    this.inspectors.find(
                                      (x) => x.id === this.selectedInspectorID
                                    )
                                      ? this.inspectors.find(
                                          (x) =>
                                            x.id === this.selectedInspectorID
                                        ).blockedByAuthorityName
                                      : "";
                                  this.blockedByAuthorityEmail =
                                    this.inspectors.find(
                                      (x) => x.id === this.selectedInspectorID
                                    )
                                      ? this.inspectors.find(
                                          (x) =>
                                            x.id === this.selectedInspectorID
                                        ).blockedByAuthorityEmail
                                      : "";
                                  this.inspectorType = this.inspectors.find(
                                    (x) => x.id === this.selectedInspectorID
                                  )
                                    ? this.inspectors.find(
                                        (x) => x.id === this.selectedInspectorID
                                      ).type
                                    : "";

                                  this.setOtherCert(this.otherCert);

                                  /*
                      this.inspectorphoneNum = this.inspectors.find((x) => x.id === + resp.inspectedByCertInspectorId) ?
                        this.inspectors.find((x) => x.id === resp.inspectedByCertInspectorId).
                          phoneNumber.replace(this.phoneRegex, '$1-$2-$3') : '';
                      this.stateCertificationNumber = this.inspectors.find((x) => x.id === resp.inspectedByCertInspectorId) ?
                        this.inspectors.find((x) => x.id === resp.inspectedByCertInspectorId).stateCertificate : '';
                      this.certificateURL = this.inspectors.find((x) => x.id === resp.inspectedByCertInspectorId) ?
                        this.inspectors.find((x) => x.id === resp.inspectedByCertInspectorId).stateCertificateImage : '';
                      this.nationalNICETNumber = this.inspectors.find((x) => x.id === resp.inspectedByCertInspectorId) ?
                        this.inspectors.find((x) => x.id === resp.inspectedByCertInspectorId).nationalCertificate : '';
                      this.otherCert = this.inspectors.find((x) => x.id === resp.inspectedByCertInspectorId) ?
                        this.inspectors.find((x) => x.id === resp.inspectedByCertInspectorId).otherCertificate : '';

                        this.isBlocked = this.inspectors.find((x) => x.id === this.selectedInspectorID) ?
                        this.inspectors.find((x) => x.id === this.selectedInspectorID).isBlocked : false;
                        this.blockedByAuthorityName = this.inspectors.find((x) => x.id === this.selectedInspectorID) ?
                        this.inspectors.find((x) => x.id === this.selectedInspectorID).blockedByAuthorityName : '';
                        this.blockedByAuthorityEmail = this.inspectors.find((x) => x.id === this.selectedInspectorID) ?
                        this.inspectors.find((x) => x.id === this.selectedInspectorID).blockedByAuthorityEmail : '';
                        this.inspectorType = this.inspectors.find((x) => x.id === this.selectedInspectorID) ?
                        this.inspectors.find((x) => x.id === this.selectedInspectorID).type : '';
                        */
                                }
                              })
                          );
                        }
                        if (this.inspectionDataforSecondPage.id) {
                          console.log("in inspectionIdSecondPage");
                          //return this.inspectionService.getQuestionsByReportId(this.inspectionDataforSecondPage.id, +property.jurisdictionId);
                          return this.inspectionService.getQuestions(
                            this.id,
                            +property.jurisdictionId,
                            assetId
                          );
                        } else {
                          console.log("not in inspectionIdSecondPage");
                          return this.inspectionService.getQuestions(
                            this.id,
                            +property.jurisdictionId,
                            assetId
                          );
                        }
                        // TODO: remove commented code
                        // if (this.useLatest) {
                        //   //alert('Report template is updated .Kindly edit & fill details in new template & submit');

                        // } else {

                        // }
                      })
                    )
                    .subscribe((res) => {
                      if (res) {
                        if (!this.useLatest) {
                          this.questions = res.sort((a, b) => {
                            return a.questions.sequence - b.questions.sequence;
                          });
                          this.questions.forEach((question) => {
                            if (question && question.questions) {
                              this.qtd[question.questions.questionId] =
                                question.questions.answer;
                              if (
                                question.questions.answer &&
                                question.questions.answer[0] === "N/A" &&
                                question.questions.optionType === "Text" &&
                                question.questions.options
                              ) {
                                this.qtd[
                                  question.questions.questionId + "checkbox"
                                ] =
                                  question.questions.questionType !==
                                  "Additional"
                                    ? true
                                    : false;
                                this.sequence.push(
                                  question.questions.sequence + 1
                                );
                              }
                            }
                            if (question.questions.optionType === "Table") {
                              if (
                                question.questions.answer &&
                                question.questions.answer[0]
                              ) {
                                const finalAnswer =
                                  question.questions.answer[0].split("~");
                                for (let i = 0; i < finalAnswer.length; i++) {
                                  this.qtd[
                                    question.questions.questionId +
                                      "~m~" +
                                      i +
                                      "~" +
                                      finalAnswer.length
                                  ] = finalAnswer[i];
                                }
                                question.questions.tableAnswers[0].unitsTested =
                                  "";
                                question.questions.tableAnswers[0].resultsAcceptable =
                                  "";
                              }
                            }
                            if (question.questions.questionType === "PRV") {
                              if (
                                question.questions.answer &&
                                question.questions.answer[0]
                              ) {
                                this.inspectionDataforSecondPage.showPRV = true;
                                const finalAnswer =
                                  question.questions.answer[0].split("~");
                                if (this.prvRows.length < finalAnswer.length) {
                                  for (let k = 0; k < finalAnswer.length; k++) {
                                    this.prvRows.push({ id: k });
                                  }
                                }
                                this.inspectionService.setStandPipeRows(
                                  finalAnswer.length
                                );
                                for (let i = 0; i < finalAnswer.length; i++) {
                                  this.prvRows[i][question.questions.question] =
                                    finalAnswer[i];
                                }
                              }
                            }
                            if (question.questions.questionType === "ClassII") {
                              if (
                                question.questions.answer &&
                                question.questions.answer[0]
                              ) {
                                this.inspectionDataforSecondPage.showHose =
                                  true;
                                const finalAnswer =
                                  question.questions.answer[0].split("~");
                                if (this.hoseRows.length < finalAnswer.length) {
                                  for (let k = 0; k < finalAnswer.length; k++) {
                                    this.hoseRows.push({ id: k });
                                  }
                                }
                                this.inspectionService.setHoseCount(
                                  finalAnswer.length
                                );
                                for (let i = 0; i < finalAnswer.length; i++) {
                                  this.hoseRows[i][
                                    question.questions.question
                                  ] = finalAnswer[i];
                                }
                              }
                            }
                            if (
                              question &&
                              question.questions &&
                              question.questions.images &&
                              question.questions.images.length > 0
                            ) {
                              const imagesForNextScreen = [];
                              question.questions.images.forEach((x) => {
                                imagesForNextScreen.push({
                                  imageContent: x.imageContent,
                                  imagePlacement: x.imagePlacement,
                                  imageName: x.imageName,
                                });
                              });
                              this.images[question.questions.questionId] =
                                imagesForNextScreen;
                              if (
                                question.questions.question ===
                                "Pictures of Hydrant (front and back wide enough to show clearance)"
                              ) {
                                if (question.questions.images) {
                                  if (
                                    question.questions.images.filter(
                                      (x) => x.imagePlacement === "Front"
                                    ).length === 0
                                  ) {
                                    this.noFrontImage = true;
                                  }
                                  if (
                                    question.questions.images.filter(
                                      (x) => x.imagePlacement === "Back"
                                    ).length === 0
                                  ) {
                                    this.noBackImage = true;
                                  }
                                }
                              }
                            }
                            if (
                              question &&
                              question.questions &&
                              question.questions.subquestion
                            ) {
                              question.questions.subquestion.forEach((x) => {
                                if (x && x.images && x.images.length > 0) {
                                  const imagesForNextScreen = [];
                                  x.images.forEach((y) => {
                                    imagesForNextScreen.push({
                                      imageContent: y.imageContent,
                                      imagePlacement: y.imagePlacement,
                                      imageName: y.imageName,
                                    });
                                  });
                                  this.images[x.questionId] =
                                    imagesForNextScreen;
                                }
                                this.qtd[x.questionId] = x.answer
                                  ? x.answer[0]
                                  : null;
                                if (x.optionType === "Radio") {
                                  x.options = x.options.sort((a, b) => {
                                    return a.sequence - b.sequence;
                                  });
                                }
                                if (x.optionType === "Checkbox") {
                                  if (x.answer && x.answer.length > 0) {
                                    // tslint:disable-next-line: prefer-for-of
                                    for (let i = 0; i < x.answer.length; i++) {
                                      this.qtd[
                                        x.questionId + "*o*" + x.answer[i]
                                      ] = true;
                                    }
                                  }
                                }
                                if (
                                  x.answer &&
                                  x.answer[0] === "N/A" &&
                                  x.optionType === "Text" &&
                                  x.options
                                ) {
                                  this.qtd[x.questionId + "checkbox"] = true;
                                }

                                if (x.subquestion && x.subquestion.length > 0) {
                                  if (x.subquestion[0].optionType === "Text") {
                                    if (x.subquestion[0].answer) {
                                      this.qtd[x.subquestion[0].questionId] =
                                        x.subquestion[0].answer[0];
                                    }
                                  } else {
                                    if (
                                      x.subquestion[0].answer &&
                                      x.subquestion[0].answer.length > 0
                                    ) {
                                      // tslint:disable-next-line: prefer-for-of
                                      for (
                                        let i = 0;
                                        i < x.subquestion[0].answer.length;
                                        i++
                                      ) {
                                        this.qtd[
                                          x.subquestion[0].questionId +
                                            "*o*" +
                                            x.subquestion[0].answer[i]
                                        ] = true;
                                      }
                                    }
                                  }
                                }
                              });
                            }
                            if (
                              (question &&
                                question.questions.optionType === "Radio") ||
                              question.questions.questionType ===
                                "Test Results Acceptable"
                            ) {
                              if (question.questions.answer) {

                                console.log(this.assetTypeName, this.inspectionResult.questionModelVersion);

                                if(this.assetTypeName === 'Fire and Smoke Damper' && this.inspectionResult.questionModelVersion === 5){
                                  this.qtd[question.questions.questionId] =
                                  question.questions.answer;
                                }else{
                                  this.qtd[question.questions.questionId] =
                                  question.questions.answer[0];
                                }
                              }
                              question.questions.options =
                                question.questions.options.sort((a, b) => {
                                  return a.sequence - b.sequence;
                                });
                              if (question && question.questions.subquestion) {
                                question.questions.subquestion.forEach((x) => {
                                  this.qtd[x.questionId] = x.answer
                                    ? x.answer[0]
                                    : null;
                                  if (x.optionType === "Radio") {
                                    x.options = x.options.sort((a, b) => {
                                      return a.sequence - b.sequence;
                                    });
                                  }
                                });
                              }
                            }
                            if (
                              question &&
                              question.questions.optionType === "Checkbox"
                            ) {
                              question.questions.options =
                                question.questions.options.sort((a, b) => {
                                  return a.sequence - b.sequence;
                                });
                              if (
                                question.questions.answer &&
                                question.questions.answer.length > 0
                              ) {
                                // tslint:disable-next-line: prefer-for-of
                                for (
                                  let i = 0;
                                  i < question.questions.answer.length;
                                  i++
                                ) {
                                  this.qtd[
                                    question.questions.questionId +
                                      "*o*" +
                                      question.questions.answer[i]
                                  ] = true;
                                }
                              }
                            }
                          });
                          this.inspectionService.sePVRRows(this.prvRows);
                          this.inspectionService.setHoseRows(this.hoseRows);
                          this.inspectionDataforSecondPage.sequence =
                            this.sequence;
                          this.inspectionDataforSecondPage.noFrontImage =
                            this.noFrontImage;
                          this.inspectionDataforSecondPage.noBackImage =
                            this.noBackImage;
                        } else {
                          this.inspectionService.setStandPipeRows(0);
                          this.inspectionService.sePVRRows(null);
                          this.inspectionService.setHoseCount(0);
                          this.inspectionService.setHoseRows(null);
                          this.questions = res.sort((a, b) => {
                            return a.questions.sequence - b.questions.sequence;
                          });
                          this.questions.forEach((question) => {
                            if (
                              question &&
                              question.questions.optionType === "Radio"
                            ) {
                              question.questions.options =
                                question.questions.options.sort((a, b) => {
                                  return a.sequence - b.sequence;
                                });
                            }
                            if (question && question.questions.subquestion) {
                              question.questions.subquestion.forEach((x) => {
                                if (x.optionType === "Radio") {
                                  x.options = x.options.sort((a, b) => {
                                    return a.sequence - b.sequence;
                                  });
                                }
                              });
                            }
                            if (
                              question &&
                              question.questions.optionType === "Checkbox"
                            ) {
                              question.questions.options =
                                question.questions.options.sort((a, b) => {
                                  return a.sequence - b.sequence;
                                });
                            }
                          });
                        }
                      } else {
                        this.questions = [];
                      }
                    });
                }
              },
              (er: Error) => {
                if (
                  er &&
                  er.error &&
                  er.error.message &&
                  er.error.message ===
                    "Logged in user is not authorised to see inspection."
                ) {
                  alert("Logged in user is not authorised to see inspection.");
                  this.redirectDashboard();
                }
              }
            )
          );
        } else {
          this.subscriptions.push(
            this.inspectionService
              .getInspectionReportIdbyAssetId(assetId)
              .subscribe(
                (resp) => {

                  this.loggerService.pushToServer({
                    Line: 'enter-inspection.component.ts: 1338',
                    API: `/inspection/propertyAsset/${assetId}`,
                    Response: resp
                  })

                  this.inspectionDataforSecondPage.id = resp.id;
                  this.inspectionDataforSecondPage.authorityRemarks =
                    resp.authorityRemarks;
                  this.inspectionDataforSecondPage.inspectedDate =
                    resp.inspectedDate;
                  this.inspectionDataforSecondPage.inspectionAttachment =
                    resp.inspectionAttachment;
                  this.inspectionDataforSecondPage.inspectionRemarks =
                    resp.inspectionRemarks;
                  this.inspectionDataforSecondPage.isInspectorCustomArea =
                    resp.isInspectorCustomArea;
                  this.inspectionDataforSecondPage.systemStatus =
                    resp.systemStatus;
                  this.inspectionResult.inspectedById = resp.inspectedById;
                  this.inspectionResult.inspectedByCertInspectorId =
                    resp.inspectedByCertInspectorId;
                  this.inspectionResult.certType = resp.certType;
                  this.inspectionResult.certificateGiven =
                    resp.certificateGiven;
                  this.inspectionResult.isCentralMonitoring =
                    resp.isCentralMonitoring;
                  this.inspectionResult.monitoringCompany =
                    resp.monitoringCompany;
                  this.inspectionResult.manufacturer = resp.manufacturer;
                  this.inspectionResult.modelNumber = resp.modelNumber;
                  this.inspectionResult.questionModelVersion =
                    resp.questionModelVersion;
                  // TODO: Remove commented code
                  // if (+this.qmv > resp.questionModelVersion) {
                  //   this.inspectionResult.questionModelVersion = this.qmv;
                  //   // 2140 - bug fix
                  //   // this.useLatest = true;
                  // } else {
                  //   this.inspectionResult.questionModelVersion = resp.questionModelVersion;
                  //   // this.useLatest = false;
                  // }
                  this.inspectionResult.jurisdictionModelVersion =
                    resp.jurisdictionModelVersion;
                  this.inspectionResult.authorityMappedAttributesModelVersion =
                    resp.authorityMappedAttributesModelVersion;
                  if (resp.inspectionType === "SIMPLIFIED_REPORT") {
                    this.selectedReport = "SIMPLIFIED_REPORT";
                  } else {
                    this.selectedReport = "STANDARD_REPORT";
                  }

                  if (
                    resp.hoodType &&
                    resp.hoodType !== "Kitchen/Range Hood" &&
                    resp.hoodType !== "Paint Spray Booth" &&
                    resp.hoodType !== "Fume Hood"
                  ) {
                    this.hoodSelected = "Others";
                    this.othersText = resp.otherHoodType;
                  } else {
                    this.hoodSelected = resp.hoodType;
                  }
                  this.inspectionResult.otherCertification =
                    resp.otherCertification;
                  this.inspectionResult.propertyAsset.assetLocation =
                    resp.propertyAsset.assetLocation;
                  this.inspectionResult.propertyAsset.assetCoverage =
                    resp.propertyAsset.assetCoverage;
                  /* deficiencies */
                  if (
                    resp.inspectionDeficiency &&
                    resp.inspectionDeficiency.length > 0
                  ) {
                    this.defOption = "Yes";
                    this.inspectionResult.isDeficiencyFound = true;
                    const deficiency: Array<Deficiency> = JSON.parse(
                      JSON.stringify(resp.inspectionDeficiency)
                    );
                    deficiency.forEach((x) => {
                      if (x.dateCorrected) {
                        x.date = x.dateCorrected;
                      }
                    });
                    this.deficiencies = deficiency;
                  } else {
                    this.defOption = "No";
                    this.inspectionResult.isDeficiencyFound = false;
                  }
                  this.monitoringOption = resp.isCentralMonitoring
                    ? "Yes"
                    : "No";
                  // this.inspectorType = resp.certType;
                  this.certificationGiven = resp.certificateGiven;
                  this.monitoringCompanyName = resp.monitoringCompany;
                  this.panelManufacturer = resp.manufacturer;
                  this.modelNumber = resp.modelNumber;
                  /* get inspectors data and populate selected inspectors details */
                  // if (this.userRole !== 'ROLE_INSPECTOR') {
                  //   this.subscriptions.push(this.inspectionService.getInspectionCompanyDetails(this.propertyId).subscribe((res) => {
                  //     if (res) {
                  //       let inspectors = [];
                  //       inspectors = res;
                  //       inspectors.unshift({ id: -1, data: 'select' });
                  //       this.inspectors = inspectors;
                  //       this.selectedInspectorID = resp.inspectedById;
                  //       // tslint:disable-next-line: no-shadowed-variable
                  //       this.subscriptions.push(this.inspectionService.getInspectorDetails(resp.inspectedById).subscribe((resp) => {
                  //         if (resp) {
                  //           this.contractorLicense = resp.licenseNumber;
                  //           this.inspectionDataforSecondPage.address = resp.address;
                  //           this.inspectionDataforSecondPage.name = resp.name;
                  //           this.inspectionDataforSecondPage.contact = resp.contact ?
                  //             resp.contact.replace(this.phoneRegex, '$1-$2-$3') : '';
                  //         }
                  //       }));
                  //     }
                  //   }));
                  // }
                  if (resp) {
                    this.inspectionService
                      .getPropertyDetailsById(this.propertyId)
                      .pipe(
                        map((res) => {
                          if (res) {
                            this.property.name = res.propertyName;
                            this.property.address = res.propertyAddress;
                            this.property.emailAddress = res.propertyEmail;
                            this.jurisdicationId = res.jurisdictionId;
                            if (+this.pac !== 0) {
                              this.property.accessCode = res.propertyAccessCode;
                            } else {
                              this.property.accessCode = "";
                            }
                            this.property.juristrictionName = res.jurisdiction;

                            /* Owner Details*/
                            this.owner.name = res.propertyOwner;
                            this.owner.address = res.ownerAddress;
                            this.owner.primaryContact = res.ownerContactNumber
                              ? res.ownerContactNumber.replace(
                                  this.phoneRegex,
                                  "$1-$2-$3"
                                )
                              : "";
                            return res;
                          }
                        }),
                        switchMap((property) => {
                          if (this.userRole === "ROLE_INSPECTOR") {
                            this.subscriptions.push(
                              this.inspectionService
                                .getInspectorList(
                                  +property.jurisdictionId,
                                  +assetId
                                )
                                .subscribe((res) => {
                                  if (res) {
                                    let inspectors: Array<CertifiedInspector>;
                                    inspectors = res;
                                    inspectors.unshift({
                                      id: -1,
                                      firstName: "Select",
                                      middleName: "",
                                      lastName: "",
                                      isBlocked: false,
                                    });
                                    this.inspectors = inspectors;

                                    this.selectedInspectorID =
                                      !this.inspectors.find(
                                        (x) =>
                                          x.id ===
                                          +resp.inspectedByCertInspectorId
                                      ).isBlocked
                                        ? resp.inspectedByCertInspectorId
                                        : -1;
                                    this.inspectionResult.inspectedByCertInspectorId =
                                      this.selectedInspectorID;
                                    this.inspectorphoneNum =
                                      this.inspectors.find(
                                        (x) =>
                                          x.id ===
                                          +resp.inspectedByCertInspectorId
                                      )
                                        ? this.inspectors
                                            .find(
                                              (x) =>
                                                x.id ===
                                                resp.inspectedByCertInspectorId
                                            )
                                            .phoneNumber.replace(
                                              this.phoneRegex,
                                              "$1-$2-$3"
                                            )
                                        : "";
                                    this.stateCertificationNumber =
                                      this.inspectors.find(
                                        (x) =>
                                          x.id ===
                                          resp.inspectedByCertInspectorId
                                      )
                                        ? this.inspectors.find(
                                            (x) =>
                                              x.id ===
                                              resp.inspectedByCertInspectorId
                                          ).stateCertificate
                                        : "";

                                        this.additionalCertificateList = this.inspectors.find(
                                          (x) => x.id === this.selectedInspectorID && !x.isBlocked  && !this.isInspectorCertificateExpired(x)
                                        ) ? this.additionalCertificateList = this.inspectors.find(
                                          (x) => x.id === this.selectedInspectorID
                                        ).additionalCertificateDtoList : [];

                                    this.certificateURL = this.inspectors.find(
                                      (x) =>
                                        x.id === resp.inspectedByCertInspectorId
                                    )
                                      ? this.inspectors.find(
                                          (x) =>
                                            x.id ===
                                            resp.inspectedByCertInspectorId
                                        ).stateCertificateImage
                                      : "";
                                    this.nationalNICETNumber =
                                      this.inspectors.find(
                                        (x) =>
                                          x.id ===
                                          resp.inspectedByCertInspectorId
                                      )
                                        ? this.inspectors.find(
                                            (x) =>
                                              x.id ===
                                              resp.inspectedByCertInspectorId
                                          ).nationalCertificate
                                        : "";
                                    this.otherCert = this.inspectors.find(
                                      (x) =>
                                        x.id === resp.inspectedByCertInspectorId
                                    )
                                      ? this.inspectors.find(
                                          (x) =>
                                            x.id ===
                                            resp.inspectedByCertInspectorId
                                        ).otherCertificate
                                      : "";

                                    this.isBlocked = this.inspectors.find(
                                      (x) => x.id === this.selectedInspectorID
                                    )
                                      ? this.inspectors.find(
                                          (x) =>
                                            x.id === this.selectedInspectorID
                                        ).isBlocked
                                      : false;
                                    this.blockedByAuthorityName =
                                      this.inspectors.find(
                                        (x) => x.id === this.selectedInspectorID
                                      )
                                        ? this.inspectors.find(
                                            (x) =>
                                              x.id === this.selectedInspectorID
                                          ).blockedByAuthorityName
                                        : "";
                                    this.blockedByAuthorityEmail =
                                      this.inspectors.find(
                                        (x) => x.id === this.selectedInspectorID
                                      )
                                        ? this.inspectors.find(
                                            (x) =>
                                              x.id === this.selectedInspectorID
                                          ).blockedByAuthorityEmail
                                        : "";
                                    this.inspectorType = this.inspectors.find(
                                      (x) => x.id === this.selectedInspectorID
                                    )
                                      ? this.inspectors.find(
                                          (x) =>
                                            x.id === this.selectedInspectorID
                                        ).type
                                      : "";
                                  }
                                })
                            );
                          }
                          if (this.inspectionDataforSecondPage.id) {
                            return this.inspectionService.getQuestionsByReportId(
                              this.inspectionDataforSecondPage.id,
                              +property.jurisdictionId
                            );
                          } else {
                            return this.inspectionService.getQuestions(
                              this.id,
                              +property.jurisdictionId,
                              assetId
                            );
                          }
                          // TODO: remove commented code
                          // if (this.useLatest) {
                          //   //alert('Report template is updated .Kindly edit & fill details in new template & submit');

                          // } else {

                          // }
                        })
                      )
                      .subscribe((res) => {
                        if (res) {
                          if (!this.useLatest) {
                            this.questions = res.sort((a, b) => {
                              return (
                                a.questions.sequence - b.questions.sequence
                              );
                            });
                            this.questions.forEach((question) => {
                              if (question && question.questions) {
                                this.qtd[question.questions.questionId] =
                                  question.questions.answer;
                                if (
                                  question.questions.answer &&
                                  question.questions.answer[0] === "N/A" &&
                                  question.questions.optionType === "Text" &&
                                  question.questions.options
                                ) {
                                  this.qtd[
                                    question.questions.questionId + "checkbox"
                                  ] =
                                    question.questions.questionType !==
                                    "Additional"
                                      ? true
                                      : false;
                                  this.sequence.push(
                                    question.questions.sequence + 1
                                  );
                                }
                                if( this.assetTypeName === 'Fire alarm' 
                                  && question.questions.answer 
                                  && question.questions.answer[0] === "N/A"
                                  && question.questions.optionType === "Date"
                                  && question.questions.options
                                  && question.questions.question === 'Date of last smoke sensitivity test?'){
                                    this.qtd[question.questions.questionId + "checkbox"] = true;
                                    this.sequence.push( question.questions.sequence + 1 );

                                }
                              }
                              if (question.questions.optionType === "Table") {
                                if (
                                  question.questions.answer &&
                                  question.questions.answer[0]
                                ) {
                                  const finalAnswer =
                                    question.questions.answer[0].split("~");
                                  for (let i = 0; i < finalAnswer.length; i++) {
                                    this.qtd[
                                      question.questions.questionId +
                                        "~m~" +
                                        i +
                                        "~" +
                                        finalAnswer.length
                                    ] = finalAnswer[i];
                                  }
                                }
                              }
                              if (question.questions.questionType === "PRV") {
                                if (
                                  question.questions.answer &&
                                  question.questions.answer[0]
                                ) {
                                  this.inspectionDataforSecondPage.showPRV =
                                    true;
                                  const finalAnswer =
                                    question.questions.answer[0].split("~");
                                  if (
                                    this.prvRows.length < finalAnswer.length
                                  ) {
                                    for (
                                      let k = 0;
                                      k < finalAnswer.length;
                                      k++
                                    ) {
                                      this.prvRows.push({ id: k });
                                    }
                                  }
                                  this.inspectionService.setStandPipeRows(
                                    finalAnswer.length
                                  );
                                  for (let i = 0; i < finalAnswer.length; i++) {
                                    this.prvRows[i][
                                      question.questions.question
                                    ] = finalAnswer[i];
                                  }
                                }
                              }
                              if (
                                question.questions.questionType === "ClassII"
                              ) {
                                if (
                                  question.questions.answer &&
                                  question.questions.answer[0]
                                ) {
                                  this.inspectionDataforSecondPage.showHose =
                                    true;
                                  const finalAnswer =
                                    question.questions.answer[0].split("~");
                                  if (
                                    this.hoseRows.length < finalAnswer.length
                                  ) {
                                    for (
                                      let k = 0;
                                      k < finalAnswer.length;
                                      k++
                                    ) {
                                      this.hoseRows.push({ id: k });
                                    }
                                  }
                                  this.inspectionService.setHoseCount(
                                    finalAnswer.length
                                  );
                                  for (let i = 0; i < finalAnswer.length; i++) {
                                    this.hoseRows[i][
                                      question.questions.question
                                    ] = finalAnswer[i];
                                  }
                                }
                              }
                              if (
                                question &&
                                question.questions &&
                                question.questions.images &&
                                question.questions.images.length > 0
                              ) {
                                const imagesForNextScreen = [];
                                question.questions.images.forEach((x) => {
                                  imagesForNextScreen.push({
                                    imageContent: x.imageContent,
                                    imagePlacement: x.imagePlacement,
                                    imageName: x.imageName,
                                  });
                                });
                                this.images[question.questions.questionId] =
                                  imagesForNextScreen;
                                if (
                                  question.questions.question ===
                                  "Pictures of Hydrant (front and back wide enough to show clearance)"
                                ) {
                                  if (question.questions.images) {
                                    if (
                                      question.questions.images.filter(
                                        (x) => x.imagePlacement === "Front"
                                      ).length === 0
                                    ) {
                                      this.noFrontImage = true;
                                    }
                                    if (
                                      question.questions.images.filter(
                                        (x) => x.imagePlacement === "Back"
                                      ).length === 0
                                    ) {
                                      this.noBackImage = true;
                                    }
                                  }
                                }
                              }
                              if (
                                question &&
                                question.questions &&
                                question.questions.subquestion
                              ) {
                                question.questions.subquestion.forEach((x) => {
                                  if (x && x.images && x.images.length > 0) {
                                    const imagesForNextScreen = [];
                                    x.images.forEach((y) => {
                                      imagesForNextScreen.push({
                                        imageContent: y.imageContent,
                                        imagePlacement: y.imagePlacement,
                                        imageName: y.imageName,
                                      });
                                    });
                                    this.images[x.questionId] =
                                      imagesForNextScreen;
                                  }
                                  this.qtd[x.questionId] = x.answer
                                    ? x.answer[0]
                                    : null;
                                  if (x.optionType === "Radio") {
                                    x.options = x.options.sort((a, b) => {
                                      return a.sequence - b.sequence;
                                    });
                                  }
                                  if (x.optionType === "Checkbox") {
                                    if (x.answer && x.answer.length > 0) {
                                      // tslint:disable-next-line: prefer-for-of
                                      for (
                                        let i = 0;
                                        i < x.answer.length;
                                        i++
                                      ) {
                                        this.qtd[
                                          x.questionId + "*o*" + x.answer[i]
                                        ] = true;
                                      }
                                    }
                                  }
                                  if (
                                    x.answer &&
                                    x.answer[0] === "N/A" &&
                                    x.optionType === "Text" &&
                                    x.options
                                  ) {
                                    this.qtd[x.questionId + "checkbox"] = true;
                                  }

                                  if (
                                    x.subquestion &&
                                    x.subquestion.length > 0
                                  ) {
                                    if (
                                      x.subquestion[0].optionType === "Text"
                                    ) {
                                      if (x.subquestion[0].answer) {
                                        this.qtd[x.subquestion[0].questionId] =
                                          x.subquestion[0].answer[0];
                                      }
                                    } else {
                                      if (
                                        x.subquestion[0].answer &&
                                        x.subquestion[0].answer.length > 0
                                      ) {
                                        // tslint:disable-next-line: prefer-for-of
                                        for (
                                          let i = 0;
                                          i < x.subquestion[0].answer.length;
                                          i++
                                        ) {
                                          this.qtd[
                                            x.subquestion[0].questionId +
                                              "*o*" +
                                              x.subquestion[0].answer[i]
                                          ] = true;
                                        }
                                      }
                                    }
                                  }
                                });
                              }
                              if (
                                (question &&
                                  question.questions.optionType === "Radio") ||
                                question.questions.questionType ===
                                  "Test Results Acceptable"
                              ) {
                                if (question.questions.answer) {

                                  if(this.assetTypeName === 'Fire and Smoke Damper' && this.inspectionResult.questionModelVersion > 4){
                                    this.qtd[question.questions.questionId] = question.questions.questionType === "Additional" 
                                    ? question.questions.answer[0] :
                                    question.questions.answer;
                                  }else{
                                    this.qtd[question.questions.questionId] =
                                    question.questions.answer[0];
                                  }
                                }
                                question.questions.options =
                                  question.questions.options.sort((a, b) => {
                                    return a.sequence - b.sequence;
                                  });
                                if (
                                  question &&
                                  question.questions.subquestion
                                ) {
                                  question.questions.subquestion.forEach(
                                    (x) => {
                                      this.qtd[x.questionId] = x.answer
                                        ? x.answer[0]
                                        : null;
                                      if (x.optionType === "Radio") {
                                        x.options = x.options.sort((a, b) => {
                                          return a.sequence - b.sequence;
                                        });
                                      }
                                    }
                                  );
                                }
                              }
                              if (
                                question &&
                                question.questions.optionType === "Checkbox"
                              ) {
                                question.questions.options =
                                  question.questions.options.sort((a, b) => {
                                    return a.sequence - b.sequence;
                                  });
                                if (
                                  question.questions.answer &&
                                  question.questions.answer.length > 0
                                ) {
                                  // tslint:disable-next-line: prefer-for-of
                                  for (
                                    let i = 0;
                                    i < question.questions.answer.length;
                                    i++
                                  ) {
                                    this.qtd[
                                      question.questions.questionId +
                                        "*o*" +
                                        question.questions.answer[i]
                                    ] = true;
                                  }
                                }
                              }
                            });
                            this.inspectionService.sePVRRows(this.prvRows);
                            this.inspectionService.setHoseRows(this.hoseRows);
                            this.inspectionDataforSecondPage.sequence =
                              this.sequence;
                            this.inspectionDataforSecondPage.noFrontImage =
                              this.noFrontImage;
                            this.inspectionDataforSecondPage.noBackImage =
                              this.noBackImage;
                          } else {
                            this.inspectionService.setStandPipeRows(0);
                            this.inspectionService.sePVRRows(null);
                            this.inspectionService.setHoseCount(0);
                            this.inspectionService.setHoseRows(null);
                            this.questions = res.sort((a, b) => {
                              return (
                                a.questions.sequence - b.questions.sequence
                              );
                            });
                            this.questions.forEach((question) => {
                              if (
                                question &&
                                question.questions.optionType === "Radio"
                              ) {
                                question.questions.options =
                                  question.questions.options.sort((a, b) => {
                                    return a.sequence - b.sequence;
                                  });
                              }
                              if (question && question.questions.subquestion) {
                                question.questions.subquestion.forEach((x) => {
                                  if (x.optionType === "Radio") {
                                    x.options = x.options.sort((a, b) => {
                                      return a.sequence - b.sequence;
                                    });
                                  }
                                });
                              }
                              if (
                                question &&
                                question.questions.optionType === "Checkbox"
                              ) {
                                question.questions.options =
                                  question.questions.options.sort((a, b) => {
                                    return a.sequence - b.sequence;
                                  });
                              }
                            });
                          }
                        } else {
                          this.questions = [];
                        }
                      });
                  }
                },
                (er: Error) => {
                  if (
                    er &&
                    er.error &&
                    er.error.message &&
                    er.error.message ===
                      "Logged in user is not authorised to see inspection."
                  ) {
                    alert(
                      "Logged in user is not authorised to see inspection."
                    );
                    this.redirectDashboard();
                  }
                }
              )
          );
        }

        if (this.propertyId) {
          this.subscriptions.push(
            this.inspectionService
              .getPropertyDetailsById(this.propertyId)
              .subscribe((res) => {
                if (res) {
                  this.property.name = res.propertyName;
                  this.property.address = res.propertyAddress;
                  this.property.emailAddress = res.propertyEmail;
                  this.jurisdicationId = res.jurisdictionId;
                  if (+this.pac !== 0) {
                    this.property.accessCode = res.propertyAccessCode;
                  } else {
                    this.property.accessCode = "";
                  }
                  this.property.juristrictionName = res.jurisdiction;

                  /* Owner Details*/
                  this.owner.name = res.propertyOwner;
                  this.owner.address = res.ownerAddress;
                  this.owner.primaryContact = res.ownerContactNumber
                    ? res.ownerContactNumber.replace(
                        this.phoneRegex,
                        "$1-$2-$3"
                      )
                    : "";
                }
              })
          );
          this.subscriptions.push(
            this.inspectionService
              .getManagerDetails(this.propertyId)
              .subscribe((res) => {
                if (res) {
                  if (!res.firstName) {
                    res.firstName = "";
                  }
                  if (!res.lastName) {
                    res.lastName = "";
                  }
                  this.property.manager.name =
                    res.firstName + " " + res.lastName;
                  this.property.manager.number = res.phoneNum
                    ? res.phoneNum.replace(this.phoneRegex, "$1-$2-$3")
                    : "";
                  this.propertyEmail = res.email;
                }
              })
          );

          if (this.userRole !== "ROLE_SITE_ADMIN") {
            this.subscriptions.push(
              this.inspectionService.getUserDetails().subscribe((res) => {
                if (res) {
                  this.contractorLicense = res.licenseNumber;
                  this.inspectionDataforSecondPage.address = res.address;
                  this.inspectionDataforSecondPage.name = res.name;
                  this.inspectionDataforSecondPage.contact = res.contact
                    ? res.contact.replace(this.phoneRegex, "$1-$2-$3")
                    : "";
                }
              })
            );
          }

          this.subscriptions.push(
            this.inspectionService.getAssetInfo(assetId).subscribe((res) => {
              if (res) {
                this.systemLocation = res.assetLocation;
                this.coverageArea = res.assetCoverage;
                this.inspectionFrequency = res.assetInspectionFrequency;
                this.propertyMarker =
                  this.utilityService.setPropertyAndSystemOnMap(
                    null,
                    new Array(res)
                  );
                  this.changeHoodTypeToLastInspection(res);
              }
            })
          );
        }
      })
    );
  }

  changeHoodTypeToLastInspection (resp) {
    this.subscriptions.push(
      this.inspectionService.getAssetHoodType(resp.id).subscribe(
        (res) => {
          if (resp.assetTypeId == 5) {
            let hd = res.hoodType;
            if (hd && hd !== 'Kitchen/Range Hood' && hd !== 'Paint Spray Booth' && hd !== 'Fume Hood') {
              this.hoodSelected = 'Others';
              this.othersText = res.otherHoodType;
            } else {
              this.hoodSelected = hd;
            }
          } else {
            console.log('this is called ');
            console.log(res);   

            if(res && res.isCentralStationMonitoring){
              this.setCentralMonitor("Yes")
              this.monitoringOption = "Yes"

              if(res && res.monitoringCompanyName){
                this.setMonitorCompany(res.monitoringCompanyName)
                this.monitoringCompanyName = res.monitoringCompanyName
              }
  
              if(res && res.panelManufacturer){
                this.setControlPanel(res.panelManufacturer)
                this.panelManufacturer = res.panelManufacturer
              }
  
              if(res && res.modelNumber){
                this.setModelNumber(res.modelNumber)
                this.modelNumber = res.modelNumber
              }

            }
          }


          
        },
        (error) => {
          this.loggerService.log(error);
        }
      )
    );
  }
  ngAfterViewInit() {
    

    if (this.userRole !== "ROLE_INSPECTOR") {
      setTimeout(() => {
        if (this.userRole === "ROLE_SITE_ADMIN") {
          this.subscriptions.push(
            this.authorityService
              .getAdminInspectionCompanies()
              .pipe(
                switchMap((res) => {
                  if (res) {
                    this.inspectionCompanies = res;
                    this.inspectionCompanies.unshift({
                      id: -1,
                      data: "Select",
                    });
                    this.selectedInspectorID = -1;
                    this.inspectionResult.inspectedById =
                      this.inspectionResult.inspectedById &&
                      this.inspectionResult.inspectedById !== -1
                        ? this.inspectionResult.inspectedById
                        : -1;
                    this.inspectionResult.inspectedByCertInspectorId =
                      this.inspectionResult.inspectedByCertInspectorId &&
                      this.inspectionResult.inspectedByCertInspectorId !== -1
                        ? this.inspectionResult.inspectedByCertInspectorId
                        : -1;
                    if (this.inspectionResult.inspectedById !== -1) {
                      return this.authorityService.getCertifiedInspectorsForACompany(
                        this.inspectionResult.inspectedById
                      );
                    } else {
                      return [];
                    }
                  }
                })
              )
              .subscribe((res: []) => {
                if (res && res.length > 0) {
                  let inspectors = [];
                  inspectors = res;
                  inspectors.unshift({
                    id: -1,
                    firstName: "Select",
                    middleName: "",
                    lastName: "",
                    isBlocked: false,
                  });
                  this.inspectors = inspectors;
                  this.inspectionResult.inspectedByCertInspectorId =
                    this.inspectionResult.inspectedByCertInspectorId &&
                    this.inspectionResult.inspectedByCertInspectorId !== -1
                      ? this.inspectionResult.inspectedByCertInspectorId
                      : -1;
                }
              })
          );
        } else {
          this.subscriptions.push(
            this.authorityService
              .getInspectionCompanyList()
              .pipe(
                switchMap((res) => {
                  if (res) {
                    this.inspectionCompanies = res;
                    this.inspectionCompanies.unshift({
                      id: -1,
                      data: "Select",
                    });
                    this.selectedInspectorID = -1;
                    this.inspectionResult.inspectedById =
                      this.inspectionResult.inspectedById &&
                      this.inspectionResult.inspectedById !== -1
                        ? this.inspectionResult.inspectedById
                        : -1;
                    this.inspectionResult.inspectedByCertInspectorId =
                      this.inspectionResult.inspectedByCertInspectorId &&
                      this.inspectionResult.inspectedByCertInspectorId !== -1
                        ? this.inspectionResult.inspectedByCertInspectorId
                        : -1;
                    if (this.inspectionResult.inspectedById !== -1) {
                      return this.authorityService.getCertifiedInspectorsForACompany(
                        this.inspectionResult.inspectedById
                      );
                    } else {
                      return [];
                    }
                  }
                })
              )
              .subscribe((res: []) => {
                if (res && res.length > 0) {
                  let inspectors = [];
                  inspectors = res;
                  inspectors.unshift({
                    id: -1,
                    firstName: "Select",
                    middleName: "",
                    lastName: "",
                    isBlocked: false,
                  });
                  this.inspectors = inspectors;
                  this.inspectionResult.inspectedByCertInspectorId =
                    this.inspectionResult.inspectedByCertInspectorId &&
                    this.inspectionResult.inspectedByCertInspectorId !== -1
                      ? this.inspectionResult.inspectedByCertInspectorId
                      : -1;
                }
              })
          );
        }
      }, 5000);
    }
  }

  onChange(event) {
    this.submitted = false;
    const inspectorId = event.target.value;
    this.inspectionResult.blockedInspector = false;
    this.isInspectorExpired = false;
    if (inspectorId !== -1) {
      const currentInspector = this.inspectors.find(
        (x) => x.id === +inspectorId
      ) as CertifiedInspector;

      const certExpiryDate = moment(
        currentInspector.stateCertExpiryDate,
        "MM-DD-YYYY"
      );
      const nationalCertExpiryDate = moment(
        currentInspector.nationalCertExpiryDate,
        "MM-DD-YYYY"
      );
      const otherCertExpiryDate = moment(
        currentInspector.otherCertExpiryDate,
        "MM-DD-YYYY"
      );
      // if (
      //   !currentInspector.isBlocked &&
      //   ((currentInspector.stateCertificate.length >= 5 &&
      //     currentInspector.stateCertExpiryDate &&
      //     currentInspector.stateCertExpiryDate.length > 0 &&
      //     certExpiryDate >= moment()) ||
      //     (currentInspector.nationalCertificate.length >= 5 &&
      //       currentInspector.nationalCertExpiryDate &&
      //       currentInspector.nationalCertExpiryDate.length > 0 &&
      //       nationalCertExpiryDate >= moment()) ||
      //       (currentInspector.otherCertExpiryDate.length >= 5 &&
      //         currentInspector.otherCertExpiryDate &&
      //         currentInspector.otherCertExpiryDate.length > 0 &&
      //         otherCertExpiryDate >= moment())||
      //     (currentInspector.stateCertificate.length === 0 &&
      //       currentInspector.nationalCertificate.length === 0 &&
      //       currentInspector.otherCertificate.length === 0))
      if (
        !currentInspector.isBlocked &&
        !this.isInspectorCertificateExpired(currentInspector)
      ) {
        this.inspectionResult.inspectedByCertInspectorId = +inspectorId;
        this.inspectorphoneNum = currentInspector
          ? currentInspector.phoneNumber.replace(this.phoneRegex, "$1-$2-$3")
          : "";
        this.stateCertificationNumber = currentInspector
          ? currentInspector.stateCertificate
          : "";

          this.additionalCertificateList = currentInspector && !currentInspector.isBlocked && !this.isInspectorCertificateExpired(currentInspector) ? currentInspector.additionalCertificateDtoList : []

        this.certificateURL = currentInspector
          ? currentInspector.stateCertificateImage
          : null;
        this.nationalNICETNumber = currentInspector
          ? currentInspector.nationalCertificate
          : null;
        this.otherCert = currentInspector
          ? currentInspector.otherCertificate
          : null;
        this.isBlocked = currentInspector ? currentInspector.isBlocked : false;
        this.blockedByAuthorityName = currentInspector
          ? currentInspector.blockedByAuthorityName
          : null;
        this.blockedByAuthorityEmail = currentInspector
          ? currentInspector.blockedByAuthorityEmail
          : null;
        this.inspectorType = currentInspector ? currentInspector.type : null;
      } else if (currentInspector.isBlocked) {
        this.inspectorphoneNum = "";
        this.stateCertificationNumber = "";
        this.certificateURL = null;
        this.nationalNICETNumber = null;
        this.additionalCertificateList = []
        this.otherCert = null;
        this.isBlocked = false;
        this.blockedByAuthorityName = null;
        this.blockedByAuthorityEmail = null;
        this.inspectorType = null;
        alert(
          "The inspector you are trying to select has been blocked by the  " +
            currentInspector.blockedByAuthorityName +
            ". Please contact them at " +
            currentInspector.blockedByAuthorityEmail +
            " for additional information."
        );
        // this.inspectionResult.inspectedByCertInspectorId =  -1;
        this.inspectionResult.blockedInspector = true;
        event.value = this.inspectionResult.inspectedByCertInspectorId;
      } else {
        //this.inspectionResult.inspectedByCertInspectorId = -1;
        event.value = this.inspectionResult.inspectedByCertInspectorId;
        this.inspectorphoneNum = "";
        this.stateCertificationNumber = "";
        this.certificateURL = null;
        this.nationalNICETNumber = null;
        this.otherCert = null;
        this.additionalCertificateList = []
        this.isBlocked = false;
        this.blockedByAuthorityName = null;
        this.blockedByAuthorityEmail = null;
        this.inspectorType = null;
        this.isInspectorExpired = true;
        // alert(
        //   "The selected certificate for the inspector you selected is expired." +
        //     " Please have your Administrator update the certificate details in your Preferences or select other inspector for report submission."
        // );
      }
    } else {
      this.additionalCertificateList = []
      this.inspectorphoneNum = "";
      this.stateCertificationNumber = "";
      this.otherCert = "";
      this.nationalNICETNumber = "";
      this.certificateURL = null;
    }
    this.setOtherCert(this.otherCert);

    console.log(this.additionalCertificateList);
  }

  getCertifiedInspectors() {
    this.isInspectorExpired = false
    this.subscriptions.push(
      this.authorityService
        .getCertifiedInspectorsForACompany(this.inspectionResult.inspectedById)
        .subscribe((res) => {
          let inspectors = [];
          inspectors = res;
          inspectors.unshift({
            id: -1,
            firstName: "Select",
            middleName: "",
            lastName: "",
            isBlocked: false,
          });
          this.inspectors = inspectors;
          this.inspectionResult.inspectedByCertInspectorId =
            this.inspectionResult.inspectedByCertInspectorId &&
            this.inspectionResult.inspectedByCertInspectorId !== -1
              ? this.inspectionResult.inspectedByCertInspectorId
              : -1;
        })
    );
    this.subscriptions.push(
      this.inspectionService
        .getInspectorDetails(this.inspectionResult.inspectedById)
        .subscribe((resp) => {
          if (resp) {
            this.contractorLicense = resp.licenseNumber;
            this.inspectionDataforSecondPage.address = resp.address;
            this.inspectionDataforSecondPage.name = resp.name;
            this.inspectionDataforSecondPage.contact = resp.contact
              ? resp.contact.replace(this.phoneRegex, "$1-$2-$3")
              : "";
          }
        })
    );
  }

  onSubmit() {
    this.loggerService.log("qtd" + this.qtd);
  }

  addDeficiency() {
    this.deficiencies.push({
      index: this.deficiencies[this.deficiencies.length - 1]["index"] + 1,
    });
  }

  deleteDeficiency(deficiency: Deficiency) {
    /* If deficiency did not have an id */
    if (deficiency.id) {
      this.deficiencies = this.deficiencies.filter(
        (x) => x.id !== deficiency.id
      );
    } else {
      this.deficiencies = this.deficiencies.filter(
        (x) => x.index !== deficiency.index
      );
    }
  }
  setCorrectionDate(value, def) {
    if (value) {
      def.dateCorrected =
        this.datepipe.transform(new Date(), "yyyy-MM-dd") + "T00:00:00.000Z";
      def.date = new Date();
    }
  }

  checkHoodSelected() {
    if (
      this.commercialHood &&
      (!this.hoodSelected ||
        (this.hoodSelected === "Others" && !this.othersText))
    ) {
      return true;
    } else {
      return false;
    }
  }
  nextPage() {
    this.submitted = true;
    this.inspectionResult.inspectionType = this.selectedReport;
    this.inspectionDataforSecondPage.inspectionType = this.selectedReport;
    this.inspectionResult.isPACBypassed = +this.pac !== 0 ? false : true;
    let hd = this.hoodSelected;
    if (hd && hd !== 'Kitchen/Range Hood'
      && hd !== 'Paint Spray Booth' && hd !== 'Fume Hood') {
      this.hoodSelected = 'Others';
      this.inspectionResult.hoodType = "Other";
      this.inspectionResult.otherHoodType = this.othersText;
    } else {
      this.inspectionResult.hoodType = hd;
    }
    const finalDefi = JSON.parse(JSON.stringify(this.deficiencies));

    finalDefi.forEach((x) => {
      delete x.date;
      delete x.imageUploadError;
      delete x.index;
    });

    if (this.isInspectorExpired) return;

    if (this.inspectionResult.isDeficiencyFound) {
      let defError = false;
      let dateError = false;
      let dateFutureDate = false;
      this.inspectionResult.inspectionDeficiency = this.deficiencies;
      this.deficiencies.forEach((x) => {
        if (!x.description || (x.description && x.description.trim() === "")) {
          defError = true;
        }
        if (
          (x &&
            x.dateCorrected &&
            this.checkForError(x.dateCorrected.split("T")[0])) ||
          x.dateCorrected === ""
        ) {
          if (
            x &&
            x.dateCorrected &&
            //(this.formatDate(x.dateCorrected.split('T')[0]) > this.formatDate(new Date()))
            moment(new Date(), "YYYY-MM-DD").isBefore(
              moment(x.dateCorrected.split("T")[0], "YYYY-MM-DD")
            )
          ) {
            dateFutureDate = true;
          } else {
            dateError = true;
          }
        }
        if (x && !x.isCorrected) {
          x.isCorrected = false;
        }

        if (x.isCorrected) {
          if (
            !x.correctionComments ||
            (x.correctionComments && x.correctionComments.trim() === "")
          ) {
            if (
              this.errorMessages.indexOf(
                "* Enter correction comments for all deficiencies found."
              ) < 0
            ) {
              this.errorMessages.push(
                "* Enter correction comments for all deficiencies found."
              );
            }
          } else {
            if (
              this.errorMessages.indexOf(
                "* Enter correction comments for all deficiencies found."
              ) >= 0
            ) {
              this.errorMessages.splice(
                this.errorMessages.indexOf(
                  "* Enter correction comments for all deficiencies found."
                ),
                1
              );
            }
          }
          if (
            x.correctionComments &&
            x.correctionComments.trim().length > 1000
          ) {
            if (
              this.errorMessages.indexOf("* Maximum 1000 characters allowed.") <
              0
            ) {
              this.errorMessages.push("* Maximum 1000 characters allowed.");
            }
          } else {
            if (
              this.errorMessages.indexOf(
                "* Maximum 1000 characters allowed."
              ) >= 0
            ) {
              this.errorMessages.splice(
                this.errorMessages.indexOf(
                  "* Maximum 1000 characters allowed."
                ),
                1
              );
            }
          }
        }
      });
      if (defError) {
        if (
          this.errorMessages.indexOf(
            "* Enter deficiency description for all deficiencies found."
          ) < 0
        ) {
          this.errorMessages.push(
            "* Enter deficiency description for all deficiencies found."
          );
        }
      } else {
        if (
          this.errorMessages.indexOf(
            "* Enter deficiency description for all deficiencies found."
          ) >= 0
        ) {
          this.errorMessages.splice(
            this.errorMessages.indexOf(
              "* Enter deficiency description for all deficiencies found."
            ),
            1
          );
        }
      }

      if (dateError) {
        if (this.errorMessages.indexOf("* Please enter a valid date") < 0) {
          this.errorMessages.push("* Please enter a valid date");
        }
      } else if (dateFutureDate) {
        if (this.errorMessages.indexOf("* Future Date is not allowed") < 0) {
          this.errorMessages.push("* Future Date is not allowed");
        }
      } else {
        if (this.errorMessages.indexOf("* Please enter a valid date") >= 0) {
          this.errorMessages.splice(
            this.errorMessages.indexOf("* Please enter a valid date"),
            1
          );
        } else if (
          this.errorMessages.indexOf("* Future Date is not allowed") >= 0
        ) {
          this.errorMessages.splice(
            this.errorMessages.indexOf("* Future Date is not allowed"),
            1
          );
        }
      }
    }
    if (
      !this.systemLocation ||
      (this.systemLocation && this.systemLocation.trim() === "")
    ) {
      if (this.errorMessages.indexOf("* Enter System Location field.") < 0) {
        this.errorMessages.push("* Enter System Location field.");
      }
    } else if (this.systemLocation && this.systemLocation.length > 128) {
      if (
        this.errorMessages.indexOf(
          "* System Location should note be more than 128 characters."
        ) < 0
      ) {
        this.errorMessages.push(
          "* System Location should note be more than 128 characters."
        );
      }
    } else {
      if (this.errorMessages.indexOf("* Enter System Location field.") >= 0) {
        this.errorMessages.splice(
          this.errorMessages.indexOf("* Enter System Location field."),
          1
        );
      }
      if (
        this.errorMessages.indexOf(
          "* System Location should note be more than 128 characters."
        ) >= 0
      ) {
        this.errorMessages.splice(
          this.errorMessages.indexOf(
            "* System Location should note be more than 128 characters."
          ),
          1
        );
      }
    }
    if (
      !this.coverageArea ||
      (this.coverageArea && this.coverageArea.trim() === "")
    ) {
      if (this.errorMessages.indexOf("* Enter Coverage Area field.") < 0) {
        this.errorMessages.push("* Enter Coverage Area field.");
      }
    } else if (this.coverageArea && this.coverageArea.length > 128) {
      if (
        this.errorMessages.indexOf(
          "* Coverage Area should note be more than 128 characters."
        ) < 0
      ) {
        this.errorMessages.push(
          "* Coverage Area should note be more than 128 characters."
        );
      }
    } else {
      if (this.errorMessages.indexOf("* Enter Coverage Area field.") >= 0) {
        this.errorMessages.splice(
          this.errorMessages.indexOf("* Enter Coverage Area field."),
          1
        );
      }
      if (
        this.errorMessages.indexOf(
          "* Coverage Area should note be more than 128 characters."
        ) >= 0
      ) {
        this.errorMessages.splice(
          this.errorMessages.indexOf(
            "* Coverage Area should note be more than 128 characters."
          ),
          1
        );
      }
    }
    var name: string = "* Enter Inspected By field.";
    if (this.userRole === "ROLE_INSPECTOR") {
      name = "* Check Preferences for valid Inspector Certifications.";
    }

    if (this.userRole !== "ROLE_INSPECTOR") {
      if (+this.inspectionResult.inspectedById === -1) {
        if (
          this.errorMessages.indexOf("* Enter Inspection Company field.") < 0
        ) {
          this.errorMessages.push("* Enter Inspection Company field.");
        }
      } else {
        if (
          this.errorMessages.indexOf("* Enter Inspection Company field.") >= 0
        ) {
          this.errorMessages.splice(
            this.errorMessages.indexOf("* Enter Inspection Company field."),
            1
          );
        }
      }
    }

    if (!(this.inspectionResult.inspectedByCertInspectorId) || ( 
      +this.inspectionResult.inspectedByCertInspectorId === -1 &&
      this.inspectors.length !== 0
    )) {
      if (this.errorMessages.indexOf("* Enter Inspected By field.") < 0) {
        this.errorMessages.push("* Enter Inspected By field.");
      }
    } else {
      if (this.errorMessages.indexOf(name) >= 0) {
        this.errorMessages.splice(this.errorMessages.indexOf(name), 1);
      }
    }
    if (+this.inspectionResult.blockedInspector) {
      if (this.errorMessages.indexOf("* Enter a valid Inspector.") < 0) {
        this.errorMessages.push("*  Enter a valid Inspector.");
      }
    } else {
      if (this.errorMessages.indexOf("*  Enter a valid Inspector.") >= 0) {
        this.errorMessages.splice(
          this.errorMessages.indexOf("*  Enter a valid Inspector."),
          1
        );
      }
    }
    if (
      this.commercialHood &&
      (!this.hoodSelected ||
        (this.hoodSelected === "Others" && !this.othersText))
    ) {
      if (this.errorMessages.indexOf("* Enter Hood Type.") < 0) {
        this.errorMessages.push("* Enter Hood Type.");
      }
    } else {
      if (this.errorMessages.indexOf("* Enter Hood Type.") >= 0) {
        this.errorMessages.splice(
          this.errorMessages.indexOf("* Enter Hood Type."),
          1
        );
      }
    }
    if (this.errorMessages.length === 0) {
      this.showInspectionQuestions = true;
      if (this.viewReportData && this.viewReportData.dialogRef) {
        let elem: any = document.getElementsByClassName(
          "custom-container-headings"
        );
        const a = [...elem].filter((e) => e.innerText === "Inspection Result");
        a[0].scrollIntoView(true);
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      const invalidControl = this.el.nativeElement.querySelector(".ng-invalid");
      if (invalidControl) {
        invalidControl.focus();
        invalidControl.scrollIntoView();
      }
    }
  }
  redirectDashboard() {
    if (this.router.url.indexOf("fileCabinet") > -1) {
      this.viewReportData.dialogRef.close();
      this.router.navigate(["user/general/fileCabinet"]);   
      return;
    }
    if(this.router.url.indexOf("dashboardAuthority") > -1){
      this.viewReportData.dialogRef.close();
      return;
    }
    
    if (!this.addEditProperty && !this.isViewMode) {
      this.router.navigate(
        [
          "user/inspection/searchInspectionProperties/getInspectionAssetsByProperty",
        ],
        {
          queryParams: {
            id: this.propertyId,
            pac: this.pac,
            isSimplifiedReportEnabled: this.isSimplifiedReportEnabled,
            isImageUploadEnabled: this.isImageUploadEnabled,
          },
        }
      );
    } else if (this.isViewMode) {
      this.location.back();
    } else {
      this.router.navigate(
        ["user/inspection/addEditProperty/getInspectionAssetsByProperty"],
        {
          queryParams: {
            id: this.propertyId,
            pac: this.pac,
            isSimplifiedReportEnabled: this.isSimplifiedReportEnabled,
            isImageUploadEnabled: this.isImageUploadEnabled,
          },
        }
      );
    }
  }

  setType(certType: string) {
    this.inspectionResult.certType = certType;
  }

  setColor(colo: string) {
    this.inspectionResult.certificateGiven = colo;
  }

  setHood(hood: string, others?: boolean) {
    this.inspectionResult.hoodType = hood;
    if (others) {
      this.inspectionResult.hoodType = this.othersText;
    }
  }

  setCentralMonitor(option: string) {
    if (option === "Yes") {
      this.inspectionResult.isCentralMonitoring = true;
    } else {
      this.inspectionResult.isCentralMonitoring = false;

      this.setMonitorCompany("")
      this.setControlPanel("")
      this.setModelNumber("")

    }
  }

  setDeficiency(option: string) {
    if (option === "Yes") {
      this.inspectionResult.isDeficiencyFound = true;
    } else {
      this.inspectionResult.isDeficiencyFound = true;
      if (this.deficiencies && this.deficiencies.length > 0) {
        if (
          confirm(
            "All added deficiencies will be lost.Do you want to continue?"
          )
        ) {
          if (
            this.errorMessages.indexOf(
              "* Enter deficiency description for all deficiencies found."
            ) >= 0
          ) {
            this.errorMessages.splice(
              this.errorMessages.indexOf(
                "* Enter deficiency description for all deficiencies found."
              ),
              1
            );
          }
          if (this.errorMessages.indexOf("* Please enter a valid date") >= 0) {
            this.errorMessages.splice(
              this.errorMessages.indexOf("* Please enter a valid date"),
              1
            );
          }
          if (this.errorMessages.indexOf("* Future Date is not allowed") >= 0) {
            this.errorMessages.splice(
              this.errorMessages.indexOf("* Future Date is not allowed"),
              1
            );
          }
          if (
            this.errorMessages.indexOf(
              "* Enter correction comments for all deficiencies found."
            ) >= 0
          ) {
            this.errorMessages.splice(
              this.errorMessages.indexOf(
                "* Enter correction comments for all deficiencies found."
              ),
              1
            );
          }
          this.deficiencies = [{ index: 0 }];
          this.inspectionResult.isDeficiencyFound = false;
        }
      }
    }
  }

  setMonitorCompany(monitoringCompany) {
    this.inspectionResult.monitoringCompany = monitoringCompany;
  }

  setControlPanel(manufacturer) {
    this.inspectionResult.manufacturer = manufacturer;
  }

  setModelNumber(modelNumber) {
    this.inspectionResult.modelNumber = modelNumber;
  }

  setOtherCert(cert) {
    this.inspectionResult.otherCertification = cert;
  }

  setSystemLocation(loc) {
    this.inspectionResult.propertyAsset.assetLocation = loc;
  }

  setSystemCoverage(cov) {
    this.inspectionResult.propertyAsset.assetCoverage = cov;
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  backButton() {
    this.showInspectionQuestions = !this.showInspectionQuestions;
  }

  showErrorMessages(data) {
    this.errorMessages = data;
  }

  clear() {
    this.certificationGiven = null;
  }

  uploadFile(deficiency, event, files: FileList) {
    const image = files.item(0);
    if (image.size < 1097152) {
      if (
        image.type.toLowerCase() === "image/gif" ||
        image.type.toString() === "image/jpeg" ||
        image.type.toString().toLowerCase() === "image/png"
      ) {
        deficiency.imageUploadError = "";
        const reader = new FileReader();
        reader.readAsDataURL(image);
        deficiency.image = [{}];
        reader.onload = () => {
          this.base64logoImage = reader.result.toString();
          deficiency.image[0].imageName = image.name;
          deficiency.image[0].attachment = this.base64logoImage;
          this.image = reader.result.toString();
          event.value = "";
        };

        reader.onerror = (error) => {
          this.loggerService.log("Error on Loading Image", error);
          deficiency.imageUploadError = "Error while Loading File";
        };
      } else {
        deficiency.imageUploadError = "Invalid File Type";
      }
    } else if (image.size > 1097152) {
      deficiency.imageUploadError = "File Size is greater than 1MB";
    } else if (
      image.type.toLowerCase() !== "gif" ||
      image.type.toString() !== "jpg" ||
      image.type.toString() !== "application/pdf"
    ) {
      deficiency.imageUploadError = "Invalid File Type";
    }
  }

  checkForError(pickerInput) {
    if (!pickerInput || pickerInput === "") {
      return true;
      // } else if (pickerInput.indexOf('-') < 0) {
      //   return true;
    } else if (!moment(pickerInput, "YYYY-MM-DD", true).isValid()) {
      return true;
    } else if (
      moment(new Date(), "YYYY-MM-DD").isBefore(
        moment(pickerInput, "YYYY-MM-DD")
      )
    ) {
      console.log("picker" + moment(pickerInput, "YYYY-MM-DD"));
      console.log("Todays Date" + moment(new Date()), "YYYY-MM-DD");
      return true;
    } else {
      return false;
    }
  }
  updateDeficiencyDate(deficiency, pickerInput) {
    if (typeof pickerInput === "object") {
      if (this.errorMessages.indexOf("* Please enter a valid date") >= 0) {
        this.errorMessages.splice(
          this.errorMessages.indexOf("* Please enter a valid date"),
          1
        );
      } else if (
        this.errorMessages.indexOf("* Future Date is not allowed") >= 0
      ) {
        this.errorMessages.splice(
          this.errorMessages.indexOf("* Future Date is not allowed"),
          1
        );
      }
      deficiency.dateCorrected =
        deficiency && deficiency.date
          ? this.datepipe.transform(deficiency.date._d, "yyyy-MM-dd") +
            "T00:00:00.000Z"
          : "";
    } else {
      if (!pickerInput || pickerInput === "") {
        if (this.errorMessages.indexOf("* Please enter a valid date") < 0) {
          this.errorMessages.push("* Please enter a valid date");
        }
      } else if (pickerInput.indexOf("-") < 0) {
        if (this.errorMessages.indexOf("* Please enter a valid date") < 0) {
          this.errorMessages.push("* Please enter a valid date");
        }
      } else if (!moment(pickerInput, "MM-DD-YYYY", true).isValid()) {
        if (this.errorMessages.indexOf("* Please enter a valid date") < 0) {
          this.errorMessages.push("* Please enter a valid date");
        }
      } else if (
        moment(pickerInput, "MM-DD-YYYY").isAfter(
          moment(new Date(), "MM-DD-YYYY")
        )
      ) {
        this.errorMessages.push("* Future Date is not allowed");
      } else {
        if (this.errorMessages.indexOf("* Please enter a valid date") >= 0) {
          this.errorMessages.splice(
            this.errorMessages.indexOf("* Please enter a valid date"),
            1
          );
        } else if (
          this.errorMessages.indexOf("* Future Date is not allowed") >= 0
        ) {
          this.errorMessages.splice(
            this.errorMessages.indexOf("* Future Date is not allowed"),
            1
          );
        }
        deficiency.dateCorrected =
          this.datepipe.transform(pickerInput, "yyyy-MM-dd") + "T00:00:00.000Z";
      }
    }
  }

  getErrorMsgDate(pickerInput) {
    if (this.errorMessages.indexOf("* Future Date is not allowed") >= 0) {
      return "* Future Date is not allowed";
    } else if (this.errorMessages.indexOf("* Please enter a valid date") >= 0) {
      return "* Please enter a valid date";
    }
  }
  newTabImage(url) {
    const image = new Image();
    image.src = url;

    const w = window.open("", "_blank");
    w.document.write(image.outerHTML);
    w.document.close();
  }

  formatDate(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [month, day, year].join("-");
  }

  getInspectionOptionsStyle(inspector: CertifiedInspector) {
    if (inspector.isBlocked === true || this.isInspectorCertificateExpired(inspector)) {
      return { "background-color": "lightgrey",  color: "#495057" };
    }
  }

  private isInspectorCertificateExpired(
    inspector: CertifiedInspector
  ): boolean {

    let additionalCertification = inspector.additionalCertificateDtoList as additionalCertificates[];

    let nationalCertExpiryDate = inspector.nationalCertExpiryDate;
    let otherCertExpiryDate = inspector.otherCertExpiryDate;
    let stateCertExpiryDate = inspector.stateCertExpiryDate;

    const expiredAdditionalCertificates  = additionalCertification ? additionalCertification.filter(o => {
      return moment(o.otherCertExpiryDate, "MM-DD-YYYY") < moment()
    }) : []

    if (
      nationalCertExpiryDate == null &&
      otherCertExpiryDate == null &&
      stateCertExpiryDate == null &&
      expiredAdditionalCertificates.length == 0
    ) {
      return false;
    } else if (
      moment(nationalCertExpiryDate, "MM-DD-YYYY") < moment() ||
      moment(otherCertExpiryDate, "MM-DD-YYYY") < moment() ||
      moment(stateCertExpiryDate, "MM-DD-YYYY") < moment()
    ) {
      return true;
    }

    if(expiredAdditionalCertificates && expiredAdditionalCertificates.length > 0)
    return true

    return false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((x) => {
      x.unsubscribe();
    });
  }
}
