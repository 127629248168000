import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { OwnerCity, Owner, SearchInspectorProperties } from '../models/owner.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OwnerService = /** @class */ (function () {
    function OwnerService(http) {
        this.http = http;
        this.baseUrl = environment.baseUrl;
        this.enableEditMode = new BehaviorSubject(false);
        this.ownerPreferences = new BehaviorSubject(null);
        this.saveBasicDetails = new BehaviorSubject(false);
        this.savedContacts = new BehaviorSubject(null);
        this.ownerTemp = new BehaviorSubject(null);
        this.formInvalid = new BehaviorSubject(false);
        this.dashboardProperties = new BehaviorSubject(null);
        this.ownerProperties = new BehaviorSubject(null);
        this.preferencesUpdated = new BehaviorSubject(null);
    }
    OwnerService.prototype.getOwnerPreferences = function () {
        var url = this.baseUrl + "/owner/preferences";
        return this.http.get(url);
    };
    OwnerService.prototype.putOwnerPreferences = function (ownerId, owner) {
        var url = this.baseUrl + "/owner/preferences";
        return this.http.put(url, owner);
    };
    OwnerService.prototype.getAllProperties = function (params) {
        var url = this.baseUrl + "/properties";
        return this.http.post(url, params);
    };
    OwnerService.prototype.getOwnerDashboard = function (ownerId) {
        var url = this.baseUrl + "/owner/dashboard/myproperties";
        return this.http.get(url);
    };
    OwnerService.prototype.getOwnerStatistics = function () {
        var url = this.baseUrl + "/owner/dashboard/statistics";
        return this.http.get(url);
    };
    OwnerService.prototype.exportToCsv = function () {
        var url = this.baseUrl + "/owner/dashboard/myproperties/download";
        return this.http.get(url);
    };
    OwnerService.prototype.getBidInformationByPropertyId = function (propertyId) {
        var url = this.baseUrl + ("/bid/property/" + propertyId + "/information ");
        return this.http.get(url);
    };
    OwnerService.prototype.getInspectorInformationByPropertyId = function (propertyId) {
        var url = this.baseUrl + ("/bids/properties/" + propertyId + "/inspectors");
        return this.http.get(url);
    };
    OwnerService.prototype.sendBidInformationByPropertyId = function (body) {
        var url = this.baseUrl + "/bid";
        return this.http.post(url, body);
    };
    OwnerService.prototype.sendInspectorInformationBybidId = function (bidId, body) {
        var url = this.baseUrl + ("/bid/" + bidId + "/notifiedinspectors");
        return this.http.post(url, body);
    };
    OwnerService.prototype.getInspectionResultIdByInspectionId = function (inspectionId) {
        var url = this.baseUrl + ("/inspection/" + inspectionId + "/inspectionresult ");
        return this.http.get(url);
    };
    OwnerService.prototype.getAllBids = function () {
        var url = this.baseUrl + "/bids/properties";
        return this.http.get(url);
    };
    OwnerService.prototype.fetchUserName = function (id) {
        var url = this.baseUrl + ("/user/details/" + id + " ");
        return this.http.get(url);
    };
    OwnerService.prototype.getOwnerCities = function () {
        var url = this.baseUrl + "/owner/search";
        return this.http.get(url);
    };
    OwnerService.prototype.getInspectors = function () {
        // const data = { stateCode };
        var url = this.baseUrl + "/owner/inspectorList";
        return this.http.get(url);
    };
    OwnerService.prototype.assignProperty = function (pac, id) {
        var url = this.baseUrl + "/property/assign";
        return this.http.post(url, {
            accessCode: pac,
            id: id
        });
    };
    OwnerService.prototype.setPreference = function () {
        var url = this.baseUrl + "/setPreference";
        return this.http.put(url, {});
    };
    OwnerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OwnerService_Factory() { return new OwnerService(i0.ɵɵinject(i1.HttpClient)); }, token: OwnerService, providedIn: "root" });
    return OwnerService;
}());
export { OwnerService };
