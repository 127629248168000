import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { SignUpService } from "../services/sign-up.service";
import { SignUpRoles, User } from "../shared/models/signUp.model";
import { ZipcodeMappingDetails } from "../../core/models/property/property.model";
import { PropertyService } from "../../core/services/property/property.service";

@Component({
  selector: "app-sign-up-form",
  templateUrl: "./sign-up-form.component.html",
  styleUrls: ["./sign-up-form.component.scss"],
})
export class SignUpFormComponent implements OnInit, OnDestroy {
  public role: string;
  public signUpForm: FormGroup;
  signUpDetails: User;
  public signUpRoles: SignUpRoles[];
  public formValidity: boolean;
  public zipcodeMappingDetails: ZipcodeMappingDetails;
  public errors: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    role: string;
    orgName: string;
    Streetline1: string;
    Zipcode: string;
    JurisdictionName: string;
    tncCheck: string;
    recaptcha: string;
  };
  public recaptchaVal: string;
  public phoneRegex: any;
  subscriptions = [];
  buttonDisabled: boolean;
  // user

  constructor(
    public fb: FormBuilder,
    private signUpService: SignUpService,
    private propertyService: PropertyService,
    private router: Router
  ) {}

  ngOnInit() {
    this.buttonDisabled = false;
    this.phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    this.formValidity = true;
    this.resetErrors();
    this.recaptchaVal = "";
    this.subscriptions.push(
      this.signUpService.getSignUpRoles().subscribe((res) => {
        res = res.filter((x) => x.name !== "ROLE_RFA");
        this.signUpRoles = res;
      })
    );

    this.signUpDetails = {
      firstName: null,
      lastName: null,
      email: null,
      cotactNumber: null,
      role: null,
      name: null,
      streetLine1: null,
      streetLine2: null,
      zipcode: null,
      jurisdiction: null,
    };

    this.signUpForm = this.fb.group({
      firstName: [""],
      lastName: [""],
      email: [""],
      phone: [""],
      role: [""],
      orgName: [""],
      Streetline1: [""],
      Streetline2: [""],
      Zipcode: [""],
      JurisdictionName: [""],
      tncCheck: [""],
    });

    this.role = "";
  }

  checkRole(val) {
    this.role = val;
  }

  resetAll() {
    this.resetErrors();
  }

  signUpNewUser(val) {
    this.validateForm(val);
  }

  signUp(val) {
    if (this.formValidity) {
      this.buttonDisabled = true;

      var obj: any = this.signUpRoles.find((item) => {
        return item.id === parseInt(this.signUpForm.value.role, 10);
      });
      this.signUpDetails = {
        firstName: this.signUpForm.value.firstName,
        lastName: this.signUpForm.value.lastName,
        email: this.signUpForm.value.email,
        cotactNumber: this.signUpForm.value.phone,
        name: this.signUpForm.value.orgName,
        role: {
          id: parseInt(this.signUpForm.value.role, 10),
          name: this.signUpRoles.find((item) => {
            return item.id === parseInt(this.signUpForm.value.role, 10);
          }).name,
          description: this.signUpRoles.find((item) => {
            return item.id === parseInt(this.signUpForm.value.role, 10);
          }).description,
        },
        streetLine1: this.signUpForm.value.Streetline1,
        streetLine2: this.signUpForm.value.Streetline2,
        zipcode: this.signUpForm.value.Zipcode,
      };

      this.signUpDetails.cotactNumber = this.signUpDetails.cotactNumber.replace(
        this.phoneRegex,
        "$1-$2-$3"
      );

      if (
        this.signUpForm.value.role === "3" ||
        this.signUpForm.value.role === "4"
      ) {
        this.signUpDetails.name = this.signUpForm.value.orgName;
      } else {
        this.signUpDetails.name = null;
      }

      if (this.signUpForm.value.role === "3") {
        const jusristiction = {
          id: null,
          name: this.signUpForm.value.JurisdictionName,
          status: 0,
        };
        this.signUpDetails.jurisdiction = jusristiction;
      } else {
        this.signUpDetails.jurisdiction = null;
      }
      // trim all spaces that are of type string
      Object.keys(this.signUpDetails).map(
        (prop) =>
          (this.signUpDetails[prop] =
            typeof this.signUpDetails[prop] === "string"
              ? this.signUpDetails[prop].trim()
              : this.signUpDetails[prop])
      );
      this.subscriptions.push(
        this.signUpService.postSignUpDetails(this.signUpDetails).subscribe(
          (res) => {
            this.buttonDisabled = false;
            alert("An email has been sent to " + this.signUpDetails.email);
            this.router.navigate(["login/login-form"]);
          },
          (error) => {
            this.buttonDisabled = false;
            if (error.status === 400) {
              alert(error.error.message);
              this.buttonDisabled = false;
            } else {
              alert("Unexpected error occured");
              this.buttonDisabled = false;
            }
          }
        )
      );
    } else {
      // set focus to input
    }
  }

  resetErrors() {
    this.errors = {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      role: null,
      orgName: null,
      Streetline1: null,
      Zipcode: null,
      JurisdictionName: null,
      tncCheck: null,
      recaptcha: null,
    };
  }

  validateForm(val) {
    this.resetErrors();

    this.formValidity = true;
    //  check form validity and set it accordingly
    this.validateRecaptcha();
    this.validateFirstName();
    this.validateLastName();
    this.validateEmail();
    this.validatePhoneNumber();
    this.validateRole();
    if (
      this.signUpForm.value.role === "4" ||
      this.signUpForm.value.role === "3"
    ) {
      this.validateOrgName();
    }
    if (this.signUpForm.value.role !== "") {
      this.validateStLine1();
      this.validateZip();
      if (this.signUpForm.value.role === "3") {
        this.validateJurisdiction();
      }
    }

    if (!this.signUpForm.value.tncCheck) {
      this.errors.tncCheck = "Please accept Terms & Conditions of TEGRIS";
      this.formValidity = false;
    }

    if (/^([0-9]{5})$/.test(this.signUpForm.value.Zipcode)) {
      this.subscriptions.push(
        this.propertyService
          .getJurisdictionDetailsFromZipcode(this.signUpForm.value.Zipcode)
          .subscribe(
            (res) => {
              this.zipcodeMappingDetails = res;
              if (!this.zipcodeMappingDetails) {
                alert(
                  "Zipcode does not exist. Please contact support@tegrisfire.com"
                );
                return;
              }
              this.signUp(val);
            },
            (error) => {
              console.log(error);
            }
          )
      );
    }
  }

  validateFirstName() {
    const firstName = this.signUpForm.value.firstName;
    if (!firstName || firstName.length === 0) {
      this.errors.firstName = "Enter First Name of the user";
      this.formValidity = false;
    }
  }

  validateLastName() {
    const lastName = this.signUpForm.value.lastName;
    if (!lastName || lastName.length === 0) {
      this.errors.lastName = "Enter Last Name of the user";
      this.formValidity = false;
    }
  }

  validateEmail() {
    const email = this.signUpForm.value.email;
    if (!email || email.length === 0) {
      this.errors.email = "Please enter Email address";
      this.formValidity = false;
    } else if (
      !/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(
        email
      )
    ) {
      this.errors.email = "Enter a valid email address like abc@gmail.com";
      this.formValidity = false;
      document.getElementById('email').focus();
    }
  }

  validatePhoneNumber() {
    const phone = this.signUpForm.value.phone;
    if (!phone || phone.length === 0) {
      this.errors.phone = "Enter Phone Number of the user";
      this.formValidity = false;
    } else if (!/^([0-9]{3}[-. ]?[0-9]{3}[-. ]?[0-9]{4})$/.test(phone)) {
      this.errors.phone = "Enter valid Phone Number";
      this.formValidity = false;
    }
  }

  validateRole() {
    const role = this.signUpForm.value.role;
    if (role === "") {
      this.errors.role = "Select one role for the user";
      this.formValidity = false;
    }
  }

  validateOrgName() {
    const orgName = this.signUpForm.value.orgName;
    if (!orgName || orgName.length === 0) {
      if (this.signUpForm.value.role === "3") {
        this.errors.orgName = "Enter name of the Authority";
      } else if (this.signUpForm.value.role === "4") {
        this.errors.orgName = "Enter Company Name for Inspector";
      }
      this.formValidity = false;
    }
  }

  validateStLine1() {
    const stline1 = this.signUpForm.value.Streetline1;
    if (!stline1 || stline1.length === 0) {
      this.errors.Streetline1 = "Enter Street Line 1 details of the address";
      this.formValidity = false;
    }
  }

  validateZip() {
    const zip = this.signUpForm.value.Zipcode;
    if (!zip || zip.length === 0) {
      this.errors.Zipcode = "Enter Zip Code for the address";
      this.formValidity = false;
    } else if (!/^([0-9]{5})$/.test(zip)) {
      this.errors.Zipcode =
        "There is some error validating zip code. Please try again";
      this.formValidity = false;
    }
  }

  validateJurisdiction() {
    const jurisdiction = this.signUpForm.value.JurisdictionName;
    if (!jurisdiction || jurisdiction.length === 0) {
      this.errors.JurisdictionName = "Enter Jurisdiction Name for Authority";
      this.formValidity = false;
      // show error on screen
      return true;
    } else if (
      !/^[A-Z]{2}, [a-zA-Z0-9$&+_,:;=?@#|'<>.^*()%!-]+( [a-zA-Z0-9$&+_,:;=?@#|'<>.^*()%!-]+)*$/.test(
        jurisdiction
      )
    ) {
      this.errors.JurisdictionName =
        "Incorrect Format. Please enter the jurisdiction in following format.";
      this.formValidity = false;
      // show error on screen
      return true;
    }
    // do not show error on screen
    return false;
  }

  validateRecaptcha() {
    if (!this.recaptchaVal || this.recaptchaVal.length == 0) {
      this.errors.recaptcha = "Enter Captcha value";
      this.formValidity = false;
    }
  }

  backToLogin() {
    this.router.navigate(["login/login-form"]);
  }

  public resolved(captchaResponse: string) {}

  ngOnDestroy() {
    this.subscriptions.forEach((x) => {
      x.unsubscribe();
    });
  }
}
