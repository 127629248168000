import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../core/services/loader/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  loading;
  constructor(private loaderService: LoaderService) { }

  ngOnInit() {
    this.loaderService.isLoading.subscribe((res) =>{
      this.loading = res;
    });
  }

}
