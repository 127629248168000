<table cellspacing="0" cellpadding="0" class="sp-top-table1" width="100%" style="margin-bottom: 2%;">
  <tbody>
    <tr>
      <td class="header-td" colspan="9">PRV - PRESSURE REDUCING VALVE TESTING
      </td>
    </tr>
    <tr>
      <td width="12%">Floor/Stair</td>
      <td width="12%">Inlet-Static(psi)</td>
      <td width="12%">Outlet-Static(psi)</td>
      <td width="12%">Inlet-Residual(psi)</td>
      <td width="12%">Outlet-Residual(psi)</td>
      <td width="10%">Outlet-Flow(gpm)</td>
      <td width="20%">P.R.V Setting(psi)</td>
      <td *ngIf="!reviewInspection"> &nbsp;</td>
    </tr>
    <tr *ngFor="let k of prv;let i = index" [attr.data-index]="i">
      <td width="10%">
        <input type="text" style="width:63px;" [(ngModel)]="k['Floor/Stair']"
          (change)="updateModel('Floor/Stair',i , k)" name="qtd-{{findQuestionIdSecond('Floor/Stair')}}-{{i}}"
          id="qtd-{{findQuestionIdSecond('Floor/Stair')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="10%">
        <input type="text" style="width:63px;" [(ngModel)]="k['Inlet-Static(psi)']"
          (change)="updateModel('Inlet-Static(psi)',i , k)"
          name="qtd-{{findQuestionIdSecond('Inlet-Static(psi)')}}-{{i}}"
          id="qtd-{{findQuestionIdSecond('Inlet-Static(psi)')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="10%">
        <input type="text" style="width:63px;" [(ngModel)]="k['Outlet-Static(psi)']"
          (change)="updateModel('Outlet-Static(psi)',i , k)"
          name="qtd-{{findQuestionIdSecond('Outlet-Static(psi)')}}-{{i}}"
          id="qtd-{{findQuestionIdSecond('Outlet-Static(psi)')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="10%">
        <input type="text" style="width:63px;" [(ngModel)]="k['Inlet-Residual(psi)']"
          (change)="updateModel('Inlet-Residual(psi)',i , k)"
          name="qtd-{{findQuestionIdSecond('Inlet-Residual(psi)')}}-{{i}}"
          id="qtd-{{findQuestionIdSecond('Inlet-Residual(psi)')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="10%">
        <input type="text" style="width:63px;" [(ngModel)]="k['Outlet-Residual(psi)']"
          (change)="updateModel('Outlet-Residual(psi)',i , k)"
          name="qtd-{{findQuestionIdSecond('Outlet-Residual(psi)')}}-{{i}}"
          id="qtd-{{findQuestionIdSecond('Outlet-Residual(psi)')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="10%">
        <input type="text" style="width:63px;" [(ngModel)]="k['Outlet-Flow(gpm)']"
          (change)="updateModel('Outlet-Flow(gpm)',i , k)" name="qtd-{{findQuestionIdSecond('Outlet-Flow(gpm)')}}-{{i}}"
          id="qtd-{{findQuestionIdSecond('Outlet-Flow(gpm)')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="20%">
        <input type="text" style="width:103px;" [(ngModel)]="k['P.R.V.Setting(psi)']"
          (change)="updateModel('P.R.V.Setting(psi)',i , k)"
          name="qtd-{{findQuestionIdSecond('P.R.V.Setting(psi)')}}-{{i}}"
          id="qtd-{{findQuestionIdSecond('P.R.V.Setting(psi)')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="40%" *ngIf="!reviewInspection">
        <button type="button" class="cust-but" *ngIf="prv.length !== 1" (click)="deleteRow(k)">Delete</button>
        <button type="button" class="cust-but" *ngIf="i === prv.length-1" (click)="addRow();">Add</button>
      </td>
    </tr>
  </tbody>
</table>
