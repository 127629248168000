import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var FireCommunicationComponent = /** @class */ (function () {
    function FireCommunicationComponent() {
    }
    FireCommunicationComponent.prototype.ngOnInit = function () {
        this.init();
    };
    FireCommunicationComponent.prototype.init = function () {
        this.communicationTable = {
            phone: {
                'unitsInBuilding': this.findAnswerbyQuestion('Phone', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Phone', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Phone', 'resultsAcceptable') || 'N/A'
            },
            cellular: {
                'unitsInBuilding': this.findAnswerbyQuestion('Cellular', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Cellular', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Cellular', 'resultsAcceptable') || 'N/A'
            },
            radio: {
                'unitsInBuilding': this.findAnswerbyQuestion('Radio', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Radio', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Radio', 'resultsAcceptable') || 'N/A'
            }
        };
    };
    FireCommunicationComponent.prototype.ngOnChanges = function (changes) {
        if (changes['questions'] && changes['questions'].currentValue) {
            this.questions = tslib_1.__spread(changes['questions'].currentValue);
            this.init();
        }
    };
    FireCommunicationComponent.prototype.findAnswerbyQuestion = function (question, description) {
        var myQuestion = this.questions.filter(function (x) { return x.questions.question === question; });
        if (myQuestion.length > 0) {
            if (myQuestion[0].questions &&
                myQuestion[0].questions.tableAnswers &&
                myQuestion[0].questions.tableAnswers.length > 0 &&
                myQuestion[0].questions.tableAnswers[0][description]) {
                this.updateModel(question, description, myQuestion[0].questions.tableAnswers[0][description]);
                return myQuestion[0].questions.tableAnswers[0][description];
            }
            else {
                if (description === 'resultsAcceptable') {
                    this.updateModel(question, description, 'N/A');
                    return 'N/A';
                }
                else {
                    return '';
                }
            }
        }
        return;
    };
    FireCommunicationComponent.prototype.findQuestionId = function (description) {
        var myQuestion = this.questions.filter(function (x) { return x.questions.question === description; });
        if (myQuestion.length > 0) {
            return myQuestion[0].questions.questionId;
        }
        return 0;
    };
    FireCommunicationComponent.prototype.updateModel = function (question, column, value) {
        var id = this.findQuestionId(question);
        if (!this.qtd[id] || typeof this.qtd[id][0] === 'string') {
            this.qtd[id] = {};
        }
        if (column === 'unitsInBuilding') {
            this.qtd[id][column] = value;
        }
        else if (column === 'unitsTested') {
            this.qtd[id][column] = value;
        }
        else if (column === 'resultsAcceptable') {
            this.qtd[id][column] = value;
        }
    };
    return FireCommunicationComponent;
}());
export { FireCommunicationComponent };
