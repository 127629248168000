
<div mat-dialog-title  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
   <ng-content select=".dialog-title"></ng-content>
</div>
<div mat-dialog-content>
   <ng-content select=".dialog-content"></ng-content>
</div>
<div mat-dialog-actions>
   <ng-content select=".dialog-actions"></ng-content>
</div>


