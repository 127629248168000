import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { InspectorService } from '../../../services/inspector.service';
import { Roles } from '../../../../shared/models/user.model';
import { AuthorityService } from '../../../../authority/shared/services/authority.service';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: []
})
export class DeleteDialogComponent implements OnInit {
  userRole: string;
  user: any;

  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private inspectorService: InspectorService, private authService: AuthorityService) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userDetails'));
  }

  save() {
    if (this.user.role.id === Roles.ROLE_AUTHORITY_ADMIN ||
      this.user.role.id === Roles.ROLE_AUTHORITY_WORX || this.user.role.id === Roles.ROLE_RFA) {
      this.inspectorService.deleteLetterHead(this.data.id).subscribe((x) => {
      });
    } else {
      this.inspectorService.deleteLetterHead(this.data.id).subscribe((x) => {
      });
    }
    this.dialogRef.close('continue');
  }

  cancel(): void {
    this.dialogRef.close('cancelled');
  }
}
