/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./certify-bypass.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/app-dialog/app-dialog.component.ngfactory";
import * as i3 from "../../../shared/components/app-dialog/app-dialog.component";
import * as i4 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "@angular/forms";
import * as i7 from "./certify-bypass.component";
import * as i8 from "@angular/material/dialog";
var styles_CertifyBypassComponent = [i0.styles];
var RenderType_CertifyBypassComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CertifyBypassComponent, data: {} });
export { RenderType_CertifyBypassComponent as RenderType_CertifyBypassComponent };
export function View_CertifyBypassComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "app-dialog", [], null, null, null, i2.View_AppDialogComponent_0, i2.RenderType_AppDialogComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppDialogComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "span", [["class", "dialog-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Certification "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "mat-icon", [["class", "cursor-pointer mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(8, 0, null, 1, 8, "div", [["class", "dialog-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "input", [["class", "col-1"], ["style", "\n          float: left;margin-top: 2vh;height: 25px"], ["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.checked = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i6.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.CheckboxControlValueAccessor]), i1.ɵdid(12, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(14, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "label", [["class", "col-form-label col-form-label-sm inputLabel notes-sub-heading col-11"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \"By clicking here you are certifying, under penalties of perjury, that this property owner has specifically granted you access for the sole purpose of inputting an inspection report for which you are responsible for and that you understand and will adhere to the terms of use in TEGRIS Fire. Your bypassed access is being tracked in the system and will be monitored for misuse.\" "])), (_l()(), i1.ɵeld(17, 0, null, 2, 4, "div", [["class", "dialog-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "custom-buttons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.certify() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Submit"])), (_l()(), i1.ɵeld(20, 0, null, null, 1, "button", [["class", "custom-buttons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 6, 0); var currVal_9 = _co.checked; _ck(_v, 12, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).inline; var currVal_1 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 14).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 14).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 14).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 14).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 14).ngClassValid; var currVal_7 = i1.ɵnov(_v, 14).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 14).ngClassPending; _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_CertifyBypassComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-certify-bypass", [], null, null, null, View_CertifyBypassComponent_0, RenderType_CertifyBypassComponent)), i1.ɵdid(1, 114688, null, 0, i7.CertifyBypassComponent, [i8.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CertifyBypassComponentNgFactory = i1.ɵccf("app-certify-bypass", i7.CertifyBypassComponent, View_CertifyBypassComponent_Host_0, {}, {}, []);
export { CertifyBypassComponentNgFactory as CertifyBypassComponentNgFactory };
