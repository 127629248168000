import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  store(body) {
    return this.http.post(`${this.baseUrl}/cart`, body);

  }

  
  get() {
    return this.http.get(`${this.baseUrl}/cart`);
  }

  getCount() {
    const url = this.baseUrl + `/cart/count`;
    return this.http.get<number>(url);
  }


  remove(id) {
    return this.http.delete(`${this.baseUrl}/cart/${id}`);
  }

  
  submit(body) {
    // TODO: remove redundant code
    // not removing now, because unsure of from where all is it being called
    return this.http.post(`${this.baseUrl}/cart`, body);
  }


}
