import * as tslib_1 from "tslib";
import { AfterViewChecked, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { NavigationEnd } from '@angular/router';
import { DuplicatePropertyComponent } from './duplicate-property/duplicate-property.component';
import { USPSInvalidPopUpComponent } from './usps-popUp-invalid/usps-popUp-invalid.component';
import { USPSValidPopUpComponent } from './usps-popUp-valid/usps-popUp-valid.component';
import { Roles } from '../../shared/models/user.model';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { ChangeOwnerComponent } from '../../file-cabinet/change-owner/change-owner.component';
import { EditPropertyDialogComponent } from '../../inspection/enter-inspection/edit-property-dialog/edit-property-dialog.component';
var PropertyDetailsComponent = /** @class */ (function () {
    // selectedStateID: string;
    // selectedManagerStateID: string;
    // selectedCityID: string;
    // selectedManagerCityID: string;
    // public constTypeOptions: ContsructionType[];
    // otherContacts = [];
    function PropertyDetailsComponent(fb, propertyService, dialog, router, contactsService, journalService, 
    // private _snackBar: MatSnackBar,
    siteAdminService, fileCabService, route, location, authorityService, userService) {
        this.fb = fb;
        this.propertyService = propertyService;
        this.dialog = dialog;
        this.router = router;
        this.contactsService = contactsService;
        this.journalService = journalService;
        this.siteAdminService = siteAdminService;
        this.fileCabService = fileCabService;
        this.route = route;
        this.location = location;
        this.authorityService = authorityService;
        this.userService = userService;
        this.formData = new EventEmitter();
        this.errorOccured = new EventEmitter(false);
        this.close = new EventEmitter();
        this.subscriptions = [];
        this.showMessage = false;
        this.addressUspsDone = false;
        this.managerAddressUspsDone = false;
        this.managerAddressSameAsProp = false;
        this.isDisabledReadOnly = false;
        this.isDisabledReadOnly1 = false;
        this.formSubmissionAllowedIfContactNotPresent = false;
    }
    PropertyDetailsComponent.prototype.ngAfterViewChecked = function () {
        if (this.propertyIdForEdit && !this.addPropertyForm.get('address').get('streetLine1').value) {
            this.addPropertyForm.get('buildingName').patchValue(this.propertyDetails ? this.propertyDetails.buildingName : '');
            this.addPropertyForm.get('occupancyType').patchValue(this.propertyDetails ? this.propertyDetails.propertyType : '');
            this.addPropertyForm.get('constructionType').patchValue(this.propertyDetails ? this.propertyDetails.buildingType : '');
            this.addPropertyForm.get('noOfStories').patchValue(this.propertyDetails ? this.propertyDetails.numberOfStories : '');
            this.addPropertyForm.get('yearBuilt').patchValue(this.propertyDetails ? this.propertyDetails.yearBuilt : '');
            this.addPropertyForm.get('floorArea').patchValue(this.propertyDetails ? this.propertyDetails.floorArea : '');
            this.addPropertyForm.get('address').patchValue({
                streetLine1: this.propertyDetails.streetLine1,
                streetLine2: this.propertyDetails.streetLine2 ? this.propertyDetails.streetLine2 : '',
                state: this.propertyDetails.zipcode && this.propertyDetails.zipcode.state
                    ? this.propertyDetails.zipcode.state
                    : '',
                city: this.propertyDetails.zipcode && this.propertyDetails.zipcode.city
                    ? this.propertyDetails.zipcode.city
                    : '',
                zipcode: this.propertyDetails.zipcode && this.propertyDetails.zipcode.zipcode
                    ? this.propertyDetails.zipcode.zipcode
                    : '',
            });
            var managerDetails = this.propertyDetails.propertyContacts.find(function (p) { return p.isManager === true; });
            if (managerDetails) {
                this.addPropertyForm.get('manager').patchValue({
                    firstName: managerDetails.firstName ? managerDetails.firstName : '',
                    lastName: managerDetails.lastName ? managerDetails.lastName : '',
                    phoneNumber: managerDetails.phoneNum ? managerDetails.phoneNum : '',
                    emailAddress: managerDetails.email ? managerDetails.email : '',
                    managerAddress: {
                        streetLine1: managerDetails.streetLine1 ? managerDetails.streetLine1 : '',
                        streetLine2: managerDetails.streetLine2 ? managerDetails.streetLine2 : '',
                        zipcode: (managerDetails.zipcode) ? managerDetails.zipcode.zipcode : '',
                        city: (managerDetails.zipcode) ? managerDetails.zipcode.city : '',
                        state: (managerDetails.zipcode) ? managerDetails.zipcode.state : ''
                    }
                });
            }
            this.addPropertyForm.get('owner').patchValue({
                name: this.propertyDetails.ownerId ? this.propertyDetails.ownerId.firstName + ' ' + this.propertyDetails.ownerId.lastName : ' ',
                number: this.propertyDetails.ownerId ? this.propertyDetails.ownerId.cotactNumber : '',
                address: this.propertyDetails.ownerId ? this.propertyDetails.ownerId.address.replace('<br/>', ' ') : '',
                email: this.propertyDetails.ownerId ? this.propertyDetails.ownerId.email : '',
            });
        }
    };
    PropertyDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.route.queryParams
            .subscribe(function (params) {
            _this.department = params.department;
            _this.editId = params.id;
            // setting to avoid wrong view rendering
            if (!_this.editId) {
                _this.showPencil = false;
                _this.showMessage = false;
            }
            else {
                _this.showPencil = true;
                _this.showMessage = false;
            }
        }));
        this.creationDisabled = false;
        this.user = JSON.parse(localStorage.getItem('userDetails'));
        if (this.user) {
            this.isAuthorityLoggedIn = this.user.role.id === Roles.ROLE_SITE_ADMIN
                || this.user.role.id === Roles.ROLE_AUTHORITY_ADMIN || this.user.role.id === Roles.ROLE_AUTHORITY_WORX;
        }
        this.zipcodeMappingDetails = { jurisdictionDto: [] };
        var juristictionArray = [];
        this.userRole = localStorage.getItem('userRole');
        juristictionArray.push(this.user.jurisdiction);
        if (this.userRole === 'ROLE_AUTHORITY' || this.userRole === 'ROLE_AUTHORITY_WORX') {
            this.zipcodeMappingDetails = { jurisdictionDto: juristictionArray };
        }
        this.nextDisabled = false;
        this.readOnlyCity = true;
        this.readOnlyState = true;
        this.readOnlyManagerCity = true;
        this.readOnlyManagerState = true;
        this.showAdditionZipCode = false;
        // this.uspsOn = environment.uspsServiceOn;
        this.isCanadianZipCode = false;
        this.isCanadianZipCodeManager = false;
        this.phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        this.canadinaZipRegex = /^(?!.*[DFIOQUdfioqu])[A-VXYa-vxy][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/;
        this.allowManagerPopulate = false;
        this.states = [];
        this.cities = [];
        // this.selectedStateID = null;
        // this.selectedCityID = null;
        // this.selectedManagerStateID = null;
        // this.selectedManagerCityID = null;
        // this.propertyService.getAllStates().subscribe(
        //   res => {
        //     this.states = res;
        //     this.selectedStateID = this.states[0].id;
        //     this.selectedManagerStateID = this.states[0].id;
        //   }
        // );
        this.subscriptions.push(this.propertyService.getOccupancyType().subscribe(function (data) {
            _this.occupancyTypeOptions = data;
        }, function (error) {
            console.log(error);
            alert('An Error has Occured');
        }));
        this.subscriptions.push(this.propertyService.getConstructionTypeOptions().subscribe(function (data) {
            _this.constructionTypeOptions = data;
        }, function (error) {
            console.log(error);
            alert('An Error has Occured');
        }));
        // reset errors
        this.resetErrorsMap();
        this.propertyDetails = {
            accessCode: null,
            assignmentId: null,
            buildingName: "",
            buildingType: null,
            createdBy: 0,
            floorArea: null,
            id: null,
            isActive: null,
            isPropertyActive: false,
            jurisdiction: null,
            numberOfStories: null,
            ownerId: null,
            propertyContacts: [],
            propertyType: null,
            propertySubType: null,
            streetLine1: null,
            streetLine2: null,
            yearBuilt: null,
            updatedBy: null,
            zipcode: null
        };
        var juristriction = [];
        juristriction = this.user.jurisdiction;
        this.zipcodeMappingDetails.selectedJurisdictionId = juristriction ? juristriction.id : -1;
        this.addPropertyForm = this.fb.group({
            buildingName: [''],
            isActive: [true],
            occupancyType: [''],
            occupancySubType: [],
            otherOccupancyType: [],
            constructionType: [''],
            otherContructionType: [],
            noOfStories: ['', Validators.maxLength(2)],
            yearBuilt: ['', Validators.pattern('[0-9]{4}')],
            floorArea: [],
            address: this.fb.group({
                streetLine1: ['', Validators.required],
                streetLine2: [''],
                zipcode: ['', Validators.required],
                additionalZipcode: [''],
                city: ['', Validators.required],
                state: ['', Validators.required],
                jurisdictionName: [juristriction ? juristriction.id : -1]
            }),
            manager: this.fb.group({
                firstName: ['PROPERTY'],
                lastName: ['MANAGER'],
                phoneNumber: [''],
                emailAddress: [
                    '',
                    Validators.pattern('[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+')
                ],
                copyAddress: [false],
                managerAddress: this.fb.group({
                    streetLine1: [],
                    streetLine2: [],
                    zipcode: [],
                    city: [],
                    state: []
                })
            }),
            owner: this.fb.group({
                name: [''],
                number: [''],
                address: [''],
                email: ['']
            })
        });
        // this.updateManagerContactsFlag();
        // property form
        if (this.userService.userDetails && this.userService.userDetails.accessSpecifier === 'READ_ONLY') {
            this.isDisabledReadOnly = true;
            //this.addPropertyForm.controls.buildingName.disable();
            //this.fb.control['buildingName'].disable();
        }
        this.subscriptions.push(this.propertyService.propertyId$.subscribe(function (res) {
            _this.errorOccured.emit(false);
            if (+res > 0) {
                _this.loading = true;
                _this.subscriptions.push(_this.propertyService
                    .getPropertyById(_this.propertyService.propertyId$.getValue().toString())
                    .subscribe(function (response) {
                    _this.loading = false;
                    _this.propertyService.propertyDetails$.next(response);
                    if (response) {
                        // Code Block for Worx allow edit or not - start
                        if (_this.department && _this.department.indexOf('Worx') > 0) {
                            _this.subscriptions.push(_this.authorityService.getAuthorityPreferences().subscribe(function (res) {
                                _this.authorityService.authorityPrefenrences.next(res);
                                var pref = res;
                                var createdByCurrentUser = (response.createdByEmail === _this.user.email ||
                                    pref.authorityContacts.exisitingContacts.filter(function (elem) {
                                        elem.emailAddress === response.createdByEmail;
                                    }).length > 1);
                                if (_this.user.role.name === "ROLE_AUTHORITY_WORX"
                                    && createdByCurrentUser) {
                                    _this.showPencil = false;
                                    _this.showMessage = false;
                                }
                                else {
                                    if (_this.user.isPPA) {
                                        _this.showPencil = false;
                                        _this.showMessage = false;
                                    }
                                    else {
                                        _this.showPencil = true;
                                        _this.showMessage = true;
                                    }
                                }
                            }, function (error) {
                            }));
                        }
                        else {
                            _this.showPencil = false;
                        }
                        var occupancyType = void 0;
                        var occupancySubType = void 0;
                        var buildingType = void 0;
                        var propertyContact = void 0;
                        if (response.propertyContacts &&
                            response.propertyContacts.length > 0) {
                            if (response.propertyContacts.find(function (x) { return x.isManager; })) {
                                propertyContact = response.propertyContacts.find(function (x) { return x.isManager; });
                            }
                            // this.otherContacts = (response.propertyContacts.filter((x) => !x.isManager));
                        }
                        if (response.propertyType && _this.occupancyTypeOptions) {
                            occupancyType = _this.occupancyTypeOptions.find(function (x) {
                                return x.name === response.propertyType;
                            });
                            if (!occupancyType) {
                                occupancyType = _this.occupancyTypeOptions[_this.occupancyTypeOptions.length - 1];
                                _this.occupancyTypeOther = true;
                            }
                            if (response.propertySubType &&
                                occupancyType &&
                                occupancyType.propertySubtypes &&
                                !_this.occupancyTypeOther) {
                                _this.occupancySubTypeDisplay = true;
                                _this.occupancySubTypeOptions =
                                    occupancyType.propertySubtypes;
                                occupancySubType = occupancyType.propertySubtypes.find(function (x) {
                                    return x.subtypeName === response.propertySubType;
                                });
                            }
                            else {
                                _this.occupancySubTypeDisplay = false;
                            }
                        }
                        if (response.buildingType && _this.constructionTypeOptions) {
                            buildingType = _this.constructionTypeOptions.find(function (x) {
                                return x.name === response.buildingType;
                            });
                            if (!buildingType) {
                                buildingType = { name: 'Other' };
                                _this.constructionTypeOther = true;
                            }
                        }
                        _this.addPropertyForm = _this.fb.group({
                            buildingName: [
                                response.buildingName ? response.buildingName : ''
                            ],
                            isActive: [
                                response.isActive !== null ? response.isActive : 'true'
                            ],
                            occupancyType: [occupancyType ? occupancyType.name : ''],
                            occupancySubType: [
                                occupancySubType ? occupancySubType.subtypeName : ''
                            ],
                            otherOccupancyType: [
                                occupancyType && occupancyType.name === 'Other'
                                    ? response.propertyType
                                    : ''
                            ],
                            constructionType: [
                                buildingType ? buildingType.name : ''
                            ],
                            otherContructionType: [
                                buildingType && buildingType.name === 'Other'
                                    ? response.buildingType
                                    : ''
                            ],
                            noOfStories: [
                                response.numberOfStories || '',
                                Validators.maxLength(2)
                            ],
                            yearBuilt: [
                                response.yearBuilt || '',
                                Validators.pattern('[0-9]{4}')
                            ],
                            floorArea: [response.floorArea || ''],
                            address: _this.fb.group({
                                streetLine1: [
                                    response.streetLine1 || '',
                                    Validators.required
                                ],
                                streetLine2: [response.streetLine2 || ''],
                                zipcode: [
                                    response.zipcode && response.zipcode.zipcode
                                        ? response.zipcode.zipcode
                                        : '',
                                    Validators.required
                                ],
                                additionalZipcode: [''],
                                city: [
                                    response.zipcode && response.zipcode.city
                                        ? response.zipcode.city
                                        : '',
                                    Validators.required
                                ],
                                state: [
                                    response.zipcode && response.zipcode.state
                                        ? response.zipcode.state
                                        : '',
                                    Validators.required
                                ],
                                jurisdictionName: [
                                    response.jurisdiction ? response.jurisdiction.id : ''
                                ]
                            }),
                            manager: _this.fb.group({
                                firstName: [
                                    propertyContact
                                        ? propertyContact.firstName
                                        : ''
                                ],
                                lastName: [
                                    propertyContact
                                        ? propertyContact.lastName
                                        : ''
                                ],
                                phoneNumber: [
                                    propertyContact
                                        ? propertyContact.phoneNum
                                        : ''
                                ],
                                emailAddress: [
                                    propertyContact
                                        ? propertyContact.email
                                        : '',
                                    Validators.pattern('[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+')
                                ],
                                copyAddress: [false],
                                managerAddress: _this.fb.group({
                                    streetLine1: [
                                        propertyContact
                                            ? propertyContact.streetLine1
                                            : ''
                                    ],
                                    streetLine2: [
                                        propertyContact
                                            ? propertyContact.streetLine2
                                            : ''
                                    ],
                                    zipcode: [
                                        propertyContact &&
                                            propertyContact.zipcode &&
                                            propertyContact.zipcode.zipcode
                                            ? propertyContact.zipcode.zipcode
                                            : ''
                                    ],
                                    city: [
                                        propertyContact &&
                                            propertyContact.zipcode &&
                                            propertyContact.zipcode.city
                                            ? propertyContact.zipcode.city
                                            : ''
                                    ],
                                    state: [
                                        propertyContact &&
                                            propertyContact.zipcode &&
                                            propertyContact.zipcode.state
                                            ? propertyContact.zipcode.state
                                            : ''
                                    ]
                                })
                            }),
                            owner: _this.fb.group({
                                name: [response.ownerId ? response.ownerId.firstName + ' ' + response.ownerId.lastName : ' '],
                                number: [response.ownerId ? response.ownerId.cotactNumber : ''],
                                address: [response.ownerId ? response.ownerId.address : ''],
                                email: [response.ownerId ? response.ownerId.email : '']
                            })
                        });
                        // this.updateManagerContactsFlag();
                    }
                    if (_this.userRole === 'ROLE_AUTHORITY' || _this.userRole === 'ROLE_RFA' ||
                        _this.userRole === 'ROLE_AUTHORITY_WORX' || _this.userRole === 'ROLE_SITE_ADMIN') {
                        if (response && response.isPropertyActive) {
                            _this.propertyDetails.isPropertyActive = true;
                        }
                        else if (response && !response.isPropertyActive) {
                            _this.propertyDetails.isPropertyActive = false;
                        }
                    }
                    if (response.zipcode && response.zipcode.zipcode) {
                        _this.subscriptions.push(_this.propertyService
                            .getJurisdictionDetailsFromZipcode(response.zipcode.zipcode)
                            .subscribe(function (data) {
                            if (data) {
                                _this.zipcodeMappingDetails = data;
                                _this.zipcodeMappingDetails.jurisdictionDto = _this.zipcodeMappingDetails.jurisdictionDto.filter(function (res) { return res.id != -1; });
                                if (data && data.city && data.state && _this.zipcodeMappingDetails) {
                                    _this.readOnlyCity = true;
                                    _this.readOnlyState = true;
                                    _this.addPropertyForm.get('address').patchValue({
                                        jurisdictionName: _this.propertyService.propertyDetails$.getValue().jurisdiction.id,
                                        state: _this.zipcodeMappingDetails.state,
                                        city: _this.zipcodeMappingDetails.city
                                    });
                                    if (!_this.zipcodeMappingDetails.jurisdictionDto ||
                                        _this.zipcodeMappingDetails.jurisdictionDto.length === 0) {
                                        _this.zipcodeMappingDetails.jurisdictionDto = [];
                                    }
                                    // this.addPropertyForm.value.address.jurisdictionName = this.zipcodeMappingDetails.selectedJurisdictionId;
                                }
                                else {
                                    _this.zipcodeMappingDetails = { jurisdictionDto: [] };
                                    // this.getCitiesByState(this.selectedStateID);
                                }
                            }
                            else {
                                _this.zipcodeMappingDetails = { jurisdictionDto: [] };
                            }
                        }));
                    }
                    if (_this.addPropertyForm.value.address.zipcode.length === 5) {
                        _this.isCanadianZipCode = false;
                    }
                    else {
                        _this.isCanadianZipCode = true;
                    }
                    // this.updateManagerContactsFlag();
                }, function (error) {
                    console.log(error);
                    alert('An Error has Occured');
                    _this.loading = false;
                    _this.errorOccured.emit(true);
                }));
            }
        }));
        this.subscriptions.push(this.router.events.subscribe(function (evnt) {
            if (evnt instanceof NavigationEnd) {
                _this.router.navigated = false;
                _this.ngOnInit();
                _this.propertyService.propertyDetails$.next(null);
                _this.propertyService.propertyId$.next(-1);
                _this.propertyService.propertySaved.next(false);
                _this.contactsService.propertyContacts.next(null);
                _this.contactsService.propertyName.next(null);
                _this.contactsService.propertyID.next(null);
                _this.contactsService.propertyAddress.next(null);
                _this.propertyService.systemDetails$.next(null);
                _this.journalService.notes.next(null);
                _this.journalService.journal.next(null);
                _this.occupancySubTypeDisplay = false;
                _this.constructionTypeOther = false;
                _this.occupancyTypeOther = false;
            }
        }));
    };
    PropertyDetailsComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.subscriptions.push(_this.propertyService.propertyDetails$.subscribe(function (res) {
                if (res && res.jurisdiction && res.jurisdiction.status === -1) {
                    _this.zipcodeMappingDetails.selectedJurisdictionId = 0;
                }
                else if (res !== null) {
                    _this.zipcodeMappingDetails.selectedJurisdictionId = res.jurisdiction.id;
                }
            }, function (err) {
                // TODO: Handle Error
            }));
        }, 2000);
    };
    PropertyDetailsComponent.prototype.editProperty = function () {
        var dialogRef = this.dialog.open(EditPropertyDialogComponent, {
            height: '90vh',
            width: '60vw',
            autoFocus: false,
            data: { id: this.editId }
        });
    };
    PropertyDetailsComponent.prototype.populateAddress = function (val) {
        var _this = this;
        this.readOnlyCity = false;
        this.readOnlyState = false;
        var zipcode = this.addPropertyForm.value.address.zipcode;
        this.isCanadianZipCode = false;
        if (zipcode.length === 6) {
            zipcode = zipcode.toUpperCase();
            zipcode = zipcode.substr(0, 3) + ' ' + zipcode.substr(3, 3);
            this.addPropertyForm.value.address.zipcode = zipcode;
        }
        if (this.canadinaZipRegex.test(zipcode)) {
            this.isCanadianZipCode = true;
        }
        if (zipcode && zipcode.length > 0) {
            this.subscriptions.push(this.propertyService
                .getJurisdictionDetailsFromZipcode(zipcode)
                .subscribe(function (data) {
                if (data) {
                    _this.zipcodeMappingDetails = data;
                    _this.zipcodeMappingDetails.jurisdictionDto = _this.zipcodeMappingDetails.jurisdictionDto.filter(function (res) { return res.id != -1; });
                    if (data && data.city && data.state && _this.zipcodeMappingDetails) {
                        _this.readOnlyCity = true;
                        _this.readOnlyState = true;
                        _this.addPropertyForm.get('address').patchValue({
                            jurisdictionName: _this.zipcodeMappingDetails.selectedJurisdictionId,
                            state: _this.zipcodeMappingDetails.state,
                            city: _this.zipcodeMappingDetails.city
                        });
                        if (!_this.zipcodeMappingDetails.jurisdictionDto || _this.zipcodeMappingDetails.jurisdictionDto.length === 0) {
                            _this.zipcodeMappingDetails.jurisdictionDto = [];
                        }
                        // this.addPropertyForm.value.address.jurisdictionName = this.zipcodeMappingDetails.selectedJurisdictionId;
                    }
                    else {
                        _this.zipcodeMappingDetails = { jurisdictionDto: [] };
                        // this.getCitiesByState(this.selectedStateID);
                    }
                }
                else {
                    _this.zipcodeMappingDetails = { jurisdictionDto: [] };
                }
            }));
        }
        else {
            this.readOnlyCity = true;
            this.readOnlyState = true;
            this.zipcodeMappingDetails.selectedJurisdictionId = -1;
            this.addPropertyForm.get('address').patchValue({
                jurisdictionName: this.zipcodeMappingDetails.selectedJurisdictionId,
                state: "",
                city: ""
            });
            if (!this.zipcodeMappingDetails.jurisdictionDto || this.zipcodeMappingDetails.jurisdictionDto.length === 0) {
                this.zipcodeMappingDetails.jurisdictionDto = [];
            }
        }
        // this.subscriptions.push(
        //   this.propertyService
        //     .getJurisdictionDetailsFromZipcode(zipcode)
        //     .subscribe(data => {
        //       if (data && data.state) {
        //         this.readOnlyState = true;
        //         this.zipcodeMappingDetails = data;
        //         this.addPropertyForm.value.address.state =
        //           this.zipcodeMappingDetails && this.zipcodeMappingDetails.state;
        //         if (!this.zipcodeMappingDetails.jurisdictionDto || this.zipcodeMappingDetails.jurisdictionDto.length === 0) {
        //             this.zipcodeMappingDetails.jurisdictionDto = [];
        //           }
        //       }
        //     })
        // );
        // this.subscriptions.push(this.propertyService
        //     .getJurisdictionDetailsFromZipcode(zipcode)
        //     .subscribe(data => {
        //       if (data && data.selectedJurisdictionId) {
        //         this.zipcodeMappingDetails = data;
        //         this.addPropertyForm.value.address.jurisdictionName = this.zipcodeMappingDetails.selectedJurisdictionId;
        //       }
        //     }));
    };
    PropertyDetailsComponent.prototype.goBackToPreviousPage = function () {
        this.location.back();
    };
    PropertyDetailsComponent.prototype.redirectToHomePage = function () {
        this.propertyService.propertyDetails$.next(null);
        this.contactsService.propertyContacts.next(null);
        this.contactsService.propertyName.next(null);
        this.contactsService.propertyID.next(null);
        this.contactsService.propertyAddress.next(null);
        this.propertyService.systemDetails$.next(null);
        this.journalService.notes.next(null);
        this.journalService.journal.next(null);
        if (this.userRole === 'ROLE_OWNER') {
            this.router.navigate(['user/owner/dashboard']);
        }
        if (this.userRole === 'ROLE_AUTHORITY' || this.userRole === 'ROLE_RFA'
            || this.userRole === 'ROLE_AUTHORITY_WORX') {
            this.router.navigate(['user/authority/dashboardAuthority']);
        }
        if (this.userRole === 'ROLE_SITE_ADMIN') {
            this.router.navigate(['user/admin/dashboardSiteAdmin']);
        }
        if (this.userRole === 'ROLE_INSPECTOR') {
            this.router.navigate(['user/inspector/dashboardInspector']);
        }
    };
    PropertyDetailsComponent.prototype.populateManagerAddress = function (val) {
        var _this = this;
        var zipcode = this.addPropertyForm.value.manager.managerAddress.zipcode;
        this.subscriptions.push(this.propertyService
            .getJurisdictionDetailsFromZipcode(zipcode)
            .subscribe(function (data) {
            _this.addPropertyForm.value.manager.managerAddress.city = _this.zipcodeMappingDetails.city;
            _this.addPropertyForm.value.manager.managerAddress.state = _this.zipcodeMappingDetails.state;
        }));
    };
    PropertyDetailsComponent.prototype.onSubmit = function (val) {
        var _this = this;
        this.creationDisabled = true;
        var formValidity = true;
        this.resetErrorsMap();
        // other occupancy Type validation
        if (this.occupancyTypeOther === true) {
            if (!this.addPropertyForm.value.otherOccupancyType) {
                var othetOccTypeError = [];
                othetOccTypeError.push('Enter Occupancy type of property.');
                this.errors.set('otherOccType', othetOccTypeError);
                formValidity = false;
            }
        }
        // other construction Type validation
        if (this.constructionTypeOther === true) {
            if (!this.addPropertyForm.value.otherContructionType) {
                var othetConstructTypeError = [];
                othetConstructTypeError.push('Enter Construction Type.');
                this.errors.set('otherConstructType', othetConstructTypeError);
                formValidity = false;
            }
        }
        // number of stories
        this.storiesNumberValidation();
        if (this.errors.get('storiesNumber').length > 0) {
            formValidity = false;
        }
        // year build validation check
        this.yearBuildValidation();
        if (this.errors.get('yearBuilt').length > 0) {
            formValidity = false;
        }
        // floor area validation check
        this.floorAreaValidation();
        if (this.errors.get('floorArea').length > 0) {
            formValidity = false;
        }
        // validate street line 1
        this.validateStreetLine1();
        if (this.errors.get('streetLine1').length > 0) {
            formValidity = false;
        }
        // validate zip code
        this.validateZipCode();
        if (this.errors.get('zipcode').length > 0) {
            formValidity = false;
        }
        // validate Additional Zip-code
        this.validateAdditionalZipCode();
        if (this.errors.get('additionalZipcode').length > 0) {
            formValidity = false;
        }
        // validate city
        var city = this.addPropertyForm.value.address.city;
        var cityError = [];
        if (!city || city.trim().length === 0) {
            cityError.push('City is mandatory.');
            this.errors.set('city', cityError);
            formValidity = false;
        }
        else if (!/^([a-z A-Z]+)$/.test(city)) {
            cityError.push('City name can only have alphabets.');
            this.errors.set('city', cityError);
            formValidity = false;
        }
        // validate state
        var state = this.addPropertyForm.value.address.state;
        var stateError = [];
        if (!state || state.trim().length === 0) {
            stateError.push('State is mandatory.');
            this.errors.set('state', stateError);
            formValidity = false;
        }
        else if (!/^([a-z A-Z]+)$/.test(state)) {
            stateError.push('State name can only have alphabets.');
            this.errors.set('state', stateError);
            formValidity = false;
        }
        // validate phone Number
        var phoneNo = this.addPropertyForm.value.manager.phoneNumber;
        if (phoneNo && phoneNo !== " " && phoneNo.length > 0 && !this.phoneRegex.test(phoneNo)) {
            formValidity = false;
            var phoneNumberError = [];
            phoneNumberError.push('Enter a valid 10 digit phone number.');
            this.errors.set('phoneNo', phoneNumberError);
        }
        // email validation
        this.validateEmail();
        if (this.errors.get('email').length > 0) {
            formValidity = false;
        }
        this.validationForManagerName();
        if (this.errors.get('managerName').length > 0) {
            formValidity = false;
        }
        // manager validation
        this.validateManager();
        if (this.errors.get('manager').length > 0 ||
            this.errors.get('managerAddress').length > 0) {
            formValidity = false;
        }
        if (formValidity) {
            // USPS  Address Validation
            if (+this.propertyService.propertyId$.getValue() > 0 ||
                this.propertyService.propertySaved.getValue()) {
                var currentProperty = this.propertyService.propertyDetails$.getValue();
                this.changeInAddress = false;
                this.changeInManagerAddress = false;
                if (!currentProperty.streetLine1) {
                    currentProperty.streetLine1 = '';
                }
                if (!currentProperty.streetLine2) {
                    currentProperty.streetLine2 = '';
                }
                if (this.addPropertyForm.value.address.city !==
                    currentProperty.zipcode.city ||
                    this.addPropertyForm.value.address.state !==
                        currentProperty.zipcode.state ||
                    this.addPropertyForm.value.address.zipcode !==
                        currentProperty.zipcode.zipcode ||
                    this.addPropertyForm.value.address.streetLine1 !==
                        currentProperty.streetLine1 ||
                    this.addPropertyForm.value.address.streetLine2 !==
                        currentProperty.streetLine2) {
                    this.changeInAddress = true;
                }
                if (currentProperty.propertyContacts.length > 0) {
                    if (currentProperty.propertyContacts.length > 0 &&
                        this.addPropertyForm.value.manager.managerAddress.streetLine1 !==
                            currentProperty.propertyContacts[0].streetLine1 ||
                        this.addPropertyForm.value.manager.managerAddress.streetLine2 !==
                            currentProperty.propertyContacts[0].streetLine2 ||
                        currentProperty.propertyContacts[0].zipcode !== null &&
                            (this.addPropertyForm.value.manager.managerAddress.city !==
                                currentProperty.propertyContacts[0].zipcode.city ||
                                this.addPropertyForm.value.manager.managerAddress.state !==
                                    currentProperty.propertyContacts[0].zipcode.state ||
                                this.addPropertyForm.value.manager.managerAddress.zipcode !==
                                    currentProperty.propertyContacts[0].zipcode.zipcode)) {
                        this.changeInManagerAddress = true;
                    }
                }
                else {
                    if (this.addPropertyForm.value.manager.managerAddress
                        && this.addPropertyForm.value.manager.managerAddress.streetLine1 !== null
                        && this.addPropertyForm.value.manager.managerAddress.city !== null
                        && this.addPropertyForm.value.manager.managerAddress.state !== null
                        && this.addPropertyForm.value.manager.managerAddress.zipcode !== null &&
                        this.addPropertyForm.value.manager.managerAddress.streetLine1.length > 0 &&
                        this.addPropertyForm.value.manager.managerAddress.city.length > 0 &&
                        this.addPropertyForm.value.manager.managerAddress.state.length > 0 &&
                        this.addPropertyForm.value.manager.managerAddress.zipcode.length > 0) {
                        this.changeInManagerAddress = true;
                    }
                }
                this.subscriptions.push(this.siteAdminService.getAdminSettings().subscribe(function (res) {
                    _this.uspsOn = res.uspsVerification;
                    if (_this.uspsOn && !_this.isCanadianZipCode) {
                        if (_this.changeInAddress) {
                            _this.validateAddressFromUsps(_this.changeInAddress);
                        }
                        else if (!_this.changeInAddress && _this.changeInManagerAddress) {
                            _this.validateManagerAddressFromUsps();
                        }
                        else if (!_this.changeInAddress && !_this.changeInManagerAddress) {
                            _this.prePostForm();
                        }
                    }
                    else {
                        // no pre required
                        _this.postForm();
                    }
                }, function (error) {
                    console.log(error);
                    _this.uspsOn = false;
                    // no pre required
                    _this.postForm();
                }));
            }
            else {
                this.subscriptions.push(this.siteAdminService.getAdminSettings().subscribe(function (res) {
                    _this.uspsOn = res.uspsVerification;
                    if (_this.uspsOn && !_this.isCanadianZipCode) {
                        _this.changeInAddress = true;
                        _this.validateAddressFromUsps();
                        if (_this.addPropertyForm.value.manager.managerAddress &&
                            _this.addPropertyForm.value.manager.managerAddress.streetLine1 !== null &&
                            _this.addPropertyForm.value.manager.managerAddress.streetLine1.length > 0 &&
                            _this.addPropertyForm.value.manager.managerAddress.city !== null &&
                            _this.addPropertyForm.value.manager.managerAddress.city.length > 0 &&
                            _this.addPropertyForm.value.manager.managerAddress.state !== null &&
                            _this.addPropertyForm.value.manager.managerAddress.state.length > 0 &&
                            _this.addPropertyForm.value.manager.managerAddress.zipcode !== null &&
                            _this.addPropertyForm.value.manager.managerAddress.zipcode.length > 0) {
                            _this.changeInManagerAddress = true;
                        }
                        else {
                            _this.changeInManagerAddress = false;
                        }
                    }
                    else {
                        _this.nextDisabled = true;
                        // no pre required
                        if (_this.userRole === 'ROLE_OWNER' || _this.userRole === 'ROLE_INSPECTOR') {
                            _this.triggerContinueAlert();
                        }
                        else {
                            _this.postForm();
                        }
                        _this.nextDisabled = false;
                    }
                }, function (error) {
                    console.log(error);
                    _this.uspsOn = false;
                    // no pre required
                    _this.postForm();
                }));
            }
        }
        else {
            this.creationDisabled = false;
            if (this.errors.get('otherOccType').length > 0) {
                document.getElementById('otherOccupancyType').focus();
            }
            else if (this.errors.get('otherConstructType').length > 0) {
                document.getElementById('otherConstructType').focus();
            }
            else if (this.errors.get('storiesNumber').length > 0) {
                document.getElementById('noOfStories').focus();
            }
            else if (this.errors.get('yearBuilt').length > 0) {
                document.getElementById('yearBuilt').focus();
            }
            else if (this.errors.get('floorArea').length > 0) {
                document.getElementById('floorArea').focus();
            }
            else if (this.errors.get('streetLine1').length > 0) {
                document.getElementById('addressStLine1').focus();
            }
            else if (this.errors.get('zipcode').length > 0) {
                document.getElementById('addressZipCode').focus();
            }
            else if (this.errors.get('additionalZipcode').length > 0) {
                document.getElementById('addressAdditionalZipCode').focus();
            }
            else if (this.errors.get('city').length > 0) {
                document.getElementById('addressCity').focus();
            }
            else if (this.errors.get('state').length > 0) {
                document.getElementById('addressState').focus();
            }
            else if (this.errors.get('phoneNo').length > 0 ||
                this.errors.get('manager').length > 0) {
                document.getElementById('phonep1').focus();
            }
            else if (this.errors.get('email').length > 0) {
                document.getElementById('emailAddress').focus();
            }
            else if (this.errors.get('managerName').length > 0) {
                if (this.errors.get('managerName')[0].includes('first name')) {
                    document.getElementById('managerFirstName').focus();
                }
                else {
                    document.getElementById('managerLastName').focus();
                }
            }
        }
    };
    PropertyDetailsComponent.prototype.triggerContinueAlert = function () {
        if (this.userRole === 'ROLE_INSPECTOR' && this.isEditDetail === 'Edit') {
            this.postForm();
            // }else if(!this.propertyService.propertyId$.getValue()){// create property flow
            //   this.postForm();
            //
        }
        else if (confirm("The information you provided will be sent to the fire marshal's office for approval. The property will be in your File Cabinet and marked red as inactive until it is approved.\nDo you want to continue?")) {
            this.postForm();
        }
        else {
            this.creationDisabled = false;
        }
    };
    PropertyDetailsComponent.prototype.checkOtherType = function (occType) {
        if (occType.length === 0) {
            this.occupancySubTypeDisplay = false;
            this.occupancyTypeOther = false;
        }
        else {
            if (occType === 'Other') {
                this.occupancyTypeOther = true;
                this.occupancySubTypeDisplay = false;
            }
            else {
                this.occupancyTypeOther = false;
                var obj = this.occupancyTypeOptions.find(function (innerObj) { return innerObj.name === occType; });
                if (obj != null && obj.propertySubtypes.length > 0) {
                    this.occupancySubTypeDisplay = true;
                }
                else {
                    this.occupancySubTypeDisplay = false;
                }
                this.occupancySubTypeOptions = obj.propertySubtypes;
                if (this.occupancySubTypeOptions &&
                    this.occupancySubTypeOptions.length > 0) {
                    this.addPropertyForm.value.occupancySubType = this.occupancySubTypeOptions[0].subtypeName;
                }
            }
        }
    };
    PropertyDetailsComponent.prototype.checkOtherConstructionType = function (constructType) {
        if (constructType === 'Other') {
            this.constructionTypeOther = true;
        }
        else {
            this.constructionTypeOther = false;
        }
    };
    PropertyDetailsComponent.prototype.copyAddress = function (checked) {
        if (checked) {
            this.managerAddressSameAsProp = true;
            // this.addPropertyForm.value.manager.managerAddress. = ;
            // this.addPropertyForm.value.manager.managerAddress. = ;
            // this.addPropertyForm.value.manager.managerAddress.zipcode = ;
            this.addPropertyForm.get('manager').get('managerAddress').patchValue({
                streetLine1: this.addPropertyForm.value.address.streetLine1,
                streetLine2: this.addPropertyForm.value.address.streetLine2,
                zipcode: this.addPropertyForm.value.address.zipcode,
                state: this.addPropertyForm.value.address.state,
                city: this.addPropertyForm.value.address.city
            });
            // this.addPropertyForm.value.manager.managerAddress.city = this.addPropertyForm.value.address.city;
            // this.addPropertyForm.value.manager.managerAddress.state = ;
            if (this.readOnlyCity) {
                this.readOnlyManagerCity = true;
                this.readOnlyManagerState = true;
            }
            else {
                this.readOnlyManagerCity = false;
                this.readOnlyManagerState = false;
                // this.selectedManagerStateID = this.states.find(item => {
                //   return item.id.trim() === this.addPropertyForm.value.manager.managerAddress.state;
                // }).id;
                // this.subscriptions.push(
                // this.propertyService.getCitiesByState(this.selectedManagerStateID).subscribe(
                //     res => {
                //       this.managerCities = res;
                //       this.selectedManagerCityID = this.managerCities.find(item => {
                //         return item.data.trim() === this.addPropertyForm.value.manager.managerAddress.city;
                //       }).data;
                //       this.setManagerCity(this.selectedManagerCityID);
                //     }
                //   )
                // );
            }
        }
        else {
            this.managerAddressSameAsProp = false;
        }
    };
    PropertyDetailsComponent.prototype.copyAddressEdit = function (checked) {
        if (checked) {
            if (this.addPropertyForm.value.manager && this.addPropertyForm.value.manager.managerAddress
                && this.addPropertyForm.value.manager.managerAddress.streetLine1
                && this.addPropertyForm.value.manager.managerAddress.zipcode &&
                this.addPropertyForm.value.manager.managerAddress.state &&
                this.addPropertyForm.value.manager.managerAddress.city) {
                this.addPropertyForm.get('address').patchValue({
                    streetLine1: this.addPropertyForm.value.manager.managerAddress.streetLine1,
                    streetLine2: this.addPropertyForm.value.manager.managerAddress.streetLine2,
                    zipcode: this.addPropertyForm.value.manager.managerAddress.zipcode,
                    state: this.addPropertyForm.value.manager.managerAddress.state,
                    city: this.addPropertyForm.value.manager.managerAddress.city
                });
                if (this.readOnlyManagerCity) {
                    this.readOnlyCity = true;
                    this.readOnlyState = true;
                }
                else {
                    this.readOnlyCity = false;
                    this.readOnlyState = false;
                }
            }
        }
    };
    PropertyDetailsComponent.prototype.buttonClicked = function (buttonText) {
        this.currentButton = buttonText;
    };
    PropertyDetailsComponent.prototype.moveToNextPhoneTab = function (value, nextTabID) {
        if (value.length >= 3) {
            document.getElementById(nextTabID).focus();
        }
    };
    PropertyDetailsComponent.prototype.storiesNumberValidation = function () {
        var floors = this.addPropertyForm.value.noOfStories;
        if (floors) {
            var storiesNumberErrors = [];
            if (!/^([0-9]{0,3})$/.test(floors) ||
                parseInt(floors, 10) < 1 ||
                parseInt(floors, 10) > 999) {
                storiesNumberErrors.push('Enter a numeric value for Number of Stories greater than or equal to 1 and less than or equal to 999.');
            }
            else {
                this.addPropertyForm.value.noOfStories = parseInt(floors, 10);
            }
            this.errors.set('storiesNumber', storiesNumberErrors);
        }
    };
    PropertyDetailsComponent.prototype.validateStreetLine1 = function () {
        var stL1 = this.addPropertyForm.value.address.streetLine1;
        if (!stL1) {
            var streetLine1Error = [];
            streetLine1Error.push('Street Line 1 is mandatory');
            this.errors.set('streetLine1', streetLine1Error);
        }
    };
    PropertyDetailsComponent.prototype.yearBuildValidation = function () {
        var builtYear = this.addPropertyForm.value.yearBuilt;
        if (builtYear) {
            var builtYearErrors = [];
            if (!/^([0-9]{4})$/.test(builtYear) ||
                (parseInt(builtYear, 10) > new Date().getFullYear() ||
                    parseInt(builtYear, 10) < 1600)) {
                builtYearErrors.push('Enter numeric value for Year Built of property greater than or equal to 1600 and less than or equal to current year.');
            }
            this.errors.set('yearBuilt', builtYearErrors);
        }
    };
    PropertyDetailsComponent.prototype.floorAreaValidation = function () {
        var floorArea = this.addPropertyForm.value.floorArea;
        var floorAreaErrors = [];
        if (floorArea && !/^([0-9]+)$/.test(floorArea)) {
            floorAreaErrors.push('Enter numeric value for Floor Area of property.');
        }
        this.errors.set('floorArea', floorAreaErrors);
    };
    PropertyDetailsComponent.prototype.validateEmail = function () {
        var emailAddressErrors = [];
        if (this.addPropertyForm.value.manager.emailAddress &&
            this.addPropertyForm.value.manager.emailAddress !== " " &&
            !/[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*/.test(this.addPropertyForm.value.manager.emailAddress)
        // this.addPropertyForm.controls.manager.controls.emailAddress.isActive === 'INVALID'
        ) {
            emailAddressErrors.push('Enter a valid email address like abc@gmail.com.');
        }
        this.errors.set('email', emailAddressErrors);
    };
    PropertyDetailsComponent.prototype.validateManager = function () {
        var managerErrors = [];
        var managerAddressErrors = [];
        var stl1 = this.addPropertyForm.value.manager.managerAddress
            .streetLine1;
        var stl2 = this.addPropertyForm.value.manager.managerAddress
            .streetLine2;
        var city = this.addPropertyForm.value.manager.managerAddress.city;
        var state = this.addPropertyForm.value.manager.managerAddress
            .state;
        var zip = this.addPropertyForm.value.manager.managerAddress
            .zipcode;
        var managerNamePresent = (this.addPropertyForm.value.manager.firstName !== null && this.addPropertyForm.value.manager.firstName.lenght > 0) ||
            (this.addPropertyForm.value.manager.lastName !== null && this.addPropertyForm.value.manager.lastName.length > 0);
        var nameNotPropertyManager = this.addPropertyForm.value.manager.firstName !== 'PROPERTY' &&
            this.addPropertyForm.value.manager.lastName !== 'MANAGER';
        var addressEntered = ((stl1 !== null && stl1.length > 0) || (stl2 !== null && stl2.length > 0) ||
            (city !== null && city.length > 0) || (state !== null && state.length > 0) || (zip !== null && zip.length > 0));
        var contactNotEntered = !this.addPropertyForm.value.manager.phoneNumber &&
            !this.addPropertyForm.value.manager.emailAddress;
        if (addressEntered && (zip.length === 0 || stl1.length === 0)) {
            managerAddressErrors.push('Enter Manager details before proceeding further.');
        }
        // // TEGFE-3326 contact and adress are independent in case of manager.
        if (addressEntered && contactNotEntered && !nameNotPropertyManager) {
            this.formSubmissionAllowedIfContactNotPresent = true;
            // managerErrors.push('Enter Manager Phone Number and/or Email Address before proceeding further.');
        }
        else {
            this.formSubmissionAllowedIfContactNotPresent = false;
        }
        if ((managerNamePresent && (nameNotPropertyManager))
            && contactNotEntered) {
            managerErrors.push('Enter Manager Phone Number and/or Email Address before proceeding further.');
        }
        this.errors.set('manager', managerErrors);
        this.errors.set('managerAddress', managerAddressErrors);
    };
    PropertyDetailsComponent.prototype.resetErrorsMap = function () {
        this.errors = new Map([
            ['otherOccType', []],
            ['otherConstructType', []],
            ['storiesNumber', []],
            ['yearBuilt', []],
            ['floorArea', []],
            ['streetLine1', []],
            ['zipcode', []],
            ['additionalZipcode', []],
            ['city', []],
            ['state', []],
            ['phoneNo', []],
            ['email', []],
            ['manager', []],
            ['managerName', []]
        ]);
    };
    PropertyDetailsComponent.prototype.validateAddressFromUsps = function (putForm) {
        var _this = this;
        this.nextDisabled = true;
        if (this.addPropertyForm.value.address.streetLine1 != null ||
            this.addPropertyForm.value.address.additionalZipcode != null) {
            this.subscriptions.push(this.propertyService
                .validateAddressFromUsps(this.addPropertyForm.value.address)
                .subscribe(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.nextDisabled = false;
                            if (!res.includes('<Error>')) return [3 /*break*/, 2];
                            this.propertyService.isPropAddressValidated$.next(false);
                            return [4 /*yield*/, this.openInvalidAddressPopUp()];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, this.openValidAddressPopup(res)];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4: return [2 /*return*/];
                    }
                });
            }); }, function (error) {
                // no pre required
                _this.postForm();
            }));
        }
    };
    PropertyDetailsComponent.prototype.validateAdditionalZipCode = function () {
        var addZipCode = this.addPropertyForm.value.address.additionalZipcode;
        if (addZipCode && !/^([0-9]{4})$/.test(addZipCode)) {
            var addZipCodeErrors = [];
            addZipCodeErrors.push('Please enter valid numeric sub zip code of property address.');
            this.errors.set('additionalZipcode', addZipCodeErrors);
        }
    };
    PropertyDetailsComponent.prototype.validateZipCode = function () {
        var zipCode = this.addPropertyForm.value.address.zipcode;
        var zipCodeError = [];
        this.isCanadianZipCode = false;
        if (this.canadinaZipRegex.test(zipCode)) {
            this.isCanadianZipCode = true;
        }
        else if (!zipCode || !/^([0-9]{5})$/.test(zipCode)) {
            zipCodeError.push('Please enter valid numeric zip code of property address.');
        }
        this.errors.set('zipcode', zipCodeError);
    };
    PropertyDetailsComponent.prototype.validationForManagerName = function () {
        if (this.addPropertyForm.value.manager.firstName) {
            this.addPropertyForm.value.manager.firstName = this.addPropertyForm.value.manager.firstName.trim();
        }
        if (this.addPropertyForm.value.manager.lastName) {
            this.addPropertyForm.value.manager.lastName = this.addPropertyForm.value.manager.lastName.trim();
        }
        var firstName = this.addPropertyForm.value.manager.firstName;
        var lastName = this.addPropertyForm.value.manager.lastName;
        var stl1 = this.addPropertyForm.value.manager.managerAddress
            .streetLine1;
        var stl2 = this.addPropertyForm.value.manager.managerAddress
            .streetLine2;
        var city = this.addPropertyForm.value.manager.managerAddress.city;
        var state = this.addPropertyForm.value.manager.managerAddress
            .state;
        var zip = this.addPropertyForm.value.manager.managerAddress
            .zipcode;
        var managerNameErrors = [];
        if (firstName &&
            firstName.length > 0 &&
            (!lastName || lastName.trim().length === 0)) {
            this.addPropertyForm.value.manager.firstName = 'PROPERTY';
            firstName = 'PROPERTY';
            // managerNameErrors.push('Please enter manager last Name');
        }
        else if (lastName &&
            lastName.length > 0 &&
            (!firstName || firstName.length === 0)) {
            this.addPropertyForm.value.manager.lastName = 'MANAGER';
            lastName = 'MANAGER';
            // managerNameErrors.push('Please enter manager first Name');
        }
        else if ((!lastName || lastName.length === 0) &&
            (!firstName || firstName.length === 0) &&
            ((stl1 && stl1.length > 0) ||
                (stl2 && stl2.length > 0) ||
                (city && city.length > 0) ||
                (state && state.length > 0) ||
                (zip && zip.length > 0))) {
            this.addPropertyForm.get('manager').patchValue({
                firstName: 'PROPERTY',
                lastName: 'MANAGER'
            });
            // this.addPropertyForm.value.manager.firstName = 'PROPERTY';
            firstName = 'PROPERTY';
            // this.addPropertyForm.value.manager.lastName = 'MANAGER';
            lastName = 'MANAGER';
            // managerNameErrors.push('Please enter manager first Name');
            // managerNameErrors.push('Please enter manager last Name');
        }
        this.errors.set('managerName', managerNameErrors);
    };
    PropertyDetailsComponent.prototype.setJurisdiction = function (val) {
        var _this = this;
        this.addPropertyForm.value.address.jurisdictionName = val;
        if ((this.user.role.id == Roles.ROLE_AUTHORITY_ADMIN ||
            this.user.role.id == Roles.ROLE_AUTHORITY_USER || this.user.role.id === Roles.ROLE_AUTHORITY_WORX) &&
            this.zipcodeMappingDetails.selectedJurisdictionId != val) {
            var a = this.dialog.open(ConfirmationDialogComponent, {
                width: '50vw',
                height: '40vh',
            });
            this.subscriptions.push(a.afterClosed().subscribe(function (res) {
                if (res === 'no') {
                    _this.addPropertyForm.value.address.jurisdictionName = _this.zipcodeMappingDetails.selectedJurisdictionId;
                }
                else {
                    if (_this.user.jurisdiction.id === -1) {
                        _this.propertyDetails.isPropertyActive = false;
                    }
                }
            }));
        }
        else {
            if (this.user && this.user.jurisdiction && this.user.jurisdiction.id && this.user.jurisdiction.id === -1) {
                this.propertyDetails.isPropertyActive = false;
            }
        }
    };
    PropertyDetailsComponent.prototype.compare = function (a, b) {
        return JSON.stringify(a) === JSON.stringify(b);
    };
    PropertyDetailsComponent.prototype.openManagerValidAddressPopup = function (res) {
        var _this = this;
        var parser = new DOMParser();
        var xmlDoc = parser.parseFromString(res, 'text/xml');
        var stLine2 = null;
        if (xmlDoc.getElementsByTagName('Address1')[0] &&
            xmlDoc.getElementsByTagName('Address1')[0].childNodes[0]) {
            stLine2 = xmlDoc.getElementsByTagName('Address1')[0].childNodes[0]
                .nodeValue;
            if (stLine2 && stLine2.toLowerCase() === 'null') {
                stLine2 = null;
            }
        }
        var subZip = null;
        if (xmlDoc.getElementsByTagName('Zip4')[0] &&
            xmlDoc.getElementsByTagName('Zip4')[0].childNodes[0]) {
            subZip = xmlDoc.getElementsByTagName('Zip4')[0].childNodes[0].nodeValue;
            if (subZip && subZip.toLowerCase() === 'null') {
                subZip = null;
            }
        }
        this.addPropertyForm.value.manager.managerAddress.streetLine1 = xmlDoc.getElementsByTagName('Address2')[0].childNodes[0].nodeValue;
        this.addPropertyForm.value.manager.managerAddress.streetLine2 = stLine2;
        (this.addPropertyForm.value.manager.managerAddress.city = xmlDoc.getElementsByTagName('City')[0].childNodes[0].nodeValue),
            (this.addPropertyForm.value.manager.managerAddress.state = xmlDoc.getElementsByTagName('State')[0].childNodes[0].nodeValue),
            (this.addPropertyForm.value.manager.managerAddress.zipcode = xmlDoc.getElementsByTagName('Zip5')[0].childNodes[0].nodeValue),
            (this.addPropertyForm.value.manager.managerAddress.additionalZipcode = subZip);
        var dialogRef = this.dialog.open(USPSValidPopUpComponent, {
            width: '735px',
            height: '350px',
            data: {
                streetLine1: this.addPropertyForm.value.manager.managerAddress.streetLine1,
                streetLine2: stLine2,
                city: this.addPropertyForm.value.manager.managerAddress.city,
                state: this.addPropertyForm.value.manager.managerAddress.state,
                zipcode: this.addPropertyForm.value.manager.managerAddress.zipcode,
                subzipcode: subZip,
                manager: true
            },
            disableClose: true,
            autoFocus: false
        });
        this.subscriptions.push(dialogRef.afterClosed().subscribe(function (result) {
            _this.managerAddressUspsDone = true;
            if (result === 'continue') {
                _this.addPropertyForm.value.manager.managerAddress.streetLine1 = xmlDoc.getElementsByTagName('Address2')[0].childNodes[0].nodeValue;
                _this.addPropertyForm.value.manager.managerAddress.streetLine2 = stLine2;
                _this.addPropertyForm.value.manager.managerAddress.city = xmlDoc.getElementsByTagName('City')[0].childNodes[0].nodeValue;
                _this.addPropertyForm.value.manager.managerAddress.state = xmlDoc.getElementsByTagName('State')[0].childNodes[0].nodeValue;
                _this.addPropertyForm.value.manager.managerAddress.zipcode = xmlDoc.getElementsByTagName('Zip5')[0].childNodes[0].nodeValue;
                _this.addPropertyForm.value.manager.managerAddress.additionalZipcode = subZip;
                _this.prePostForm();
            }
            else if (result === 'cancelled') {
                _this.currentButton = null;
                _this.creationDisabled = false;
            }
        }));
        //  rest api call to post -> this.service.postPropertyDetails(this.propertyDetails);
    };
    PropertyDetailsComponent.prototype.validateManagerAddressFromUsps = function () {
        var _this = this;
        this.nextDisabled = true;
        if (this.addPropertyForm.value.manager.managerAddress.streetLine1 != null ||
            this.addPropertyForm.value.manager.managerAddress.additionalZipcode != null) {
            this.subscriptions.push(this.propertyService
                .validateAddressFromUsps(this.addPropertyForm.value.manager.managerAddress)
                .subscribe(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.nextDisabled = false;
                            if (!res.includes('<Error>')) return [3 /*break*/, 2];
                            this.propertyService.isPropAddressValidated$.next(false);
                            return [4 /*yield*/, this.openInvalidAddressPopUp(true)];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, this.openManagerValidAddressPopup(res)];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4: return [2 /*return*/];
                    }
                });
            }); }, function (error) {
                _this.postForm();
            }));
        }
    };
    PropertyDetailsComponent.prototype.prePostForm = function () {
        var shouldPost = false;
        var noChange = false;
        if ((this.changeInAddress && this.addressUspsDone) &&
            (this.changeInManagerAddress && this.managerAddressUspsDone)) {
            shouldPost = true;
        }
        if (!this.changeInAddress && !this.changeInManagerAddress) {
            shouldPost = true;
        }
        if (!this.changeInAddress && this.changeInManagerAddress && this.managerAddressUspsDone) {
            shouldPost = true;
        }
        if (!this.changeInManagerAddress && this.changeInAddress && this.addressUspsDone) {
            shouldPost = true;
        }
        // if(!this.changeInAddress && !this.changeInManagerAddress && !this.addressUspsDone &&
        //    !this.managerAddressUspsDone) {
        //   noChange = true;
        // }
        if (shouldPost) {
            if (this.userRole === "ROLE_OWNER" ||
                this.userRole === "ROLE_INSPECTOR") {
                this.triggerContinueAlert();
            }
            else {
                this.postForm();
            }
        }
    };
    PropertyDetailsComponent.prototype.postForm = function () {
        var _this = this;
        this.propertyDetails.buildingName = this.addPropertyForm.value.buildingName;
        this.propertyDetails.isActive = this.addPropertyForm.value.isActive;
        if (this.isPPAButActive) {
            this.propertyDetails.isActive = this.isPPAButActive;
        }
        if (this.addPropertyForm.value.occupancyType !== 'Other') {
            this.propertyDetails.propertyType = this.addPropertyForm.value.occupancyType;
            this.propertyDetails.propertySubType = this.addPropertyForm.value.occupancySubType;
        }
        else {
            this.propertyDetails.propertyType = this.addPropertyForm.value.otherOccupancyType;
        }
        if (this.addPropertyForm.value.constructionType !== 'Other') {
            this.propertyDetails.buildingType = this.addPropertyForm.value.constructionType;
        }
        else {
            this.propertyDetails.buildingType = this.addPropertyForm.value.otherContructionType;
        }
        var selectedJurisdiction = this.zipcodeMappingDetails.jurisdictionDto.filter(function (d) { return d.id === +_this.addPropertyForm.value.address.jurisdictionName; })[0];
        if (!selectedJurisdiction && (this.propertyService.propertyDetails$.getValue() !== null &&
            parseInt(this.addPropertyForm.value.address.jurisdictionName) !== -1)) {
            this.propertyDetails.jurisdiction = this.propertyService.propertyDetails$.getValue().jurisdiction;
        }
        else {
            this.propertyDetails.jurisdiction = {
                id: parseInt(this.addPropertyForm.value.address.jurisdictionName, 10),
                name: ((selectedJurisdiction || { name: '' }).name) || 'Other',
                status: 2
            };
        }
        this.propertyDetails.createdBy = 0;
        this.propertyDetails.numberOfStories = this.addPropertyForm.value.noOfStories;
        this.propertyDetails.yearBuilt = this.addPropertyForm.value.yearBuilt;
        this.propertyDetails.floorArea = this.addPropertyForm.value.floorArea;
        this.propertyDetails.streetLine1 = this.addPropertyForm.value.address.streetLine1;
        this.propertyDetails.streetLine2 = this.addPropertyForm.value.address.streetLine2;
        this.propertyDetails.zipcode = {
            city: this.addPropertyForm.value.address.city,
            id: null,
            isApproved: null,
            state: this.addPropertyForm.value.address.state,
            zipcode: this.addPropertyForm.value.address.zipcode
        };
        if (this.zipcodeMappingDetails) {
            this.propertyDetails.zipcode.id = this.zipcodeMappingDetails.id;
        }
        this.propertyDetails.propertyContacts = [];
        if (this.addPropertyForm.value.manager.phoneNumber ||
            this.addPropertyForm.value.manager.emailAddress ||
            this.formSubmissionAllowedIfContactNotPresent) {
            this.propertyDetails.propertyContacts = [
                {
                    displaySequence: 1,
                    email: this.addPropertyForm.value.manager.emailAddress,
                    firstName: this.addPropertyForm.value.manager.firstName,
                    lastName: this.addPropertyForm.value.manager.lastName,
                    isManager: true,
                    responsibility: 'Manager',
                    secondaryContacts: null,
                    streetLine1: this.addPropertyForm.value.manager.managerAddress
                        .streetLine1,
                    streetLine2: this.addPropertyForm.value.manager.managerAddress
                        .streetLine2,
                    phoneNum: null,
                    zipcode: {
                        city: this.addPropertyForm.value.manager.managerAddress.city,
                        state: this.addPropertyForm.value.manager.managerAddress.state,
                        zipcode: this.addPropertyForm.value.manager.managerAddress.zipcode
                    }
                }
            ];
            if (+this.propertyService.propertyId$.getValue() > 0 ||
                this.propertyService.propertySaved.getValue()) {
                this.propertyService.propertyDetails$
                    .getValue()
                    .propertyContacts.forEach(function (x) {
                    if (x.isManager) {
                        _this.propertyDetails.propertyContacts[0].id = x.id;
                    }
                });
            }
            if (this.addPropertyForm.value.manager.phoneNumber) {
                this.propertyDetails.propertyContacts[0].phoneNum = this.addPropertyForm.value.manager.phoneNumber.replace(this.phoneRegex, '$1-$2-$3');
            }
            if ((this.propertyDetails.propertyContacts[0].phoneNum !== null && this.propertyDetails.propertyContacts[0].phoneNum.length > 0)
                || (this.propertyDetails.propertyContacts[0].email !== null && this.propertyDetails.propertyContacts[0].email.length > 0)) {
                if (!this.propertyDetails.propertyContacts[0].firstName || this.propertyDetails.propertyContacts[0].firstName.length === 0) {
                    this.propertyDetails.propertyContacts[0].firstName = 'PROPERTY';
                    this.addPropertyForm.get('manager').patchValue({
                        firstName: 'PROPERTY',
                    });
                }
                if (!this.propertyDetails.propertyContacts[0].lastName || this.propertyDetails.propertyContacts[0].lastName.length === 0) {
                    this.propertyDetails.propertyContacts[0].lastName = 'MANAGER';
                    this.addPropertyForm.get('manager').patchValue({
                        lastName: 'MANAGER'
                    });
                }
            }
        }
        // if (this.otherContacts.length > 0) {
        //   for (const contact of this.otherContacts) {
        //     this.propertyDetails.propertyContacts.push(contact);
        //   }
        // }
        if (this.isEditDetail !== 'Edit') {
            if (+this.propertyService.propertyId$.getValue() > 0 ||
                this.propertyService.propertySaved.getValue()) {
                var propertyId = +this.propertyService.propertyId$.getValue() > 0
                    ? +this.propertyService.propertyId$.getValue()
                    : this.propertyService.propertyDetails$.getValue().id;
                if (this.userRole === 'ROLE_OWNER' || this.userRole === 'ROLE_INSPECTOR') {
                    var changeDetected = this.addPropertyForm.dirty;
                    if (changeDetected) {
                        this.propertyDetails.isPropertyActive = false;
                    }
                    else {
                        this.propertyDetails.isPropertyActive = true;
                    }
                }
                this.subscriptions.push(this.propertyService
                    .putPropertyDetails(this.propertyDetails, propertyId)
                    .subscribe(function (res) {
                    _this.creationDisabled = false;
                    if (_this.currentButton.trim() === 'Next') {
                        _this.propertyService.propertyDetails$.next(res);
                        _this.propertyService.oldProperyDetailsModel$.next(tslib_1.__assign({}, _this.propertyDetails));
                        _this.formData.emit(res);
                    }
                    else if (_this.currentButton === 'Finish') {
                        // 1777 - Fix
                        // this.redirectToHomePage();
                        _this.goBackToPreviousPage();
                    }
                }, function (error) {
                    console.log(error);
                    if (error &&
                        error.error &&
                        error.error.message &&
                        error.error.message.includes('Property Already exists')) {
                        // const dialogRef = this.dialog.open(DuplicatePropertyComponent, {
                        //   width: '735px',
                        //   height: '350px',
                        //   disableClose: true,
                        //   autoFocus: false
                        // });
                    }
                    else {
                        alert('Unexpected error occured');
                    }
                    _this.currentButton = null;
                }));
            }
            else {
                if (this.currentButton.trim() === 'Next' || this.currentButton.trim() === 'Finish') {
                    this.checkDuplicateProperty();
                }
            }
        }
        else {
            if (this.userRole === 'ROLE_OWNER' || this.userRole === 'ROLE_INSPECTOR') {
                var changeDetected = this.addPropertyForm.dirty;
                if (changeDetected) {
                    this.propertyDetails.isPropertyActive = false;
                }
                else {
                    this.propertyDetails.isPropertyActive = true;
                }
            }
            this.propertyDetails.owner = this.addPropertyForm.value.owner;
            this.subscriptions.push(this.propertyService
                .putPropertyEditDetails(this.propertyDetails, this.propertyIdForEdit)
                .subscribe(function (res) {
                if (res) {
                    _this.closeDialog();
                    alert('Data has been updated and Recommendation has been sent to the concerned Authority.');
                }
            }));
        }
        //  rest api call to post -> this.service.postPropertyDetails(this.propertyDetails);
    };
    PropertyDetailsComponent.prototype.checkDuplicateProperty = function () {
        var _this = this;
        this.subscriptions.push(this.propertyService
            .checkDuplicateProperty(this.propertyDetails)
            .subscribe(function (res) {
            _this.creationDisabled = false;
            if (!res) {
                if (_this.userRole === "ROLE_AUTHORITY" || _this.userRole === "ROLE_SITE_ADMIN" || _this.userRole === 'ROLE_AUTHORITY_WORX' || _this.userRole === 'ROLE_RFA') {
                    _this.subscriptions.push(_this.propertyService
                        .postPropertyDetails(_this.propertyDetails)
                        .subscribe(function (res) {
                        _this.creationDisabled = false;
                        if (res.streetLine1) {
                            console.log(res);
                            _this.propertyService.propertySaved.next(true);
                            if (_this.currentButton.trim() === 'Next') {
                                _this.propertyService.propertyDetails$.next(res);
                                _this.propertyService.oldProperyDetailsModel$.next(tslib_1.__assign({}, _this.propertyDetails));
                                _this.formData.emit(res);
                            }
                            else if (_this.currentButton.trim() === 'Finish') {
                                _this.goBackToPreviousPage();
                            }
                        }
                    }, function (error) {
                        if (error &&
                            error.error &&
                            error.error.message &&
                            error.error.message.includes('Property Already exists')) {
                            alert('This address is already registered with TEGRIS FireTM.');
                        }
                        else {
                            alert('Unexpected error occured');
                        }
                        // this.currentButton = null;
                    }));
                }
                else {
                    _this.propertyService.propertySaved.next(true);
                    _this.propertyService.propertyDetails$.next(_this.propertyDetails);
                    _this.propertyService.oldProperyDetailsModel$.next(tslib_1.__assign({}, _this.propertyDetails));
                    _this.creationDisabled = false;
                    _this.formData.emit(_this.propertyDetails);
                }
            }
            else {
                _this.duplicatePropertyID = res;
                var dialogRef = _this.dialog.open(DuplicatePropertyComponent, {
                    width: '735px',
                    height: '350px',
                    disableClose: true,
                    autoFocus: false
                });
                _this.subscriptions.push(dialogRef.afterClosed().subscribe(function (result) {
                    console.log('The dialog was closed');
                    if (result === 'continue') {
                        _this.propertyService.propertyId$.next(_this.duplicatePropertyID);
                        _this.subscriptions.push(_this.propertyService
                            .getPropertyById(_this.duplicatePropertyID)
                            .subscribe(function (response) {
                            _this.loading = false;
                            _this.propertyService.propertyDetails$.next(response);
                            if (response) {
                                var occupancyType = void 0;
                                var occupancySubType = void 0;
                                var buildingType = void 0;
                                if (response.propertyType && _this.occupancyTypeOptions) {
                                    occupancyType = _this.occupancyTypeOptions.find(function (x) {
                                        return x.name === response.propertyType;
                                    });
                                    if (!occupancyType) {
                                        occupancyType = _this.occupancyTypeOptions[_this.occupancyTypeOptions.length - 1];
                                        _this.occupancyTypeOther = true;
                                    }
                                    if (response.propertySubType &&
                                        occupancyType &&
                                        occupancyType.propertySubtypes &&
                                        !_this.occupancyTypeOther) {
                                        _this.occupancySubTypeDisplay = true;
                                        _this.occupancySubTypeOptions =
                                            occupancyType.propertySubtypes;
                                        occupancySubType = occupancyType.propertySubtypes.find(function (x) {
                                            return x.subtypeName === response.propertySubType;
                                        });
                                    }
                                    else {
                                        _this.occupancySubTypeDisplay = false;
                                    }
                                }
                                if (response.buildingType && _this.constructionTypeOptions) {
                                    buildingType = _this.constructionTypeOptions.find(function (x) {
                                        return x.name === response.buildingType;
                                    });
                                    if (!buildingType) {
                                        buildingType = { name: 'Other' };
                                        _this.constructionTypeOther = true;
                                    }
                                }
                                _this.addPropertyForm = _this.fb.group({
                                    buildingName: [
                                        response.buildingName ? response.buildingName : ''
                                    ],
                                    isActive: [
                                        response.isActive !== null ? response.isActive : 'true'
                                    ],
                                    occupancyType: [occupancyType ? occupancyType.name : ''],
                                    occupancySubType: [
                                        occupancySubType ? occupancySubType.subtypeName : ''
                                    ],
                                    otherOccupancyType: [
                                        occupancyType && occupancyType.name === 'Other'
                                            ? response.propertyType
                                            : ''
                                    ],
                                    constructionType: [
                                        buildingType ? buildingType.name : ''
                                    ],
                                    otherContructionType: [
                                        buildingType && buildingType.name === 'Other'
                                            ? response.buildingType
                                            : ''
                                    ],
                                    noOfStories: [
                                        response.numberOfStories || '',
                                        Validators.maxLength(2)
                                    ],
                                    yearBuilt: [
                                        response.yearBuilt || '',
                                        Validators.pattern('[0-9]{4}')
                                    ],
                                    floorArea: [response.floorArea || ''],
                                    copyAddressEdit: [false],
                                    address: _this.fb.group({
                                        streetLine1: [
                                            response.streetLine1 || '',
                                            Validators.required
                                        ],
                                        streetLine2: [response.streetLine2 || ''],
                                        zipcode: [
                                            response.zipcode && response.zipcode.zipcode
                                                ? response.zipcode.zipcode
                                                : '',
                                            Validators.required
                                        ],
                                        additionalZipcode: [''],
                                        city: [
                                            response.zipcode && response.zipcode.city
                                                ? response.zipcode.city
                                                : '',
                                            Validators.required
                                        ],
                                        state: [
                                            response.zipcode && response.zipcode.state
                                                ? response.zipcode.state
                                                : '',
                                            Validators.required
                                        ],
                                        jurisdictionName: [
                                            response.jurisdiction ? response.jurisdiction.id : ''
                                        ]
                                    }),
                                    manager: _this.fb.group({
                                        firstName: [
                                            response.propertyContacts &&
                                                response.propertyContacts.length > 0
                                                ? response.propertyContacts[0].firstName
                                                : ''
                                        ],
                                        lastName: [
                                            response.propertyContacts &&
                                                response.propertyContacts.length > 0
                                                ? response.propertyContacts[0].lastName
                                                : ''
                                        ],
                                        phoneNumber: [
                                            response.propertyContacts &&
                                                response.propertyContacts.length > 0
                                                ? response.propertyContacts[0].phoneNum
                                                : ''
                                        ],
                                        emailAddress: [
                                            response.propertyContacts &&
                                                response.propertyContacts.length > 0
                                                ? response.propertyContacts[0].email
                                                : '',
                                            Validators.pattern('[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+')
                                        ],
                                        copyAddress: [false],
                                        managerAddress: _this.fb.group({
                                            streetLine1: [
                                                response.propertyContacts &&
                                                    response.propertyContacts.length > 0
                                                    ? response.propertyContacts[0].streetLine1
                                                    : ''
                                            ],
                                            streetLine2: [
                                                response.propertyContacts &&
                                                    response.propertyContacts.length > 0
                                                    ? response.propertyContacts[0].streetLine2
                                                    : ''
                                            ],
                                            zipcode: [
                                                response.propertyContacts &&
                                                    response.propertyContacts.length > 0 &&
                                                    response.propertyContacts[0].zipcode &&
                                                    response.propertyContacts[0].zipcode.zipcode
                                                    ? response.propertyContacts[0].zipcode.zipcode
                                                    : ''
                                            ],
                                            city: [
                                                response.propertyContacts &&
                                                    response.propertyContacts.length > 0 &&
                                                    response.propertyContacts[0].zipcode &&
                                                    response.propertyContacts[0].zipcode.city
                                                    ? response.propertyContacts[0].zipcode.city
                                                    : ''
                                            ],
                                            state: [
                                                response.propertyContacts &&
                                                    response.propertyContacts.length > 0 &&
                                                    response.propertyContacts[0].zipcode &&
                                                    response.propertyContacts[0].zipcode.state
                                                    ? response.propertyContacts[0].zipcode.state
                                                    : ''
                                            ]
                                        })
                                    })
                                });
                            }
                        }, function (error) {
                            console.log(error);
                            alert('An Error has Occured');
                            _this.loading = false;
                            _this.errorOccured.emit(true);
                        }));
                    }
                    else {
                        _this.propertyService.propertyId$.next(null);
                    }
                }));
            }
        }, function (error) {
            console.log(error);
            if (error &&
                error.error &&
                error.error.message &&
                error.error.message.includes('Property Already exists')) {
                alert('This address is already registered with TEGRIS FireTM.');
            }
            else {
                alert('Unexpected error occured');
            }
            _this.currentButton = null;
        }));
    };
    PropertyDetailsComponent.prototype.openValidAddressPopup = function (res) {
        var _this = this;
        var parser = new DOMParser();
        var xmlDoc = parser.parseFromString(res, 'text/xml');
        var stLine2 = null;
        if (xmlDoc.getElementsByTagName('Address1')[0] &&
            xmlDoc.getElementsByTagName('Address1')[0].childNodes[0]) {
            stLine2 = xmlDoc.getElementsByTagName('Address1')[0].childNodes[0]
                .nodeValue;
            if (stLine2 && stLine2.toLowerCase() === 'null') {
                stLine2 = null;
            }
        }
        var subZip = null;
        if (xmlDoc.getElementsByTagName('Zip4')[0] &&
            xmlDoc.getElementsByTagName('Zip4')[0].childNodes[0]) {
            subZip = xmlDoc.getElementsByTagName('Zip4')[0].childNodes[0].nodeValue;
            if (subZip && subZip.toLowerCase() === 'null') {
                subZip = null;
            }
        }
        this.addPropertyForm.value.address.streetLine1 = xmlDoc.getElementsByTagName('Address2')[0].childNodes[0].nodeValue;
        this.addPropertyForm.value.address.streetLine2 = stLine2;
        (this.addPropertyForm.value.address.city = xmlDoc.getElementsByTagName('City')[0].childNodes[0].nodeValue),
            (this.addPropertyForm.value.address.state = xmlDoc.getElementsByTagName('State')[0].childNodes[0].nodeValue),
            (this.addPropertyForm.value.address.zipcode = xmlDoc.getElementsByTagName('Zip5')[0].childNodes[0].nodeValue),
            (this.addPropertyForm.value.address.additionalZipcode = subZip);
        var dialogRef = this.dialog.open(USPSValidPopUpComponent, {
            width: '735px',
            height: '350px',
            data: {
                streetLine1: this.addPropertyForm.value.address.streetLine1,
                streetLine2: stLine2,
                city: this.addPropertyForm.value.address.city,
                state: this.addPropertyForm.value.address.state,
                zipcode: this.addPropertyForm.value.address.zipcode,
                subzipcode: subZip,
                manager: false
            },
            disableClose: true,
            autoFocus: false
        });
        this.subscriptions.push(dialogRef.afterClosed().subscribe(function (result) {
            _this.addressUspsDone = true;
            if (result === 'continue') {
                _this.addPropertyForm.value.address.streetLine1 = xmlDoc.getElementsByTagName('Address2')[0].childNodes[0].nodeValue;
                _this.addPropertyForm.value.address.streetLine2 = stLine2;
                _this.addPropertyForm.value.address.city = xmlDoc.getElementsByTagName('City')[0].childNodes[0].nodeValue;
                _this.addPropertyForm.value.address.state = xmlDoc.getElementsByTagName('State')[0].childNodes[0].nodeValue;
                _this.addPropertyForm.value.address.zipcode = xmlDoc.getElementsByTagName('Zip5')[0].childNodes[0].nodeValue;
                _this.addPropertyForm.value.address.additionalZipcode = subZip;
                if (_this.changeInManagerAddress) {
                    _this.validateManagerAddressFromUsps();
                }
                _this.prePostForm();
            }
            else if (result === 'cancelled') {
                _this.currentButton = null;
                _this.creationDisabled = false;
            }
        }));
        //  rest api call to post -> this.service.postPropertyDetails(this.propertyDetails);
    };
    PropertyDetailsComponent.prototype.openInvalidAddressPopUp = function (manager) {
        var _this = this;
        if (manager === void 0) { manager = false; }
        var data;
        if (manager) {
            data = {
                manager: true
            };
        }
        else {
            data = {
                manager: false
            };
        }
        var dialogRef = this.dialog.open(USPSInvalidPopUpComponent, {
            width: '600px',
            height: '300px',
            disableClose: true,
            autoFocus: false,
            data: data
        });
        this.subscriptions.push(dialogRef.afterClosed().subscribe(function (result) {
            if (manager) {
                _this.managerAddressUspsDone = true;
            }
            else {
                _this.addressUspsDone = true;
            }
            if (result === 'abort') {
                // 2521 - fix
                _this.location.back();
            }
            else if (result === 'try again') {
                _this.creationDisabled = false;
            }
            else if (result === 'cancel') {
                _this.currentButton = null;
                _this.creationDisabled = false;
            }
            else if (result === 'continue') {
                if (!manager) {
                    if (_this.changeInManagerAddress) {
                        _this.validateManagerAddressFromUsps();
                    }
                }
                _this.prePostForm();
            }
        }));
    };
    PropertyDetailsComponent.prototype.resetFunction = function () {
        var _this = this;
        this.propertyService.isPropAddressValidated$.next(false);
        if (this.propertyService.propertyId$.getValue() ||
            this.propertyService.propertySaved.getValue()) {
            this.subscriptions.push(this.propertyService
                .getPropertyById(this.propertyService.propertyId$.getValue().toString())
                .subscribe(function (response) {
                _this.loading = false;
                _this.propertyService.propertyDetails$.next(response);
                if (response) {
                    var occupancyType = void 0;
                    var occupancySubType = void 0;
                    var buildingType = void 0;
                    if (response.propertyType && _this.occupancyTypeOptions) {
                        occupancyType = _this.occupancyTypeOptions.find(function (x) {
                            return x.name === response.propertyType;
                        });
                        if (response.propertySubType &&
                            occupancyType &&
                            occupancyType.propertySubtypes) {
                            _this.occupancySubTypeDisplay = true;
                            _this.occupancySubTypeOptions =
                                occupancyType.propertySubtypes;
                            occupancySubType = occupancyType.propertySubtypes.find(function (x) {
                                return x.subtypeName === response.propertySubType;
                            });
                        }
                        else {
                            _this.occupancySubTypeDisplay = false;
                        }
                    }
                    if (response.buildingType) {
                        buildingType = _this.constructionTypeOptions.find(function (x) {
                            return x.name === response.buildingType;
                        });
                    }
                    _this.addPropertyForm = _this.fb.group({
                        buildingName: [
                            response.buildingName ? response.buildingName : ''
                        ],
                        isActive: [response.isActive !== null ? response.isActive : 'true'],
                        occupancyType: [occupancyType ? occupancyType.name : ''],
                        occupancySubType: [
                            occupancySubType ? occupancySubType.subtypeName : ''
                        ],
                        otherOccupancyType: [''],
                        constructionType: [
                            buildingType && buildingType[0] ? buildingType[0].id : ''
                        ],
                        otherContructionType: [],
                        noOfStories: [
                            response.numberOfStories || '',
                            Validators.maxLength(2)
                        ],
                        yearBuilt: [
                            response.yearBuilt || '',
                            Validators.pattern('[0-9]{4}')
                        ],
                        floorArea: [response.floorArea || ''],
                        address: _this.fb.group({
                            streetLine1: [
                                response.streetLine1 || '',
                                Validators.required
                            ],
                            streetLine2: [response.streetLine2 || ''],
                            zipcode: [
                                response.zipcode && response.zipcode.zipcode
                                    ? response.zipcode.zipcode
                                    : '',
                                Validators.required
                            ],
                            additionalZipcode: [''],
                            city: [
                                response.zipcode && response.zipcode.city
                                    ? response.zipcode.city
                                    : '',
                                Validators.required
                            ],
                            state: [
                                response.zipcode && response.zipcode.state
                                    ? response.zipcode.state
                                    : '',
                                Validators.required
                            ],
                            jurisdictionName: [
                                response.jurisdiction ? response.jurisdiction.id : ''
                            ]
                        }),
                        manager: _this.fb.group({
                            firstName: [
                                response.propertyContacts &&
                                    response.propertyContacts.length > 0
                                    ? response.propertyContacts[0].firstName
                                    : ''
                            ],
                            lastName: [
                                response.propertyContacts &&
                                    response.propertyContacts.length > 0
                                    ? response.propertyContacts[0].lastName
                                    : ''
                            ],
                            phoneNumber: [
                                response.propertyContacts &&
                                    response.propertyContacts.length > 0
                                    ? response.propertyContacts[0].phoneNum
                                    : ''
                            ],
                            emailAddress: [
                                response.propertyContacts &&
                                    response.propertyContacts.length > 0
                                    ? response.propertyContacts[0].email
                                    : '',
                                Validators.pattern('[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+')
                            ],
                            copyAddress: [false],
                            managerAddress: _this.fb.group({
                                streetLine1: [
                                    response.propertyContacts &&
                                        response.propertyContacts.length > 0
                                        ? response.propertyContacts[0].streetLine1
                                        : ''
                                ],
                                streetLine2: [
                                    response.propertyContacts &&
                                        response.propertyContacts.length > 0
                                        ? response.propertyContacts[0].streetLine2
                                        : ''
                                ],
                                zipcode: [
                                    response.propertyContacts &&
                                        response.propertyContacts.length > 0 &&
                                        response.propertyContacts[0].zipcode &&
                                        response.propertyContacts[0].zipcode.zipcode
                                        ? response.propertyContacts[0].zipcode.zipcode
                                        : ''
                                ],
                                city: [
                                    response.propertyContacts &&
                                        response.propertyContacts.length > 0 &&
                                        response.propertyContacts[0].zipcode &&
                                        response.propertyContacts[0].zipcode.city
                                        ? response.propertyContacts[0].zipcode.city
                                        : ''
                                ],
                                state: [
                                    response.propertyContacts &&
                                        response.propertyContacts.length > 0 &&
                                        response.propertyContacts[0].zipcode &&
                                        response.propertyContacts[0].zipcode.state
                                        ? response.propertyContacts[0].zipcode.state
                                        : ''
                                ]
                            })
                        })
                    });
                }
            }));
        }
        else {
            this.addPropertyForm = this.fb.group({
                buildingName: [''],
                isActive: ['true'],
                occupancyType: [''],
                occupancySubType: [],
                otherOccupancyType: [],
                constructionType: [''],
                otherContructionType: [],
                noOfStories: ['', Validators.maxLength(2)],
                yearBuilt: ['', Validators.pattern('[0-9]{4}')],
                floorArea: [],
                address: this.fb.group({
                    streetLine1: ['', Validators.required],
                    streetLine2: [''],
                    zipcode: ['', Validators.required],
                    additionalZipcode: [''],
                    city: ['', Validators.required],
                    state: ['', Validators.required],
                    jurisdictionName: [-1]
                }),
                manager: this.fb.group({
                    firstName: [],
                    lastName: [],
                    phoneNumber: [''],
                    emailAddress: [
                        '',
                        Validators.pattern('[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+')
                    ],
                    copyAddress: [false],
                    managerAddress: this.fb.group({
                        streetLine1: [],
                        streetLine2: [],
                        zipcode: [],
                        city: [],
                        state: []
                    })
                })
            });
            this.resetErrorsMap();
            if (this.addPropertyForm.value.address.jurisdictionName == null) {
                this.addPropertyForm.value.address.jurisdictionName = -1;
            }
            this.checkOtherType('');
        }
    };
    // updateManagerContactsFlag() {
    // if (this.addPropertyForm.value.manager.firstName
    //   && this.addPropertyForm.value.manager.firstName.length > 0
    //   && this.addPropertyForm.value.manager.lastName
    //   && this.addPropertyForm.value.manager.lastName.length > 0) {
    //   this.allowManagerPopulate = true;
    // } else {
    //   this.allowManagerPopulate = false;
    //   this.addPropertyForm.get('manager').patchValue({
    //     emailAddress: null,
    //     phoneNumber: null
    //   });
    //   // this.propertyContact
    // }
    // }
    PropertyDetailsComponent.prototype.getCitiesByState = function (StateID) {
        // this.addPropertyForm.value.address.state = this.states.find(item => {
        //   return item.id == StateID;
        // }).id;
        // this.subscriptions.push(
        //   this.propertyService.getCitiesByState(StateID).subscribe(
        //     res => {
        //       this.cities = res;
        //       this.selectedCityID = this.cities[0].data;
        //       this.setCity(this.selectedCityID);
        //     }
        //   )
        // );
    };
    PropertyDetailsComponent.prototype.setCity = function (cityID) {
        this.addPropertyForm.value.address.city = this.cities.find(function (item) {
            return item.data == cityID;
        }).data;
    };
    PropertyDetailsComponent.prototype.populateManagerCityState = function (val) {
        var _this = this;
        this.readOnlyManagerCity = false;
        this.readOnlyManagerState = false;
        var zipcode = this.addPropertyForm.value.manager.managerAddress.zipcode;
        this.isCanadianZipCodeManager = false;
        if (zipcode.length === 6) {
            zipcode = zipcode.toUpperCase();
            zipcode = zipcode.substr(0, 3) + ' ' + zipcode.substr(3, 3);
            this.addPropertyForm.value.manager.managerAddress.zipcode = zipcode;
        }
        if (this.canadinaZipRegex.test(zipcode)) {
            this.isCanadianZipCodeManager = true;
        }
        if (zipcode && zipcode.length > 0) {
            this.subscriptions.push(this.propertyService
                .getJurisdictionDetailsFromZipcode(zipcode)
                .subscribe(function (data) {
                if (data) {
                    if (data && data.city && data.state) {
                        _this.readOnlyManagerCity = true;
                        _this.readOnlyManagerState = true;
                        _this.addPropertyForm.get('manager').get('managerAddress').patchValue({
                            state: data.state,
                            city: data.city
                        });
                    }
                    // else if (!this.isCanadianZipCodeManager) {
                    //   this.getManagerCitiesByState(this.selectedManagerStateID);
                    // }
                }
                // else if (!this.isCanadianZipCodeManager) {
                //   this.getManagerCitiesByState(this.selectedManagerStateID);
                // }
            }, function (err) {
                console.log(err);
            }));
        }
        else {
            this.addPropertyForm.get('manager').get('managerAddress').patchValue({
                state: "",
                city: ""
            });
            this.readOnlyManagerCity = true;
            this.readOnlyManagerState = true;
        }
    };
    PropertyDetailsComponent.prototype.getManagerCitiesByState = function (StateID) {
        // if (StateID) {
        //   this.addPropertyForm.value.manager.managerAddress.state = this.states.find(item => {
        //     return item.id === StateID;
        //   }).id;
        //   this.subscriptions.push(
        //     this.propertyService.getCitiesByState(StateID).subscribe(
        //       res => {
        //         this.managerCities = res;
        //         this.selectedManagerCityID = this.managerCities[0].data;
        //         this.setManagerCity(this.selectedManagerCityID);
        //       }
        //     )
        //   );
        // } else {
        //   // this.selectedManagerStateID = null;
        //   // this.selectedManagerCityID = null;
        //   this.managerCities = [];
        //   this.addPropertyForm.get('manager').get('managerAddress').patchValue({
        //     city: null,
        //     state: null
        //   });
        // }
    };
    PropertyDetailsComponent.prototype.setManagerCity = function (cityID) {
        var cityVar = this.managerCities.find(function (item) {
            return item.data === cityID;
        }).data;
        this.addPropertyForm.get('manager').get('managerAddress').patchValue({
            city: cityVar
        });
    };
    PropertyDetailsComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (x) {
            x.unsubscribe();
        });
    };
    PropertyDetailsComponent.prototype.closeDialog = function () {
        this.close.emit();
    };
    PropertyDetailsComponent.prototype.openOwnerBox = function () {
        var _this = this;
        var a = this.dialog.open(ChangeOwnerComponent, {
            width: '800px',
            maxHeight: '99vh',
            // height: '800px',
            autoFocus: false
        });
        this.subscriptions.push(a.afterClosed().subscribe(function (res) {
            var owner = _this.fileCabService.getOwnerDetails();
            setTimeout(function () {
                _this.addPropertyForm.patchValue({
                    owner: {
                        name: owner['firstName'] + ' ' + owner['lastName'],
                        number: owner['contactNumber'],
                        address: owner['address'],
                        email: owner['email']
                    }
                });
            });
        }));
    };
    return PropertyDetailsComponent;
}());
export { PropertyDetailsComponent };
