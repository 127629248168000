import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { InspectionReportService } from '../../../core/services/inspection/inspection-report.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  invalidToEmail: string;
  invalidCCEmail: string;
  loading: boolean;

  constructor(
    public dialogRef: MatDialogRef<EmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private inspectionReportService: InspectionReportService ) { }

  ngOnInit() {
    this.resetErrors();
    this.loading = false;
  }

  resetErrors() {
    this.invalidToEmail = '';
    this.invalidCCEmail = '';
  }

  send() {
    this.loading = true;
    this.resetErrors();
    this.invalidToEmail = this.validateEmail(this.data.to);
    this.invalidCCEmail = this.validateEmail(this.data.cc);

    this.invalidCCEmail = this.invalidCCEmail === 'Please enter Email address' ? '' : this.invalidCCEmail;

    if (this.invalidCCEmail.length === 0 || this.invalidToEmail.length === 0) {
      // const formData = new FormData();
      // formData.append('report', this.data.attachment);
      const body = {
        to: this.data.to,
        cc: this.data.cc,
        from: 'tegris.fire@nagarro.com',
        subject: this.data.subject,
        body: this.data.body
      };
      body.body = body.body.replace(/(?:\r\n|\r|\n)/g, '<br/>');
      this.inspectionReportService.mailPdf(this.data.inspectionResultId, body)
        .subscribe( res => {
          alert(res['1'][0]);
          // const msg = data[`message`];
          // alert(msg);
          // // console.log(data, "success");
          this.loading = false;
        }, error => {
          console.error(error, 'error');
          this.loading = false;
        });

    }else {
      this.loading = false;
    }
  }

  validateEmail(email): string {
    if (!email || email.length === 0) {
      return 'Please enter Email address';
    } else if (!/^([a-zA-Z0-9][a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,3}([ ]*;[ ]*[a-zA-Z0-9][a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,3})*)$/.
    test(email)) {
      return 'Enter a valid email address like abc@gmail.com';
    }
    return '';
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
